import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import type { TAgentCustomersOrganizationSourceBaseFragment } from '@src/gen/graphql/bindings';
import { getSourceName } from '@src/gen/shared/enums/source';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrganizationSourceCardBase = {
  onRemove: (() => void) | null;
  organizationSource: TAgentCustomersOrganizationSourceBaseFragment;
};

export type TOrganizationSourceCard = TProps<false, TOrganizationSourceCardBase, 'div'>;
export const ORGANIZATION_SOURCE_CARD_CLASS_NAME = 'wp-organization-source-card';

export const OrganizationSourceCard = withCssToString(
  ORGANIZATION_SOURCE_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrganizationSourceCard>(
      ({ onRemove, organizationSource, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORGANIZATION_SOURCE_CARD_CLASS_NAME),
          [className],
        );

        const items = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: '',
              text: '',
            },
            {
              caption: 'Enabled Since',
              text: { asDate: 'on', at: organizationSource.created_at },
            },
          ],
          // @sort
          [organizationSource.created_at],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () =>
            getAvailableIconButtonActions([
              {
                icon: 'trash',
                isAsync: false,
                onClick: onRemove ?? undefined,
              },
            ]),
          [onRemove],
        );

        return (
          <Card.Container
            {...rest}
            iconButtonActions={iconButtonActions}
            flush={true}
            interactive={false}
            className={joinedClassName}
            ref={ref}>
            <Card.ContentArea>
              <Card.Title title={getSourceName(organizationSource.source)} />
              <Items items={items} />
            </Card.ContentArea>
          </Card.Container>
        );
      },
    ),
  ),
);
