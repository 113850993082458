import type { CustomerOrderSubscriptionVariables, TCustomerOrderCompleteFragment } from '@src/gen/graphql/bindings';
import { useCustomerOrderSubscription } from '@src/gen/graphql/bindings';
import { CustomerOrderManager } from '@src/gen/shared/data/customerOrders';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useCustomerOrderRouteParams } from '@src/modules/routing/customer';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TCustomerOrderContext = {
  order: TCustomerOrderCompleteFragment;
  orderManager: CustomerOrderManager;
  serializationKey: string;
};

export const { Context: CustomerOrderContext, useContext: useCustomerOrder } =
  createRequiredContext<TCustomerOrderContext>();

export type TCustomerOrderProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function CustomerOrderProvider({ LoaderComponent, children }: TCustomerOrderProvider): JSX.Element {
  const routeParams = useCustomerOrderRouteParams();

  const variables = useMemo<CustomerOrderSubscriptionVariables>(
    () => ({
      orderId: routeParams.orderId,
    }),
    // @sort
    [routeParams.orderId],
  );

  const { data, error, loading } = useCustomerOrderSubscription({
    variables,
  });

  const value = useMemo<TCustomerOrderContext | undefined>(
    () =>
      isDef(data) && isDef(data.orders_by_pk)
        ? {
            order: data.orders_by_pk,
            orderManager: new CustomerOrderManager(data.orders_by_pk),
            serializationKey: `order[${data.orders_by_pk.id}]`,
          }
        : undefined,
    // @sort
    [data],
  );

  if (isDef(error)) {
    throw error;
  }

  if (isDef(data) && !isDef(data.orders_by_pk)) {
    throw new Error('Not Found');
  }

  return isDef(value) && !loading ? (
    <CustomerOrderContext.Provider value={value}>{children}</CustomerOrderContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
