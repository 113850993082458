import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { getFullName } from '@src/gen/shared/data/snippets';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentCustomersUserActions } from '@src/modules/data/agent/customers/users/AgentCustomersUserActionsProvider';
import { useAgentCustomersUser } from '@src/modules/data/agent/customers/users/AgentCustomersUserProvider';
import { useMemo } from 'react';

export function AgentCustomersUserOverviewPart(): JSX.Element {
  const { user } = useAgentCustomersUser();
  const { beginUserCustomerUpdate } = useAgentCustomersUserActions();

  const activeOrgAcls = useMemo(
    () => user.organization_acls.filter((orgAcl) => orgAcl.is_member && !user.is_disabled),
    [user],
  );

  const inactiveOrgAcls = useMemo(
    () => user.organization_acls.filter((orgAcl) => !orgAcl.is_member || user.is_disabled),
    [user],
  );

  const itemsFirst = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Name',
        text: getFullName(user),
      },
      {
        caption: 'Active Org. Memberships',
        text: `${activeOrgAcls.length}`,
      },
      {
        caption: 'Inactive Org. Memberships',
        text: `${inactiveOrgAcls.length}`,
      },
    ],
    // @sort
    [user, activeOrgAcls.length, inactiveOrgAcls.length],
  );

  const itemsSecond = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Enabled',
        text: { asBoolean: !user.is_disabled },
      },
      {
        caption: '',
        text: '',
      },
    ],
    // @sort
    [user.is_disabled],
  );

  const itemsThird = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Email',
        text: `${user.email}`,
      },
      {
        caption: 'Phone Number',
        text: user.phone_number !== '' ? user.phone_number : 'N/A',
      },
      {
        caption: 'Onboarded On',
        text: { asDate: 'on', at: user.created_at },
      },
    ],
    // @sort
    [user.created_at, user.email, user.phone_number],
  );

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <Structure.Group
          title='Summary and Settings'
          iconButtonActions={
            isDef(beginUserCustomerUpdate)
              ? [
                  {
                    isAsync: false,
                    icon: 'edit',
                    onClick: (): void => beginUserCustomerUpdate(),
                  },
                ]
              : []
          }>
          <ItemsBanner items={itemsFirst} />
          <ItemsBanner items={itemsSecond} />
          <ItemsBanner items={itemsThird} />
        </Structure.Group>
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}
