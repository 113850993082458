import { ActionButton } from '@src/components/appearance/controls/Button';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import type {
  TAgentCustomersLocationAclBaseFragment,
  TAgentCustomersLocationAclCompleteFragment,
  TAgentCustomersLocationCompleteFragment,
  TAgentCustomersOrganizationCompleteFragment,
} from '@src/gen/graphql/bindings';
import { getFullName } from '@src/gen/shared/data/snippets';
import { formatDollarsCurrency } from '@src/gen/shared/utils/converters';
import type { TArrayElement } from '@src/gen/shared/utils/types';
import { isDef } from '@src/gen/shared/utils/types';
import { openUrl } from '@src/logic/internal/utils/utils';
import { useAgentCustomersOrganizationActions } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import { useAgentCustomersOrganization } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import { encodeAgentCustomersUserOverviewPath } from '@src/modules/routing/agent';
import { useMemo } from 'react';

export function AgentCustomersOrganizationLocationsPart(): JSX.Element {
  const { organization } = useAgentCustomersOrganization();
  const { beginLocationAdd } = useAgentCustomersOrganizationActions();

  const activeOrgAcls = useMemo(
    () => organization.organization_acls.filter((orgAcl) => orgAcl.is_member && !orgAcl.user.is_disabled),
    [organization.organization_acls],
  );

  const activeUserIds = useMemo(
    () =>
      activeOrgAcls.reduce<{ [key: string]: true }>(
        (out, orgAcl) => ({
          ...out,
          [orgAcl.user_id]: true,
        }),
        {},
      ),
    [activeOrgAcls],
  );

  const items = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Locations',
        text: `${organization.locations.length}`,
      },
      {
        caption: '',
        text: '',
      },
    ], // @sort
    [organization.locations.length],
  );

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <ItemsBanner
          items={items}
          ButtonElement={
            isDef(beginLocationAdd) ? (
              <ActionButton
                action={{
                  isAsync: false,
                  onClick: beginLocationAdd,
                  text: 'Add',
                }}
              />
            ) : undefined
          }
        />
        {organization.locations.map((loc) => (
          <LocationGroupComponent
            key={loc.id}
            location={loc}
            activeUserIds={activeUserIds}
            lastOrderByLocation={organization.lastOrderByLocation.find((lobc) => lobc.id === loc.id)?.orders[0]}
          />
        ))}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function LocationGroupComponent({
  location,
  activeUserIds,
  lastOrderByLocation,
}: {
  location: TAgentCustomersLocationCompleteFragment;
  activeUserIds: { [key: string]: true };
  lastOrderByLocation:
    | TArrayElement<TArrayElement<TAgentCustomersOrganizationCompleteFragment['lastOrderByLocation']>['orders']>
    | undefined;
}): JSX.Element {
  const { beginLocationEdit, beginLocationAclAddGivenLocation, beginCartProductsView } =
    useAgentCustomersOrganizationActions();

  const activeLocAcls = useMemo(
    () => location.location_acls.filter((locAcl) => activeUserIds[locAcl.user.id]),
    [activeUserIds, location.location_acls],
  );

  const itemsFirst = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Order Code',
        text: location.order_code,
      },
      {
        caption: 'Requires Prebill Approval',
        text: location.requires_prebill_approval
          ? isDef(location.requires_prebill_approval_threshold) && location.requires_prebill_approval_threshold > 0
            ? `Over ${formatDollarsCurrency(location.requires_prebill_approval_threshold)}`
            : 'Always'
          : 'Never',
      },
      {
        caption: 'Account Stewardship',
        text: {
          asBoolean: location.has_account_stewardship,
        },
      },
    ], // @sort
    [
      location.has_account_stewardship,
      location.order_code,
      location.requires_prebill_approval,
      location.requires_prebill_approval_threshold,
    ],
  );

  const itemsSecond = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Ship To',
        text: location.ship_to,
      },
      {
        caption: 'Bill To',
        text: location.bill_to,
      },
    ], // @sort
    [location.bill_to, location.ship_to],
  );

  const itemsThird = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Onboarded On',
        text: { asDate: 'on', at: location.created_at },
      },
      {
        caption: 'Last Order Placed',
        text: isDef(lastOrderByLocation) ? { asDate: 'ago', at: lastOrderByLocation.created_at } : 'N/A',
      },
    ],
    [lastOrderByLocation, location.created_at],
  );

  const iconButtonActions = useMemo<TIconButtonAction[]>(
    () =>
      getAvailableIconButtonActions([
        {
          icon: 'edit',
          isAsync: false,
          onClick: isDef(beginLocationEdit) ? (): void => beginLocationEdit({ locationId: location.id }) : undefined,
        },
        {
          icon: 'shopping-cart',
          isAsync: false,
          onClick: isDef(beginCartProductsView)
            ? (): void => beginCartProductsView({ locationId: location.id })
            : undefined,
        },
        {
          isAsync: false,
          icon: 'credit-card',
          onClick: (): void => {
            if (isDef(location.stripe_customer_id)) {
              openUrl(`https://dashboard.stripe.com/customers/${location.stripe_customer_id}`);
            }
          },
          variant: isDef(location.stripe_customer_id) ? 'default' : 'disabled',
        },
      ]),
    [beginCartProductsView, beginLocationEdit, location.id, location.stripe_customer_id],
  );

  const userPermissionsIconButtonActions = useMemo<TIconButtonAction[]>(
    () =>
      getAvailableIconButtonActions([
        {
          isAsync: false,
          icon: 'add',
          onClick: isDef(beginLocationAclAddGivenLocation)
            ? (): void =>
                beginLocationAclAddGivenLocation({
                  locationId: location.id,
                  omitUserIds: activeLocAcls.reduce<{ [key: string]: true }>(
                    (out, aLocAcl) => ({
                      ...out,
                      [aLocAcl.user_id]: true,
                    }),
                    {},
                  ),
                })
            : undefined,
        },
      ]), // @sort
    [activeLocAcls, beginLocationAclAddGivenLocation, location.id],
  );

  return (
    <Structure.Group iconButtonActions={iconButtonActions} title={location.name} variant='section'>
      <Structure.Group>
        <ItemsBanner items={itemsFirst} />
        <ItemsBanner items={itemsSecond} />
        <ItemsBanner items={itemsThird} />
      </Structure.Group>
      <Structure.Group
        title='User Permissions'
        variant='secondary'
        iconButtonActions={userPermissionsIconButtonActions}>
        {activeLocAcls.length === 0 && (
          <Banner accent='warning' icon='warning' message='No user is enabled to access this location.' />
        )}
        {activeLocAcls.map((locAcl) => (
          <LocationAclUserCardComponent key={locAcl.user.id} locAcl={locAcl} />
        ))}
      </Structure.Group>
    </Structure.Group>
  );
}

function LocationAclUserCardComponent({ locAcl }: { locAcl: TAgentCustomersLocationAclCompleteFragment }): JSX.Element {
  const { organization } = useAgentCustomersOrganization();
  const { beginLocationAclEdit, beginLocationAclRemove } = useAgentCustomersOrganizationActions();
  const to = useMemo(() => encodeAgentCustomersUserOverviewPath(locAcl.user.id), [locAcl.user.id]);

  const organizationAcl = organization.organization_acls.find(
    (orgAcl) => orgAcl.user_id === locAcl.user_id && orgAcl.is_member && !orgAcl.user.is_disabled,
  );

  const items = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Permissions for Orders',
        text: toValue(locAcl),
      },
      {
        caption: 'View Analytics',
        text:
          organizationAcl?.can_view_analytics === true
            ? 'Yes (Organization Setting)'
            : locAcl.can_view_analytics
            ? 'Yes'
            : 'No',
      },
    ], // @sort
    [locAcl, organizationAcl?.can_view_analytics],
  );

  const iconButtonActions = useMemo<TIconButtonAction[]>(
    () =>
      getAvailableIconButtonActions([
        {
          icon: 'edit',
          isAsync: false,
          onClick: isDef(beginLocationAclEdit)
            ? (): void =>
                beginLocationAclEdit({
                  locationId: locAcl.location_id,
                  userId: locAcl.user_id,
                })
            : undefined,
        },
        {
          icon: 'trash',
          isAsync: false,
          onClick: isDef(beginLocationAclRemove)
            ? (): void =>
                beginLocationAclRemove({
                  locationId: locAcl.location_id,
                  userId: locAcl.user_id,
                })
            : undefined,
        },
      ]),
    [beginLocationAclEdit, beginLocationAclRemove, locAcl.location_id, locAcl.user_id],
  );

  return (
    <Card.Container iconButtonActions={iconButtonActions} flush={true} interactive={true}>
      <Card.LinkArea to={to}>
        <Card.Title title={getFullName(locAcl.user)} subTitle={locAcl.user.email} />
        <Items items={items} />
      </Card.LinkArea>
    </Card.Container>
  );
}

function toValue(
  acl: Pick<
    TAgentCustomersLocationAclBaseFragment,
    'can_approve_orders' | 'can_place_orders' | 'can_send_order_messages' | 'can_view_orders'
  >,
): string {
  if (acl.can_approve_orders) {
    return 'View + Send Messages + Place + Approve';
  } else if (acl.can_place_orders) {
    return 'View + Send Messages + Place';
  } else if (acl.can_send_order_messages) {
    return 'View + Send Messages';
  } else if (acl.can_view_orders) {
    return 'View';
  } else {
    return 'None';
  }
}
