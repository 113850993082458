import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Card } from '@src/components/appearance/fragments/Card';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import type { TAgentOrderProductBaseFragment, TCustomerOrderProductBaseFragment } from '@src/gen/graphql/bindings';
import { getSaleUnitQuantity } from '@src/gen/shared/data/snippets';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderOriginalCartProductCardBase = {
  onClick?: (() => void) | undefined;
  orderProduct: Pick<
    TAgentOrderProductBaseFragment | TCustomerOrderProductBaseFragment,
    | 'image_asset_path'
    | 'is_substitution_allowed'
    | 'name'
    | 'product_sku'
    | 'quantity'
    | 'sale_unit'
    | 'secondary_name'
    | 'source'
  >;
};

export type TOrderOriginalCartProductCard = TProps<false, TOrderOriginalCartProductCardBase, 'div'>;
export const ORDER_ORIGINAL_CART_PRODUCT_CARD_CLASS_NAME = 'wp-order-original-cart-product-card';

export const OrderOriginalCartProductCard = withCssToString(
  ORDER_ORIGINAL_CART_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderOriginalCartProductCard>(
      ({ onClick, orderProduct, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ORIGINAL_CART_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const items = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(orderProduct.source),
            },
            {
              caption: 'SKU',
              text: orderProduct.product_sku,
            },
            {
              caption: 'Quantity',
              text: getSaleUnitQuantity(orderProduct.quantity, orderProduct.sale_unit),
            },
            {
              caption: 'Allow Substitutions',
              text: orderProduct.is_substitution_allowed ? 'Yes' : 'No',
            },
          ],
          // @sort
          [
            orderProduct.is_substitution_allowed,
            orderProduct.product_sku,
            orderProduct.quantity,
            orderProduct.sale_unit,
            orderProduct.source,
          ],
        );

        return (
          <Card.Container {...rest} className={joinedClassName} interactive={isDef(onClick)} ref={ref} flush={true}>
            {isDef(onClick) ? (
              <Card.ButtonArea onClick={onClick}>
                <Card.ImageAssetArea>
                  <ImageAsset alt={orderProduct.name} imageAssetPath={orderProduct.image_asset_path} />
                  <Card.ContentArea>
                    <Card.Title subTitle={orderProduct.secondary_name} title={orderProduct.name} />
                    <Items items={items} />
                  </Card.ContentArea>
                </Card.ImageAssetArea>
              </Card.ButtonArea>
            ) : (
              <Card.ImageAssetArea>
                <ImageAsset alt={orderProduct.name} imageAssetPath={orderProduct.image_asset_path} />
                <Card.ContentArea>
                  <Card.Title subTitle={orderProduct.secondary_name} title={orderProduct.name} />
                  <Items items={items} />
                </Card.ContentArea>
              </Card.ImageAssetArea>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);

export const OrderOriginalCartProductCardLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Card.Container ref={ref}>
        <Card.ImageAssetArea>
          <Illustrations.Loader height={85} uniqueKey='wp-order-original-cart-product-card-loader-image' width={128}>
            <rect x='0' y='5' rx='0' ry='0' width='128' height='85' />
          </Illustrations.Loader>
          <Card.ContentArea>
            <Card.TitleLoader />
            <ItemsLoader count={4} />
          </Card.ContentArea>
        </Card.ImageAssetArea>
      </Card.Container>
    ),
  ),
);
