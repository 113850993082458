import { ConditionalControl } from '@src/components/appearance/controls/ConditionalControl';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { SelectControl } from '@src/components/appearance/controls/Select';
import { TextBoxControl } from '@src/components/appearance/controls/TextBox';
import { UploadControl } from '@src/components/appearance/controls/Upload';
import { EOrderAttachmentType, ORDER_ATTACHMENT_TYPE_LABELS } from '@src/gen/shared/enums/orderAttachmentType';
import { ensureDef, ensureNotEmptyString } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentUploadsTypes } from '@src/modules/data/agent/global/uploads/AgentUploadsProvider';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TOrderAttachmentAddFormValues = {
  type: EOrderAttachmentType;
  for: string | undefined;
  title: string | undefined;
  uploadId?: string | undefined;
};

export type TOrderAttachmentAddFormBase = {
  doOrderAttachmentAddAsync: TAgentOrderActionsTypes['DoOrderAttachmentAddAsync'];
  doUploadAsync: TAgentUploadsTypes['DoUploadAsync'];
};

export type TOrderAttachmentAddForm = TProps<false, TOrderAttachmentAddFormBase, 'div'>;
export const ORDER_ATTACHMENT_ADD_FORM_CLASS_NAME = 'wp-order-attachment-add-form';

export const OrderAttachmentAddForm = withCssToString(
  ORDER_ATTACHMENT_ADD_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderAttachmentAddForm>(
      ({ doOrderAttachmentAddAsync, doUploadAsync, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ATTACHMENT_ADD_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TOrderAttachmentAddFormValues>(
          () => ({
            type: EOrderAttachmentType.PREBILL,
            for: undefined,
            title: undefined,
            uploadId: undefined,
          }),
          [],
        );

        const handleSubmit = useCallback<TFormSubmit<TOrderAttachmentAddFormValues>>(
          async (values) => {
            await doOrderAttachmentAddAsync({
              type: values.type,
              description:
                values.type === EOrderAttachmentType.RETURN_LABEL
                  ? ensureNotEmptyString(values.for)
                  : values.type === EOrderAttachmentType.OTHER
                  ? ensureNotEmptyString(values.title)
                  : null,
              uploadId: ensureDef(values.uploadId),
            });
            return undefined;
          },
          [doOrderAttachmentAddAsync],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TOrderAttachmentAddFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              submitButtonText='Add Attachment'>
              <SelectControl id='type' label='Type'>
                {Object.entries(ORDER_ATTACHMENT_TYPE_LABELS).map(([k, v]) => (
                  <option key={k} value={k}>
                    {v}
                  </option>
                ))}
              </SelectControl>
              <ConditionalControl<string>
                subscription='type'
                predicate={(type): boolean => type === EOrderAttachmentType.RETURN_LABEL}
                render={(): JSX.Element => <TextBoxControl id='for' label='For' required={true} />}
              />
              <ConditionalControl<string>
                subscription='type'
                predicate={(type): boolean => type === EOrderAttachmentType.OTHER}
                render={(): JSX.Element => <TextBoxControl id='title' label='Title' required={true} />}
              />
              <UploadControl doUploadAsync={doUploadAsync} label='Attachment' id='uploadId' required={true} />
            </Form>
          </div>
        );
      },
    ),
  ),
);
