import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import type {
  TAgentOrderAttachmentBaseFragment,
  TCustomerOrderAttachmentBaseFragment,
} from '@src/gen/graphql/bindings';
import { getOrderAttachmentSubTitle, getOrderAttachmentTitle } from '@src/gen/shared/data/snippets';
import { getOrderAttachmentType } from '@src/gen/shared/enums/orderAttachmentType';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderAttachmentCardBase = {
  onDownloadAsync: () => Promise<void>;
  onRemoveAsync?: (() => Promise<void>) | undefined;
  orderAttachment: TAgentOrderAttachmentBaseFragment | TCustomerOrderAttachmentBaseFragment;
};

export type TOrderAttachmentCard = TProps<false, TOrderAttachmentCardBase, 'div'>;
export const ORDER_ATTACHMENT_CARD_CLASS_NAME = 'wp-order-attachment-card';

export const OrderAttachmentCard = withCssToString(
  ORDER_ATTACHMENT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderAttachmentCard>(
      ({ onDownloadAsync, onRemoveAsync, orderAttachment, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_ATTACHMENT_CARD_CLASS_NAME), [className]);

        const items = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Added By',
              text: `${orderAttachment.creator.first_name} ${orderAttachment.creator.last_name}`,
            },
            {
              caption: 'Added',
              text: { asDate: 'ago', at: orderAttachment.created_at },
            },
            {
              caption: 'Type',
              text: { asContentType: orderAttachment.content_type },
            },
            {
              caption: 'Size',
              text: { asContentLength: orderAttachment.content_length },
            },
          ],
          // @sort
          [
            orderAttachment.content_length,
            orderAttachment.content_type,
            orderAttachment.created_at,
            orderAttachment.creator.first_name,
            orderAttachment.creator.last_name,
          ],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () =>
            getAvailableIconButtonActions([
              { isAsync: true, icon: 'download', onClick: onDownloadAsync },
              { isAsync: true, icon: 'trash', onClick: onRemoveAsync },
            ]),
          // @sort
          [onDownloadAsync, onRemoveAsync],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flush={true}
            iconButtonActions={iconButtonActions}
            ref={ref}>
            <Card.Title
              subTitle={getOrderAttachmentSubTitle(
                getOrderAttachmentType(orderAttachment.type),
                orderAttachment.description,
                orderAttachment.revision,
              )}
              title={getOrderAttachmentTitle(getOrderAttachmentType(orderAttachment.type), orderAttachment.description)}
            />
            <Items items={items} />
          </Card.Container>
        );
      },
    ),
  ),
);
