import { useAuthSubject } from '@src/modules/auth/AuthProvider';
import { getAgentRoutes } from '@src/modules/routing/agent';
import { getCustomerRoutes } from '@src/modules/routing/customer';
import { useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

export function Router(): JSX.Element {
  const subject = useAuthSubject();

  const router = useMemo(
    () =>
      subject.is_wellplaece_agent ? createBrowserRouter(getAgentRoutes()) : createBrowserRouter(getCustomerRoutes()),
    [subject.is_wellplaece_agent],
  );

  return <RouterProvider router={router} />;
}
