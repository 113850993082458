import { ActionButton } from '@src/components/appearance/controls/Button';
import { Description } from '@src/components/appearance/fragments/Description';
import { Dialog } from '@src/components/appearance/structure/Dialog';
import { EDescriptionBlockType } from '@src/gen/shared/data/description';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderReturnInstructionsDialogPanelBase = {
  downloadShippingLabel: (() => void) | null;
  instructions: string;
  onClose: () => void;
};

export type TOrderReturnInstructionsDialogPanel = TProps<false, TOrderReturnInstructionsDialogPanelBase, 'div'>;
export const ORDER_RETURN_INSTRUCTIONS_DIALOG_PANEL_CLASS_NAME = 'wp-order-return-instructions-dialog-panel';

const SFooterDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

const SFooterSpacerDiv = styled('div', {
  flexGrow: 1,
});

const SFooterActionButton = styled(ActionButton, {
  minWidth: '$dialogFooterButtonMinWidth',
});

export const OrderReturnInstructionsDialogPanel = withCssToString(
  ORDER_RETURN_INSTRUCTIONS_DIALOG_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderReturnInstructionsDialogPanel>(
      ({ downloadShippingLabel, instructions, onClose, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_RETURN_INSTRUCTIONS_DIALOG_PANEL_CLASS_NAME),
          [className],
        );

        return (
          <Dialog.Panel {...rest} className={joinedClassName} ref={ref}>
            <Dialog.Header title='Return Instructions' />
            <Description
              description={[
                {
                  type: EDescriptionBlockType.PARAGRAPH,
                  paragraph: instructions,
                },
              ]}
            />
            <Dialog.Separator />
            <SFooterDiv>
              {isDef(downloadShippingLabel) && (
                <SFooterActionButton
                  action={{
                    isAsync: false,
                    onClick: downloadShippingLabel,
                    text: 'Download Shipping Label',
                    variant: 'secondary',
                  }}
                />
              )}
              <SFooterSpacerDiv />
              <SFooterActionButton
                action={{
                  isAsync: false,
                  onClick: onClose,
                  text: 'Close',
                }}
              />
            </SFooterDiv>
          </Dialog.Panel>
        );
      },
    ),
  ),
);
