import { NetworkStatus } from '@apollo/client';
import type {
  CustomerCartProductsQueryVariables,
  TCustomerCartProductCompleteFragment,
} from '@src/gen/graphql/bindings';
import { CustomerCartProductsDocument, useCustomerCartProductsQuery } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useCustomerRouteLocation, useCustomerRouteOrganization } from '@src/modules/routing/customer';
import type { DocumentNode } from 'graphql/language';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TCustomerNextOrderContext = {
  cartProducts: TCustomerCartProductCompleteFragment[];
  nonDeletedCartProducts: TCustomerCartProductCompleteFragment[];
  cartProductsByPublicCatalogProductId: { [key: string]: TCustomerCartProductCompleteFragment };
  cartProductsTotalItems: number;
  cartProductsUniqueItems: number;
  lastUpdatedCartProduct: TCustomerCartProductCompleteFragment | null;
  queryRef: { query: DocumentNode; variables: CustomerCartProductsQueryVariables };
  serializationKey: string;
};

export const { Context: CustomerNextOrderContext, useContext: useCustomerNextOrder } =
  createRequiredContext<TCustomerNextOrderContext>();

export function useCustomerNextOrderCartProduct(
  publicCatalogProductId: string,
): TCustomerCartProductCompleteFragment | undefined {
  const { cartProductsByPublicCatalogProductId } = useCustomerNextOrder();
  return cartProductsByPublicCatalogProductId[publicCatalogProductId];
}

export type TCustomerNextOrderProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function CustomerNextOrderProvider({ LoaderComponent, children }: TCustomerNextOrderProvider): JSX.Element {
  const organization = useCustomerRouteOrganization();
  const location = useCustomerRouteLocation();

  const variables = useMemo<CustomerCartProductsQueryVariables>(
    () => ({
      organizationId: organization.id,
      locationId: location.id,
    }),
    // @sort
    [location.id, organization.id],
  );

  const { data, error, networkStatus } = useCustomerCartProductsQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const nonDeletedCartProducts = useMemo(
    () => data?.cart_products.filter((cp) => !cp.is_deleted) ?? [],
    [data?.cart_products],
  );

  const cartProductsByPublicCatalogProductId = useMemo(
    () =>
      (data?.cart_products ?? []).reduce<TCustomerNextOrderContext['cartProductsByPublicCatalogProductId']>(
        (out, cp) => {
          out[cp.public_catalog_product_id] = cp;
          return out;
        },
        {},
      ),
    [data?.cart_products],
  );

  const cartProductsTotalItems = useMemo(
    () => data?.cart_products.reduce((out, cp) => out + (cp.is_deleted ? 0 : cp.quantity), 0) ?? 0,
    [data?.cart_products],
  );

  const cartProductsUniqueItems = useMemo(
    () => data?.cart_products.filter((cp) => cp.quantity > 0 && !cp.is_deleted).length ?? 0,
    [data?.cart_products],
  );

  const lastUpdatedCartProduct = useMemo(
    () =>
      cartProductsTotalItems > 0
        ? [...(data?.cart_products ?? [])].sort((a, b) => b.updated_at.localeCompare(a.updated_at))[0] ?? null
        : null,
    [cartProductsTotalItems, data?.cart_products],
  );

  const value = useMemo<TCustomerNextOrderContext | undefined>(
    () =>
      isDef(data)
        ? {
            cartProducts: data.cart_products,
            nonDeletedCartProducts,
            cartProductsByPublicCatalogProductId,
            cartProductsTotalItems,
            cartProductsUniqueItems,
            lastUpdatedCartProduct,
            queryRef: {
              query: CustomerCartProductsDocument,
              variables,
            },
            serializationKey: `nextOrder[${location.id}]`,
          }
        : undefined,
    // @sort
    [
      cartProductsByPublicCatalogProductId,
      cartProductsTotalItems,
      cartProductsUniqueItems,
      data,
      lastUpdatedCartProduct,
      nonDeletedCartProducts,
      variables,
      location.id,
    ],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <CustomerNextOrderContext.Provider value={value}>{children}</CustomerNextOrderContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
