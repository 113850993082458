import { CheckBoxControl } from '@src/components/appearance/controls/CheckBox';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { TextBoxControl } from '@src/components/appearance/controls/TextBox';
import type { TAgentCustomersUserBaseFragment } from '@src/gen/graphql/bindings';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersUserActionsTypes } from '@src/modules/data/agent/customers/users/AgentCustomersUserActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TUserCustomerEditFormValues = {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  isEnabled: boolean;
};

export type TUserCustomerEditFormBase = {
  doCustomersUserCustomerUpdateAsync: TAgentCustomersUserActionsTypes['DoCustomersUserCustomerUpdateAsync'];
  user: Pick<TAgentCustomersUserBaseFragment, 'first_name' | 'id' | 'is_disabled' | 'last_name' | 'phone_number'>;
};

export type TUserCustomerEditForm = TProps<false, TUserCustomerEditFormBase, 'div'>;
export const USER_CUSTOMER_UPDATE_FORM_CLASS_NAME = 'wp-user-customer-edit-form';

export const UserCustomerEditForm = withCssToString(
  USER_CUSTOMER_UPDATE_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TUserCustomerEditForm>(
      ({ doCustomersUserCustomerUpdateAsync, user, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, USER_CUSTOMER_UPDATE_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TUserCustomerEditFormValues>(
          () => ({
            phoneNumber: user.phone_number,
            firstName: user.first_name,
            lastName: user.last_name,
            isEnabled: !user.is_disabled,
          }),
          [user.first_name, user.is_disabled, user.last_name, user.phone_number],
        );

        const handleSubmit = useCallback<TFormSubmit<TUserCustomerEditFormValues>>(
          async (values) => {
            await doCustomersUserCustomerUpdateAsync({
              phoneNumber: values.phoneNumber,
              firstName: values.firstName,
              lastName: values.lastName,
              isDisabled: !values.isEnabled,
            });
            return undefined;
          },
          [doCustomersUserCustomerUpdateAsync],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TUserCustomerEditFormValues>
              initialValues={initialValues}
              requireChanges={true}
              onSubmit={handleSubmit}
              submitButtonText='Update User'>
              <TextBoxControl id='firstName' required={true} label='First Name' />
              <TextBoxControl id='lastName' required={true} label='Last Name' />
              <TextBoxControl id='phoneNumber' required={false} phoneNumber={true} label='Phone Number' />
              <CheckBoxControl id='isEnabled' label='Enabled' />
            </Form>
          </div>
        );
      },
    ),
  ),
);
