import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { ItemButton } from '@src/components/appearance/fragments/ItemButton';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type {
  TAgentOrderShipmentsAnalyzerOrderSnapshotEntry,
  TAgentOrderShipmentsAnalyzerSource,
} from '@src/gen/shared/data/agentOrders';
import { getCombinedProductName } from '@src/gen/shared/data/snippets';
import { getSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TIcon, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderShipmentsAnalyzerSourceCardBase = {
  beginOrderSnapshotEntryView: TAgentOrderActionsTypes['BeginOrderSnapshotEntryView'];
  orderShipmentsAnalyzerSource: TAgentOrderShipmentsAnalyzerSource;
};

export type TOrderShipmentsAnalyzerSourceCard = TProps<false, TOrderShipmentsAnalyzerSourceCardBase, 'div'>;
export const ORDER_SHIPMENTS_ANALYZER_SOURCE_CARD_CLASS_NAME = 'wp-order-shipments-analyzer-source-card';

export const OrderShipmentsAnalyzerSourceCard = withCssToString(
  ORDER_SHIPMENTS_ANALYZER_SOURCE_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderShipmentsAnalyzerSourceCard>(
      ({ beginOrderSnapshotEntryView, orderShipmentsAnalyzerSource, className, ...rest }, ref): JSX.Element | null => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_SHIPMENTS_ANALYZER_SOURCE_CARD_CLASS_NAME),
          [className],
        );

        const supplierOrderReference = orderShipmentsAnalyzerSource.orderPlanGroup?.supplier_order_reference;

        return (
          <Card.Container {...rest} className={joinedClassName} flush={true} interactive={false} ref={ref}>
            <Card.Title
              title={getSourceName(orderShipmentsAnalyzerSource.source)}
              subTitle={isDef(supplierOrderReference) ? `Ref. ${supplierOrderReference}` : ''}
            />
            {orderShipmentsAnalyzerSource.orderSnapshotEntries.map((ose) => {
              return (
                <ItemButton
                  icon={getPlanGroupOrderEntryIcon(ose)}
                  item={getPlanGroupOrderEntryItem(ose)}
                  onClick={(): void =>
                    beginOrderSnapshotEntryView({
                      type: 'plan',
                      orderSnapshotEntryManager: ose.orderSnapshotEntryManager,
                    })
                  }
                  variant='card'
                />
              );
            })}
            <ItemsBanner
              items={[
                {
                  caption: 'Total',
                  text: `${orderShipmentsAnalyzerSource.shipmentStatus.total}`,
                },
                {
                  caption: 'Untracked',
                  text: `${orderShipmentsAnalyzerSource.shipmentStatus.untracked}`,
                },
                {
                  caption: 'Tracked',
                  text: `${orderShipmentsAnalyzerSource.shipmentStatus.tracked}`,
                },
                {
                  caption: 'Delivered',
                  text: `${orderShipmentsAnalyzerSource.shipmentStatus.delivered}`,
                },
              ]}
            />
          </Card.Container>
        );
      },
    ),
  ),
);

function getPlanGroupOrderEntryItem(orderSnapshotEntry: TAgentOrderShipmentsAnalyzerOrderSnapshotEntry): TItemBaseItem {
  const planProduct = orderSnapshotEntry.orderSnapshotEntryManager.mustGetPlanProduct();

  const prefix = `${planProduct.product_sku}`;
  const total = `${orderSnapshotEntry.shipmentStatus.total} Total`;
  const untracked = `${orderSnapshotEntry.shipmentStatus.untracked} Untracked`;
  const tracked = `${orderSnapshotEntry.shipmentStatus.tracked} Tracked`;
  const delivered = `${orderSnapshotEntry.shipmentStatus.delivered} Delivered`;

  return {
    caption: `${prefix} — ${total}, ${untracked}, ${tracked}, ${delivered}`,
    text: getCombinedProductName(planProduct.name, planProduct.secondary_name),
  };
}

function getPlanGroupOrderEntryIcon(orderSnapshotEntry: TAgentOrderShipmentsAnalyzerOrderSnapshotEntry): TIcon {
  if (orderSnapshotEntry.shipmentStatus.untracked > 0) {
    return 'warning';
  }

  if (orderSnapshotEntry.shipmentStatus.tracked > 0) {
    return 'waiting';
  }

  return 'apply';
}
