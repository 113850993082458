import { Card } from '@src/components/appearance/fragments/Card';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { AgentOrderEntryManager } from '@src/gen/shared/data/agentOrders';
import { getSaleUnitQuantityWithRequestAndFallbackPrefix } from '@src/gen/shared/data/snippets';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { maybeFormatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderEntryPlanProductCardBase = {
  onClick: (() => void) | null;
  orderEntryManager: AgentOrderEntryManager;
};

export type TOrderEntryPlanProductCard = TProps<false, TOrderEntryPlanProductCardBase, 'div'>;
export const ORDER_ENTRY_PLAN_PRODUCT_CARD_CLASS_NAME = 'wp-order-entry-plan-product-card';

export const OrderEntryPlanProductCard = withCssToString(
  ORDER_ENTRY_PLAN_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderEntryPlanProductCard>(
      ({ onClick, orderEntryManager, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ENTRY_PLAN_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const needsReview = orderEntryManager.needsReview();
        const planProduct = useMemo(() => orderEntryManager.mustGetPlanProduct(), [orderEntryManager]);

        const itemsFirst = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(planProduct.source),
            },
            {
              caption: 'SKU',
              text: planProduct.product_sku,
            },
            {
              caption: 'Manufacturer',
              text: planProduct.manufacturer_name,
            },
            {
              caption: 'Manufacturer SKU',
              text: planProduct.manufacturer_sku,
            },
          ],
          // @sort
          [planProduct.manufacturer_name, planProduct.manufacturer_sku, planProduct.product_sku, planProduct.source],
        );

        const itemsSecond = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Approx. Savings',
              accent: needsReview ? 'warning' : undefined,
              text: orderEntryManager.getSavingsText(),
            },
            {
              caption: 'Quantity',
              accent: needsReview ? 'warning' : undefined,
              text: getSaleUnitQuantityWithRequestAndFallbackPrefix(
                'TBD',
                orderEntryManager.orderEntry.plan_quantity,
                orderEntryManager.orderEntry.customer_edited_plan_quantity,
                planProduct.sale_unit,
              ),
            },
            {
              caption: 'Unit Price (Wellplaece)',
              text: maybeFormatDollarsCurrency(orderEntryManager.orderEntry.plan_unit_price) ?? 'TBD',
            },
            {
              caption: 'Sub Total (Wellplaece)',
              accent: needsReview ? 'warning' : undefined,
              text: maybeFormatDollarsCurrency(orderEntryManager.maybeGetPlanSubTotal()) ?? 'TBD',
            },
          ],
          // @sort
          [needsReview, orderEntryManager, planProduct.sale_unit],
        );

        return (
          <Card.Container {...rest} className={joinedClassName} flush={true} interactive={isDef(onClick)} ref={ref}>
            {isDef(onClick) ? (
              <Card.ButtonArea onClick={onClick}>
                <Card.ImageAssetArea>
                  <ImageAsset alt={planProduct.name} imageAssetPath={planProduct.image_asset_path} />
                  <Card.ContentArea>
                    <Card.Title subTitle={planProduct.secondary_name} title={planProduct.name} />
                    <Items bannerAligned={true} items={itemsFirst} />
                    <ItemsBanner items={itemsSecond} />
                  </Card.ContentArea>
                </Card.ImageAssetArea>
              </Card.ButtonArea>
            ) : (
              <Card.ImageAssetArea>
                <ImageAsset alt={planProduct.name} imageAssetPath={planProduct.image_asset_path} />
                <Card.ContentArea>
                  <Card.Title subTitle={planProduct.secondary_name} title={planProduct.name} />
                  <Items bannerAligned={true} items={itemsFirst} />
                  <ItemsBanner items={itemsSecond} />
                </Card.ContentArea>
              </Card.ImageAssetArea>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);
