import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TTypographyBase = {
  expanding?: boolean | undefined;
  rigid?: boolean | undefined;
  text: string;
};

const STitleH4 = styled('h4', {
  color: '$typographyTitleText',
  text: '$title',

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TTitle = TProps<false, TTypographyBase, 'h4'>;
export const TYPOGRAPHY_TITLE_CLASS_NAME = 'wp-typography-title';

const Title = withCssToString(
  TYPOGRAPHY_TITLE_CLASS_NAME,
  memo(
    forwardRef<HTMLHeadingElement, TTitle>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_TITLE_CLASS_NAME), [className]);

      return (
        <STitleH4 {...rest} className={joinedClassName} expanding={expanding === true} rigid={rigid === true} ref={ref}>
          {text}
        </STitleH4>
      );
    }),
  ),
);

const SSubTitleH6 = styled('h6', {
  color: '$typographySubTitleText',
  text: '$subTitle',

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TSubTitle = TProps<false, TTypographyBase, 'h6'>;
export const TYPOGRAPHY_SUB_TITLE_CLASS_NAME = 'wp-typography-sub-title';

const SubTitle = withCssToString(
  TYPOGRAPHY_SUB_TITLE_CLASS_NAME,
  memo(
    forwardRef<HTMLHeadingElement, TSubTitle>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_SUB_TITLE_CLASS_NAME), [className]);

      return (
        <SSubTitleH6
          {...rest}
          className={joinedClassName}
          expanding={expanding === true}
          rigid={rigid === true}
          ref={ref}>
          {text}
        </SSubTitleH6>
      );
    }),
  ),
);

const SRegularSpan = styled('span', {
  color: '$typographyRegularText',
  text: '$regular',

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TRegular = TProps<false, TTypographyBase, 'span'>;
export const TYPOGRAPHY_REGULAR_CLASS_NAME = 'wp-typography-regular';

const Regular = withCssToString(
  TYPOGRAPHY_REGULAR_CLASS_NAME,
  memo(
    forwardRef<HTMLSpanElement, TRegular>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_REGULAR_CLASS_NAME), [className]);

      return (
        <SRegularSpan
          {...rest}
          className={joinedClassName}
          expanding={expanding === true}
          rigid={rigid === true}
          ref={ref}>
          {text}
        </SRegularSpan>
      );
    }),
  ),
);

const SStrongSpan = styled('span', {
  color: '$typographyStrongText',
  text: '$strong',

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TStrong = TProps<false, TTypographyBase, 'span'>;
export const TYPOGRAPHY_STRONG_CLASS_NAME = 'wp-typography-strong';

const Strong = withCssToString(
  TYPOGRAPHY_STRONG_CLASS_NAME,
  memo(
    forwardRef<HTMLSpanElement, TStrong>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_STRONG_CLASS_NAME), [className]);

      return (
        <SStrongSpan
          {...rest}
          className={joinedClassName}
          expanding={expanding === true}
          rigid={rigid === true}
          ref={ref}>
          {text}
        </SStrongSpan>
      );
    }),
  ),
);

const SSmallSpan = styled('span', {
  color: '$typographySmallText',
  text: '$small',

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TSmall = TProps<false, TTypographyBase, 'span'>;
export const TYPOGRAPHY_SMALL_CLASS_NAME = 'wp-typography-small';

const Small = withCssToString(
  TYPOGRAPHY_SMALL_CLASS_NAME,
  memo(
    forwardRef<HTMLSpanElement, TSmall>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_SMALL_CLASS_NAME), [className]);

      return (
        <SSmallSpan
          {...rest}
          className={joinedClassName}
          expanding={expanding === true}
          rigid={rigid === true}
          ref={ref}>
          {text}
        </SSmallSpan>
      );
    }),
  ),
);

const SCtaSpan = styled('span', {
  color: '$typographyCtaText',
  text: '$cta',

  variants: {
    false: {
      // intentionally empty
    },
    expanding: {
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TCta = TProps<false, TTypographyBase, 'span'>;
export const TYPOGRAPHY_CTA_CLASS_NAME = 'wp-typography-cta';

const Cta = withCssToString(
  TYPOGRAPHY_CTA_CLASS_NAME,
  memo(
    forwardRef<HTMLSpanElement, TCta>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_CTA_CLASS_NAME), [className]);

      return (
        <SCtaSpan {...rest} className={joinedClassName} expanding={expanding === true} rigid={rigid === true} ref={ref}>
          {text}
        </SCtaSpan>
      );
    }),
  ),
);

const SNotificationSpan = styled('span', {
  color: '$typographyNotificationText',
  fontStyle: 'italic',
  text: '$small',

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TNotification = TProps<false, TTypographyBase, 'span'>;
export const TYPOGRAPHY_NOTIFICATION_CLASS_NAME = 'wp-typography-notification';

const Notification = withCssToString(
  TYPOGRAPHY_NOTIFICATION_CLASS_NAME,
  memo(
    forwardRef<HTMLSpanElement, TNotification>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_NOTIFICATION_CLASS_NAME), [className]);

      return (
        <SNotificationSpan
          {...rest}
          className={joinedClassName}
          expanding={expanding === true}
          rigid={rigid === true}
          ref={ref}>
          {text}
        </SNotificationSpan>
      );
    }),
  ),
);

const SCaptionSpan = styled('span', {
  color: '$typographyCaptionText',
  text: '$caption',

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TCaption = TProps<false, TTypographyBase, 'span'>;
export const TYPOGRAPHY_CAPTION_CLASS_NAME = 'wp-typography-caption';

const Caption = withCssToString(
  TYPOGRAPHY_CAPTION_CLASS_NAME,
  memo(
    forwardRef<HTMLSpanElement, TCaption>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_CAPTION_CLASS_NAME), [className]);

      return (
        <SCaptionSpan
          {...rest}
          className={joinedClassName}
          expanding={expanding === true}
          rigid={rigid === true}
          ref={ref}>
          {text}
        </SCaptionSpan>
      );
    }),
  ),
);

const SErrorCaptionDiv = styled('div', {
  color: '$typographyErrorCaptionText',
  flexGrow: 1,
  height: '$typographyErrorCaptionHeight',
  overflow: 'hidden',
  text: '$caption',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export type TErrorCaption = TProps<false, { error?: string | null | undefined }, 'span'>;
export const TYPOGRAPHY_ERROR_CAPTION_CLASS_NAME = 'wp-typography-error-caption';

const ErrorCaption = withCssToString(
  TYPOGRAPHY_ERROR_CAPTION_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TErrorCaption>(({ error, className, ...rest }, ref) => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, TYPOGRAPHY_ERROR_CAPTION_CLASS_NAME),
        [className],
      );

      return (
        <SErrorCaptionDiv {...rest} className={joinedClassName} ref={ref}>
          {error}
        </SErrorCaptionDiv>
      );
    }),
  ),
);

const SLabelLabel = styled('label', {
  color: '$typographyLabelText',
  cursor: 'pointer',
  text: '$label',

  [`${CheckBox.toString()}[disabled]+&`]: {
    cursor: 'not-allowed',
  },

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

const SLabelRequiredSpan = styled('span', {
  color: '$typographyLabelRequiredText',
});

export type TLabel = TProps<false, TTypographyBase & { required?: boolean | undefined }, 'label'>;
export const TYPOGRAPHY_LABEL_CLASS_NAME = 'wp-typography-label';

const Label = withCssToString(
  TYPOGRAPHY_LABEL_CLASS_NAME,
  memo(
    forwardRef<HTMLLabelElement, TLabel>(({ expanding, required, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_LABEL_CLASS_NAME), [className]);

      return (
        <SLabelLabel
          {...rest}
          className={joinedClassName}
          expanding={expanding === true}
          rigid={rigid === true}
          ref={ref}>
          {text}
          {required === true && <SLabelRequiredSpan>{' *'}</SLabelRequiredSpan>}
        </SLabelLabel>
      );
    }),
  ),
);

const SAnnotationSpan = styled('span', {
  color: '$typographyAnnotationText',
  text: '$annotation',

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TAnnotation = TProps<false, TTypographyBase, 'span'>;
export const TYPOGRAPHY_ANNOTATION_CLASS_NAME = 'wp-typography-annotation';

const Annotation = withCssToString(
  TYPOGRAPHY_ANNOTATION_CLASS_NAME,
  memo(
    forwardRef<HTMLSpanElement, TAnnotation>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_ANNOTATION_CLASS_NAME), [className]);

      return (
        <SAnnotationSpan
          {...rest}
          className={joinedClassName}
          expanding={expanding === true}
          rigid={rigid === true}
          ref={ref}>
          {text}
        </SAnnotationSpan>
      );
    }),
  ),
);

const SNoteSpan = styled('span', {
  color: '$typographyNoteText',
  text: '$note',
  fontStyle: 'italic',

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

export type TNote = TProps<false, TTypographyBase, 'span'>;
export const TYPOGRAPHY_NOTE_CLASS_NAME = 'wp-typography-note';

const Note = withCssToString(
  TYPOGRAPHY_NOTE_CLASS_NAME,
  memo(
    forwardRef<HTMLSpanElement, TNote>(({ expanding, rigid, text, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TYPOGRAPHY_NOTE_CLASS_NAME), [className]);

      return (
        <SNoteSpan
          {...rest}
          className={joinedClassName}
          expanding={expanding === true}
          rigid={rigid === true}
          ref={ref}>
          {text}
        </SNoteSpan>
      );
    }),
  ),
);

export const Typography = {
  Annotation,
  Caption,
  Cta,
  ErrorCaption,
  Label,
  Note,
  Notification,
  Regular,
  Small,
  Strong,
  SubTitle,
  Title,
};
