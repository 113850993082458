import { Typography } from '@src/components/appearance/basics/Typography';
import type { TCheckBoxBase } from '@src/components/appearance/controls/CheckBox';
import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import { Control } from '@src/components/appearance/controls/Control';
import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { Quantity } from '@src/components/appearance/controls/Quantity';
import { RawDatePicker } from '@src/components/appearance/controls/RawDatePicker';
import { Annotated } from '@src/components/appearance/fragments/Annotated';
import { Card } from '@src/components/appearance/fragments/Card';
import { ItemButton } from '@src/components/appearance/fragments/ItemButton';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { AgentOrderSnapshotEntryManager } from '@src/gen/shared/data/agentOrders';
import { getCombinedProductName } from '@src/gen/shared/data/snippets';
import type { ESource } from '@src/gen/shared/enums/source';
import { getSource, getSourceName } from '@src/gen/shared/enums/source';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { newDatePickerValidator } from '@src/logic/internal/data/validation';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { Dispatch, SetStateAction } from 'react';
import { Fragment, forwardRef, memo, useCallback, useMemo, useState } from 'react';

export type TOrderBackOrderAddDrawerPanelBase = {
  doOrderBackOrderAddAsync: TAgentOrderActionsTypes['DoOrderBackOrderAddAsync'];
  orderSnapshotEntryManagers: AgentOrderSnapshotEntryManager[];
};

type TOrderBackOrderAddDrawerPanelState = {
  orderSnapshotEntryManagers: AgentOrderSnapshotEntryManager[];
  sourcePhase: {
    sources: { source: ESource; isSelected: boolean }[];
  };
  productsPhase: {
    source: ESource;
    products: { orderSnapshotEntryManager: AgentOrderSnapshotEntryManager; isSelected: boolean }[];
  } | null;
  detailsPhase: {
    orderSnapshotEntryManager: AgentOrderSnapshotEntryManager;
    quantity: number;
    isEstimatedDateKnown: boolean;
    estimatedDate: string;
  } | null;
};

export type TOrderBackOrderAddDrawerPanel = TProps<false, TOrderBackOrderAddDrawerPanelBase, 'div'>;
export const ORDER_BACK_ORDER_ADD_DRAWER_PANEL_CLASS_NAME = 'wp-order-back-order-add-drawer-panel';

export const OrderBackOrderAddDrawerPanel = withCssToString(
  ORDER_BACK_ORDER_ADD_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderBackOrderAddDrawerPanel>(
      ({ doOrderBackOrderAddAsync, orderSnapshotEntryManagers, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_BACK_ORDER_ADD_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [state, setState] = useState<TOrderBackOrderAddDrawerPanelState>({
          orderSnapshotEntryManagers: orderSnapshotEntryManagers.filter((osem) => osem.isPurchasedByWellplaece()),
          sourcePhase: {
            sources: Object.keys(
              orderSnapshotEntryManagers
                .filter((osem) => osem.isPurchasedByWellplaece())
                .reduce<{ [source: string]: true }>(
                  (out, osem) => ({ ...out, [ensureDef(osem.orderSnapshotEntry.plan_source)]: true }),
                  {},
                ),
            )
              .sort((a, b) => a.localeCompare(b))
              .map((s) => ({ source: getSource(s), isSelected: false })),
          },
          productsPhase: null,
          detailsPhase: null,
        });

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Add Back-Order' />
            {!isDef(state.productsPhase) && !isDef(state.detailsPhase) && (
              <SourcePhase state={state} setState={setState} />
            )}
            {isDef(state.productsPhase) && !isDef(state.detailsPhase) && (
              <ProductsPhase state={state} setState={setState} />
            )}
            {isDef(state.productsPhase) && isDef(state.detailsPhase) && (
              <DetailsPhase doOrderBackOrderAddAsync={doOrderBackOrderAddAsync} state={state} setState={setState} />
            )}
          </Drawer.Panel>
        );
      },
    ),
  ),
);

function SourcePhase({
  state,
  setState,
}: {
  state: TOrderBackOrderAddDrawerPanelState;
  setState: Dispatch<SetStateAction<TOrderBackOrderAddDrawerPanelState>>;
}): JSX.Element {
  const handleSelect = useCallback(
    (source: ESource) => {
      setState((prevState) => ({
        ...prevState,
        sourcePhase: {
          sources: prevState.sourcePhase.sources.map((s) =>
            s.source === source ? { ...s, isSelected: true } : { ...s, isSelected: false },
          ),
        },
      }));
    },
    [setState],
  );

  const handleContinue = useCallback(() => {
    setState((prevState) => {
      const source = ensureDef(prevState.sourcePhase.sources.find((s) => s.isSelected)).source;

      return {
        ...prevState,
        productsPhase: {
          source: ensureDef(prevState.sourcePhase.sources.find((s) => s.isSelected)).source,
          products: prevState.orderSnapshotEntryManagers
            .filter((osem) => osem.orderSnapshotEntry.plan_source === source)
            .map((osem) => ({
              orderSnapshotEntryManager: osem,
              isSelected: false,
            })),
        },
      };
    });
  }, [setState]);

  const canContinue = useMemo(
    () => isDef(state.sourcePhase.sources.find((s) => s.isSelected)),
    [state.sourcePhase.sources],
  );

  return (
    <Fragment>
      <Drawer.ScrollContent>
        <Drawer.Group title='Select Source'>
          {state.sourcePhase.sources.map((s) => (
            <ControlButton
              icon={s.isSelected ? 'apply' : undefined}
              text={getSourceName(s.source)}
              onClick={(): void => handleSelect(s.source)}
            />
          ))}
        </Drawer.Group>
      </Drawer.ScrollContent>
      <Drawer.Footer
        buttonAction={{
          isAsync: false,
          onClick: handleContinue,
          variant: canContinue ? 'default' : 'disabled',
          text: 'Continue',
        }}
      />
    </Fragment>
  );
}

function ProductsPhase({
  state,
  setState,
}: {
  state: TOrderBackOrderAddDrawerPanelState;
  setState: Dispatch<SetStateAction<TOrderBackOrderAddDrawerPanelState>>;
}): JSX.Element {
  const handleSelect = useCallback(
    (orderSnapshotEntryId: string) => {
      setState((prevState) => ({
        ...prevState,
        productsPhase: {
          ...ensureDef(prevState.productsPhase),
          products: ensureDef(prevState.productsPhase).products.map((p) =>
            p.orderSnapshotEntryManager.orderSnapshotEntry.id === orderSnapshotEntryId
              ? { ...p, isSelected: true }
              : { ...p, isSelected: false },
          ),
        },
      }));
    },
    [setState],
  );

  const handleBack = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      productsPhase: null,
    }));
  }, [setState]);

  const handleContinue = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        detailsPhase: {
          orderSnapshotEntryManager: ensureDef(prevState.productsPhase?.products.find((p) => p.isSelected))
            .orderSnapshotEntryManager,
          quantity: 0,
          isEstimatedDateKnown: true,
          estimatedDate: '',
        },
      };
    });
  }, [setState]);

  const canContinue = useMemo(
    () => isDef(ensureDef(state.productsPhase).products.find((s) => s.isSelected)),
    [state.productsPhase],
  );

  return (
    <Fragment>
      <Drawer.ScrollContent>
        <Drawer.Group title='Select Product'>
          {state.productsPhase?.products.map((p) => {
            const planProduct = p.orderSnapshotEntryManager.mustGetPlanProduct();

            return (
              <ItemButton
                key={p.orderSnapshotEntryManager.orderSnapshotEntry.id}
                icon={p.isSelected ? 'apply' : undefined}
                item={{
                  caption: `${getSourceName(getSource(planProduct.source))} · ${planProduct.product_sku}`,
                  text: getCombinedProductName(planProduct.name, planProduct.secondary_name),
                }}
                onClick={(): void => handleSelect(p.orderSnapshotEntryManager.orderSnapshotEntry.id)}
                variant='control'
              />
            );
          })}
        </Drawer.Group>
      </Drawer.ScrollContent>
      <Drawer.Footer
        buttonAction={{
          isAsync: false,
          onClick: handleContinue,
          text: 'Continue',
          variant: canContinue ? 'default' : 'disabled',
        }}
        secondaryButtonAction={{
          isAsync: false,
          onClick: handleBack,
          text: 'Go Back',
          variant: 'secondary',
        }}
      />
    </Fragment>
  );
}

const SDetailsDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$controlGap',
});

function DetailsPhase({
  doOrderBackOrderAddAsync,
  state,
  setState,
}: {
  doOrderBackOrderAddAsync: TAgentOrderActionsTypes['DoOrderBackOrderAddAsync'];
  state: TOrderBackOrderAddDrawerPanelState;
  setState: Dispatch<SetStateAction<TOrderBackOrderAddDrawerPanelState>>;
}): JSX.Element {
  const handleQuantityChange = useCallback(
    (newValue: number) => {
      setState((prevState) => ({
        ...prevState,
        detailsPhase: {
          ...ensureDef(prevState.detailsPhase),
          quantity: newValue,
        },
      }));
    },
    [setState],
  );

  const handleIsEstimatedDateKnownChange = useCallback<NonNullable<TCheckBoxBase['onCheckedChange']>>(
    (newValue) => {
      setState((prevState) => ({
        ...prevState,
        detailsPhase: {
          ...ensureDef(prevState.detailsPhase),
          isEstimatedDateKnown: newValue === true,
        },
      }));
    },
    [setState],
  );

  const handleEstimatedDateChange = useCallback(
    (newValue: string) => {
      setState((prevState) => ({
        ...prevState,
        detailsPhase: {
          ...ensureDef(prevState.detailsPhase),
          estimatedDate: newValue,
        },
      }));
    },
    [setState],
  );

  const estimatedDateError = useMemo(
    () => newDatePickerValidator(true)(ensureDef(state.detailsPhase).estimatedDate),
    [state.detailsPhase],
  );

  const handleBack = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      detailsPhase: null,
    }));
  }, [setState]);

  const handleSave = useCallback(
    async () => {
      const detailsPhase = ensureDef(state.detailsPhase);

      await doOrderBackOrderAddAsync({
        orderSnapshotEntryId: detailsPhase.orderSnapshotEntryManager.orderSnapshotEntry.id,
        quantity: detailsPhase.quantity,
        estimatedDate: detailsPhase.isEstimatedDateKnown ? detailsPhase.estimatedDate : null,
      });
    },
    // @sort
    [doOrderBackOrderAddAsync, state.detailsPhase],
  );

  const canSave = useMemo(
    () => !isDef(estimatedDateError) && ensureDef(state.detailsPhase).quantity > 0,
    [state.detailsPhase, estimatedDateError],
  );

  return (
    <Fragment>
      <Drawer.ScrollContent>
        <Drawer.Group title='Input Details'>
          <Card.Container flush={true} interactive={false} variant='form'>
            <SDetailsDiv>
              <Typography.Label
                expanding={true}
                htmlFor='order-back-order-quantity-edit'
                required={true}
                rigid={true}
                text='Quantity'
              />
              <Annotated
                css={{ marginBottom: '12px' }}
                text={`(${state.detailsPhase?.orderSnapshotEntryManager.orderSnapshotEntry.plan_quantity} in most recent invoice)`}>
                <Quantity
                  id='order-back-order-quantity-edit'
                  value={ensureDef(state.detailsPhase).quantity}
                  onChange={handleQuantityChange}
                />
              </Annotated>
            </SDetailsDiv>
            <SDetailsDiv>
              <Control id='order-back-order-is-estimated-date-known' label='Is Estimated Date Known?' required={false}>
                <CheckBox
                  id='order-back-order-is-estimated-date-known'
                  checked={ensureDef(state.detailsPhase).isEstimatedDateKnown}
                  onCheckedChange={handleIsEstimatedDateKnownChange}
                  css={{ alignSelf: 'flex-start' }}
                />
              </Control>
            </SDetailsDiv>
            {state.detailsPhase?.isEstimatedDateKnown === true && (
              <SDetailsDiv>
                <Control
                  error={estimatedDateError}
                  id='order-back-order-estimated-date-edit'
                  label='Estimated Date'
                  required={true}>
                  <RawDatePicker
                    id='order-back-order-estimated-date-edit'
                    value={ensureDef(state.detailsPhase).estimatedDate}
                    onChange={handleEstimatedDateChange}
                  />
                </Control>
              </SDetailsDiv>
            )}
          </Card.Container>
        </Drawer.Group>
      </Drawer.ScrollContent>
      <Drawer.Footer
        buttonAction={{
          isAsync: true,
          onClick: handleSave,
          text: 'Save',
          variant: canSave ? 'default' : 'disabled',
        }}
        secondaryButtonAction={{
          isAsync: false,
          onClick: handleBack,
          text: 'Go Back',
          variant: 'secondary',
        }}
      />
    </Fragment>
  );
}
