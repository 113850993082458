import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import type {
  TAgentOrderPaymentCompleteFragment,
  TCustomerOrderPaymentCompleteFragment,
} from '@src/gen/graphql/bindings';
import { getFullName } from '@src/gen/shared/data/snippets';
import { maybeGetOrderPaymentTypeLabel } from '@src/gen/shared/enums/orderPaymentType';
import { formatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderPaymentCardBase = {
  onDeleteAsyncNotify: (() => Promise<void>) | null;
  orderPayment: TAgentOrderPaymentCompleteFragment | TCustomerOrderPaymentCompleteFragment;
};

export type TOrderPaymentCard = TProps<false, TOrderPaymentCardBase, 'div'>;
export const ORDER_PAYMENT_CARD_CLASS_NAME = 'wp-order-payment-card';

export const OrderPaymentCard = withCssToString(
  ORDER_PAYMENT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderPaymentCard>(
      ({ onDeleteAsyncNotify, orderPayment, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_PAYMENT_CARD_CLASS_NAME), [className]);

        const items = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: `Amount (${maybeGetOrderPaymentTypeLabel(orderPayment.type)})`,
              text: formatDollarsCurrency(orderPayment.amount),
            },
            {
              caption: 'Processed By',
              text: getFullName(orderPayment.creator),
            },
            {
              caption: 'Processed On',
              text: { asDate: 'on', at: orderPayment.created_at },
            },
          ],
          // @sort
          [orderPayment.amount, orderPayment.created_at, orderPayment.creator, orderPayment.type],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () =>
            getAvailableIconButtonActions([
              {
                isAsync: true,
                onClick: onDeleteAsyncNotify ?? undefined,
                icon: 'trash',
              },
            ]),
          [onDeleteAsyncNotify],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flush={true}
            iconButtonActions={iconButtonActions}
            interactive={false}
            ref={ref}>
            <Card.Title title={maybeGetOrderPaymentTypeLabel(orderPayment.type)} />
            <Items items={items} />
          </Card.Container>
        );
      },
    ),
  ),
);
