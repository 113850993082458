import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import {
  OrganizationWithStatisticsCard,
  OrganizationWithStatisticsCardLoader,
} from '@src/components/mixins/cards/OrganizationWithStatisticsCard';
import type { TAgentCustomersOrganizationBaseWithStatisticsFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentCustomersOrganizationsActions } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationsActionsProvider';
import { useAgentCustomersOrganizationsWithStatistics } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationsWithStatisticsProvider';
import { encodeAgentCustomersOrganizationOverviewPath } from '@src/modules/routing/agent';
import { Fragment, memo, useMemo } from 'react';

export function AgentCustomersOrganizationsPart(): JSX.Element {
  const { organizations, loaderRef } = useAgentCustomersOrganizationsWithStatistics();
  const { beginOrganizationAdd, beginOrganizationAddSimplified } = useAgentCustomersOrganizationsActions();

  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav
          title='Organizations'
          IconButtonElement={
            isDef(beginOrganizationAdd) ? <IconButton icon='add' onClick={beginOrganizationAdd} /> : undefined
          }
          ExtraIconButtonElement={
            isDef(beginOrganizationAddSimplified) ? (
              <IconButton icon='rocket' onClick={beginOrganizationAddSimplified} />
            ) : undefined
          }
        />
      </Structure.Content>
      <Structure.ScrollContent>
        <Structure.Group>
          {organizations.map((o) => (
            <CardComponent key={o.id} organization={o} />
          ))}
          {isDef(loaderRef) && <Illustrations.Spinner ref={loaderRef} />}
        </Structure.Group>
      </Structure.ScrollContent>
    </Fragment>
  );
}

function CardComponent({
  organization,
}: {
  organization: TAgentCustomersOrganizationBaseWithStatisticsFragment;
}): JSX.Element {
  const to = useMemo(() => encodeAgentCustomersOrganizationOverviewPath(organization.id), [organization.id]);
  return <OrganizationWithStatisticsCard organization={organization} to={to} />;
}

export const AgentCustomersOrganizationsPartLoader = memo(
  (): JSX.Element => (
    <Fragment>
      <Structure.Content>
        <SecondaryNav title='Organizations' />
      </Structure.Content>
      <Structure.ScrollContentLoader>
        <Structure.Stack>
          <Structure.GroupLoader hasTitle={false}>
            <OrganizationWithStatisticsCardLoader />
            <OrganizationWithStatisticsCardLoader />
            <OrganizationWithStatisticsCardLoader />
            <OrganizationWithStatisticsCardLoader />
            <OrganizationWithStatisticsCardLoader />
            <OrganizationWithStatisticsCardLoader />
            <OrganizationWithStatisticsCardLoader />
            <OrganizationWithStatisticsCardLoader />
            <OrganizationWithStatisticsCardLoader />
            <OrganizationWithStatisticsCardLoader />
          </Structure.GroupLoader>
        </Structure.Stack>
      </Structure.ScrollContentLoader>
    </Fragment>
  ),
);
