import { ensureNotEmptyString } from '@src/gen/shared/utils/types';
import type { TConfig } from '@src/modules/config/ConfigProvider';
import assert from 'assert';

export function parseConfigFromEnv(): TConfig {
  const WP_ENTRY_POINT = ensureNotEmptyString(process.env['WP_ENTRY_POINT']);
  assert(WP_ENTRY_POINT === 'app' || WP_ENTRY_POINT === 'showcase', 'Invalid WP_ENTRY_POINT.');

  const WP_AMPLITUDE_API_KEY = ensureNotEmptyString(process.env['WP_AMPLITUDE_API_KEY']);
  assert(WP_AMPLITUDE_API_KEY !== '', 'Invalid WP_AMPLITUDE_API_KEY.');

  const WP_STAGE = ensureNotEmptyString(process.env['WP_STAGE']);
  assert(WP_STAGE === 'local' || WP_STAGE === 'staging' || WP_STAGE === 'prod', 'Invalid WP_STAGE.');

  const NODE_ENV = ensureNotEmptyString(process.env['NODE_ENV']);
  assert(NODE_ENV === 'development' || NODE_ENV === 'production', 'Invalid NODE_ENV.');

  const WP_VERSION = ensureNotEmptyString(process.env['WP_VERSION']);
  const WP_HASURA_BASE_URL = ensureNotEmptyString(process.env['WP_HASURA_BASE_URL']);
  const WP_STORAGE_PUBLIC_BASE_URL = ensureNotEmptyString(process.env['WP_STORAGE_PUBLIC_BASE_URL']);
  const WP_WPX_STORAGE_PUBLIC_BASE_URL = ensureNotEmptyString(process.env['WP_WPX_STORAGE_PUBLIC_BASE_URL']);
  const WP_SENTRY_DSN = ensureNotEmptyString(process.env['WP_SENTRY_DSN']);

  return {
    NODE_ENV,
    WP_ENTRY_POINT,
    WP_STAGE,
    WP_VERSION,
    WP_HASURA_BASE_URL,
    WP_STORAGE_PUBLIC_BASE_URL,
    WP_WPX_STORAGE_PUBLIC_BASE_URL,
    WP_SENTRY_DSN,
    WP_AMPLITUDE_API_KEY,
  };
}
