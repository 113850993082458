import type { TEmptyObject } from '@src/modules/design/theme';
import { globalStyles } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { Fragment } from 'react';

export function DesignProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  globalStyles();

  return <Fragment>{children}</Fragment>;
}
