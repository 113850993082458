import { Typography } from '@src/components/appearance/basics/Typography';
import type { TDialog } from '@src/components/appearance/structure/Dialog';
import { Dialog } from '@src/components/appearance/structure/Dialog';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TConfirmationDialogPanelBase = {
  title: string;
  message: string;
  action: string;
  onConfirm: () => Promise<void>;
};

export type TConfirmationDialogPanel = TProps<false, TConfirmationDialogPanelBase, 'div'>;
export const CONFIRMATION_DIALOG_PANEL_CLASS_NAME = 'wp-confirmation-dialog-panel';

export const ConfirmationDialogPanel = withCssToString(
  CONFIRMATION_DIALOG_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TConfirmationDialogPanel>(
      ({ title, message, action, onConfirm, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, CONFIRMATION_DIALOG_PANEL_CLASS_NAME),
          [className],
        );

        const buttonAction = useMemo<TDialog['FooterBase']['buttonAction']>(
          () => ({
            isAsync: true,
            text: action,
            onClick: onConfirm,
          }),
          // @sort
          [action, onConfirm],
        );

        return (
          <Dialog.Panel {...rest} className={joinedClassName} ref={ref}>
            <Dialog.Header title={title} />
            <Typography.Small text={message} />
            <Dialog.Footer buttonAction={buttonAction} />
          </Dialog.Panel>
        );
      },
    ),
  ),
);
