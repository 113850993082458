import type { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useAgentOrganizationPickerDrawer } from '@src/modules/data/agent/global/overlays/AgentOrganizationPickerDrawerProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { encodeAgentOrdersPathWithConfig, useAgentOrdersRouteParams } from '@src/modules/routing/agent';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export type TAgentOrdersFilterActionsTypes = {
  BeginOrganizationFilter: () => void;
  ClearOrganizationFilter: () => void;
  DoSetStatusFilter: (args: { status: EOrderStatus | null }) => void;
  DoSetIsAwaitingPrebillApprovalFilter: (args: { isAwaitingPrebillApproval: boolean | null }) => void;
  DoSetIsBlockedOnCustomerFilter: (args: { isBlockedOnCustomer: boolean | null }) => void;
  DoSetIsEmergencyFilter: (args: { isEmergency: boolean | null }) => void;
  DoSetIsPaidFilter: (args: { isPaid: boolean | null }) => void;
};

export type TAgentOrdersFilterActionsContext = {
  beginOrganizationFilter: TAgentOrdersFilterActionsTypes['BeginOrganizationFilter'];
  clearOrganizationFilter: TAgentOrdersFilterActionsTypes['ClearOrganizationFilter'];
  doSetStatusFilter: TAgentOrdersFilterActionsTypes['DoSetStatusFilter'];
  doSetIsAwaitingPrebillApprovalFilter: TAgentOrdersFilterActionsTypes['DoSetIsAwaitingPrebillApprovalFilter'];
  doSetIsBlockedOnCustomerFilter: TAgentOrdersFilterActionsTypes['DoSetIsBlockedOnCustomerFilter'];
  doSetIsEmergencyFilter: TAgentOrdersFilterActionsTypes['DoSetIsEmergencyFilter'];
  doSetIsPaidFilter: TAgentOrdersFilterActionsTypes['DoSetIsPaidFilter'];
};

export const { Context: AgentOrdersFilterActionsContext, useContext: useAgentOrdersFilterActions } =
  createRequiredContext<TAgentOrdersFilterActionsContext>();

export function AgentOrdersFilterActionsProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const beginOrganizationFilter = useBeginOrganizationFilter();
  const clearOrganizationFilter = useClearOrganizationFilter();
  const doSetStatusFilter = useDoSetStatusFilter();
  const doSetIsAwaitingPrebillApprovalFilter = useDoSetIsAwaitingPrebillApprovalFilter();
  const doSetIsBlockedOnCustomerFilter = useDoSetIsBlockedOnCustomerFilter();
  const doSetIsEmergencyFilter = useDoSetIsEmergencyFilter();
  const doSetIsPaidFilter = useDoSetIsPaidFilter();

  const value = useMemo<TAgentOrdersFilterActionsContext>(
    () => ({
      beginOrganizationFilter,
      clearOrganizationFilter,
      doSetStatusFilter,
      doSetIsAwaitingPrebillApprovalFilter,
      doSetIsBlockedOnCustomerFilter,
      doSetIsEmergencyFilter,
      doSetIsPaidFilter,
    }),

    // @sort
    [
      beginOrganizationFilter,
      clearOrganizationFilter,
      doSetIsAwaitingPrebillApprovalFilter,
      doSetIsBlockedOnCustomerFilter,
      doSetIsEmergencyFilter,
      doSetIsPaidFilter,
      doSetStatusFilter,
    ],
  );

  return <AgentOrdersFilterActionsContext.Provider value={value}>{children}</AgentOrdersFilterActionsContext.Provider>;
}

function useBeginOrganizationFilter(): TAgentOrdersFilterActionsTypes['BeginOrganizationFilter'] {
  const navigate = useNavigate();
  const routeParams = useAgentOrdersRouteParams();
  const { beginOrganizationPick } = useAgentOrganizationPickerDrawer();

  return useCallback<TAgentOrdersFilterActionsTypes['BeginOrganizationFilter']>(() => {
    beginOrganizationPick({
      callback: async (organization) => {
        navigate(
          encodeAgentOrdersPathWithConfig({
            ...routeParams.config,
            organizationId: organization.id,
          }),
        );
      },
    });
  }, [beginOrganizationPick, navigate, routeParams.config]);
}

function useClearOrganizationFilter(): TAgentOrdersFilterActionsTypes['ClearOrganizationFilter'] {
  const navigate = useNavigate();
  const routeParams = useAgentOrdersRouteParams();

  return useCallback<TAgentOrdersFilterActionsTypes['ClearOrganizationFilter']>(() => {
    navigate(
      encodeAgentOrdersPathWithConfig({
        ...routeParams.config,
        organizationId: null,
      }),
    );
  }, [navigate, routeParams.config]);
}

function useDoSetStatusFilter(): TAgentOrdersFilterActionsTypes['DoSetStatusFilter'] {
  const navigate = useNavigate();
  const routeParams = useAgentOrdersRouteParams();

  return useCallback<TAgentOrdersFilterActionsTypes['DoSetStatusFilter']>(
    ({ status }) => {
      navigate(
        encodeAgentOrdersPathWithConfig({
          ...routeParams.config,
          status,
        }),
      );
    },
    [navigate, routeParams.config],
  );
}

function useDoSetIsAwaitingPrebillApprovalFilter(): TAgentOrdersFilterActionsTypes['DoSetIsAwaitingPrebillApprovalFilter'] {
  const navigate = useNavigate();
  const routeParams = useAgentOrdersRouteParams();

  return useCallback<TAgentOrdersFilterActionsTypes['DoSetIsAwaitingPrebillApprovalFilter']>(
    ({ isAwaitingPrebillApproval }) => {
      navigate(
        encodeAgentOrdersPathWithConfig({
          ...routeParams.config,
          isAwaitingPrebillApproval,
        }),
      );
    },
    [navigate, routeParams.config],
  );
}

function useDoSetIsBlockedOnCustomerFilter(): TAgentOrdersFilterActionsTypes['DoSetIsBlockedOnCustomerFilter'] {
  const navigate = useNavigate();
  const routeParams = useAgentOrdersRouteParams();

  return useCallback<TAgentOrdersFilterActionsTypes['DoSetIsBlockedOnCustomerFilter']>(
    ({ isBlockedOnCustomer }) => {
      navigate(
        encodeAgentOrdersPathWithConfig({
          ...routeParams.config,
          isBlockedOnCustomer,
        }),
      );
    },
    [navigate, routeParams.config],
  );
}

function useDoSetIsEmergencyFilter(): TAgentOrdersFilterActionsTypes['DoSetIsEmergencyFilter'] {
  const navigate = useNavigate();
  const routeParams = useAgentOrdersRouteParams();

  return useCallback<TAgentOrdersFilterActionsTypes['DoSetIsEmergencyFilter']>(
    ({ isEmergency }) => {
      navigate(
        encodeAgentOrdersPathWithConfig({
          ...routeParams.config,
          isEmergency,
        }),
      );
    },
    [navigate, routeParams.config],
  );
}

function useDoSetIsPaidFilter(): TAgentOrdersFilterActionsTypes['DoSetIsPaidFilter'] {
  const navigate = useNavigate();
  const routeParams = useAgentOrdersRouteParams();

  return useCallback<TAgentOrdersFilterActionsTypes['DoSetIsPaidFilter']>(
    ({ isPaid }) => {
      navigate(
        encodeAgentOrdersPathWithConfig({
          ...routeParams.config,
          isPaid,
        }),
      );
    },
    [navigate, routeParams.config],
  );
}
