import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderOriginalCartItemsBanner } from '@src/components/mixins/banners/OrderOriginalCartItemsBanner';
import { OrderOriginalCartProductCard } from '@src/components/mixins/cards/OrderOriginalCartProductCard';
import type {
  TAgentOrderCompleteFragment,
  TAgentOrderProductBaseFragment,
  TCustomerOrderCompleteFragment,
  TCustomerOrderProductBaseFragment,
} from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderOriginalCartStructureGroupBase = {
  beginOrderProductView:
    | TAgentOrderActionsTypes['BeginOrderProductView']
    | TCustomerOrderActionsTypes['BeginOrderProductView'];
  downloadOrderExportOriginalCartAsyncNotify?:
    | TAgentOrderActionsTypes['DownloadOrderExportOriginalCartAsyncNotify']
    | undefined;
  order: Pick<TAgentOrderCompleteFragment | TCustomerOrderCompleteFragment, 'created_at' | 'creator'> & {
    order_products: (TAgentOrderProductBaseFragment | TCustomerOrderProductBaseFragment)[];
  };
};

export type TOrderOriginalCartStructureGroup = TProps<false, TOrderOriginalCartStructureGroupBase, 'div'>;
export const ORDER_ORIGINAL_CART_STRUCTURE_GROUP = 'wp-order-original-cart-structure-group';

export const OrderOriginalCartStructureGroup = withCssToString(
  ORDER_ORIGINAL_CART_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderOriginalCartStructureGroup>(
      (
        { beginOrderProductView, downloadOrderExportOriginalCartAsyncNotify, order, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ORIGINAL_CART_STRUCTURE_GROUP),
          [className],
        );

        const handleDownloadAsync = useMemo(
          () =>
            isDef(downloadOrderExportOriginalCartAsyncNotify)
              ? async (): Promise<void> => await downloadOrderExportOriginalCartAsyncNotify()
              : undefined,
          [downloadOrderExportOriginalCartAsyncNotify],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () => getAvailableIconButtonActions([{ isAsync: true, icon: 'download', onClick: handleDownloadAsync }]),
          [handleDownloadAsync],
        );

        return (
          <Structure.Group
            {...rest}
            className={joinedClassName}
            iconButtonActions={iconButtonActions}
            ref={ref}
            title='Original Cart'>
            <OrderOriginalCartItemsBanner order={order} />
            {order.order_products.map((op) => (
              <OrderOriginalCartProductCard
                key={op.product_id}
                onClick={(): void => beginOrderProductView({ productId: op.product_id })}
                orderProduct={op}
              />
            ))}
          </Structure.Group>
        );
      },
    ),
  ),
);
