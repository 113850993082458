import { mustParseDatePickerDate } from '@src/gen/shared/utils/converters';

export type TStringValidator = (value: string | null | undefined) => string | null | undefined;

export function newStringValidator(
  isRequired: boolean,
  isEmail: boolean,
  isOrderCode: boolean,
  isStripeCustomerId: boolean,
  isPhoneNumber: boolean,
): TStringValidator {
  return (value) => {
    value = value?.trim() ?? '';

    if (isRequired && value === '') {
      return 'Please provide a value.';
    }

    if (!isRequired && value === '') {
      return null;
    }

    if (isEmail && !/^[^ \t]+@[^ \t]+\.[^ \t]+$/.test(value)) {
      return 'Please provide a valid email address.';
    }

    if (isOrderCode && !/^[A-Z]{3}[A-Z0-9]{3}$/.test(value)) {
      return 'Please provide a valid order code (e.g. ABCDEF).';
    }

    if (isStripeCustomerId && !/^cus_[A-Za-z0-9]{14}$/.test(value)) {
      return 'Please provide a valid Stripe customer ID (e.g. cus_2LaWX13kKZ3oJx).';
    }

    if (isPhoneNumber && !/^\+[1-9]\d{1,14}$/.test(value)) {
      return 'Please provide a valid phone number (e.g. +12223334444)';
    }

    return null;
  };
}

export function newDatePickerValidator(isRequired: boolean): TStringValidator {
  return (value) => {
    value = value?.trim() ?? '';

    if (isRequired && value === '') {
      return 'Please provide a value.';
    }

    try {
      mustParseDatePickerDate(value);
      return null;
    } catch {
      return 'Please provide a valid date.';
    }
  };
}
