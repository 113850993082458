import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { TextBox } from '@src/components/appearance/controls/TextBox';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { usePrevious, withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';

const SDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

export type TSearchFilterBase = {
  searchFilter: string;
  onApply: (searchFilter: string) => void;
  placeholder?: string | undefined;
};

export type TSearchFilter = TProps<false, TSearchFilterBase, 'div'>;
export const SEARCH_FILTER_CLASS_NAME = 'wp-search-filter';

export const SearchFilter = withCssToString(
  SEARCH_FILTER_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TSearchFilter>(
      ({ className, searchFilter, onApply, placeholder, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, SEARCH_FILTER_CLASS_NAME), [className]);
        const [internalSearchFilter, setInternalSearchFilter] = useState(searchFilter);
        const prevSearchFilter = usePrevious(searchFilter);

        const handleChange = useCallback(
          (e: ChangeEvent<HTMLInputElement>) => setInternalSearchFilter(e.target.value),
          [],
        );

        const handleKeyPress = useCallback(
          (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter') {
              onApply(internalSearchFilter);
            }
          },
          // @sort
          [internalSearchFilter, onApply],
        );

        const handleClear = useCallback(() => {
          onApply('');
        }, [onApply]);

        useEffect(
          () => {
            if (
              prevSearchFilter !== undefined &&
              prevSearchFilter !== searchFilter &&
              searchFilter !== internalSearchFilter
            ) {
              setInternalSearchFilter(searchFilter);
            }
          },
          // @sort
          [internalSearchFilter, prevSearchFilter, searchFilter],
        );

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            {searchFilter !== '' ? (
              <ControlButton icon='close' onClick={handleClear} text={searchFilter} />
            ) : (
              <TextBox
                placeholder={placeholder}
                icon='search'
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                type='text'
                value={internalSearchFilter}
              />
            )}
          </SDiv>
        );
      },
    ),
  ),
);
