import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TIcon, TProps } from '@src/modules/design/theme';
import { getIconComponent, styled, theme } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SOuterDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$bannerGap',
  padding: '$bannerPadding',

  variants: {
    accent: {
      default: {
        backgroundColor: '$bannerDefaultBackground',
      },
      success: {
        backgroundColor: '$bannerSuccessBackground',
      },
      warning: {
        backgroundColor: '$bannerWarningBackground',
      },
      error: {
        backgroundColor: '$bannerErrorBackground',
      },
    },
  },
});

const STopDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$bannerGap',
});

const SChildrenDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$bannerChildrenGap',
});

export type TBannerBase = {
  icon: TIcon;
  accent?: 'default' | 'error' | 'success' | 'warning' | undefined;
  message: string;
};

export type TBanner = TProps<true, TBannerBase, 'div'>;
export const BANNER_CLASS_NAME = 'wp-banner';

export const Banner = withCssToString(
  BANNER_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TBanner>(({ icon, accent, message, className, children, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, BANNER_CLASS_NAME), [className]);
      const IconComponent = getIconComponent(icon);
      const iconStyle = useMemo(() => ({ flexShrink: 0 }), []);

      return (
        <SOuterDiv {...rest} accent={accent ?? 'default'} className={joinedClassName} ref={ref}>
          <STopDiv>
            <IconComponent
              height={theme.sizes.bannerIconSize.value}
              style={iconStyle}
              width={theme.sizes.bannerIconSize.value}
            />
            <Typography.Small text={message} />
          </STopDiv>
          {isDef(children) && <SChildrenDiv>{children}</SChildrenDiv>}
        </SOuterDiv>
      );
    }),
  ),
);

export const BannerLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <SOuterDiv accent='default' ref={ref}>
        <Illustrations.Loader darker={true} height={24} uniqueKey='wp-banner-loader-icon' width={24}>
          <rect x='0' y='0' rx='0' ry='0' width='24' height='24' />
        </Illustrations.Loader>
        <Illustrations.Loader darker={true} height={24} uniqueKey='wp-banner-loader-message' width={224}>
          <rect x='0' y='0' rx='0' ry='0' width='192' height='9' />
          <rect x='0' y='15' rx='0' ry='0' width='224' height='9' />
        </Illustrations.Loader>
      </SOuterDiv>
    ),
  ),
);
