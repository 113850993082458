import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EOrderStatus {
  RECEIVED = 'received',
  PREPARING = 'preparing',
  PROCESSING = 'processing',
  SHIPPING = 'shipping',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

const ORDER_STATUS_LOOKUP = mapValues(invert(EOrderStatus), () => true);

export function getOrderStatus(rawOrderStatus: string): EOrderStatus {
  assert(ORDER_STATUS_LOOKUP[rawOrderStatus], `Unknown order status: "${rawOrderStatus}".`);
  return rawOrderStatus as EOrderStatus; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetOrderStatus(rawOrderStatus: string): EOrderStatus | undefined {
  try {
    return getOrderStatus(rawOrderStatus);
  } catch {
    return undefined;
  }
}

const ORDER_STATUS_LABELS: { [key in EOrderStatus]: string } = {
  [EOrderStatus.RECEIVED]: 'Received',
  [EOrderStatus.PREPARING]: 'Preparing',
  [EOrderStatus.PROCESSING]: 'Processing',
  [EOrderStatus.SHIPPING]: 'Shipping',
  [EOrderStatus.COMPLETED]: 'Completed',
  [EOrderStatus.CANCELED]: 'Canceled',
};

export function maybeGetOrderStatusLabel(rawOrderStatusLabel: string): string {
  const orderStatus = maybeGetOrderStatus(rawOrderStatusLabel);
  return orderStatus !== undefined ? ensureDef(ORDER_STATUS_LABELS[orderStatus]) : rawOrderStatusLabel;
}
