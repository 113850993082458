import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { THEME_COLORS } from '@src/gen/shared/utils/design';
import { styled } from '@src/modules/design/theme';
import type { ComponentProps } from 'react';
import { memo } from 'react';

export type TTabSelectBase = {
  onChange: NonNullable<ComponentProps<'select'>['onChange']>;
  value: string;
};

export const TabSelect = memo(
  styled('select', {
    all: 'unset',

    alignItems: 'center',
    backgroundImage: Illustrations.getChevronDownDataUrl(THEME_COLORS.Black),
    backgroundPosition: 'top 50% right 0px',
    backgroundRepeat: 'no-repeat',
    borderBottomColor: '$gray3',
    borderBottomStyle: '$regular',
    borderBottomWidth: '$regular',
    color: '$selectText',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingRight: '$selectPaddingRight',
    text: '$subTitle',
    whiteSpace: 'nowrap',

    '&:focus': {
      borderBottomColor: '$gray4',
    },

    '& > option': {
      text: '$small',
    },
  }),
);
