import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderBaseGroupsStructureGroup } from '@src/components/mixins/groups/OrderBaseGroupsStructureGroup';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';

export function AgentOrderWorksheetBaseOrdersPart(): JSX.Element {
  const { order, orderManager } = useAgentOrder();
  const { beginOrderBaseGroupEdit, beginOrderEntryBaseProductView, beginOrderEntryEdit } = useAgentOrderActions();

  return (
    <Structure.ScrollContent skipSeparator={true}>
      <OrderBaseGroupsStructureGroup
        beginOrderBaseGroupEdit={beginOrderBaseGroupEdit}
        beginOrderEntryBaseProductView={beginOrderEntryBaseProductView}
        beginOrderEntryEdit={beginOrderEntryEdit}
        orderBaseGroupManagers={orderManager.orderBaseGroupManagers}
        orderBaseGroups={order.order_base_groups}
        isEditable={!orderManager.hasSnapshotPrebillPending()}
      />
    </Structure.ScrollContent>
  );
}
