import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EOrderPaymentType {
  CHARGE = 'charge',
  REFUND = 'refund',
}

const ORDER_PAYMENT_TYPE_LOOKUP = mapValues(invert(EOrderPaymentType), () => true);

export function getOrderPaymentType(rawOrderPaymentType: string): EOrderPaymentType {
  assert(ORDER_PAYMENT_TYPE_LOOKUP[rawOrderPaymentType], `Unknown order payment type: "${rawOrderPaymentType}".`);
  return rawOrderPaymentType as EOrderPaymentType; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetOrderPaymentType(rawOrderPaymentType: string): EOrderPaymentType | undefined {
  try {
    return getOrderPaymentType(rawOrderPaymentType);
  } catch {
    return undefined;
  }
}

export const ORDER_PAYMENT_TYPE_LABELS: { [key in EOrderPaymentType]: string } = {
  [EOrderPaymentType.CHARGE]: 'Charge',
  [EOrderPaymentType.REFUND]: 'Refund',
};

export function maybeGetOrderPaymentTypeLabel(rawOrderPaymentType: string): string {
  const orderPaymentType = maybeGetOrderPaymentType(rawOrderPaymentType);
  return orderPaymentType !== undefined ? ensureDef(ORDER_PAYMENT_TYPE_LABELS[orderPaymentType]) : rawOrderPaymentType;
}
