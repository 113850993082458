import { CheckBoxControl } from '@src/components/appearance/controls/CheckBox';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { Card } from '@src/components/appearance/fragments/Card';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TAgentCustomersOrganizationAclBaseFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersOrganizationActionsTypes } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import type { TAgentCustomersUserActionsTypes } from '@src/modules/data/agent/customers/users/AgentCustomersUserActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TOrganizationAclEditFormValues = {
  canViewAnalytics: boolean;
};

export type TOrganizationAclEditFormBase = {
  doCustomersOrganizationAclUpsertAsyncGivenOrganization:
    | TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationAclUpsertAsync']
    | null;
  doCustomersOrganizationAclUpsertAsyncGivenUser:
    | TAgentCustomersUserActionsTypes['DoCustomersOrganizationAclUpsertAsync']
    | null;
  organizationAcl: Pick<
    TAgentCustomersOrganizationAclBaseFragment,
    'can_view_analytics' | 'organization_id' | 'user_id'
  >;
  organizationName: string;
  userFullName: string;
};

export type TOrganizationAclEditForm = TProps<false, TOrganizationAclEditFormBase, 'div'>;
export const ORGANIZATION_ACL_EDIT_FORM_CLASS_NAME = 'wp-organization-acl-edit-form';

export const OrganizationAclEditForm = withCssToString(
  ORGANIZATION_ACL_EDIT_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrganizationAclEditForm>(
      (
        {
          doCustomersOrganizationAclUpsertAsyncGivenOrganization,
          doCustomersOrganizationAclUpsertAsyncGivenUser,
          organizationAcl,
          organizationName,
          userFullName,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORGANIZATION_ACL_EDIT_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TOrganizationAclEditFormValues>(
          () => ({
            canViewAnalytics: organizationAcl.can_view_analytics,
          }),
          [organizationAcl],
        );

        const handleSubmit = useCallback<TFormSubmit<TOrganizationAclEditFormValues>>(
          async (values) => {
            if (isDef(doCustomersOrganizationAclUpsertAsyncGivenOrganization)) {
              await doCustomersOrganizationAclUpsertAsyncGivenOrganization({
                ...values,
                isMember: true,
                userId: organizationAcl.user_id,
              });
            }

            if (isDef(doCustomersOrganizationAclUpsertAsyncGivenUser)) {
              await doCustomersOrganizationAclUpsertAsyncGivenUser({
                ...values,
                isMember: true,
                organizationId: organizationAcl.organization_id,
              });
            }

            return undefined;
          },
          // @sort
          [
            doCustomersOrganizationAclUpsertAsyncGivenOrganization,
            doCustomersOrganizationAclUpsertAsyncGivenUser,
            organizationAcl.organization_id,
            organizationAcl.user_id,
          ],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TOrganizationAclEditFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              requireChanges={true}
              submitButtonText='Save'>
              <Item item={{ caption: 'Organization', text: organizationName }} />
              <Item item={{ caption: 'User', text: userFullName }} />
              <Card.Separator />
              <CheckBoxControl id='canViewAnalytics' label='View Analytics' />
            </Form>
          </div>
        );
      },
    ),
  ),
);
