import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { CategoryTree } from '@src/components/appearance/fragments/CategoryTree';
import { Description } from '@src/components/appearance/fragments/Description';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { TAgentOrderReturnBaseFragment, TCustomerOrderReturnBaseFragment } from '@src/gen/graphql/bindings';
import { castDescriptionUnsafe } from '@src/gen/shared/data/description';
import { castSpecsUnsafe } from '@src/gen/shared/data/specs';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { getSpecsItems, joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '256px',
});

export type TOrderReturnProductDrawerPanelBase = {
  orderReturn: TAgentOrderReturnBaseFragment | TCustomerOrderReturnBaseFragment;
};

export type TOrderReturnProductDrawerPanel = TProps<false, TOrderReturnProductDrawerPanelBase, 'div'>;
export const ORDER_RETURN_PRODUCT_DRAWER_PANEL_CLASS_NAME = 'wp-order-return-product-drawer-panel';

export const OrderReturnProductDrawerPanel = withCssToString(
  ORDER_RETURN_PRODUCT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderReturnProductDrawerPanel>(
      ({ orderReturn, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_RETURN_PRODUCT_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const itemsFirst = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(orderReturn.source),
            },
            {
              caption: 'SKU',
              text: orderReturn.product_sku,
            },
            {
              caption: 'Manufacturer',
              text: orderReturn.manufacturer_name,
            },
            {
              caption: 'Manufacturer SKU',
              text: orderReturn.manufacturer_sku,
            },
          ],
          // @sort
          [orderReturn.manufacturer_name, orderReturn.manufacturer_sku, orderReturn.product_sku, orderReturn.source],
        );

        const productDescriptionBlocks = useMemo(
          () => castDescriptionUnsafe(orderReturn.description),
          [orderReturn.description],
        );

        const specsItems = useMemo<TItemBaseItem[]>(
          () => getSpecsItems(castSpecsUnsafe(orderReturn.specs)),
          [orderReturn.specs],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Product' />
            <Drawer.ScrollContent>
              <SImageAsset alt={orderReturn.name} imageAssetPath={orderReturn.image_asset_path} />
              <Drawer.Separator />
              <Drawer.Group>
                <Drawer.Title subTitle={orderReturn.secondary_name} title={orderReturn.name} />
                <Items items={itemsFirst} />
              </Drawer.Group>
              <Drawer.Separator />
              <Drawer.Group title='Category'>
                <CategoryTree category={orderReturn.category_name} />
              </Drawer.Group>
              {productDescriptionBlocks.length > 0 && (
                <Drawer.Group title='Description'>
                  <Description description={productDescriptionBlocks} />
                </Drawer.Group>
              )}
              {specsItems.length > 0 && (
                <Drawer.Group title='Specifications'>
                  {specsItems.map((item) => (
                    <Item item={item} key={item.caption} />
                  ))}
                </Drawer.Group>
              )}
              {isDef(orderReturn.sds_asset_path) && (
                <Drawer.Group title='Documents'>
                  <ControlButton icon='download' text='Safety Data Sheet' />
                </Drawer.Group>
              )}
            </Drawer.ScrollContent>
          </Drawer.Panel>
        );
      },
    ),
  ),
);
