import type { TSearchBase, TSearchBaseChip } from '@src/components/appearance/controls/Search';
import { Search } from '@src/components/appearance/controls/Search';
import { Chrome } from '@src/components/appearance/structure/Chrome';
import { CustomerNextOrderCatalogsFiltersPart } from '@src/components/parts/customer/nextOrder/CustomerNextOrderCatalogsFiltersPart';
import { getSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import type { TWpxSearchLegacyProductsResponse } from '@src/logic/internal/data/wpx';
import { useCustomerNextOrderSearch } from '@src/modules/data/customer/nextOrder/CustomerNextOrderSearchProvider';
import { InlineChrome, useInlineChrome } from '@src/modules/design/ChromeProvider';
import { styled, type TEmptyObject } from '@src/modules/design/theme';
import {
  encodeCustomerEncodeNextOrderCatalogsPath,
  useCustomerNextOrderCatalogsRouteParams,
  useCustomerRouteLocation,
  useCustomerRouteOrganization,
} from '@src/modules/routing/customer';
import noop from 'lodash/noop';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const SDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  paddingTop: '$secondaryNavSpaceMobile',

  '@desktop': {
    paddingTop: '$secondaryNavSpaceDesktop',
  },
});

export function CustomerNextOrderCatalogsNavPart(): JSX.Element {
  const { searchResponse } = useCustomerNextOrderSearch();
  return <InternalPart searchResponse={searchResponse} />;
}

export function CustomerNextOrderCatalogsNavPartLoader(): JSX.Element {
  return <InternalPart searchResponse={null} />;
}

type TInternalPart = {
  searchResponse: TWpxSearchLegacyProductsResponse | null;
};

function InternalPart({ searchResponse }: TInternalPart): JSX.Element {
  const location = useCustomerRouteLocation();
  const navigate = useNavigate();
  const organization = useCustomerRouteOrganization();
  const routeParams = useCustomerNextOrderCatalogsRouteParams();
  const filtersInlineChrome = useInlineChrome<TEmptyObject>();

  const handleSearch = useCallback<TSearchBase['onSearch']>(
    (query) =>
      navigate(
        encodeCustomerEncodeNextOrderCatalogsPath(organization.id, location.id, {
          ...routeParams.config,
          query,
        }),
      ),
    // @sort
    [navigate, location.id, organization.id, routeParams.config],
  );

  const chips = useMemo<TSearchBaseChip[]>(() => {
    const res: TSearchBaseChip[] = [];

    if (isDef(routeParams.config.sourceFilter)) {
      res.push({
        label: `Catalog: ${getSourceName(routeParams.config.sourceFilter)}`,
        onClose: () =>
          navigate(
            encodeCustomerEncodeNextOrderCatalogsPath(organization.id, location.id, {
              query: routeParams.config.query,
              sourceFilter: null,
              categoryName01Filter: null,
              categoryName02Filter: null,
              manufacturerNameFilter: null,
            }),
          ),
      });
    }

    if (isDef(routeParams.config.categoryName01Filter)) {
      res.push({
        label: `Category: ${routeParams.config.categoryName01Filter}`,
        onClose: () =>
          navigate(
            encodeCustomerEncodeNextOrderCatalogsPath(organization.id, location.id, {
              query: routeParams.config.query,
              sourceFilter: routeParams.config.sourceFilter,
              categoryName01Filter: null,
              categoryName02Filter: null,
              manufacturerNameFilter: routeParams.config.manufacturerNameFilter,
            }),
          ),
      });
    }

    if (isDef(routeParams.config.categoryName02Filter)) {
      res.push({
        label: `Sub-Category: ${routeParams.config.categoryName02Filter}`,
        onClose: () =>
          navigate(
            encodeCustomerEncodeNextOrderCatalogsPath(organization.id, location.id, {
              query: routeParams.config.query,
              sourceFilter: routeParams.config.sourceFilter,
              categoryName01Filter: routeParams.config.categoryName01Filter,
              categoryName02Filter: null,
              manufacturerNameFilter: routeParams.config.manufacturerNameFilter,
            }),
          ),
      });
    }

    if (isDef(routeParams.config.manufacturerNameFilter)) {
      res.push({
        label: `Manufacturer: ${routeParams.config.manufacturerNameFilter}`,
        onClose: () =>
          navigate(
            encodeCustomerEncodeNextOrderCatalogsPath(organization.id, location.id, {
              query: routeParams.config.query,
              sourceFilter: routeParams.config.sourceFilter,
              categoryName01Filter: routeParams.config.categoryName01Filter,
              categoryName02Filter: routeParams.config.categoryName02Filter,
              manufacturerNameFilter: null,
            }),
          ),
      });
    }

    return res;
  }, [
    location.id,
    navigate,
    organization.id,
    routeParams.config.categoryName01Filter,
    routeParams.config.categoryName02Filter,
    routeParams.config.manufacturerNameFilter,
    routeParams.config.query,
    routeParams.config.sourceFilter,
  ]);

  const hasAggrs =
    isDef(searchResponse?.sourceAggregation) ||
    isDef(searchResponse?.categoryName01Aggregation) ||
    isDef(searchResponse?.categoryName02Aggregation) ||
    isDef(searchResponse?.manufacturerNameAggregation);

  const handleFilters = useCallback(() => {
    filtersInlineChrome.doChromeOpen({
      metadata: {},
      drawerChildren: (
        <Chrome.Panel>
          <Chrome.Header title='Filters' />
          <Chrome.ScrollContent>
            <CustomerNextOrderCatalogsFiltersPart />,
          </Chrome.ScrollContent>
        </Chrome.Panel>
      ),
    });
  }, [filtersInlineChrome]);

  return (
    <SDiv>
      <InlineChrome {...filtersInlineChrome} />
      <Search
        searchQuery={routeParams.config.query}
        onSearch={handleSearch}
        chips={chips}
        onFilters={isDef(searchResponse) ? (hasAggrs ? handleFilters : undefined) : noop}
      />
    </SDiv>
  );
}
