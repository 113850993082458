import { Banner } from '@src/components/appearance/fragments/Banner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderPlanGroupCard } from '@src/components/mixins/cards/OrderPlanGroupCard';
import type { TAgentOrderPlanGroupCompleteFragment } from '@src/gen/graphql/bindings';
import type { AgentOrderPlanGroupManager } from '@src/gen/shared/data/agentOrders';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderPlanGroupsStructureGroupBase = {
  beginOrderEntryEdit: TAgentOrderActionsTypes['BeginOrderEntryEdit'];
  beginOrderEntryPlanProductView: TAgentOrderActionsTypes['BeginOrderEntryPlanProductView'] | null;
  beginOrderPlanGroupEdit: TAgentOrderActionsTypes['BeginOrderPlanGroupEdit'] | null;
  orderPlanGroups: TAgentOrderPlanGroupCompleteFragment[];
  orderPlanGroupManagers: AgentOrderPlanGroupManager[];
  isEditable: boolean;
};

export type TOrderPlanGroupsStructureGroup = TProps<false, TOrderPlanGroupsStructureGroupBase, 'div'>;
export const ORDER_PLAN_GROUPS_STRUCTURE_GROUP = 'wp-order-plan-groups-structure-group';

export const OrderPlanGroupsStructureGroup = withCssToString(
  ORDER_PLAN_GROUPS_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderPlanGroupsStructureGroup>(
      (
        {
          beginOrderEntryEdit,
          beginOrderEntryPlanProductView,
          beginOrderPlanGroupEdit,
          orderPlanGroups,
          orderPlanGroupManagers,
          className,
          isEditable,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_PLAN_GROUPS_STRUCTURE_GROUP),
          [className],
        );

        const activeOrderPlanGroupManagers = useMemo(
          () => orderPlanGroupManagers.filter((obgm) => !obgm.isRemoved()),
          [orderPlanGroupManagers],
        );

        return (
          <Structure.Group {...rest} className={joinedClassName} ref={ref}>
            {activeOrderPlanGroupManagers.length === 0 && (
              <Banner icon='info' message='Plan orders will appear here as products are planned.' />
            )}
            {activeOrderPlanGroupManagers.map((opgm) => (
              <OrderPlanGroupCard
                isEditable={isEditable}
                key={opgm.orderPlanGroup.id}
                beginOrderPlanGroupEdit={beginOrderPlanGroupEdit}
                beginOrderEntryPlanProductView={beginOrderEntryPlanProductView}
                beginOrderEntryEdit={beginOrderEntryEdit}
                orderPlanGroupManager={opgm}
                notesUpdater={orderPlanGroups.find((obg) => obg.id === opgm.orderPlanGroup.id)?.notes_updater ?? null}
              />
            ))}
          </Structure.Group>
        );
      },
    ),
  ),
);
