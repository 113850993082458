import { ActionButton } from '@src/components/appearance/controls/Button';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import type {
  TAgentCustomersLocationAclBaseFragment,
  TAgentCustomersLocationAclCompleteFragment,
  TAgentCustomersLocationBaseFragment,
  TAgentCustomersOrganizationAclCompleteFragment,
  TAgentCustomersOrganizationCompleteFragment,
} from '@src/gen/graphql/bindings';
import { getFullName } from '@src/gen/shared/data/snippets';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentCustomersOrganizationActions } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import { useAgentCustomersOrganization } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import { useMemo } from 'react';

export function AgentCustomersOrganizationUsersPart(): JSX.Element {
  const { organization } = useAgentCustomersOrganization();
  const { beginOrganizationUserAdd } = useAgentCustomersOrganizationActions();

  const activeOrgAcls = useMemo(
    () => organization.organization_acls.filter((orgAcl) => orgAcl.is_member && !orgAcl.user.is_disabled),
    [organization.organization_acls],
  );

  const inactiveOrgAcls = useMemo(
    () => organization.organization_acls.filter((orgAcl) => !orgAcl.is_member || orgAcl.user.is_disabled),
    [organization.organization_acls],
  );

  const items = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Active Users',
        text: `${activeOrgAcls.length}`,
      },
      {
        caption: 'Inactive Users',
        text: `${inactiveOrgAcls.length}`,
      },
    ],
    // @sort
    [activeOrgAcls.length, inactiveOrgAcls.length],
  );

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <ItemsBanner
          items={items}
          ButtonElement={
            isDef(beginOrganizationUserAdd) ? (
              <ActionButton
                action={{
                  isAsync: false,
                  onClick: beginOrganizationUserAdd,
                  text: 'Add',
                }}
              />
            ) : undefined
          }
        />
        {organization.organization_acls.map((orgAcl) => (
          <OrganizationAclGroupComponent key={orgAcl.user_id} organizationAcl={orgAcl} organization={organization} />
        ))}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function OrganizationAclGroupComponent({
  organizationAcl,
  organization,
}: {
  organizationAcl: TAgentCustomersOrganizationAclCompleteFragment;
  organization: TAgentCustomersOrganizationCompleteFragment;
}): JSX.Element {
  const {
    beginOrganizationUserRemove,
    beginOrganizationUserRestore,
    beginLocationAclAddGivenUser,
    beginOrganizationAclEdit,
  } = useAgentCustomersOrganizationActions();
  const beginOrganizationUser = organizationAcl.is_member ? beginOrganizationUserRemove : beginOrganizationUserRestore;

  const locationAclsWithLocations = useMemo(
    () => {
      const out: (TAgentCustomersLocationAclCompleteFragment & {
        location: TAgentCustomersLocationBaseFragment;
      })[] = [];

      organization.locations.map((loc) =>
        loc.location_acls
          .filter((locAcl) => locAcl.user_id === organizationAcl.user.id)
          .forEach((locAcl) => out.push({ ...locAcl, location: loc })),
      );

      return out;
    },
    // @sort
    [organization.locations, organizationAcl.user.id],
  );

  const itemsFirst = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'User Email',
        text: `${organizationAcl.user.email}`,
      },
      {
        caption: 'User Phone Number',
        text: organizationAcl.user.phone_number !== '' ? organizationAcl.user.phone_number : 'N/A',
      },
      {
        caption: 'User Enabled',
        text: { asBoolean: !organizationAcl.user.is_disabled },
      },
    ],
    // @sort
    [organizationAcl.user.email, organizationAcl.user.is_disabled, organizationAcl.user.phone_number],
  );

  const itemsSecond = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Org. Membership Enabled',
        text: { asBoolean: organizationAcl.is_member },
      },
      {
        caption: 'View Analytics',
        text: { asBoolean: organizationAcl.can_view_analytics },
      },
    ],
    // @sort
    [organizationAcl.can_view_analytics, organizationAcl.is_member],
  );

  const iconButtonActions = useMemo<TIconButtonAction[]>(
    () =>
      getAvailableIconButtonActions([
        {
          isAsync: false,
          icon: 'edit',
          onClick:
            isDef(beginOrganizationAclEdit) && organizationAcl.is_member
              ? (): void => beginOrganizationAclEdit({ userId: organizationAcl.user_id })
              : undefined,
        },
        {
          isAsync: false,
          icon: organizationAcl.is_member ? 'exit' : 'enter',
          onClick: isDef(beginOrganizationUser)
            ? (): void => beginOrganizationUser({ userId: organizationAcl.user_id })
            : undefined,
        },
      ]),
    // @sort
    [beginOrganizationAclEdit, beginOrganizationUser, organizationAcl.is_member, organizationAcl.user_id],
  );

  const locationPermissionsIconButtonActions = useMemo<TIconButtonAction[]>(
    () =>
      getAvailableIconButtonActions([
        {
          isAsync: false,
          icon: 'add',
          onClick: isDef(beginLocationAclAddGivenUser)
            ? (): void =>
                beginLocationAclAddGivenUser({
                  userId: organizationAcl.user.id,
                  omitLocationIds: locationAclsWithLocations.reduce<{ [key: string]: true }>(
                    (out, aLocAcl) => ({ ...out, [aLocAcl.location.id]: true }),
                    {},
                  ),
                })
            : undefined,
        },
      ]),
    // @sort
    [beginLocationAclAddGivenUser, locationAclsWithLocations, organizationAcl.user.id],
  );

  return (
    <Structure.Group title={getFullName(organizationAcl.user)} variant='section' iconButtonActions={iconButtonActions}>
      <Structure.Group>
        <ItemsBanner items={itemsFirst} />
        <ItemsBanner items={itemsSecond} />
        {(organizationAcl.user.is_disabled || !organizationAcl.is_member) && (
          <Banner
            accent='error'
            icon='warning'
            message='This user is not enabled or their organization membership is not enabled.'
          />
        )}
      </Structure.Group>
      {!organizationAcl.user.is_disabled && organizationAcl.is_member && (
        <Structure.Group
          title='Location Permissions'
          variant='secondary'
          iconButtonActions={locationPermissionsIconButtonActions}>
          {locationAclsWithLocations.length === 0 && (
            <Banner
              accent='warning'
              icon='warning'
              message='This user is not enabled to access any location in this organization.'
            />
          )}
          {locationAclsWithLocations.map((locAclWithLocation) => (
            <LocationAclLocationCardComponent
              key={locAclWithLocation.location.id}
              locationAclWithLocation={locAclWithLocation}
            />
          ))}
        </Structure.Group>
      )}
    </Structure.Group>
  );
}

function LocationAclLocationCardComponent({
  locationAclWithLocation,
}: {
  locationAclWithLocation: TAgentCustomersLocationAclCompleteFragment & {
    location: TAgentCustomersLocationBaseFragment;
  };
}): JSX.Element {
  const { organization } = useAgentCustomersOrganization();
  const { beginLocationAclEdit, beginLocationAclRemove } = useAgentCustomersOrganizationActions();

  const organizationAcl = organization.organization_acls.find(
    (orgAcl) => orgAcl.user_id === locationAclWithLocation.user_id && orgAcl.is_member && !orgAcl.user.is_disabled,
  );

  const items = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Permissions for Orders',
        text: toValue(locationAclWithLocation),
      },
      {
        caption: 'View Analytics',
        text:
          organizationAcl?.can_view_analytics === true
            ? 'Yes (Organization Setting)'
            : locationAclWithLocation.can_view_analytics
            ? 'Yes'
            : 'No',
      },
    ],
    // @sort
    [locationAclWithLocation, organizationAcl?.can_view_analytics],
  );

  const iconButtonActions = useMemo<TIconButtonAction[]>(
    () =>
      getAvailableIconButtonActions([
        {
          icon: 'edit',
          isAsync: false,
          onClick: isDef(beginLocationAclEdit)
            ? (): void =>
                beginLocationAclEdit({
                  locationId: locationAclWithLocation.location_id,
                  userId: locationAclWithLocation.user_id,
                })
            : undefined,
        },
        {
          icon: 'trash',
          isAsync: false,
          onClick: isDef(beginLocationAclRemove)
            ? (): void =>
                beginLocationAclRemove({
                  locationId: locationAclWithLocation.location_id,
                  userId: locationAclWithLocation.user_id,
                })
            : undefined,
        },
      ]),
    [
      beginLocationAclEdit,
      beginLocationAclRemove,
      locationAclWithLocation.location_id,
      locationAclWithLocation.user_id,
    ],
  );

  return (
    <Card.Container iconButtonActions={iconButtonActions} flush={true} interactive={false}>
      <Card.ContentArea>
        <Card.Title
          title={locationAclWithLocation.location.name}
          subTitle={locationAclWithLocation.location.order_code}
        />
        <Items items={items} />
      </Card.ContentArea>
    </Card.Container>
  );
}

function toValue(
  acl: Pick<
    TAgentCustomersLocationAclBaseFragment,
    'can_approve_orders' | 'can_place_orders' | 'can_send_order_messages' | 'can_view_orders'
  >,
): string {
  if (acl.can_approve_orders) {
    return 'View + Send Messages + Place + Approve';
  } else if (acl.can_place_orders) {
    return 'View + Send Messages + Place';
  } else if (acl.can_send_order_messages) {
    return 'View + Send Messages';
  } else if (acl.can_view_orders) {
    return 'View';
  } else {
    return 'None';
  }
}
