import { Banner } from '@src/components/appearance/fragments/Banner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderShipmentCard } from '@src/components/mixins/cards/OrderShipmentCard';
import type { TAgentOrderShipmentCompleteFragment } from '@src/gen/graphql/bindings';
import { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderShipmentsStructureGroupBase = {
  beginOrderShipmentProductView:
    | TAgentOrderActionsTypes['BeginOrderShipmentProductView']
    | TCustomerOrderActionsTypes['BeginOrderShipmentProductView'];
  beginOrderTrackingHistoryView:
    | TAgentOrderActionsTypes['BeginOrderTrackingHistoryView']
    | TCustomerOrderActionsTypes['BeginOrderTrackingHistoryView'];
  orderStatus: EOrderStatus;
  orderShipments: TAgentOrderShipmentCompleteFragment[];
};

export type TOrderShipmentsStructureGroup = TProps<false, TOrderShipmentsStructureGroupBase, 'div'>;
export const ORDER_SHIPMENTS_STRUCTURE_GROUP_CLASS_NAME = 'wp-order-shipments-structure-group';

export const OrderShipmentsStructureGroup = withCssToString(
  ORDER_SHIPMENTS_STRUCTURE_GROUP_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderShipmentsStructureGroup>(
      (
        {
          beginOrderShipmentProductView,
          beginOrderTrackingHistoryView,
          orderStatus,
          orderShipments,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_SHIPMENTS_STRUCTURE_GROUP_CLASS_NAME),
          [className],
        );

        return (
          <Structure.Group {...rest} className={joinedClassName} ref={ref} title='Shipments'>
            {orderShipments.length === 0 && (
              <Banner icon='info' message='Shipments for your order will appear here when available.' />
            )}
            {orderShipments.length > 0 &&
              (orderStatus === EOrderStatus.RECEIVED ||
                orderStatus === EOrderStatus.PREPARING ||
                orderStatus === EOrderStatus.PROCESSING) && (
                <Banner
                  icon='warning'
                  message='Not all items have shipped. Additional shipments will appear here when available.'
                />
              )}
            {orderShipments.map((os) => (
              <OrderShipmentCard
                key={os.id}
                beginOrderShipmentProductView={beginOrderShipmentProductView}
                beginOrderTrackingHistoryView={beginOrderTrackingHistoryView}
                onDeleteAsyncNotify={null}
                onSetDeliveredAsyncNotify={null}
                orderShipment={os}
              />
            ))}
          </Structure.Group>
        );
      },
    ),
  ),
);
