import { Button, getButtonVariant } from '@src/components/appearance/controls/Button';
import type { TCheckBoxBase } from '@src/components/appearance/controls/CheckBox';
import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import type { TItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { ItemsBanner, ItemsBannerLoader } from '@src/components/appearance/fragments/ItemsBanner';
import { Labeled } from '@src/components/appearance/fragments/Labeled';
import type { TCustomerCartProductBaseFragment } from '@src/gen/graphql/bindings';
import { getFullName } from '@src/gen/shared/data/snippets';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TCustomerNextOrderActionsTypes } from '@src/modules/data/customer/nextOrder/CustomerNextOrderActionsProvider';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TNextOrderCartItemsBannerBase = {
  cartProductsTotalItems: number;
  cartProductsUniqueItems: number;
  doOrderPlace?: TCustomerNextOrderActionsTypes['BeginOrderPlace'] | undefined;
  isEmergencyOrder?: TCheckBoxBase | undefined;
  isEmergencyOrderIndex: number; // TODO(ibrt): Get rid of this obscenity.
  lastUpdatedCartProduct: Pick<TCustomerCartProductBaseFragment, 'updated_at' | 'updater'> | null;
};

export type TNextOrderCartItemsBanner = TProps<false, TNextOrderCartItemsBannerBase, 'div'>;
export const NEXT_ORDER_CART_ITEMS_BANNER_CLASS_NAME = 'wp-next-order-cart-items-banner';

export const NextOrderCartItemsBanner = withCssToString(
  NEXT_ORDER_CART_ITEMS_BANNER_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNextOrderCartItemsBanner>(
      (
        {
          cartProductsTotalItems,
          cartProductsUniqueItems,
          doOrderPlace,
          isEmergencyOrder,
          isEmergencyOrderIndex,
          lastUpdatedCartProduct,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, NEXT_ORDER_CART_ITEMS_BANNER_CLASS_NAME),
          [className],
        );

        const lastUpdatedBy = useMemo(
          () => (isDef(lastUpdatedCartProduct) ? getFullName(lastUpdatedCartProduct.updater) : null),
          [lastUpdatedCartProduct],
        );

        const items = useMemo<TItemsBanner['items']>(
          () => [
            {
              caption: 'Unique Items',
              text: `${cartProductsUniqueItems}`,
            },
            {
              caption: 'Total Items',
              text: `${cartProductsTotalItems}`,
            },
            {
              caption: 'Last Updated By',
              text: lastUpdatedBy,
            },
            {
              caption: 'Updated',
              text: isDef(lastUpdatedCartProduct?.updated_at)
                ? { asDate: 'ago', at: ensureDef(lastUpdatedCartProduct?.updated_at) }
                : null,
            },
          ],
          // @sort
          [cartProductsTotalItems, cartProductsUniqueItems, lastUpdatedBy, lastUpdatedCartProduct?.updated_at],
        );

        return (
          <ItemsBanner
            {...rest}
            ButtonElement={
              <Button
                variant={getButtonVariant({ base: 'default', disabled: !isDef(doOrderPlace), loading: false })}
                onClick={
                  isDef(doOrderPlace)
                    ? (): void => doOrderPlace({ isEmergency: isEmergencyOrder?.checked === true })
                    : undefined
                }
                text='Place Order'
              />
            }
            LabeledElement={
              isDef(isEmergencyOrder) ? (
                <Labeled
                  htmlFor={`wp-next-order-cart-items-banner-is-emergency-${isEmergencyOrderIndex}`}
                  text='Emergency'>
                  <CheckBox
                    {...isEmergencyOrder}
                    disabled={!isDef(doOrderPlace)}
                    id={`wp-next-order-cart-items-banner-is-emergency-${isEmergencyOrderIndex}`}
                  />
                </Labeled>
              ) : undefined
            }
            className={joinedClassName}
            items={items}
            ref={ref}
          />
        );
      },
    ),
  ),
);

export const NextOrderCartItemsBannerLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => <ItemsBannerLoader buttonText='Place Order' count={4} ref={ref} />,
  ),
);
