import { Drawer } from '@src/components/appearance/structure/Drawer';
import {
  UserPickerDrawerPanel,
  UserPickerDrawerPanelLoader,
} from '@src/components/mixins/drawers/UserPickerDrawerPanel';
import type { TAgentUserBaseFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import {
  AgentUsersForPickerProvider,
  useAgentUsersForPicker,
} from '@src/modules/data/agent/global/operations/AgentUsersForPickerProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TAgentUserPickerDrawerTypes = {
  BeginUserPickCallback: (user: TAgentUserBaseFragment) => Promise<void>;
  BeginUserPickArgs: {
    buttonActionText?: string | undefined;
    callback: TAgentUserPickerDrawerTypes['BeginUserPickCallback'];
    drawerHeaderTitle?: string | undefined;
    isWellplaeceAgent: boolean;
    omitUserIds?: { [key: string]: true } | undefined;
  };
  BeginUserPick: (args: TAgentUserPickerDrawerTypes['BeginUserPickArgs']) => void;
};

export type TAgentUserPickerDrawerContext = {
  beginUserPick: TAgentUserPickerDrawerTypes['BeginUserPick'];
};

export const { Context: AgentUserPickerDrawerContext, useContext: useAgentUserPickerDrawer } =
  createRequiredContext<TAgentUserPickerDrawerContext>();

export function AgentUserPickerDrawerProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const [state, setState] = useState<TAgentUserPickerDrawerTypes['BeginUserPickArgs'] | undefined>(undefined);

  const handleOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setState(undefined);
    }
  }, []);

  const handleCallback = useCallback(
    async (user: TAgentUserBaseFragment): Promise<void> => {
      await state?.callback(user);
      setState(undefined);
    },
    [state],
  );

  const value = useMemo<TAgentUserPickerDrawerContext>(
    () => ({
      beginUserPick: setState,
    }),
    [],
  );

  return (
    <AgentUserPickerDrawerContext.Provider value={value}>
      <Drawer.Root open={isDef(state)} onOpenChange={handleOpenChange}>
        {isDef(state) && (
          <AgentUsersForPickerProvider
            LoaderComponent={(): JSX.Element => (
              <UserPickerDrawerPanelLoader
                buttonActionText={state.buttonActionText}
                drawerHeaderTitle={state.drawerHeaderTitle}
              />
            )}
            isWellplaeceAgent={state.isWellplaeceAgent}>
            <ConsumerComponent
              buttonActionText={state.buttonActionText}
              callback={handleCallback}
              drawerHeaderTitle={state.drawerHeaderTitle}
              isWellplaeceAgent={state.isWellplaeceAgent}
              omitUserIds={state.omitUserIds}
            />
          </AgentUsersForPickerProvider>
        )}
      </Drawer.Root>
      {children}
    </AgentUserPickerDrawerContext.Provider>
  );
}

function ConsumerComponent({
  buttonActionText,
  callback,
  drawerHeaderTitle,
  omitUserIds,
}: TAgentUserPickerDrawerTypes['BeginUserPickArgs']): JSX.Element {
  const { users, loaderRef } = useAgentUsersForPicker();

  const filteredUsers = useMemo(() => {
    return users.filter((user) => !isDef(omitUserIds?.[user.id]));
  }, [omitUserIds, users]);

  return (
    <UserPickerDrawerPanel
      buttonActionText={buttonActionText}
      drawerHeaderTitle={drawerHeaderTitle}
      loaderRef={loaderRef}
      onSelectUser={callback}
      users={filteredUsers}
    />
  );
}
