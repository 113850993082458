import { NetworkStatus } from '@apollo/client';
import type {
  AgentCustomersOrganizationsWithStatisticsQueryVariables,
  TAgentCustomersOrganizationBaseWithStatisticsFragment,
} from '@src/gen/graphql/bindings';
import { useAgentCustomersOrganizationsWithStatisticsQuery } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, mergeGetMore } from '@src/modules/graphql/utils';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TAgentCustomersOrganizationsWithStatisticsContext = {
  organizations: TAgentCustomersOrganizationBaseWithStatisticsFragment[];
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const {
  Context: AgentCustomersOrganizationsWithStatisticsContext,
  useContext: useAgentCustomersOrganizationsWithStatistics,
} = createRequiredContext<TAgentCustomersOrganizationsWithStatisticsContext>();

export type TAgentCustomersOrganizationsWithStatisticsProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function AgentCustomersOrganizationsWithStatisticsProvider({
  LoaderComponent,
  children,
}: TAgentCustomersOrganizationsWithStatisticsProvider): JSX.Element {
  const variables = useMemo<AgentCustomersOrganizationsWithStatisticsQueryVariables>(
    () => ({
      limit: DEFAULT_LIMIT,
      offset: 0,
    }),
    // @sort
    [],
  );

  const { data, error, fetchMore, networkStatus } = useAgentCustomersOrganizationsWithStatisticsQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          offset: ensureDef(data?.organizations.length),
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          organizations: mergeGetMore(prev.organizations, fetchMoreResult.organizations),
        }),
      });

      return newData.organizations.length;
    },
    // @sort
    [fetchMore, data?.organizations.length],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.organizations.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TAgentCustomersOrganizationsWithStatisticsContext | undefined>(
    () =>
      isDef(data)
        ? {
            organizations: data.organizations,
            loaderRef: loaderRef,
          }
        : undefined,
    // @sort
    [data, loaderRef],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentCustomersOrganizationsWithStatisticsContext.Provider value={value}>
      {children}
    </AgentCustomersOrganizationsWithStatisticsContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
