import { Card } from '@src/components/appearance/fragments/Card';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { AgentProductHintManager } from '@src/gen/shared/data/agentOrders';
import { getSaleUnitQuantityWithFallbackPrefix } from '@src/gen/shared/data/snippets';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { maybeFormatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TProductHintPlanProductCardBase = {
  onClick: (() => void) | null;
  productHintManager: AgentProductHintManager;
};

export type TProductHintPlanProductCard = TProps<false, TProductHintPlanProductCardBase, 'div'>;
export const PRODUCT_HINT_PLAN_PRODUCT_CARD_CLASS_NAME = 'wp-product-hint-plan-product-card';

export const ProductHintPlanProductCard = withCssToString(
  PRODUCT_HINT_PLAN_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TProductHintPlanProductCard>(
      ({ onClick, productHintManager, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, PRODUCT_HINT_PLAN_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const planProduct = useMemo(() => productHintManager.mustGetPlanProduct(), [productHintManager]);

        const itemsFirst = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(planProduct.source),
            },
            {
              caption: 'SKU',
              text: planProduct.product_sku,
            },
            {
              caption: 'Quantity',
              text: `${getSaleUnitQuantityWithFallbackPrefix(
                'N/A',
                productHintManager.productHint.plan_quantity,
                planProduct.sale_unit,
              )}`,
            },
            {
              caption: 'Unit Price (Wellplaece)',
              text: maybeFormatDollarsCurrency(productHintManager.productHint.plan_unit_price) ?? 'N/A',
            },
          ],
          // @sort
          [
            planProduct.product_sku,
            planProduct.sale_unit,
            planProduct.source,
            productHintManager.productHint.plan_quantity,
            productHintManager.productHint.plan_unit_price,
          ],
        );

        return (
          <Card.Container {...rest} className={joinedClassName} flush={true} interactive={isDef(onClick)} ref={ref}>
            {isDef(onClick) ? (
              <Card.ButtonArea onClick={onClick}>
                <Card.ImageAssetArea>
                  <ImageAsset alt={planProduct.name} imageAssetPath={planProduct.image_asset_path} />
                  <Card.ContentArea>
                    <Card.Title subTitle={planProduct.secondary_name} title={planProduct.name} />
                    <ItemsBanner items={itemsFirst} />
                  </Card.ContentArea>
                </Card.ImageAssetArea>
              </Card.ButtonArea>
            ) : (
              <Card.ImageAssetArea>
                <ImageAsset alt={planProduct.name} imageAssetPath={planProduct.image_asset_path} />
                <Card.ContentArea>
                  <Card.Title subTitle={planProduct.secondary_name} title={planProduct.name} />
                  <ItemsBanner items={itemsFirst} />
                </Card.ContentArea>
              </Card.ImageAssetArea>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);
