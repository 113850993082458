import { NetworkStatus } from '@apollo/client';
import type { CustomerOrdersQuery, CustomerOrdersQueryVariables } from '@src/gen/graphql/bindings';
import { useCustomerOrdersQuery } from '@src/gen/graphql/bindings';
import { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, FUTURE_DATE, mergeGetMore } from '@src/modules/graphql/utils';
import { useCustomerRouteOrganization } from '@src/modules/routing/customer';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TCustomerOrdersContext = {
  orders: CustomerOrdersQuery;
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const { Context: CustomerOrdersContext, useContext: useCustomerOrders } =
  createRequiredContext<TCustomerOrdersContext>();

export type TCustomerOrdersProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function CustomerOrdersProvider({ LoaderComponent, children }: TCustomerOrdersProvider): JSX.Element {
  const organization = useCustomerRouteOrganization();

  const variables = useMemo<CustomerOrdersQueryVariables>(
    () => ({
      organizationId: organization.id,
      completedUpdatedAtCursor: FUTURE_DATE.toISOString(),
      completedLimit: DEFAULT_LIMIT,
    }),
    // @sort
    [organization.id],
  );

  const { data, error, fetchMore, networkStatus } = useCustomerOrdersQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          completedUpdatedAtCursor:
            (data?.completed.length ?? 0) > 0
              ? ensureDef(data?.completed[data.completed.length - 1]).updated_at
              : FUTURE_DATE.toISOString(),
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          ...prev,
          completed: mergeGetMore(prev.completed, fetchMoreResult.completed),
        }),
      });

      return newData.completed.length;
    },
    // @sort
    [fetchMore, data?.completed],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.completed.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TCustomerOrdersContext | undefined>(
    () =>
      isDef(data)
        ? {
            orders: {
              waitingOnCustomer: data.waitingOnCustomer.filter(
                (o) =>
                  o.is_awaiting_prebill_approval &&
                  o.status !== EOrderStatus.COMPLETED &&
                  o.status !== EOrderStatus.CANCELED,
              ),
              inProgress: data.inProgress.filter(
                (o) =>
                  !o.is_awaiting_prebill_approval &&
                  o.status !== EOrderStatus.COMPLETED &&
                  o.status !== EOrderStatus.CANCELED,
              ),
              completed: data.completed.filter(
                (o) => o.status === EOrderStatus.COMPLETED || o.status === EOrderStatus.CANCELED,
              ),
            },
            loaderRef: loaderRef,
          }
        : undefined,
    // @sort
    [data, loaderRef],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <CustomerOrdersContext.Provider value={value}>{children}</CustomerOrdersContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
