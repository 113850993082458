import { Control } from '@src/components/appearance/controls/Control';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { newStringValidator } from '@src/logic/internal/data/validation';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import max from 'lodash/max';
import type { ComponentProps } from 'react';
import { forwardRef, memo, useMemo } from 'react';
import { useField } from 'react-final-form';

const STextArea = styled('textarea', {
  all: 'unset',

  alignItems: 'center',
  backgroundColor: '$textAreaBackground',
  borderColor: '$textAreaBorder',
  borderRadius: '$textArea',
  borderStyle: '$regular',
  borderWidth: '$regular',
  overflow: 'hidden',
  padding: '$textAreaPadding',
  text: '$small',

  '&:hover': {
    borderColor: '$textAreaHoverBorder',
  },

  '&:focus': {
    boxShadow: '$secondaryFocus',
  },

  '&::placeholder': {
    color: '$textAreaPlaceholderText',
  },

  '&[disabled]': {
    borderColor: '$textAreaDisabledBorder',
    borderStyle: '$disabled',
    color: '$textAreaDisabledText',
    cursor: 'not-allowed',
    whiteSpace: 'pre-wrap',

    '&::placeholder': {
      color: '$textAreaDisabledText',
    },
  },
});

export type TTextAreaBase = {
  onChange: NonNullable<ComponentProps<'textarea'>['onChange']>;
  value: string;
};

export type TTextArea = Omit<TProps<false, TTextAreaBase, 'textarea'>, 'rows'> & {
  minRows?: number | undefined;
};
export const TEXT_AREA_CLASS_NAME = 'wp-text-area';
export const TEXT_AREA_MIN_ROWS = 3;

export const TextArea = withCssToString(
  TEXT_AREA_CLASS_NAME,
  memo(
    forwardRef<HTMLTextAreaElement, TTextArea>(({ value, className, minRows, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, TEXT_AREA_CLASS_NAME), [className]);
      const rows = useMemo(() => max([value.split('\n').length, minRows ?? TEXT_AREA_MIN_ROWS]), [minRows, value]);
      return <STextArea {...rest} className={joinedClassName} ref={ref} rows={rows} value={value} />;
    }),
  ),
);

export type TTextAreaControl = {
  id: string;
  label?: string | undefined;
  maxLength?: number | undefined;
  placeholder?: string | undefined;
  minRows?: number | undefined;
  required?: boolean | undefined;
};

export function TextAreaControl({
  id,
  label,
  maxLength,
  placeholder,
  minRows,
  required,
}: TTextAreaControl): JSX.Element {
  const {
    input: { onBlur, onChange, onFocus, value },
    meta: { error, submitting, visited }, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  } = useField<string>(id, {
    subscription: {
      error: true,
      submitting: true,
      value: true,
      visited: true,
    },
    validate: newStringValidator(required === true, false, false, false, false),
  });

  const finalError = useMemo(
    () => (visited === true && typeof error === 'string' && error !== '' ? error : null),
    [error, visited],
  );

  const secondaryLabel = useMemo(
    () => (maxLength !== undefined ? `(${value.length} / ${maxLength})` : undefined),
    [maxLength, value],
  );

  return (
    <Control error={finalError} id={id} required={required} label={label} secondaryLabel={secondaryLabel}>
      <TextArea
        disabled={submitting}
        id={id}
        maxLength={maxLength}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        value={value}
        minRows={minRows}
      />
    </Control>
  );
}
