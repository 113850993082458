import { Typography } from '@src/components/appearance/basics/Typography';
import type { TDescription as TDescriptionData } from '@src/gen/shared/data/description';
import { EDescriptionBlockType } from '@src/gen/shared/data/description';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';

const SDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$descriptionGap',
});

const SFeaturesUl = styled('ul', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$descriptionFeaturesGap',
  paddingLeft: '$descriptionFeaturesPaddingLeft',
});

const SFeaturesLi = styled('li', {
  text: '$small',
});

export type TDescriptionBase = {
  description: TDescriptionData;
};

export type TDescription = TProps<false, TDescriptionBase, 'div'>;
export const DESCRIPTION_CLASS_NAME = 'wp-description';

export const Description = withCssToString(
  DESCRIPTION_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TDescription>(({ description, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, DESCRIPTION_CLASS_NAME), [className]);
      const key = useMemo(() => `${uuidV4()}-${description.length}`, [description]);

      return (
        <SDiv {...rest} className={joinedClassName} ref={ref}>
          {description.map((block, i) => {
            switch (block.type) {
              case EDescriptionBlockType.TITLE:
                return <Typography.Caption key={`${key}-${i}`} text={block.title} />;
              case EDescriptionBlockType.FEATURES:
                return (
                  <SFeaturesUl key={`${key}-${i}`}>
                    {block.features.map((feature, j) => (
                      <SFeaturesLi key={`${key}-${i}-${j}`}>{feature}</SFeaturesLi>
                    ))}
                  </SFeaturesUl>
                );
              case EDescriptionBlockType.PARAGRAPH:
                return <Typography.Small key={`${key}-${i}`} text={block.paragraph} />;
            }
          })}
        </SDiv>
      );
    }),
  ),
);
