import { Typography } from '@src/components/appearance/basics/Typography';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderCardLoader } from '@src/components/mixins/cards/OrderCard';
import { OrderReturnListingCard } from '@src/components/mixins/cards/OrderReturnListingCard';
import type { TAgentOrderReturnCompleteWithOrderSummaryFragment } from '@src/gen/graphql/bindings';
import { useAgentOrderReturnsWaiting } from '@src/modules/data/agent/orderReturns/AgentOrderReturnsWaitingProvider';
import { encodeAgentOrderWorksheetReturnsPath } from '@src/modules/routing/agent';
import { Fragment, memo, useMemo } from 'react';

export function AgentOrderReturnsPart(): JSX.Element {
  const { waitingForInstructions, waitingForShipment, waitingForAcknowledgment } = useAgentOrderReturnsWaiting();

  return (
    <Structure.ScrollContent skipSeparator={true}>
      <Structure.Stack>
        {waitingForInstructions.length === 0 &&
          waitingForShipment.length === 0 &&
          waitingForAcknowledgment.length === 0 && (
            <Announcement css={{ paddingTop: '128px' }}>
              <Fragment>
                <Typography.SubTitle text='Nothing to See Here' />
                <Typography.Small text='There are currently no active returns.' />
              </Fragment>
            </Announcement>
          )}
        {waitingForInstructions.length > 0 && (
          <Structure.Group title='Waiting for Instructions'>
            {waitingForInstructions.map((or) => (
              <CardComponent key={or.id} orderReturn={or} />
            ))}
          </Structure.Group>
        )}
        {waitingForShipment.length > 0 && (
          <Structure.Group title='Waiting for Customer to Ship'>
            {waitingForShipment.map((or) => (
              <CardComponent key={or.id} orderReturn={or} />
            ))}
          </Structure.Group>
        )}
        {waitingForAcknowledgment.length > 0 && (
          <Structure.Group title='Waiting for Wellplaece to Confirm'>
            {waitingForAcknowledgment.map((or) => (
              <CardComponent key={or.id} orderReturn={or} />
            ))}
          </Structure.Group>
        )}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function CardComponent({
  orderReturn,
}: {
  orderReturn: TAgentOrderReturnCompleteWithOrderSummaryFragment;
}): JSX.Element {
  const to = useMemo(() => encodeAgentOrderWorksheetReturnsPath(orderReturn.order_id), [orderReturn.order_id]);
  return <OrderReturnListingCard to={to} orderReturn={orderReturn} />;
}

export const AgentOrderReturnsPartLoader = memo(
  (): JSX.Element => (
    <Fragment>
      <Structure.ScrollContentLoader>
        <Structure.Stack>
          <Structure.GroupLoader hasTitle={true}>
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
          </Structure.GroupLoader>
        </Structure.Stack>
      </Structure.ScrollContentLoader>
    </Fragment>
  ),
);
