import { Dialog } from '@src/components/appearance/structure/Dialog';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { PropsWithChildren, ReactElement } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TDialogTypes = {
  DoDialogOpen: (newDialogChildren: ReactElement) => void;
  DoDialogClose: () => void;
};

export type TDialogContext = {
  doDialogOpen: TDialogTypes['DoDialogOpen'];
  doDialogClose: TDialogTypes['DoDialogClose'];
};

export const { Context: DialogContext, useContext: useDialog } = createRequiredContext<TDialogContext>();

export function DialogProvider({ children }: PropsWithChildren): JSX.Element {
  const [dialogChildren, setDialogChildren] = useState<ReactElement | undefined>(undefined);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setDialogChildren(undefined);
    }
  }, []);

  const value = useMemo<TDialogContext>(
    () => ({
      doDialogOpen: (newDialogChildren: ReactElement) => setDialogChildren(newDialogChildren),
      doDialogClose: () => setDialogChildren(undefined),
    }),
    [],
  );

  return (
    <DialogContext.Provider value={value}>
      <Dialog.Root onOpenChange={handleOpenChange} open={dialogChildren !== undefined}>
        {dialogChildren}
      </Dialog.Root>
      {children}
    </DialogContext.Provider>
  );
}
