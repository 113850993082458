import { Select } from '@src/components/appearance/controls/Select';
import { ensureStringToNumber } from '@src/gen/shared/utils/converters';
import type { TOptional } from '@src/gen/shared/utils/types';
import { ensureString, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const SDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$cardGap',
});

export type TRawDatePickerBase = {
  disabled?: boolean | undefined;
  onChange: (value: string) => void;
  value: string;
};

type TRawDatePickerState = {
  year: string;
  month: string;
  day: string;
};

export type TRawDatePicker = TProps<false, TRawDatePickerBase, 'div'>;
export const RAW_DATE_PICKER_CLASS_NAME = 'wp-raw-date-picker';

export const RawDatePicker = withCssToString(
  RAW_DATE_PICKER_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TRawDatePicker>(
      ({ disabled, onChange, value, className, ...rest }: TRawDatePicker, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, RAW_DATE_PICKER_CLASS_NAME), [className]);
        const [state, setState] = useState<TRawDatePickerState>(rawParseDate(value));
        const lastValueRef = useRef(value);

        const updateState = useCallback(
          (updates: TOptional<TRawDatePickerState, 'day' | 'month' | 'year'>) => {
            const newState = {
              year: isDef(updates.year) ? updates.year : state.year,
              month: isDef(updates.month) ? updates.month : state.month,
              day: isDef(updates.day) ? updates.day : state.day,
            };

            const newStateStr = rawFormatDate(newState);
            setState(newState);
            lastValueRef.current = newStateStr;
            onChange(newStateStr);
          },
          [onChange, state.day, state.month, state.year],
        );

        useEffect(() => {
          if (lastValueRef.current !== value) {
            setState(rawParseDate(value));
          }
        }, [value]);

        useEffect(() => {
          const stateStr = rawFormatDate(state);

          if (value !== stateStr) {
            onChange(stateStr);
          }
        }, [state, onChange, value]);

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            <Select
              disabled={disabled}
              onChange={(e): void => updateState({ month: e.target.value })}
              value={state.month}>
              <option value=''>Month</option>
              <option value='01'>Jan (01)</option>
              <option value='02'>Feb (02)</option>
              <option value='03'>Mar (03)</option>
              <option value='04'>Apr (04)</option>
              <option value='05'>May (05)</option>
              <option value='06'>Jun (06)</option>
              <option value='07'>Jul (07)</option>
              <option value='08'>Aug (08)</option>
              <option value='09'>Sep (09)</option>
              <option value='10'>Oct (10)</option>
              <option value='11'>Nov (11)</option>
              <option value='12'>Dec (12)</option>
            </Select>
            <Select disabled={disabled} onChange={(e): void => updateState({ day: e.target.value })} value={state.day}>
              <option value=''>Day</option>
              {new Array(31)
                .fill(0)
                .map((v, i) => `${i + 1}`.padStart(2, '0'))
                .map((v) => (
                  <option key={v} value={v}>
                    {v}
                  </option>
                ))}
            </Select>
            <Select
              disabled={disabled}
              onChange={(e): void => updateState({ year: e.target.value })}
              value={state.year}>
              <option value=''>Year</option>
              {new Array(10)
                .fill(0)
                .map((v, i) => `${2020 + i}`.padStart(4, '0'))
                .map((v) => (
                  <option key={v} value={v}>
                    {v}
                  </option>
                ))}
            </Select>
          </SDiv>
        );
      },
    ),
  ),
);

function rawParseDate(dateStr: string): TRawDatePickerState {
  if (dateStr === '') {
    const date = new Date();

    return {
      year: `${date.getFullYear()}`.padStart(4, '0'),
      month: `${date.getMonth() + 1}`.padStart(2, '0'),
      day: `${date.getDate()}`.padStart(2, '0'),
    };
  }

  const parts = dateStr.split('-');

  if (parts.length !== 3) {
    return { year: '', month: '', day: '' };
  }

  const yearStr = ensureString(parts[0]);
  const monthStr = ensureString(parts[1]);
  const dayStr = ensureString(parts[2]);

  let year = /^\d{4}$/.test(yearStr) ? ensureStringToNumber(yearStr) : null;
  let month = /^\d{2}$/.test(monthStr) ? ensureStringToNumber(monthStr) : null;
  let day = /^\d{2}$/.test(dayStr) ? ensureStringToNumber(dayStr) : null;

  if (isDef(year) && year < 2020) {
    year = null;
  }

  if (isDef(month) && (month < 1 || month > 12)) {
    month = null;
  }

  if (isDef(day) && (day < 1 || day > 31)) {
    day = null;
  }

  return {
    year: isDef(year) ? yearStr : '',
    month: isDef(month) ? monthStr : '',
    day: isDef(day) ? dayStr : '',
  };
}

function rawFormatDate(v: TRawDatePickerState): string {
  return `${v.year}-${v.month}-${v.day}`;
}
