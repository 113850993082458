import { NetworkStatus } from '@apollo/client';
import type { TAgentOrderBackOrderCompleteWithOrderSummaryFragment } from '@src/gen/graphql/bindings';
import { useAgentOrderBackOrdersWaitingQuery } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TAgentOrderBackOrdersWaitingContext = {
  waitingForCustomerDecision: TAgentOrderBackOrderCompleteWithOrderSummaryFragment[];
  waitingForRemoval: TAgentOrderBackOrderCompleteWithOrderSummaryFragment[];
  waitingForShipment: TAgentOrderBackOrderCompleteWithOrderSummaryFragment[];
};

export const { Context: AgentOrderBackOrdersWaitingContext, useContext: useAgentOrderBackOrdersWaiting } =
  createRequiredContext<TAgentOrderBackOrdersWaitingContext>();

export type TAgentOrderBackOrdersWaitingProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function AgentOrderBackOrdersWaitingProvider({
  LoaderComponent,
  children,
}: TAgentOrderBackOrdersWaitingProvider): JSX.Element {
  const { data, error, networkStatus } = useAgentOrderBackOrdersWaitingQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  const value = useMemo<TAgentOrderBackOrdersWaitingContext | undefined>(
    () =>
      isDef(data)
        ? {
            waitingForCustomerDecision: data.waitingForCustomerDecision,
            waitingForRemoval: data.waitingForRemoval,
            waitingForShipment: data.waitingForShipment,
          }
        : undefined,
    // @sort
    [data],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentOrderBackOrdersWaitingContext.Provider value={value}>{children}</AgentOrderBackOrdersWaitingContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
