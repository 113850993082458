import { ActionButton } from '@src/components/appearance/controls/Button';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderReturnCard } from '@src/components/mixins/cards/OrderReturnCard';
import type { TCustomerOrderReturnCompleteFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderReturnsStructureGroupBase = {
  allOrderReturns: TCustomerOrderReturnCompleteFragment[];
  beginOrderReturnAdd: TCustomerOrderActionsTypes['BeginOrderReturnAdd'] | null;
  beginOrderReturnProductView:
    | TAgentOrderActionsTypes['BeginOrderReturnProductView']
    | TCustomerOrderActionsTypes['BeginOrderReturnProductView'];
  beginOrderReturnSeeInstructionsAsyncNotify: TCustomerOrderActionsTypes['BeginOrderReturnSeeInstructionsAsyncNotify'];
  doOrderReturnExecuteAsyncNotify: TCustomerOrderActionsTypes['DoOrderReturnExecuteAsyncNotify'] | null;
  doesOrderHaveInvoice: boolean;
  mode: 'customer-awaiting' | 'customer-other';
};

export type TOrderReturnsStructureGroup = TProps<false, TOrderReturnsStructureGroupBase, 'div'>;
export const ORDER_RETURNS_STRUCTURE_GROUP_CLASS_NAME = 'wp-order-returns-structure-group';

export const OrderReturnsStructureGroup = withCssToString(
  ORDER_RETURNS_STRUCTURE_GROUP_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderReturnsStructureGroup>(
      (
        {
          allOrderReturns,
          beginOrderReturnAdd,
          beginOrderReturnProductView,
          beginOrderReturnSeeInstructionsAsyncNotify,
          doOrderReturnExecuteAsyncNotify,
          doesOrderHaveInvoice,
          mode,
          className,
          ...rest
        },
        ref,
      ): JSX.Element | null => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_RETURNS_STRUCTURE_GROUP_CLASS_NAME),
          [className],
        );

        const orderReturns = useMemo(
          () =>
            allOrderReturns.filter((or) =>
              mode === 'customer-awaiting'
                ? isDef(or.replied_at) && !isDef(or.executed_at)
                : (!isDef(or.replied_at) || isDef(or.executed_at)) && !isDef(or.applied_at),
            ),
          [allOrderReturns, mode],
        );

        if (orderReturns.length === 0 && (!doesOrderHaveInvoice || mode !== 'customer-other')) {
          return null;
        }

        return (
          <Structure.Group
            {...rest}
            className={joinedClassName}
            ref={ref}
            title={`Returns${mode === 'customer-awaiting' ? ' - Awaiting Shipping' : ''}`}>
            {doesOrderHaveInvoice && mode === 'customer-other' && (
              <ToolBar>
                <ActionButton
                  action={{
                    isAsync: false,
                    onClick: beginOrderReturnAdd ?? noop,
                    text: 'Initiate Return',
                    variant: isDef(beginOrderReturnAdd) ? 'default' : 'disabled',
                  }}
                />
              </ToolBar>
            )}
            {orderReturns.map((or) => (
              <OrderReturnCard
                key={or.id}
                beginOrderReturnProductView={beginOrderReturnProductView}
                downloadPictureAsyncNotify={null}
                onApplyAsyncNotify={null}
                onDeleteAsyncNotify={null}
                onExecuteAsyncNotify={
                  isDef(doOrderReturnExecuteAsyncNotify)
                    ? async (): Promise<void> => await doOrderReturnExecuteAsyncNotify({ orderReturnId: or.id })
                    : null
                }
                onReply={null}
                onSeeInstructionsAsyncNotify={async (): Promise<void> =>
                  await beginOrderReturnSeeInstructionsAsyncNotify({ orderReturnId: or.id })
                }
                orderReturn={or}
                variant='customer'
              />
            ))}
          </Structure.Group>
        );
      },
    ),
  ),
);
