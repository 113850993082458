import { Typography } from '@src/components/appearance/basics/Typography';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import { Item } from '@src/components/appearance/fragments/Item';
import { ItemButton } from '@src/components/appearance/fragments/ItemButton';
import { Items } from '@src/components/appearance/fragments/Items';
import type { AgentOrderSnapshotEntryManager } from '@src/gen/shared/data/agentOrders';
import type { CustomerOrderSnapshotEntryManager } from '@src/gen/shared/data/customerOrders';
import {
  getCombinedProductName,
  getSaleUnitQuantity,
  getSaleUnitQuantityWithOverrideAndFallbackPrefix,
} from '@src/gen/shared/data/snippets';
import { EOrderEntryPlanStatus, maybeGetOrderEntryPlanStatusLabel } from '@src/gen/shared/enums/orderEntryPlanStatus';
import { getSource, maybeGetSourceName } from '@src/gen/shared/enums/source';
import { formatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

export type TOrderSnapshotEntryProductCardBase = {
  beginOrderSnapshotEntryPlanQuantityEdit: TCustomerOrderActionsTypes['BeginOrderSnapshotEntryPlanQuantityEdit'] | null;
  beginOrderSnapshotEntryView:
    | TAgentOrderActionsTypes['BeginOrderSnapshotEntryView']
    | TCustomerOrderActionsTypes['BeginOrderSnapshotEntryView']
    | null;
  onClick?: (() => void) | undefined;
  orderSnapshotEntryManager: AgentOrderSnapshotEntryManager | CustomerOrderSnapshotEntryManager;
};

export type TOrderSnapshotEntryProductCard = TProps<false, TOrderSnapshotEntryProductCardBase, 'div'>;
export const ORDER_SNAPSHOT_ENTRY_PRODUCT_CARD_CLASS_NAME = 'wp-order-snapshot-entry-product-card';

export const OrderSnapshotEntryProductCard = withCssToString(
  ORDER_SNAPSHOT_ENTRY_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderSnapshotEntryProductCard>(
      (
        {
          beginOrderSnapshotEntryPlanQuantityEdit,
          beginOrderSnapshotEntryView,
          onClick,
          orderSnapshotEntryManager,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_SNAPSHOT_ENTRY_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const baseProduct = useMemo(() => orderSnapshotEntryManager.getBaseProduct(), [orderSnapshotEntryManager]);
        const planProduct = useMemo(() => orderSnapshotEntryManager.maybeGetPlanProduct(), [orderSnapshotEntryManager]);
        const customerVisibleNotes = orderSnapshotEntryManager.orderSnapshotEntry.customer_visible_notes;

        const iconButtonActions = useMemo(
          () =>
            getAvailableIconButtonActions([
              {
                isAsync: false,
                icon: 'edit',
                onClick: isDef(beginOrderSnapshotEntryPlanQuantityEdit)
                  ? (): void =>
                      beginOrderSnapshotEntryPlanQuantityEdit({
                        orderSnapshotEntryManager,
                      })
                  : undefined,
              },
            ]),
          [beginOrderSnapshotEntryPlanQuantityEdit, orderSnapshotEntryManager],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flush={true}
            iconButtonActions={iconButtonActions}
            interactive={isDef(onClick)}
            ref={ref}
            flapText={maybeGetOrderEntryPlanStatusLabel(orderSnapshotEntryManager.orderSnapshotEntry.plan_status)}>
            <Card.ContentArea>
              {isDef(beginOrderSnapshotEntryView) ? (
                <ItemButton
                  variant='card'
                  onClick={(): void => beginOrderSnapshotEntryView({ type: 'base', orderSnapshotEntryManager })}
                  item={{
                    caption: `${maybeGetSourceName(getSource(baseProduct.source))} · ${baseProduct.product_sku}`,
                    text: getCombinedProductName(baseProduct.name, baseProduct.secondary_name),
                  }}
                />
              ) : (
                <Item
                  item={{
                    caption: `${maybeGetSourceName(getSource(baseProduct.source))} · ${baseProduct.product_sku}`,
                    text: getCombinedProductName(baseProduct.name, baseProduct.secondary_name),
                  }}
                />
              )}
              {(orderSnapshotEntryManager.isPurchasedByWellplaece() ||
                orderSnapshotEntryManager.isPurchasedByCustomer()) && (
                <Items
                  items={[
                    {
                      caption: 'Quantity',
                      text: getSaleUnitQuantity(
                        orderSnapshotEntryManager.orderSnapshotEntry.quantity,
                        baseProduct.sale_unit,
                      ),
                    },
                    {
                      caption: 'Unit Price (Base)',
                      text: formatDollarsCurrency(ensureDef(orderSnapshotEntryManager.orderSnapshotEntry.unit_price)),
                    },
                    {
                      caption: 'Sub Total (Base)',
                      text: formatDollarsCurrency(orderSnapshotEntryManager.mustGetBaseSubTotal()),
                    },
                  ]}
                />
              )}
              {!isDef(planProduct) && isDef(customerVisibleNotes) && customerVisibleNotes !== '' && (
                <Fragment>
                  <Card.Separator />
                  <Typography.Note expanding={true} text={customerVisibleNotes} />
                </Fragment>
              )}
            </Card.ContentArea>
            {isDef(planProduct) && (
              <Card.Footer>
                {orderSnapshotEntryManager.orderSnapshotEntry.plan_status !==
                  EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER && (
                  <Fragment>
                    {isDef(beginOrderSnapshotEntryView) ? (
                      <ItemButton
                        variant='card-inverted'
                        onClick={(): void =>
                          beginOrderSnapshotEntryView({
                            type: 'plan',
                            orderSnapshotEntryManager,
                          })
                        }
                        item={{
                          caption: `${maybeGetSourceName(getSource(planProduct.source))} · ${planProduct.product_sku}`,
                          text: getCombinedProductName(planProduct.name, planProduct.secondary_name),
                        }}
                      />
                    ) : (
                      <Item
                        item={{
                          caption: `${maybeGetSourceName(getSource(planProduct.source))} · ${planProduct.product_sku}`,
                          text: getCombinedProductName(planProduct.name, planProduct.secondary_name),
                        }}
                      />
                    )}
                  </Fragment>
                )}
                <Items
                  items={[
                    {
                      caption: 'Quantity',
                      accent: isDef(orderSnapshotEntryManager.orderSnapshotEntry.customer_edited_plan_quantity)
                        ? 'warning'
                        : undefined,
                      text: getSaleUnitQuantityWithOverrideAndFallbackPrefix(
                        'TBD',
                        ensureDef(orderSnapshotEntryManager.orderSnapshotEntry.plan_quantity),
                        orderSnapshotEntryManager.orderSnapshotEntry.customer_edited_plan_quantity,
                        planProduct.sale_unit,
                      ),
                    },
                    {
                      caption: 'Unit Price (Wellplaece)',
                      text: formatDollarsCurrency(
                        ensureDef(orderSnapshotEntryManager.orderSnapshotEntry.plan_unit_price),
                      ),
                    },
                    {
                      caption: 'Sub Total (Wellplaece)',
                      accent: isDef(orderSnapshotEntryManager.orderSnapshotEntry.customer_edited_plan_quantity)
                        ? 'warning'
                        : undefined,
                      text: formatDollarsCurrency(orderSnapshotEntryManager.mustGetPlanSubTotal()),
                    },
                  ]}
                />
                {isDef(customerVisibleNotes) && customerVisibleNotes !== '' && (
                  <Fragment>
                    <Card.Separator />
                    <Typography.Note expanding={true} text={customerVisibleNotes} />
                  </Fragment>
                )}
              </Card.Footer>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);
