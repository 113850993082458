import { CheckBoxControl } from '@src/components/appearance/controls/CheckBox';
import { ConditionalControl } from '@src/components/appearance/controls/ConditionalControl';
import { CurrencyControl } from '@src/components/appearance/controls/Currency';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { TextAreaControl } from '@src/components/appearance/controls/TextArea';
import { TextBoxControl } from '@src/components/appearance/controls/TextBox';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersOrganizationActionsTypes } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TLocationAddFormValues = {
  locationName: string;
  orderCode: string;
  requiresPrebillApproval: boolean;
  requiresPrebillApprovalThreshold: number;
  hasAccountStewardship: boolean;
  shipTo: string;
  billTo: string;
  stripeCustomerId: string;
};

export type TLocationAddFormBase = {
  doCustomersLocationAddAsync: TAgentCustomersOrganizationActionsTypes['DoCustomersLocationAddAsync'];
  organizationId: string;
};

export type TLocationAddForm = TProps<false, TLocationAddFormBase, 'div'>;
export const LOCATION_ADD_FORM_CLASS_NAME = 'wp-location-add-form';

export const LocationAddForm = withCssToString(
  LOCATION_ADD_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TLocationAddForm>(
      ({ doCustomersLocationAddAsync, className, organizationId, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, LOCATION_ADD_FORM_CLASS_NAME), [className]);

        const initialValues = useMemo<TLocationAddFormValues>(
          () => ({
            locationName: '',
            orderCode: '',
            requiresPrebillApproval: false,
            requiresPrebillApprovalThreshold: 0,
            hasAccountStewardship: false,
            shipTo: '',
            billTo: '',
            stripeCustomerId: '',
          }),
          [],
        );

        const handleSubmit = useCallback<TFormSubmit<TLocationAddFormValues>>(
          async (values) => {
            await doCustomersLocationAddAsync({
              ...values,
              requiresPrebillApprovalThreshold: values.requiresPrebillApproval
                ? values.requiresPrebillApprovalThreshold
                : null,

              organizationId: organizationId,
            });
            return undefined;
          },
          [doCustomersLocationAddAsync, organizationId],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TLocationAddFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              requireChanges={true}
              submitButtonText='Save'>
              <TextBoxControl id='locationName' required={true} label='Location Name' />
              <TextBoxControl id='orderCode' required={true} label='Order Code' orderCode={true} />
              <CheckBoxControl id='requiresPrebillApproval' label='Requires Prebill Approval' />
              <ConditionalControl<boolean>
                subscription='requiresPrebillApproval'
                predicate={(requiresPrebillApproval): boolean => requiresPrebillApproval}
                render={(): JSX.Element => (
                  <CurrencyControl
                    id='requiresPrebillApprovalThreshold'
                    required={false}
                    label='Requires Prebill Approval Over Amount'
                  />
                )}
              />
              <CheckBoxControl id='hasAccountStewardship' label='Account Stewardship' />
              <TextAreaControl id='shipTo' label='Ship To' maxLength={1024} required={true} />
              <TextAreaControl id='billTo' label='Bill To' maxLength={1024} required={true} />
              <TextBoxControl
                id='stripeCustomerId'
                label='Stripe Customer ID'
                required={false}
                stripeCustomerId={true}
              />
            </Form>
          </div>
        );
      },
    ),
  ),
);
