import { Typography } from '@src/components/appearance/basics/Typography';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import type { TAgentOrderBaseFragment, TCustomerOrderBaseFragment } from '@src/gen/graphql/bindings';
import type { CustomerOrderSnapshotManager } from '@src/gen/shared/data/customerOrders';
import { getOrderReference } from '@src/gen/shared/data/snippets';
import { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { formatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderCardBase = {
  order: Pick<
    TAgentOrderBaseFragment | TCustomerOrderBaseFragment,
    'created_at' | 'creator' | 'is_emergency' | 'location' | 'reference_number' | 'status' | 'updated_at'
  >;
  orderSnapshotManager?: CustomerOrderSnapshotManager | null | undefined;
  to: string;
};

export type TOrderCard = TProps<false, TOrderCardBase, 'div'>;
export const ORDER_CARD_CLASS_NAME = 'wp-order-card';

export const OrderCard = withCssToString(
  ORDER_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderCard>(
      ({ order, orderSnapshotManager, to, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_CARD_CLASS_NAME), [className]);

        const orderRef = useMemo(
          () => getOrderReference(order.location.order_code, order.reference_number),
          // @sort
          [order.location.order_code, order.reference_number],
        );

        const items = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Location',
              text: order.location.name,
            },
            {
              caption: 'Placed By',
              text: `${order.creator.first_name} ${order.creator.last_name}`,
            },
            {
              caption: 'Placed On',
              text: { asDate: 'on', at: order.created_at },
            },
            {
              caption: 'Updated',
              text: { asDate: 'ago', at: order.updated_at },
            },
          ],
          // @sort
          [order.created_at, order.creator.first_name, order.creator.last_name, order.location.name, order.updated_at],
        );

        const itemsFooter = useMemo(() => {
          if (!isDef(orderSnapshotManager)) {
            return null;
          }

          const maybeEstimateAfterCustomerEdits = orderSnapshotManager.maybeGetEstimateAfterCustomerEdits();

          return isDef(maybeEstimateAfterCustomerEdits)
            ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              ([
                {
                  caption: 'Sub Total (Wellplaece)',
                  accent: 'warning',
                  text: formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_sub_total),
                },
                ...(maybeEstimateAfterCustomerEdits.wellplaece_donation > 0
                  ? [
                      {
                        caption: 'Shipping & Handling + Tax (Wellplaece)',
                        accent: 'warning',
                        text: `${formatDollarsCurrency(
                          maybeEstimateAfterCustomerEdits.wellplaece_fees,
                        )} + ${formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_tax)}`,
                      },
                      {
                        caption: 'Your Charitable Donation',
                        accent: 'warning',
                        text: `${formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_donation)}`,
                      },
                    ]
                  : [
                      {
                        caption: 'Shipping & Handling (Wellplaece)',
                        accent: 'warning',
                        text: formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_fees),
                      },
                      {
                        caption: 'Tax (Wellplaece)',
                        accent: 'warning',
                        text: formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_tax),
                      },
                    ]),
                {
                  caption: 'Total (Wellplaece)',
                  accent: 'warning',
                  text: `${formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_total)}`,
                },
              ] as unknown as TItemsBase['items'])
            : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              ([
                {
                  caption: 'Sub Total (Wellplaece)',
                  text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_sub_total),
                },
                ...(orderSnapshotManager.orderSnapshot.wellplaece_donation > 0
                  ? [
                      {
                        caption: 'Shipping & Handling + Tax (Wellplaece)',
                        text: `${formatDollarsCurrency(
                          orderSnapshotManager.orderSnapshot.wellplaece_fees,
                        )} + ${formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_tax)}`,
                      },
                      {
                        caption: 'Your Charitable Donation',
                        text: `${formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_donation)}`,
                      },
                    ]
                  : [
                      {
                        caption: 'Shipping & Handling (Wellplaece)',
                        text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_fees),
                      },
                      {
                        caption: 'Tax (Wellplaece)',
                        text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_tax),
                      },
                    ]),
                {
                  caption: 'Total (Wellplaece)',
                  text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_total),
                },
              ] as unknown as TItemsBase['items']);
        }, [orderSnapshotManager]);

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flapText={order.status}
            flapWarningText={order.status !== EOrderStatus.COMPLETED && order.is_emergency ? 'Emergency' : undefined}
            interactive={true}
            ref={ref}>
            <Card.LinkArea to={to}>
              <Card.Title title={orderRef} />
              <Items items={items} />
              {isDef(itemsFooter) && (
                <Card.Footer>
                  <Typography.Annotation text={'PREBILL SUMMARY WITH WELLPLAECE'.toUpperCase()} />
                  <Items items={itemsFooter} />
                  <Typography.Annotation text='Click on this card to visit order, review prebill, make changes, and approve.' />
                </Card.Footer>
              )}
            </Card.LinkArea>
          </Card.Container>
        );
      },
    ),
  ),
);

export const OrderCardLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Card.Container ref={ref}>
        <Card.TitleLoader />
        <ItemsLoader count={4} />
      </Card.Container>
    ),
  ),
);
