import { Root as RxSeparatorRoot } from '@radix-ui/react-separator';
import { Tag, TagLoader } from '@src/components/appearance/basics/Tag';
import { Typography } from '@src/components/appearance/basics/Typography';
import type { TIconButton } from '@src/components/appearance/controls/IconButton';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import { Select } from '@src/components/appearance/controls/Select';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import { CONTAINER_MEDIA_DESKTOP, CONTAINER_MEDIA_TABLET } from '@src/modules/design/breakpoints';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { ChangeEvent, ReactElement } from 'react';
import { Fragment, forwardRef, memo, useCallback, useMemo } from 'react';

const SDiv = styled('div', {
  containerType: 'inline-size',
});

const SInnerDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$secondaryNavSpaceMobile',

  [CONTAINER_MEDIA_DESKTOP]: {
    gap: '$secondaryNavSpaceDesktop',
  },
});

const STopDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',

  [CONTAINER_MEDIA_TABLET]: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: '$secondaryNavSpaceDesktop',
  },
});

const STitleDiv = styled('div', {
  alignItems: 'center',
  display: 'none',
  flexDirection: 'row',
  gap: '$secondaryNavDetailsSpaceMobile',

  '@desktop': {
    display: 'flex',
  },

  [CONTAINER_MEDIA_DESKTOP]: {
    gap: '$secondaryNavDetailsSpaceDesktop',
  },
});

const SDetailsDiv = styled('div', {
  alignItems: 'flex-end',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$secondaryNavDetailsSpaceMobile',
  justifyContent: 'center',

  [CONTAINER_MEDIA_DESKTOP]: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    gap: '$secondaryNavDetailsSpaceDesktop',
  },
});

const SDetailsHighlightTypographyStrong = memo(
  styled(Typography.Strong, {
    alignSelf: 'stretch',
    textAlign: 'right',

    [CONTAINER_MEDIA_DESKTOP]: {
      alignSelf: 'center',
    },
  }),
);

const SDetailsLabelTypographyRegular = memo(
  styled(Typography.Regular, {
    alignSelf: 'stretch',
    textAlign: 'right',

    [CONTAINER_MEDIA_DESKTOP]: {
      alignSelf: 'center',
    },
  }),
);

const SBottomDiv = styled('div', {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  gap: '$secondaryNavSpaceMobile',

  [CONTAINER_MEDIA_DESKTOP]: {
    gap: '$secondaryNavTabsGap',
  },

  variants: {
    display: {
      mobile: {
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',

        [CONTAINER_MEDIA_DESKTOP]: {
          display: 'none',
        },
      },
      desktop: {
        display: 'none',

        [CONTAINER_MEDIA_DESKTOP]: {
          display: 'flex',
          height: '$controlHeight',
        },
      },
    },
  },
});

const STabButton = styled('button', {
  all: 'unset',

  alignItems: 'center',
  borderBottomColor: 'transparent',
  borderBottomStyle: '$regular',
  borderBottomWidth: '$secondaryNavTab',
  color: '$secondaryNavTabText',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  text: '$subTitle',
  textDecoration: 'none',

  '&:hover': {
    color: '$secondaryNavTabHoverText',
  },

  variants: {
    active: {
      false: {
        '&:focus': {
          borderBottomColor: '$gray3',
        },
      },
      true: {
        borderBottomColor: '$secondaryNavTabActiveBorder',
        color: '$secondaryNavTabActiveText',
      },
    },
  },
});

const STabGroupTitle = memo(
  styled(Typography.Caption, {
    alignSelf: 'center',
  }),
);

const STabSeparator = memo(
  styled(RxSeparatorRoot, {
    alignSelf: 'stretch',
    width: '$separatorThickness',
    backgroundColor: '$separatorBackground',
  }),
);

export type TSecondaryNavTab = {
  active: boolean;
  onClick: () => void;
  title: string;
};

export type TSecondaryNavBase = {
  IconButtonElement?: ReactElement<TIconButton> | undefined;
  ExtraIconButtonElement?: ReactElement<TIconButton> | undefined;
  additionalTabs?: TSecondaryNavTab[] | undefined;
  additionalTabsTitle?: string | undefined;
  highlight?: string | undefined;
  label?: string | undefined;
  onBack?: (() => void) | undefined;
  tabs?: TSecondaryNavTab[] | undefined;
  tag?: string | undefined;
  errorTag?: string | undefined;
  tagLoader?: boolean | undefined;
  title: string;
};

const Tabs = memo(
  ({
    additionalTabs = [],
    additionalTabsTitle = '',
    tabs = [],
  }: Pick<TSecondaryNavBase, 'additionalTabs' | 'additionalTabsTitle' | 'tabs'>): JSX.Element => {
    if (tabs.length === 0 && additionalTabs.length === 0) {
      return <Fragment />;
    }

    return (
      <SBottomDiv display='desktop'>
        {tabs.map((tab) => (
          <STabButton key={tab.title} active={tab.active} onClick={tab.onClick}>
            {tab.title}
          </STabButton>
        ))}
        {(additionalTabsTitle !== '' || additionalTabs.length > 0) && (
          <Fragment>
            {additionalTabsTitle !== '' && (
              <Fragment>
                <STabSeparator />
                <STabGroupTitle text='Add From' />
              </Fragment>
            )}
            {additionalTabs.map((tab) => (
              <STabButton key={tab.title} active={tab.active} onClick={tab.onClick}>
                {tab.title}
              </STabButton>
            ))}
          </Fragment>
        )}
      </SBottomDiv>
    );
  },
);

const Switcher = memo(
  ({
    additionalTabs = [],
    additionalTabsTitle = '',
    tabs = [],
  }: Pick<TSecondaryNavBase, 'additionalTabs' | 'additionalTabsTitle' | 'tabs'>): JSX.Element => {
    const value = useMemo(() => [...tabs, ...additionalTabs].find((tab) => tab.active)?.title, [tabs, additionalTabs]);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLSelectElement>) =>
        [...tabs, ...additionalTabs].find((tab) => tab.title === e.target.value)?.onClick(),
      [tabs, additionalTabs],
    );

    if (tabs.length === 0 && additionalTabs.length === 0) {
      return <Fragment />;
    }

    return (
      <SBottomDiv display='mobile'>
        <Select value={value} onChange={handleChange}>
          {tabs.map((tab) => (
            <option key={tab.title} value={tab.title}>
              {tab.title}
            </option>
          ))}
          {additionalTabsTitle !== '' ? (
            <optgroup label={additionalTabsTitle}>
              {additionalTabs.map((tab) => (
                <option key={tab.title} value={tab.title}>
                  {tab.title}
                </option>
              ))}
            </optgroup>
          ) : (
            additionalTabs.map((tab) => (
              <option key={tab.title} value={tab.title}>
                {tab.title}
              </option>
            ))
          )}
        </Select>
      </SBottomDiv>
    );
  },
);

export type TSecondaryNav = TProps<false, TSecondaryNavBase, 'div'>;
export const SECONDARY_NAV_CLASS_NAME = 'wp-secondary-nav';

export const SecondaryNav = withCssToString(
  SECONDARY_NAV_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TSecondaryNav>(
      (
        {
          IconButtonElement,
          ExtraIconButtonElement,
          additionalTabs,
          additionalTabsTitle,
          highlight,
          label,
          onBack,
          tabs,
          tag,
          tagLoader,
          errorTag,
          title,
          className,
          ...rest
        },
        ref,
      ) => {
        const joinedClassName = useMemo(() => joinClassNames(className, SECONDARY_NAV_CLASS_NAME), [className]);

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            <SInnerDiv>
              <STopDiv>
                <STitleDiv>
                  {isDef(onBack) && <IconButton icon='back' onClick={onBack} />}
                  <Typography.Title text={title} />
                </STitleDiv>
                <SDetailsDiv>
                  {isDef(highlight) && <SDetailsHighlightTypographyStrong rigid={true} text={highlight} />}
                  {isDef(label) && <SDetailsLabelTypographyRegular rigid={true} text={label} />}
                  {isDef(tag) && <Tag text={tag} />}
                  {tagLoader === true && <TagLoader />}
                  {isDef(errorTag) && <Tag accent='error' text={errorTag} />}
                  {isDef(IconButtonElement) && <Fragment>{IconButtonElement}</Fragment>}
                  {isDef(ExtraIconButtonElement) && <Fragment>{ExtraIconButtonElement}</Fragment>}
                </SDetailsDiv>
              </STopDiv>
              <Tabs additionalTabs={additionalTabs} additionalTabsTitle={additionalTabsTitle} tabs={tabs} />
              <Switcher additionalTabs={additionalTabs} additionalTabsTitle={additionalTabsTitle} tabs={tabs} />
            </SInnerDiv>
          </SDiv>
        );
      },
    ),
  ),
);
