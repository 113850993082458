import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import { Select } from '@src/components/appearance/controls/Select';
import type { TTabSelectBase } from '@src/components/appearance/controls/TabSelect';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { AnalyticsOrdersDashboard } from '@src/components/mixins/dashboards/AnalyticsOrdersDashboard';
import { getDateDate } from '@src/gen/shared/data/snippets';
import { EExportFormat } from '@src/gen/shared/enums/exportFormat';
import { isDef } from '@src/gen/shared/utils/types';
import { useAuthCustomerOrganization } from '@src/modules/auth/AuthProvider';
import type { TCustomerAnalyticsOrdersActionsTypes } from '@src/modules/data/customer/analytics/CustomerAnalyticsOrdersActionsProvider';
import { useCustomerAnalyticsOrdersActions } from '@src/modules/data/customer/analytics/CustomerAnalyticsOrdersActionsProvider';
import { useCustomerAnalyticsOrders } from '@src/modules/data/customer/analytics/CustomerAnalyticsOrdersProvider';
import type { TDateRangePickerDrawerTypes } from '@src/modules/data/shared/overlays/DateRangePickerDrawerProvider';
import { useDateRangePickerDrawer } from '@src/modules/data/shared/overlays/DateRangePickerDrawerProvider';
import {
  encodeCustomerAnalyticsOrdersPath,
  useCustomerAnalyticsOrdersRouteParams,
} from '@src/modules/routing/customer';
import { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function CustomerAnalyticsOrdersPart(): JSX.Element {
  const { dashboard } = useCustomerAnalyticsOrders();
  const { doGenerateOrderTo, downloadOrderSnapshotAsyncNotify, downloadOrdersDashboardExportAsyncNotify } =
    useCustomerAnalyticsOrdersActions();

  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav title='Analytics' />
      </Structure.Content>
      <Structure.Content>
        <Filters disabled={false} downloadOrdersDashboardExportAsyncNotify={downloadOrdersDashboardExportAsyncNotify} />
      </Structure.Content>
      <Structure.ScrollContent skipSeparator={true}>
        <AnalyticsOrdersDashboard
          dashboard={dashboard}
          doGenerateOrderTo={doGenerateOrderTo}
          downloadOrderSnapshotAsyncNotify={downloadOrderSnapshotAsyncNotify}
        />
      </Structure.ScrollContent>
    </Fragment>
  );
}

function Filters({
  disabled,
  downloadOrdersDashboardExportAsyncNotify,
}: {
  disabled: boolean;
  downloadOrdersDashboardExportAsyncNotify:
    | TCustomerAnalyticsOrdersActionsTypes['DownloadOrdersDashboardExportAsyncNotify']
    | null;
}): JSX.Element {
  const navigate = useNavigate();
  const routeParams = useCustomerAnalyticsOrdersRouteParams();
  const organization = useAuthCustomerOrganization(routeParams.organizationId);
  const { beginDateRangePick } = useDateRangePickerDrawer();

  const handleLocationSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeCustomerAnalyticsOrdersPath(routeParams.organizationId, {
          ...routeParams.config,
          locationId: e.target.value === 'all' ? null : e.target.value,
        }),
      );
    },
    [navigate, routeParams.config, routeParams.organizationId],
  );

  const handleDateRangeChange = useCallback<TDateRangePickerDrawerTypes['BeginLocationPickCallback']>(
    (range) => {
      navigate(
        encodeCustomerAnalyticsOrdersPath(routeParams.organizationId, {
          ...routeParams.config,
          ...range,
        }),
      );
    },
    [navigate, routeParams.config, routeParams.organizationId],
  );

  const downloadAsyncNotify = useCallback(
    async (exportFormat: EExportFormat): Promise<void> => {
      await downloadOrdersDashboardExportAsyncNotify?.({
        organizationId: routeParams.organizationId,
        locationId: routeParams.config.locationId,
        startTime: routeParams.config.startTime,
        endTime: routeParams.config.endTime,
        exportFormat,
      });
    },
    [
      downloadOrdersDashboardExportAsyncNotify,
      routeParams.config.endTime,
      routeParams.config.locationId,
      routeParams.config.startTime,
      routeParams.organizationId,
    ],
  );

  const datesFilterText = useMemo(() => {
    if (isDef(routeParams.config.startTime) && isDef(routeParams.config.endTime)) {
      return `${getDateDate(routeParams.config.startTime)} — ${getDateDate(routeParams.config.endTime)} (Inclusive)`;
    } else if (!isDef(routeParams.config.startTime) && isDef(routeParams.config.endTime)) {
      return `Before ${getDateDate(routeParams.config.endTime)} (Inclusive)`;
    } else if (isDef(routeParams.config.startTime) && !isDef(routeParams.config.endTime)) {
      return `After ${getDateDate(routeParams.config.startTime)} (Inclusive)`;
    } else {
      return 'All Time';
    }
  }, [routeParams.config.endTime, routeParams.config.startTime]);

  return (
    <ToolBar
      variant='page'
      RightChildren={
        <Fragment>
          <ActionIconButton
            action={{
              isAsync: true,
              icon: 'download',
              annotation: 'JSON',
              variant: isDef(downloadOrdersDashboardExportAsyncNotify) ? 'default' : 'disabled',
              onClick: async (): Promise<void> => await downloadAsyncNotify(EExportFormat.JSON),
            }}
          />
          <ActionIconButton
            action={{
              isAsync: true,
              icon: 'download',
              annotation: 'XLSX',
              variant: isDef(downloadOrdersDashboardExportAsyncNotify) ? 'default' : 'disabled',
              onClick: async (): Promise<void> => await downloadAsyncNotify(EExportFormat.XLSX),
            }}
          />
        </Fragment>
      }>
      <Select disabled={disabled} onChange={handleLocationSelectChange} value={routeParams.config.locationId ?? 'all'}>
        {organization.acl.can_view_analytics ? (
          <Fragment>
            <option value='all'>Location: All</option>
            {organization.allLocations.map((loc) => (
              <option key={loc.id} value={loc.id}>
                Location: {loc.name}
              </option>
            ))}
          </Fragment>
        ) : (
          <Fragment>
            {organization.locations
              .filter((loc) => loc.acl.can_view_analytics)
              .map((loc) => (
                <option key={loc.id} value={loc.id}>
                  Location: {loc.name}
                </option>
              ))}
          </Fragment>
        )}
      </Select>
      <ControlButton
        icon='funnel'
        variant={disabled ? 'disabled' : 'default'}
        onClick={(): void => beginDateRangePick({ callback: handleDateRangeChange })}
        text={datesFilterText}
      />
    </ToolBar>
  );
}

export function CustomerAnalyticsOrdersPartLoader(): JSX.Element {
  return (
    <Structure.Content>
      <SecondaryNav title='Analytics' />
      <Filters disabled={true} downloadOrdersDashboardExportAsyncNotify={null} />
    </Structure.Content>
  );
}
