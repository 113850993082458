import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { CategoryTree } from '@src/components/appearance/fragments/CategoryTree';
import { Description } from '@src/components/appearance/fragments/Description';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { TAgentOrderProductBaseFragment, TCustomerOrderProductBaseFragment } from '@src/gen/graphql/bindings';
import { castDescriptionUnsafe } from '@src/gen/shared/data/description';
import { getSaleUnitQuantity } from '@src/gen/shared/data/snippets';
import { castSpecsUnsafe } from '@src/gen/shared/data/specs';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { getSpecsItems, joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '256px',
});

export type TOrderProductDrawerPanelBase = {
  orderProduct: Pick<
    TAgentOrderProductBaseFragment | TCustomerOrderProductBaseFragment,
    | 'category_name'
    | 'description'
    | 'image_asset_path'
    | 'is_substitution_allowed'
    | 'manufacturer_name'
    | 'manufacturer_sku'
    | 'name'
    | 'product_sku'
    | 'quantity'
    | 'sale_unit'
    | 'sds_asset_path'
    | 'secondary_name'
    | 'source'
    | 'specs'
  >;
};

export type TOrderProductDrawerPanel = TProps<false, TOrderProductDrawerPanelBase, 'div'>;
export const ORDER_PRODUCT_DRAWER_PANEL_CLASS_NAME = 'wp-order-product-drawer-panel';

export const OrderProductDrawerPanel = withCssToString(
  ORDER_PRODUCT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderProductDrawerPanel>(({ orderProduct, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, ORDER_PRODUCT_DRAWER_PANEL_CLASS_NAME),
        [className],
      );

      const productItems = useMemo<TItems['items']>(
        () => [
          {
            caption: 'Catalog',
            text: maybeGetSourceName(orderProduct.source),
          },
          {
            caption: 'SKU',
            text: orderProduct.product_sku,
          },
          {
            caption: 'Manufacturer',
            text: orderProduct.manufacturer_name ?? 'N/A',
          },
          {
            caption: 'Manufacturer SKU',
            text: orderProduct.manufacturer_sku ?? 'N/A',
          },
        ],
        // @sort
        [orderProduct.manufacturer_name, orderProduct.manufacturer_sku, orderProduct.product_sku, orderProduct.source],
      );

      const orderItems = useMemo<TItems['items']>(
        () => [
          {
            caption: 'Quantity',
            text: getSaleUnitQuantity(orderProduct.quantity, orderProduct.sale_unit),
          },
          {
            caption: 'Allow Substitutions',
            text: orderProduct.is_substitution_allowed ? 'Yes' : 'No',
          },
        ],
        // @sort
        [orderProduct.is_substitution_allowed, orderProduct.quantity, orderProduct.sale_unit],
      );

      const productDescriptionBlocks = useMemo(
        () => castDescriptionUnsafe(orderProduct.description),
        [orderProduct.description],
      );

      const specsItems = useMemo<TItemBaseItem[]>(
        () => getSpecsItems(castSpecsUnsafe(orderProduct.specs)),
        [orderProduct.specs],
      );

      return (
        <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
          <Drawer.Header title='Product' />
          <Drawer.ScrollContent>
            <SImageAsset alt={orderProduct.name} imageAssetPath={orderProduct.image_asset_path} />
            <Drawer.Separator />
            <Drawer.Group>
              <Drawer.Title subTitle={orderProduct.secondary_name} title={orderProduct.name} />
              <Items items={productItems} />
            </Drawer.Group>
            <Drawer.Separator />
            <Items items={orderItems} />
            <Drawer.Separator />
            <Drawer.Group title='Category'>
              <CategoryTree category={orderProduct.category_name} />
            </Drawer.Group>
            {productDescriptionBlocks.length > 0 && (
              <Drawer.Group title='Description'>
                <Description description={productDescriptionBlocks} />
              </Drawer.Group>
            )}
            {specsItems.length > 0 && (
              <Drawer.Group title='Specifications'>
                {specsItems.map((item) => (
                  <Item item={item} key={item.caption} />
                ))}
              </Drawer.Group>
            )}
            {isDef(orderProduct.sds_asset_path) && (
              <Drawer.Group title='Documents'>
                <ControlButton icon='download' text='Safety Data Sheet' />
              </Drawer.Group>
            )}
          </Drawer.ScrollContent>
        </Drawer.Panel>
      );
    }),
  ),
);

const SLoaderTopDiv = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const OrderProductDrawerPanelLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Drawer.Panel ref={ref}>
        <Drawer.Header title='Product' />
        <Drawer.ScrollContentLoader>
          <SLoaderTopDiv>
            <Illustrations.Loader height={256} uniqueKey='wp-order-product-drawer-panel-loader-image' width={320}>
              <rect x='0' y='5' rx='0' ry='0' width='320' height='256' />
            </Illustrations.Loader>
          </SLoaderTopDiv>
          <Drawer.Separator />
          <Drawer.Group>
            <Drawer.TitleLoader />
            <ItemsLoader count={4} />
          </Drawer.Group>
          <Drawer.Separator />
          <Drawer.Group>
            <ItemsLoader count={2} />
          </Drawer.Group>
        </Drawer.ScrollContentLoader>
      </Drawer.Panel>
    ),
  ),
);
