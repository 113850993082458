import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import type { TCustomerOrdersDashboardOrdersInvoice } from '@src/gen/shared/data/dashboards';
import { getOrderReference } from '@src/gen/shared/data/snippets';
import { EExportFormat } from '@src/gen/shared/enums/exportFormat';
import { formatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrdersDashboardInvoiceCardBase = {
  invoice: TCustomerOrdersDashboardOrdersInvoice;
  to: string | null;
  downloadAsyncNotify: ((format: EExportFormat) => Promise<void>) | null;
};

export type TOrdersDashboardInvoiceCard = TProps<false, TOrdersDashboardInvoiceCardBase, 'div'>;
export const ORDERS_DASHBOARD_INVOICE_CARD_CLASS_NAME = 'wp-orders-dashboard-invoice-card';

export const OrdersDashboardInvoiceCard = withCssToString(
  ORDERS_DASHBOARD_INVOICE_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrdersDashboardInvoiceCard>(
      ({ invoice, to, downloadAsyncNotify, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDERS_DASHBOARD_INVOICE_CARD_CLASS_NAME),
          [className],
        );

        const items = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Revision',
              text: invoice.revision > 0 ? `#${invoice.revision}` : 'Initial',
            },
            {
              caption: 'Issued On',
              text: { asDate: 'on', at: invoice.issuedAt },
            },
            {
              caption: 'Wellplaece Total',
              text: `${formatDollarsCurrency(invoice.wellplaeceTotal)}`,
            },
          ],
          // @sort
          [invoice.issuedAt, invoice.revision, invoice.wellplaeceTotal],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () =>
            getAvailableIconButtonActions([
              {
                isAsync: true,
                icon: 'download',
                annotation: 'PDF',
                onClick: isDef(downloadAsyncNotify)
                  ? async (): Promise<void> => await downloadAsyncNotify(EExportFormat.PDF)
                  : undefined,
              },
              {
                isAsync: true,
                icon: 'download',
                annotation: 'XLSX',
                onClick: isDef(downloadAsyncNotify)
                  ? async (): Promise<void> => await downloadAsyncNotify(EExportFormat.XLSX)
                  : undefined,
              },
            ]),
          [downloadAsyncNotify],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flush={true}
            iconButtonActions={iconButtonActions}
            interactive={isDef(to)}
            ref={ref}>
            {isDef(to) ? (
              <Card.LinkArea to={to}>
                <Card.Title
                  preTitle='Order'
                  subTitle={`for ${invoice.locationName}`}
                  title={getOrderReference(invoice.locationOrderCode, invoice.orderReferenceNumber)}
                />
                <Items items={items} />
              </Card.LinkArea>
            ) : (
              <Card.ContentArea>
                <Card.Title
                  preTitle='Order'
                  subTitle={`for ${invoice.locationName}`}
                  title={getOrderReference(invoice.locationOrderCode, invoice.orderReferenceNumber)}
                />
                <Items items={items} />
              </Card.ContentArea>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);
