import { Drawer } from '@src/components/appearance/structure/Drawer';
import { OrganizationSourcePickerDrawerPanel } from '@src/components/mixins/drawers/OrganizationSourcePickerDrawerPanel';
import { ESource } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TAgentOrganizationSourcePickerDrawerTypes = {
  BeginOrganizationSourcePickCallback: (source: ESource) => Promise<void>;
  BeginOrganizationSourcePickArgs: {
    buttonActionText?: string | undefined;
    callback: TAgentOrganizationSourcePickerDrawerTypes['BeginOrganizationSourcePickCallback'];
    drawerHeaderTitle?: string | undefined;
    omitSources?: { [key in ESource]?: true } | undefined;
  };
  BeginOrganizationSourcePick: (
    args: TAgentOrganizationSourcePickerDrawerTypes['BeginOrganizationSourcePickArgs'],
  ) => void;
};

export type TAgentOrganizationSourcePickerDrawerContext = {
  beginOrganizationSourcePick: TAgentOrganizationSourcePickerDrawerTypes['BeginOrganizationSourcePick'];
};

export const {
  Context: AgentOrganizationSourcePickerDrawerContext,
  useContext: useAgentOrganizationSourcePickerDrawer,
} = createRequiredContext<TAgentOrganizationSourcePickerDrawerContext>();

export function AgentOrganizationSourcePickerDrawerProvider({
  children,
}: PropsWithChildren<TEmptyObject>): JSX.Element {
  const [state, setState] = useState<
    TAgentOrganizationSourcePickerDrawerTypes['BeginOrganizationSourcePickArgs'] | undefined
  >(undefined);

  const handleOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setState(undefined);
    }
  }, []);

  const handleCallback = useCallback<TAgentOrganizationSourcePickerDrawerTypes['BeginOrganizationSourcePickCallback']>(
    async (source): Promise<void> => {
      await state?.callback(source);
      setState(undefined);
    },
    [state],
  );

  const value = useMemo<TAgentOrganizationSourcePickerDrawerContext>(
    () => ({
      beginOrganizationSourcePick: setState,
    }),
    [],
  );

  return (
    <AgentOrganizationSourcePickerDrawerContext.Provider value={value}>
      <Drawer.Root open={isDef(state)} onOpenChange={handleOpenChange}>
        {isDef(state) && (
          <ConsumerComponent
            buttonActionText={state.buttonActionText}
            callback={handleCallback}
            drawerHeaderTitle={state.drawerHeaderTitle}
            omitSources={state.omitSources}
          />
        )}
      </Drawer.Root>
      {children}
    </AgentOrganizationSourcePickerDrawerContext.Provider>
  );
}

function ConsumerComponent({
  buttonActionText,
  callback,
  drawerHeaderTitle,
  omitSources,
}: TAgentOrganizationSourcePickerDrawerTypes['BeginOrganizationSourcePickArgs']): JSX.Element {
  const filteredSources = useMemo(() => {
    return Object.values(ESource).filter((source) => !isDef(omitSources?.[source]) && source !== ESource.TEST);
  }, [omitSources]);

  return (
    <OrganizationSourcePickerDrawerPanel
      buttonActionText={buttonActionText}
      drawerHeaderTitle={drawerHeaderTitle}
      onSelectSource={callback}
      sources={filteredSources}
    />
  );
}
