import { DismissableTag } from '@src/components/appearance/basics/DismissableTag';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import { TextBox } from '@src/components/appearance/controls/TextBox';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { usePrevious, withCssToString } from '@src/logic/internal/utils/utils';
import { CONTAINER_MEDIA_DESKTOP } from '@src/modules/design/breakpoints';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';

const SOuterDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '$searchGap',
  containerType: 'inline-size',
});

const SInnerDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$searchGap',
});

const STextBox = styled(TextBox, {
  flexGrow: 1,
});

const SDismissableTag = styled(DismissableTag, {
  alignSelf: 'flex-start',
});

const STags = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  flexWrap: 'wrap',
});

const SFiltersIconButton = styled(IconButton, {
  [CONTAINER_MEDIA_DESKTOP]: {
    display: 'none',
  },
});

export type TSearchBaseChip = {
  label: string;
  onClose: () => void;
};

export type TSearchBase = {
  searchQuery: string | null;
  onSearch: (searchQuery: string | null) => void;
  onFilters?: (() => void) | null | undefined;
  chips?: TSearchBaseChip[] | null | undefined;
};

export type TSearch = TProps<false, TSearchBase, 'div'>;
export const SEARCH_CLASS_NAME = 'wp-search';

export const Search = withCssToString(
  SEARCH_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TSearch>(
      ({ className, chips, searchQuery, onSearch, onFilters, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, SEARCH_CLASS_NAME), [className]);
        const [internalSearchQuery, setInternalSearchQuery] = useState(searchQuery ?? '');
        const prevSearchQuery = usePrevious(searchQuery);

        const handleChange = useCallback(
          (e: ChangeEvent<HTMLInputElement>) => setInternalSearchQuery(e.target.value),
          [],
        );

        const handleKeyPress = useCallback(
          (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter') {
              onSearch(internalSearchQuery !== '' ? internalSearchQuery : null);
            }
          },
          // @sort
          [internalSearchQuery, onSearch],
        );

        const handleIconButtonClick = useCallback(() => {
          onSearch(internalSearchQuery !== '' ? internalSearchQuery : null);
        }, [onSearch, internalSearchQuery]);

        const handleDismissableTagClick = useCallback(() => {
          onSearch(null);
        }, [onSearch]);

        useEffect(
          () => {
            if (prevSearchQuery !== undefined && prevSearchQuery !== searchQuery) {
              if (searchQuery === null && internalSearchQuery !== '') {
                setInternalSearchQuery('');
              }

              if (searchQuery !== null && searchQuery !== internalSearchQuery) {
                setInternalSearchQuery(searchQuery);
              }
            }
          },
          // @sort
          [internalSearchQuery, prevSearchQuery, searchQuery],
        );

        return (
          <SOuterDiv {...rest} className={joinedClassName} ref={ref}>
            <SInnerDiv>
              <STextBox
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                type='text'
                value={internalSearchQuery}
                css={{ flexGrow: 1 }}
              />
              <IconButton icon='search' onClick={handleIconButtonClick} />
              {isDef(onFilters) && <SFiltersIconButton icon='funnel' onClick={onFilters} />}
            </SInnerDiv>
            {(isDef(searchQuery) || (isDef(chips) && chips.length > 0)) && (
              <STags>
                {isDef(searchQuery) && <SDismissableTag text={searchQuery} onClick={handleDismissableTagClick} />}
                {chips?.map((chip) => (
                  <SDismissableTag key={chip.label} text={chip.label} onClick={chip.onClose} />
                ))}
              </STags>
            )}
          </SOuterDiv>
        );
      },
    ),
  ),
);
