import type {
  TAgentOrderForBudgetAssistantFragment,
  TCustomerOrderForBudgetAssistantFragment,
} from '@src/gen/graphql/bindings';
import { getDate, getOrderReference } from '@src/gen/shared/data/snippets';
import { EOrderSnapshotType } from '@src/gen/shared/enums/orderSnapshotType';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { format } from 'date-fns';

export type TOrderBudgetAssistantManagerSummary = {
  orderReference: string;
  placedOn: Date;
  status: string;
  wellplaeceTotal: number | null;
  isCustomerEdited: boolean;
};

export type TOrderBudgetAssistantManagerResult = {
  summaries: TOrderBudgetAssistantManagerSummary[];
  invoiced: number;
  toBeFinalized: number;
  estimatedTotal: number;
  preparingCount: number;
};

export class OrderBudgetAssistantManager {
  public readonly monthYearLabel: string | null;

  public constructor(
    public readonly currentOrderId: string,
    public readonly locationOrderCode: string,
    public readonly orders: (TAgentOrderForBudgetAssistantFragment | TCustomerOrderForBudgetAssistantFragment)[] | null,
  ) {
    this.monthYearLabel =
      isDef(orders) && orders.length > 0 ? format(getDate(ensureDef(orders[0]).created_at), 'LLLL yyyy') : null;
  }

  public getResult(overrides: { [orderId: string]: number }): TOrderBudgetAssistantManagerResult {
    const summaries: TOrderBudgetAssistantManagerSummary[] = isDef(this.orders)
      ? this.orders.map((o) => ({
          orderReference: `${getOrderReference(this.locationOrderCode, o.reference_number)}${
            o.id === this.currentOrderId ? ' (This Order)' : ''
          }`,
          placedOn: getDate(o.created_at),
          status: OrderBudgetAssistantManager.getStatusLabel(o),
          wellplaeceTotal:
            isDef(o.latest_order_snapshot) && !OrderBudgetAssistantManager.isRetractedPrebill(o)
              ? isDef(overrides[o.id])
                ? ensureDef(overrides[o.id])
                : o.latest_order_snapshot.wellplaece_total
              : null,
          isCustomerEdited: isDef(overrides[o.id]),
        }))
      : [];

    const invoiced = summaries
      .filter((s) => isDef(s.wellplaeceTotal) && s.status === 'Invoiced')
      .reduce((out, o) => out + ensureDef(o.wellplaeceTotal), 0);

    const toBeFinalized = summaries
      .filter((s) => isDef(s.wellplaeceTotal) && s.status.startsWith('Prebill'))
      .reduce((out, o) => out + ensureDef(o.wellplaeceTotal), 0);

    return {
      summaries,
      invoiced,
      toBeFinalized,
      estimatedTotal: invoiced + toBeFinalized,
      preparingCount: summaries.filter((s) => s.status === 'Preparing').length,
    };
  }

  private static getStatusLabel(
    order: TCustomerOrderForBudgetAssistantFragment | TAgentOrderForBudgetAssistantFragment,
  ): string {
    if (!isDef(order.latest_order_snapshot)) {
      return 'Preparing';
    }

    if (
      order.latest_order_snapshot.type === EOrderSnapshotType.INVOICE ||
      order.latest_order_snapshot.type === EOrderSnapshotType.BACKFILL_INVOICE
    ) {
      return 'Invoiced';
    }

    if (isDef(order.latest_order_snapshot.rejected_at)) {
      if (order.latest_order_snapshot.rejector?.is_wellplaece_agent === true) {
        return 'Preparing';
      }

      return 'Prebill (Rejected, Updating)';
    }

    if (isDef(order.latest_order_snapshot.approved_at)) {
      return 'Prebill (Approved)';
    }

    return 'Prebill (Awaiting Approval)';
  }

  private static isRetractedPrebill(
    order: TAgentOrderForBudgetAssistantFragment | TCustomerOrderForBudgetAssistantFragment,
  ): boolean {
    return (
      isDef(order.latest_order_snapshot) &&
      order.latest_order_snapshot.type === EOrderSnapshotType.PREBILL &&
      isDef(order.latest_order_snapshot.rejector) &&
      order.latest_order_snapshot.rejector.is_wellplaece_agent
    );
  }
}
