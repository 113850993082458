import { Banner } from '@src/components/appearance/fragments/Banner';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderShipmentsAnalyzerSourceCard } from '@src/components/mixins/cards/OrderShipmentsAnalyzerSourceCard';
import { AgentOrderShipmentsAnalyzer } from '@src/gen/shared/data/agentOrders';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import { Fragment, useMemo } from 'react';

export function AgentOrderWorksheetShipmentsAnalyzerPart(): JSX.Element {
  const { order, orderManager } = useAgentOrder();
  const { beginOrderSnapshotEntryView } = useAgentOrderActions();

  const orderShipmentsAnalyzer = useMemo(
    () =>
      isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())
        ? new AgentOrderShipmentsAnalyzer(
            ensureDef(orderManager.maybeGetOrderSnapshotInvoiceLatest()),
            order.order_shipments,
            order.order_plan_groups,
          )
        : null,
    [order.order_plan_groups, order.order_shipments, orderManager],
  );

  if (!isDef(orderShipmentsAnalyzer)) {
    return (
      <Structure.ScrollContent skipSeparator={true}>
        <Banner icon='info' message='Shipments analyzer will be enabled once an invoice is issued.' />
      </Structure.ScrollContent>
    );
  }

  return (
    <Fragment>
      <Structure.ScrollContent skipSeparator={true}>
        <Structure.Stack>
          <ItemsBanner
            items={[
              {
                caption: 'Shipments Status',
                text: orderShipmentsAnalyzer.isFullyDelivered()
                  ? 'Fully Delivered'
                  : orderShipmentsAnalyzer.isFullyShipped()
                  ? 'Fully Tracked'
                  : 'TBD',
              },
              {
                caption: 'Untracked',
                text: `${orderShipmentsAnalyzer.shipmentStatus.untracked}`,
              },
              {
                caption: 'Tracked',
                text: `${orderShipmentsAnalyzer.shipmentStatus.tracked}`,
              },
              {
                caption: 'Delivered',
                text: `${orderShipmentsAnalyzer.shipmentStatus.delivered}`,
              },
            ]}
          />
          <Structure.Group>
            {Object.entries(orderShipmentsAnalyzer.orderSnapshotEntriesBySource)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([, entry]) => (
                <OrderShipmentsAnalyzerSourceCard
                  key={entry.source}
                  beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
                  orderShipmentsAnalyzerSource={entry}
                />
              ))}
          </Structure.Group>
        </Structure.Stack>
      </Structure.ScrollContent>
    </Fragment>
  );
}
