import { Typography } from '@src/components/appearance/basics/Typography';
import { Control } from '@src/components/appearance/controls/Control';
import { ControlButton, getControlButtonVariant } from '@src/components/appearance/controls/ControlButton';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { usePrevious, withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentUploadsTypes } from '@src/modules/data/agent/global/uploads/AgentUploadsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import type { ChangeEvent } from 'react';
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField, useForm, useFormState } from 'react-final-form';

const SDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$uploadGap',
});

export type TUploadBase = {
  acceptOverride?: string | null | undefined;
  buttonText?: string | undefined;
  disabled?: boolean | undefined;
  doUploadAsync: TAgentUploadsTypes['DoUploadAsync'];
  errorOverride?: string | null | undefined;
  onChange: (value: string | undefined) => void;
  value: string | undefined;
};

export type TUpload = TProps<false, TUploadBase, 'div'>;
export const UPLOAD_CLASS_NAME = 'wp-upload';

export const UPLOAD_ACCEPT = [
  'application/pdf',
  'application/rtf',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'text/plain',
].join(', ');

export const UPLOAD_ACCEPT_IMAGES = ['image/jpeg', 'image/png'].join(', ');

export const Upload = withCssToString(
  UPLOAD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TUpload>(
      (
        { acceptOverride, buttonText, disabled, doUploadAsync, errorOverride, onChange, value, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, UPLOAD_CLASS_NAME), [className]);
        const inputRef = useRef<HTMLInputElement>(null);
        const { doErrorNotify } = useErrors();

        type TState = {
          uploadId: string;
          fileName: string;
        };

        const [state, setState] = useState<TState | null>(null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState<string | null>(null);

        const updateState = useCallback(
          (newState: TState | null) => {
            setState(newState);
            onChange(newState !== null ? newState.uploadId : undefined);
          },
          [onChange],
        );

        const handleSelectFilesClick = useCallback(() => {
          if (isDef(inputRef.current)) {
            setError(null);
            inputRef.current.value = '';
            inputRef.current.click();
          }
        }, []);

        const handleClearClick = useCallback(() => {
          if (isDef(inputRef.current)) {
            updateState(null);
            inputRef.current.value = '';
          }
        }, [updateState]);

        const handleChangeAsync = useCallback(
          async (e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];

            if (!isDef(file)) {
              return;
            }

            try {
              setLoading(true);
              const resp = await doUploadAsync(file);
              updateState({ uploadId: resp.uploadId, fileName: file.name });
            } catch (thrown: unknown) {
              const errorSummary = getErrorSummary(thrown, { forceDisplayMode: EErrorSummaryDisplayMode.SILENT });
              doErrorNotify(errorSummary);
              setError(errorSummary.display.message);
              handleClearClick();
            } finally {
              setLoading(false);
            }
          },
          // @sort
          [doErrorNotify, doUploadAsync, handleClearClick, updateState],
        );

        const handleChange = useCallback(
          (e: ChangeEvent<HTMLInputElement>) => void handleChangeAsync(e),
          [handleChangeAsync],
        );

        useEffect(
          () => {
            if ((value === undefined || value === '') && state !== null) {
              updateState(null);
            }
          },
          // @sort
          [state, updateState, value],
        );

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            <input
              disabled={disabled}
              accept={acceptOverride ?? UPLOAD_ACCEPT}
              onChange={handleChange}
              style={{ display: 'none' }}
              ref={inputRef}
              type='file'
            />
            {state !== null ? (
              <ControlButton
                icon='close'
                onClick={handleClearClick}
                text={state.fileName}
                variant={getControlButtonVariant({ disabled: disabled === true, loading: false })}
              />
            ) : (
              <ControlButton
                icon='upload'
                onClick={handleSelectFilesClick}
                text={buttonText ?? 'Select File'}
                variant={getControlButtonVariant({ disabled: disabled === true, loading })}
              />
            )}
            <Typography.ErrorCaption error={error ?? errorOverride} />
          </SDiv>
        );
      },
    ),
  ),
);

export type TUploadControl = {
  acceptOverride?: string | null | undefined;
  buttonText?: string | undefined;
  doUploadAsync: TAgentUploadsTypes['DoUploadAsync'];
  id: string;
  label?: string | undefined;
  required?: boolean | undefined;
};

export function UploadControl({
  acceptOverride,
  buttonText,
  doUploadAsync,
  id,
  label,
  required,
}: TUploadControl): JSX.Element {
  const form = useForm();
  const { pristine: isFormPristine } = useFormState({ subscription: { pristine: true } });
  const prevIsFormPristine = usePrevious(isFormPristine);

  const {
    input: { onChange, value },
    meta: { error, submitting, touched }, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  } = useField<string>(id, {
    subscription: {
      error: true,
      submitting: true,
      value: true,
      touched: true,
    },
    validate: (newValue: string | undefined) =>
      required === true && !isDef(newValue) ? 'Please provide a file.' : null,
  });

  const finalError = useMemo(
    () => (touched === true && typeof error === 'string' && error !== '' ? error : null),
    [error, touched],
  );

  const handleChange = useCallback(
    (newValue: string | undefined) => {
      form.mutators['setFieldTouched']?.(id, true);
      onChange(newValue);
    },
    [form.mutators, id, onChange],
  );

  useEffect(
    () => {
      if (prevIsFormPristine === false && isFormPristine) {
        form.mutators['setFieldTouched']?.(id, false);
      }
    },
    // @sort
    [id, isFormPristine, prevIsFormPristine, form.mutators],
  );

  return (
    <Control id={id} label={label} noError={true} required={required}>
      <Upload
        acceptOverride={acceptOverride}
        buttonText={buttonText}
        disabled={submitting}
        doUploadAsync={doUploadAsync}
        errorOverride={finalError}
        onChange={handleChange}
        value={value}
      />
    </Control>
  );
}
