import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { TextBoxControl } from '@src/components/appearance/controls/TextBox';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersUsersActionsTypes } from '@src/modules/data/agent/customers/users/AgentCustomersUsersActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TUserCustomerAddFormValues = {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
};

export type TUserCustomerAddFormBase = {
  doCustomersUserCustomerCreateAsync: TAgentCustomersUsersActionsTypes['DoCustomersUserCustomerCreateAsync'];
};

export type TUserCustomerAddForm = TProps<false, TUserCustomerAddFormBase, 'div'>;
export const USER_CUSTOMER_ADD_FORM_CLASS_NAME = 'wp-user-customer-add-form';

export const UserCustomerAddForm = withCssToString(
  USER_CUSTOMER_ADD_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TUserCustomerAddForm>(
      ({ doCustomersUserCustomerCreateAsync, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, USER_CUSTOMER_ADD_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TUserCustomerAddFormValues>(
          () => ({
            email: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
          }),
          [],
        );

        const handleSubmit = useCallback<TFormSubmit<TUserCustomerAddFormValues>>(
          async (values) => {
            await doCustomersUserCustomerCreateAsync(values);
            return undefined;
          },
          [doCustomersUserCustomerCreateAsync],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TUserCustomerAddFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              submitButtonText='Add User'>
              <TextBoxControl id='email' required={true} label='Email' email={true} />
              <TextBoxControl id='phoneNumber' required={false} phoneNumber={true} label='Phone Number' />
              <TextBoxControl id='firstName' required={true} label='First Name' />
              <TextBoxControl id='lastName' required={true} label='Last Name' />
            </Form>
          </div>
        );
      },
    ),
  ),
);
