import { Card } from '@src/components/appearance/fragments/Card';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { AgentProductHintManager } from '@src/gen/shared/data/agentOrders';
import { getSaleUnitQuantityWithFallbackPrefix } from '@src/gen/shared/data/snippets';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { maybeFormatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TProductHintBaseProductCardBase = {
  onClick: (() => void) | null;
  productHintManager: AgentProductHintManager;
};

export type TProductHintBaseProductCard = TProps<false, TProductHintBaseProductCardBase, 'div'>;
export const PRODUCT_HINT_BASE_PRODUCT_CARD_CLASS_NAME = 'wp-product-hint-base-product-card';

export const ProductHintBaseProductCard = withCssToString(
  PRODUCT_HINT_BASE_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TProductHintBaseProductCard>(
      ({ onClick, productHintManager, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, PRODUCT_HINT_BASE_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const baseProduct = useMemo(() => productHintManager.getBaseProduct(), [productHintManager]);

        const itemsFirst = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(baseProduct.source),
            },
            {
              caption: 'SKU',
              text: baseProduct.product_sku,
            },
            {
              caption: 'Quantity',
              text: `${getSaleUnitQuantityWithFallbackPrefix(
                'N/A',
                productHintManager.productHint.quantity,
                baseProduct.sale_unit,
              )}`,
            },
            {
              caption: 'Unit Price (Base)',
              text: maybeFormatDollarsCurrency(productHintManager.productHint.unit_price) ?? 'N/A',
            },
          ],
          // @sort
          [
            baseProduct.product_sku,
            baseProduct.sale_unit,
            baseProduct.source,
            productHintManager.productHint.quantity,
            productHintManager.productHint.unit_price,
          ],
        );

        return (
          <Card.Container {...rest} className={joinedClassName} interactive={isDef(onClick)} ref={ref}>
            {isDef(onClick) ? (
              <Card.ButtonArea onClick={onClick}>
                <Card.ImageAssetArea>
                  <ImageAsset alt={baseProduct.name} imageAssetPath={baseProduct.image_asset_path} />
                  <Card.ContentArea>
                    <Card.Title subTitle={baseProduct.secondary_name} title={baseProduct.name} />
                    <ItemsBanner items={itemsFirst} />
                  </Card.ContentArea>
                </Card.ImageAssetArea>
              </Card.ButtonArea>
            ) : (
              <Card.ImageAssetArea>
                <ImageAsset alt={baseProduct.name} imageAssetPath={baseProduct.image_asset_path} />
                <Card.ContentArea>
                  <Card.Title subTitle={baseProduct.secondary_name} title={baseProduct.name} />
                  <ItemsBanner items={itemsFirst} />
                </Card.ContentArea>
              </Card.ImageAssetArea>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);
