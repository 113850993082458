import { Description } from '@src/components/appearance/fragments/Description';
import { Dialog } from '@src/components/appearance/structure/Dialog';
import { EDescriptionBlockType } from '@src/gen/shared/data/description';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TEmergencyOrderDialogPanelBase = {
  doOrderPlace: () => void;
};

export type TEmergencyOrderDialogPanel = TProps<false, TEmergencyOrderDialogPanelBase, 'div'>;
export const EMERGENCY_ORDER_DIALOG_PANEL_CLASS_NAME = 'wp-emergency-order-dialog-panel';

export const EmergencyOrderDialogPanel = withCssToString(
  EMERGENCY_ORDER_DIALOG_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TEmergencyOrderDialogPanel>(({ doOrderPlace, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, EMERGENCY_ORDER_DIALOG_PANEL_CLASS_NAME),
        [className],
      );

      return (
        <Dialog.Panel {...rest} className={joinedClassName} ref={ref}>
          <Dialog.Header title='Place Emergency Order' />
          <Description
            description={[
              {
                type: EDescriptionBlockType.TITLE,
                title: 'About Emergency Orders',
              },
              {
                type: EDescriptionBlockType.PARAGRAPH,
                paragraph:
                  'Emergency orders are for those rare times when regular purchasing went wrong, and products are needed ASAP.',
              },
              {
                type: EDescriptionBlockType.TITLE,
                title: 'What to Expect',
              },
              {
                type: EDescriptionBlockType.FEATURES,
                features: [
                  'We will not search for the best prices.',
                  'We will not issue a prebill and/or await for approval.',
                  'We will not provide your usual Wellplaece savings.',
                ],
              },
              {
                type: EDescriptionBlockType.TITLE,
                title: 'Are you sure you need an emergency order?',
              },
              {
                type: EDescriptionBlockType.PARAGRAPH,
                paragraph: 'If not, we recommend going back and placing a regular order.',
              },
            ]}
          />
          <Dialog.Footer buttonAction={{ isAsync: false, text: 'Place Emergency Order', onClick: doOrderPlace }} />
        </Dialog.Panel>
      );
    }),
  ),
);
