import { Typography } from '@src/components/appearance/basics/Typography';
import type { TCurrencyBase } from '@src/components/appearance/controls/Currency';
import { Currency } from '@src/components/appearance/controls/Currency';
import type { TTextAreaBase } from '@src/components/appearance/controls/TextArea';
import { TextArea } from '@src/components/appearance/controls/TextArea';
import type { TTextBoxBase } from '@src/components/appearance/controls/TextBox';
import { TextBox } from '@src/components/appearance/controls/TextBox';
import { Card } from '@src/components/appearance/fragments/Card';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { OrderPlanGroupCard } from '@src/components/mixins/cards/OrderPlanGroupCard';
import type { TAgentOrderPlanGroupBaseFragment } from '@src/gen/graphql/bindings';
import { AgentOrderPlanGroupManager } from '@src/gen/shared/data/agentOrders';
import { getSource, SOURCE_TAX_ESTIMATES } from '@src/gen/shared/enums/source';
import { formatDollarsCurrency, formatPercent } from '@src/gen/shared/utils/converters';
import { ensureDef, ensureNotEmptyString, isDef, isNotEmptyString } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';

export type TOrderPlanGroupEditDrawerPanelBase = {
  doOrderPlanGroupUpdateAsync: TAgentOrderActionsTypes['DoOrderPlanGroupUpdateAsync'];
  orderPlanGroupManager: AgentOrderPlanGroupManager;
};

export type TOrderPlanGroupEditDrawerPanelState = {
  orderPlanGroupManager: AgentOrderPlanGroupManager;
};

type TMutateOrderPlanGroupEditDrawerPanelState = (
  callback: (orderPlanGroup: TAgentOrderPlanGroupBaseFragment) => TAgentOrderPlanGroupBaseFragment,
) => void;

export type TOrderPlanGroupEditDrawerPanel = TProps<false, TOrderPlanGroupEditDrawerPanelBase, 'div'>;
export const ORDER_PLAN_GROUP_EDIT_DRAWER_PANEL_CLASS_NAME = 'wp-order-plan-group-edit-drawer-panel';

export const OrderPlanGroupEditDrawerPanel = withCssToString(
  ORDER_PLAN_GROUP_EDIT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderPlanGroupEditDrawerPanel>(
      ({ doOrderPlanGroupUpdateAsync, orderPlanGroupManager, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_PLAN_GROUP_EDIT_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [state, setState] = useState<TOrderPlanGroupEditDrawerPanelState>({
          orderPlanGroupManager: new AgentOrderPlanGroupManager(
            orderPlanGroupManager.orderPlanGroup,
            orderPlanGroupManager.orderEntryManagers,
          ),
        });

        const mutateState = useCallback<TMutateOrderPlanGroupEditDrawerPanelState>((callback) => {
          setState((prevState) => ({
            orderPlanGroupManager: new AgentOrderPlanGroupManager(
              callback(prevState.orderPlanGroupManager.orderPlanGroup),
              prevState.orderPlanGroupManager.orderEntryManagers,
            ),
          }));
        }, []);

        const handleSave = useCallback(
          async (): Promise<void> => {
            await doOrderPlanGroupUpdateAsync({
              orderPlanGroupId: state.orderPlanGroupManager.orderPlanGroup.id,
              shipping: state.orderPlanGroupManager.orderPlanGroup.shipping,
              tax: state.orderPlanGroupManager.orderPlanGroup.tax,
              notes: state.orderPlanGroupManager.orderPlanGroup.notes,
              supplierOrderReference: state.orderPlanGroupManager.orderPlanGroup.supplier_order_reference,
            });
          },
          // @sort
          [
            doOrderPlanGroupUpdateAsync,
            state.orderPlanGroupManager.orderPlanGroup.id,
            state.orderPlanGroupManager.orderPlanGroup.notes,
            state.orderPlanGroupManager.orderPlanGroup.shipping,
            state.orderPlanGroupManager.orderPlanGroup.supplier_order_reference,
            state.orderPlanGroupManager.orderPlanGroup.tax,
          ],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Edit Plan Order' />
            <Drawer.ScrollContent>
              <Drawer.Group>
                <OrderPlanGroupCard
                  beginOrderEntryEdit={null}
                  beginOrderEntryPlanProductView={null}
                  beginOrderPlanGroupEdit={null}
                  orderPlanGroupManager={state.orderPlanGroupManager}
                  notesUpdater={null}
                  isEditable={false}
                />
                <EditComponent state={state} mutateState={mutateState} />
              </Drawer.Group>
            </Drawer.ScrollContent>
            <Drawer.Footer
              buttonAction={{
                isAsync: true,
                onClick: handleSave,
                text: 'Save',
              }}
            />
          </Drawer.Panel>
        );
      },
    ),
  ),
);

const SEditComponentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$controlGap',
});

function EditComponent({
  state,
  mutateState,
}: {
  state: TOrderPlanGroupEditDrawerPanelState;
  mutateState: TMutateOrderPlanGroupEditDrawerPanelState;
}): JSX.Element {
  const handleShippingChange = useCallback<TCurrencyBase['onChange']>(
    (newValue) => {
      mutateState((orderPlanGroup) => ({
        ...orderPlanGroup,
        shipping: newValue,
      }));
    },
    [mutateState],
  );

  const handleTaxChange = useCallback<TCurrencyBase['onChange']>(
    (newValue) => {
      mutateState((orderPlanGroup) => ({
        ...orderPlanGroup,
        tax: newValue,
      }));
    },
    [mutateState],
  );

  const handleSupplierOrderReferenceChange = useCallback<TTextBoxBase['onChange']>(
    (e) => {
      mutateState((orderPlanGroup) => ({
        ...orderPlanGroup,
        supplier_order_reference: isNotEmptyString(e.target.value) ? ensureNotEmptyString(e.target.value) : null,
      }));
    },
    [mutateState],
  );

  const handleNotesChange = useCallback<TTextAreaBase['onChange']>(
    (e) => {
      mutateState((orderPlanGroup) => ({
        ...orderPlanGroup,
        notes: e.target.value,
      }));
    },
    [mutateState],
  );

  const sourceTaxEstimate = useMemo(
    () => SOURCE_TAX_ESTIMATES[getSource(state.orderPlanGroupManager.orderPlanGroup.source)],
    [state.orderPlanGroupManager.orderPlanGroup.source],
  );

  return (
    <Card.Container flush={true} interactive={false} variant='form'>
      <SEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-plan-group-shipping-edit'
          required={false}
          rigid={true}
          text='Shipping'
        />
        <Currency
          id='order-plan-group-shipping-edit'
          value={state.orderPlanGroupManager.orderPlanGroup.shipping ?? 0}
          onChange={handleShippingChange}
        />
      </SEditComponentDiv>
      <SEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-plan-group-tax-edit'
          required={false}
          rigid={true}
          text='Tax'
        />
        <Currency
          id='order-plan-group-tax-edit'
          value={state.orderPlanGroupManager.orderPlanGroup.tax ?? 0}
          onChange={handleTaxChange}
        />
        {isDef(sourceTaxEstimate) && isDef(state.orderPlanGroupManager.maybeGetPlanSubTotal()) && (
          <Typography.Annotation
            css={{ paddingTop: '2px' }}
            text={`Estimated Tax ${formatPercent(sourceTaxEstimate)} of ${formatDollarsCurrency(
              ensureDef(state.orderPlanGroupManager.maybeGetPlanSubTotal()),
            )} → ${formatDollarsCurrency(
              Math.round((ensureDef(state.orderPlanGroupManager.maybeGetPlanSubTotal()) * sourceTaxEstimate) / 10000),
            )} `}
          />
        )}
      </SEditComponentDiv>
      <SEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-plan-group-supplier-order-reference-edit'
          required={false}
          rigid={true}
          text='Supplier Order Reference'
        />
        <TextBox
          id='order-plan-group-supplier-order-reference-edit'
          value={state.orderPlanGroupManager.orderPlanGroup.supplier_order_reference ?? ''}
          onChange={handleSupplierOrderReferenceChange}
        />
      </SEditComponentDiv>
      <SEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-plan-group-notes-edit'
          required={false}
          rigid={true}
          text='Notes'
        />
        <TextArea
          id='order-plan-group-notes-edit'
          value={state.orderPlanGroupManager.orderPlanGroup.notes ?? ''}
          onChange={handleNotesChange}
          maxLength={2048}
        />
        <Typography.Annotation
          text={`(${state.orderPlanGroupManager.orderPlanGroup.notes?.length ?? 0} / 2048)`}
          css={{ alignSelf: 'flex-end', paddingTop: '4px' }}
        />
      </SEditComponentDiv>
    </Card.Container>
  );
}
