import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import type { TSearchBase, TSearchBaseChip } from '@src/components/appearance/controls/Search';
import { Search } from '@src/components/appearance/controls/Search';
import { getSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentCustomersOrganizationActions } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import { useAgentCustomersOrganization } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import { styled } from '@src/modules/design/theme';
import {
  encodeAgentCustomersOrganizationFormularyPath,
  useAgentCustomersOrganizationFormularyRouteParams,
} from '@src/modules/routing/agent';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const SDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  paddingTop: '$secondaryNavSpaceMobile',
  gap: '$cardGap',

  '@desktop': {
    paddingTop: '$secondaryNavSpaceDesktop',
  },
});

export function AgentCustomersOrganizationFormularyNavPart(): JSX.Element {
  const navigate = useNavigate();
  const { organization } = useAgentCustomersOrganization();
  const routeParams = useAgentCustomersOrganizationFormularyRouteParams();
  const { beginFormularyProductAdd } = useAgentCustomersOrganizationActions();

  const handleSearch = useCallback<TSearchBase['onSearch']>(
    (query) =>
      navigate(
        encodeAgentCustomersOrganizationFormularyPath(organization.id, {
          ...routeParams.config,
          query,
        }),
      ),
    // @sort
    [navigate, organization.id, routeParams.config],
  );

  const chips = useMemo<TSearchBaseChip[]>(() => {
    const res: TSearchBaseChip[] = [];

    if (isDef(routeParams.config.sourceFilter)) {
      res.push({
        label: `Catalog: ${getSourceName(routeParams.config.sourceFilter)}`,
        onClose: () =>
          navigate(
            encodeAgentCustomersOrganizationFormularyPath(organization.id, {
              query: routeParams.config.query,
              sourceFilter: null,
              categoryName01Filter: null,
              categoryName02Filter: null,
              manufacturerNameFilter: null,
            }),
          ),
      });
    }

    if (isDef(routeParams.config.categoryName01Filter)) {
      res.push({
        label: `Category: ${routeParams.config.categoryName01Filter}`,
        onClose: () =>
          navigate(
            encodeAgentCustomersOrganizationFormularyPath(organization.id, {
              query: routeParams.config.query,
              sourceFilter: routeParams.config.sourceFilter,
              categoryName01Filter: null,
              categoryName02Filter: null,
              manufacturerNameFilter: routeParams.config.manufacturerNameFilter,
            }),
          ),
      });
    }

    if (isDef(routeParams.config.categoryName02Filter)) {
      res.push({
        label: `Sub-Category: ${routeParams.config.categoryName02Filter}`,
        onClose: () =>
          navigate(
            encodeAgentCustomersOrganizationFormularyPath(organization.id, {
              query: routeParams.config.query,
              sourceFilter: routeParams.config.sourceFilter,
              categoryName01Filter: routeParams.config.categoryName01Filter,
              categoryName02Filter: null,
              manufacturerNameFilter: routeParams.config.manufacturerNameFilter,
            }),
          ),
      });
    }

    if (isDef(routeParams.config.manufacturerNameFilter)) {
      res.push({
        label: `Manufacturer: ${routeParams.config.manufacturerNameFilter}`,
        onClose: () =>
          navigate(
            encodeAgentCustomersOrganizationFormularyPath(organization.id, {
              query: routeParams.config.query,
              sourceFilter: routeParams.config.sourceFilter,
              categoryName01Filter: routeParams.config.categoryName01Filter,
              categoryName02Filter: routeParams.config.categoryName02Filter,
              manufacturerNameFilter: null,
            }),
          ),
      });
    }

    return res;
  }, [
    navigate,
    organization.id,
    routeParams.config.categoryName01Filter,
    routeParams.config.categoryName02Filter,
    routeParams.config.manufacturerNameFilter,
    routeParams.config.query,
    routeParams.config.sourceFilter,
  ]);

  return (
    <SDiv>
      <Search css={{ flexGrow: 1 }} searchQuery={routeParams.config.query} onSearch={handleSearch} chips={chips} />
      {isDef(beginFormularyProductAdd) && (
        <ActionIconButton
          action={{
            isAsync: false,
            onClick: beginFormularyProductAdd,
            icon: 'add',
          }}
        />
      )}
    </SDiv>
  );
}
