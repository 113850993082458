import { Typography } from '@src/components/appearance/basics/Typography';
import { Button } from '@src/components/appearance/controls/Button';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

export type TNextOrderResultsEmptyAnnouncementBase = {
  doCatalogsSearch?: (() => void) | undefined;
};

export type TNextOrderResultsEmptyAnnouncement = TProps<false, TNextOrderResultsEmptyAnnouncementBase, 'div'>;
export const NEXT_ORDER_RESULTS_EMPTY_ANNOUNCEMENT_CLASS_NAME = 'wp-next-order-results-empty-announcement';

export const NextOrderResultsEmptyAnnouncement = withCssToString(
  NEXT_ORDER_RESULTS_EMPTY_ANNOUNCEMENT_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNextOrderResultsEmptyAnnouncement>(
      ({ doCatalogsSearch, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, NEXT_ORDER_RESULTS_EMPTY_ANNOUNCEMENT_CLASS_NAME),
          [className],
        );

        return (
          <Announcement {...rest} className={joinedClassName} ref={ref}>
            <Fragment>
              <Typography.SubTitle text='No Results' />
              <Typography.Small
                text={
                  isDef(doCatalogsSearch)
                    ? 'Please try a different query or expand your search to "Catalogs".'
                    : 'Please try a different query.'
                }
              />
              {isDef(doCatalogsSearch) && <Button onClick={doCatalogsSearch} text='Search Catalogs' />}
            </Fragment>
          </Announcement>
        );
      },
    ),
  ),
);
