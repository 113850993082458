import { Search, type TSearchBase, type TSearchBaseChip } from '@src/components/appearance/controls/Search';
import { Select } from '@src/components/appearance/controls/Select';
import { Chrome } from '@src/components/appearance/structure/Chrome';
import { ProductPickerProductCardLoader } from '@src/components/mixins/cards/ProductPickerProductCard';
import { getSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import type { TAgentProductsSearchConfig } from '@src/modules/data/agent/global/operations/AgentProductsSearchProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';

export type TProductPickerChrome = {
  SearchAndFiltersBase: {
    showPreferredCatalogsOption: boolean;
    config: Omit<TAgentProductsSearchConfig, 'allowableSources'> & { sourceMode: 'all' | 'preferred' };
    setConfig: Dispatch<
      SetStateAction<Omit<TAgentProductsSearchConfig, 'allowableSources'> & { sourceMode: 'all' | 'preferred' }>
    >;
  };
  Panel: PropsWithChildren<TEmptyObject>;
  SearchAndFilters: TProductPickerChrome['SearchAndFiltersBase'];
};

function Panel({ children }: TProductPickerChrome['Panel']): JSX.Element {
  return (
    <Chrome.Panel>
      <Chrome.Header title='Select Product' />
      {children}
    </Chrome.Panel>
  );
}

const SSearchAndFiltersDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '$cardGap',
  paddingTop: '4px',
  paddingBottom: '4px',
});

function SearchAndFilters({
  showPreferredCatalogsOption,
  config,
  setConfig,
}: TProductPickerChrome['SearchAndFilters']): JSX.Element {
  const handleSearch = useCallback<TSearchBase['onSearch']>(
    (query) =>
      setConfig((oldConfig) => ({
        ...oldConfig,
        query,
      })),
    // @sort
    [setConfig],
  );

  const chips = useMemo<TSearchBaseChip[]>(() => {
    const res: TSearchBaseChip[] = [];

    if (isDef(config.sourceFilter)) {
      res.push({
        label: `Catalog: ${getSourceName(config.sourceFilter)}`,
        onClose: () =>
          setConfig((oldConfig) => ({
            ...oldConfig,
            sourceFilter: null,
            categoryName01Filter: null,
            categoryName02Filter: null,
            manufacturerNameFilter: null,
          })),
      });
    }

    if (isDef(config.categoryName01Filter)) {
      res.push({
        label: `Category: ${config.categoryName01Filter}`,
        onClose: () =>
          setConfig((oldConfig) => ({
            ...oldConfig,
            categoryName01Filter: null,
            categoryName02Filter: null,
          })),
      });
    }

    if (isDef(config.categoryName02Filter)) {
      res.push({
        label: `Sub-Category: ${config.categoryName02Filter}`,
        onClose: () =>
          setConfig((oldConfig) => ({
            ...oldConfig,
            categoryName02Filter: null,
          })),
      });
    }

    if (isDef(config.manufacturerNameFilter)) {
      res.push({
        label: `Manufacturer: ${config.manufacturerNameFilter}`,
        onClose: () =>
          setConfig((oldConfig) => ({
            ...oldConfig,
            manufacturerNameFilter: null,
          })),
      });
    }

    return res;
  }, [
    config.categoryName01Filter,
    config.categoryName02Filter,
    config.manufacturerNameFilter,
    config.sourceFilter,
    setConfig,
  ]);

  return (
    <Chrome.SubHeader>
      <SSearchAndFiltersDiv>
        {showPreferredCatalogsOption && (
          <Select
            onChange={(e): void => {
              switch (e.target.value) {
                case 'all':
                  setConfig((oldConfig) => ({
                    ...oldConfig,
                    sourceMode: 'all',
                    sourceFilter: null,
                  }));
                  break;
                case 'preferred':
                  setConfig((oldConfig) => ({
                    ...oldConfig,
                    sourceMode: 'preferred',
                    sourceFilter: null,
                  }));
              }
            }}
            value={config.sourceMode}>
            <option value='all'>All Catalogs</option>
            <option value='preferred'>Preferred Catalogs</option>
          </Select>
        )}
        <Search css={{ flexGrow: 1 }} searchQuery={config.query} onSearch={handleSearch} chips={chips} />
      </SSearchAndFiltersDiv>
    </Chrome.SubHeader>
  );
}

const SGridDiv = styled('div', {
  display: 'grid',
  gap: '$structureGridInnerGapMobile',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',

  '@desktop': {
    gap: '$structureGridInnerGapDesktop',
  },
});

function Grid({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  return (
    <Chrome.ScrollContent>
      <SGridDiv>{children}</SGridDiv>
    </Chrome.ScrollContent>
  );
}

const GridLoader = (): JSX.Element => {
  return (
    <Chrome.ScrollContentLoader>
      <SGridDiv>
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
        <ProductPickerProductCardLoader />
      </SGridDiv>
    </Chrome.ScrollContentLoader>
  );
};

export const ProductPickerChrome = {
  Grid,
  GridLoader,
  Panel,
  SearchAndFilters,
};
