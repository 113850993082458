import { useConfig } from '@src/modules/config/ConfigProvider';
import { styled } from '@src/modules/design/theme';
import { Fragment } from 'react';

const SOuterDiv = styled('div', {
  height: '100dvh',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const SInnerDiv = styled('div', {
  height: '100dvh',
  marginTop: '-32px',
  width: '100%',
  maxWidth: '1280px',
});

export function AgentMetricsPart(): JSX.Element {
  const { WP_STAGE } = useConfig();

  if (WP_STAGE !== 'prod') {
    return <Fragment />;
  }

  return (
    <SOuterDiv>
      <SInnerDiv>
        <iframe
          allowFullScreen
          frameBorder='0'
          src='https://lookerstudio.google.com/embed/reporting/7a5e61ea-f950-4a58-9381-21cc1a847770/page/p_hua22q4ucd'
          style={{ border: 0, width: '100%', height: '100dvh' }}></iframe>
      </SInnerDiv>
    </SOuterDiv>
  );
}
