import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderPlanGroupsStructureGroup } from '@src/components/mixins/groups/OrderPlanGroupsStructureGroup';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';

export function AgentOrderWorksheetPlanOrdersPart(): JSX.Element {
  const { order, orderManager } = useAgentOrder();
  const { beginOrderEntryEdit, beginOrderEntryPlanProductView, beginOrderPlanGroupEdit } = useAgentOrderActions();

  return (
    <Structure.ScrollContent skipSeparator={true}>
      <OrderPlanGroupsStructureGroup
        beginOrderEntryEdit={beginOrderEntryEdit}
        beginOrderEntryPlanProductView={beginOrderEntryPlanProductView}
        beginOrderPlanGroupEdit={beginOrderPlanGroupEdit}
        isEditable={!orderManager.hasSnapshotPrebillPending()}
        orderPlanGroupManagers={orderManager.orderPlanGroupManagers}
        orderPlanGroups={order.order_plan_groups}
      />
    </Structure.ScrollContent>
  );
}
