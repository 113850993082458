import { setUserId as amplitudeSetUserId } from '@amplitude/analytics-browser';
import { ApolloProvider } from '@apollo/client';
import { setUser } from '@sentry/react';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Cover } from '@src/components/appearance/structure/Cover';
import { SignInPart } from '@src/components/parts/anonymous/SignInPart';
import { EErrorClientCode } from '@src/gen/shared/enums/errorClientCode';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useRender } from '@src/logic/internal/utils/utils';
import type {
  TAuthAgent,
  TAuthCustomer,
  TAuthLocation,
  TAuthOrganization,
  TAuthSubject,
} from '@src/modules/auth/subject';
import { parseAuthSubject } from '@src/modules/auth/subject';
import { useConfig } from '@src/modules/config/ConfigProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { getErrorSummary } from '@src/modules/errors/errorSummary';
import {
  doAnonymousIssueEmailToken,
  doAnonymousRefreshToken,
  doAnonymousVerifyEmailToken,
  doUserSelf,
  initAuthClient,
} from '@src/modules/graphql/authClient';
import { initClient } from '@src/modules/graphql/client';
import { maybeGetEmailTokenHash } from '@src/modules/routing/hash';
import assert from 'assert';
import isEqual from 'lodash/isEqual';
import type { PropsWithChildren } from 'react';
import { useMemo, useRef } from 'react';
import useAsyncEffect from 'use-async-effect';

export type TAuthContext = {
  state: {
    loading: boolean;
    subject: TAuthSubject | null;
    accessToken: string | null;
    refreshToken: string | null;
  };
  doIssueEmailToken: (email: string) => Promise<string>;
  doRefreshAccessToken: () => Promise<void>;
  doSignOut: () => void;
  doVerifyEmailToken: (emailToken: string) => Promise<void>;
};

export const { Context: AuthContext, useContext: useAuth } = createRequiredContext<TAuthContext>();

export function useAuthSubject(): TAuthSubject {
  const { state } = useAuth();
  return ensureDef(state.subject);
}

export function useAuthAgent(): TAuthAgent {
  const { state } = useAuth();
  assert(state.subject?.is_wellplaece_agent === true);
  return state.subject;
}

export function useAuthCustomer(): TAuthCustomer {
  const { state } = useAuth();
  assert(state.subject?.is_wellplaece_agent === false);
  return state.subject;
}

export function useAuthCustomerOrganization(organizationId: string): TAuthOrganization {
  const customer = useAuthCustomer();

  return useMemo(
    () => {
      const org = customer.organizations.find((o) => o.id === organizationId);
      if (!isDef(org)) {
        throw new Error('Not Found');
      }

      return org;
    },
    // @sort
    [organizationId, customer.organizations],
  );
}

export function useAuthCustomerLocation(organizationId: string, locationId: string): TAuthLocation {
  const organization = useAuthCustomerOrganization(organizationId);

  return useMemo(
    () => {
      const loc = organization.locations.find((l) => l.id === locationId);
      if (!isDef(loc)) {
        throw new Error('Not Found');
      }

      return loc;
    },
    // @sort
    [locationId, organization.locations],
  );
}

export function useAuthCustomerNextOrderLocations(organizationId: string): TAuthLocation[] {
  const organization = useAuthCustomerOrganization(organizationId);

  return useMemo(
    () => organization.locations.filter((loc) => loc.acl.can_place_orders),
    // @sort
    [organization.locations],
  );
}

export function AuthProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const { WP_HASURA_BASE_URL, WP_STAGE } = useConfig(); // note: immutable after mount
  const render = useRender(); // note: immutable after mount
  const { doErrorNotify } = useErrors();

  const isMountingRef = useRef(true);
  const authClientRef = useRef(initAuthClient({ WP_STAGE, WP_HASURA_BASE_URL }));

  const stateRef = useRef<TAuthContext['state']>({
    loading: true,
    subject: null,
    accessToken: null,
    refreshToken: null,
  });

  const doUpdateStateRef = useRef((newState: TAuthContext['state']) => {
    const state = stateRef.current;

    if (state.refreshToken !== newState.refreshToken) {
      saveRefreshToken(newState.refreshToken);
    }
    const shouldRender = state.loading !== newState.loading || !isEqual(state.subject, newState.subject);

    state.loading = newState.loading;
    state.subject = newState.subject;
    state.accessToken = newState.accessToken;
    state.refreshToken = newState.refreshToken;

    if (shouldRender) {
      setUser(newState.subject);
      amplitudeSetUserId(newState.subject?.email ?? undefined);
      render();
    }
  });

  const doIssueEmailTokenRef = useRef(async (email: string): Promise<string> => {
    return (await doAnonymousIssueEmailToken(authClientRef.current, email)).emailTokenId;
  });

  const doRefreshAccessTokenRef = useRef(async (): Promise<void> => {
    const state = stateRef.current;

    let user: TAuthSubject | null = null;
    let accessToken: string | null = null;

    try {
      if (isDef(state.refreshToken)) {
        accessToken = (await doAnonymousRefreshToken(authClientRef.current, ensureDef(state.refreshToken))).accessToken;
        user = parseAuthSubject(await doUserSelf(authClientRef.current, accessToken));
      }
    } catch (thrown: unknown) {
      const errorSummary = getErrorSummary(thrown, {});

      if (errorSummary.code === EErrorClientCode.DISABLED_USER) {
        doUpdateStateRef.current({
          loading: false,
          subject: null,
          accessToken: null,
          refreshToken: null,
        });

        doErrorNotify(errorSummary);
      }

      if (errorSummary.code === EErrorClientCode.NETWORK_ERROR) {
        doErrorNotify(errorSummary);
      }
    } finally {
      doUpdateStateRef.current({
        loading: false,
        subject: user,
        accessToken,
        refreshToken: state.refreshToken,
      });
    }
  });

  const doSignOutRef = useRef((): void => {
    doUpdateStateRef.current({
      loading: false,
      subject: null,
      accessToken: null,
      refreshToken: null,
    });

    window.location.replace('/');
  });

  const doVerifyEmailTokenRef = useRef(async (emailToken: string): Promise<void> => {
    let user: TAuthSubject | null = null;
    let accessToken: string | null = null;
    let refreshToken: string | null = null;

    try {
      const verifyEmailToken = await doAnonymousVerifyEmailToken(authClientRef.current, emailToken);
      accessToken = verifyEmailToken.accessToken;
      refreshToken = verifyEmailToken.refreshToken;
      user = parseAuthSubject(await doUserSelf(authClientRef.current, accessToken));
    } finally {
      doUpdateStateRef.current({
        loading: false,
        subject: user,
        accessToken,
        refreshToken,
      });
    }
  });

  const clientRef = useRef(
    initClient({
      WP_STAGE,
      WP_HASURA_BASE_URL,
      stateRef,
      doRefreshAccessTokenRef,
    }),
  );

  const valueRef = useRef<TAuthContext>({
    state: stateRef.current,
    doIssueEmailToken: doIssueEmailTokenRef.current,
    doRefreshAccessToken: doRefreshAccessTokenRef.current,
    doSignOut: doSignOutRef.current,
    doVerifyEmailToken: doVerifyEmailTokenRef.current,
  });

  useAsyncEffect(async (isMounted) => {
    if (isMounted() && isMountingRef.current) {
      isMountingRef.current = false;
      const emailToken = maybeGetEmailTokenHash();
      stateRef.current.refreshToken = loadRefreshToken();

      if (isDef(emailToken)) {
        try {
          await doVerifyEmailTokenRef.current(emailToken);
        } catch (thrown: unknown) {
          doErrorNotify(getErrorSummary(thrown, {}));
        }
      } else {
        await doRefreshAccessTokenRef.current();
      }
    }
  }, []);

  if (stateRef.current.loading) {
    return (
      <Cover background='image'>
        <Illustrations.Spinner />
      </Cover>
    );
  }

  return (
    <AuthContext.Provider value={valueRef.current}>
      {isDef(stateRef.current.subject) ? (
        <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>
      ) : (
        <SignInPart />
      )}
    </AuthContext.Provider>
  );
}

function loadRefreshToken(): string | null {
  try {
    return window.localStorage.getItem('wp-auth-refresh-token');
  } catch {
    return null;
  }
}

function saveRefreshToken(refreshToken: string | null): void {
  try {
    if (isDef(refreshToken)) {
      window.localStorage.setItem('wp-auth-refresh-token', refreshToken);
    } else {
      window.localStorage.removeItem('wp-auth-refresh-token');
    }
  } catch {
    // do nothing
  }
}
