import { startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import subDays from 'date-fns/subDays';

export type TDateRange = {
  startTime: string | null;
  endTime: string | null;
};

export function getDateRangeMonthToDate(): TDateRange {
  return {
    startTime: startOfMonth(new Date()).toISOString(),
    endTime: null,
  };
}

export function getDateRangeQuarterToDate(): TDateRange {
  return {
    startTime: startOfQuarter(new Date()).toISOString(),
    endTime: null,
  };
}

export function getDateRangeYearToDate(): TDateRange {
  return {
    startTime: startOfYear(new Date()).toISOString(),
    endTime: null,
  };
}

export function getDateRangeLastNDays(n: number): TDateRange {
  return {
    startTime: subDays(new Date(), n).toISOString(),
    endTime: null,
  };
}
