import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EOrderEntryOrigin {
  ORIGINAL_CART = 'original_cart',
  CUSTOMER_REQUESTED = 'customer_requested',
}

const ORDER_ENTRY_ORIGIN_LOOKUP = mapValues(invert(EOrderEntryOrigin), () => true);

export function getOrderEntryOrigin(rawOrderEntryOrigin: string): EOrderEntryOrigin {
  assert(ORDER_ENTRY_ORIGIN_LOOKUP[rawOrderEntryOrigin], `Unknown order entry origin: "${rawOrderEntryOrigin}".`);
  return rawOrderEntryOrigin as EOrderEntryOrigin; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetOrderEntryOrigin(rawOrderEntryOrigin: string): EOrderEntryOrigin | undefined {
  try {
    return getOrderEntryOrigin(rawOrderEntryOrigin);
  } catch {
    return undefined;
  }
}

export const ORDER_ENTRY_ORIGIN_LABELS: { [key in EOrderEntryOrigin]: string } = {
  [EOrderEntryOrigin.ORIGINAL_CART]: 'Original Cart',
  [EOrderEntryOrigin.CUSTOMER_REQUESTED]: 'Customer Requested',
};

export function maybeGetOrderEntryOriginLabel(rawOrderEntryOrigin: string): string {
  const orderEntryOrigin = maybeGetOrderEntryOrigin(rawOrderEntryOrigin);
  return orderEntryOrigin !== undefined ? ensureDef(ORDER_ENTRY_ORIGIN_LABELS[orderEntryOrigin]) : rawOrderEntryOrigin;
}
