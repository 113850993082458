import {
  Content as RxDialogContent,
  Overlay as RxDialogOverlay,
  Portal as RxDialogPortal,
  Root as RxDialogRoot,
} from '@radix-ui/react-dialog';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Tag } from '@src/components/appearance/basics/Tag';
import { Typography } from '@src/components/appearance/basics/Typography';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { TPrimaryNav } from '@src/components/appearance/structure/PrimaryNav';
import { PrimaryNav } from '@src/components/appearance/structure/PrimaryNav';
import { ensureFiniteNumber, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import { CONTAINER_MEDIA_DESKTOP, EBreakpoints, useBreakpoint } from '@src/modules/design/breakpoints';
import type { TEmptyObject, TIcon, TProps } from '@src/modules/design/theme';
import { getIconComponent, styled, theme } from '@src/modules/design/theme';
import type { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import {
  Children,
  Fragment,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  memo,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { ValueType } from 'react-ui-animate';
import {
  AnimatedBlock,
  interpolate,
  makeAnimatedComponent,
  useAnimatedValue,
  useMeasure,
  useScroll,
} from 'react-ui-animate';

export type TStructure = {
  ColumnsBase: {
    sideChildren?: ReactNode | undefined;
  };
  ContentBase: {
    fullWidth?: boolean | undefined;
  };
  GroupBase: {
    closingSeparator?: boolean | undefined;
    iconButtonActions?: TIconButtonAction[] | undefined;
    tag?: string | undefined;
    title?: string | undefined;
    variant?: 'default' | 'secondary' | 'section' | undefined;
    icon?: TIcon | undefined;
  };
  ScrollContentBase: {
    reverse?: boolean | undefined;
    skipSeparator?: boolean | undefined;
    fullWidth?: boolean | undefined;
  };
  ScrollContentContext: {
    height: ValueType;
  };

  Columns: TProps<true, TStructure['ColumnsBase'], 'div'>;
  Content: TProps<true, TStructure['ContentBase'], 'div'>;
  Grid: TProps<true, TEmptyObject, 'div'>;
  Group: TProps<true, TStructure['GroupBase'], 'div'>;
  Nav: PropsWithChildren<
    Pick<TPrimaryNav['PanelBase'], 'footerChildren' | 'headerChildren' | 'message'> & { pageTitle: string }
  >;
  ScrollContent: PropsWithChildren<TStructure['ScrollContentBase']>;
};

export const PAGE_CLASS_NAMES = {
  Columns: 'wp-page-columns',
  Content: 'wp-page-content',
  Grid: 'wp-page-grid',
  Group: 'wp-page-group',
};

const Root = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  height: '100dvh',
  width: '100vw',

  '@desktop': {
    paddingBottom: 0,
    height: '100dvh',
    flexDirection: 'row',
  },
});

const NavPrimaryNavPanelMobile = styled(PrimaryNav.Panel, {
  left: 0,
  position: 'fixed',
  height: '100dvh',
  top: 0,
  width: '100vw',
  zIndex: '$primaryNavOverride',
});

const NavPrimaryNavPanelDesktop = styled(PrimaryNav.Panel, {
  flexShrink: 0,
  width: '$primaryNavWidthDesktop',

  '@largeDesktop': {
    width: '$primaryNavWidthLargeDesktop',
  },
});

function NavMobile({ children, footerChildren, headerChildren, pageTitle, message }: TStructure['Nav']): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const openAction = useMemo<TPrimaryNav['helperTypes']['Action']>(
    () => ({ icon: 'menu', onClick: () => setIsOpen(true) }),
    [setIsOpen],
  );

  const closeAction = useMemo<TPrimaryNav['helperTypes']['Action']>(
    () => ({ icon: 'close', onClick: () => setIsOpen(false) }),
    [setIsOpen],
  );

  return (
    <RxDialogRoot open={isOpen} onOpenChange={setIsOpen}>
      <PrimaryNav.Header action={openAction} pageTitle={pageTitle} />
      <RxDialogPortal>
        <RxDialogOverlay />
        <RxDialogContent>
          <NavPrimaryNavPanelMobile
            action={closeAction}
            footerChildren={assignOnClick(footerChildren, closeAction.onClick)}
            headerChildren={headerChildren}
            message={message}>
            {assignOnClick(children, closeAction.onClick)}
          </NavPrimaryNavPanelMobile>
        </RxDialogContent>
      </RxDialogPortal>
    </RxDialogRoot>
  );
}

function assignOnClick(children: ReactNode, onClick: () => void): ReactNode {
  return Children.map(children, (child) => {
    if (isValidElement<TPrimaryNav['Item']>(child)) {
      const newOnClick = (e: MouseEvent<HTMLButtonElement>): void => {
        child.props.onClick?.(e);
        onClick();
      };

      return cloneElement<TPrimaryNav['Item']>(child, {
        onClick: newOnClick,
      });
    }
    return child;
  });
}

function Nav({ children, footerChildren, headerChildren, pageTitle, message }: TStructure['Nav']): JSX.Element {
  const breakpoint = useBreakpoint();

  return breakpoint === EBreakpoints.DESKTOP || breakpoint === EBreakpoints.LARGE_DESKTOP ? (
    <NavPrimaryNavPanelDesktop footerChildren={footerChildren} headerChildren={headerChildren} message={message}>
      {children}
    </NavPrimaryNavPanelDesktop>
  ) : (
    <NavMobile footerChildren={footerChildren} headerChildren={headerChildren} message={message} pageTitle={pageTitle}>
      {children}
    </NavMobile>
  );
}

const Main = styled('main', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
  paddingTop: '$structureMainPaddingMobile',

  '@desktop': {
    paddingTop: '$structureMainPaddingDesktop',
  },
});

const SContentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  paddingX: '$structureContentPaddingMobile',

  '@desktop': {
    paddingX: '$structureContentPaddingDesktop',
  },
});

const SContentInnerDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  margin: '0px auto',
  paddingX: '$structureContentInnerPaddingMobile',
  width: '100%',

  '@desktop': {
    paddingX: '$structureContentInnerPaddingDesktop',
  },

  variants: {
    fullWidth: {
      true: {
        // intentionally empty
      },
      false: {
        maxWidth: '$structureContentMaxWidth',
      },
    },
  },
});

const Content = withCssToString(
  PAGE_CLASS_NAMES.Content,
  forwardRef<HTMLDivElement, TStructure['Content']>(({ children, className, fullWidth, ...rest }, ref): JSX.Element => {
    const joinedClassName = useMemo(() => joinClassNames(className, PAGE_CLASS_NAMES.Content), [className]);

    return (
      <SContentDiv {...rest} className={joinedClassName} ref={ref}>
        <SContentInnerDiv fullWidth={fullWidth === true}>{children}</SContentInnerDiv>
      </SContentDiv>
    );
  }),
);

const SScrollContentSContentInnerDiv = styled(SContentInnerDiv, {
  borderBottomColor: '$separatorBackground',
  borderBottomStyle: '$regular',
  borderBottomWidth: '$regular',
  paddingTop: '$structureScrollContentPaddingMobile',

  '@desktop': {
    paddingTop: '$structureScrollContentPaddingDesktop',
  },
});

const SScrollContentOuterDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
});

const SScrollContentInnerDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexGrow: 1,
  overflowY: 'auto',
  paddingY: '$structureScrollContentPaddingMobile',

  '@desktop': {
    paddingY: '$structureScrollContentPaddingDesktop',
  },

  variants: {
    reverse: {
      true: {
        flexDirection: 'column-reverse',
      },
      false: {
        flexDirection: 'column',
      },
    },
  },
});

const ScrollContentContext = createContext<TStructure['ScrollContentContext'] | undefined>(undefined);

function ScrollContent({ children, reverse, skipSeparator, fullWidth }: TStructure['ScrollContent']): JSX.Element {
  const breakpoint = useBreakpoint();
  const boundScrollY = useAnimatedValue(0, { immediate: true });
  const boundHeight = useAnimatedValue(0, { immediate: true });

  const bindScroll = useScroll(({ scrollY }) => {
    boundScrollY.value = scrollY;
  });

  const bindMeasure = useMeasure(
    ({ height }) => {
      const offset = breakpoint === EBreakpoints.PHONE || breakpoint === EBreakpoints.TABLET ? 32 : 48;
      boundHeight.value = ensureFiniteNumber(height) - offset;
    },
    [breakpoint, boundHeight],
  );

  const opacity: unknown = interpolate(boundScrollY.value, [0, 64], [0, 1], { extrapolate: 'clamp' });
  const value = useMemo<TStructure['ScrollContentContext']>(() => ({ height: boundHeight.value }), [boundHeight.value]);

  return (
    <Fragment>
      {skipSeparator !== true && (
        <SContentDiv>
          <AnimatedBlock style={{ opacity: reverse === true ? 1 : opacity }}>
            <SScrollContentSContentInnerDiv fullWidth={fullWidth === true} />
          </AnimatedBlock>
        </SContentDiv>
      )}
      <SScrollContentOuterDiv {...bindMeasure()}>
        <ScrollContentContext.Provider value={value}>
          <SScrollContentInnerDiv {...bindScroll()} reverse={reverse === true}>
            <Content fullWidth={fullWidth === true}>{children}</Content>
          </SScrollContentInnerDiv>
        </ScrollContentContext.Provider>
      </SScrollContentOuterDiv>
    </Fragment>
  );
}

const SScrollContentLoaderDiv = styled(Content, {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  marginTop: '$structureScrollContentPaddingMobile',
  overflow: 'hidden',
  paddingTop: '$structureScrollContentPaddingMobile',

  '@desktop': {
    marginTop: '$structureScrollContentPaddingDesktop',
    paddingTop: '$structureScrollContentPaddingDesktop',
  },
});

function ScrollContentLoader({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  return <SScrollContentLoaderDiv>{children}</SScrollContentLoaderDiv>;
}

const Stack = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$structureStackGapMobile',

  '@desktop': {
    gap: '$structureStackGapDesktop',
  },
});

const SGridDiv = styled('div', {
  alignItems: 'stretch',
  containerType: 'inline-size',
  display: 'flex',
  flexDirection: 'column',
});

const SGridInnerDiv = styled('div', {
  display: 'grid',
  gap: '$structureGridInnerGapMobile',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',

  '@desktop': {
    gap: '$structureGridInnerGapDesktop',
  },
});

const Grid = withCssToString(
  PAGE_CLASS_NAMES.Grid,
  forwardRef<HTMLDivElement, TStructure['Grid']>(({ children, className, ...rest }, ref) => {
    const joinedClassName = useMemo(() => joinClassNames(className, PAGE_CLASS_NAMES.Grid), [className]);

    return (
      <SGridDiv {...rest} className={joinedClassName} ref={ref}>
        <SGridInnerDiv>{children}</SGridInnerDiv>
      </SGridDiv>
    );
  }),
);

const SColumnsDiv = styled('div', {
  alignItems: 'stretch',
  containerType: 'inline-size',
  display: 'flex',
  flexDirection: 'column',
});

const SColumnsInnerDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  [CONTAINER_MEDIA_DESKTOP]: {
    flexDirection: 'row',
    gap: '24px',
  },
});

const SColumnsSideDivDesktop = makeAnimatedComponent(
  styled('div', {
    display: 'none',

    [CONTAINER_MEDIA_DESKTOP]: {
      alignItems: 'stretch',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      position: 'sticky',
      top: 0,
      width: '240px',
    },
  }),
);

const SColumnsMainDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',

  [CONTAINER_MEDIA_DESKTOP]: {
    flexGrow: 1,
  },
});

const Columns = withCssToString(
  PAGE_CLASS_NAMES.Columns,
  forwardRef<HTMLDivElement, TStructure['Columns']>(({ sideChildren, children, className, ...rest }, ref) => {
    const joinedClassName = useMemo(() => joinClassNames(className, PAGE_CLASS_NAMES.Columns), [className]);
    const scrollContentContext = useContext(ScrollContentContext);
    const height = scrollContentContext?.height;

    return (
      <SColumnsDiv {...rest} className={joinedClassName} ref={ref}>
        <SColumnsInnerDiv>
          <SColumnsSideDivDesktop style={{ height }}>{sideChildren}</SColumnsSideDivDesktop>
          <SColumnsMainDiv>{children}</SColumnsMainDiv>
        </SColumnsInnerDiv>
      </SColumnsDiv>
    );
  }),
);

const SGroupDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',

  variants: {
    variant: {
      default: {
        gap: '$structureGroupGapMobile',

        '@desktop': {
          gap: '$structureGroupGapDesktop',
        },
      },
      section: {
        gap: '$structureGroupGapMobile',

        '@desktop': {
          gap: '$structureGroupGapDesktop',
        },
      },
      secondary: {
        gap: '$structureGroupInnerGapMobile',

        '@desktop': {
          gap: '$structureGroupInnerGapDesktop',
        },
      },
    },
  },
});

const SGroupTitleDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$structureGroupInnerGapMobile',

  '@desktop': {
    gap: '$structureGroupInnerGapDesktop',
  },

  variants: {
    variant: {
      default: {
        // intentionally empty
      },
      section: {
        borderBottomColor: '$separatorBackground',
        borderBottomStyle: '$regular',
        borderBottomWidth: '$regular',
        fontStyle: 'italic',
        paddingBottom: '$structureGroupInnerGapMobile',

        '@desktop': {
          paddingBottom: '$structureGroupInnerGapDesktop',
        },
      },
      secondary: {
        // intentionally empty
      },
    },
  },
});

const SGroupInnerDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',

  variants: {
    variant: {
      default: {
        gap: '$structureGroupInnerGapMobile',

        '@desktop': {
          gap: '$structureGroupInnerGapDesktop',
        },
      },
      section: {
        gap: '$structureGroupGapMobile',
        paddingX: '$structureGroupGapMobile',

        '@desktop': {
          gap: '$structureGroupGapDesktop',
          paddingX: '$structureGroupGapDesktop',
        },
      },
      secondary: {
        gap: '$structureGroupInnerGapMobile',

        '@desktop': {
          gap: '$structureGroupInnerGapDesktop',
        },
      },
    },
  },
});

const Group = withCssToString(
  PAGE_CLASS_NAMES.Group,
  forwardRef<HTMLDivElement, TStructure['Group']>(
    ({ closingSeparator, icon, iconButtonActions, title, tag, variant, children, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, PAGE_CLASS_NAMES.Group), [className]);
      const IconComponent = useMemo(() => (isDef(icon) ? getIconComponent(icon) : null), [icon]);

      return (
        <Fragment>
          <SGroupDiv {...rest} className={joinedClassName} variant={variant ?? 'default'} ref={ref}>
            {((iconButtonActions?.length ?? 0) > 0 || isDef(title)) && (
              <SGroupTitleDiv variant={variant ?? 'default'}>
                {isDef(title) && variant !== 'secondary' && (
                  <Typography.SubTitle expanding={true} rigid={true} text={title} />
                )}
                {isDef(title) && variant === 'secondary' && (
                  <Typography.Cta expanding={true} rigid={true} text={title} />
                )}
                {isDef(tag) && <Tag text={tag} />}
                {isDef(IconComponent) && (
                  <IconComponent
                    height={theme.sizes.iconButtonIconSize.value}
                    width={theme.sizes.iconButtonIconSize.value}
                    style={{
                      flexShrink: 0,
                      backgroundColor: icon === 'warning' ? theme.colors.yellow2.value : 'inherit',
                    }}
                  />
                )}
                {iconButtonActions?.map((iconButtonAction) => (
                  <ActionIconButton action={iconButtonAction} key={iconButtonAction.icon} />
                ))}
              </SGroupTitleDiv>
            )}
            {isDef(children) && children !== false && (
              <SGroupInnerDiv variant={variant ?? 'default'}>{children}</SGroupInnerDiv>
            )}
          </SGroupDiv>
          {closingSeparator === true && <Drawer.Separator />}
        </Fragment>
      );
    },
  ),
);

const GroupLoader = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<{ hasTitle: boolean }>>(({ children, hasTitle }, ref) => (
    <SGroupDiv ref={ref} variant='default'>
      {hasTitle && (
        <Illustrations.Loader height={33} uniqueKey='wp-structure-group-loader' width={256}>
          <rect x='0' y='6' rx='0' ry='0' width='256' height='21' />
        </Illustrations.Loader>
      )}
      <SGroupInnerDiv variant='default'>{children}</SGroupInnerDiv>
    </SGroupDiv>
  )),
);

export const Structure = {
  Columns,
  Content,
  Grid,
  Group,
  GroupLoader,
  Main,
  Nav,
  Root,
  ScrollContent,
  ScrollContentLoader,
  Stack,
};
