import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item, ItemLoader } from '@src/components/appearance/fragments/Item';
import type { TAgentPublicCatalogProductBaseFragment } from '@src/gen/graphql/bindings';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { forwardRef, memo, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '128px',
});

export type TProductComparePickerProductCardBase = {
  isPinned: boolean;
  onClick: () => void;
  onTogglePin: () => void;
  publicCatalogProduct: TAgentPublicCatalogProductBaseFragment;
};

export type TProductComparePickerProductCard = TProps<false, TProductComparePickerProductCardBase, 'div'>;
export const PRODUCT_COMPARE_PICKER_PRODUCT_CARD_CLASS_NAME = 'wp-product-compare-picker-product-card';

export const ProductComparePickerProductCard = withCssToString(
  PRODUCT_COMPARE_PICKER_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TProductComparePickerProductCard>(
      ({ isPinned, onClick, onTogglePin, publicCatalogProduct, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, PRODUCT_COMPARE_PICKER_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const catalogItem = useMemo<TItemBaseItem>(
          () => ({ caption: 'Catalog', text: maybeGetSourceName(publicCatalogProduct.source) }),
          [publicCatalogProduct.source],
        );

        const skuItem = useMemo<TItemBaseItem>(
          () => ({ caption: 'SKU', text: publicCatalogProduct.product_sku }),
          [publicCatalogProduct.product_sku],
        );

        return (
          <Card.Container {...rest} className={joinedClassName} interactive={true} ref={ref}>
            <Card.ButtonArea onClick={onClick}>
              <SImageAsset alt={publicCatalogProduct.name} imageAssetPath={publicCatalogProduct.image_asset_path} />
              <Card.Separator />
              <Card.Title subTitle={publicCatalogProduct.secondary_name} title={publicCatalogProduct.name} />
              <Item item={catalogItem} rigid={true} />
              <Item item={skuItem} rigid={true} />
            </Card.ButtonArea>
            {publicCatalogProduct.is_discontinued && (
              <Card.ContentArea>
                <Card.Separator />
                <Typography.Annotation css={{ alignSelf: 'center' }} text='This product is no longer available.' />
              </Card.ContentArea>
            )}
            <Card.ContentArea>
              <Card.Separator />
              <IconButton css={{ alignSelf: 'flex-end' }} icon={isPinned ? 'unpin' : 'pin'} onClick={onTogglePin} />
            </Card.ContentArea>
          </Card.Container>
        );
      },
    ),
  ),
);

const SLoaderTopDiv = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const ProductComparePickerProductCardLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Card.Container ref={ref}>
        <SLoaderTopDiv>
          <Illustrations.Loader
            height={128}
            uniqueKey='wp-product-compare-picker-product-card-loader-image'
            width={192}>
            <rect x='0' y='5' rx='0' ry='0' width='192' height='128' />
          </Illustrations.Loader>
        </SLoaderTopDiv>
        <Card.Separator />
        <Card.TitleLoader />
        <ItemLoader />
        <ItemLoader />
        <Card.Separator />
        <IconButton css={{ alignSelf: 'flex-end' }} icon='pin' onClick={noop} variant='disabled' />
      </Card.Container>
    ),
  ),
);
