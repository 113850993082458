import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EExportFormat {
  XLSX = 'xlsx',
  PDF = 'pdf',
  JSON = 'json',
}

const EXPORT_FORMAT_LOOKUP = mapValues(invert(EExportFormat), () => true);

export function getExportFormat(rawExportFormat: string): EExportFormat {
  assert(EXPORT_FORMAT_LOOKUP[rawExportFormat], `Unknown export format: "${rawExportFormat}".`);
  return rawExportFormat as EExportFormat; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetExportFormat(rawExportFormat: string): EExportFormat | undefined {
  try {
    return getExportFormat(rawExportFormat);
  } catch {
    return undefined;
  }
}
