import { Typography } from '@src/components/appearance/basics/Typography';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import { ChatBubble } from '@src/components/appearance/fragments/ChatBubble';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderSnapshotEntryProductCard } from '@src/components/mixins/cards/OrderSnapshotEntryProductCard';
import { AgentOrderSnapshotManager } from '@src/gen/shared/data/agentOrders';
import type { CustomerOrderSnapshotManager } from '@src/gen/shared/data/customerOrders';
import type { OrderBudgetAssistantManager } from '@src/gen/shared/data/orderBudgetAssistantManager';
import { getDateOn, getFullName, getOrderReference } from '@src/gen/shared/data/snippets';
import { maybeGetDonationTargetLabel } from '@src/gen/shared/enums/donationTarget';
import { maybeGetRevenueShareTargetLabel } from '@src/gen/shared/enums/revenueShareTarget';
import { formatDollarsCurrency, formatPercent } from '@src/gen/shared/utils/converters';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

export type TOrderSnapshotStructureGroupBase = {
  beginOrderSnapshotEntryPlanQuantityEdit: TCustomerOrderActionsTypes['BeginOrderSnapshotEntryPlanQuantityEdit'] | null;
  beginOrderSnapshotEntryView: TAgentOrderActionsTypes['BeginOrderSnapshotEntryView'];
  isSummary: boolean;
  orderBudgetAssistantManager: OrderBudgetAssistantManager | null;
  orderSnapshotManager: AgentOrderSnapshotManager | CustomerOrderSnapshotManager;
};

export type TOrderSnapshotStructureGroup = TProps<false, TOrderSnapshotStructureGroupBase, 'div'>;
export const ORDER_SNAPSHOT_STRUCTURE_GROUP = 'wp-order-snapshot-structure-group';

export const OrderSnapshotStructureGroup = withCssToString(
  ORDER_SNAPSHOT_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderSnapshotStructureGroup>(
      (
        {
          beginOrderSnapshotEntryPlanQuantityEdit,
          beginOrderSnapshotEntryView,
          isSummary,
          orderBudgetAssistantManager,
          orderSnapshotManager,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_SNAPSHOT_STRUCTURE_GROUP), [className]);
        const maybeEstimateAfterCustomerEdits = orderSnapshotManager.maybeGetEstimateAfterCustomerEdits();

        const budgetResult = orderBudgetAssistantManager?.getResult(
          isDef(maybeEstimateAfterCustomerEdits)
            ? { [orderSnapshotManager.orderSnapshot.order_id]: maybeEstimateAfterCustomerEdits.wellplaece_total }
            : {},
        );

        const itemsFirst = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Order Ref.',
              text: getOrderReference(
                orderSnapshotManager.orderSnapshot.location_order_code,
                orderSnapshotManager.orderSnapshot.order_reference_number,
              ),
            },

            {
              caption: 'Revision',
              text:
                orderSnapshotManager.orderSnapshot.revision > 0
                  ? `#${orderSnapshotManager.orderSnapshot.revision}`
                  : 'Initial',
            },
            {
              caption: 'Issued By',
              text: getFullName(orderSnapshotManager.orderSnapshot.creator),
            },
            {
              caption: 'Issued On',
              text: { asDate: 'on', at: orderSnapshotManager.orderSnapshot.created_at },
            },
          ],
          // @sort
          [
            orderSnapshotManager.orderSnapshot.created_at,
            orderSnapshotManager.orderSnapshot.creator,
            orderSnapshotManager.orderSnapshot.location_order_code,
            orderSnapshotManager.orderSnapshot.order_reference_number,
            orderSnapshotManager.orderSnapshot.revision,
          ],
        );

        const itemsBase = useMemo(
          () =>
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            [
              {
                caption: 'Sub Total (Base)',
                text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.base_sub_total),
              },
              ...(orderSnapshotManager.orderSnapshot.wellplaece_donation > 0
                ? [
                    {
                      caption: 'Shipping + Tax (Base)',
                      text: `${formatDollarsCurrency(
                        orderSnapshotManager.orderSnapshot.base_shipping,
                      )} + ${formatDollarsCurrency(orderSnapshotManager.orderSnapshot.base_tax)}`,
                    },
                    {
                      caption: '',
                      text: '',
                    },
                  ]
                : [
                    {
                      caption: 'Shipping (Base)',
                      text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.base_shipping),
                    },
                    {
                      caption: 'Tax (Base)',
                      text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.base_tax),
                    },
                  ]),
              {
                caption: 'Total (Base)',
                text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.base_total),
              },
            ] as unknown as TItemsBase['items'],
          // @sort
          [
            orderSnapshotManager.orderSnapshot.base_shipping,
            orderSnapshotManager.orderSnapshot.base_sub_total,
            orderSnapshotManager.orderSnapshot.base_tax,
            orderSnapshotManager.orderSnapshot.base_total,
            orderSnapshotManager.orderSnapshot.wellplaece_donation,
          ],
        );

        const itemsWellplaece = useMemo(
          () =>
            isDef(maybeEstimateAfterCustomerEdits)
              ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                ([
                  {
                    caption: 'Sub Total (Wellplaece)',
                    accent: 'warning',
                    text: formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_sub_total),
                  },
                  ...(maybeEstimateAfterCustomerEdits.wellplaece_donation > 0
                    ? [
                        {
                          caption: 'Shipping & Handling + Tax (Wellplaece)',
                          accent: 'warning',
                          text: `${formatDollarsCurrency(
                            maybeEstimateAfterCustomerEdits.wellplaece_fees,
                          )} + ${formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_tax)}`,
                        },
                        {
                          caption: 'Your Charitable Donation',
                          accent: 'warning',
                          text: `${formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_donation)}`,
                        },
                      ]
                    : [
                        {
                          caption: 'Shipping & Handling (Wellplaece)',
                          accent: 'warning',
                          text: formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_fees),
                        },
                        {
                          caption: 'Tax (Wellplaece)',
                          accent: 'warning',
                          text: formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_tax),
                        },
                      ]),
                  {
                    caption: 'Total (Wellplaece)',
                    accent: 'warning',
                    text: `${formatDollarsCurrency(maybeEstimateAfterCustomerEdits.wellplaece_total)}`,
                  },
                ] as unknown as TItemsBase['items'])
              : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                ([
                  {
                    caption: 'Sub Total (Wellplaece)',
                    text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_sub_total),
                  },
                  ...(orderSnapshotManager.orderSnapshot.wellplaece_donation > 0
                    ? [
                        {
                          caption: 'Shipping & Handling + Tax (Wellplaece)',
                          text: `${formatDollarsCurrency(
                            orderSnapshotManager.orderSnapshot.wellplaece_fees,
                          )} + ${formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_tax)}`,
                        },
                        {
                          caption: 'Your Charitable Donation',
                          text: `${formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_donation)}`,
                        },
                      ]
                    : [
                        {
                          caption: 'Shipping & Handling (Wellplaece)',
                          text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_fees),
                        },
                        {
                          caption: 'Tax (Wellplaece)',
                          text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_tax),
                        },
                      ]),
                  {
                    caption: 'Total (Wellplaece)',
                    text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_total),
                  },
                ] as unknown as TItemsBase['items']),
          [
            maybeEstimateAfterCustomerEdits,
            orderSnapshotManager.orderSnapshot.wellplaece_donation,
            orderSnapshotManager.orderSnapshot.wellplaece_fees,
            orderSnapshotManager.orderSnapshot.wellplaece_sub_total,
            orderSnapshotManager.orderSnapshot.wellplaece_tax,
            orderSnapshotManager.orderSnapshot.wellplaece_total,
          ],
        );

        const itemsAgent = useMemo<TItemsBase['items'] | null>(() => {
          if (orderSnapshotManager instanceof AgentOrderSnapshotManager) {
            return [
              {
                caption: 'CC Fee',
                text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.agent_wellplaece_cc_fee),
              },
              {
                caption: 'Shipping Fees',
                text: formatDollarsCurrency(orderSnapshotManager.orderSnapshot.agent_wellplaece_shipping),
              },
              {
                caption: 'Commission',
                text: `${formatDollarsCurrency(
                  orderSnapshotManager.orderSnapshot.agent_wellplaece_commission,
                )} (${formatPercent(orderSnapshotManager.orderSnapshot.agent_wellplaece_commission_percent)})`,
              },

              {
                caption: 'Effective Savings',
                text: formatPercent(orderSnapshotManager.orderSnapshot.effective_savings_percent),
              },
            ];
          }

          return null;
        }, [orderSnapshotManager]);

        const charityItems = useMemo<TItemsBase['items'] | null>(() => {
          if (
            orderSnapshotManager instanceof AgentOrderSnapshotManager &&
            isDef(orderSnapshotManager.orderSnapshot.wellplaece_donation_target)
          ) {
            return [
              {
                caption: 'Charity Program',
                text: maybeGetDonationTargetLabel(orderSnapshotManager.orderSnapshot.wellplaece_donation_target),
              },
              {
                caption: 'Charity Donation Amount',
                text: `${formatDollarsCurrency(orderSnapshotManager.orderSnapshot.wellplaece_donation)}`,
              },
            ];
          }

          return null;
        }, [orderSnapshotManager]);

        const revenueShareItems = useMemo<TItemsBase['items'] | null>(() => {
          if (
            orderSnapshotManager instanceof AgentOrderSnapshotManager &&
            isDef(orderSnapshotManager.orderSnapshot.agent_wellplaece_revenue_share_target)
          ) {
            return [
              {
                caption: 'Revenue Share Program',
                text: maybeGetRevenueShareTargetLabel(
                  orderSnapshotManager.orderSnapshot.agent_wellplaece_revenue_share_target,
                ),
              },
              {
                caption: 'Revenue Share Amount',
                text: `${formatDollarsCurrency(orderSnapshotManager.orderSnapshot.agent_wellplaece_revenue_share)}`,
              },
            ];
          }

          return null;
        }, [orderSnapshotManager]);

        return (
          <Structure.Stack {...rest} className={joinedClassName} ref={ref}>
            <Structure.Group>
              {isDef(orderSnapshotManager.orderSnapshot.approved_at) &&
                isDef(orderSnapshotManager.orderSnapshot.approver) && (
                  <Banner
                    icon='apply'
                    accent='success'
                    message={`Approved by ${getFullName(orderSnapshotManager.orderSnapshot.approver)} on ${getDateOn(
                      orderSnapshotManager.orderSnapshot.approved_at,
                    )}.`}
                  />
                )}
              {isDef(orderSnapshotManager.orderSnapshot.rejected_at) &&
                isDef(orderSnapshotManager.orderSnapshot.rejector) && (
                  <Fragment>
                    {orderSnapshotManager.orderSnapshot.rejector.is_wellplaece_agent ? (
                      <Banner
                        icon='forbidden'
                        accent='error'
                        message={`Retracted by ${getFullName(
                          orderSnapshotManager.orderSnapshot.rejector,
                        )} on ${getDateOn(orderSnapshotManager.orderSnapshot.rejected_at)}.`}
                      />
                    ) : (
                      <Banner
                        icon='forbidden'
                        accent='error'
                        message={`Rejected by ${getFullName(
                          orderSnapshotManager.orderSnapshot.rejector,
                        )} on ${getDateOn(orderSnapshotManager.orderSnapshot.rejected_at)}.`}>
                        {isDef(orderSnapshotManager.orderSnapshot.rejected_reason) && (
                          <ChatBubble
                            at={orderSnapshotManager.orderSnapshot.rejected_at}
                            firstName={orderSnapshotManager.orderSnapshot.rejector.first_name}
                            lastName={orderSnapshotManager.orderSnapshot.rejector.last_name}
                            content={orderSnapshotManager.orderSnapshot.rejected_reason}
                          />
                        )}
                      </Banner>
                    )}
                  </Fragment>
                )}
              <ItemsBanner items={itemsFirst} />
              <Card.Container flush={true} interactive={false}>
                <Card.ContentArea>
                  <Typography.Annotation text={'Before Wellplaece...'.toUpperCase()} />
                  <Items items={itemsBase} />
                </Card.ContentArea>
                <Card.Footer>
                  <Typography.Annotation
                    text={`...With Wellplaece${
                      isDef(maybeEstimateAfterCustomerEdits) ? ' (Estimate After Your Changes)' : ''
                    }`.toUpperCase()}
                  />
                  <Items items={itemsWellplaece} />
                </Card.Footer>
              </Card.Container>
              {!isDef(maybeEstimateAfterCustomerEdits) && (
                <Fragment>
                  {orderSnapshotManager.orderSnapshot.savings > 0 && (
                    <Banner
                      icon='dollar'
                      accent='success'
                      message={`By choosing Wellplaece, you are saving ${formatDollarsCurrency(
                        orderSnapshotManager.orderSnapshot.savings,
                      )} (${formatPercent(orderSnapshotManager.orderSnapshot.raw_savings_percent)}) on this order!`}
                    />
                  )}
                  {orderSnapshotManager.orderSnapshot.wellplaece_donation > 0 && (
                    <Banner
                      icon='gift'
                      accent='success'
                      message={`Your order includes a charitable donation of ${formatDollarsCurrency(
                        orderSnapshotManager.orderSnapshot.wellplaece_donation,
                      )} for ${maybeGetDonationTargetLabel(
                        ensureDef(orderSnapshotManager.orderSnapshot.wellplaece_donation_target),
                      )}.`}
                    />
                  )}
                </Fragment>
              )}
              {isDef(maybeEstimateAfterCustomerEdits) && (
                <Banner
                  icon='warning'
                  accent='warning'
                  message='The pricing displayed above is an estimate after your changes. Your invoice will reflect the final price, which might require small adjustments.'
                />
              )}

              {orderSnapshotManager.orderSnapshotEntryManagers.filter((opem) => !opem.isPurchasedByWellplaece())
                .length > 0 && (
                <Banner
                  icon='warning'
                  accent='warning'
                  message='Some products originally in this order have been removed.'
                />
              )}
            </Structure.Group>
            {isDef(itemsAgent) && (
              <Structure.Group title='Agent-Only Details' variant='secondary'>
                <ItemsBanner items={itemsAgent} />
                {isDef(charityItems) && <ItemsBanner items={charityItems} />}
                {isDef(revenueShareItems) && <ItemsBanner items={revenueShareItems} />}
              </Structure.Group>
            )}

            {isDef(budgetResult) && budgetResult.summaries.length > 0 && (
              <Structure.Group title={'Budget Assistant'} variant='secondary'>
                <Card.Container flush={true} interactive={false}>
                  {budgetResult.summaries.map((s) => (
                    <Items
                      key={s.orderReference}
                      items={[
                        {
                          caption: 'Order Reference',
                          text: s.orderReference,
                        },
                        {
                          caption: 'Placed On',
                          text: { asDate: 'date', at: s.placedOn },
                        },
                        {
                          caption: 'Invoice/Prebill Status',
                          text: s.status,
                        },
                        {
                          caption: 'Wellplaece Total',
                          accent: s.isCustomerEdited ? 'warning' : undefined,
                          text: isDef(s.wellplaeceTotal) ? formatDollarsCurrency(s.wellplaeceTotal) : 'TBD',
                        },
                      ]}
                    />
                  ))}
                  <Card.Footer>
                    <Items
                      items={[
                        {
                          caption: 'Estimates For',
                          text: ensureDef(orderBudgetAssistantManager).monthYearLabel,
                        },
                        {
                          caption: 'Already Invoiced',
                          text: formatDollarsCurrency(budgetResult.invoiced),
                        },
                        {
                          caption: 'Still Processing',
                          text: formatDollarsCurrency(budgetResult.toBeFinalized),
                        },
                        {
                          caption: 'Estimated Spend',
                          text: `${formatDollarsCurrency(budgetResult.estimatedTotal)}${
                            budgetResult.preparingCount > 0 ? ` + ${budgetResult.preparingCount} order(s) TBD` : ''
                          }`,
                        },
                      ]}
                    />
                  </Card.Footer>
                </Card.Container>
              </Structure.Group>
            )}
            {!isSummary && (
              <Fragment>
                <Structure.Group title='Included in this Order' variant='secondary'>
                  {orderSnapshotManager.orderSnapshotEntryManagers
                    .filter((opem) => opem.isPurchasedByWellplaece())
                    .map((opem) => (
                      <OrderSnapshotEntryProductCard
                        key={opem.orderSnapshotEntry.product_id}
                        beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
                        orderSnapshotEntryManager={opem}
                        beginOrderSnapshotEntryPlanQuantityEdit={beginOrderSnapshotEntryPlanQuantityEdit}
                      />
                    ))}
                </Structure.Group>
                {orderSnapshotManager.orderSnapshotEntryManagers.filter((opem) => opem.isPurchasedByCustomer()).length >
                  0 && (
                  <Structure.Group title='Removed, Customer to Purchase Directly' variant='secondary'>
                    {orderSnapshotManager.orderSnapshotEntryManagers
                      .filter((opem) => opem.isPurchasedByCustomer())
                      .map((opem) => (
                        <OrderSnapshotEntryProductCard
                          key={opem.orderSnapshotEntry.product_id}
                          beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
                          orderSnapshotEntryManager={opem}
                          beginOrderSnapshotEntryPlanQuantityEdit={null}
                        />
                      ))}
                  </Structure.Group>
                )}
                {orderSnapshotManager.orderSnapshotEntryManagers.filter(
                  (opem) => opem.isRemoved() && !opem.isPurchasedByCustomer(),
                ).length > 0 && (
                  <Structure.Group title='Removed' variant='secondary'>
                    {orderSnapshotManager.orderSnapshotEntryManagers
                      .filter((opem) => opem.isRemoved() && !opem.isPurchasedByCustomer())
                      .map((opem) => (
                        <OrderSnapshotEntryProductCard
                          key={opem.orderSnapshotEntry.product_id}
                          beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
                          orderSnapshotEntryManager={opem}
                          beginOrderSnapshotEntryPlanQuantityEdit={null}
                        />
                      ))}
                  </Structure.Group>
                )}
              </Fragment>
            )}
          </Structure.Stack>
        );
      },
    ),
  ),
);
