import { Typography } from '@src/components/appearance/basics/Typography';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import { ChatBubble } from '@src/components/appearance/fragments/ChatBubble';
import { ItemsLoader } from '@src/components/appearance/fragments/Items';
import type {
  TAgentNotificationCompleteFragment,
  TCustomerNotificationCompleteFragment,
} from '@src/gen/graphql/bindings';
import { ENotificationType, castNotificationUnsafe } from '@src/gen/shared/data/notification';
import { getOrderReference } from '@src/gen/shared/data/snippets';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import assert from 'assert';
import { forwardRef, memo, useMemo } from 'react';

export type TNotificationCardBase = {
  notification: TAgentNotificationCompleteFragment | TCustomerNotificationCompleteFragment;
  onClear?: (() => void) | undefined;
  to: string;
};

export type TNotificationCard = TProps<false, TNotificationCardBase, 'div'>;
export const NOTIFICATION_CARD_CLASS_NAME = 'wp-notification-card';

export const NotificationCard = withCssToString(
  NOTIFICATION_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNotificationCard>(
      ({ notification, onClear, to, className, ...rest }, ref): JSX.Element => {
        assert(notification.order_notifications.length > 0, 'Currently only order notifications are supported.');
        const joinedClassName = useMemo(() => joinClassNames(className, NOTIFICATION_CARD_CLASS_NAME), [className]);

        const oldestContent = useMemo(
          () =>
            castNotificationUnsafe(
              ensureDef(notification.order_notifications[notification.order_notifications.length - 1])
                .notification_content,
            ),
          [notification.order_notifications],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () => (isDef(onClear) ? [{ isAsync: false, icon: 'apply', onClick: onClear }] : []),
          [onClear],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flush={true}
            iconButtonActions={iconButtonActions}
            interactive={true}
            ref={ref}>
            <Card.LinkArea to={to}>
              <Card.Title
                preTitle='In Order'
                title={getOrderReference(oldestContent.location.orderCode, oldestContent.order.referenceNumber)}
                subTitle={`for ${oldestContent.location.name}`}
              />
              {oldestContent.type !== ENotificationType.ORDER_PLACED && (
                <Typography.Caption text='See order page for older activity...' css={{ alignSelf: 'center' }} />
              )}
              {[...notification.order_notifications].reverse().map((on) => {
                const content = castNotificationUnsafe(on.notification_content);

                return (
                  <ChatBubble
                    key={on.id}
                    at={content.at}
                    compact={true}
                    content={
                      content.type === ENotificationType.ORDER_MESSAGE_SENT
                        ? content.orderMessage.chatContentSummary
                        : content
                    }
                    firstName={content.user.firstName}
                    lastName={content.user.lastName}
                    wellplaeceAgent={content.user.isWellplaeceAgent}
                  />
                );
              })}
            </Card.LinkArea>
          </Card.Container>
        );
      },
    ),
  ),
);

export const NotificationCardLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Card.Container ref={ref}>
        <Card.TitleLoader />
        <ItemsLoader count={4} />
      </Card.Container>
    ),
  ),
);
