import 'core-js';

import { ensureDef } from '@src/gen/shared/utils/types';
import { AuthProvider } from '@src/modules/auth/AuthProvider';
import { ConfigProvider, initStaticConfig } from '@src/modules/config/ConfigProvider';
import { ChromeProvider } from '@src/modules/design/ChromeProvider';
import { DesignProvider } from '@src/modules/design/DesignProvider';
import { DialogProvider } from '@src/modules/design/DialogProvider';
import { DrawerProvider, TopDrawerProvider } from '@src/modules/design/DrawerProvider';
import { ToastProvider } from '@src/modules/design/ToastProvider';
import { ErrorsProvider } from '@src/modules/errors/ErrorsProvider';
import { Router } from '@src/modules/routing/Router';
import { WorkersProvider } from '@src/modules/workers/WorkersProvider';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

initStaticConfig();

ReactDOM.createRoot(ensureDef(document.getElementById('root'))).render(
  <StrictMode>
    <ConfigProvider>
      <DesignProvider>
        <ToastProvider>
          <ErrorsProvider>
            <WorkersProvider>
              <DialogProvider>
                <AuthProvider>
                  <DrawerProvider>
                    <TopDrawerProvider>
                      <ChromeProvider>
                        <Router />
                      </ChromeProvider>
                    </TopDrawerProvider>
                  </DrawerProvider>
                </AuthProvider>
              </DialogProvider>
            </WorkersProvider>
          </ErrorsProvider>
        </ToastProvider>
      </DesignProvider>
    </ConfigProvider>
  </StrictMode>,
);
