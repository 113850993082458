import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { CompleteOnboardingAnnouncement } from '@src/components/mixins/announcements/CompleteOnboardingAnnouncement';
import { NextOrderPlacingOrderAnnouncement } from '@src/components/mixins/announcements/NextOrderPlacingOrderAnnouncement';
import { CustomerNavPart } from '@src/components/parts/customer/CustomerNavPart';
import {
  CustomerAnalyticsOrdersPart,
  CustomerAnalyticsOrdersPartLoader,
} from '@src/components/parts/customer/analytics/CustomerAnalyticsOrdersPart';
import { CustomerNextOrderCartPart } from '@src/components/parts/customer/nextOrder/CustomerNextOrderCartPart';
import {
  CustomerNextOrderCatalogsPart,
  CustomerNextOrderCatalogsPartLoader,
} from '@src/components/parts/customer/nextOrder/CustomerNextOrderCatalogsPart';
import {
  CustomerNextOrderFormularyPart,
  CustomerNextOrderFormularyPartLoader,
} from '@src/components/parts/customer/nextOrder/CustomerNextOrderFormularyPart';
import {
  CustomerNextOrderHistoryPart,
  CustomerNextOrderHistoryPartLoader,
} from '@src/components/parts/customer/nextOrder/CustomerNextOrderHistoryPart';
import {
  CustomerNextOrderNavPart,
  CustomerNextOrderNavPartLoader,
} from '@src/components/parts/customer/nextOrder/CustomerNextOrderNavPart';
import {
  CustomerOrderNavPart,
  CustomerOrderNavPartLoader,
} from '@src/components/parts/customer/order/CustomerOrderNavPart';
import { CustomerOrderSummaryPart } from '@src/components/parts/customer/order/CustomerOrderSummaryPart';
import { CustomerOrderUpdatesAndSupportPart } from '@src/components/parts/customer/order/CustomerOrderUpdatesAndSupportPart';
import { CustomerOrdersPart, CustomerOrdersPartLoader } from '@src/components/parts/customer/orders/CustomerOrdersPart';
import { CustomerUserAccountPart } from '@src/components/parts/customer/user/CustomerUserAccountPart';
import { CustomerUserNavPart } from '@src/components/parts/customer/user/CustomerUserNavPart';
import {
  CustomerUserNotificationsPart,
  CustomerUserPartLoader,
} from '@src/components/parts/customer/user/CustomerUserNotificationsPart';
import { ensureDef, ensureNotEmptyString, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useAuthCustomer, useAuthCustomerLocation, useAuthCustomerOrganization } from '@src/modules/auth/AuthProvider';
import type { TAuthCustomer, TAuthLocation, TAuthOrganization } from '@src/modules/auth/subject';
import { CustomerAnalyticsOrdersActionsProvider } from '@src/modules/data/customer/analytics/CustomerAnalyticsOrdersActionsProvider';
import { CustomerAnalyticsOrdersProvider } from '@src/modules/data/customer/analytics/CustomerAnalyticsOrdersProvider';
import { CustomerUploadsProvider } from '@src/modules/data/customer/global/uploads/CustomerUploadsProvider';
import { CustomerNextOrderActionsProvider } from '@src/modules/data/customer/nextOrder/CustomerNextOrderActionsProvider';
import { CustomerNextOrderPastOrdersCatalogsFilterProvider } from '@src/modules/data/customer/nextOrder/CustomerNextOrderHistoryProvider';
import { CustomerNextOrderProvider } from '@src/modules/data/customer/nextOrder/CustomerNextOrderProvider';
import { CustomerNextOrderSearchProvider } from '@src/modules/data/customer/nextOrder/CustomerNextOrderSearchProvider';
import { CustomerNotificationsActionsProvider } from '@src/modules/data/customer/notifications/CustomerNotificationsActionsProvider';
import { CustomerNotificationsProvider } from '@src/modules/data/customer/notifications/CustomerNotificationsProvider';
import { CustomerOrderActionsProvider } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import { CustomerOrderBudgetAssistantProvider } from '@src/modules/data/customer/order/CustomerOrderBudgetAssistantProvider';
import { CustomerOrderMessagesProvider } from '@src/modules/data/customer/order/CustomerOrderMessagesProvider';
import { CustomerOrderProvider } from '@src/modules/data/customer/order/CustomerOrderProvider';
import { CustomerOrderSnapshotReviewChromeProvider } from '@src/modules/data/customer/order/CustomerOrderSnapshotReviewChromeProvider';
import { CustomerOrdersProvider } from '@src/modules/data/customer/orders/CustomerOrdersProvider';
import { AmplitudeAnalyticsActionsProvider } from '@src/modules/data/shared/analytics/AmplitudeAnalyticsProvider';
import { DateRangePickerDrawerProvider } from '@src/modules/data/shared/overlays/DateRangePickerDrawerProvider';
import { RouterErrorElement } from '@src/modules/errors/RouterErrorElement';
import assert from 'assert';
import { Base64 } from 'js-base64';
import { Fragment, useMemo } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, Outlet, generatePath, matchPath, useLocation } from 'react-router-dom';

export enum ECustomerRoutes {
  CUSTOMER_NEXT_ORDER_CART = '/customer/:organizationId/next-order/:locationId/cart',
  CUSTOMER_NEXT_ORDER_HISTORY = '/customer/:organizationId/next-order/:locationId/history',
  CUSTOMER_NEXT_ORDER_FORMULARY = '/customer/:organizationId/next-order/:locationId/formulary/:config',
  CUSTOMER_NEXT_ORDER_CATALOGS = '/customer/:organizationId/next-order/:locationId/catalogs/:config',
  CUSTOMER_ORDERS = '/customer/:organizationId/orders',
  CUSTOMER_ORDER_SUMMARY = '/customer/:organizationId/order/:orderId/summary',
  CUSTOMER_ORDER_UPDATES_AND_SUPPORT = '/customer/:organizationId/order/:orderId/updates-and-support',
  CUSTOMER_ANALYTICS_ORDERS = '/customer/:organizationId/analytics/orders/:config',
  CUSTOMER_USER_ACCOUNT = '/customer/:organizationId/user/account',
  CUSTOMER_USER_NOTIFICATIONS = '/customer/:organizationId/user/notifications',
}

export type TCustomerNextOrderCartRouteParams = {
  route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART;
  organizationId: string;
  locationId: string;
};

export type TCustomerNextOrderHistoryRouteParams = {
  route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY;
  organizationId: string;
  locationId: string;
};

export type TCustomerNextOrderFormularyRouteParamsConfig = {
  query: string | null;
  sourceFilter: string | null;
  categoryName01Filter: string | null;
  categoryName02Filter: string | null;
  manufacturerNameFilter: string | null;
};

export function encodeCustomerNextOrderFormularyRouteParamsConfig(
  filter: TCustomerNextOrderFormularyRouteParamsConfig,
): string {
  return Base64.encode(
    JSON.stringify([
      2,
      filter.query,
      filter.sourceFilter,
      filter.categoryName01Filter,
      filter.categoryName02Filter,
      filter.manufacturerNameFilter,
    ]),
  );
}

export function decodeCustomerNextOrderFormularyRouteParamsConfig(
  config: string,
): TCustomerNextOrderFormularyRouteParamsConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(Base64.decode(config));

    if (Array.isArray(parsed)) {
      assert(parsed[0] === 2);

      return {
        query: isDef(parsed[1]) ? ensureNotEmptyString(parsed[1]) : null,
        sourceFilter: isDef(parsed[2]) ? ensureNotEmptyString(parsed[2]) : null,
        categoryName01Filter: isDef(parsed[3]) ? ensureNotEmptyString(parsed[3]) : null,
        categoryName02Filter: isDef(parsed[4]) ? ensureNotEmptyString(parsed[4]) : null,
        manufacturerNameFilter: isDef(parsed[5]) ? ensureNotEmptyString(parsed[5]) : null,
      };
    }
  } catch {
    // intentionally empty
  }

  throw new Error(`Invalid config: "${config}".`);
}

export type TCustomerNextOrderFormularyRouteParams = {
  route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY;
  organizationId: string;
  locationId: string;
  config: TCustomerNextOrderFormularyRouteParamsConfig;
};

export type TCustomerNextOrderCatalogsRouteParamsConfig = {
  query: string | null;
  sourceFilter: string | null;
  categoryName01Filter: string | null;
  categoryName02Filter: string | null;
  manufacturerNameFilter: string | null;
};

export function encodeCustomerNextOrderCatalogsRouteParamsConfig(
  filter: TCustomerNextOrderCatalogsRouteParamsConfig,
): string {
  return Base64.encode(
    JSON.stringify([
      2,
      filter.query,
      filter.sourceFilter,
      filter.categoryName01Filter,
      filter.categoryName02Filter,
      filter.manufacturerNameFilter,
    ]),
  );
}

export function decodeCustomerNextOrderCatalogsRouteParamsConfig(
  config: string,
): TCustomerNextOrderCatalogsRouteParamsConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(Base64.decode(config));

    if (Array.isArray(parsed)) {
      assert(parsed[0] === 2);

      return {
        query: isDef(parsed[1]) ? ensureNotEmptyString(parsed[1]) : null,
        sourceFilter: isDef(parsed[2]) ? ensureNotEmptyString(parsed[2]) : null,
        categoryName01Filter: isDef(parsed[3]) ? ensureNotEmptyString(parsed[3]) : null,
        categoryName02Filter: isDef(parsed[4]) ? ensureNotEmptyString(parsed[4]) : null,
        manufacturerNameFilter: isDef(parsed[5]) ? ensureNotEmptyString(parsed[5]) : null,
      };
    }
  } catch {
    // intentionally empty
  }

  throw new Error(`Invalid config: "${config}".`);
}

export type TCustomerNextOrderCatalogsRouteParams = {
  route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS;
  organizationId: string;
  locationId: string;
  config: TCustomerNextOrderCatalogsRouteParamsConfig;
};

export type TCustomerOrdersRouteParams = {
  route: ECustomerRoutes.CUSTOMER_ORDERS;
  organizationId: string;
};

export type TCustomerOrderSummaryRouteParams = {
  route: ECustomerRoutes.CUSTOMER_ORDER_SUMMARY;
  organizationId: string;
  orderId: string;
};

export type TCustomerOrderUpdatesAndSupportRouteParams = {
  route: ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT;
  organizationId: string;
  orderId: string;
};

export type TCustomerAnalyticsOrdersRouteParamsConfig = {
  locationId: string | null;
  startTime: string | null;
  endTime: string | null;
};

export function encodeCustomerAnalyticsOrdersRouteParamsConfig(
  config: TCustomerAnalyticsOrdersRouteParamsConfig,
): string {
  return Base64.encode(JSON.stringify([1, config.locationId, config.startTime, config.endTime]));
}

export function decodeCustomerAnalyticsOrdersRouteParamsConfig(
  config: string,
): TCustomerAnalyticsOrdersRouteParamsConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(Base64.decode(config));

    if (Array.isArray(parsed)) {
      assert(parsed[0] === 1);

      return {
        locationId: isDef(parsed[1]) ? ensureNotEmptyString(parsed[1]) : null,
        startTime: isDef(parsed[2]) ? ensureNotEmptyString(parsed[2]) : null,
        endTime: isDef(parsed[3]) ? ensureNotEmptyString(parsed[3]) : null,
      };
    }
  } catch {
    // intentionally empty
  }

  throw new Error(`Invalid config: "${config}".`);
}

export type TCustomerAnalyticsOrdersRouteParams = {
  route: ECustomerRoutes.CUSTOMER_ANALYTICS_ORDERS;
  organizationId: string;
  config: TCustomerAnalyticsOrdersRouteParamsConfig;
};

export type TCustomerUserAccountRouteParams = {
  route: ECustomerRoutes.CUSTOMER_USER_ACCOUNT;
  organizationId: string;
};

export type TCustomerUserNotificationsRouteParams = {
  route: ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS;
  organizationId: string;
};

export type TCustomerRouteParams =
  | TCustomerAnalyticsOrdersRouteParams
  | TCustomerNextOrderCartRouteParams
  | TCustomerNextOrderCatalogsRouteParams
  | TCustomerNextOrderFormularyRouteParams
  | TCustomerNextOrderHistoryRouteParams
  | TCustomerOrdersRouteParams
  | TCustomerOrderSummaryRouteParams
  | TCustomerOrderUpdatesAndSupportRouteParams
  | TCustomerUserAccountRouteParams
  | TCustomerUserNotificationsRouteParams;

export function encodeCustomerPath(params: TCustomerRouteParams): string {
  switch (params.route) {
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART:
      return generatePath(params.route, {
        organizationId: params.organizationId,
        locationId: params.locationId,
      });
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY:
      return generatePath(params.route, {
        organizationId: params.organizationId,
        locationId: params.locationId,
      });
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY:
      return generatePath(params.route, {
        organizationId: params.organizationId,
        locationId: params.locationId,
        config: encodeCustomerNextOrderFormularyRouteParamsConfig(params.config),
      });
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS:
      return generatePath(params.route, {
        organizationId: params.organizationId,
        locationId: params.locationId,
        config: encodeCustomerNextOrderCatalogsRouteParamsConfig(params.config),
      });
    case ECustomerRoutes.CUSTOMER_ORDERS:
      return generatePath(params.route, {
        organizationId: params.organizationId,
      });
    case ECustomerRoutes.CUSTOMER_ORDER_SUMMARY:
    case ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT:
      return generatePath(params.route, {
        organizationId: params.organizationId,
        orderId: params.orderId,
      });
    case ECustomerRoutes.CUSTOMER_ANALYTICS_ORDERS:
      return generatePath(params.route, {
        organizationId: params.organizationId,
        config: encodeCustomerAnalyticsOrdersRouteParamsConfig(params.config),
      });
    case ECustomerRoutes.CUSTOMER_USER_ACCOUNT:
      return generatePath(params.route, {
        organizationId: params.organizationId,
      });
    case ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS:
      return generatePath(params.route, {
        organizationId: params.organizationId,
      });
    default:
      throw new Error('Unexpected.');
  }
}

export function encodeCustomerNextOrderCartPath(organizationId: string, locationId: string): string {
  return encodeCustomerPath({
    route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART,
    organizationId: ensureNotEmptyString(organizationId),
    locationId: ensureNotEmptyString(locationId),
  });
}

export function encodeCustomerNextOrderHistoryPath(organizationId: string, locationId: string): string {
  return encodeCustomerPath({
    route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY,
    organizationId: ensureNotEmptyString(organizationId),
    locationId: ensureNotEmptyString(locationId),
  });
}

export function encodeCustomerEncodeNextOrderFormularyPath(
  organizationId: string,
  locationId: string,
  config: TCustomerNextOrderFormularyRouteParamsConfig,
): string {
  return encodeCustomerPath({
    route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY,
    organizationId: ensureNotEmptyString(organizationId),
    locationId: ensureNotEmptyString(locationId),
    config,
  });
}

export function encodeCustomerEncodeNextOrderCatalogsPath(
  organizationId: string,
  locationId: string,
  config: TCustomerNextOrderCatalogsRouteParamsConfig,
): string {
  return encodeCustomerPath({
    route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS,
    organizationId: ensureNotEmptyString(organizationId),
    locationId: ensureNotEmptyString(locationId),
    config,
  });
}

export function encodeCustomerOrdersPath(organizationId: string): string {
  return encodeCustomerPath({
    organizationId: ensureNotEmptyString(organizationId),
    route: ECustomerRoutes.CUSTOMER_ORDERS,
  });
}

export function encodeCustomerOrderSummaryPath(organizationId: string, orderId: string): string {
  return encodeCustomerPath({
    route: ECustomerRoutes.CUSTOMER_ORDER_SUMMARY,
    organizationId: ensureNotEmptyString(organizationId),
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeCustomerOrderUpdatesAndSupportPath(organizationId: string, orderId: string): string {
  return encodeCustomerPath({
    route: ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT,
    organizationId: ensureNotEmptyString(organizationId),
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeCustomerAnalyticsOrdersPath(
  organizationId: string,
  config: TCustomerAnalyticsOrdersRouteParamsConfig,
): string {
  return encodeCustomerPath({
    route: ECustomerRoutes.CUSTOMER_ANALYTICS_ORDERS,
    organizationId: ensureNotEmptyString(organizationId),
    config,
  });
}

export function encodeCustomerUserAccountPath(organizationId: string): string {
  return encodeCustomerPath({
    route: ECustomerRoutes.CUSTOMER_USER_ACCOUNT,
    organizationId: ensureNotEmptyString(organizationId),
  });
}

export function encodeCustomerUserNotificationsPath(organizationId: string): string {
  return encodeCustomerPath({
    route: ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS,
    organizationId: ensureNotEmptyString(organizationId),
  });
}

export function decodeCustomerPath(path: string): TCustomerRouteParams {
  try {
    for (const route of Object.values(ECustomerRoutes)) {
      const match = matchPath(route, path);

      if (!isDef(match)) {
        continue;
      }

      switch (route) {
        case ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART:
          return {
            route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART,
            organizationId: ensureNotEmptyString(match.params.organizationId),
            locationId: ensureNotEmptyString(match.params.locationId),
          };
        case ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY:
          return {
            route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY,
            organizationId: ensureNotEmptyString(match.params.organizationId),
            locationId: ensureNotEmptyString(match.params.locationId),
          };
        case ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY:
          return {
            route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY,
            organizationId: ensureNotEmptyString(match.params.organizationId),
            locationId: ensureNotEmptyString(match.params.locationId),
            config: decodeCustomerNextOrderFormularyRouteParamsConfig(ensureDef(match.params.config)),
          };
        case ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS:
          return {
            route: ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS,
            organizationId: ensureNotEmptyString(match.params.organizationId),
            locationId: ensureNotEmptyString(match.params.locationId),
            config: decodeCustomerNextOrderCatalogsRouteParamsConfig(ensureDef(match.params.config)),
          };
        case ECustomerRoutes.CUSTOMER_ORDERS:
          return {
            route: ECustomerRoutes.CUSTOMER_ORDERS,
            organizationId: ensureNotEmptyString(match.params.organizationId),
          };
        case ECustomerRoutes.CUSTOMER_ORDER_SUMMARY:
          return {
            route: ECustomerRoutes.CUSTOMER_ORDER_SUMMARY,
            organizationId: ensureNotEmptyString(match.params.organizationId),
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT:
          return {
            route: ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT,
            organizationId: ensureNotEmptyString(match.params.organizationId),
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case ECustomerRoutes.CUSTOMER_ANALYTICS_ORDERS:
          return {
            route: ECustomerRoutes.CUSTOMER_ANALYTICS_ORDERS,
            organizationId: ensureNotEmptyString(match.params.organizationId),
            config: decodeCustomerAnalyticsOrdersRouteParamsConfig(ensureDef(match.params.config)),
          };
        case ECustomerRoutes.CUSTOMER_USER_ACCOUNT:
          return {
            route: ECustomerRoutes.CUSTOMER_USER_ACCOUNT,
            organizationId: ensureNotEmptyString(match.params.organizationId),
          };
        case ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS:
          return {
            route: ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS,
            organizationId: ensureNotEmptyString(match.params.organizationId),
          };
      }
    }
  } catch (thrown: unknown) {
    // intentionally empty
  }

  throw new Error('Not Found');
}

export const { Context: CustomerRouteParamsContext, useContext: useCustomerRouteParams } =
  createRequiredContext<TCustomerRouteParams>();

export const { Context: CustomerRouteOrganizationContext, useContext: useCustomerRouteOrganization } =
  createRequiredContext<TAuthOrganization>();

export const { Context: CustomerRouteLocationContext, useContext: useCustomerRouteLocation } =
  createRequiredContext<TAuthLocation>();

export function useCustomerNextOrderRouteParams():
  | TCustomerNextOrderCartRouteParams
  | TCustomerNextOrderCatalogsRouteParams
  | TCustomerNextOrderFormularyRouteParams
  | TCustomerNextOrderHistoryRouteParams {
  const routeParams = useCustomerRouteParams();

  if (
    routeParams.route !== ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART &&
    routeParams.route !== ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY &&
    routeParams.route !== ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY &&
    routeParams.route !== ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS
  ) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerNextOrderCartRouteParams(): TCustomerNextOrderCartRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerNextOrderHistoryRouteParams(): TCustomerNextOrderHistoryRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerNextOrderFormularyRouteParams(): TCustomerNextOrderFormularyRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerNextOrderCatalogsRouteParams(): TCustomerNextOrderCatalogsRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerNextOrderCatalogsRouteParamsConfig(): TCustomerNextOrderCatalogsRouteParamsConfig {
  const routeParams = useCustomerNextOrderCatalogsRouteParams();
  return routeParams.config;
}

export function useCustomerNextOrderCatalogsOrFormularyRouteParamsConfig():
  | TCustomerNextOrderCatalogsRouteParamsConfig
  | TCustomerNextOrderFormularyRouteParamsConfig {
  const routeParams = useCustomerRouteParams();

  switch (routeParams.route) {
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY:
      return routeParams.config;
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS:
      return routeParams.config;
    default:
      throw new Error(`Mismatched route: "${routeParams.route}".`);
  }
}

export function useCustomerOrdersRouteParams(): TCustomerOrdersRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_ORDERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerOrderRouteParams():
  | TCustomerOrderSummaryRouteParams
  | TCustomerOrderUpdatesAndSupportRouteParams {
  const routeParams = useCustomerRouteParams();

  if (
    routeParams.route !== ECustomerRoutes.CUSTOMER_ORDER_SUMMARY &&
    routeParams.route !== ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT
  ) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerOrderSummaryRouteParams(): TCustomerOrderSummaryRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_ORDER_SUMMARY) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerOrderUpdatesAndSupportRouteParams(): TCustomerOrderUpdatesAndSupportRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerAnalyticsOrdersRouteParams(): TCustomerAnalyticsOrdersRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_ANALYTICS_ORDERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerUserRouteParams(): TCustomerUserAccountRouteParams | TCustomerUserNotificationsRouteParams {
  const routeParams = useCustomerRouteParams();

  if (
    routeParams.route !== ECustomerRoutes.CUSTOMER_USER_ACCOUNT &&
    routeParams.route !== ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS
  ) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerUserAccountRouteParams(): TCustomerUserAccountRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_USER_ACCOUNT) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useCustomerUserNotificationsRouteParams(): TCustomerUserNotificationsRouteParams {
  const routeParams = useCustomerRouteParams();

  if (routeParams.route !== ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function getCustomerRoutePageTitle(route: ECustomerRoutes, customer: TAuthCustomer): string {
  switch (route) {
    case ECustomerRoutes.CUSTOMER_ORDERS:
      return 'Orders';
    case ECustomerRoutes.CUSTOMER_ORDER_SUMMARY:
    case ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT:
      return 'Order';
    case ECustomerRoutes.CUSTOMER_USER_ACCOUNT:
    case ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS:
      return `${customer.first_name} ${customer.last_name}`;
    case ECustomerRoutes.CUSTOMER_ANALYTICS_ORDERS:
      return 'Analytics';
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART:
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY:
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY:
    case ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS:
      return 'Next Order';
  }
}

export function getCustomerRoutes(): RouteObject[] {
  return [
    {
      path: '/',
      element: <RedirectRoute />,
      errorElement: <RouterErrorElement />,
    },
    {
      element: <RootLayoutRoute />,
      errorElement: <RouterErrorElement />,
      children: [
        {
          element: <NextOrderLayoutRoute />,
          errorElement: <RouterErrorElement />,
          children: [
            {
              path: ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART,
              element: <NextOrderCartRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY,
              element: <NextOrderHistoryRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY,
              element: <NextOrderFormularyRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS,
              element: <NextOrderCatalogsRoute />,
              errorElement: <RouterErrorElement />,
            },
          ],
        },
        {
          path: ECustomerRoutes.CUSTOMER_ORDERS,
          element: <OrdersRoute />,
          errorElement: <RouterErrorElement />,
        },
        {
          element: <OrderLayoutRoute />,
          errorElement: <RouterErrorElement />,
          children: [
            {
              path: ECustomerRoutes.CUSTOMER_ORDER_SUMMARY,
              element: <OrderSummaryRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT,
              element: <OrderUpdatesAndSupportRoute />,
              errorElement: <RouterErrorElement />,
            },
          ],
        },
        {
          path: ECustomerRoutes.CUSTOMER_ANALYTICS_ORDERS,
          element: <AnalyticsOrdersRoute />,
          errorElement: <RouterErrorElement />,
        },
        {
          element: <UserLayoutRoute />,
          errorElement: <RouterErrorElement />,
          children: [
            {
              path: ECustomerRoutes.CUSTOMER_USER_ACCOUNT,
              element: <UserAccountRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS,
              element: <UserNotificationsRoute />,
              errorElement: <RouterErrorElement />,
            },
          ],
        },
      ],
    },
  ];
}

function RedirectRoute(): JSX.Element {
  const { pathname } = useLocation();
  const customer = useAuthCustomer();

  switch (pathname) {
    case '/':
      return <Navigate to={encodeCustomerOrdersPath(ensureDef(customer.organizations[0]).id)} replace={true} />;
    default:
      throw new Error('Not Found');
  }
}

function RootLayoutRoute(): JSX.Element {
  const { pathname } = useLocation();
  const routeParams = useMemo(() => decodeCustomerPath(pathname), [pathname]);
  const organization = useAuthCustomerOrganization(routeParams.organizationId);

  return (
    <CustomerRouteParamsContext.Provider value={routeParams}>
      <AmplitudeAnalyticsActionsProvider>
        <DateRangePickerDrawerProvider>
          <CustomerUploadsProvider>
            <CustomerRouteOrganizationContext.Provider value={organization}>
              <Structure.Root>
                <CustomerNavPart />
                <Structure.Main>
                  <Outlet />
                </Structure.Main>
              </Structure.Root>
            </CustomerRouteOrganizationContext.Provider>
          </CustomerUploadsProvider>
        </DateRangePickerDrawerProvider>
      </AmplitudeAnalyticsActionsProvider>
    </CustomerRouteParamsContext.Provider>
  );
}

function NextOrderLayoutRoute(): JSX.Element {
  const routeParams = useCustomerNextOrderRouteParams();
  const location = useAuthCustomerLocation(routeParams.organizationId, routeParams.locationId);

  return (
    <CustomerRouteLocationContext.Provider value={location}>
      <CustomerNextOrderProvider LoaderComponent={CustomerNextOrderNavPartLoader}>
        <CustomerNextOrderActionsProvider PlacingOrderComponent={NextOrderPlacingOrderAnnouncement}>
          <Structure.Content>
            <CustomerNextOrderNavPart />
          </Structure.Content>
          <Outlet />
        </CustomerNextOrderActionsProvider>
      </CustomerNextOrderProvider>
    </CustomerRouteLocationContext.Provider>
  );
}

function NextOrderCartRoute(): JSX.Element {
  return <CustomerNextOrderCartPart />;
}

function NextOrderHistoryRoute(): JSX.Element {
  const routeParams = useCustomerNextOrderHistoryRouteParams();
  const organization = useAuthCustomerOrganization(routeParams.organizationId);

  if (organization.isSandbox) {
    return <CompleteOnboardingAnnouncement organizationName={organization.name} css={{ marginTop: '64px' }} />;
  }

  return (
    <CustomerNextOrderPastOrdersCatalogsFilterProvider LoaderComponent={CustomerNextOrderHistoryPartLoader}>
      <CustomerNextOrderHistoryPart />
    </CustomerNextOrderPastOrdersCatalogsFilterProvider>
  );
}

function NextOrderFormularyRoute(): JSX.Element {
  const routeParams = useCustomerNextOrderFormularyRouteParams();
  const organization = useAuthCustomerOrganization(routeParams.organizationId);

  if (organization.isSandbox) {
    return <CompleteOnboardingAnnouncement organizationName={organization.name} css={{ marginTop: '64px' }} />;
  }

  return (
    <Fragment>
      <CustomerNextOrderSearchProvider LoaderComponent={CustomerNextOrderFormularyPartLoader} formularyMode={true}>
        <CustomerNextOrderFormularyPart />
      </CustomerNextOrderSearchProvider>
    </Fragment>
  );
}

function NextOrderCatalogsRoute(): JSX.Element {
  return (
    <Fragment>
      <CustomerNextOrderSearchProvider LoaderComponent={CustomerNextOrderCatalogsPartLoader} formularyMode={false}>
        <CustomerNextOrderCatalogsPart />
      </CustomerNextOrderSearchProvider>
    </Fragment>
  );
}

function OrdersRoute(): JSX.Element {
  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav title='Orders' />
      </Structure.Content>
      <CustomerOrdersProvider LoaderComponent={CustomerOrdersPartLoader}>
        <CustomerOrdersPart />
      </CustomerOrdersProvider>
    </Fragment>
  );
}

function OrderLayoutRoute(): JSX.Element {
  return (
    <CustomerOrderProvider LoaderComponent={CustomerOrderNavPartLoader}>
      <CustomerOrderBudgetAssistantProvider>
        <CustomerOrderActionsProvider>
          <CustomerOrderMessagesProvider>
            <CustomerOrderSnapshotReviewChromeProvider>
              <Structure.Content>
                <CustomerOrderNavPart />
              </Structure.Content>
              <Outlet />
            </CustomerOrderSnapshotReviewChromeProvider>
          </CustomerOrderMessagesProvider>
        </CustomerOrderActionsProvider>
      </CustomerOrderBudgetAssistantProvider>
    </CustomerOrderProvider>
  );
}

function OrderSummaryRoute(): JSX.Element {
  return <CustomerOrderSummaryPart />;
}

function OrderUpdatesAndSupportRoute(): JSX.Element {
  return <CustomerOrderUpdatesAndSupportPart />;
}

function AnalyticsOrdersRoute(): JSX.Element {
  return (
    <CustomerAnalyticsOrdersProvider LoaderComponent={CustomerAnalyticsOrdersPartLoader}>
      <CustomerAnalyticsOrdersActionsProvider>
        <CustomerAnalyticsOrdersPart />
      </CustomerAnalyticsOrdersActionsProvider>
    </CustomerAnalyticsOrdersProvider>
  );
}

function UserLayoutRoute(): JSX.Element {
  return (
    <Fragment>
      <Structure.Content>
        <CustomerUserNavPart />
      </Structure.Content>
      <Outlet />
    </Fragment>
  );
}

function UserAccountRoute(): JSX.Element {
  return <CustomerUserAccountPart />;
}

function UserNotificationsRoute(): JSX.Element {
  return (
    <CustomerNotificationsProvider LoaderComponent={CustomerUserPartLoader}>
      <CustomerNotificationsActionsProvider>
        <CustomerUserNotificationsPart />
      </CustomerNotificationsActionsProvider>
    </CustomerNotificationsProvider>
  );
}
