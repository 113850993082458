import { NetworkStatus } from '@apollo/client';
import type {
  CustomerPublicCatalogProductsHistoryListQuery,
  CustomerWpxLegacyProductsGetQuery,
  TCustomerPublicCatalogProductCompleteFragment,
} from '@src/gen/graphql/bindings';
import {
  useCustomerPublicCatalogProductsHistoryListQuery,
  useCustomerWpxLegacyProductsGetQuery,
} from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT } from '@src/modules/graphql/utils';
import { useCustomerRouteLocation } from '@src/modules/routing/customer';
import noop from 'lodash/noop';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TCustomerNextOrderPastOrdersCatalogsFilterContext = {
  publicCatalogProducts: TCustomerPublicCatalogProductCompleteFragment[];
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const {
  Context: CustomerNextOrderPastOrdersCatalogsFilterContext,
  useContext: useCustomerNextOrderPastOrdersCatalogsFilter,
} = createRequiredContext<TCustomerNextOrderPastOrdersCatalogsFilterContext>();

export type TCustomerNextOrderPastOrdersCatalogsFilterProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function CustomerNextOrderPastOrdersCatalogsFilterProvider({
  LoaderComponent,
  children,
}: TCustomerNextOrderPastOrdersCatalogsFilterProvider): JSX.Element {
  const location = useCustomerRouteLocation();

  const { data, error, networkStatus, fetchMore } = useCustomerPublicCatalogProductsHistoryListQuery({
    variables: {
      locationId: location.id,
      offset: 0,
      limit: DEFAULT_LIMIT,
    },
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          offset: data?.customer_public_catalog_products_history_list_v2.length ?? 0,
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          customer_public_catalog_products_history_list_v2: [
            ...prev.customer_public_catalog_products_history_list_v2,
            ...fetchMoreResult.customer_public_catalog_products_history_list_v2,
          ],
        }),
      });

      return newData.customer_public_catalog_products_history_list_v2.length;
    },
    // @sort
    [fetchMore, data?.customer_public_catalog_products_history_list_v2.length],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.customer_public_catalog_products_history_list_v2.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TCustomerNextOrderPastOrdersCatalogsFilterProviderInner | undefined>(
    () =>
      isDef(data)
        ? {
            LoaderComponent,
            pointers: data.customer_public_catalog_products_history_list_v2,
            loaderRef,
          }
        : undefined,
    // @sort
    [LoaderComponent, data, loaderRef],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading && networkStatus !== NetworkStatus.setVariables ? (
    <CustomerNextOrderPastOrdersCatalogsFilterProviderInner {...value}>
      {children}
    </CustomerNextOrderPastOrdersCatalogsFilterProviderInner>
  ) : (
    <LoaderComponent />
  );
}

export type TCustomerNextOrderPastOrdersCatalogsFilterProviderInner = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  loaderRef: UseInfiniteScrollHookRefCallback | null;
  pointers: NonNullable<
    CustomerPublicCatalogProductsHistoryListQuery['customer_public_catalog_products_history_list_v2']
  >;
}>;

export function CustomerNextOrderPastOrdersCatalogsFilterProviderInner({
  LoaderComponent,
  loaderRef,
  pointers,
  children,
}: TCustomerNextOrderPastOrdersCatalogsFilterProviderInner): JSX.Element {
  const productsCacheRef = useRef<{ [key: string]: TCustomerPublicCatalogProductCompleteFragment }>({});

  const missingPointers = useMemo(
    () => pointers.filter((p) => !isDef(productsCacheRef.current[p.product_id])),
    [pointers],
  );

  const { data, networkStatus, error } = useCustomerWpxLegacyProductsGetQuery({
    variables: {
      productIds: missingPointers.map((p) => p.product_id),
    },
    skip: missingPointers.length === 0,
  });

  if (isDef(error)) {
    throw error;
  }

  const value = useMemo<TCustomerNextOrderPastOrdersCatalogsFilterContext>(
    () => {
      if (isDef(data)) {
        for (const product of castQuery(data).products) {
          productsCacheRef.current[product.id] = product;
        }
      }

      return {
        publicCatalogProducts: pointers
          .map((p) => productsCacheRef.current[p.product_id])
          .filter((p): p is TCustomerPublicCatalogProductCompleteFragment => isDef(p)),
        loaderRef: isDef(loaderRef)
          ? networkStatus !== NetworkStatus.loading &&
            networkStatus !== NetworkStatus.setVariables &&
            networkStatus !== NetworkStatus.refetch &&
            networkStatus !== NetworkStatus.fetchMore
            ? loaderRef
            : noop
          : null,
      };
    },
    // @sort
    [data, loaderRef, networkStatus, pointers],
  );

  return value.publicCatalogProducts.length > 0 ? (
    <CustomerNextOrderPastOrdersCatalogsFilterContext.Provider value={value}>
      {children}
    </CustomerNextOrderPastOrdersCatalogsFilterContext.Provider>
  ) : (
    <LoaderComponent />
  );
}

type TWpxGetLegacyProducts = {
  products: TCustomerPublicCatalogProductCompleteFragment[];
  notFoundProductIds: string[];
};

function castQuery(data: CustomerWpxLegacyProductsGetQuery): TWpxGetLegacyProducts {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return data.wpxGetLegacyProducts as TWpxGetLegacyProducts;
}
