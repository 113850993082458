import type {
  TAgentOrderBackOrderCompleteFragment,
  TAgentOrderBaseFragment,
  TAgentOrderBaseGroupBaseFragment,
  TAgentOrderCompleteFragment,
  TAgentOrderEntryBaseFragment,
  TAgentOrderPaymentCompleteFragment,
  TAgentOrderPlanGroupBaseFragment,
  TAgentOrderReturnCompleteFragment,
  TAgentOrderShipmentCompleteFragment,
  TAgentOrderSnapshotBaseFragment,
  TAgentOrderSnapshotCompleteFragment,
  TAgentOrderSnapshotEntryBaseFragment,
  TAgentProductHintBaseFragment,
  TAgentPublicCatalogProductBaseFragment,
} from '@src/gen/graphql/bindings';
import { EOrderBackOrderDecision } from '@src/gen/shared/enums/orderBackOrderDecision';
import { EOrderEntryPlanStatus } from '@src/gen/shared/enums/orderEntryPlanStatus';
import { EOrderPaymentType } from '@src/gen/shared/enums/orderPaymentType';
import { EOrderSnapshotType } from '@src/gen/shared/enums/orderSnapshotType';
import { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import type { ESource } from '@src/gen/shared/enums/source';
import { getSource } from '@src/gen/shared/enums/source';
import { formatDollarsCurrency, formatPercent } from '@src/gen/shared/utils/converters';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { v4 as uuidV4 } from 'uuid';

export type TAgentOrderManagerOrder = Pick<
  TAgentOrderBaseFragment,
  'id' | 'is_emergency' | 'latest_order_snapshot_id' | 'location_id' | 'reference_number' | 'status' | 'version'
> &
  Pick<TAgentOrderCompleteFragment, 'location' | 'order_snapshots'> & {
    order_entries: TAgentOrderEntryBaseFragment[];
    order_base_groups: TAgentOrderBaseGroupBaseFragment[];
    order_plan_groups: TAgentOrderPlanGroupBaseFragment[];
  };

export class AgentOrderManager {
  public readonly orderEntryManagers: AgentOrderEntryManager[];
  public readonly orderBaseGroupManagers: AgentOrderBaseGroupManager[];
  public readonly orderPlanGroupManagers: AgentOrderPlanGroupManager[];
  public readonly orderSnapshotManagers: AgentOrderSnapshotManager[];

  public constructor(public readonly order: TAgentOrderManagerOrder) {
    this.orderEntryManagers = order.order_entries.map((oe) => new AgentOrderEntryManager(oe));
    this.orderBaseGroupManagers = order.order_base_groups.map(
      (obg) => new AgentOrderBaseGroupManager(obg, this.orderEntryManagers),
    );
    this.orderPlanGroupManagers = order.order_plan_groups.map(
      (obg) => new AgentOrderPlanGroupManager(obg, this.orderEntryManagers),
    );
    this.orderSnapshotManagers = order.order_snapshots.map((op) => new AgentOrderSnapshotManager(op));
  }

  public isReadyForSnapshot(): boolean {
    return this.areOrderEntriesReady() && this.areOrderBaseGroupsReady() && this.areOrderPlanGroupsReady();
  }

  public areOrderEntriesReady(): boolean {
    let hasNonRemoved = false;

    for (const oem of this.orderEntryManagers) {
      if (!oem.isReady()) {
        return false;
      }

      if (!oem.isRemoved()) {
        hasNonRemoved = true;
      }
    }

    return hasNonRemoved;
  }

  public areOrderBaseGroupsReady(): boolean {
    let hasNonRemoved = false;

    for (const obgm of this.orderBaseGroupManagers) {
      if (!obgm.isReady()) {
        return false;
      }

      if (!obgm.isRemoved()) {
        hasNonRemoved = true;
      }
    }

    return hasNonRemoved;
  }

  public areOrderPlanGroupsReady(): boolean {
    let hasNonRemoved = false;

    for (const opgm of this.orderPlanGroupManagers) {
      if (!opgm.isReady()) {
        return false;
      }

      if (!opgm.isRemoved()) {
        hasNonRemoved = true;
      }
    }

    return hasNonRemoved;
  }

  public hasSnapshotInvoice(): boolean {
    return isDef(this.orderSnapshotManagers.find((osm) => osm.orderSnapshot.type === EOrderSnapshotType.INVOICE));
  }

  public hasSnapshotPrebillApproved(): boolean {
    return isDef(
      this.orderSnapshotManagers.find(
        (osm) => osm.orderSnapshot.type === EOrderSnapshotType.PREBILL && isDef(osm.orderSnapshot.approved_at),
      ),
    );
  }

  public hasSnapshotPrebillRejected(): boolean {
    return isDef(
      this.orderSnapshotManagers.find(
        (osm) => osm.orderSnapshot.type === EOrderSnapshotType.PREBILL && isDef(osm.orderSnapshot.rejected_at),
      ),
    );
  }

  public hasSnapshotPrebillPending(): boolean {
    return isDef(
      this.orderSnapshotManagers.find(
        (osm) =>
          osm.orderSnapshot.type === EOrderSnapshotType.PREBILL &&
          !isDef(osm.orderSnapshot.approved_at) &&
          !isDef(osm.orderSnapshot.rejected_at),
      ),
    );
  }

  public isCompleted(): boolean {
    return this.order.status === EOrderStatus.COMPLETED;
  }

  public isCanceled(): boolean {
    return this.order.status === EOrderStatus.CANCELED;
  }

  public canIssueSnapshotPrebill(): boolean {
    return (
      !this.isCompleted() &&
      !this.isCanceled() &&
      this.isReadyForSnapshot() &&
      this.order.location.requires_prebill_approval &&
      !this.hasSnapshotPrebillPending() &&
      !this.hasSnapshotInvoice()
    );
  }

  public canIssueSnapshotInvoice(): boolean {
    return (
      !this.isCanceled() &&
      this.isReadyForSnapshot() &&
      !this.hasSnapshotPrebillPending() &&
      (!this.order.location.requires_prebill_approval ||
        (isDef(this.order.location.requires_prebill_approval_threshold) &&
          this.order.location.requires_prebill_approval_threshold > 0) ||
        this.hasSnapshotPrebillApproved() ||
        this.order.is_emergency)
    );
  }

  public canCancel(): boolean {
    return !this.isCompleted() && !this.isCanceled();
  }

  public mustGetOrderEntry(orderEntryId: string): AgentOrderEntryManager {
    return ensureDef(this.orderEntryManagers.find((oem) => oem.orderEntry.id === orderEntryId));
  }

  public mustGetOrderBaseGroup(orderBaseGroupId: string): AgentOrderBaseGroupManager {
    return ensureDef(this.orderBaseGroupManagers.find((obgm) => obgm.orderBaseGroup.id === orderBaseGroupId));
  }

  public mustGetOrderPlanGroup(orderPlanGroupId: string): AgentOrderPlanGroupManager {
    return ensureDef(this.orderPlanGroupManagers.find((opgm) => opgm.orderPlanGroup.id === orderPlanGroupId));
  }

  public mustGetOrderSnapshot(orderSnapshotId: string): AgentOrderSnapshotManager {
    return ensureDef(this.orderSnapshotManagers.find((os) => os.orderSnapshot.id === orderSnapshotId));
  }

  public mustGetOrderSnapshotPrebillPending(): AgentOrderSnapshotManager {
    return ensureDef(
      this.orderSnapshotManagers.find(
        (os) =>
          os.orderSnapshot.type === EOrderSnapshotType.PREBILL &&
          !isDef(os.orderSnapshot.approved_at) &&
          !isDef(os.orderSnapshot.rejected_at),
      ),
    );
  }

  public maybeGetOrderSnapshotInvoiceLatest(): AgentOrderSnapshotManager | null {
    if (!isDef(this.order.latest_order_snapshot_id)) {
      return null;
    }

    return (
      this.orderSnapshotManagers.find(
        (os) =>
          os.orderSnapshot.id === this.order.latest_order_snapshot_id &&
          os.orderSnapshot.type === EOrderSnapshotType.INVOICE,
      ) ?? null
    );
  }
}

export class AgentOrderEntryManager {
  public constructor(public readonly orderEntry: TAgentOrderEntryBaseFragment) {
    // intentionally empty
  }

  public isPlanned(): boolean {
    return this.orderEntry.plan_status !== EOrderEntryPlanStatus.NONE;
  }

  public isPurchasedByCustomer(): boolean {
    return (
      this.orderEntry.plan_status === EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE ||
      this.orderEntry.plan_status ===
        EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED ||
      this.orderEntry.plan_status === EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION
    );
  }

  public isPurchasedByWellplaece(): boolean {
    switch (this.orderEntry.plan_status) {
      case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
      case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
        return true;
      default:
        return false;
    }
  }

  public isRemoved(): boolean {
    switch (this.orderEntry.plan_status) {
      case EOrderEntryPlanStatus.REMOVED_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.REMOVED_REASON_OUT_OF_STOCK:
      case EOrderEntryPlanStatus.REMOVED_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_REQUESTED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
      case EOrderEntryPlanStatus.REMOVED_REASON_RETURNED:
        return true;
      default:
        return false;
    }
  }

  public isReady(): boolean {
    if (this.needsReview()) {
      return false;
    }

    switch (this.orderEntry.plan_status) {
      case EOrderEntryPlanStatus.NONE:
        return false;
      case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
      case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
        return (
          isDef(this.orderEntry.unit_price) &&
          isDef(this.orderEntry.plan_quantity) &&
          isDef(this.orderEntry.plan_unit_price)
        );
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
        return isDef(this.orderEntry.unit_price);
      default:
        return true;
    }
  }

  public needsReview(): boolean {
    return isDef(this.orderEntry.customer_edited_plan_quantity);
  }

  public getBaseProduct(): TAgentPublicCatalogProductBaseFragment {
    return {
      __typename: 'public_catalog_products',
      category_name: this.orderEntry.category_name,
      category_path: this.orderEntry.category_path,
      description: this.orderEntry.description,
      id: this.orderEntry.product_id,
      image_asset_path: this.orderEntry.image_asset_path,
      is_discontinued: this.orderEntry.is_discontinued,
      is_house_brand: this.orderEntry.is_house_brand,
      manufacturer_name: this.orderEntry.manufacturer_name,
      manufacturer_sku: this.orderEntry.manufacturer_sku,
      name: this.orderEntry.name,
      product_page_url: this.orderEntry.product_page_url,
      product_sku: this.orderEntry.product_sku,
      sale_unit: this.orderEntry.sale_unit,
      sds_asset_path: this.orderEntry.sds_asset_path,
      secondary_name: this.orderEntry.secondary_name,
      simplified_category: this.orderEntry.simplified_category,
      source: this.orderEntry.source,
      specs: this.orderEntry.specs,
    };
  }

  public mustGetPlanProduct(): TAgentPublicCatalogProductBaseFragment {
    return ensureDef(this.maybeGetPlanProduct());
  }

  public maybeGetPlanProduct(): TAgentPublicCatalogProductBaseFragment | null {
    if (isDef(this.orderEntry.plan_product_id)) {
      return {
        __typename: 'public_catalog_products',
        category_name: ensureDef(this.orderEntry.plan_category_name),
        category_path: ensureDef(this.orderEntry.plan_category_path),
        description: this.orderEntry.plan_description,
        id: this.orderEntry.plan_product_id,
        image_asset_path: this.orderEntry.plan_image_asset_path,
        is_discontinued: ensureDef(this.orderEntry.plan_is_discontinued),
        is_house_brand: ensureDef(this.orderEntry.plan_is_house_brand),
        manufacturer_name: this.orderEntry.plan_manufacturer_name,
        manufacturer_sku: this.orderEntry.plan_manufacturer_sku,
        name: ensureDef(this.orderEntry.plan_name),
        product_page_url: ensureDef(this.orderEntry.plan_product_page_url),
        product_sku: ensureDef(this.orderEntry.plan_product_sku),
        sale_unit: this.orderEntry.plan_sale_unit,
        sds_asset_path: this.orderEntry.plan_sds_asset_path,
        secondary_name: this.orderEntry.plan_secondary_name,
        simplified_category: ensureDef(this.orderEntry.plan_simplified_category),
        source: ensureDef(this.orderEntry.plan_source),
        specs: this.orderEntry.plan_specs,
      };
    }

    return null;
  }

  public mustGetBaseSubTotal(): number {
    return ensureDef(this.maybeGetBaseSubTotal());
  }

  public maybeGetBaseSubTotal(): number | null {
    return isDef(this.orderEntry.unit_price) ? this.orderEntry.quantity * this.orderEntry.unit_price : null;
  }

  public mustGetPlanSubTotal(): number {
    return ensureDef(this.maybeGetPlanSubTotal());
  }

  public maybeGetPlanSubTotal(): number | null {
    return isDef(this.orderEntry.plan_quantity) && isDef(this.orderEntry.plan_unit_price)
      ? this.orderEntry.plan_quantity * this.orderEntry.plan_unit_price
      : null;
  }

  public getSavingsPercent(): number | null {
    if (!this.isPurchasedByWellplaece()) {
      return null;
    }

    const baseSubTotal = this.maybeGetBaseSubTotal();
    const planSubTotal = this.maybeGetPlanSubTotal();

    if (!isDef(baseSubTotal) || !isDef(planSubTotal)) {
      return null;
    }

    if (planSubTotal >= baseSubTotal) {
      return 0;
    }

    const savings = baseSubTotal - planSubTotal;
    return Math.round((savings * 10000) / baseSubTotal);
  }

  public getSavingsText(): string {
    if (!this.isPurchasedByWellplaece()) {
      return '';
    }

    const baseSubTotal = this.maybeGetBaseSubTotal();
    const planSubTotal = this.maybeGetPlanSubTotal();

    if (!isDef(baseSubTotal) || !isDef(planSubTotal)) {
      return 'TBD';
    }

    if (planSubTotal >= baseSubTotal) {
      return 'None';
    }

    const savings = baseSubTotal - planSubTotal;
    const savingsPercent = Math.round((savings * 10000) / baseSubTotal);

    return `${formatDollarsCurrency(savings)} (${formatPercent(savingsPercent)})`;
  }

  public toOrderSnapshotEntry(orderSnapshotId: string): TAgentOrderSnapshotEntryBaseFragment {
    return {
      __typename: 'order_snapshot_entries',
      id: uuidV4(),
      order_snapshot_id: orderSnapshotId,
      product_id: this.orderEntry.product_id,
      origin: this.orderEntry.origin,
      plan_status: this.orderEntry.plan_status,
      customer_visible_notes: this.orderEntry.customer_visible_notes,
      source: this.orderEntry.source,
      product_page_url: this.orderEntry.product_page_url,
      product_sku: this.orderEntry.product_sku,
      manufacturer_name: this.orderEntry.manufacturer_name,
      manufacturer_sku: this.orderEntry.manufacturer_sku,
      name: this.orderEntry.name,
      secondary_name: this.orderEntry.secondary_name,
      sale_unit: this.orderEntry.sale_unit,
      is_discontinued: this.orderEntry.is_discontinued,
      is_house_brand: this.orderEntry.is_house_brand,
      category_path: this.orderEntry.category_path,
      category_name: this.orderEntry.category_name,
      simplified_category: this.orderEntry.simplified_category,
      description: this.orderEntry.description,
      specs: this.orderEntry.specs,
      image_asset_path: this.orderEntry.image_asset_path,
      sds_asset_path: this.orderEntry.sds_asset_path,
      is_substitution_allowed: this.orderEntry.is_substitution_allowed,
      quantity: this.orderEntry.quantity,
      unit_price: this.orderEntry.unit_price,
      plan_product_id: this.orderEntry.plan_product_id,
      plan_source: this.orderEntry.plan_source,
      plan_product_page_url: this.orderEntry.plan_product_page_url,
      plan_product_sku: this.orderEntry.plan_product_sku,
      plan_manufacturer_name: this.orderEntry.plan_manufacturer_name,
      plan_manufacturer_sku: this.orderEntry.plan_manufacturer_sku,
      plan_name: this.orderEntry.plan_name,
      plan_secondary_name: this.orderEntry.plan_secondary_name,
      plan_sale_unit: this.orderEntry.plan_sale_unit,
      plan_is_discontinued: this.orderEntry.plan_is_discontinued,
      plan_is_house_brand: this.orderEntry.plan_is_house_brand,
      plan_category_path: this.orderEntry.plan_category_path,
      plan_category_name: this.orderEntry.plan_category_name,
      plan_simplified_category: this.orderEntry.plan_simplified_category,
      plan_description: this.orderEntry.plan_description,
      plan_specs: this.orderEntry.plan_specs,
      plan_image_asset_path: this.orderEntry.plan_image_asset_path,
      plan_sds_asset_path: this.orderEntry.plan_sds_asset_path,
      plan_quantity: this.orderEntry.plan_quantity,
      plan_unit_price: this.orderEntry.plan_unit_price,
      customer_edited_plan_quantity: this.orderEntry.customer_edited_plan_quantity,
    };
  }
}

export class AgentOrderBaseGroupManager {
  public readonly orderEntryManagers: AgentOrderEntryManager[];

  public constructor(
    public readonly orderBaseGroup: TAgentOrderBaseGroupBaseFragment,
    allOrderEntryManagers: AgentOrderEntryManager[],
  ) {
    this.orderEntryManagers = allOrderEntryManagers.filter((oem) => oem.orderEntry.source === orderBaseGroup.source);
  }

  public isReady(): boolean {
    return this.isRemoved() || isDef(this.maybeGetBaseTotal());
  }

  public isRemoved(): boolean {
    for (const oem of this.orderEntryManagers) {
      if (!oem.isRemoved()) {
        return false;
      }
    }

    return true;
  }

  public mustGetBaseSubTotal(): number {
    return ensureDef(this.maybeGetBaseSubTotal());
  }

  public maybeGetBaseSubTotal(): number | null {
    let subTotal = 0;

    for (const oem of this.orderEntryManagers) {
      if (oem.isRemoved()) {
        continue;
      }

      if (!oem.isPlanned() || !isDef(oem.maybeGetBaseSubTotal())) {
        return null;
      }

      subTotal += oem.mustGetBaseSubTotal();
    }

    return subTotal;
  }

  public maybeGetBaseSubTotalProvisional(): number | null {
    let subTotal = 0;

    for (const oem of this.orderEntryManagers) {
      if (oem.isRemoved()) {
        continue;
      }

      if (!isDef(oem.maybeGetBaseSubTotal())) {
        return null;
      }

      subTotal += oem.mustGetBaseSubTotal();
    }

    return subTotal;
  }

  public mustGetBaseTotal(): number {
    return ensureDef(this.maybeGetBaseTotal());
  }

  public maybeGetBaseTotal(): number | null {
    return isDef(this.maybeGetBaseSubTotal()) && isDef(this.orderBaseGroup.shipping) && isDef(this.orderBaseGroup.tax)
      ? this.mustGetBaseSubTotal() + this.orderBaseGroup.shipping + this.orderBaseGroup.tax
      : null;
  }

  public maybeGetBaseTotalProvisional(): number | null {
    return isDef(this.maybeGetBaseSubTotalProvisional()) &&
      isDef(this.orderBaseGroup.shipping) &&
      isDef(this.orderBaseGroup.tax)
      ? ensureDef(this.maybeGetBaseSubTotalProvisional()) + this.orderBaseGroup.shipping + this.orderBaseGroup.tax
      : null;
  }
}

export class AgentOrderPlanGroupManager {
  public readonly orderEntryManagers: AgentOrderEntryManager[];

  public constructor(
    public readonly orderPlanGroup: TAgentOrderPlanGroupBaseFragment,
    allOrderEntryManagers: AgentOrderEntryManager[],
  ) {
    this.orderEntryManagers = allOrderEntryManagers.filter(
      (oem) => oem.maybeGetPlanProduct()?.source === orderPlanGroup.source,
    );
  }

  public isReady(): boolean {
    return this.isRemoved() || isDef(this.maybeGetPlanTotal());
  }

  public isRemoved(): boolean {
    for (const oem of this.orderEntryManagers) {
      if (!oem.isRemoved()) {
        return false;
      }
    }

    return true;
  }

  public mustGetPlanSubTotal(): number {
    return ensureDef(this.maybeGetPlanSubTotal());
  }

  public maybeGetPlanSubTotal(): number | null {
    let subTotal = 0;

    for (const oem of this.orderEntryManagers) {
      if (oem.isRemoved()) {
        continue;
      }

      if (!isDef(oem.maybeGetPlanSubTotal())) {
        return null;
      }

      subTotal += oem.mustGetPlanSubTotal();
    }

    return subTotal;
  }

  public mustGetPlanTotal(): number {
    return ensureDef(this.maybeGetPlanTotal());
  }

  public maybeGetPlanTotal(): number | null {
    return isDef(this.maybeGetPlanSubTotal()) && isDef(this.orderPlanGroup.shipping) && isDef(this.orderPlanGroup.tax)
      ? this.mustGetPlanSubTotal() + this.orderPlanGroup.shipping + this.orderPlanGroup.tax
      : null;
  }
}

export class AgentOrderSnapshotManager {
  public readonly orderSnapshotEntryManagers: AgentOrderSnapshotEntryManager[];

  public constructor(public readonly orderSnapshot: TAgentOrderSnapshotCompleteFragment) {
    this.orderSnapshotEntryManagers = orderSnapshot.order_snapshot_entries.map(
      (ope) => new AgentOrderSnapshotEntryManager(this, ope),
    );
  }

  public maybeGetEstimateAfterCustomerEdits(): Pick<
    TAgentOrderSnapshotBaseFragment,
    'wellplaece_sub_total' | 'wellplaece_fees' | 'wellplaece_tax' | 'wellplaece_total' | 'wellplaece_donation'
  > | null {
    if (!this.isEditedByCustomer()) {
      return null;
    }

    const planSubTotal = this.getPlanSubTotalAfterCustomerEdits();

    if (planSubTotal === 0) {
      return {
        wellplaece_sub_total: 0,
        wellplaece_fees: 0,
        wellplaece_tax: 0,
        wellplaece_donation: 0,
        wellplaece_total: 0,
      };
    }

    const proportion = planSubTotal / this.orderSnapshot.wellplaece_sub_total;

    const estimate = {
      wellplaece_sub_total: planSubTotal,
      wellplaece_fees: Math.floor(this.orderSnapshot.wellplaece_fees * proportion),
      wellplaece_tax: Math.floor(this.orderSnapshot.wellplaece_tax * proportion),
      wellplaece_donation: Math.floor(this.orderSnapshot.wellplaece_donation * proportion),
      wellplaece_total: 0,
    };

    estimate.wellplaece_total =
      estimate.wellplaece_sub_total + estimate.wellplaece_fees + estimate.wellplaece_tax + estimate.wellplaece_donation;

    return estimate;
  }

  public getPlanSubTotalAfterCustomerEdits(): number {
    let planSubTotal = 0;

    for (const osem of this.orderSnapshotEntryManagers) {
      if (osem.isPurchasedByWellplaece()) {
        planSubTotal += osem.mustGetPlanSubTotal();
      }
    }

    return planSubTotal;
  }

  public isEditedByCustomer(): boolean {
    return isDef(this.orderSnapshotEntryManagers.find((osem) => osem.isEditedByCustomer()));
  }
}

export class AgentOrderSnapshotEntryManager {
  public constructor(
    public readonly orderSnapshotManager: AgentOrderSnapshotManager,
    public readonly orderSnapshotEntry: TAgentOrderSnapshotEntryBaseFragment,
  ) {
    // intentionally empty
  }

  public isPlanned(): boolean {
    return this.orderSnapshotEntry.plan_status !== EOrderEntryPlanStatus.NONE;
  }

  public isEditedByCustomer(): boolean {
    return isDef(this.orderSnapshotEntry.customer_edited_plan_quantity);
  }

  public isPurchasedByCustomer(): boolean {
    return (
      this.orderSnapshotEntry.plan_status === EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE ||
      this.orderSnapshotEntry.plan_status ===
        EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED ||
      this.orderSnapshotEntry.plan_status ===
        EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION
    );
  }

  public isPurchasedByWellplaece(): boolean {
    switch (this.orderSnapshotEntry.plan_status) {
      case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
      case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
        return true;
      default:
        return false;
    }
  }

  public isRemoved(): boolean {
    switch (this.orderSnapshotEntry.plan_status) {
      case EOrderEntryPlanStatus.REMOVED_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.REMOVED_REASON_OUT_OF_STOCK:
      case EOrderEntryPlanStatus.REMOVED_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_REQUESTED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
      case EOrderEntryPlanStatus.REMOVED_REASON_RETURNED:
        return true;
      default:
        return false;
    }
  }

  public isReady(): boolean {
    switch (this.orderSnapshotEntry.plan_status) {
      case EOrderEntryPlanStatus.NONE:
        return false;
      case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
      case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
        return (
          isDef(this.orderSnapshotEntry.unit_price) &&
          isDef(this.orderSnapshotEntry.plan_quantity) &&
          isDef(this.orderSnapshotEntry.plan_unit_price)
        );
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
        return isDef(this.orderSnapshotEntry.unit_price);
      default:
        return true;
    }
  }

  public getBaseProduct(): TAgentPublicCatalogProductBaseFragment {
    return {
      __typename: 'public_catalog_products',
      category_name: this.orderSnapshotEntry.category_name,
      category_path: this.orderSnapshotEntry.category_path,
      description: this.orderSnapshotEntry.description,
      id: this.orderSnapshotEntry.product_id,
      image_asset_path: this.orderSnapshotEntry.image_asset_path,
      is_discontinued: this.orderSnapshotEntry.is_discontinued,
      is_house_brand: this.orderSnapshotEntry.is_house_brand,
      manufacturer_name: this.orderSnapshotEntry.manufacturer_name,
      manufacturer_sku: this.orderSnapshotEntry.manufacturer_sku,
      name: this.orderSnapshotEntry.name,
      product_page_url: this.orderSnapshotEntry.product_page_url,
      product_sku: this.orderSnapshotEntry.product_sku,
      sale_unit: this.orderSnapshotEntry.sale_unit,
      sds_asset_path: this.orderSnapshotEntry.sds_asset_path,
      secondary_name: this.orderSnapshotEntry.secondary_name,
      simplified_category: this.orderSnapshotEntry.simplified_category,
      source: this.orderSnapshotEntry.source,
      specs: this.orderSnapshotEntry.specs,
    };
  }

  public mustGetPlanProduct(): TAgentPublicCatalogProductBaseFragment {
    return ensureDef(this.maybeGetPlanProduct());
  }

  public maybeGetPlanProduct(): TAgentPublicCatalogProductBaseFragment | null {
    if (isDef(this.orderSnapshotEntry.plan_product_id)) {
      return {
        __typename: 'public_catalog_products',
        category_name: ensureDef(this.orderSnapshotEntry.plan_category_name),
        category_path: ensureDef(this.orderSnapshotEntry.plan_category_path),
        description: this.orderSnapshotEntry.plan_description,
        id: this.orderSnapshotEntry.plan_product_id,
        image_asset_path: this.orderSnapshotEntry.plan_image_asset_path,
        is_discontinued: ensureDef(this.orderSnapshotEntry.plan_is_discontinued),
        is_house_brand: ensureDef(this.orderSnapshotEntry.plan_is_house_brand),
        manufacturer_name: this.orderSnapshotEntry.plan_manufacturer_name,
        manufacturer_sku: this.orderSnapshotEntry.plan_manufacturer_sku,
        name: ensureDef(this.orderSnapshotEntry.plan_name),
        product_page_url: ensureDef(this.orderSnapshotEntry.plan_product_page_url),
        product_sku: ensureDef(this.orderSnapshotEntry.plan_product_sku),
        sale_unit: this.orderSnapshotEntry.plan_sale_unit,
        sds_asset_path: this.orderSnapshotEntry.plan_sds_asset_path,
        secondary_name: this.orderSnapshotEntry.plan_secondary_name,
        simplified_category: ensureDef(this.orderSnapshotEntry.plan_simplified_category),
        source: ensureDef(this.orderSnapshotEntry.plan_source),
        specs: this.orderSnapshotEntry.plan_specs,
      };
    }

    return null;
  }

  public mustGetBaseSubTotal(): number {
    return ensureDef(this.maybeGetBaseSubTotal());
  }

  public maybeGetBaseSubTotal(): number | null {
    return isDef(this.orderSnapshotEntry.unit_price)
      ? this.orderSnapshotEntry.quantity * this.orderSnapshotEntry.unit_price
      : null;
  }

  public mustGetPlanSubTotal(): number {
    return ensureDef(this.maybeGetPlanSubTotal());
  }

  public maybeGetPlanSubTotal(): number | null {
    return (isDef(this.orderSnapshotEntry.customer_edited_plan_quantity) ||
      isDef(this.orderSnapshotEntry.plan_quantity)) &&
      isDef(this.orderSnapshotEntry.plan_unit_price)
      ? (this.orderSnapshotEntry.customer_edited_plan_quantity ?? ensureDef(this.orderSnapshotEntry.plan_quantity)) *
          this.orderSnapshotEntry.plan_unit_price
      : null;
  }
}

export class AgentProductHintManager {
  public constructor(public readonly productHint: TAgentProductHintBaseFragment) {
    // intentionally empty
  }

  public isPlanned(): boolean {
    return this.productHint.plan_status !== EOrderEntryPlanStatus.NONE;
  }

  public isPurchasedByCustomer(): boolean {
    return (
      this.productHint.plan_status === EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE ||
      this.productHint.plan_status ===
        EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION ||
      this.productHint.plan_status ===
        EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED
    );
  }

  public isPurchasedByWellplaece(): boolean {
    switch (this.productHint.plan_status) {
      case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
      case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
        return true;
      default:
        return false;
    }
  }

  public isRemoved(): boolean {
    switch (this.productHint.plan_status) {
      case EOrderEntryPlanStatus.REMOVED_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.REMOVED_REASON_OUT_OF_STOCK:
      case EOrderEntryPlanStatus.REMOVED_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_REQUESTED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
      case EOrderEntryPlanStatus.REMOVED_REASON_RETURNED:
        return true;
      default:
        return false;
    }
  }

  public isReady(): boolean {
    switch (this.productHint.plan_status) {
      case EOrderEntryPlanStatus.NONE:
        return false;
      case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
      case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
        return (
          isDef(this.productHint.unit_price) &&
          isDef(this.productHint.plan_quantity) &&
          isDef(this.productHint.plan_unit_price)
        );
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
        return isDef(this.productHint.unit_price);
      default:
        return true;
    }
  }

  public getBaseProduct(): TAgentPublicCatalogProductBaseFragment {
    return {
      __typename: 'public_catalog_products',
      category_name: this.productHint.category_name,
      category_path: this.productHint.category_path,
      description: this.productHint.description,
      id: this.productHint.product_id,
      image_asset_path: this.productHint.image_asset_path,
      is_discontinued: this.productHint.is_discontinued,
      is_house_brand: this.productHint.is_house_brand,
      manufacturer_name: this.productHint.manufacturer_name,
      manufacturer_sku: this.productHint.manufacturer_sku,
      name: this.productHint.name,
      product_page_url: this.productHint.product_page_url,
      product_sku: this.productHint.product_sku,
      sale_unit: this.productHint.sale_unit,
      sds_asset_path: this.productHint.sds_asset_path,
      secondary_name: this.productHint.secondary_name,
      simplified_category: this.productHint.simplified_category,
      source: this.productHint.source,
      specs: this.productHint.specs,
    };
  }

  public mustGetPlanProduct(): TAgentPublicCatalogProductBaseFragment {
    return ensureDef(this.maybeGetPlanProduct());
  }

  public maybeGetPlanProduct(): TAgentPublicCatalogProductBaseFragment | null {
    if (isDef(this.productHint.plan_product_id)) {
      return {
        __typename: 'public_catalog_products',
        category_name: ensureDef(this.productHint.plan_category_name),
        category_path: ensureDef(this.productHint.plan_category_path),
        description: this.productHint.plan_description,
        id: this.productHint.plan_product_id,
        image_asset_path: this.productHint.plan_image_asset_path,
        is_discontinued: ensureDef(this.productHint.plan_is_discontinued),
        is_house_brand: ensureDef(this.productHint.plan_is_house_brand),
        manufacturer_name: this.productHint.plan_manufacturer_name,
        manufacturer_sku: this.productHint.plan_manufacturer_sku,
        name: ensureDef(this.productHint.plan_name),
        product_page_url: ensureDef(this.productHint.plan_product_page_url),
        product_sku: ensureDef(this.productHint.plan_product_sku),
        sale_unit: this.productHint.plan_sale_unit,
        sds_asset_path: this.productHint.plan_sds_asset_path,
        secondary_name: this.productHint.plan_secondary_name,
        simplified_category: ensureDef(this.productHint.plan_simplified_category),
        source: ensureDef(this.productHint.plan_source),
        specs: this.productHint.plan_specs,
      };
    }

    return null;
  }
}

export type TAgentOrderShipmentsAnalyzerShipmentStatus = {
  total: number;
  untracked: number;
  tracked: number;
  delivered: number;
};

export type TAgentOrderShipmentsAnalyzerOrderSnapshotEntry = {
  orderSnapshotEntryManager: AgentOrderSnapshotEntryManager;
  shipmentStatus: TAgentOrderShipmentsAnalyzerShipmentStatus;
};

export type TAgentOrderShipmentsAnalyzerSource = {
  source: ESource;
  orderPlanGroup: TAgentOrderPlanGroupBaseFragment | null;
  orderSnapshotEntries: TAgentOrderShipmentsAnalyzerOrderSnapshotEntry[];
  shipmentStatus: TAgentOrderShipmentsAnalyzerShipmentStatus;
};

export class AgentOrderShipmentsAnalyzer {
  public readonly orderSnapshotEntries: TAgentOrderShipmentsAnalyzerOrderSnapshotEntry[] = [];
  public readonly orderSnapshotEntriesBySource: { [key in ESource]?: TAgentOrderShipmentsAnalyzerSource } = {};
  public readonly shipmentStatus: TAgentOrderShipmentsAnalyzerShipmentStatus;

  public constructor(
    orderSnapshotManager: AgentOrderSnapshotManager,
    orderShipments: TAgentOrderShipmentCompleteFragment[],
    orderPlanGroups: TAgentOrderPlanGroupBaseFragment[],
  ) {
    for (const orderSnapshotEntryManager of orderSnapshotManager.orderSnapshotEntryManagers) {
      if (orderSnapshotEntryManager.isPurchasedByWellplaece()) {
        this.orderSnapshotEntries.push({
          orderSnapshotEntryManager,
          shipmentStatus: {
            total: ensureDef(orderSnapshotEntryManager.orderSnapshotEntry.plan_quantity),
            untracked: 0,
            tracked: 0,
            delivered: 0,
          },
        });
      }
    }

    for (const orderShipment of orderShipments) {
      for (const orderShipmentProduct of orderShipment.order_shipment_products) {
        const orderSnapshotEntry = this.orderSnapshotEntries.find(
          (ose) =>
            ose.orderSnapshotEntryManager.orderSnapshotEntry.plan_source === orderShipmentProduct.source &&
            ose.orderSnapshotEntryManager.orderSnapshotEntry.plan_product_sku === orderShipmentProduct.product_sku,
        );

        if (isDef(orderSnapshotEntry)) {
          if (orderShipment.is_delivered) {
            orderSnapshotEntry.shipmentStatus.delivered += orderShipmentProduct.quantity;
          } else {
            orderSnapshotEntry.shipmentStatus.tracked += orderShipmentProduct.quantity;
          }
        }
      }
    }

    for (const orderSnapshotEntry of this.orderSnapshotEntries) {
      orderSnapshotEntry.shipmentStatus.untracked =
        orderSnapshotEntry.shipmentStatus.total -
        orderSnapshotEntry.shipmentStatus.tracked -
        orderSnapshotEntry.shipmentStatus.delivered;

      if (orderSnapshotEntry.shipmentStatus.untracked < 0) {
        orderSnapshotEntry.shipmentStatus.untracked = 0;
      }
    }

    for (const orderSnapshotEntry of this.orderSnapshotEntries) {
      const source = getSource(ensureDef(orderSnapshotEntry.orderSnapshotEntryManager.orderSnapshotEntry.plan_source));

      const bySource = this.orderSnapshotEntriesBySource[source] ?? {
        source: source,
        orderSnapshotEntries: [],
        orderPlanGroup: orderPlanGroups.find((opg) => opg.source === source) ?? null,
        shipmentStatus: {
          total: 0,
          untracked: 0,
          tracked: 0,
          delivered: 0,
        },
      };

      bySource.orderSnapshotEntries.push(orderSnapshotEntry);
      bySource.shipmentStatus.total += orderSnapshotEntry.shipmentStatus.total;
      bySource.shipmentStatus.untracked += orderSnapshotEntry.shipmentStatus.untracked;
      bySource.shipmentStatus.tracked += orderSnapshotEntry.shipmentStatus.tracked;
      bySource.shipmentStatus.delivered += orderSnapshotEntry.shipmentStatus.delivered;

      this.orderSnapshotEntriesBySource[source] = bySource;
    }

    this.shipmentStatus = {
      total: 0,
      untracked: 0,
      tracked: 0,
      delivered: 0,
    };

    for (const orderSnapshotEntry of this.orderSnapshotEntries) {
      this.shipmentStatus.total += orderSnapshotEntry.shipmentStatus.total;
      this.shipmentStatus.untracked += orderSnapshotEntry.shipmentStatus.untracked;
      this.shipmentStatus.tracked += orderSnapshotEntry.shipmentStatus.tracked;
      this.shipmentStatus.delivered += orderSnapshotEntry.shipmentStatus.delivered;
    }
  }

  public isFullyShipped(): boolean {
    for (const orderSnapshotEntry of this.orderSnapshotEntries) {
      if (orderSnapshotEntry.shipmentStatus.untracked > 0) {
        return false;
      }
    }

    return true;
  }

  public isFullyDelivered(): boolean {
    for (const orderSnapshotEntry of this.orderSnapshotEntries) {
      if (orderSnapshotEntry.shipmentStatus.untracked > 0 || orderSnapshotEntry.shipmentStatus.tracked > 0) {
        return false;
      }
    }

    return true;
  }
}

export class AgentOrderPaymentsAnalyzer {
  public readonly latestInvoiceTotal: number;
  public readonly paymentsTotal: number;
  public readonly status: 'done' | 'mark-paid' | 'mismatch' | 'process-payment';

  public constructor(
    orderSnapshotManager: AgentOrderSnapshotManager,
    orderPayments: TAgentOrderPaymentCompleteFragment[],
    isPaid: boolean,
  ) {
    this.latestInvoiceTotal = orderSnapshotManager.orderSnapshot.wellplaece_total;

    this.paymentsTotal = orderPayments.reduce((out, op) => {
      if (op.type === EOrderPaymentType.CHARGE) {
        return out + op.amount;
      } else {
        return out - op.amount;
      }
    }, 0);

    if (this.paymentsTotal === 0) {
      this.status = 'process-payment';
    } else if (this.paymentsTotal !== this.latestInvoiceTotal) {
      this.status = 'mismatch';
    } else if (!isPaid) {
      this.status = 'mark-paid';
    } else {
      this.status = 'done';
    }
  }

  public getStatusText(): string {
    switch (this.status) {
      case 'process-payment':
        return 'TBD - Not Processed';
      case 'mismatch':
        return 'TBD - Mismatch';
      case 'mark-paid':
        return 'TBD - Current, Not Marked';
      case 'done':
        return 'Done';
    }
  }

  public getStatusAccent(): 'default' | 'error' | 'warning' {
    switch (this.status) {
      case 'process-payment':
        return 'warning';
      case 'mismatch':
        return 'error';
      case 'mark-paid':
        return 'warning';
      case 'done':
        return 'default';
    }
  }
}

export class AgentOrderBackOrdersAnalyzer {
  public readonly waitingForCustomerDecision: number;
  public readonly waitingForRemoval: number;
  public readonly waitingForShipment: number;
  public readonly status: 'agent' | 'customer-agent' | 'customer' | 'nothing';

  public constructor(orderBackOrders: TAgentOrderBackOrderCompleteFragment[]) {
    this.waitingForCustomerDecision = orderBackOrders.filter(
      (obo) => !isDef(obo.replied_at) && !isDef(obo.applied_at),
    ).length;

    this.waitingForRemoval = orderBackOrders.filter(
      (obo) =>
        isDef(obo.replied_at) && obo.replied_decision === EOrderBackOrderDecision.REMOVE && !isDef(obo.applied_at),
    ).length;

    this.waitingForShipment = orderBackOrders.filter(
      (obo) => isDef(obo.replied_at) && obo.replied_decision === EOrderBackOrderDecision.WAIT && !isDef(obo.applied_at),
    ).length;

    if (this.waitingForCustomerDecision > 0 && (this.waitingForRemoval > 0 || this.waitingForShipment > 0)) {
      this.status = 'customer-agent';
    } else if (this.waitingForCustomerDecision > 0) {
      this.status = 'customer';
    } else if (this.waitingForRemoval > 0 || this.waitingForShipment > 0) {
      this.status = 'agent';
    } else {
      this.status = 'nothing';
    }
  }

  public getStatusText(): string {
    switch (this.status) {
      case 'customer-agent':
        return 'Waiting on Customer + Wellplaece';
      case 'customer':
        return 'Waiting on Customer';
      case 'agent':
        return 'Waiting on Wellplaece';
      case 'nothing':
        return 'Nothing to Do';
    }
  }

  public getStatusAccent(): 'default' | 'error' | 'warning' {
    switch (this.status) {
      case 'customer-agent':
        return 'warning';
      case 'customer':
        return 'warning';
      case 'agent':
        return 'warning';
      case 'nothing':
        return 'default';
    }
  }
}

export class AgentOrderReturnsAnalyzer {
  public readonly waitingForInstructions: number;
  public readonly waitingForShipment: number;
  public readonly waitingForAcknowledgment: number;
  public readonly status: 'agent' | 'customer-agent' | 'customer' | 'nothing';

  public constructor(orderReturns: TAgentOrderReturnCompleteFragment[]) {
    this.waitingForInstructions = orderReturns.filter(
      (or) => !isDef(or.replied_at) && !isDef(or.executed_at) && !isDef(or.applied_at),
    ).length;

    this.waitingForShipment = orderReturns.filter(
      (or) => isDef(or.replied_at) && !isDef(or.executed_at) && !isDef(or.applied_at),
    ).length;

    this.waitingForAcknowledgment = orderReturns.filter(
      (or) => isDef(or.replied_at) && isDef(or.executed_at) && !isDef(or.applied_at),
    ).length;

    if (this.waitingForShipment > 0 && (this.waitingForInstructions > 0 || this.waitingForAcknowledgment > 0)) {
      this.status = 'customer-agent';
    } else if (this.waitingForShipment > 0) {
      this.status = 'customer';
    } else if (this.waitingForInstructions > 0 || this.waitingForAcknowledgment > 0) {
      this.status = 'agent';
    } else {
      this.status = 'nothing';
    }
  }

  public getStatusText(): string {
    switch (this.status) {
      case 'customer-agent':
        return 'Waiting on Customer + Wellplaece';
      case 'customer':
        return 'Waiting on Customer';
      case 'agent':
        return 'Waiting on Wellplaece';
      case 'nothing':
        return 'Nothing to Do';
    }
  }

  public getStatusAccent(): 'default' | 'error' | 'warning' {
    switch (this.status) {
      case 'customer-agent':
        return 'warning';
      case 'customer':
        return 'warning';
      case 'agent':
        return 'warning';
      case 'nothing':
        return 'default';
    }
  }
}
