import { Typography } from '@src/components/appearance/basics/Typography';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import type { TAgentOrderSnapshotBaseFragment } from '@src/gen/graphql/bindings';
import { formatDollarsCurrency, formatPercent } from '@src/gen/shared/utils/converters';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderBackfilledInvoiceStructureGroupBase = {
  backfilledInvoice: TAgentOrderSnapshotBaseFragment;
  beginOrderBackfilledInvoiceRemove: TAgentOrderActionsTypes['BeginOrderBackfilledInvoiceRemove'];
};

export type TOrderBackfilledInvoiceStructureGroup = TProps<false, TOrderBackfilledInvoiceStructureGroupBase, 'div'>;
export const ORDER_BACKFILLED_INVOICE_STRUCTURE_GROUP = 'wp-order-backfilled-invoice-structure-group';

export const OrderBackfilledInvoiceStructureGroup = withCssToString(
  ORDER_BACKFILLED_INVOICE_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderBackfilledInvoiceStructureGroup>(
      ({ backfilledInvoice, beginOrderBackfilledInvoiceRemove, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_BACKFILLED_INVOICE_STRUCTURE_GROUP),
          [className],
        );

        const itemsBase = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Sub Total (Base)',
              text: formatDollarsCurrency(backfilledInvoice.base_sub_total),
            },
            {
              caption: 'Shipping (Base)',
              text: formatDollarsCurrency(backfilledInvoice.base_shipping),
            },
            {
              caption: 'Tax (Base)',
              text: formatDollarsCurrency(backfilledInvoice.base_tax),
            },
            {
              caption: 'Total (Base)',
              text: formatDollarsCurrency(backfilledInvoice.base_total),
            },
          ],
          // @sort
          [
            backfilledInvoice.base_shipping,
            backfilledInvoice.base_sub_total,
            backfilledInvoice.base_tax,
            backfilledInvoice.base_total,
          ],
        );

        const itemsWellplaece = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Sub Total (Wellplaece)',
              text: formatDollarsCurrency(backfilledInvoice.wellplaece_sub_total),
            },
            {
              caption: 'Shipping and Handling (Wellplaece)',
              text: formatDollarsCurrency(backfilledInvoice.wellplaece_fees),
            },
            {
              caption: 'Tax (Wellplaece)',
              text: formatDollarsCurrency(backfilledInvoice.wellplaece_tax),
            },
            {
              caption: 'Total (Wellplaece)',
              text: formatDollarsCurrency(backfilledInvoice.wellplaece_total),
            },
          ],
          // @sort
          [
            backfilledInvoice.wellplaece_fees,
            backfilledInvoice.wellplaece_sub_total,
            backfilledInvoice.wellplaece_tax,
            backfilledInvoice.wellplaece_total,
          ],
        );

        const { savings, savingsPercent } = calculateSavings({
          baseTotal: backfilledInvoice.base_total,
          wellplaeceTotal: backfilledInvoice.wellplaece_total,
        });

        const itemsAgent = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'CC Fee',
              text: formatDollarsCurrency(backfilledInvoice.agent_wellplaece_cc_fee),
            },
            {
              caption: 'Shipping Fees',
              text: formatDollarsCurrency(backfilledInvoice.agent_wellplaece_shipping),
            },
            {
              caption: 'Commission',
              text: `${formatDollarsCurrency(backfilledInvoice.agent_wellplaece_commission)} (${formatPercent(
                backfilledInvoice.agent_wellplaece_commission_percent,
              )})`,
            },
            {
              caption: 'Savings',
              text: `${formatDollarsCurrency(savings)} (${formatPercent(savingsPercent)})`,
            },
          ],
          [
            backfilledInvoice.agent_wellplaece_cc_fee,
            backfilledInvoice.agent_wellplaece_commission,
            backfilledInvoice.agent_wellplaece_commission_percent,
            backfilledInvoice.agent_wellplaece_shipping,
            savings,
            savingsPercent,
          ],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () => [
            {
              isAsync: false,
              icon: 'trash',
              onClick: beginOrderBackfilledInvoiceRemove,
            },
          ],
          [beginOrderBackfilledInvoiceRemove],
        );

        return (
          <Structure.Group
            {...rest}
            className={joinedClassName}
            iconButtonActions={iconButtonActions}
            title='Back-Filled Invoice'
            ref={ref}>
            <Card.Container flush={true} interactive={false}>
              <Card.ContentArea>
                <Typography.Annotation text={'Before Wellplaece...'.toUpperCase()} />
                <Items items={itemsBase} />
              </Card.ContentArea>
              <Card.Footer>
                <Typography.Annotation text={'...With Wellplaece'.toUpperCase()} />
                <Items items={itemsWellplaece} />
              </Card.Footer>
            </Card.Container>
            <ItemsBanner items={itemsAgent} />
          </Structure.Group>
        );
      },
    ),
  ),
);

function calculateSavings({ baseTotal, wellplaeceTotal }: { baseTotal: number; wellplaeceTotal: number }): {
  savings: number;
  savingsPercent: number;
} {
  // No savings.
  if (baseTotal === wellplaeceTotal) {
    return {
      savings: 0,
      savingsPercent: 0,
    };
  }

  if (baseTotal > wellplaeceTotal) {
    const savings = baseTotal - wellplaeceTotal;

    return {
      savings,
      savingsPercent: Math.round((savings * 10000) / baseTotal),
    };
  }

  const savings = wellplaeceTotal - baseTotal;

  return {
    savings: -savings,
    savingsPercent: -Math.round((savings * 10000) / wellplaeceTotal),
  };
}
