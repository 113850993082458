import { Typography } from '@src/components/appearance/basics/Typography';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { getIconComponent, styled, theme } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SButton = styled('button', {
  all: 'unset',

  alignItems: 'center',
  borderRadius: '$tag',
  color: '$dismissableTagText',
  display: 'inline-flex',
  flexDirection: 'row',
  gap: '$dismissableTagPaddingLeft',
  height: '$dismissableTagHeight',
  justifyContent: 'center',
  overflow: 'hidden',
  paddingLeft: '$dismissableTagPaddingLeft',
  paddingRight: '$dismissableTagPaddingRight',

  variants: {
    variant: {
      default: {
        backgroundColor: '$dismissableTagDefaultBackground',
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: '$dismissableTagDefaultHoverBackground',
        },

        '&:active': {
          backgroundColor: '$dismissableTagDefaultActiveBackground',
        },
      },
      disabled: {
        backgroundColor: '$dismissableTagDisabledBackground',
        cursor: 'not-allowed',
      },
    },
  },
});

const STypographyCaption = styled(Typography.Caption, {
  color: '$dismissableTagText',
  paddingTop: '1px',
  textTransform: 'uppercase',
});

export type TDismissableTagBase = {
  text: string;
  variant?: 'default' | 'disabled' | undefined;
};

export type TDismissableTag = Omit<TProps<false, TDismissableTagBase, 'button'>, 'disabled'>;
export const DISMISSABLE_TAG_CLASS_NAME = 'wp-dismissable-tag';

export const DismissableTag = withCssToString(
  DISMISSABLE_TAG_CLASS_NAME,
  memo(
    forwardRef<HTMLButtonElement, TDismissableTag>(({ text, className, variant, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, DISMISSABLE_TAG_CLASS_NAME), [className]);
      const IconComponent = getIconComponent('close');

      return (
        <SButton
          {...rest}
          className={joinedClassName}
          disabled={variant === 'disabled'}
          ref={ref}
          variant={variant ?? 'default'}>
          <STypographyCaption expanding={true} rigid={true} text={text} />
          <IconComponent
            height={theme.sizes.dismissableTagIconSize.value}
            width={theme.sizes.dismissableTagIconSize.value}
          />
        </SButton>
      );
    }),
  ),
);
