import { CurrencyControl } from '@src/components/appearance/controls/Currency';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { SelectControl } from '@src/components/appearance/controls/Select';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { AgentOrderPaymentsAnalyzer } from '@src/gen/shared/data/agentOrders';
import { EOrderPaymentType } from '@src/gen/shared/enums/orderPaymentType';
import { formatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TOrderPaymentAddFormValues = {
  type: EOrderPaymentType;
  amount: number;
};

export type TOrderPaymentAddFormBase = {
  doOrderPaymentAddAsync: TAgentOrderActionsTypes['DoOrderPaymentAddAsync'];
  orderPaymentsAnalyzer: AgentOrderPaymentsAnalyzer;
};

export type TOrderPaymentAddForm = TProps<false, TOrderPaymentAddFormBase, 'div'>;
export const ORDER_PAYMENT_ADD_FORM_CLASS_NAME = 'wp-order-payment-add-form';

export const OrderPaymentAddForm = withCssToString(
  ORDER_PAYMENT_ADD_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderPaymentAddForm>(
      ({ doOrderPaymentAddAsync, orderPaymentsAnalyzer, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_PAYMENT_ADD_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TOrderPaymentAddFormValues>(
          () => ({
            type: EOrderPaymentType.CHARGE,
            amount: 0,
          }),
          [],
        );

        const handleSubmit = useCallback<TFormSubmit<TOrderPaymentAddFormValues>>(
          async (values) => {
            await doOrderPaymentAddAsync(values);
            return undefined;
          },
          [doOrderPaymentAddAsync],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <ItemsBanner
              items={[
                {
                  caption: 'Payments Status',
                  text: orderPaymentsAnalyzer.getStatusText(),
                  accent: orderPaymentsAnalyzer.getStatusAccent(),
                },
                {
                  caption: 'Latest Invoice Total',
                  text: formatDollarsCurrency(orderPaymentsAnalyzer.latestInvoiceTotal),
                },
                {
                  caption: 'Payments Total',
                  text: formatDollarsCurrency(orderPaymentsAnalyzer.paymentsTotal),
                },
                {
                  caption: 'Expected Adjustment',
                  text:
                    orderPaymentsAnalyzer.latestInvoiceTotal === orderPaymentsAnalyzer.paymentsTotal
                      ? 'None'
                      : orderPaymentsAnalyzer.latestInvoiceTotal > orderPaymentsAnalyzer.paymentsTotal
                      ? `${formatDollarsCurrency(
                          orderPaymentsAnalyzer.latestInvoiceTotal - orderPaymentsAnalyzer.paymentsTotal,
                        )} (Charge)`
                      : `${formatDollarsCurrency(
                          orderPaymentsAnalyzer.paymentsTotal - orderPaymentsAnalyzer.latestInvoiceTotal,
                        )} (Refund)`,
                },
              ]}
            />

            <Form<TOrderPaymentAddFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              requireChanges={true}
              submitButtonText='Add Payment'>
              <SelectControl id='type' label='Type'>
                <option value='charge'>Charge</option>
                <option value='refund'>Refund</option>
              </SelectControl>
              <CurrencyControl id='amount' label='Amount' required={true} />
            </Form>
          </div>
        );
      },
    ),
  ),
);
