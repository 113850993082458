import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { TStructure } from '@src/components/appearance/structure/Structure';
import { Structure } from '@src/components/appearance/structure/Structure';
import type { TAgentCustomersLocationBaseWithOrganizationFragment } from '@src/gen/graphql/bindings';
import { maybeGetDonationTargetLabel } from '@src/gen/shared/enums/donationTarget';
import { maybeGetReferralFeeTargetLabel } from '@src/gen/shared/enums/referralFeeTarget';
import { maybeGetRevenueShareTargetLabel } from '@src/gen/shared/enums/revenueShareTarget';
import { formatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderCustomerStructureGroupBase = {
  locationWithOrganization: TAgentCustomersLocationBaseWithOrganizationFragment;
  variant?: TStructure['GroupBase']['variant'];
};

export type TOrderCustomerStructureGroup = TProps<false, TOrderCustomerStructureGroupBase, 'div'>;
export const ORDER_CUSTOMER_STRUCTURE_GROUP = 'wp-order-customer-structure-group';

export const OrderCustomerStructureGroup = withCssToString(
  ORDER_CUSTOMER_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderCustomerStructureGroup>(
      ({ locationWithOrganization, variant, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_CUSTOMER_STRUCTURE_GROUP), [className]);

        const itemsFirst = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Organization',
              text: locationWithOrganization.organization.name,
            },
            {
              caption: 'GPO Formularies',
              accent: locationWithOrganization.organization.is_catapult_grow ? 'warning' : undefined,
              text: locationWithOrganization.organization.is_catapult_grow ? 'Catapult Grow' : 'None',
            },
          ],
          // @sort
          [locationWithOrganization.organization.is_catapult_grow, locationWithOrganization.organization.name],
        );

        const itemsSecond = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Charity Program',
              text: isDef(locationWithOrganization.organization.donation_target)
                ? maybeGetDonationTargetLabel(locationWithOrganization.organization.donation_target)
                : 'None',
            },
            {
              caption: 'Revenue Share Program',
              text: isDef(locationWithOrganization.organization.revenue_share_target)
                ? maybeGetRevenueShareTargetLabel(locationWithOrganization.organization.revenue_share_target)
                : 'None',
            },
            {
              caption: 'Referral Fee Program',
              text: isDef(locationWithOrganization.organization.referral_fee_target)
                ? maybeGetReferralFeeTargetLabel(locationWithOrganization.organization.referral_fee_target)
                : 'None',
            },
          ],
          // @sort
          [
            locationWithOrganization.organization.donation_target,
            locationWithOrganization.organization.referral_fee_target,
            locationWithOrganization.organization.revenue_share_target,
          ],
        );

        const itemsThird = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Location',
              text: locationWithOrganization.name,
            },
            {
              caption: 'Order Code',
              text: locationWithOrganization.order_code,
            },
            {
              caption: 'Requires Prebill Approval',
              text: locationWithOrganization.requires_prebill_approval
                ? isDef(locationWithOrganization.requires_prebill_approval_threshold) &&
                  locationWithOrganization.requires_prebill_approval_threshold > 0
                  ? `Over ${formatDollarsCurrency(locationWithOrganization.requires_prebill_approval_threshold)}`
                  : 'Always'
                : 'Never',
            },
            {
              caption: 'Account Stewardship',
              text: {
                asBoolean: locationWithOrganization.has_account_stewardship,
              },
            },
          ],
          // @sort
          [
            locationWithOrganization.has_account_stewardship,
            locationWithOrganization.name,
            locationWithOrganization.order_code,
            locationWithOrganization.requires_prebill_approval,
            locationWithOrganization.requires_prebill_approval_threshold,
          ],
        );

        const itemsFourth = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Ship To',
              text: locationWithOrganization.ship_to,
            },
            {
              caption: 'Bill To',
              text: locationWithOrganization.bill_to,
            },
          ],
          // @sort
          [locationWithOrganization.bill_to, locationWithOrganization.ship_to],
        );

        return (
          <Structure.Group {...rest} className={joinedClassName} title='Customer Info' variant={variant} ref={ref}>
            <ItemsBanner items={itemsFirst} />
            <ItemsBanner items={itemsSecond} />
            <ItemsBanner items={itemsThird} />
            <ItemsBanner items={itemsFourth} />
          </Structure.Group>
        );
      },
    ),
  ),
);
