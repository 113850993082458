import type { FetchResult, NextLink, NormalizedCacheObject, Observable, Operation } from '@apollo/client';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, fromPromise, toPromise } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import type {
  AgentUserSelfQuery,
  AgentUserSelfQueryVariables,
  AnonymousIssueEmailTokenMutation,
  AnonymousIssueEmailTokenMutationVariables,
  AnonymousRefreshTokenMutation,
  AnonymousRefreshTokenMutationVariables,
  AnonymousVerifyEmailTokenMutation,
  AnonymousVerifyEmailTokenMutationVariables,
  CustomerUserSelfQuery,
  CustomerUserSelfQueryVariables,
} from '@src/gen/graphql/bindings';
import {
  AgentUserSelfDocument,
  AnonymousIssueEmailTokenDocument,
  AnonymousRefreshTokenDocument,
  AnonymousVerifyEmailTokenDocument,
  CustomerUserSelfDocument,
} from '@src/gen/graphql/bindings';
import type { TArrayElement } from '@src/gen/shared/utils/types';
import { ensureDef, ensureNotEmptyString, isDef } from '@src/gen/shared/utils/types';
import { SentryLink } from 'apollo-link-sentry';
import assert from 'assert';
import jwtDecode from 'jwt-decode';
import random from 'lodash/random';

export type TInitAuthClientOptions = {
  WP_STAGE: string;
  WP_HASURA_BASE_URL: string;
};

export function initAuthClient({
  WP_STAGE,
  WP_HASURA_BASE_URL,
}: TInitAuthClientOptions): ApolloClient<NormalizedCacheObject> {
  const doInjectDelay = async (operation: Operation, forward: NextLink): Promise<FetchResult> => {
    await new Promise((done) => setTimeout(done, random(500, 2000)));
    return await toPromise(forward(operation));
  };

  return new ApolloClient({
    connectToDevTools: WP_STAGE === 'local',
    cache: new InMemoryCache(),
    defaultOptions: {
      mutate: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
    link: ApolloLink.from([
      ...(WP_STAGE === 'local'
        ? [
            (operation: Operation, forward: NextLink): Observable<FetchResult> =>
              fromPromise(doInjectDelay(operation, forward)),
          ]
        : []),
      new SentryLink(),
      new RetryLink(),
      new HttpLink({
        uri: WP_HASURA_BASE_URL,
      }),
    ]),
  });
}

export async function doAnonymousIssueEmailToken(
  client: ApolloClient<NormalizedCacheObject>,
  email: string,
): Promise<NonNullable<AnonymousIssueEmailTokenMutation['issueEmailToken']>> {
  const { data, errors } = await client.mutate<
    AnonymousIssueEmailTokenMutation,
    AnonymousIssueEmailTokenMutationVariables
  >({
    mutation: AnonymousIssueEmailTokenDocument,
    context: {
      headers: {
        'x-hasura-role': 'anonymous',
      },
    },
    variables: {
      email,
    },
  });

  if (isDef(errors)) {
    throw errors;
  }

  return ensureDef(data?.issueEmailToken);
}

export async function doAnonymousRefreshToken(
  client: ApolloClient<NormalizedCacheObject>,
  refreshToken: string,
): Promise<NonNullable<AnonymousRefreshTokenMutation['refreshToken']>> {
  const { data, errors } = await client.mutate<AnonymousRefreshTokenMutation, AnonymousRefreshTokenMutationVariables>({
    mutation: AnonymousRefreshTokenDocument,
    context: {
      headers: {
        'x-hasura-role': 'anonymous',
      },
    },
    variables: {
      refreshToken,
    },
  });

  if (isDef(errors)) {
    throw errors;
  }

  return ensureDef(data?.refreshToken);
}

export async function doAnonymousVerifyEmailToken(
  client: ApolloClient<NormalizedCacheObject>,
  emailToken: string,
): Promise<NonNullable<AnonymousVerifyEmailTokenMutation['verifyEmailToken']>> {
  const { data, errors } = await client.mutate<
    AnonymousVerifyEmailTokenMutation,
    AnonymousVerifyEmailTokenMutationVariables
  >({
    mutation: AnonymousVerifyEmailTokenDocument,
    context: {
      headers: {
        'x-hasura-role': 'anonymous',
      },
    },
    variables: {
      emailToken,
    },
  });

  if (isDef(errors)) {
    throw errors;
  }

  return ensureDef(data?.verifyEmailToken);
}

export async function doUserSelf(
  client: ApolloClient<NormalizedCacheObject>,
  accessToken: string,
): Promise<TArrayElement<AgentUserSelfQuery['user_self']> | TArrayElement<CustomerUserSelfQuery['user_self']>> {
  const claims = jwtDecode<{
    'https://hasura.io/jwt/claims'?:
      | {
          'x-hasura-default-role'?: string | undefined;
          'x-hasura-user-id'?: string | undefined;
        }
      | undefined;
  }>(accessToken);

  const hasuraClaims = ensureDef(claims['https://hasura.io/jwt/claims']);
  const role = ensureNotEmptyString(hasuraClaims['x-hasura-default-role']);
  assert(role === 'agent' || role === 'customer');

  const { data, error } = await client.query<
    AgentUserSelfQuery | CustomerUserSelfQuery,
    AgentUserSelfQueryVariables | CustomerUserSelfQueryVariables
  >({
    query: role === 'agent' ? AgentUserSelfDocument : CustomerUserSelfDocument,
    context: {
      headers: {
        'x-hasura-role': role,
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });

  if (isDef(error)) {
    throw error;
  }

  assert(data.user_self.length === 1);
  const userSelf = ensureDef(data.user_self[0]);
  assert(userSelf.is_wellplaece_agent === (role === 'agent'));
  return userSelf;
}
