import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Control } from '@src/components/appearance/controls/Control';
import { THEME_COLORS } from '@src/gen/shared/utils/design';
import { styled } from '@src/modules/design/theme';
import type { ComponentProps, PropsWithChildren } from 'react';
import { memo, useMemo } from 'react';
import { useField } from 'react-final-form';

export type TSelectBase = {
  onChange: NonNullable<ComponentProps<'select'>['onChange']>;
  value: string;
  variant?: 'inverted' | undefined;
};

export const Select = memo(
  styled('select', {
    all: 'unset',

    alignItems: 'center',
    backgroundColor: '$selectBackground',
    backgroundImage: Illustrations.getChevronDownDataUrl(THEME_COLORS.Black),
    backgroundPosition: 'top 50% right 12px',
    backgroundRepeat: 'no-repeat',
    borderColor: '$selectBorder',
    borderRadius: '$select',
    borderStyle: '$regular',
    borderWidth: '$regular',
    color: '$selectText',
    cursor: 'pointer',
    height: '$selectHeight',
    overflow: 'hidden',
    paddingLeft: '$selectPaddingLeft',
    paddingRight: '$selectPaddingRight',
    text: '$small',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:hover': {
      borderColor: '$selectHoverBorder',
    },

    '&:focus': {
      boxShadow: '$secondaryFocus',
    },

    '&[disabled]': {
      backgroundImage: Illustrations.getChevronDownDataUrl(THEME_COLORS.Gray4),
      borderColor: '$selectDisabledBorder',
      borderStyle: '$disabled',
      color: '$selectDisabledText',
      cursor: 'not-allowed',
    },

    variants: {
      variant: {
        inverted: {
          backgroundColor: '$primaryNavBackground',
          backgroundImage: Illustrations.getChevronDownDataUrl(THEME_COLORS.White),
          borderColor: '$primaryNavBackground',
          borderRadius: '$primaryNavItem',
          color: '$primaryNavText',

          '&:hover': {
            borderColor: '$primaryNavItemHoverBackground',
            backgroundColor: '$primaryNavItemHoverBackground',
          },

          '&:focus': {
            boxShadow: '$invertedFocus',
          },

          '&[disabled]': {
            backgroundColor: '$primaryNavBackground',
            borderColor: '$primaryNavBackground',
            borderStyle: '$regular',
          },
        },
      },
    },
  }),
);

export type TSelectControl = {
  id: string;
  label?: string | undefined;
};

export function SelectControl({ id, label, children }: PropsWithChildren<TSelectControl>): JSX.Element {
  const {
    input: { onBlur, onChange, onFocus, value },
    meta: { error, submitting, visited }, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  } = useField<string>(id, {
    subscription: {
      error: true,
      submitting: true,
      value: true,
      visited: true,
    },
  });

  const finalError = useMemo(
    () => (visited === true && typeof error === 'string' && error !== '' ? error : null),
    [error, visited],
  );

  return (
    <Control error={finalError} id={id} required={true} label={label}>
      <Select disabled={submitting} id={id} onBlur={onBlur} onChange={onChange} onFocus={onFocus} value={value}>
        {children}
      </Select>
    </Control>
  );
}
