import { Typography } from '@src/components/appearance/basics/Typography';
import type { TCurrencyBase } from '@src/components/appearance/controls/Currency';
import { Quantity } from '@src/components/appearance/controls/Quantity';
import { Card } from '@src/components/appearance/fragments/Card';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { OrderSnapshotEntryProductCard } from '@src/components/mixins/cards/OrderSnapshotEntryProductCard';
import { CustomerOrderSnapshotEntryManager } from '@src/gen/shared/data/customerOrders';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';

export type TOrderSnapshotEntryPlanQuantityEditDrawerPanelBase = {
  doOrderSnapshotEntryPlanQuantityEditAsync: TCustomerOrderActionsTypes['DoOrderSnapshotEntryPlanQuantityEditAsync'];
  orderSnapshotEntryManager: CustomerOrderSnapshotEntryManager;
};

export type TOrderSnapshotEntryPlanQuantityEditDrawerPanelState = {
  customerEditedPlanQuantity: number;
};

type TMutateOrderSnapshotEntryPlanQuantityEditDrawerPanelState = (
  callback: () => TOrderSnapshotEntryPlanQuantityEditDrawerPanelState,
) => void;

export type TOrderSnapshotEntryPlanQuantityEditDrawerPanel = TProps<
  false,
  TOrderSnapshotEntryPlanQuantityEditDrawerPanelBase,
  'div'
>;
export const ORDER_SNAPSHOT_ENTRY_PLAN_QUANTITY_EDIT_DRAWER_PANEL_CLASS_NAME =
  'wp-order-snapshot-entry-plan-quantity-edit-drawer-panel';

export const OrderSnapshotEntryPlanQuantityEditDrawerPanel = withCssToString(
  ORDER_SNAPSHOT_ENTRY_PLAN_QUANTITY_EDIT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderSnapshotEntryPlanQuantityEditDrawerPanel>(
      (
        { doOrderSnapshotEntryPlanQuantityEditAsync, orderSnapshotEntryManager, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_SNAPSHOT_ENTRY_PLAN_QUANTITY_EDIT_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [state, setState] = useState<TOrderSnapshotEntryPlanQuantityEditDrawerPanelState>({
          customerEditedPlanQuantity:
            orderSnapshotEntryManager.orderSnapshotEntry.customer_edited_plan_quantity ??
            orderSnapshotEntryManager.orderSnapshotEntry.plan_quantity ??
            0,
        });

        const mutateState = useCallback<TMutateOrderSnapshotEntryPlanQuantityEditDrawerPanelState>((callback) => {
          setState(() => callback());
        }, []);

        const handleSave = useCallback(
          async (): Promise<void> => {
            await doOrderSnapshotEntryPlanQuantityEditAsync({
              orderSnapshotEntryId: orderSnapshotEntryManager.orderSnapshotEntry.id,
              customerEditedPlanQuantity: state.customerEditedPlanQuantity,
            });
          },
          // @sort
          [
            doOrderSnapshotEntryPlanQuantityEditAsync,
            orderSnapshotEntryManager.orderSnapshotEntry.id,
            state.customerEditedPlanQuantity,
          ],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Request Changes' />
            <Drawer.ScrollContent>
              <Drawer.Group>
                <OrderSnapshotEntryProductCard
                  beginOrderSnapshotEntryPlanQuantityEdit={null}
                  beginOrderSnapshotEntryView={null}
                  orderSnapshotEntryManager={
                    new CustomerOrderSnapshotEntryManager(orderSnapshotEntryManager.orderSnapshotManager, {
                      ...orderSnapshotEntryManager.orderSnapshotEntry,
                      customer_edited_plan_quantity:
                        state.customerEditedPlanQuantity !== orderSnapshotEntryManager.orderSnapshotEntry.plan_quantity
                          ? state.customerEditedPlanQuantity
                          : null,
                    })
                  }
                />
              </Drawer.Group>
              <Drawer.Group>
                <EditComponent state={state} mutateState={mutateState} />
              </Drawer.Group>
            </Drawer.ScrollContent>
            <Drawer.Footer
              buttonAction={{
                isAsync: true,
                onClick: handleSave,
                text: 'Save',
              }}
            />
          </Drawer.Panel>
        );
      },
    ),
  ),
);

const SEditComponentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$controlGap',
});

function EditComponent({
  state,
  mutateState,
}: {
  state: TOrderSnapshotEntryPlanQuantityEditDrawerPanelState;
  mutateState: TMutateOrderSnapshotEntryPlanQuantityEditDrawerPanelState;
}): JSX.Element {
  const handleChange = useCallback<TCurrencyBase['onChange']>(
    (newValue) => {
      mutateState(() => ({
        customerEditedPlanQuantity: newValue,
      }));
    },
    [mutateState],
  );

  return (
    <Card.Container flush={true} interactive={false} variant='form'>
      <SEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-plan-quantity-edit'
          required={false}
          rigid={true}
          text='Quantity'
        />
        <Quantity value={state.customerEditedPlanQuantity} onChange={handleChange} />
      </SEditComponentDiv>
    </Card.Container>
  );
}
