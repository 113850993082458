import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderStatusBanners } from '@src/components/mixins/banners/OrderStatusBanners';
import { OrderStatusItemsBanner } from '@src/components/mixins/banners/OrderStatusItemsBanner';
import type {
  TAgentOrderCompleteFragment,
  TAgentOrderShipmentBaseFragment,
  TAgentOrderTrackingNumberBaseFragment,
  TCustomerOrderCompleteFragment,
  TCustomerOrderShipmentBaseFragment,
  TCustomerOrderTrackingNumberBaseFragment,
} from '@src/gen/graphql/bindings';
import { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderStatusStructureGroupBase = {
  beginBackfillOrderInvoice?: TAgentOrderActionsTypes['BeginBackfillOrderInvoice'] | undefined;
  beginOrderCancel?: TAgentOrderActionsTypes['BeginOrderCancel'] | undefined;
  beginOrderStatusEdit?: TAgentOrderActionsTypes['BeginOrderStatusEdit'] | undefined;
  order: Pick<
    TAgentOrderCompleteFragment | TCustomerOrderCompleteFragment,
    | 'has_back_ordered_products'
    | 'has_pending_returns'
    | 'is_awaiting_prebill_approval'
    | 'is_blocked_on_customer'
    | 'is_emergency'
    | 'is_paid'
    | 'latest_order_snapshot_id'
    | 'status'
    | 'version'
  > & {
    order_shipments: Pick<TAgentOrderShipmentBaseFragment | TCustomerOrderShipmentBaseFragment, 'id'>[];
  } & {
    order_tracking_numbers: Pick<
      TAgentOrderTrackingNumberBaseFragment | TCustomerOrderTrackingNumberBaseFragment,
      'order_id' | 'tracking_number'
    >[];
  };
  organizationName: string;
};

export type TOrderStatusStructureGroup = TProps<false, TOrderStatusStructureGroupBase, 'div'>;
export const ORDER_STATUS_STRUCTURE_GROUP = 'wp-order-status-structure-group';

export const OrderStatusStructureGroup = withCssToString(
  ORDER_STATUS_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderStatusStructureGroup>(
      (
        {
          beginBackfillOrderInvoice,
          beginOrderCancel,
          beginOrderStatusEdit,
          order,
          organizationName,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_STATUS_STRUCTURE_GROUP), [className]);
        const isCanceled = order.status === EOrderStatus.CANCELED;

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () =>
            getAvailableIconButtonActions([
              { isAsync: false, icon: 'edit', onClick: isCanceled ? undefined : beginOrderStatusEdit },
              {
                isAsync: false,
                icon: 'dollar',
                onClick:
                  isCanceled || order.version !== 1 || isDef(order.latest_order_snapshot_id)
                    ? undefined
                    : beginBackfillOrderInvoice,
              },
              { isAsync: false, icon: 'trash', onClick: isCanceled ? undefined : beginOrderCancel },
            ]),
          // @sort
          [
            beginBackfillOrderInvoice,
            beginOrderCancel,
            beginOrderStatusEdit,
            isCanceled,
            order.latest_order_snapshot_id,
            order.version,
          ],
        );

        return (
          <Structure.Group
            {...rest}
            className={joinedClassName}
            iconButtonActions={iconButtonActions}
            title='Status'
            ref={ref}>
            <OrderStatusBanners order={order} organizationName={organizationName} />
            {isDef(beginOrderStatusEdit) && !isCanceled && <OrderStatusItemsBanner order={order} />}
          </Structure.Group>
        );
      },
    ),
  ),
);
