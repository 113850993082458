import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import type { TOptional } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { omit } from 'lodash';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';

const SButton = styled('button', {
  all: 'unset',

  alignItems: 'center',
  borderRadius: '$button',
  borderStyle: '$regular',
  borderWidth: '$regular',
  display: 'inline-flex',
  height: '$buttonHeight',
  justifyContent: 'center',
  paddingX: '$buttonPaddingX',
  position: 'relative',
  text: '$cta',
  userSelect: 'none',

  variants: {
    variant: {
      default: {
        backgroundColor: '$buttonDefaultBackground',
        borderColor: '$buttonDefaultBackground',
        color: '$buttonDefaultText',
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: '$buttonDefaultHoverBackground',
          borderColor: '$buttonDefaultHoverBackground',
        },

        '&:focus': {
          boxShadow: '$primaryFocus',
        },

        '&:active': {
          backgroundColor: '$buttonDefaultActiveBackground',
          borderColor: '$buttonDefaultActiveBackground',
        },
      },
      disabled: {
        backgroundColor: '$buttonDisabledBackground',
        color: '$buttonDisabledText',
        cursor: 'not-allowed',
      },
      loading: {
        backgroundColor: '$buttonLoadingBackground',
        color: '$buttonLoadingText',
        cursor: 'default',
      },
      secondary: {
        backgroundColor: '$buttonSecondaryBackground',
        borderColor: '$buttonSecondaryBorder',
        color: '$buttonSecondaryText',
        cursor: 'pointer',

        '&:hover': {
          borderColor: '$buttonSecondaryHoverBorder',
        },

        '&:focus': {
          boxShadow: '$secondaryFocus',
        },

        '&:active': {
          borderColor: '$buttonSecondaryActiveBorder',
          color: '$buttonSecondaryActiveText',
        },
      },
    },
  },
});

const SIllustrationsSpinnerDiv = styled('div', {
  height: '$illustrationsSpinnerSize',
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '$spinnerSize',
});

export type TButtonBase = {
  text: string;
  variant?: 'default' | 'disabled' | 'loading' | 'secondary' | undefined;
};

export type TButton = Omit<TProps<false, TButtonBase, 'button'>, 'disabled'>;
export type TSyncButtonAction = TButtonBase & { isAsync: false; onClick: () => void };
export type TAsyncButtonAction = TButtonBase & { isAsync: true; onClick: () => Promise<void> };
export type TButtonAction = TAsyncButtonAction | TSyncButtonAction;
export const BUTTON_CLASS_NAME = 'wp-button';

export function getButtonVariant(options: {
  base: 'default' | 'secondary';
  disabled: boolean;
  loading: boolean;
}): 'default' | 'disabled' | 'loading' | 'secondary' {
  if (options.disabled) {
    return 'disabled';
  } else if (options.loading) {
    return 'loading';
  } else {
    return options.base;
  }
}

export function getAvailableButtonActions(
  buttonActions: (TOptional<TAsyncButtonAction, 'onClick'> | TOptional<TSyncButtonAction, 'onClick'>)[],
): TButtonAction[] {
  return buttonActions.filter((buttonAction): buttonAction is TButtonAction => buttonAction.onClick !== undefined);
}

export const Button = withCssToString(
  BUTTON_CLASS_NAME,
  memo(
    forwardRef<HTMLButtonElement, TButton>(({ text, variant, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, BUTTON_CLASS_NAME), [className]);

      return (
        <SButton
          {...rest}
          className={joinedClassName}
          disabled={variant === 'disabled' || variant === 'loading'}
          variant={variant ?? 'default'}
          ref={ref}>
          {variant === 'loading' && (
            <SIllustrationsSpinnerDiv>
              <Illustrations.Spinner />
            </SIllustrationsSpinnerDiv>
          )}
          {text}
        </SButton>
      );
    }),
  ),
);

export type TSyncActionButtonBase = { action: TSyncButtonAction };
export type TSyncActionButton = Omit<TProps<false, TSyncActionButtonBase, 'button'>, 'disabled'>;

export const SyncActionButton = memo(
  forwardRef<HTMLButtonElement, TSyncActionButton>(({ action, className, ...rest }, ref): JSX.Element => {
    return <Button {...rest} {...omit(action, 'isAsync')} className={className} ref={ref} />;
  }),
);

export type TAsyncActionButtonBase = { action: TAsyncButtonAction };
export type TAsyncActionButton = Omit<TProps<false, TAsyncActionButtonBase, 'button'>, 'disabled'>;

export const AsyncActionButton = memo(
  forwardRef<HTMLButtonElement, TAsyncActionButton>(({ action, className, ...rest }, ref): JSX.Element => {
    const [loading, setLoading] = useState(false);

    const handleClickAsync = useCallback(async () => {
      try {
        setLoading(true);
        await action.onClick();
      } finally {
        setLoading(false);
      }
    }, [action]);

    const handleClick = useCallback(() => {
      void handleClickAsync();
    }, [handleClickAsync]);

    return (
      <Button
        {...rest}
        {...omit(action, 'isAsync')}
        className={className}
        onClick={handleClick}
        ref={ref}
        variant={loading ? 'loading' : action.variant}
      />
    );
  }),
);

export type TActionButtonBase = { action: TButtonAction };
export type TActionButton = Omit<TProps<false, TActionButtonBase, 'button'>, 'disabled'>;

export const ActionButton = memo(
  forwardRef<HTMLButtonElement, TActionButton>(({ action, className, ...rest }, ref): JSX.Element => {
    return action.isAsync ? (
      <AsyncActionButton {...rest} action={action} className={className} ref={ref} />
    ) : (
      <SyncActionButton {...rest} action={action} className={className} ref={ref} />
    );
  }),
);
