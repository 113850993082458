import { CheckBoxControl } from '@src/components/appearance/controls/CheckBox';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { QuantityControl } from '@src/components/appearance/controls/Quantity';
import { TextAreaControl } from '@src/components/appearance/controls/TextArea';
import { UPLOAD_ACCEPT_IMAGES, UploadControl } from '@src/components/appearance/controls/Upload';
import { Card } from '@src/components/appearance/fragments/Card';
import { ItemButton } from '@src/components/appearance/fragments/ItemButton';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { MinimalProductItem } from '@src/components/mixins/items/MinimalProductItem';
import type { CustomerOrderSnapshotEntryManager } from '@src/gen/shared/data/customerOrders';
import { getCombinedProductName } from '@src/gen/shared/data/snippets';
import { getSource, getSourceName } from '@src/gen/shared/enums/source';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TCustomerUploadsTypes } from '@src/modules/data/customer/global/uploads/CustomerUploadsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import type { Dispatch, SetStateAction } from 'react';
import { Fragment, forwardRef, memo, useCallback, useMemo, useState } from 'react';

export type TOrderReturnAddDrawerPanelBase = {
  doOrderReturnAddAsync: TCustomerOrderActionsTypes['DoOrderReturnAddAsync'];
  doUploadAsync: TCustomerUploadsTypes['DoUploadAsync'];
  orderSnapshotEntryManagers: CustomerOrderSnapshotEntryManager[];
};

type TOrderReturnAddDrawerPanelState = {
  products: { orderSnapshotEntryManager: CustomerOrderSnapshotEntryManager; isSelected: boolean }[];
  selectedProduct: CustomerOrderSnapshotEntryManager | null;
};

export type TOrderReturnAddDrawerPanel = TProps<false, TOrderReturnAddDrawerPanelBase, 'div'>;
export const ORDER_RETURN_ADD_DRAWER_PANEL_CLASS_NAME = 'wp-order-return-add-drawer-panel';

export const OrderReturnAddDrawerPanel = withCssToString(
  ORDER_RETURN_ADD_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderReturnAddDrawerPanel>(
      ({ doOrderReturnAddAsync, doUploadAsync, orderSnapshotEntryManagers, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_RETURN_ADD_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [state, setState] = useState<TOrderReturnAddDrawerPanelState>({
          products: orderSnapshotEntryManagers
            .filter((osem) => osem.isPurchasedByWellplaece())
            .map((osem) => ({ orderSnapshotEntryManager: osem, isSelected: false })),
          selectedProduct: null,
        });

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Initiate Return' />
            {!isDef(state.selectedProduct) && <ProductsPhase state={state} setState={setState} />}
            {isDef(state.selectedProduct) && (
              <FormPhase
                doOrderReturnAddAsync={doOrderReturnAddAsync}
                doUploadAsync={doUploadAsync}
                state={state}
                setState={setState}
              />
            )}
          </Drawer.Panel>
        );
      },
    ),
  ),
);

function ProductsPhase({
  state,
  setState,
}: {
  state: TOrderReturnAddDrawerPanelState;
  setState: Dispatch<SetStateAction<TOrderReturnAddDrawerPanelState>>;
}): JSX.Element {
  const handleSelect = useCallback(
    (orderSnapshotEntryId: string) => {
      setState((prevState) => ({
        ...prevState,
        products: prevState.products.map((p) =>
          p.orderSnapshotEntryManager.orderSnapshotEntry.id === orderSnapshotEntryId
            ? { ...p, isSelected: true }
            : { ...p, isSelected: false },
        ),
      }));
    },
    [setState],
  );

  const handleContinue = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedProduct: ensureDef(prevState.products.find((p) => p.isSelected)).orderSnapshotEntryManager,
      };
    });
  }, [setState]);

  const canContinue = useMemo(() => isDef(state.products.find((p) => p.isSelected)), [state.products]);

  return (
    <Fragment>
      <Drawer.ScrollContent>
        <Drawer.Group title='Select Product'>
          {state.products.map((p) => {
            const planProduct = p.orderSnapshotEntryManager.mustGetPlanProduct();

            return (
              <ItemButton
                key={p.orderSnapshotEntryManager.orderSnapshotEntry.id}
                icon={p.isSelected ? 'apply' : undefined}
                item={{
                  caption: `${getSourceName(getSource(planProduct.source))} · ${planProduct.product_sku}`,
                  text: getCombinedProductName(planProduct.name, planProduct.secondary_name),
                }}
                onClick={(): void => handleSelect(p.orderSnapshotEntryManager.orderSnapshotEntry.id)}
                variant='control'
              />
            );
          })}
        </Drawer.Group>
      </Drawer.ScrollContent>
      <Drawer.Footer
        buttonAction={{
          isAsync: false,
          onClick: handleContinue,
          text: 'Continue',
          variant: canContinue ? 'default' : 'disabled',
        }}
      />
    </Fragment>
  );
}

function FormPhase({
  doOrderReturnAddAsync,
  doUploadAsync,
  state,
}: {
  doOrderReturnAddAsync: TCustomerOrderActionsTypes['DoOrderReturnAddAsync'];
  doUploadAsync: TCustomerUploadsTypes['DoUploadAsync'];
  state: TOrderReturnAddDrawerPanelState;
  setState: Dispatch<SetStateAction<TOrderReturnAddDrawerPanelState>>;
}): JSX.Element {
  type TFormValues = {
    quantity: number;
    reason: string;
    shouldBlacklist: boolean;
    pictureUploadId?: string | undefined;
  };

  const selectedProduct = useMemo(() => ensureDef(state.selectedProduct), [state.selectedProduct]);
  const selectedPlanProduct = useMemo(() => selectedProduct.mustGetPlanProduct(), [selectedProduct]);

  const initialValues = useMemo<TFormValues>(
    () => ({
      quantity: 0,
      reason: '',
      shouldBlacklist: false,
      pictureUploadId: undefined,
    }),
    [],
  );

  const handleSubmit = useCallback<TFormSubmit<TFormValues>>(
    async (values) => {
      await doOrderReturnAddAsync({
        ...values,
        orderSnapshotEntryId: selectedProduct.orderSnapshotEntry.id,
        pictureUploadId: values.pictureUploadId ?? null,
      });
      return undefined;
    },
    [doOrderReturnAddAsync, selectedProduct],
  );

  return (
    <Drawer.ScrollContent>
      <Form<TFormValues> initialValues={initialValues} onSubmit={handleSubmit} submitButtonText='Initiate Return'>
        <MinimalProductItem
          source={selectedPlanProduct.source}
          productSku={selectedPlanProduct.product_sku}
          name={selectedPlanProduct.name}
          secondaryName={selectedPlanProduct.secondary_name}
        />
        <Card.Separator />
        <QuantityControl id='quantity' required={true} label='Returned Quantity' />
        <TextAreaControl id='reason' required={true} label='Return Reason / Explanation' maxLength={2048} />
        <CheckBoxControl id='shouldBlacklist' label='Never Receive Again' />
        <UploadControl
          acceptOverride={UPLOAD_ACCEPT_IMAGES}
          doUploadAsync={doUploadAsync}
          id='pictureUploadId'
          label='Picture if Damaged or Incorrect (JPG or PNG)'
          required={false}
        />
      </Form>
    </Drawer.ScrollContent>
  );
}
