import {
  Content as RxDialogContent,
  Overlay as RxDialogOverlay,
  Portal as RxDialogPortal,
  Root as RXDialogRoot,
} from '@radix-ui/react-dialog';
import { Root as RxSeparatorRoot } from '@radix-ui/react-separator';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Tag } from '@src/components/appearance/basics/Tag';
import { Typography } from '@src/components/appearance/basics/Typography';
import type { TAsyncButtonAction, TButtonAction, TSyncButtonAction } from '@src/components/appearance/controls/Button';
import { ActionButton, Button } from '@src/components/appearance/controls/Button';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import type { TCard } from '@src/components/appearance/fragments/Card';
import { DIALOG_CLASS_NAMES } from '@src/components/appearance/structure/Dialog';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled, styledKeyframes } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import noop from 'lodash/noop';
import type { PropsWithChildren } from 'react';
import { createContext, forwardRef, Fragment, memo, useCallback, useContext, useMemo, useState } from 'react';
import { AnimatedBlock, interpolate, useAnimatedValue, useScroll } from 'react-ui-animate';

export type TDrawer = {
  HeaderBase: {
    title: string;
  };
  GroupBase: {
    flapText?: string | undefined;
    title?: string | undefined;
    tag?: string | undefined;
  };
  TitleBase: {
    subTitle?: string | null | undefined;
    title: string;
  };
  RootBase: {
    level?: 'default' | 'top' | undefined;
    onOpenChange: (open: boolean) => void;
    open: boolean;
  };
  FooterBase: {
    buttonAction: TAsyncButtonAction | TSyncButtonAction;
    secondaryButtonAction?: TAsyncButtonAction | TSyncButtonAction | undefined;
  };

  Header: TProps<false, TDrawer['HeaderBase'], 'div'>;
  Footer: TProps<false, TDrawer['FooterBase'], 'div'>;
  Group: TProps<true, TDrawer['GroupBase'], 'div'>;
  Title: TProps<false, TCard['TitleBase'], 'div'>;
  Root: PropsWithChildren<TDrawer['RootBase']>;
};

export const DRAWER_CLASS_NAMES = {
  Header: 'wp-drawer-header',
  Group: 'wp-drawer-group',
  Title: 'wp-drawer-title',
};

type TRootContext = { doDrawerClose: () => void };
const RootContext = createContext<TRootContext | undefined>(undefined);

const Separator = memo(
  styled(RxSeparatorRoot, {
    alignSelf: 'stretch',
    backgroundColor: '$separatorBackground',
    flexShrink: 0,
    height: '$separatorThickness',
  }),
);

const SHeaderDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  padding: '$drawerPanelPaddingMobile',

  '@desktop': {
    padding: '$drawerPanelPaddingDesktop',
  },
});

const Header = withCssToString(
  DRAWER_CLASS_NAMES.Header,
  memo(
    forwardRef<HTMLDivElement, TDrawer['Header']>(({ title, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, DRAWER_CLASS_NAMES.Header), [className]);
      const rootContext = useContext(RootContext);
      const doDrawerClose = useCallback(() => rootContext?.doDrawerClose(), [rootContext]);

      return (
        <SHeaderDiv {...rest} className={joinedClassName} ref={ref}>
          <Typography.SubTitle expanding={true} rigid={true} text={title} />
          <IconButton icon='close' onClick={doDrawerClose} />
        </SHeaderDiv>
      );
    }),
  ),
);

const SFooterDiv = styled('div', {
  alignItems: 'center',
  backgroundColor: '$drawerFooterBackground',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  marginBottom: '$drawerPanelPaddingMobile',
  marginX: '$drawerPanelPaddingMobile',
  padding: '$drawerFooterPadding',

  '@desktop': {
    marginBottom: '$drawerPanelPaddingDesktop',
    marginX: '$drawerPanelPaddingDesktop',
    padding: '$drawerFooterPadding',
  },
});

const SFooterActionButton = styled(ActionButton, {
  minWidth: '$drawerFooterButtonMinWidth',
});

const Footer = withCssToString(
  DIALOG_CLASS_NAMES.Footer,
  memo(
    forwardRef<HTMLDivElement, TDrawer['Footer']>(
      ({ buttonAction, secondaryButtonAction, className, ...rest }, ref) => {
        const joinedClassName = useMemo(() => joinClassNames(className, DIALOG_CLASS_NAMES.Footer), [className]);
        const [error, setError] = useState<string | null>(null);
        const { doErrorNotify } = useErrors();

        const primaryButtonAction = useMemo<TButtonAction>(
          () =>
            buttonAction.isAsync
              ? {
                  ...buttonAction,
                  onClick: async (): Promise<void> => {
                    try {
                      setError(null);
                      await buttonAction.onClick();
                    } catch (thrown: unknown) {
                      const errorSummary = getErrorSummary(thrown, {
                        forceDisplayMode: EErrorSummaryDisplayMode.SILENT,
                      });
                      doErrorNotify(errorSummary);
                      setError(errorSummary.display.message);
                    }
                  },
                }
              : {
                  ...buttonAction,
                  onClick: (): void => {
                    try {
                      setError(null);
                      buttonAction.onClick();
                    } catch (thrown: unknown) {
                      const errorSummary = getErrorSummary(thrown, {
                        forceDisplayMode: EErrorSummaryDisplayMode.SILENT,
                      });
                      doErrorNotify(errorSummary);
                      setError(errorSummary.display.message);
                    }
                  },
                },
          // @sort
          [buttonAction, doErrorNotify],
        );

        const secondaryButton = useMemo<TButtonAction | undefined>(
          () => {
            if (!isDef(secondaryButtonAction)) return undefined;
            return secondaryButtonAction.isAsync
              ? {
                  ...secondaryButtonAction,
                  onClick: async (): Promise<void> => {
                    try {
                      setError(null);
                      await secondaryButtonAction.onClick();
                    } catch (thrown: unknown) {
                      const errorSummary = getErrorSummary(thrown, {
                        forceDisplayMode: EErrorSummaryDisplayMode.SILENT,
                      });
                      doErrorNotify(errorSummary);
                      setError(errorSummary.display.message);
                    }
                  },
                }
              : {
                  ...secondaryButtonAction,
                  onClick: (): void => {
                    try {
                      setError(null);
                      secondaryButtonAction.onClick();
                    } catch (thrown: unknown) {
                      const errorSummary = getErrorSummary(thrown, {
                        forceDisplayMode: EErrorSummaryDisplayMode.SILENT,
                      });
                      doErrorNotify(errorSummary);
                      setError(errorSummary.display.message);
                    }
                  },
                };
          },
          // @sort
          [doErrorNotify, secondaryButtonAction],
        );

        return (
          <Fragment>
            <SFooterDiv {...rest} className={joinedClassName} ref={ref}>
              {isDef(error) ? (
                <Fragment>
                  <Typography.ErrorCaption error={error} />
                  <Button onClick={(): void => setError(null)} text='Reset' variant='secondary' />
                </Fragment>
              ) : (
                <Fragment>
                  {isDef(secondaryButtonAction) ? <SFooterActionButton action={ensureDef(secondaryButton)} /> : null}
                  <div style={{ flexGrow: 1 }} />
                  <SFooterActionButton action={primaryButtonAction} />
                </Fragment>
              )}
            </SFooterDiv>
          </Fragment>
        );
      },
    ),
  ),
);

export const FooterLoader = memo(
  forwardRef<HTMLDivElement, { buttonText: string; secondaryButtonText?: string | undefined }>(
    ({ buttonText, secondaryButtonText }, ref): JSX.Element => (
      <SFooterDiv ref={ref}>
        {isDef(secondaryButtonText) && (
          <SFooterActionButton
            action={{
              isAsync: false,
              onClick: noop,
              text: secondaryButtonText,
              variant: 'disabled',
            }}
          />
        )}
        <div style={{ flexGrow: 1 }} />
        <SFooterActionButton
          action={{
            isAsync: false,
            onClick: noop,
            text: buttonText,
            variant: 'disabled',
          }}
        />
      </SFooterDiv>
    ),
  ),
);

const SGroupDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$drawerGroupGap',
  position: 'relative',
});

const SGroupFlapDiv = styled('div', {
  alignItems: 'center',
  backgroundColor: '$flapDefaultBackground',
  borderColor: '$flapDefaultBorder',
  borderRadius: '$flapDefault',
  borderStyle: '$regular',
  borderWidth: '$regular',
  color: '$flapText',
  display: 'inline-flex',
  flexDirection: 'row',
  height: '$flapHeight',
  justifyContent: 'center',
  paddingX: '$flapPaddingX',
  position: 'absolute',
  right: '12px',
  text: '$annotation',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
  borderTopWidth: '0px',
  top: '-16px',

  '@desktop': {
    top: '-24px',
  },
});

const SGroupTitleDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

const SGroupInnerDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$drawerGroupInnerGap',
});

const Group = withCssToString(
  DRAWER_CLASS_NAMES.Group,
  forwardRef<HTMLDivElement, TDrawer['Group']>(({ flapText, title, tag, children, className, ...rest }, ref) => {
    const joinedClassName = useMemo(() => joinClassNames(className, DRAWER_CLASS_NAMES.Group), [className]);

    return (
      <SGroupDiv {...rest} className={joinedClassName} ref={ref}>
        {isDef(flapText) && <SGroupFlapDiv>{flapText}</SGroupFlapDiv>}
        {isDef(title) && (
          <SGroupTitleDiv>
            {isDef(title) && <Typography.Cta expanding={true} rigid={isDef(tag)} text={title} />}
            {isDef(tag) && <Tag text={tag} />}
          </SGroupTitleDiv>
        )}
        <SGroupInnerDiv>{children}</SGroupInnerDiv>
      </SGroupDiv>
    );
  }),
);

const STitleDiv = styled('div', {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexWrap: 'wrap',
  gap: '$drawerTitleGap',
});

const Title = withCssToString(
  DRAWER_CLASS_NAMES.Title,
  memo(
    forwardRef<HTMLDivElement, TDrawer['Title']>(({ subTitle, title, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, DRAWER_CLASS_NAMES.Title), [className]);

      return (
        <STitleDiv {...rest} className={joinedClassName} ref={ref}>
          <Typography.Cta text={title} />
          {isDef(subTitle) && <Typography.Small text={subTitle} />}
        </STitleDiv>
      );
    }),
  ),
);

const TitleLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(({}, ref) => (
    <STitleDiv ref={ref}>
      <Illustrations.Loader height={24} uniqueKey='wp-drawer-title-loader' width={192}>
        <rect x='0' y='5' rx='0' ry='0' width='192' height='14' />
      </Illustrations.Loader>
    </STitleDiv>
  )),
);

const Panel = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
});

const SScrollContentRxSeparatorRoot = memo(
  styled(RxSeparatorRoot, {
    alignSelf: 'stretch',
    backgroundColor: '$separatorBackground',
    height: '$separatorThickness',
    marginX: '$drawerPanelPaddingMobile',

    '@desktop': {
      marginX: '$drawerPanelPaddingDesktop',
    },
  }),
);

const SScrollContentInnerDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$drawerPanelGapMobile',
  hideScrollbars: true,
  overflowY: 'scroll',
  padding: '$drawerPanelPaddingMobile',

  '@desktop': {
    padding: '$drawerPanelPaddingDesktop',
    gap: '$drawerPanelGapDesktop',
  },
});

function ScrollContent({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const boundScrollY = useAnimatedValue(0, { immediate: true });
  const opacity: unknown = interpolate(boundScrollY.value, [0, 64], [0, 1], { extrapolate: 'clamp' });

  const bindScroll = useScroll(({ scrollY }) => {
    boundScrollY.value = scrollY;
  });

  return (
    <Fragment>
      <AnimatedBlock style={{ opacity }}>
        <SScrollContentRxSeparatorRoot />
      </AnimatedBlock>
      <SScrollContentInnerDiv {...bindScroll()}>{children}</SScrollContentInnerDiv>
    </Fragment>
  );
}

const ScrollContentLoader = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$drawerPanelGapMobile',
  overflowY: 'hidden',
  padding: '$drawerPanelPaddingMobile',

  '@desktop': {
    gap: '$drawerPanelGapDesktop',
    padding: '$drawerPanelPaddingDesktop',
  },
});

const SRootRxDialogOverlay = memo(
  styled(RxDialogOverlay, {
    backgroundColor: '$drawerOverlayBackground',
    inset: 0,
    position: 'fixed',

    '&[data-state=open]': {
      animation: `${styledKeyframes.fadeIn.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },

    '&[data-state=closed]': {
      animation: `${styledKeyframes.fadeOut.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },

    variants: {
      level: {
        default: {
          zIndex: '$drawerDefault',
        },
        top: {
          zIndex: '$drawerTop',
        },
      },
    },
  }),
);

const SRootRxDialogContent = styled(RxDialogContent, {
  alignItems: 'stretch',
  backgroundColor: '$drawerContentBackground',
  display: 'flex',
  flexDirection: 'column',
  height: '100dvh',
  maxWidth: '$drawerContentMaxWidth',
  position: 'fixed',
  right: 0,
  top: 0,
  width: '$drawerContentWidth',

  '&[data-state=open]': {
    animation: `${styledKeyframes.slideIn.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },

  '&[data-state=closed]': {
    animation: `${styledKeyframes.slideOut.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },

  variants: {
    level: {
      default: {
        zIndex: '$drawerDefault',
      },
      top: {
        zIndex: '$drawerTop',
      },
    },
  },
});

function Root({ level, onOpenChange, open, children }: TDrawer['Root']): JSX.Element {
  const value = useMemo<TRootContext>(
    () => ({
      doDrawerClose: () => onOpenChange(false),
    }),
    [onOpenChange],
  );

  return (
    <RootContext.Provider value={value}>
      <RXDialogRoot onOpenChange={onOpenChange} open={open}>
        <RxDialogPortal>
          <SRootRxDialogOverlay level={level ?? 'default'} />
          <SRootRxDialogContent level={level ?? 'default'}>{children}</SRootRxDialogContent>
        </RxDialogPortal>
      </RXDialogRoot>
    </RootContext.Provider>
  );
}

export const Drawer = {
  Footer,
  FooterLoader,
  Group,
  Header,
  Panel,
  Root,
  ScrollContent,
  ScrollContentLoader,
  Separator,
  Title,
  TitleLoader,
};
