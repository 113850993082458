import type { FetchResult, NextLink, NormalizedCacheObject, Observable, Operation } from '@apollo/client';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, fromPromise, toPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import type { ErrorResponse } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { isDef } from '@src/gen/shared/utils/types';
import type { TAuthContext } from '@src/modules/auth/AuthProvider';
import { getErrorSummary } from '@src/modules/errors/errorSummary';
import DebounceLink from 'apollo-link-debounce';
import { SentryLink } from 'apollo-link-sentry';
import SerializingLink from 'apollo-link-serialize';
import { createClient } from 'graphql-ws';
import merge from 'lodash/merge';
import random from 'lodash/random';
import type { MutableRefObject } from 'react';

export type TInitClientOptions = {
  WP_STAGE: string;
  WP_HASURA_BASE_URL: string;
  stateRef: MutableRefObject<TAuthContext['state']>;
  doRefreshAccessTokenRef: MutableRefObject<TAuthContext['doRefreshAccessToken']>;
};

export function initClient({
  WP_STAGE,
  WP_HASURA_BASE_URL,
  stateRef,
  doRefreshAccessTokenRef,
}: TInitClientOptions): ApolloClient<NormalizedCacheObject> {
  const doInjectDelay = async (operation: Operation, forward: NextLink): Promise<FetchResult> => {
    await new Promise((done) => setTimeout(done, random(500, 2000)));
    return await toPromise(forward(operation));
  };

  const doRefreshTokenAndRetry = async ({ forward, operation }: ErrorResponse): Promise<FetchResult> => {
    await doRefreshAccessTokenRef.current();

    operation.setContext(
      merge(operation.getContext(), {
        headers: {
          authorization: isDef(stateRef.current.accessToken) ? `Bearer ${stateRef.current.accessToken}` : undefined,
        },
      }),
    );

    return await toPromise(forward(operation));
  };

  const retryIf = (thrown: unknown): boolean => {
    const errorSummary = getErrorSummary(thrown, {});
    return !isDef(errorSummary.code);
  };

  return new ApolloClient({
    connectToDevTools: WP_STAGE === 'local',
    cache: new InMemoryCache({
      dataIdFromObject: () => false, // Disable cache normalization.
      typePolicies: {
        Query: {
          fields: {
            cart_products: { merge: false },
            formulary_categories: { merge: false },
            formulary_categories_list: { merge: false },
            formulary_products: { merge: false },
            formulary_products_filter: { merge: false },
            formulary_products_search: { merge: false },
            notifications: { merge: false },
            order_attachments: { merge: false },
            order_messages: { merge: false },
            order_notifications: { merge: false },
            order_products: { merge: false },
            order_tracking_numbers: { merge: false },
            orders: { merge: false },
            public_catalog_categories: { merge: false },
            public_catalog_categories_list: { merge: false },
            public_catalog_products: { merge: false },
            public_catalog_products_filter: { merge: false },
            public_catalog_products_search: { merge: false },
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    link: ApolloLink.split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      ApolloLink.from([
        new SentryLink(),
        new RetryLink({
          delay: {
            initial: 1000,
            jitter: true,
            max: 5000,
          },
          attempts: {
            max: 10,
            retryIf,
          },
        }),
        new GraphQLWsLink(
          createClient({
            url: WP_HASURA_BASE_URL.replaceAll(/^http/g, 'ws'),
            connectionParams: () => {
              return {
                headers: {
                  authorization: isDef(stateRef.current.accessToken)
                    ? `Bearer ${stateRef.current.accessToken}`
                    : undefined,
                },
              };
            },
            lazy: true,
            retryAttempts: 1,
            retryWait: async (): Promise<void> => {
              await doRefreshAccessTokenRef.current();
            },
            shouldRetry: () => {
              // Note: Because Hasura is non-spec compliant on auth errors, we have no way here to retry only if the
              // underlying cause is an expired JWT token.
              return isDef(stateRef.current.accessToken);
            },
            lazyCloseTimeout: 60000,
          }),
        ),
      ]),
      ApolloLink.from([
        new DebounceLink(500),
        new SerializingLink(),
        ...(WP_STAGE === 'local'
          ? [
              (operation: Operation, forward: NextLink): Observable<FetchResult> =>
                fromPromise(doInjectDelay(operation, forward)),
            ]
          : []),
        new SentryLink(),
        new RetryLink({
          delay: {
            initial: 500,
            jitter: true,
            max: 1500,
          },
          attempts: {
            max: 3,
            retryIf,
          },
        }),
        setContext(async (operation, prevContext) => {
          return isDef(stateRef.current.accessToken)
            ? merge(prevContext, {
                headers: {
                  authorization: `Bearer ${stateRef.current.accessToken}`,
                },
              })
            : prevContext;
        }),
        onError((errorHandler) => {
          return isDef(stateRef.current.accessToken) &&
            isDef(errorHandler.graphQLErrors?.find(({ extensions }) => extensions['code'] === 'invalid-jwt'))
            ? fromPromise(doRefreshTokenAndRetry(errorHandler))
            : undefined;
        }),
        new HttpLink({
          uri: WP_HASURA_BASE_URL,
        }),
      ]),
    ),
  });
}
