import type { TSecondaryNavTab } from '@src/components/appearance/structure/SecondaryNav';
import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { CustomerNextOrderCartPartLoader } from '@src/components/parts/customer/nextOrder/CustomerNextOrderCartPart';
import { CustomerNextOrderCatalogsPartLoader } from '@src/components/parts/customer/nextOrder/CustomerNextOrderCatalogsPart';
import { CustomerNextOrderFormularyPartLoader } from '@src/components/parts/customer/nextOrder/CustomerNextOrderFormularyPart';
import { CustomerNextOrderHistoryPartLoader } from '@src/components/parts/customer/nextOrder/CustomerNextOrderHistoryPart';
import {
  ECustomerRoutes,
  encodeCustomerEncodeNextOrderCatalogsPath,
  encodeCustomerEncodeNextOrderFormularyPath,
  encodeCustomerNextOrderCartPath,
  encodeCustomerNextOrderHistoryPath,
  useCustomerNextOrderRouteParams,
  useCustomerRouteLocation,
} from '@src/modules/routing/customer';
import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function CustomerNextOrderNavPart(): JSX.Element {
  const additionalTabs = useAdditionalTabs();
  const location = useCustomerRouteLocation();
  const tabs = useTabs();

  return (
    <SecondaryNav
      additionalTabs={additionalTabs}
      additionalTabsTitle='Add From'
      label={`for ${location.name}`}
      tabs={tabs}
      title='Next Order'
    />
  );
}

export function CustomerNextOrderNavPartLoader(): JSX.Element {
  const additionalTabs = useAdditionalTabs();
  const location = useCustomerRouteLocation();
  const routeParams = useCustomerNextOrderRouteParams();
  const tabs = useTabs();

  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav
          additionalTabs={additionalTabs}
          additionalTabsTitle='Add From'
          label={`for ${location.name}`}
          tabs={tabs}
          title='Next Order'
        />
      </Structure.Content>
      {routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART && <CustomerNextOrderCartPartLoader />}
      {routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY && <CustomerNextOrderHistoryPartLoader />}
      {routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY && <CustomerNextOrderFormularyPartLoader />}
      {routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS && <CustomerNextOrderCatalogsPartLoader />}
    </Fragment>
  );
}

function useTabs(): TSecondaryNavTab[] {
  const navigate = useNavigate();
  const routeParams = useCustomerNextOrderRouteParams();

  return useMemo(
    () => [
      {
        active: routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART,
        onClick: (): void =>
          navigate(encodeCustomerNextOrderCartPath(routeParams.organizationId, routeParams.locationId)),
        title: 'Cart',
      },
    ],
    // @sort
    [navigate, routeParams.locationId, routeParams.organizationId, routeParams.route],
  );
}

function useAdditionalTabs(): TSecondaryNavTab[] {
  const navigate = useNavigate();
  const routeParams = useCustomerNextOrderRouteParams();

  return useMemo(
    () => [
      {
        active: routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_HISTORY,
        onClick: () => navigate(encodeCustomerNextOrderHistoryPath(routeParams.organizationId, routeParams.locationId)),
        title: 'History',
      },
      {
        active: routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY,
        onClick: () =>
          navigate(
            encodeCustomerEncodeNextOrderFormularyPath(routeParams.organizationId, routeParams.locationId, {
              query: null,
              sourceFilter: null,
              categoryName01Filter: null,
              categoryName02Filter: null,
              manufacturerNameFilter: null,
            }),
          ),
        title: 'Formulary',
      },
      {
        active: routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS,
        onClick: () =>
          navigate(
            encodeCustomerEncodeNextOrderCatalogsPath(routeParams.organizationId, routeParams.locationId, {
              query: null,
              sourceFilter: null,
              categoryName01Filter: null,
              categoryName02Filter: null,
              manufacturerNameFilter: null,
            }),
          ),
        title: 'Catalogs',
      },
    ],
    // @sort
    [navigate, routeParams.locationId, routeParams.organizationId, routeParams.route],
  );
}
