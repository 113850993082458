import { CheckBoxControl } from '@src/components/appearance/controls/CheckBox';
import { ConditionalControl } from '@src/components/appearance/controls/ConditionalControl';
import { CurrencyControl } from '@src/components/appearance/controls/Currency';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { TextAreaControl } from '@src/components/appearance/controls/TextArea';
import { TextBoxControl } from '@src/components/appearance/controls/TextBox';
import { Card } from '@src/components/appearance/fragments/Card';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TAgentCustomersLocationBaseFragment } from '@src/gen/graphql/bindings';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersOrganizationActionsTypes } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TLocationEditFormValues = {
  requiresPrebillApproval: boolean;
  requiresPrebillApprovalThreshold: number;
  hasAccountStewardship: boolean;
  shipTo: string;
  billTo: string;
  stripeCustomerId: string;
};

export type TLocationEditFormBase = {
  doCustomersLocationUpdateAsync: TAgentCustomersOrganizationActionsTypes['DoCustomersLocationUpdateAsync'];
  location: TAgentCustomersLocationBaseFragment;
};

export type TLocationEditForm = TProps<false, TLocationEditFormBase, 'div'>;
export const LOCATION_EDIT_FORM_CLASS_NAME = 'wp-location-edit-form';

export const LocationEditForm = withCssToString(
  LOCATION_EDIT_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TLocationEditForm>(
      ({ doCustomersLocationUpdateAsync, location, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, LOCATION_EDIT_FORM_CLASS_NAME), [className]);

        const initialValues = useMemo<TLocationEditFormValues>(
          () => ({
            requiresPrebillApproval: location.requires_prebill_approval,
            requiresPrebillApprovalThreshold: location.requires_prebill_approval_threshold ?? 0,
            hasAccountStewardship: location.has_account_stewardship,
            shipTo: location.ship_to ?? '',
            billTo: location.bill_to ?? '',
            stripeCustomerId: location.stripe_customer_id ?? '',
          }), // @sort
          [
            location.bill_to,
            location.has_account_stewardship,
            location.requires_prebill_approval,
            location.requires_prebill_approval_threshold,
            location.ship_to,
            location.stripe_customer_id,
          ],
        );

        const handleSubmit = useCallback<TFormSubmit<TLocationEditFormValues>>(
          async (values) => {
            await doCustomersLocationUpdateAsync({
              ...values,
              requiresPrebillApprovalThreshold: values.requiresPrebillApproval
                ? values.requiresPrebillApprovalThreshold
                : null,
              locationId: location.id,
            });
            return undefined;
          },
          [doCustomersLocationUpdateAsync, location.id],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TLocationEditFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              requireChanges={true}
              submitButtonText='Save'>
              <Item item={{ caption: 'Name', text: location.name }} />
              <Item item={{ caption: 'Order code', text: location.order_code }} />
              <Card.Separator />
              <CheckBoxControl id='requiresPrebillApproval' label='Requires Prebill Approval' />
              <ConditionalControl<boolean>
                subscription='requiresPrebillApproval'
                predicate={(requiresPrebillApproval): boolean => requiresPrebillApproval}
                render={(): JSX.Element => (
                  <CurrencyControl
                    id='requiresPrebillApprovalThreshold'
                    required={false}
                    label='Requires Prebill Approval Over Amount'
                  />
                )}
              />
              <CheckBoxControl id='hasAccountStewardship' label='Account Stewardship' />
              <TextAreaControl id='shipTo' label='Ship To' maxLength={1024} required={false} />
              <TextAreaControl id='billTo' label='Bill To' maxLength={1024} required={false} />
              <TextBoxControl
                id='stripeCustomerId'
                label='Stripe Customer ID'
                required={false}
                stripeCustomerId={true}
              />
            </Form>
          </div>
        );
      },
    ),
  ),
);
