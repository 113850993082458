import { Card } from '@src/components/appearance/fragments/Card';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { AgentOrderEntryManager } from '@src/gen/shared/data/agentOrders';
import { getSaleUnitQuantity } from '@src/gen/shared/data/snippets';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { maybeFormatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderEntryBaseProductCardBase = {
  onClick: (() => void) | null;
  orderEntryManager: AgentOrderEntryManager;
};

export type TOrderEntryBaseProductCard = TProps<false, TOrderEntryBaseProductCardBase, 'div'>;
export const ORDER_ENTRY_BASE_PRODUCT_CARD_CLASS_NAME = 'wp-order-entry-base-product-card';

export const OrderEntryBaseProductCard = withCssToString(
  ORDER_ENTRY_BASE_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderEntryBaseProductCard>(
      ({ onClick, orderEntryManager, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ENTRY_BASE_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const baseProduct = useMemo(() => orderEntryManager.getBaseProduct(), [orderEntryManager]);

        const itemsFirst = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(baseProduct.source),
            },
            {
              caption: 'SKU',
              text: baseProduct.product_sku,
            },
            {
              caption: 'Manufacturer',
              text: baseProduct.manufacturer_name,
            },
            {
              caption: 'Manufacturer SKU',
              text: baseProduct.manufacturer_sku,
            },
          ],
          // @sort
          [baseProduct.manufacturer_name, baseProduct.manufacturer_sku, baseProduct.product_sku, baseProduct.source],
        );

        const itemsSecond = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Allow Substitutions',
              text: { asBoolean: orderEntryManager.orderEntry.is_substitution_allowed },
            },
            {
              caption: 'Quantity',
              text: `${getSaleUnitQuantity(orderEntryManager.orderEntry.quantity, baseProduct.sale_unit)}${
                orderEntryManager.orderEntry.quantity !== orderEntryManager.orderEntry.initial_quantity
                  ? ` (was ${orderEntryManager.orderEntry.initial_quantity})`
                  : ''
              }`,
            },
            {
              caption: 'Unit Price (Base)',
              text:
                maybeFormatDollarsCurrency(orderEntryManager.orderEntry.unit_price) ??
                (orderEntryManager.isPurchasedByCustomer() || orderEntryManager.isPurchasedByWellplaece()
                  ? 'TBD'
                  : 'Not Needed'),
            },
            {
              caption: 'Sub Total (Base)',
              text:
                maybeFormatDollarsCurrency(orderEntryManager.maybeGetBaseSubTotal()) ??
                (orderEntryManager.isPurchasedByCustomer() || orderEntryManager.isPurchasedByWellplaece()
                  ? 'TBD'
                  : 'Not Needed'),
            },
          ],
          // @sort
          [orderEntryManager, baseProduct.sale_unit],
        );

        return (
          <Card.Container {...rest} className={joinedClassName} interactive={isDef(onClick)} ref={ref}>
            {isDef(onClick) ? (
              <Card.ButtonArea onClick={onClick}>
                <Card.ImageAssetArea>
                  <ImageAsset alt={baseProduct.name} imageAssetPath={baseProduct.image_asset_path} />
                  <Card.ContentArea>
                    <Card.Title subTitle={baseProduct.secondary_name} title={baseProduct.name} />
                    <Items bannerAligned={true} items={itemsFirst} />
                    <ItemsBanner items={itemsSecond} />
                  </Card.ContentArea>
                </Card.ImageAssetArea>
              </Card.ButtonArea>
            ) : (
              <Card.ImageAssetArea>
                <ImageAsset alt={baseProduct.name} imageAssetPath={baseProduct.image_asset_path} />
                <Card.ContentArea>
                  <Card.Title subTitle={baseProduct.secondary_name} title={baseProduct.name} />
                  <Items bannerAligned={true} items={itemsFirst} />
                  <ItemsBanner items={itemsSecond} />
                </Card.ContentArea>
              </Card.ImageAssetArea>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);
