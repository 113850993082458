import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { AgentNavPart } from '@src/components/parts/agent/AgentNavPart';
import { AgentCustomersOrganizationsActionsProvider } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationsActionsProvider';

import {
  AgentCustomersOrganizationAnalyticsOrdersPart,
  AgentCustomersOrganizationAnalyticsOrdersPartLoader,
} from '@src/components/parts/agent/customers/organizations/AgentCustomersOrganizationAnalyticsOrdersPart';
import { AgentCustomersOrganizationFormularyNavPart } from '@src/components/parts/agent/customers/organizations/AgentCustomersOrganizationFormularyNavPart';
import {
  AgentCustomersOrganizationFormularySearchPart,
  AgentCustomersOrganizationFormularySearchPartLoader,
} from '@src/components/parts/agent/customers/organizations/AgentCustomersOrganizationFormularySearchPart';
import { AgentCustomersOrganizationLocationsPart } from '@src/components/parts/agent/customers/organizations/AgentCustomersOrganizationLocationsPart';
import {
  AgentCustomersOrganizationNavPart,
  AgentCustomersOrganizationNavPartLoader,
} from '@src/components/parts/agent/customers/organizations/AgentCustomersOrganizationNavPart';
import { AgentCustomersOrganizationOverviewPart } from '@src/components/parts/agent/customers/organizations/AgentCustomersOrganizationOverviewPart';
import { AgentCustomersOrganizationUsersPart } from '@src/components/parts/agent/customers/organizations/AgentCustomersOrganizationUsersPart';
import {
  AgentCustomersOrganizationsPart,
  AgentCustomersOrganizationsPartLoader,
} from '@src/components/parts/agent/customers/organizations/AgentCustomersOrganizationsPart';
import {
  AgentCustomersUserNavPart,
  AgentCustomersUserNavPartLoader,
} from '@src/components/parts/agent/customers/users/AgentCustomersUserNavPart';
import { AgentCustomersUserOrganizationsPart } from '@src/components/parts/agent/customers/users/AgentCustomersUserOrganizationsPart';
import { AgentCustomersUserOverviewPart } from '@src/components/parts/agent/customers/users/AgentCustomersUserOverviewPart';
import {
  AgentCustomersUsersPart,
  AgentCustomersUsersPartLoader,
} from '@src/components/parts/agent/customers/users/AgentCustomersUsersPart';
import { AgentMetricsPart } from '@src/components/parts/agent/metrics/AgentMetricsPart';
import { AgentOrderNavPart, AgentOrderNavPartLoader } from '@src/components/parts/agent/order/AgentOrderNavPart';
import { AgentOrderSummaryPart } from '@src/components/parts/agent/order/AgentOrderSummaryPart';
import { AgentOrderUpdatesAndSupportPart } from '@src/components/parts/agent/order/AgentOrderUpdatesAndSupportPart';
import { AgentOrderWorksheetBackOrdersPart } from '@src/components/parts/agent/order/AgentOrderWorksheetBackOrdersPart';
import { AgentOrderWorksheetBaseOrdersPart } from '@src/components/parts/agent/order/AgentOrderWorksheetBaseOrdersPart';
import { AgentOrderWorksheetDashboardPart } from '@src/components/parts/agent/order/AgentOrderWorksheetDashboardPart';
import { AgentOrderWorksheetNavPart } from '@src/components/parts/agent/order/AgentOrderWorksheetNavPart';
import { AgentOrderWorksheetPaymentsPart } from '@src/components/parts/agent/order/AgentOrderWorksheetPaymentsPart';
import { AgentOrderWorksheetPlanOrdersPart } from '@src/components/parts/agent/order/AgentOrderWorksheetPlanOrdersPart';
import { AgentOrderWorksheetProductsPart } from '@src/components/parts/agent/order/AgentOrderWorksheetProductsPart';
import { AgentOrderWorksheetReturnsPart } from '@src/components/parts/agent/order/AgentOrderWorksheetReturnsPart';
import { AgentOrderWorksheetShipmentsAnalyzerPart } from '@src/components/parts/agent/order/AgentOrderWorksheetShipmentsAnalyzerPart';
import { AgentOrderWorksheetShipmentsPart } from '@src/components/parts/agent/order/AgentOrderWorksheetShipmentsPart';
import {
  AgentOrderBackOrdersPart,
  AgentOrderBackOrdersPartLoader,
} from '@src/components/parts/agent/orderBackOrders/AgentOrderBackOrdersPart';
import {
  AgentOrderReturnsPart,
  AgentOrderReturnsPartLoader,
} from '@src/components/parts/agent/orderReturns/AgentOrderReturnsPart';
import { AgentOrdersPart, AgentOrdersPartLoader } from '@src/components/parts/agent/orders/AgentOrdersPart';
import { AgentUserNavPart } from '@src/components/parts/agent/user/AgentUserNavPart';
import { AgentUserPart, AgentUserPartLoader } from '@src/components/parts/agent/user/AgentUserPart';
import type { EOrderEntryOrigin } from '@src/gen/shared/enums/orderEntryOrigin';
import { getOrderEntryOrigin } from '@src/gen/shared/enums/orderEntryOrigin';
import type { EOrderEntryPlanStatus } from '@src/gen/shared/enums/orderEntryPlanStatus';
import { getOrderEntryPlanStatus } from '@src/gen/shared/enums/orderEntryPlanStatus';
import type { EOrderPaymentType } from '@src/gen/shared/enums/orderPaymentType';
import { getOrderPaymentType } from '@src/gen/shared/enums/orderPaymentType';
import { EOrderStatus, getOrderStatus } from '@src/gen/shared/enums/orderStatus';
import type { ESource } from '@src/gen/shared/enums/source';
import { getSource } from '@src/gen/shared/enums/source';
import { ensureBoolean, ensureDef, ensureNotEmptyString, ensureString, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TAuthAgent } from '@src/modules/auth/subject';
import { AgentCustomersOrganizationActionsProvider } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import { AgentCustomersOrganizationAnalyticsOrdersProvider } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationAnalyticsOrdersProvider';
import { AgentCustomersOrganizationFormularySearchProvider } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationFormularySearchProvider';
import { AgentCustomersOrganizationProvider } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import { AgentCustomersOrganizationsWithStatisticsProvider } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationsWithStatisticsProvider';
import { AgentCustomersUserActionsProvider } from '@src/modules/data/agent/customers/users/AgentCustomersUserActionsProvider';
import { AgentCustomersUserProvider } from '@src/modules/data/agent/customers/users/AgentCustomersUserProvider';
import { AgentCustomersUsersActionsProvider } from '@src/modules/data/agent/customers/users/AgentCustomersUsersActionsProvider';
import { AgentCustomersUsersWithStatisticsWithStatisticsProvider } from '@src/modules/data/agent/customers/users/AgentCustomersUsersWithStatisticsProvider';
import { AgentLocationPickerDrawerProvider } from '@src/modules/data/agent/global/overlays/AgentLocationPickerDrawerProvider';
import { AgentCartProductsChromeProvider } from '@src/modules/data/agent/global/overlays/AgentOrganizationCartProductsChromeProvider';
import { AgentOrganizationPickerDrawerProvider } from '@src/modules/data/agent/global/overlays/AgentOrganizationPickerDrawerProvider';
import { AgentOrganizationSourcePickerDrawerProvider } from '@src/modules/data/agent/global/overlays/AgentOrganizationSourcePickerDrawerProvider';
import { AgentOrganizationUserPickerDrawerProvider } from '@src/modules/data/agent/global/overlays/AgentOrganizationUserPickerDrawerProvider';
import { AgentProductComparePickerChromeProvider } from '@src/modules/data/agent/global/overlays/AgentProductComparePickerChromeProvider';
import { AgentProductHistoryPickerChromeProvider } from '@src/modules/data/agent/global/overlays/AgentProductHistoryPickerChromeProvider';
import { AgentProductPickerChromeProvider } from '@src/modules/data/agent/global/overlays/AgentProductPickerChromeProvider';
import { AgentUserPickerDrawerProvider } from '@src/modules/data/agent/global/overlays/AgentUserPickerDrawerProvider';
import { AgentUploadsProvider } from '@src/modules/data/agent/global/uploads/AgentUploadsProvider';
import { AgentNotificationsActionsProvider } from '@src/modules/data/agent/notifications/AgentNotificationsActionsProvider';
import { AgentNotificationsProvider } from '@src/modules/data/agent/notifications/AgentNotificationsProvider';
import { AgentOrderActionsProvider } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { AgentOrderBudgetAssistantProvider } from '@src/modules/data/agent/order/AgentOrderBudgetAssistantProvider';
import { AgentOrderMessagesProvider } from '@src/modules/data/agent/order/AgentOrderMessagesProvider';
import { AgentOrderProvider, useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import { AgentOrderSnapshotReviewChromeProvider } from '@src/modules/data/agent/order/AgentOrderSnapshotReviewChromeProvider';
import { AgentOrderBackOrdersWaitingProvider } from '@src/modules/data/agent/orderBackOrders/AgentOrderBackOrdersWaitingProvider';
import { AgentOrderReturnsWaitingProvider } from '@src/modules/data/agent/orderReturns/AgentOrderReturnsWaitingProvider';
import { AgentOrdersFilterActionsProvider } from '@src/modules/data/agent/orders/AgentOrdersFilterActionsProvider';
import type { TAgentOrdersFilterProviderFilter } from '@src/modules/data/agent/orders/AgentOrdersFilterProvider';
import { AgentOrdersFilterProvider } from '@src/modules/data/agent/orders/AgentOrdersFilterProvider';
import { DateRangePickerDrawerProvider } from '@src/modules/data/shared/overlays/DateRangePickerDrawerProvider';
import { RouterErrorElement } from '@src/modules/errors/RouterErrorElement';
import assert from 'assert';
import { Base64 } from 'js-base64';
import { Fragment, useMemo } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, Outlet, generatePath, matchPath, useLocation } from 'react-router-dom';

export enum EAgentRoutes {
  AGENT_ORDERS = '/agent/orders/:config',
  AGENT_ORDER_SUMMARY = '/agent/order/:orderId/summary',
  AGENT_ORDER_UPDATES_AND_SUPPORT = '/agent/order/:orderId/updates-and-support',
  AGENT_ORDER_WORKSHEET_DASHBOARD = '/agent/order/:orderId/worksheet/dashboard',
  AGENT_ORDER_WORKSHEET_PRODUCTS = '/agent/order/:orderId/worksheet/products/:config',
  AGENT_ORDER_WORKSHEET_BASE_ORDERS = '/agent/order/:orderId/worksheet/base-orders',
  AGENT_ORDER_WORKSHEET_PLAN_ORDERS = '/agent/order/:orderId/worksheet/plan-orders',
  AGENT_ORDER_WORKSHEET_PAYMENTS = '/agent/order/:orderId/worksheet/payments/:config',
  AGENT_ORDER_WORKSHEET_SHIPMENTS = '/agent/order/:orderId/worksheet/shipments/:config',
  AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER = '/agent/order/:orderId/worksheet/shipments-analyzer',
  AGENT_ORDER_WORKSHEET_BACK_ORDERS = '/agent/order/:orderId/worksheet/back-orders',
  AGENT_ORDER_WORKSHEET_RETURNS = '/agent/order/:orderId/worksheet/returns',
  AGENT_BACK_ORDERS = '/agent/back-orders',
  AGENT_RETURNS = '/agent/returns',
  AGENT_CUSTOMERS_ORGANIZATIONS = '/agent/customers/organizations',
  AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW = '/agent/customers/organization/:organizationId/overview',
  AGENT_CUSTOMERS_ORGANIZATION_USERS = '/agent/customers/organization/:organizationId/users',
  AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS = '/agent/customers/organization/:organizationId/locations',
  AGENT_CUSTOMERS_ORGANIZATION_FORMULARY = '/agent/customers/organization/:organizationId/formulary/:config',
  AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS = '/agent/customers/organization/:organizationId/analytics/orders/:config',
  AGENT_CUSTOMERS_USERS = '/agent/customers/users',
  AGENT_CUSTOMERS_USER_OVERVIEW = '/agent/customers/user/:userId/overview',
  AGENT_CUSTOMERS_USER_ORGANIZATIONS = '/agent/customers/user/:userId/organizations',
  AGENT_METRICS = '/agent/metrics',
  AGENT_USER = '/agent/user',
}

export type TAgentOrdersRouteParamsConfig = TAgentOrdersFilterProviderFilter;

export function encodeAgentOrdersRouteParamsConfig(filter: TAgentOrdersRouteParamsConfig): string {
  return Base64.encode(
    JSON.stringify([
      1,
      filter.organizationId,
      filter.status,
      filter.isAwaitingPrebillApproval,
      filter.isBlockedOnCustomer,
      filter.isEmergency,
      filter.isPaid,
    ]),
    true,
  );
}

export function decodeAgentOrdersRouteParamsConfig(config: string): TAgentOrdersRouteParamsConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(Base64.decode(config));

    if (Array.isArray(parsed)) {
      assert(parsed[0] === 1);

      return {
        organizationId: isDef(parsed[1]) ? ensureNotEmptyString(parsed[1]) : null,
        status: isDef(parsed[2]) ? getOrderStatus(ensureNotEmptyString(parsed[2])) : null,
        isAwaitingPrebillApproval: isDef(parsed[3]) ? ensureBoolean(parsed[3]) : null,
        isBlockedOnCustomer: isDef(parsed[4]) ? ensureBoolean(parsed[4]) : null,
        isEmergency: isDef(parsed[5]) ? ensureBoolean(parsed[5]) : null,
        isPaid: isDef(parsed[6]) ? ensureBoolean(parsed[6]) : null,
      };
    }
  } catch {
    // intentionally empty
  }

  throw new Error(`Invalid config: "${config}".`);
}

export type TAgentOrdersRouteParams = {
  route: EAgentRoutes.AGENT_ORDERS;
  config: TAgentOrdersRouteParamsConfig;
};

export type TAgentOrderSummaryRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_SUMMARY;
  orderId: string;
};

export type TAgentOrderUpdatesAndSupportRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT;
  orderId: string;
};

export type TAgentOrderWorksheetDashboardRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD;
  orderId: string;
};

export type TAgentOrderWorksheetProductsRouteParamsConfig = {
  status: 'any' | 'ready' | 'tbd';
  origin: EOrderEntryOrigin | 'any';
  baseSource: ESource | 'any';
  planSource: ESource | 'any';
  allowSubstitutions: boolean | 'any';
  savings: '<5' | '<10' | '<15' | '<20' | '<50' | '>=50' | 'any' | 'none';
  planStatus: EOrderEntryPlanStatus | 'any';
  search: string;
  flags:
    | 'any'
    | 'is-blocked-on-price-confirmation'
    | 'is-blocked-on-product-availability-confirmation'
    | 'is-under-review';
};

export function encodeAgentOrderWorksheetProductsRouteParamsConfig(
  filter: TAgentOrderWorksheetProductsRouteParamsConfig,
): string {
  return Base64.encode(
    JSON.stringify([
      3,
      filter.status,
      filter.origin,
      filter.baseSource,
      filter.planSource,
      filter.allowSubstitutions,
      filter.savings,
      filter.planStatus,
      filter.search,
      filter.flags,
    ]),
    true,
  );
}

export function decodeAgentOrderWorksheetProductsRouteParamsConfig(
  config: string,
): TAgentOrderWorksheetProductsRouteParamsConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(Base64.decode(config));

    if (Array.isArray(parsed)) {
      assert(parsed[0] === 1 || parsed[0] === 2 || parsed[0] === 3);

      const status = ensureNotEmptyString(parsed[1]);
      const origin = ensureNotEmptyString(parsed[2]);
      const baseSource = ensureNotEmptyString(parsed[3]);
      const planSource = ensureNotEmptyString(parsed[4]);
      const allowSubstitutions = parsed[0] === 1 ? 'any' : parsed[5] === 'any' ? 'any' : ensureBoolean(parsed[5]);
      const savings = parsed[0] === 1 ? 'any' : ensureNotEmptyString(parsed[6]);
      const planStatus = parsed[0] === 1 ? 'any' : ensureNotEmptyString(parsed[7]);
      const search = parsed[0] === 1 ? '' : ensureString(parsed[8]);
      const flags = parsed[0] === 1 || parsed[0] === 2 ? 'any' : ensureString(parsed[9]);

      assert(status === 'any' || status === 'ready' || status === 'tbd');

      assert(
        savings === 'any' ||
          savings === 'none' ||
          savings === '<5' ||
          savings === '<10' ||
          savings === '<15' ||
          savings === '<20' ||
          savings === '<50' ||
          savings === '>=50',
      );

      assert(
        flags === 'any' ||
          flags === 'is-blocked-on-price-confirmation' ||
          flags === 'is-under-review' ||
          flags === 'is-blocked-on-product-availability-confirmation',
      );

      return {
        status,
        origin: origin === 'any' ? 'any' : getOrderEntryOrigin(origin),
        baseSource: baseSource === 'any' ? 'any' : getSource(baseSource),
        planSource: planSource === 'any' ? 'any' : getSource(planSource),
        allowSubstitutions: allowSubstitutions,
        savings,
        planStatus: planStatus === 'any' ? 'any' : getOrderEntryPlanStatus(planStatus),
        search,
        flags,
      };
    }
  } catch (thrown: unknown) {
    console.error('XXX', thrown);
    // intentionally empty
  }

  throw new Error(`Invalid config: "${config}".`);
}

export type TAgentOrderWorksheetProductsRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS;
  orderId: string;
  config: TAgentOrderWorksheetProductsRouteParamsConfig;
};

export type TAgentOrderWorksheetBaseOrdersRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS;
  orderId: string;
};

export type TAgentOrderWorksheetPlanOrdersRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS;
  orderId: string;
};

export type TAgentOrderWorksheetPaymentsRouteParamsConfig = {
  type: EOrderPaymentType | 'any';
};

export function encodeAgentOrderWorksheetPaymentsRouteParamsConfig(
  filter: TAgentOrderWorksheetPaymentsRouteParamsConfig,
): string {
  return Base64.encode(JSON.stringify([1, filter.type]), true);
}

export function decodeAgentOrderWorksheetPaymentsRouteParamsConfig(
  config: string,
): TAgentOrderWorksheetPaymentsRouteParamsConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(Base64.decode(config));

    if (Array.isArray(parsed)) {
      assert(parsed[0] === 1);
      const type = ensureNotEmptyString(parsed[1]);

      return {
        type: type === 'any' ? 'any' : getOrderPaymentType(type),
      };
    }
  } catch {
    // intentionally empty
  }

  throw new Error(`Invalid config: "${config}".`);
}

export type TAgentOrderWorksheetPaymentsRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS;
  orderId: string;
  config: TAgentOrderWorksheetPaymentsRouteParamsConfig;
};

export type TAgentOrderWorksheetShipmentsRouteParamsConfig = {
  status: 'any' | 'delivered' | 'tracked';
};

export function encodeAgentOrderWorksheetShipmentsRouteParamsConfig(
  filter: TAgentOrderWorksheetShipmentsRouteParamsConfig,
): string {
  return Base64.encode(JSON.stringify([1, filter.status]), true);
}

export function decodeAgentOrderWorksheetShipmentsRouteParamsConfig(
  config: string,
): TAgentOrderWorksheetShipmentsRouteParamsConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(Base64.decode(config));

    if (Array.isArray(parsed)) {
      assert(parsed[0] === 1);
      const status = ensureNotEmptyString(parsed[1]);
      assert(status === 'any' || status === 'delivered' || status === 'tracked');

      return {
        status,
      };
    }
  } catch {
    // intentionally empty
  }

  throw new Error(`Invalid config: "${config}".`);
}

export type TAgentOrderWorksheetShipmentsRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS;
  orderId: string;
  config: TAgentOrderWorksheetShipmentsRouteParamsConfig;
};

export type TAgentOrderWorksheetShipmentsAnalyzerRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER;
  orderId: string;
};

export type TAgentOrderWorksheetBackOrdersRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS;
  orderId: string;
};

export type TAgentOrderWorksheetReturnsRouteParams = {
  route: EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS;
  orderId: string;
};

export type TAgentBackOrdersRouteParams = {
  route: EAgentRoutes.AGENT_BACK_ORDERS;
};

export type TAgentReturnsRouteParams = {
  route: EAgentRoutes.AGENT_RETURNS;
};

export type TAgentCustomersOrganizationsRouteParams = {
  route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS;
};

export type TAgentCustomersOrganizationOverviewRouteParams = {
  route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW;
  organizationId: string;
};

export type TAgentCustomersOrganizationUsersRouteParams = {
  route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS;
  organizationId: string;
};

export type TAgentCustomersOrganizationLocationsRouteParams = {
  route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS;
  organizationId: string;
};

export type TAgentCustomersOrganizationAnalyticsOrdersRouteParamsConfig = {
  locationId: string | null;
  startTime: string | null;
  endTime: string | null;
};

export function encodeAgentCustomersOrganizationAnalyticsOrdersRouteParamsConfig(
  config: TAgentCustomersOrganizationAnalyticsOrdersRouteParamsConfig,
): string {
  return Base64.encode(JSON.stringify([1, config.locationId, config.startTime, config.endTime]));
}

export function decodeAgentCustomersOrganizationAnalyticsOrdersRouteParamsConfig(
  config: string,
): TAgentCustomersOrganizationAnalyticsOrdersRouteParamsConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(Base64.decode(config));

    if (Array.isArray(parsed)) {
      assert(parsed[0] === 1);

      return {
        locationId: isDef(parsed[1]) ? ensureNotEmptyString(parsed[1]) : null,
        startTime: isDef(parsed[2]) ? ensureNotEmptyString(parsed[2]) : null,
        endTime: isDef(parsed[3]) ? ensureNotEmptyString(parsed[3]) : null,
      };
    }
  } catch {
    // intentionally empty
  }

  throw new Error(`Invalid config: "${config}".`);
}

export type TAgentCustomersOrganizationAnalyticsOrdersRouteParams = {
  route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS;
  organizationId: string;
  config: TAgentCustomersOrganizationAnalyticsOrdersRouteParamsConfig;
};

export type TAgentCustomersOrganizationFormularyRouteParamsConfig = {
  query: string | null;
  sourceFilter: string | null;
  categoryName01Filter: string | null;
  categoryName02Filter: string | null;
  manufacturerNameFilter: string | null;
};

export function encodeAgentCustomersOrganizationFormularyRouteParamsConfig(
  filter: TAgentCustomersOrganizationFormularyRouteParamsConfig,
): string {
  return Base64.encode(
    JSON.stringify([
      2,
      filter.query,
      filter.sourceFilter,
      filter.categoryName01Filter,
      filter.categoryName02Filter,
      filter.manufacturerNameFilter,
    ]),
  );
}

export function decodeAgentCustomersOrganizationFormularyRouteParamsConfig(
  config: string,
): TAgentCustomersOrganizationFormularyRouteParamsConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(Base64.decode(config));

    if (Array.isArray(parsed)) {
      assert(parsed[0] === 2);

      return {
        query: isDef(parsed[1]) ? ensureNotEmptyString(parsed[1]) : null,
        sourceFilter: isDef(parsed[2]) ? ensureNotEmptyString(parsed[2]) : null,
        categoryName01Filter: isDef(parsed[3]) ? ensureNotEmptyString(parsed[3]) : null,
        categoryName02Filter: isDef(parsed[4]) ? ensureNotEmptyString(parsed[4]) : null,
        manufacturerNameFilter: isDef(parsed[5]) ? ensureNotEmptyString(parsed[5]) : null,
      };
    }
  } catch {
    // intentionally empty
  }

  throw new Error(`Invalid config: "${config}".`);
}

export type TAgentCustomersOrganizationFormularyRouteParams = {
  route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY;
  organizationId: string;
  config: TAgentCustomersOrganizationFormularyRouteParamsConfig;
};

export type TAgentCustomersUsersRouteParams = {
  route: EAgentRoutes.AGENT_CUSTOMERS_USERS;
};

export type TAgentCustomersUserOverviewRouteParams = {
  route: EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW;
  userId: string;
};

export type TAgentCustomersUserOrganizationsRouteParams = {
  route: EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS;
  userId: string;
};

export type TAgentMetricsRouteParams = {
  route: EAgentRoutes.AGENT_METRICS;
};

export type TAgentUserRouteParams = {
  route: EAgentRoutes.AGENT_USER;
};

export type TAgentRouteParams =
  | TAgentBackOrdersRouteParams
  | TAgentCustomersOrganizationAnalyticsOrdersRouteParams
  | TAgentCustomersOrganizationFormularyRouteParams
  | TAgentCustomersOrganizationLocationsRouteParams
  | TAgentCustomersOrganizationOverviewRouteParams
  | TAgentCustomersOrganizationsRouteParams
  | TAgentCustomersOrganizationUsersRouteParams
  | TAgentCustomersUserOrganizationsRouteParams
  | TAgentCustomersUserOverviewRouteParams
  | TAgentCustomersUsersRouteParams
  | TAgentOrdersRouteParams
  | TAgentOrderSummaryRouteParams
  | TAgentOrderUpdatesAndSupportRouteParams
  | TAgentOrderWorksheetBackOrdersRouteParams
  | TAgentOrderWorksheetBaseOrdersRouteParams
  | TAgentOrderWorksheetDashboardRouteParams
  | TAgentOrderWorksheetPaymentsRouteParams
  | TAgentOrderWorksheetPlanOrdersRouteParams
  | TAgentOrderWorksheetProductsRouteParams
  | TAgentOrderWorksheetReturnsRouteParams
  | TAgentOrderWorksheetShipmentsAnalyzerRouteParams
  | TAgentOrderWorksheetShipmentsRouteParams
  | TAgentReturnsRouteParams
  | TAgentMetricsRouteParams
  | TAgentUserRouteParams;

export function encodeAgentPath(params: TAgentRouteParams): string {
  switch (params.route) {
    case EAgentRoutes.AGENT_ORDERS:
      return generatePath(params.route, {
        config: encodeAgentOrdersRouteParamsConfig(params.config),
      });
    case EAgentRoutes.AGENT_ORDER_SUMMARY:
    case EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD:
      return generatePath(params.route, {
        orderId: params.orderId,
      });
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS:
      return generatePath(params.route, {
        orderId: params.orderId,
        config: encodeAgentOrderWorksheetProductsRouteParamsConfig(params.config),
      });
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS:
      return generatePath(params.route, {
        orderId: params.orderId,
      });
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS:
      return generatePath(params.route, {
        orderId: params.orderId,
      });
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS:
      return generatePath(params.route, {
        orderId: params.orderId,
        config: encodeAgentOrderWorksheetPaymentsRouteParamsConfig(params.config),
      });
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS:
      return generatePath(params.route, {
        orderId: params.orderId,
        config: encodeAgentOrderWorksheetShipmentsRouteParamsConfig(params.config),
      });
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER:
      return generatePath(params.route, {
        orderId: params.orderId,
      });
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS:
      return generatePath(params.route, {
        orderId: params.orderId,
      });
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS:
      return generatePath(params.route, {
        orderId: params.orderId,
      });
    case EAgentRoutes.AGENT_BACK_ORDERS:
      return generatePath(params.route, {});
    case EAgentRoutes.AGENT_RETURNS:
      return generatePath(params.route, {});
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS:
      return generatePath(params.route, {});
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW:
      return generatePath(params.route, {
        organizationId: params.organizationId,
      });
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS:
      return generatePath(params.route, {
        organizationId: params.organizationId,
      });
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS:
      return generatePath(params.route, {
        organizationId: params.organizationId,
      });
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS:
      return generatePath(params.route, {
        organizationId: params.organizationId,
        config: encodeAgentCustomersOrganizationAnalyticsOrdersRouteParamsConfig(params.config),
      });
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY:
      return generatePath(params.route, {
        organizationId: params.organizationId,
        config: encodeAgentCustomersOrganizationFormularyRouteParamsConfig(params.config),
      });
    case EAgentRoutes.AGENT_CUSTOMERS_USERS:
      return generatePath(params.route, {});
    case EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW:
      return generatePath(params.route, {
        userId: params.userId,
      });
    case EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS:
      return generatePath(params.route, {
        userId: params.userId,
      });
    case EAgentRoutes.AGENT_METRICS:
      return generatePath(params.route, {});
    case EAgentRoutes.AGENT_USER:
      return generatePath(params.route, {});
    default:
      throw new Error('Unexpected.');
  }
}

export function encodeAgentOrdersPath(): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDERS,
    config: {
      organizationId: null,
      status: null,
      isAwaitingPrebillApproval: null,
      isBlockedOnCustomer: null,
      isEmergency: null,
      isPaid: null,
    },
  });
}

export function encodeAgentOrdersPathWithConfig(config: TAgentOrdersRouteParamsConfig): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDERS,
    config,
  });
}

export function encodeAgentOrderSummaryPath(orderId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_SUMMARY,
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeAgentOrderUpdatesAndSupportPath(orderId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT,
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeAgentOrderWorksheetDashboardPath(orderId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD,
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeAgentOrderWorksheetProductsPath(
  orderId: string,
  config: TAgentOrderWorksheetProductsRouteParamsConfig,
): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS,
    orderId: ensureNotEmptyString(orderId),
    config,
  });
}

export function encodeAgentOrderWorksheetBaseOrdersPath(orderId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS,
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeAgentOrderWorksheetPlanOrdersPath(orderId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS,
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeAgentOrderWorksheetPaymentsPath(
  orderId: string,
  config: TAgentOrderWorksheetPaymentsRouteParamsConfig,
): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS,
    orderId: ensureNotEmptyString(orderId),
    config,
  });
}

export function encodeAgentOrderWorksheetShipmentsPath(
  orderId: string,
  config: TAgentOrderWorksheetShipmentsRouteParamsConfig,
): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS,
    orderId: ensureNotEmptyString(orderId),
    config,
  });
}

export function encodeAgentOrderWorksheetShipmentsAnalyzerPath(orderId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER,
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeAgentOrderWorksheetBackOrdersPath(orderId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS,
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeAgentOrderWorksheetReturnsPath(orderId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS,
    orderId: ensureNotEmptyString(orderId),
  });
}

export function encodeAgentBackOrdersPath(): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_BACK_ORDERS,
  });
}

export function encodeAgentReturnsPath(): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_RETURNS,
  });
}

export function encodeAgentCustomersOrganizationsPath(): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS,
  });
}

export function encodeAgentCustomersOrganizationAnalyticsOrdersPath(
  organizationId: string,
  config: TAgentCustomersOrganizationAnalyticsOrdersRouteParamsConfig,
): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS,
    organizationId: ensureNotEmptyString(organizationId),
    config,
  });
}

export function encodeAgentCustomersOrganizationOverviewPath(organizationId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW,
    organizationId: ensureNotEmptyString(organizationId),
  });
}

export function encodeAgentCustomersOrganizationUsersPath(organizationId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS,
    organizationId: ensureNotEmptyString(organizationId),
  });
}

export function encodeAgentCustomersOrganizationLocationsPath(organizationId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS,
    organizationId: ensureNotEmptyString(organizationId),
  });
}

export function encodeAgentCustomersOrganizationFormularyPath(
  organizationId: string,
  config: TAgentCustomersOrganizationFormularyRouteParamsConfig,
): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY,
    organizationId: ensureNotEmptyString(organizationId),
    config,
  });
}

export function encodeAgentCustomersUsersPath(): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_CUSTOMERS_USERS,
  });
}

export function encodeAgentCustomersUserOverviewPath(userId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW,
    userId: ensureNotEmptyString(userId),
  });
}

export function encodeAgentCustomersUserOrganizationsPath(userId: string): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS,
    userId: ensureNotEmptyString(userId),
  });
}

export function encodeAgentMetricsPath(): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_METRICS,
  });
}

export function encodeAgentUserPath(): string {
  return encodeAgentPath({
    route: EAgentRoutes.AGENT_USER,
  });
}

export function decodeAgentPath(path: string): TAgentRouteParams {
  try {
    for (const route of Object.values(EAgentRoutes)) {
      const match = matchPath(route, path);

      if (!isDef(match)) {
        continue;
      }

      switch (route) {
        case EAgentRoutes.AGENT_ORDERS:
          return {
            route: EAgentRoutes.AGENT_ORDERS,
            config: decodeAgentOrdersRouteParamsConfig(ensureDef(match.params.config)),
          };
        case EAgentRoutes.AGENT_ORDER_SUMMARY:
          return {
            route: EAgentRoutes.AGENT_ORDER_SUMMARY,
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT:
          return {
            route: EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT,
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD:
          return {
            route: EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD,
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS:
          return {
            route: EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS,
            orderId: ensureNotEmptyString(match.params.orderId),
            config: decodeAgentOrderWorksheetProductsRouteParamsConfig(ensureDef(match.params.config)),
          };
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS:
          return {
            route: EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS,
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS:
          return {
            route: EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS,
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS:
          return {
            route: EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS,
            orderId: ensureNotEmptyString(match.params.orderId),
            config: decodeAgentOrderWorksheetPaymentsRouteParamsConfig(ensureDef(match.params.config)),
          };
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS:
          return {
            route: EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS,
            orderId: ensureNotEmptyString(match.params.orderId),
            config: decodeAgentOrderWorksheetShipmentsRouteParamsConfig(ensureDef(match.params.config)),
          };
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER:
          return {
            route: EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER,
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS:
          return {
            route: EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS,
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS:
          return {
            route: EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS,
            orderId: ensureNotEmptyString(match.params.orderId),
          };
        case EAgentRoutes.AGENT_BACK_ORDERS:
          return {
            route: EAgentRoutes.AGENT_BACK_ORDERS,
          };
        case EAgentRoutes.AGENT_RETURNS:
          return {
            route: EAgentRoutes.AGENT_RETURNS,
          };
        case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS:
          return {
            route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS,
          };
        case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW:
          return {
            route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW,
            organizationId: ensureNotEmptyString(match.params.organizationId),
          };
        case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS:
          return {
            route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS,
            organizationId: ensureNotEmptyString(match.params.organizationId),
          };
        case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS:
          return {
            route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS,
            organizationId: ensureNotEmptyString(match.params.organizationId),
          };
        case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY:
          return {
            route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY,
            organizationId: ensureNotEmptyString(match.params.organizationId),
            config: decodeAgentCustomersOrganizationFormularyRouteParamsConfig(ensureDef(match.params.config)),
          };
        case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS:
          return {
            route: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS,
            organizationId: ensureNotEmptyString(match.params.organizationId),
            config: decodeAgentCustomersOrganizationAnalyticsOrdersRouteParamsConfig(ensureDef(match.params.config)),
          };
        case EAgentRoutes.AGENT_CUSTOMERS_USERS:
          return {
            route: EAgentRoutes.AGENT_CUSTOMERS_USERS,
          };
        case EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW:
          return {
            route: EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW,
            userId: ensureNotEmptyString(match.params.userId),
          };
        case EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS:
          return {
            route: EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS,
            userId: ensureNotEmptyString(match.params.userId),
          };
        case EAgentRoutes.AGENT_METRICS:
          return {
            route: EAgentRoutes.AGENT_METRICS,
          };
        case EAgentRoutes.AGENT_USER:
          return {
            route: EAgentRoutes.AGENT_USER,
          };
      }
    }
  } catch (thrown: unknown) {
    // intentionally empty
  }

  throw new Error('Not Found');
}

export const { Context: AgentRouteParamsContext, useContext: useAgentRouteParams } =
  createRequiredContext<TAgentRouteParams>();

export function useAgentOrdersRouteParams(): TAgentOrdersRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderRouteParams():
  | TAgentOrderSummaryRouteParams
  | TAgentOrderUpdatesAndSupportRouteParams
  | TAgentOrderWorksheetBackOrdersRouteParams
  | TAgentOrderWorksheetBaseOrdersRouteParams
  | TAgentOrderWorksheetDashboardRouteParams
  | TAgentOrderWorksheetPaymentsRouteParams
  | TAgentOrderWorksheetPlanOrdersRouteParams
  | TAgentOrderWorksheetProductsRouteParams
  | TAgentOrderWorksheetReturnsRouteParams
  | TAgentOrderWorksheetShipmentsAnalyzerRouteParams
  | TAgentOrderWorksheetShipmentsRouteParams {
  const routeParams = useAgentRouteParams();

  if (
    routeParams.route !== EAgentRoutes.AGENT_ORDER_SUMMARY &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS
  ) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderSummaryRouteParams(): TAgentOrderSummaryRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_SUMMARY) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderUpdatesAndSupportRouteParams(): TAgentOrderUpdatesAndSupportRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetRouteParams():
  | TAgentOrderWorksheetBackOrdersRouteParams
  | TAgentOrderWorksheetBaseOrdersRouteParams
  | TAgentOrderWorksheetDashboardRouteParams
  | TAgentOrderWorksheetPaymentsRouteParams
  | TAgentOrderWorksheetPlanOrdersRouteParams
  | TAgentOrderWorksheetProductsRouteParams
  | TAgentOrderWorksheetReturnsRouteParams
  | TAgentOrderWorksheetShipmentsAnalyzerRouteParams
  | TAgentOrderWorksheetShipmentsRouteParams {
  const routeParams = useAgentRouteParams();

  if (
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS &&
    routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS
  ) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetDashboardRouteParams(): TAgentOrderWorksheetDashboardRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetProductsRouteParams(): TAgentOrderWorksheetProductsRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetBaseOrdersRouteParams(): TAgentOrderWorksheetBaseOrdersRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetPlanOrdersRouteParams(): TAgentOrderWorksheetPlanOrdersRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetPaymentsRouteParams(): TAgentOrderWorksheetPaymentsRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetShipmentsRouteParams(): TAgentOrderWorksheetShipmentsRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetShipmentsAnalyzerRouteParams(): TAgentOrderWorksheetShipmentsAnalyzerRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetBackOrdersRouteParams(): TAgentOrderWorksheetBackOrdersRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentOrderWorksheetReturnsRouteParams(): TAgentOrderWorksheetReturnsRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentBackOrdersRouteParams(): TAgentBackOrdersRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_BACK_ORDERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentReturnsRouteParams(): TAgentReturnsRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_RETURNS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersRouteParams():
  | TAgentCustomersOrganizationAnalyticsOrdersRouteParams
  | TAgentCustomersOrganizationFormularyRouteParams
  | TAgentCustomersOrganizationLocationsRouteParams
  | TAgentCustomersOrganizationOverviewRouteParams
  | TAgentCustomersOrganizationsRouteParams
  | TAgentCustomersOrganizationUsersRouteParams
  | TAgentCustomersUserOrganizationsRouteParams
  | TAgentCustomersUserOverviewRouteParams
  | TAgentCustomersUsersRouteParams {
  const routeParams = useAgentRouteParams();

  if (
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_USERS &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS
  ) {
    throw new Error(`Mismatched route: ${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersOrganizationsRouteParams(): TAgentCustomersOrganizationsRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersOrganizationRouteParams():
  | TAgentCustomersOrganizationAnalyticsOrdersRouteParams
  | TAgentCustomersOrganizationFormularyRouteParams
  | TAgentCustomersOrganizationLocationsRouteParams
  | TAgentCustomersOrganizationOverviewRouteParams
  | TAgentCustomersOrganizationUsersRouteParams {
  const routeParams = useAgentRouteParams();

  if (
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS
  ) {
    throw new Error(`Mismatched route: ${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersOrganizationOverviewRouteParams(): TAgentCustomersOrganizationOverviewRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersOrganizationUsersRouteParams(): TAgentCustomersOrganizationUsersRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersOrganizationLocationsRouteParams(): TAgentCustomersOrganizationLocationsRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersOrganizationFormularyRouteParams(): TAgentCustomersOrganizationFormularyRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersOrganizationAnalyticsOrdersRouteParams(): TAgentCustomersOrganizationAnalyticsOrdersRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersUsersRouteParams(): TAgentCustomersUsersRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_USERS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersUserRouteParams():
  | TAgentCustomersUserOrganizationsRouteParams
  | TAgentCustomersUserOverviewRouteParams {
  const routeParams = useAgentRouteParams();

  if (
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW &&
    routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS
  ) {
    throw new Error(`Mismatched route: ${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersUserOverviewRouteParams(): TAgentCustomersUserOverviewRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentCustomersUserOrganizationsRouteParams(): TAgentCustomersUserOrganizationsRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentMetricsRouteParams(): TAgentMetricsRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_METRICS) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function useAgentUserRouteParams(): TAgentUserRouteParams {
  const routeParams = useAgentRouteParams();

  if (routeParams.route !== EAgentRoutes.AGENT_USER) {
    throw new Error(`Mismatched route: "${routeParams.route}".`);
  }

  return routeParams;
}

export function getAgentRoutePageTitle(route: EAgentRoutes, agent: TAuthAgent): string {
  switch (route) {
    case EAgentRoutes.AGENT_ORDERS:
      return 'Orders';
    case EAgentRoutes.AGENT_ORDER_SUMMARY:
    case EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER:
    case EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS:
      return 'Order';
    case EAgentRoutes.AGENT_BACK_ORDERS:
      return 'Back Orders';
    case EAgentRoutes.AGENT_RETURNS:
      return 'Returns';
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS:
      return 'Organizations';
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW:
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS:
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY:
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS:
    case EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS:
      return 'Organization';
    case EAgentRoutes.AGENT_CUSTOMERS_USERS:
      return 'Users';
    case EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW:
    case EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS:
    case EAgentRoutes.AGENT_USER:
      return `${agent.first_name} ${agent.last_name}`;
    case EAgentRoutes.AGENT_METRICS:
      return 'Metrics';
  }
}

export function getAgentRoutes(): RouteObject[] {
  return [
    {
      path: '/',
      element: <RedirectRoute />,
      errorElement: <RouterErrorElement />,
    },
    {
      element: <RootLayoutRoute />,
      errorElement: <RouterErrorElement />,
      children: [
        {
          path: EAgentRoutes.AGENT_ORDERS,
          element: <OrdersRoute />,
          errorElement: <RouterErrorElement />,
        },
        {
          element: <OrderLayoutRoute />,
          children: [
            {
              path: EAgentRoutes.AGENT_ORDER_SUMMARY,
              element: <OrderSummaryRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT,
              element: <OrderUpdatesAndSupportRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              element: <OrderWorksheetLayoutRoute />,
              children: [
                {
                  path: EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD,
                  element: <OrderWorksheetDashboardRoute />,
                  errorElement: <RouterErrorElement />,
                },
                {
                  path: EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS,
                  element: <OrderWorksheetProductsRoute />,
                  errorElement: <RouterErrorElement />,
                },
                {
                  path: EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS,
                  element: <OrderWorksheetBaseOrdersRoute />,
                  errorElement: <RouterErrorElement />,
                },
                {
                  path: EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS,
                  element: <OrderWorksheetPlanOrdersRoute />,
                  errorElement: <RouterErrorElement />,
                },
                {
                  path: EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS,
                  element: <OrderWorksheetPaymentsRoute />,
                  errorElement: <RouterErrorElement />,
                },
                {
                  path: EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS,
                  element: <OrderWorksheetShipmentsRoute />,
                  errorElement: <RouterErrorElement />,
                },
                {
                  path: EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER,
                  element: <OrderWorksheetShipmentsAnalyzerRoute />,
                  errorElement: <RouterErrorElement />,
                },
                {
                  path: EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS,
                  element: <OrderWorksheetBackOrdersRoute />,
                  errorElement: <RouterErrorElement />,
                },
                {
                  path: EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS,
                  element: <OrderWorksheetReturnsRoute />,
                  errorElement: <RouterErrorElement />,
                },
              ],
            },
          ],
        },
        {
          path: EAgentRoutes.AGENT_BACK_ORDERS,
          element: <BackOrdersRoute />,
          errorElement: <RouterErrorElement />,
        },
        {
          path: EAgentRoutes.AGENT_RETURNS,
          element: <ReturnsRoute />,
          errorElement: <RouterErrorElement />,
        },
        {
          path: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS,
          element: <CustomersOrganizationsRoute />,
          errorElement: <RouterErrorElement />,
        },
        {
          element: <CustomersOrganizationLayoutRoute />,
          errorElement: <RouterErrorElement />,
          children: [
            {
              path: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW,
              element: <CustomersOrganizationOverviewRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS,
              element: <CustomersOrganizationUsersRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS,
              element: <CustomersOrganizationLocationsRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY,
              element: <CustomersOrganizationFormularyRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS,
              element: <CustomersOrganizationAnalyticsOrdersRoute />,
              errorElement: <RouterErrorElement />,
            },
          ],
        },
        {
          path: EAgentRoutes.AGENT_CUSTOMERS_USERS,
          element: <CustomersUsersRoute />,
          errorElement: <RouterErrorElement />,
        },
        {
          element: <CustomersUserLayoutRoute />,
          errorElement: <RouterErrorElement />,
          children: [
            {
              path: EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW,
              element: <CustomersUserOverviewRoute />,
              errorElement: <RouterErrorElement />,
            },
            {
              path: EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS,
              element: <CustomersUserOrganizationsRoute />,
              errorElement: <RouterErrorElement />,
            },
          ],
        },
        {
          path: EAgentRoutes.AGENT_METRICS,
          element: <MetricsRoute />,
          errorElement: <RouterErrorElement />,
        },
        {
          element: <UserLayoutRoute />,
          errorElement: <RouterErrorElement />,
          children: [
            {
              path: EAgentRoutes.AGENT_USER,
              element: <UserRoute />,
              errorElement: <RouterErrorElement />,
            },
          ],
        },
      ],
    },
  ];
}

function RedirectRoute(): JSX.Element {
  const { pathname } = useLocation();

  switch (pathname) {
    case '/':
      return <Navigate to={encodeAgentOrdersPath()} replace={true} />;
    default:
      throw new Error('Not Found');
  }
}

function RootLayoutRoute(): JSX.Element {
  const { pathname } = useLocation();
  const value = useMemo(() => decodeAgentPath(pathname), [pathname]);

  return (
    <AgentRouteParamsContext.Provider value={value}>
      <DateRangePickerDrawerProvider>
        <AgentUploadsProvider>
          <AgentOrganizationPickerDrawerProvider>
            <AgentLocationPickerDrawerProvider>
              <AgentUserPickerDrawerProvider>
                <AgentOrganizationUserPickerDrawerProvider>
                  <AgentOrganizationSourcePickerDrawerProvider>
                    <Structure.Root>
                      <AgentNavPart />
                      <Structure.Main>
                        <Outlet />
                      </Structure.Main>
                    </Structure.Root>
                  </AgentOrganizationSourcePickerDrawerProvider>
                </AgentOrganizationUserPickerDrawerProvider>
              </AgentUserPickerDrawerProvider>
            </AgentLocationPickerDrawerProvider>
          </AgentOrganizationPickerDrawerProvider>
        </AgentUploadsProvider>
      </DateRangePickerDrawerProvider>
    </AgentRouteParamsContext.Provider>
  );
}

function OrdersRoute(): JSX.Element {
  const { config } = useAgentOrdersRouteParams();

  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav title='Orders' />
      </Structure.Content>
      <AgentOrdersFilterActionsProvider>
        <AgentOrdersFilterProvider LoaderComponent={AgentOrdersPartLoader} filter={config}>
          <AgentOrdersPart />
        </AgentOrdersFilterProvider>
      </AgentOrdersFilterActionsProvider>
    </Fragment>
  );
}

function OrderLayoutRoute(): JSX.Element {
  return (
    <AgentProductComparePickerChromeProvider>
      <AgentProductPickerChromeProvider>
        <AgentProductHistoryPickerChromeProvider>
          <AgentOrderProvider LoaderComponent={AgentOrderNavPartLoader}>
            <AgentOrderBudgetAssistantProvider>
              <AgentOrderActionsProvider>
                <AgentOrderMessagesProvider>
                  <AgentOrderSnapshotReviewChromeProvider>
                    <Structure.Content>
                      <AgentOrderNavPart />
                    </Structure.Content>
                    <Outlet />
                  </AgentOrderSnapshotReviewChromeProvider>
                </AgentOrderMessagesProvider>
              </AgentOrderActionsProvider>
            </AgentOrderBudgetAssistantProvider>
          </AgentOrderProvider>
        </AgentProductHistoryPickerChromeProvider>
      </AgentProductPickerChromeProvider>
    </AgentProductComparePickerChromeProvider>
  );
}

function OrderSummaryRoute(): JSX.Element {
  return <AgentOrderSummaryPart />;
}

function OrderUpdatesAndSupportRoute(): JSX.Element {
  return <AgentOrderUpdatesAndSupportPart />;
}

function OrderWorksheetLayoutRoute(): JSX.Element {
  const { order } = useAgentOrder();

  return (
    <Fragment>
      <AgentOrderWorksheetNavPart />
      {order.status !== EOrderStatus.CANCELED && <Outlet />}
    </Fragment>
  );
}

function OrderWorksheetDashboardRoute(): JSX.Element {
  return <AgentOrderWorksheetDashboardPart />;
}

function OrderWorksheetProductsRoute(): JSX.Element {
  return <AgentOrderWorksheetProductsPart />;
}

function OrderWorksheetBaseOrdersRoute(): JSX.Element {
  return <AgentOrderWorksheetBaseOrdersPart />;
}

function OrderWorksheetPlanOrdersRoute(): JSX.Element {
  return <AgentOrderWorksheetPlanOrdersPart />;
}

function OrderWorksheetPaymentsRoute(): JSX.Element {
  return <AgentOrderWorksheetPaymentsPart />;
}

function OrderWorksheetShipmentsRoute(): JSX.Element {
  return <AgentOrderWorksheetShipmentsPart />;
}

function OrderWorksheetShipmentsAnalyzerRoute(): JSX.Element {
  return <AgentOrderWorksheetShipmentsAnalyzerPart />;
}

function OrderWorksheetBackOrdersRoute(): JSX.Element {
  return <AgentOrderWorksheetBackOrdersPart />;
}

function OrderWorksheetReturnsRoute(): JSX.Element {
  return <AgentOrderWorksheetReturnsPart />;
}

function BackOrdersRoute(): JSX.Element {
  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav title='Back-Orders' />
      </Structure.Content>
      <AgentOrderBackOrdersWaitingProvider LoaderComponent={AgentOrderBackOrdersPartLoader}>
        <AgentOrderBackOrdersPart />
      </AgentOrderBackOrdersWaitingProvider>
    </Fragment>
  );
}

function ReturnsRoute(): JSX.Element {
  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav title='Returns' />
      </Structure.Content>
      <AgentOrderReturnsWaitingProvider LoaderComponent={AgentOrderReturnsPartLoader}>
        <AgentOrderReturnsPart />
      </AgentOrderReturnsWaitingProvider>
    </Fragment>
  );
}

function CustomersOrganizationsRoute(): JSX.Element {
  return (
    <AgentCustomersOrganizationsWithStatisticsProvider LoaderComponent={AgentCustomersOrganizationsPartLoader}>
      <AgentCustomersOrganizationsActionsProvider>
        <AgentCustomersOrganizationsPart />
      </AgentCustomersOrganizationsActionsProvider>
    </AgentCustomersOrganizationsWithStatisticsProvider>
  );
}

function CustomersOrganizationLayoutRoute(): JSX.Element {
  return (
    <AgentProductPickerChromeProvider>
      <AgentCustomersOrganizationProvider LoaderComponent={AgentCustomersOrganizationNavPartLoader}>
        <AgentCartProductsChromeProvider>
          <AgentCustomersOrganizationActionsProvider>
            <Structure.Content>
              <AgentCustomersOrganizationNavPart />
            </Structure.Content>
            <Outlet />
          </AgentCustomersOrganizationActionsProvider>
        </AgentCartProductsChromeProvider>
      </AgentCustomersOrganizationProvider>
    </AgentProductPickerChromeProvider>
  );
}

function CustomersOrganizationOverviewRoute(): JSX.Element {
  return <AgentCustomersOrganizationOverviewPart />;
}

function CustomersOrganizationUsersRoute(): JSX.Element {
  return <AgentCustomersOrganizationUsersPart />;
}

function CustomersOrganizationLocationsRoute(): JSX.Element {
  return <AgentCustomersOrganizationLocationsPart />;
}

function CustomersOrganizationFormularyRoute(): JSX.Element {
  return (
    <Fragment>
      <Structure.Content>
        <AgentCustomersOrganizationFormularyNavPart />
      </Structure.Content>
      <AgentCustomersOrganizationFormularySearchProvider
        LoaderComponent={AgentCustomersOrganizationFormularySearchPartLoader}>
        <AgentCustomersOrganizationFormularySearchPart />
      </AgentCustomersOrganizationFormularySearchProvider>
    </Fragment>
  );
}

function CustomersOrganizationAnalyticsOrdersRoute(): JSX.Element {
  return (
    <AgentCustomersOrganizationAnalyticsOrdersProvider
      LoaderComponent={AgentCustomersOrganizationAnalyticsOrdersPartLoader}>
      <AgentCustomersOrganizationAnalyticsOrdersPart />
    </AgentCustomersOrganizationAnalyticsOrdersProvider>
  );
}

function CustomersUsersRoute(): JSX.Element {
  return (
    <AgentCustomersUsersWithStatisticsWithStatisticsProvider LoaderComponent={AgentCustomersUsersPartLoader}>
      <AgentCustomersUsersActionsProvider>
        <AgentCustomersUsersPart />
      </AgentCustomersUsersActionsProvider>
    </AgentCustomersUsersWithStatisticsWithStatisticsProvider>
  );
}

function CustomersUserLayoutRoute(): JSX.Element {
  return (
    <AgentCustomersUserProvider LoaderComponent={AgentCustomersUserNavPartLoader}>
      <AgentCustomersUserActionsProvider>
        <Structure.Content>
          <AgentCustomersUserNavPart />
        </Structure.Content>
        <Outlet />
      </AgentCustomersUserActionsProvider>
    </AgentCustomersUserProvider>
  );
}

function CustomersUserOverviewRoute(): JSX.Element {
  return <AgentCustomersUserOverviewPart />;
}

function CustomersUserOrganizationsRoute(): JSX.Element {
  return <AgentCustomersUserOrganizationsPart />;
}

function MetricsRoute(): JSX.Element {
  return <AgentMetricsPart />;
}

function UserLayoutRoute(): JSX.Element {
  return (
    <Fragment>
      <Structure.Content>
        <AgentUserNavPart />
      </Structure.Content>
      <Outlet />
    </Fragment>
  );
}

function UserRoute(): JSX.Element {
  return (
    <AgentNotificationsProvider LoaderComponent={AgentUserPartLoader}>
      <AgentNotificationsActionsProvider>
        <AgentUserPart />
      </AgentNotificationsActionsProvider>
    </AgentNotificationsProvider>
  );
}
