import { Typography } from '@src/components/appearance/basics/Typography';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { Structure } from '@src/components/appearance/structure/Structure';
import { TertiaryNav } from '@src/components/appearance/structure/TertiaryNav';
import { NextOrderResultsEmptyAnnouncement } from '@src/components/mixins/announcements/NextOrderResultsEmptyAnnouncement';
import { NextOrderProductCardLoader } from '@src/components/mixins/cards/NextOrderProductCard';
import { ProductPickerProductCard } from '@src/components/mixins/cards/ProductPickerProductCard';
import { ProductPickerChrome } from '@src/components/mixins/chromes/ProductPickerChrome';
import {
  ProductPickerDrawerPanel,
  ProductPickerDrawerPanelLoader,
} from '@src/components/mixins/drawers/ProductPickerProductDrawerPanel';
import type { TAgentPublicCatalogProductBaseFragment } from '@src/gen/graphql/bindings';
import { ESource, PREFERRED_SOURCES, getSource, maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TAgentProductsSearchConfig } from '@src/modules/data/agent/global/operations/AgentProductsSearchProvider';
import {
  AgentProductsSearchProvider,
  useAgentProductsSearch,
} from '@src/modules/data/agent/global/operations/AgentProductsSearchProvider';
import {
  AgentPublicCatalogProductProvider,
  useAgentPublicCatalogProduct,
} from '@src/modules/data/agent/global/operations/AgentPublicCatalogProductProvider';
import { InlineChrome, useInlineChrome } from '@src/modules/design/ChromeProvider';
import { CONTAINER_MEDIA_DESKTOP } from '@src/modules/design/breakpoints';
import type { TEmptyObject } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TAgentProductPickerChromeTypes = {
  BeginProductPickArgs: {
    showPreferredCatalogsOption: boolean;
    restrictSourceOptionsTo: { [key in ESource]?: true } | null;
    initialSearchQuery: string | null;
    callback: (publicCatalogProduct: TAgentPublicCatalogProductBaseFragment) => void;
  };
  BeginProductPick: (args: TAgentProductPickerChromeTypes['BeginProductPickArgs']) => void;
};

export type TAgentProductPickerChromeContext = {
  beginProductPick: TAgentProductPickerChromeTypes['BeginProductPick'];
};

export const { Context: AgentProductPickerChromeContext, useContext: useAgentProductPickerChrome } =
  createRequiredContext<TAgentProductPickerChromeContext>();

export function AgentProductPickerChromeProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const inlineChrome = useInlineChrome<TAgentProductPickerChromeTypes['BeginProductPickArgs']>();

  const beginProductPick = useCallback<TAgentProductPickerChromeTypes['BeginProductPick']>(
    (args) => {
      inlineChrome.doChromeOpen({
        metadata: args,
        drawerChildren: (
          <Panel
            showPreferredCatalogsOption={args.showPreferredCatalogsOption}
            restrictSourceOptionsTo={args.restrictSourceOptionsTo}
            initialSearchQuery={args.initialSearchQuery}
            onSelect={(publicCatalogProduct: TAgentPublicCatalogProductBaseFragment): void => {
              args.callback(publicCatalogProduct);
              inlineChrome.doChromeClose();
            }}
          />
        ),
      });
    },
    [inlineChrome],
  );

  const value = useMemo<TAgentProductPickerChromeContext>(
    () => ({
      beginProductPick,
    }),
    [beginProductPick],
  );

  return (
    <AgentProductPickerChromeContext.Provider value={value}>
      <InlineChrome {...inlineChrome} />
      {children}
    </AgentProductPickerChromeContext.Provider>
  );
}

type TPanel = {
  showPreferredCatalogsOption: boolean;
  restrictSourceOptionsTo: { [key in ESource]?: true } | null;
  initialSearchQuery: string | null;
  onSelect: TAgentProductPickerChromeTypes['BeginProductPickArgs']['callback'];
};

function Panel({
  showPreferredCatalogsOption,
  restrictSourceOptionsTo,
  initialSearchQuery,
  onSelect,
}: TPanel): JSX.Element {
  const allowableAllSources = useMemo<ESource[]>(
    () =>
      Object.values(ESource)
        .map((s) => getSource(s))
        .filter((s) => s !== ESource.TEST && (!isDef(restrictSourceOptionsTo) || isDef(restrictSourceOptionsTo[s]))),
    [restrictSourceOptionsTo],
  );

  const allowablePreferredSources = useMemo<ESource[]>(
    () =>
      Object.keys(PREFERRED_SOURCES)
        .map((s) => getSource(s))
        .filter((s) => !isDef(restrictSourceOptionsTo) || isDef(restrictSourceOptionsTo[s])),
    [restrictSourceOptionsTo],
  );

  const [viewingProductId, setViewingProductId] = useState<string | null>(null);

  const [config, setConfig] = useState<
    Omit<TAgentProductsSearchConfig, 'allowableSources'> & { sourceMode: 'all' | 'preferred' }
  >({
    sourceMode: 'all',
    categoryName01Filter: null,
    categoryName02Filter: null,
    manufacturerNameFilter: null,
    productListIdFilter: null,
    query: initialSearchQuery,
    sourceFilter: null,
  });

  const handleOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setViewingProductId(null);
    }
  }, []);

  return (
    <ProductPickerChrome.Panel>
      <ProductPickerChrome.SearchAndFilters
        showPreferredCatalogsOption={showPreferredCatalogsOption && allowablePreferredSources.length > 0}
        config={config}
        setConfig={setConfig}
      />
      <AgentProductsSearchProvider
        LoaderComponent={ProductPickerChrome.GridLoader}
        config={{
          ...config,
          allowableSources: config.sourceMode === 'all' ? allowableAllSources : allowablePreferredSources,
        }}>
        <Products setViewingProductId={setViewingProductId} config={config} setConfig={setConfig} />
      </AgentProductsSearchProvider>
      <Drawer.Root onOpenChange={handleOpenChange} open={isDef(viewingProductId)} level='top'>
        {isDef(viewingProductId) && (
          <AgentPublicCatalogProductProvider
            LoaderComponent={ProductPickerDrawerPanelLoader}
            publicCatalogProductId={viewingProductId}>
            <Product onSelect={onSelect} setViewingProductId={setViewingProductId} />
          </AgentPublicCatalogProductProvider>
        )}
      </Drawer.Root>
    </ProductPickerChrome.Panel>
  );
}

type TProducts = {
  setViewingProductId: (productId: string) => void;
  config: Omit<TAgentProductsSearchConfig, 'allowableSources'> & { sourceMode: 'all' | 'preferred' };
  setConfig: Dispatch<
    SetStateAction<Omit<TAgentProductsSearchConfig, 'allowableSources'> & { sourceMode: 'all' | 'preferred' }>
  >;
};

function Products({ setViewingProductId, config, setConfig }: TProducts): JSX.Element {
  const { searchResponse, loaderRef } = useAgentProductsSearch();

  const hasAggrs =
    isDef(searchResponse.sourceAggregation) ||
    isDef(searchResponse.categoryName01Aggregation) ||
    isDef(searchResponse.categoryName02Aggregation) ||
    isDef(searchResponse.manufacturerNameAggregation);

  if (!hasAggrs) {
    return (
      <Structure.ScrollContent fullWidth={true}>
        {searchResponse.results.length === 0 ? (
          <NextOrderResultsEmptyAnnouncement />
        ) : (
          <Structure.Grid>
            {searchResponse.results.map((result) => (
              <ProductPickerProductCard
                key={result.legacyProduct.id}
                onClick={(): void => setViewingProductId(result.legacyProduct.id)}
                publicCatalogProduct={result.legacyProduct}
              />
            ))}
            {isDef(loaderRef) && <NextOrderProductCardLoader hasQuantity={true} ref={loaderRef} />}
          </Structure.Grid>
        )}
      </Structure.ScrollContent>
    );
  }

  return (
    <Structure.ScrollContent fullWidth={true}>
      <Structure.Columns sideChildren={<Filters config={config} setConfig={setConfig} />}>
        <Structure.Grid>
          {searchResponse.results.length === 0 && <NextOrderResultsEmptyAnnouncement />}
          {searchResponse.results.map((result) => (
            <ProductPickerProductCard
              key={result.legacyProduct.id}
              onClick={(): void => setViewingProductId(result.legacyProduct.id)}
              publicCatalogProduct={result.legacyProduct}
            />
          ))}
          {isDef(loaderRef) && <NextOrderProductCardLoader hasQuantity={true} ref={loaderRef} />}
        </Structure.Grid>
      </Structure.Columns>
    </Structure.ScrollContent>
  );
}

type TProduct = {
  onSelect: TAgentProductPickerChromeTypes['BeginProductPickArgs']['callback'];
  setViewingProductId: (productId: string) => void;
};

function Product({ onSelect, setViewingProductId }: TProduct): JSX.Element {
  const { publicCatalogProduct } = useAgentPublicCatalogProduct();
  return (
    <ProductPickerDrawerPanel
      onChangeVariant={setViewingProductId}
      onSelect={(): void => onSelect(publicCatalogProduct)}
      product={publicCatalogProduct}
    />
  );
}

const SFiltersDiv = styled('div', {
  display: 'none',

  [CONTAINER_MEDIA_DESKTOP]: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '24px',
    hideScrollbars: true,
    overflow: 'scroll',
    padding: '0px',
  },
});

const SFiltersInnerDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  alignItems: 'stretch',
});

type TFilters = {
  config: Omit<TAgentProductsSearchConfig, 'allowableSources'> & { sourceMode: 'all' | 'preferred' };
  setConfig: Dispatch<
    SetStateAction<Omit<TAgentProductsSearchConfig, 'allowableSources'> & { sourceMode: 'all' | 'preferred' }>
  >;
};

function Filters({ setConfig }: TFilters): JSX.Element {
  const { searchResponse } = useAgentProductsSearch();

  return (
    <SFiltersDiv>
      {isDef(searchResponse.sourceAggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Catalog' css={{ paddingBottom: '4px' }} />
          {searchResponse.sourceAggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                setConfig((prevConfig) => ({
                  ...prevConfig,
                  sourceFilter: bucket.value,
                }))
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
      {isDef(searchResponse.categoryName01Aggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Category' css={{ paddingBottom: '4px' }} />
          {searchResponse.categoryName01Aggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                setConfig((prevConfig) => ({
                  ...prevConfig,
                  categoryName01Filter: bucket.value,
                }))
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
      {isDef(searchResponse.categoryName02Aggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Sub-Category' css={{ paddingBottom: '4px' }} />
          {searchResponse.categoryName02Aggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                setConfig((prevConfig) => ({
                  ...prevConfig,
                  categoryName02Filter: bucket.value,
                }))
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
      {isDef(searchResponse.manufacturerNameAggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Manufacturer' css={{ paddingBottom: '4px' }} />
          {searchResponse.manufacturerNameAggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                setConfig((prevConfig) => ({
                  ...prevConfig,
                  manufacturerNameFilter: bucket.value,
                }))
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
    </SFiltersDiv>
  );
}
