import { NextOrderProductCard } from '@src/components/mixins/cards/NextOrderProductCard';
import type { TCustomerPublicCatalogProductBaseFragment } from '@src/gen/graphql/bindings';
import {
  useCustomerNextOrderActions,
  useCustomerNextOrderActionsCartProductIsSubstitutionAllowed,
  useCustomerNextOrderActionsCartProductQuantity,
} from '@src/modules/data/customer/nextOrder/CustomerNextOrderActionsProvider';
import { useCustomerNextOrderCartProduct } from '@src/modules/data/customer/nextOrder/CustomerNextOrderProvider';
import { useCallback } from 'react';

export type TCustomerNextOrderProductCardPart = {
  product: TCustomerPublicCatalogProductBaseFragment;
  eventLocation: 'cart' | 'drawer' | 'history' | 'formulary' | 'catalogs';
};

export function CustomerNextOrderProductCardPart({
  product,
  eventLocation,
}: TCustomerNextOrderProductCardPart): JSX.Element {
  const cartProduct = useCustomerNextOrderCartProduct(product.id);
  const { beginPublicCatalogProductView } = useCustomerNextOrderActions();

  const quantity = useCustomerNextOrderActionsCartProductQuantity({
    cartProduct: cartProduct ?? null,
    publicCatalogProduct: product,
    eventLocation: eventLocation,
  });

  const isSubstitutionAllowed = useCustomerNextOrderActionsCartProductIsSubstitutionAllowed({
    cartProduct: cartProduct ?? null,
  });

  const handleClick = useCallback(
    () => beginPublicCatalogProductView({ publicCatalogProductId: product.id }),
    [beginPublicCatalogProductView, product.id],
  );

  return (
    <NextOrderProductCard
      publicCatalogProduct={product}
      quantity={quantity}
      isSubstitutionAllowed={isSubstitutionAllowed}
      onClick={handleClick}
    />
  );
}
