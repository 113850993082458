import type { TButtonAction } from '@src/components/appearance/controls/Button';
import { ActionButton } from '@src/components/appearance/controls/Button';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import { ItemButton } from '@src/components/appearance/fragments/ItemButton';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type {
  TAgentOrderBackOrderCompleteFragment,
  TCustomerOrderBackOrderCompleteFragment,
} from '@src/gen/graphql/bindings';
import { getCombinedProductName, getDateOn, getFullName, getSaleUnitQuantity } from '@src/gen/shared/data/snippets';
import {
  EOrderBackOrderDecision,
  maybeGetOrderBackOrderDecisionLabel,
} from '@src/gen/shared/enums/orderBackOrderDecision';
import { getSource, maybeGetSourceName } from '@src/gen/shared/enums/source';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderBackOrderCardBase = {
  beginOrderBackOrderProductView:
    | TAgentOrderActionsTypes['BeginOrderBackOrderProductView']
    | TCustomerOrderActionsTypes['BeginOrderBackOrderProductView'];
  onApplyAsyncNotify: (() => Promise<void>) | null;
  onDecisionAsyncNotify: ((decision: EOrderBackOrderDecision) => Promise<void>) | null;
  onDeleteAsyncNotify: (() => Promise<void>) | null;
  orderBackOrder: TAgentOrderBackOrderCompleteFragment | TCustomerOrderBackOrderCompleteFragment;
  variant: 'agent' | 'customer';
};

export type TOrderBackOrderCard = TProps<false, TOrderBackOrderCardBase, 'div'>;
export const ORDER_BACK_ORDER_CARD_CLASS_NAME = 'wp-order-back-order-card';

export const OrderBackOrderCard = withCssToString(
  ORDER_BACK_ORDER_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderBackOrderCard>(
      (
        {
          beginOrderBackOrderProductView,
          onApplyAsyncNotify,
          onDecisionAsyncNotify,
          onDeleteAsyncNotify,
          orderBackOrder,
          variant,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_BACK_ORDER_CARD_CLASS_NAME), [className]);

        const items = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Back-Ordered Quantity',
              text: getSaleUnitQuantity(orderBackOrder.quantity, orderBackOrder.sale_unit),
            },
            {
              caption: 'Estimated Date',
              text: isDef(orderBackOrder.estimated_date)
                ? { asDate: 'date', at: orderBackOrder.estimated_date }
                : 'N/A',
            },
            {
              caption: 'Notified By',
              text: getFullName(orderBackOrder.creator),
            },
            {
              caption: 'Notified On',
              text: { asDate: 'on', at: orderBackOrder.created_at },
            },
          ],
          // @sort
          [
            orderBackOrder.created_at,
            orderBackOrder.creator,
            orderBackOrder.estimated_date,
            orderBackOrder.quantity,
            orderBackOrder.sale_unit,
          ],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () =>
            getAvailableIconButtonActions([
              {
                isAsync: true,
                icon: 'apply',
                onClick:
                  isDef(onApplyAsyncNotify) && isDef(orderBackOrder.replied_at) && !isDef(orderBackOrder.applied_at)
                    ? onApplyAsyncNotify
                    : undefined,
              },
              {
                isAsync: true,
                icon: 'trash',
                onClick:
                  isDef(onDeleteAsyncNotify) && !isDef(orderBackOrder.applied_at) ? onDeleteAsyncNotify : undefined,
              },
            ]),
          // @sort
          [onApplyAsyncNotify, onDeleteAsyncNotify, orderBackOrder.applied_at, orderBackOrder.replied_at],
        );

        const waitDecisionButtonAction = useMemo<TButtonAction>(
          () =>
            isDef(onDecisionAsyncNotify)
              ? {
                  isAsync: true,
                  onClick: async (): Promise<void> => await onDecisionAsyncNotify(EOrderBackOrderDecision.WAIT),
                  text: maybeGetOrderBackOrderDecisionLabel(EOrderBackOrderDecision.WAIT),
                }
              : {
                  isAsync: false,
                  onClick: noop,
                  text: maybeGetOrderBackOrderDecisionLabel(EOrderBackOrderDecision.WAIT),
                  variant: 'disabled',
                },
          [onDecisionAsyncNotify],
        );

        const removeDecisionButtonAction = useMemo<TButtonAction>(
          () =>
            isDef(onDecisionAsyncNotify)
              ? {
                  isAsync: true,
                  onClick: async (): Promise<void> => await onDecisionAsyncNotify(EOrderBackOrderDecision.REMOVE),
                  text: maybeGetOrderBackOrderDecisionLabel(EOrderBackOrderDecision.REMOVE),
                  variant: 'secondary',
                }
              : {
                  isAsync: false,
                  onClick: noop,
                  text: maybeGetOrderBackOrderDecisionLabel(EOrderBackOrderDecision.REMOVE),
                  variant: 'disabled',
                },
          [onDecisionAsyncNotify],
        );

        const flapText = useMemo<string>(
          () => {
            if (!isDef(orderBackOrder.replied_at)) {
              return 'Waiting on Customer';
            }

            if (!isDef(orderBackOrder.applied_at)) {
              return 'Waiting on Wellplaece';
            }

            return `Completed By ${getFullName(ensureDef(orderBackOrder.applier))} on ${getDateOn(
              ensureDef(orderBackOrder.applied_at),
            )}`;
          },
          // @sort
          [orderBackOrder.applied_at, orderBackOrder.applier, orderBackOrder.replied_at],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flapText={flapText}
            flush={true}
            iconButtonActions={iconButtonActions}
            interactive={false}
            ref={ref}>
            <Card.Title title='Back-Order' />
            <ItemButton
              key={orderBackOrder.id}
              variant='card'
              onClick={(): void => beginOrderBackOrderProductView({ orderBackOrder })}
              item={{
                caption: `${maybeGetSourceName(getSource(orderBackOrder.source))} · ${orderBackOrder.product_sku}`,
                text: getCombinedProductName(orderBackOrder.name, orderBackOrder.secondary_name),
              }}
            />
            <ItemsBanner items={items} />
            {isDef(orderBackOrder.replied_at) && (
              <ItemsBanner
                items={[
                  {
                    caption: 'Customer Decision',
                    text: maybeGetOrderBackOrderDecisionLabel(ensureDef(orderBackOrder.replied_decision)),
                  },
                  {
                    caption: 'Decided By',
                    text: getFullName(ensureDef(orderBackOrder.replier)),
                  },
                  {
                    caption: 'Decided On',
                    text: { asDate: 'on', at: ensureDef(orderBackOrder.replied_at) },
                  },
                ]}
              />
            )}
            {!isDef(orderBackOrder.replied_at) && variant === 'customer' && (
              <Card.Footer>
                <Card.Label text='Customer Decision' />
                <Card.Actions>
                  <ActionButton action={waitDecisionButtonAction} />
                  <ActionButton action={removeDecisionButtonAction} />
                </Card.Actions>
              </Card.Footer>
            )}
            {isDef(orderBackOrder.applied_at) && (
              <Banner icon='apply' accent='success' message='This back-order is completed.' />
            )}
          </Card.Container>
        );
      },
    ),
  ),
);
