import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { ControlButton, ControlButtonLoader } from '@src/components/appearance/controls/ControlButton';
import type { TDrawer } from '@src/components/appearance/structure/Drawer';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { TAgentOrganizationForPickerFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { forwardRef, memo, useMemo, useState } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

type TOrganizationPickerDrawerPanelBase = {
  buttonActionText?: string | undefined;
  drawerHeaderTitle?: string | undefined;
  loaderRef: UseInfiniteScrollHookRefCallback | null;
  onBack?: (() => void) | undefined;
  onSelectOrganization: (organization: TAgentOrganizationForPickerFragment) => Promise<void>;
  organizations: TAgentOrganizationForPickerFragment[];
};

export type TOrganizationPickerDrawerPanel = TProps<false, TOrganizationPickerDrawerPanelBase, 'div'>;
export const ORGANIZATION_PICKER_DRAWER_PANEL_CLASS_NAME = 'wp-organization-picker-drawer-panel';

export const OrganizationPickerDrawerPanel = withCssToString(
  ORGANIZATION_PICKER_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrganizationPickerDrawerPanel>(
      (
        {
          buttonActionText,
          drawerHeaderTitle,
          loaderRef,
          onBack,
          onSelectOrganization,
          organizations,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORGANIZATION_PICKER_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [selectedOrganization, setSelectedOrganization] = useState<TAgentOrganizationForPickerFragment | null>(
          null,
        );

        const buttonAction = useMemo<TDrawer['FooterBase']['buttonAction']>(
          () =>
            isDef(selectedOrganization)
              ? {
                  isAsync: true,
                  onClick: async (): Promise<void> => await onSelectOrganization(selectedOrganization),
                  text: buttonActionText ?? 'Select Organization',
                }
              : {
                  isAsync: false,
                  onClick: noop,
                  text: buttonActionText ?? 'Select Organization',
                  variant: 'disabled',
                },
          [buttonActionText, onSelectOrganization, selectedOrganization],
        );

        const secondaryButtonAction = useMemo<TDrawer['FooterBase']['secondaryButtonAction']>(
          () =>
            isDef(onBack)
              ? {
                  isAsync: false,
                  onClick: onBack,
                  text: 'Go Back',
                  variant: 'secondary',
                }
              : undefined,
          [onBack],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title={drawerHeaderTitle ?? 'Select Organization'} />
            <Drawer.ScrollContent>
              <Drawer.Group>
                {organizations.map((org) => (
                  <ControlButton
                    key={org.id}
                    icon={org.id === selectedOrganization?.id ? 'apply' : undefined}
                    onClick={(): void => setSelectedOrganization(org)}
                    text={org.name}
                  />
                ))}
              </Drawer.Group>
              {isDef(loaderRef) && <Illustrations.Spinner ref={loaderRef} />}
            </Drawer.ScrollContent>
            <Drawer.Footer buttonAction={buttonAction} secondaryButtonAction={secondaryButtonAction} />
          </Drawer.Panel>
        );
      },
    ),
  ),
);

export const OrganizationPickerDrawerPanelLoader = memo(
  forwardRef<HTMLDivElement, Pick<TOrganizationPickerDrawerPanelBase, 'buttonActionText' | 'drawerHeaderTitle'>>(
    ({ buttonActionText, drawerHeaderTitle }, ref): JSX.Element => (
      <Drawer.Panel ref={ref}>
        <Drawer.Header title={drawerHeaderTitle ?? 'Select Organization'} />
        <Drawer.ScrollContentLoader>
          <Drawer.Group>
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
          </Drawer.Group>
        </Drawer.ScrollContentLoader>
        <Drawer.FooterLoader buttonText={buttonActionText ?? 'Select Organization'} />
      </Drawer.Panel>
    ),
  ),
);
