import type { TButton } from '@src/components/appearance/controls/Button';
import { Button, getButtonVariant } from '@src/components/appearance/controls/Button';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import type { TLabeled } from '@src/components/appearance/fragments/Labeled';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import { CONTAINER_MEDIA_DESKTOP } from '@src/modules/design/breakpoints';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import type { ReactElement } from 'react';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$itemsBannerBackground',
  containerType: 'inline-size',
  display: 'flex',
  flexDirection: 'column',
  gap: '$itemsBannerGap',
  padding: '$itemsBannerPadding',
});

const SInnerDiv = styled('div', {
  alignItems: 'center',
  display: 'grid',
  gap: '$itemsBannerGap',
  gridTemplateColumns: 'auto',

  variants: {
    withButton: {
      true: {
        [CONTAINER_MEDIA_DESKTOP]: {
          gridTemplateColumns: 'auto $itemsBannerButtonWidth',
        },
      },
    },
  },
});

const SActionsDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '$itemsBannerGap',
});

const SChildrenDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$itemsBannerChildrenGap',
});

export type TItemsBannerBase = TItemsBase & {
  ButtonElement?: ReactElement<TButton> | undefined;
  LabeledElement?: ReactElement<TLabeled> | undefined;
};

export type TItemsBanner = TProps<true, TItemsBannerBase, 'div'>;
export const ITEMS_BANNER_CLASS_NAME = 'wp-items-banner';

export const ItemsBanner = withCssToString(
  ITEMS_BANNER_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TItemsBanner>(
      ({ ButtonElement, LabeledElement, items, className, children, ...rest }: TItemsBanner, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ITEMS_BANNER_CLASS_NAME), [className]);

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            <SInnerDiv withButton={isDef(ButtonElement)}>
              <Items items={items} />
              {(isDef(ButtonElement) || isDef(LabeledElement)) && (
                <SActionsDiv>
                  {ButtonElement}
                  {LabeledElement}
                </SActionsDiv>
              )}
            </SInnerDiv>
            {isDef(children) && <SChildrenDiv>{children}</SChildrenDiv>}
          </SDiv>
        );
      },
    ),
  ),
);

export const ItemsBannerLoader = memo(
  forwardRef<HTMLDivElement, { buttonText?: string | undefined; count: 2 | 4 | 8 }>(
    ({ buttonText, count }, ref): JSX.Element => (
      <SDiv ref={ref}>
        <SInnerDiv withButton={isDef(buttonText)}>
          <ItemsLoader count={count} />
          {isDef(buttonText) && (
            <Button
              variant={getButtonVariant({ base: 'default', disabled: true, loading: false })}
              onClick={noop}
              text={buttonText}
            />
          )}
        </SInnerDiv>
      </SDiv>
    ),
  ),
);
