import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { CategoryTree } from '@src/components/appearance/fragments/CategoryTree';
import { Description } from '@src/components/appearance/fragments/Description';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { AgentOrderSnapshotEntryManager } from '@src/gen/shared/data/agentOrders';
import type { CustomerOrderSnapshotEntryManager } from '@src/gen/shared/data/customerOrders';
import { castDescriptionUnsafe } from '@src/gen/shared/data/description';
import { castSpecsUnsafe } from '@src/gen/shared/data/specs';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { getSpecsItems, joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '256px',
});

export type TOrderSnapshotEntryBaseProductDrawerPanelBase = {
  orderSnapshotEntryManager: AgentOrderSnapshotEntryManager | CustomerOrderSnapshotEntryManager;
};

export type TOrderSnapshotEntryBaseProductDrawerPanel = TProps<
  false,
  TOrderSnapshotEntryBaseProductDrawerPanelBase,
  'div'
>;
export const ORDER_SNAPSHOT_ENTRY_BASE_PRODUCT_DRAWER_PANEL_CLASS_NAME =
  'wp-order-snapshot-entry-base-product-drawer-panel';

export const OrderSnapshotEntryBaseProductDrawerPanel = withCssToString(
  ORDER_SNAPSHOT_ENTRY_BASE_PRODUCT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderSnapshotEntryBaseProductDrawerPanel>(
      ({ orderSnapshotEntryManager, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_SNAPSHOT_ENTRY_BASE_PRODUCT_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const baseProduct = useMemo(() => orderSnapshotEntryManager.getBaseProduct(), [orderSnapshotEntryManager]);

        const itemsFirst = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(baseProduct.source),
            },
            {
              caption: 'SKU',
              text: baseProduct.product_sku,
            },
            {
              caption: 'Manufacturer',
              text: baseProduct.manufacturer_name,
            },
            {
              caption: 'Manufacturer SKU',
              text: baseProduct.manufacturer_sku,
            },
          ],
          // @sort
          [baseProduct.manufacturer_name, baseProduct.manufacturer_sku, baseProduct.product_sku, baseProduct.source],
        );

        const productDescriptionBlocks = useMemo(
          () => castDescriptionUnsafe(baseProduct.description),
          [baseProduct.description],
        );

        const specsItems = useMemo<TItemBaseItem[]>(
          () => getSpecsItems(castSpecsUnsafe(baseProduct.specs)),
          [baseProduct.specs],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Product' />
            <Drawer.ScrollContent>
              <SImageAsset alt={baseProduct.name} imageAssetPath={baseProduct.image_asset_path} />
              <Drawer.Separator />
              <Drawer.Group>
                <Drawer.Title subTitle={baseProduct.secondary_name} title={baseProduct.name} />
                <Items items={itemsFirst} />
              </Drawer.Group>
              <Drawer.Separator />
              <Drawer.Group title='Category'>
                <CategoryTree category={baseProduct.category_name} />
              </Drawer.Group>
              {productDescriptionBlocks.length > 0 && (
                <Drawer.Group title='Description'>
                  <Description description={productDescriptionBlocks} />
                </Drawer.Group>
              )}
              {specsItems.length > 0 && (
                <Drawer.Group title='Specifications'>
                  {specsItems.map((item) => (
                    <Item item={item} key={item.caption} />
                  ))}
                </Drawer.Group>
              )}
              {isDef(baseProduct.sds_asset_path) && (
                <Drawer.Group title='Documents'>
                  <ControlButton icon='download' text='Safety Data Sheet' />
                </Drawer.Group>
              )}
            </Drawer.ScrollContent>
          </Drawer.Panel>
        );
      },
    ),
  ),
);
