import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderBackOrderCard } from '@src/components/mixins/cards/OrderBackOrderCard';
import { EOrderBackOrderDecision } from '@src/gen/shared/enums/orderBackOrderDecision';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import { Fragment, useMemo } from 'react';

export function AgentOrderWorksheetBackOrdersPart(): JSX.Element {
  const { order, orderManager } = useAgentOrder();
  const {
    beginOrderBackOrderAdd,
    beginOrderBackOrderProductView,
    doOrderBackOrderApplyAsyncNotify,
    doOrderBackOrderRemoveAsyncNotify,
  } = useAgentOrderActions();

  const waitingForCustomerDecision = useMemo(
    () => order.order_back_orders.filter((obo) => !isDef(obo.replied_at) && !isDef(obo.applied_at)),
    [order.order_back_orders],
  );

  const waitingForRemoval = useMemo(
    () =>
      order.order_back_orders.filter(
        (obo) =>
          isDef(obo.replied_at) && obo.replied_decision === EOrderBackOrderDecision.REMOVE && !isDef(obo.applied_at),
      ),
    [order.order_back_orders],
  );

  const waitingForShipment = useMemo(
    () =>
      order.order_back_orders.filter(
        (obo) =>
          isDef(obo.replied_at) && obo.replied_decision === EOrderBackOrderDecision.WAIT && !isDef(obo.applied_at),
      ),
    [order.order_back_orders],
  );

  const applied = useMemo(
    () => order.order_back_orders.filter((obo) => isDef(obo.applied_at)),
    [order.order_back_orders],
  );

  if (!isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())) {
    return (
      <Structure.ScrollContent skipSeparator={true}>
        <Banner icon='info' message='Back-orders will be enabled once an invoice is issued.' />
      </Structure.ScrollContent>
    );
  }

  return (
    <Fragment>
      <Structure.Content>
        <ToolBar
          RightChildren={
            <Fragment>
              <ActionIconButton action={{ isAsync: false, icon: 'add', onClick: beginOrderBackOrderAdd }} />
            </Fragment>
          }
          variant='structure'></ToolBar>
      </Structure.Content>
      <Structure.ScrollContent skipSeparator={true}>
        <Structure.Stack>
          {order.order_back_orders.length === 0 && (
            <Banner icon='info' message='Click on the + button to add a back-order.' />
          )}
          {waitingForCustomerDecision.length > 0 && (
            <Structure.Group title='Waiting for Customer Decision' variant='secondary'>
              {waitingForCustomerDecision.map((obo) => (
                <OrderBackOrderCard
                  key={obo.id}
                  beginOrderBackOrderProductView={beginOrderBackOrderProductView}
                  onApplyAsyncNotify={async (): Promise<void> =>
                    await doOrderBackOrderApplyAsyncNotify({ orderBackOrderId: obo.id })
                  }
                  onDecisionAsyncNotify={null}
                  onDeleteAsyncNotify={async (): Promise<void> =>
                    await doOrderBackOrderRemoveAsyncNotify({ orderBackOrderId: obo.id })
                  }
                  orderBackOrder={obo}
                  variant='agent'
                />
              ))}
            </Structure.Group>
          )}
          {waitingForRemoval.length > 0 && (
            <Structure.Group title='Waiting for Removal from Order' variant='secondary'>
              {waitingForRemoval.map((obo) => (
                <OrderBackOrderCard
                  key={obo.id}
                  beginOrderBackOrderProductView={beginOrderBackOrderProductView}
                  onApplyAsyncNotify={async (): Promise<void> =>
                    await doOrderBackOrderApplyAsyncNotify({ orderBackOrderId: obo.id })
                  }
                  onDecisionAsyncNotify={null}
                  onDeleteAsyncNotify={async (): Promise<void> =>
                    await doOrderBackOrderRemoveAsyncNotify({ orderBackOrderId: obo.id })
                  }
                  orderBackOrder={obo}
                  variant='agent'
                />
              ))}
            </Structure.Group>
          )}
          {waitingForShipment.length > 0 && (
            <Structure.Group title='Waiting for Fulfillment' variant='secondary'>
              {waitingForShipment.map((obo) => (
                <OrderBackOrderCard
                  key={obo.id}
                  beginOrderBackOrderProductView={beginOrderBackOrderProductView}
                  onApplyAsyncNotify={async (): Promise<void> =>
                    await doOrderBackOrderApplyAsyncNotify({ orderBackOrderId: obo.id })
                  }
                  onDecisionAsyncNotify={null}
                  onDeleteAsyncNotify={async (): Promise<void> =>
                    await doOrderBackOrderRemoveAsyncNotify({ orderBackOrderId: obo.id })
                  }
                  orderBackOrder={obo}
                  variant='agent'
                />
              ))}
            </Structure.Group>
          )}
          {applied.length > 0 && (
            <Structure.Group title='Completed' variant='secondary'>
              {applied.map((obo) => (
                <OrderBackOrderCard
                  key={obo.id}
                  beginOrderBackOrderProductView={beginOrderBackOrderProductView}
                  onApplyAsyncNotify={async (): Promise<void> =>
                    await doOrderBackOrderApplyAsyncNotify({ orderBackOrderId: obo.id })
                  }
                  onDecisionAsyncNotify={null}
                  onDeleteAsyncNotify={async (): Promise<void> =>
                    await doOrderBackOrderRemoveAsyncNotify({ orderBackOrderId: obo.id })
                  }
                  orderBackOrder={obo}
                  variant='agent'
                />
              ))}
            </Structure.Group>
          )}
        </Structure.Stack>
      </Structure.ScrollContent>
    </Fragment>
  );
}
