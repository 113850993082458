import { Drawer } from '@src/components/appearance/structure/Drawer';
import { UserCustomerAddForm } from '@src/components/mixins/forms/UserCustomerAddForm';
import type { AgentCustomersUserCustomerCreateMutationVariables } from '@src/gen/graphql/bindings';
import { useAgentCustomersUserCustomerCreateMutation } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useAuthAgent } from '@src/modules/auth/AuthProvider';
import { useDrawer } from '@src/modules/design/DrawerProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { encodeAgentCustomersUserOverviewPath } from '@src/modules/routing/agent';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export type TAgentCustomersUsersActionsTypes = {
  BeginUserCustomerAdd: () => void;
  DoCustomersUserCustomerCreateAsync: (args: AgentCustomersUserCustomerCreateMutationVariables) => Promise<void>;
};

export type TAgentCustomersUsersActionsContext = {
  beginUserCustomerAdd: TAgentCustomersUsersActionsTypes['BeginUserCustomerAdd'] | null;
};

export const { Context: AgentCustomersUsersActionsContext, useContext: useAgentCustomersUsersActions } =
  createRequiredContext<TAgentCustomersUsersActionsContext>();

export function AgentCustomersUsersActionsProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const user = useAuthAgent();

  const beginUserCustomerAdd = useBeginUserCustomerAdd();

  const value = useMemo<TAgentCustomersUsersActionsContext>(
    () => ({
      beginUserCustomerAdd: user.agent_can_create_users ? beginUserCustomerAdd : null,
    }),
    // @sort
    [beginUserCustomerAdd, user.agent_can_create_users],
  );

  return (
    <AgentCustomersUsersActionsContext.Provider value={value}>{children}</AgentCustomersUsersActionsContext.Provider>
  );
}

function useBeginUserCustomerAdd(): TAgentCustomersUsersActionsTypes['BeginUserCustomerAdd'] {
  const [customersUserCustomerCreateMutation] = useAgentCustomersUserCustomerCreateMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const navigate = useNavigate();

  const doCustomersUserCustomerCreateAsync = useCallback<
    TAgentCustomersUsersActionsTypes['DoCustomersUserCustomerCreateAsync']
  >(
    async (args) => {
      const { errors, data } = await customersUserCustomerCreateMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
      navigate(encodeAgentCustomersUserOverviewPath(ensureDef(data?.agent_user_customer_create_v2?.id)));
    },
    // @sort
    [customersUserCustomerCreateMutation, doDrawerClose, navigate],
  );

  return useCallback<TAgentCustomersUsersActionsTypes['BeginUserCustomerAdd']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Add User (Customer)' />
          <Drawer.ScrollContent>
            <UserCustomerAddForm doCustomersUserCustomerCreateAsync={doCustomersUserCustomerCreateAsync} />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersUserCustomerCreateAsync, doDrawerOpen],
  );
}
