import type { CheckboxProps as RxCheckboxCheckboxProps } from '@radix-ui/react-checkbox';
import { Root as RxCheckboxRoot } from '@radix-ui/react-checkbox';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Control } from '@src/components/appearance/controls/Control';
import { THEME_COLORS } from '@src/gen/shared/utils/design';
import { styled } from '@src/modules/design/theme';
import { useField } from 'react-final-form';

export type TCheckBoxBase = Required<Pick<RxCheckboxCheckboxProps, 'checked' | 'onCheckedChange'>>;

export const CheckBox = styled(RxCheckboxRoot, {
  all: 'unset',

  backgroundColor: '$checkboxBackground',
  backgroundPosition: 'top 50% right 50%',
  backgroundRepeat: 'no-repeat',
  borderColor: '$checkboxBorder',
  borderRadius: '$checkbox',
  borderStyle: '$regular',
  borderWidth: '$regular',
  cursor: 'pointer',
  flexShrink: 0,
  height: '$checkboxSize',
  width: '$checkboxSize',

  '&:hover': {
    borderColor: '$checkboxHoverBorder',
  },

  '&:focus': {
    boxShadow: '$secondaryFocus',
  },

  '&[data-state=checked]': {
    backgroundImage: Illustrations.getCheckDataUrl(THEME_COLORS.Black),
  },

  '&[disabled]': {
    borderColor: '$checkboxDisabledBorder',
    borderStyle: '$disabled',
    cursor: 'not-allowed',

    '&[data-state=checked]': {
      backgroundImage: Illustrations.getCheckDataUrl(THEME_COLORS.Gray3),
    },
  },
});

export type TCheckBoxControl = {
  id: string;
  label?: string | undefined;
  disabled?: boolean | undefined;
};

export function CheckBoxControl({ id, label, disabled }: TCheckBoxControl): JSX.Element {
  const {
    input: { onBlur, onChange, onFocus, value },
    meta: { submitting },
  } = useField<boolean>(id, {
    subscription: {
      submitting: true,
      value: true,
    },
  });

  return (
    <Control id={id} label={label}>
      <CheckBox
        checked={value}
        disabled={disabled === true || submitting}
        id={id}
        onBlur={onBlur}
        onCheckedChange={onChange}
        onFocus={onFocus}
      />
    </Control>
  );
}
