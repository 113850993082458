import type {
  TCustomerOrderBaseFragment,
  TCustomerOrderCompleteFragment,
  TCustomerOrderSnapshotBaseFragment,
  TCustomerOrderSnapshotCompleteFragment,
  TCustomerOrderSnapshotEntryBaseFragment,
  TCustomerPublicCatalogProductBaseFragment,
} from '@src/gen/graphql/bindings';
import { EOrderEntryPlanStatus } from '@src/gen/shared/enums/orderEntryPlanStatus';
import { EOrderSnapshotType } from '@src/gen/shared/enums/orderSnapshotType';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';

export type TCustomerOrderManagerOrder = Pick<
  TCustomerOrderBaseFragment,
  'id' | 'latest_order_snapshot_id' | 'location_id' | 'reference_number' | 'status' | 'version'
> &
  Pick<TCustomerOrderCompleteFragment, 'location' | 'order_snapshots'>;

export class CustomerOrderManager {
  public readonly orderSnapshotManagers: CustomerOrderSnapshotManager[];

  public constructor(public readonly order: TCustomerOrderManagerOrder) {
    this.orderSnapshotManagers = order.order_snapshots.map((op) => new CustomerOrderSnapshotManager(op));
  }

  public hasSnapshotInvoice(): boolean {
    return isDef(this.orderSnapshotManagers.find((osm) => osm.orderSnapshot.type === EOrderSnapshotType.INVOICE));
  }

  public hasSnapshotPrebillApproved(): boolean {
    return isDef(
      this.orderSnapshotManagers.find(
        (osm) => osm.orderSnapshot.type === EOrderSnapshotType.PREBILL && isDef(osm.orderSnapshot.approved_at),
      ),
    );
  }

  public hasSnapshotPrebillRejected(): boolean {
    return isDef(
      this.orderSnapshotManagers.find(
        (osm) => osm.orderSnapshot.type === EOrderSnapshotType.PREBILL && isDef(osm.orderSnapshot.rejected_at),
      ),
    );
  }

  public hasSnapshotPrebillPending(): boolean {
    return isDef(
      this.orderSnapshotManagers.find(
        (osm) =>
          osm.orderSnapshot.type === EOrderSnapshotType.PREBILL &&
          !isDef(osm.orderSnapshot.approved_at) &&
          !isDef(osm.orderSnapshot.rejected_at),
      ),
    );
  }

  public mustGetOrderSnapshot(orderSnapshotId: string): CustomerOrderSnapshotManager {
    return ensureDef(this.orderSnapshotManagers.find((os) => os.orderSnapshot.id === orderSnapshotId));
  }

  public mustGetOrderSnapshotPrebillPending(): CustomerOrderSnapshotManager {
    return ensureDef(
      this.orderSnapshotManagers.find(
        (os) =>
          os.orderSnapshot.type === EOrderSnapshotType.PREBILL &&
          !isDef(os.orderSnapshot.approved_at) &&
          !isDef(os.orderSnapshot.rejected_at),
      ),
    );
  }

  public maybeGetOrderSnapshotInvoiceLatest(): CustomerOrderSnapshotManager | null {
    if (!isDef(this.order.latest_order_snapshot_id)) {
      return null;
    }

    return (
      this.orderSnapshotManagers.find(
        (os) =>
          os.orderSnapshot.id === this.order.latest_order_snapshot_id &&
          os.orderSnapshot.type === EOrderSnapshotType.INVOICE,
      ) ?? null
    );
  }
}

export class CustomerOrderSnapshotManager {
  public readonly orderSnapshotEntryManagers: CustomerOrderSnapshotEntryManager[];

  public constructor(public readonly orderSnapshot: TCustomerOrderSnapshotCompleteFragment) {
    this.orderSnapshotEntryManagers = orderSnapshot.order_snapshot_entries.map(
      (ope) => new CustomerOrderSnapshotEntryManager(this, ope),
    );
  }

  public maybeGetEstimateAfterCustomerEdits(): Pick<
    TCustomerOrderSnapshotBaseFragment,
    'wellplaece_sub_total' | 'wellplaece_fees' | 'wellplaece_tax' | 'wellplaece_total' | 'wellplaece_donation'
  > | null {
    if (!this.isEditedByCustomer()) {
      return null;
    }

    const planSubTotal = this.getPlanSubTotalAfterCustomerEdits();

    if (planSubTotal === 0) {
      return {
        wellplaece_sub_total: 0,
        wellplaece_fees: 0,
        wellplaece_tax: 0,
        wellplaece_donation: 0,
        wellplaece_total: 0,
      };
    }

    const proportion = planSubTotal / this.orderSnapshot.wellplaece_sub_total;

    const estimate = {
      wellplaece_sub_total: planSubTotal,
      wellplaece_fees: Math.floor(this.orderSnapshot.wellplaece_fees * proportion),
      wellplaece_tax: Math.floor(this.orderSnapshot.wellplaece_tax * proportion),
      wellplaece_donation: Math.floor(this.orderSnapshot.wellplaece_donation * proportion),
      wellplaece_total: 0,
    };

    estimate.wellplaece_total =
      estimate.wellplaece_sub_total + estimate.wellplaece_fees + estimate.wellplaece_tax + estimate.wellplaece_donation;

    return estimate;
  }

  public getPlanSubTotalAfterCustomerEdits(): number {
    let planSubTotal = 0;

    for (const osem of this.orderSnapshotEntryManagers) {
      if (osem.isPurchasedByWellplaece()) {
        planSubTotal += osem.mustGetPlanSubTotal();
      }
    }

    return planSubTotal;
  }

  public isEditedByCustomer(): boolean {
    return isDef(this.orderSnapshotEntryManagers.find((osem) => osem.isEditedByCustomer()));
  }
}

export class CustomerOrderSnapshotEntryManager {
  public constructor(
    public readonly orderSnapshotManager: CustomerOrderSnapshotManager,
    public readonly orderSnapshotEntry: TCustomerOrderSnapshotEntryBaseFragment,
  ) {
    // intentionally empty
  }

  public isPlanned(): boolean {
    return this.orderSnapshotEntry.plan_status !== EOrderEntryPlanStatus.NONE;
  }

  public isEditedByCustomer(): boolean {
    return isDef(this.orderSnapshotEntry.customer_edited_plan_quantity);
  }

  public isPurchasedByCustomer(): boolean {
    return (
      this.orderSnapshotEntry.plan_status === EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE ||
      this.orderSnapshotEntry.plan_status ===
        EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED ||
      this.orderSnapshotEntry.plan_status ===
        EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION
    );
  }

  public isPurchasedByWellplaece(): boolean {
    switch (this.orderSnapshotEntry.plan_status) {
      case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
      case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
        return true;
      default:
        return false;
    }
  }

  public isRemoved(): boolean {
    switch (this.orderSnapshotEntry.plan_status) {
      case EOrderEntryPlanStatus.REMOVED_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.REMOVED_REASON_OUT_OF_STOCK:
      case EOrderEntryPlanStatus.REMOVED_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_REQUESTED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
      case EOrderEntryPlanStatus.REMOVED_REASON_RETURNED:
        return true;
      default:
        return false;
    }
  }

  public isReady(): boolean {
    switch (this.orderSnapshotEntry.plan_status) {
      case EOrderEntryPlanStatus.NONE:
        return false;
      case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
      case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
      case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
        return (
          isDef(this.orderSnapshotEntry.unit_price) &&
          isDef(this.orderSnapshotEntry.plan_quantity) &&
          isDef(this.orderSnapshotEntry.plan_unit_price)
        );
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
      case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
        return isDef(this.orderSnapshotEntry.unit_price);
      default:
        return true;
    }
  }

  public getBaseProduct(): TCustomerPublicCatalogProductBaseFragment {
    return {
      __typename: 'public_catalog_products',
      category_name: this.orderSnapshotEntry.category_name,
      category_path: this.orderSnapshotEntry.category_path,
      description: this.orderSnapshotEntry.description,
      id: this.orderSnapshotEntry.product_id,
      image_asset_path: this.orderSnapshotEntry.image_asset_path,
      is_discontinued: this.orderSnapshotEntry.is_discontinued,
      is_house_brand: this.orderSnapshotEntry.is_house_brand,
      manufacturer_name: this.orderSnapshotEntry.manufacturer_name,
      manufacturer_sku: this.orderSnapshotEntry.manufacturer_sku,
      name: this.orderSnapshotEntry.name,
      product_page_url: this.orderSnapshotEntry.product_page_url,
      product_sku: this.orderSnapshotEntry.product_sku,
      sale_unit: this.orderSnapshotEntry.sale_unit,
      sds_asset_path: this.orderSnapshotEntry.sds_asset_path,
      secondary_name: this.orderSnapshotEntry.secondary_name,
      simplified_category: this.orderSnapshotEntry.simplified_category,
      source: this.orderSnapshotEntry.source,
      specs: this.orderSnapshotEntry.specs,
    };
  }

  public mustGetPlanProduct(): TCustomerPublicCatalogProductBaseFragment {
    return ensureDef(this.maybeGetPlanProduct());
  }

  public maybeGetPlanProduct(): TCustomerPublicCatalogProductBaseFragment | null {
    if (isDef(this.orderSnapshotEntry.plan_product_id)) {
      return {
        __typename: 'public_catalog_products',
        category_name: ensureDef(this.orderSnapshotEntry.plan_category_name),
        category_path: ensureDef(this.orderSnapshotEntry.plan_category_path),
        description: this.orderSnapshotEntry.plan_description,
        id: this.orderSnapshotEntry.plan_product_id,
        image_asset_path: this.orderSnapshotEntry.plan_image_asset_path,
        is_discontinued: ensureDef(this.orderSnapshotEntry.plan_is_discontinued),
        is_house_brand: ensureDef(this.orderSnapshotEntry.plan_is_house_brand),
        manufacturer_name: this.orderSnapshotEntry.plan_manufacturer_name,
        manufacturer_sku: this.orderSnapshotEntry.plan_manufacturer_sku,
        name: ensureDef(this.orderSnapshotEntry.plan_name),
        product_page_url: ensureDef(this.orderSnapshotEntry.plan_product_page_url),
        product_sku: ensureDef(this.orderSnapshotEntry.plan_product_sku),
        sale_unit: this.orderSnapshotEntry.plan_sale_unit,
        sds_asset_path: this.orderSnapshotEntry.plan_sds_asset_path,
        secondary_name: this.orderSnapshotEntry.plan_secondary_name,
        simplified_category: ensureDef(this.orderSnapshotEntry.plan_simplified_category),
        source: ensureDef(this.orderSnapshotEntry.plan_source),
        specs: this.orderSnapshotEntry.plan_specs,
      };
    }

    return null;
  }

  public mustGetBaseSubTotal(): number {
    return ensureDef(this.maybeGetBaseSubTotal());
  }

  public maybeGetBaseSubTotal(): number | null {
    return isDef(this.orderSnapshotEntry.unit_price)
      ? this.orderSnapshotEntry.quantity * this.orderSnapshotEntry.unit_price
      : null;
  }

  public mustGetPlanSubTotal(): number {
    return ensureDef(this.maybeGetPlanSubTotal());
  }

  public maybeGetPlanSubTotal(): number | null {
    return (isDef(this.orderSnapshotEntry.customer_edited_plan_quantity) ||
      isDef(this.orderSnapshotEntry.plan_quantity)) &&
      isDef(this.orderSnapshotEntry.plan_unit_price)
      ? (this.orderSnapshotEntry.customer_edited_plan_quantity ?? ensureDef(this.orderSnapshotEntry.plan_quantity)) *
          this.orderSnapshotEntry.plan_unit_price
      : null;
  }
}
