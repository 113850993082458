import assert from 'assert';

export enum EDescriptionBlockType {
  FEATURES = 'features',
  PARAGRAPH = 'paragraph',
  TITLE = 'title',
}

export type TDescriptionBlockFeatures = {
  type: EDescriptionBlockType.FEATURES;
  features: string[];
};

export type TDescriptionBlockParagraph = {
  type: EDescriptionBlockType.PARAGRAPH;
  paragraph: string;
};

export type TDescriptionBlockTitle = {
  type: EDescriptionBlockType.TITLE;
  title: string;
};

export type TDescriptionBlock = TDescriptionBlockFeatures | TDescriptionBlockParagraph | TDescriptionBlockTitle;
export type TDescription = TDescriptionBlock[];

export function castDescriptionUnsafe(description: unknown): TDescription {
  assert(Array.isArray(description));
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return description as TDescription;
}
