import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import { ItemButton } from '@src/components/appearance/fragments/ItemButton';
import { ItemsLoader } from '@src/components/appearance/fragments/Items';
import type {
  TAgentOrderShipmentCompleteFragment,
  TCustomerOrderShipmentCompleteFragment,
} from '@src/gen/graphql/bindings';
import { getCombinedProductName } from '@src/gen/shared/data/snippets';
import { getSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderShipmentCardBase = {
  beginOrderShipmentProductView:
    | TAgentOrderActionsTypes['BeginOrderShipmentProductView']
    | TCustomerOrderActionsTypes['BeginOrderShipmentProductView'];
  beginOrderTrackingHistoryView: (args: { ship24TrackerId: string }) => void;
  onDeleteAsyncNotify: (() => Promise<void>) | null;
  onSetDeliveredAsyncNotify: (() => Promise<void>) | null;
  orderShipment: TAgentOrderShipmentCompleteFragment | TCustomerOrderShipmentCompleteFragment;
};

export type TOrderShipmentCard = TProps<false, TOrderShipmentCardBase, 'div'>;
export const ORDER_SHIPMENT_CARD_CLASS_NAME = 'wp-order-shipment-card';

export const OrderShipmentCard = withCssToString(
  ORDER_SHIPMENT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderShipmentCard>(
      (
        {
          beginOrderShipmentProductView,
          beginOrderTrackingHistoryView,
          onDeleteAsyncNotify,
          onSetDeliveredAsyncNotify,
          orderShipment,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_SHIPMENT_CARD_CLASS_NAME), [className]);

        const iconButtonActions = useMemo(
          () =>
            getAvailableIconButtonActions([
              {
                isAsync: true,
                icon: 'apply',
                onClick:
                  isDef(onSetDeliveredAsyncNotify) && !orderShipment.is_delivered
                    ? onSetDeliveredAsyncNotify
                    : undefined,
              },
              {
                isAsync: true,
                icon: 'trash',
                onClick: onDeleteAsyncNotify ?? undefined,
              },
            ]),
          // @sort
          [onDeleteAsyncNotify, onSetDeliveredAsyncNotify, orderShipment.is_delivered],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flush={true}
            iconButtonActions={iconButtonActions}
            interactive={false}
            ref={ref}>
            <Card.Title title='Fulfilled By' subTitle={getSourceName(orderShipment.source)} />
            {orderShipment.order_shipment_products.map((osp) => (
              <ItemButton
                key={osp.id}
                variant='card'
                onClick={(): void => beginOrderShipmentProductView({ orderShipmentProduct: osp })}
                item={{
                  caption: `Qty. ${osp.quantity} of ${osp.product_sku}`,
                  text: getCombinedProductName(osp.name, osp.secondary_name),
                }}
              />
            ))}
            {orderShipment.is_delivered && (
              <Banner icon='apply' accent='success' message='This shipment is delivered.' />
            )}
            <Card.Footer>
              <Card.Label text='Tracking Numbers' />
              <Card.Actions>
                {orderShipment.order_shipment_tracking_numbers.map((ostn) => (
                  <ControlButton
                    key={ostn.id}
                    icon='link'
                    text={ostn.tracking_number}
                    onClick={(): void => {
                      if (isDef(ostn.ship24_tracker_id)) {
                        beginOrderTrackingHistoryView({ ship24TrackerId: ostn.ship24_tracker_id });
                      }
                    }}
                    variant={
                      isDef(ostn.ship24_tracker_id) ? (isDef(ostn.delivered_at) ? 'success' : 'default') : 'disabled'
                    }
                  />
                ))}
              </Card.Actions>
            </Card.Footer>
          </Card.Container>
        );
      },
    ),
  ),
);

export const OrderShipmentCardLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Card.Container ref={ref}>
        <Card.TitleLoader />
        <ItemsLoader count={4} />
      </Card.Container>
    ),
  ),
);
