import { Fragment } from 'react';
import { useField } from 'react-final-form';

export type TConditionalControl<T> = {
  subscription: string;
  predicate: (value: T) => boolean;
  render: (value: T) => JSX.Element;
};

export function ConditionalControl<T>({ subscription, predicate, render }: TConditionalControl<T>): JSX.Element {
  const {
    input: { value },
  } = useField<T>(subscription, { subscription: { value: true } });

  return predicate(value) ? render(value) : <Fragment />;
}
