import { Toast } from '@src/components/appearance/structure/Toast';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { PropsWithChildren, ReactElement } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

export type TToastTypes = {
  DoToastOpen: (newToastChildren: ReactElement) => void;
  DoToastClose: () => void;
};

export type TToastContext = {
  doToastOpen: TToastTypes['DoToastOpen'];
  doToastClose: TToastTypes['DoToastClose'];
};

export const { Context: ToastContext, useContext: useToast } = createRequiredContext<TToastContext>();

export function ToastProvider({ children }: PropsWithChildren): JSX.Element {
  const [toastChildren, setToastChildren] = useState<ReactElement | undefined>(undefined);
  const timerRef = useRef(0);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setToastChildren(undefined);
    }
  }, []);

  const value = useMemo<TToastContext>(
    () => ({
      doToastOpen: (newToastChildren: ReactElement): void => {
        setToastChildren(undefined);
        clearTimeout(timerRef.current);
        // @ts-expect-error Weird mix-up with Node types.
        timerRef.current = setTimeout(() => setToastChildren(newToastChildren), 100);
      },
      doToastClose: () => setToastChildren(undefined),
    }),
    [],
  );

  return (
    <ToastContext.Provider value={value}>
      <Toast.Root onOpenChange={handleOpenChange} open={toastChildren !== undefined}>
        {toastChildren}
      </Toast.Root>
      {children}
    </ToastContext.Provider>
  );
}
