import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item, ItemLoader } from '@src/components/appearance/fragments/Item';
import type { TTuple } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import { CONTAINER_MEDIA_DESKTOP, CONTAINER_MEDIA_TABLET } from '@src/modules/design/breakpoints';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  containerType: 'inline-size',
});

const SInnerDiv = styled('div', {
  display: 'grid',
  gap: '$itemsGap',
  gridTemplateColumns: 'repeat(1, 1fr)',

  [CONTAINER_MEDIA_TABLET]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  variants: {
    bannerAligned: {
      false: {
        // intentionally empty
      },
      true: {
        paddingX: '$itemsBannerPadding',
      },
    },
    large: {
      true: {
        [CONTAINER_MEDIA_DESKTOP]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
        },
      },
    },
  },
});

export type TItemsBase = {
  bannerAligned?: boolean | undefined;
  items: TTuple<TItemBaseItem, 2> | TTuple<TItemBaseItem, 3> | TTuple<TItemBaseItem, 4> | TTuple<TItemBaseItem, 8>;
};

export type TItems = TProps<false, TItemsBase, 'div'>;
export const ITEMS_CLASS_NAME = 'wp-items';

export const Items = withCssToString(
  ITEMS_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TItems>(({ bannerAligned, items, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, ITEMS_CLASS_NAME), [className]);

      return (
        <SDiv {...rest} className={joinedClassName} ref={ref}>
          <SInnerDiv bannerAligned={bannerAligned === true} large={items.length > 2}>
            {items.map((item, i) => (
              <Item
                item={item}
                key={item.caption}
                rigid={true}
                css={{ gridColumn: items.length === 3 && i === 0 ? 'span 2' : undefined }}
              />
            ))}
          </SInnerDiv>
        </SDiv>
      );
    }),
  ),
);

export const ItemsLoader = memo(
  forwardRef<HTMLDivElement, { count: 2 | 4 | 8 }>(
    ({ count }, ref): JSX.Element => (
      <SDiv ref={ref}>
        <SInnerDiv large={count > 2}>
          {new Array(count).fill(0).map((v, i) => (
            <ItemLoader key={i} />
          ))}
        </SInnerDiv>
      </SDiv>
    ),
  ),
);
