import type { Reference } from '@apollo/client';
import { isReference } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import add from 'date-fns/add';

export const DEFAULT_LIMIT = 25;
export const FUTURE_DATE = add(new Date(), { years: 25 });
export const PAST_DATE = add(new Date(), { years: -25 });

export function mergeGetMore<T extends { id: string }>(existing: T[], incoming: T[]): T[] {
  const seen: { [key: string]: true } = {};
  const merged: T[] = [];

  for (const items of [existing, incoming]) {
    for (const item of items) {
      if (!isDef(seen[item.id])) {
        merged.push(item);
        seen[item.id] = true;
      }
    }
  }

  return merged;
}

export type TMergeStringId = {
  id: string;
};

export function mergeHasMoreDesc(
  existing: TMergeStringId[] | undefined,
  incoming: TMergeStringId[] | undefined,
  pageSize: number,
): boolean {
  if (!isDef(incoming) || incoming.length < pageSize) {
    return false;
  }

  if (!isDef(existing) || existing.length === 0) {
    return true;
  }

  return ensureDef(existing[existing.length - 1]).id !== ensureDef(incoming[incoming.length - 1]).id;
}

export type TMergeRefsCompare = (first: Reference, second: Reference, readField: ReadFieldFunction) => number;
export type TMergeRefsDeduplicate = (refs: Reference[]) => Reference[];

export function assertRefs(maybeRefs: unknown[]): asserts maybeRefs is Reference[] {
  for (const maybeRef of maybeRefs) {
    assert(isReference(maybeRef));
  }
}

export function getMergeRefsCompareByStringFieldDesc(fieldName: string): TMergeRefsCompare {
  return (ref1, ref2, readField) =>
    (readField<string>(fieldName, ref2) ?? '').localeCompare(readField<string>(fieldName, ref1) ?? '');
}

export function mergeRefsDeduplicateAfter(refs: Reference[]): Reference[] {
  const seen: { [key: string]: true } = {};

  return refs.filter((ref) => {
    if (seen[ref.__ref] === undefined) {
      seen[ref.__ref] = true;
      return true;
    }
    return false;
  });
}

export function mergeRefsUnsafe(
  existing: unknown[] | undefined,
  incoming: unknown[] | undefined,
  readField: ReadFieldFunction,
  compare: TMergeRefsCompare,
  deduplicate: TMergeRefsDeduplicate,
): Reference[] {
  existing = existing ?? [];
  incoming = incoming ?? [];

  assertRefs(existing);
  assertRefs(incoming);

  return deduplicate([...existing, ...incoming].sort((ref1, ref2) => compare(ref1, ref2, readField)));
}
