import { NetworkStatus } from '@apollo/client';
import { ensureDef, ensureNotEmptyString, isDef } from '@src/gen/shared/utils/types';
import { DEFAULT_ROOT_MARGIN } from '@src/modules/design/breakpoints';
import { DEFAULT_LIMIT } from '@src/modules/graphql/utils';
import assert from 'assert';
import set from 'lodash/set';
import type { Context } from 'react';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';
import useInfiniteScroll from 'react-infinite-scroll-hook';

export function createRequiredContext<T>(): {
  Context: Context<T | undefined>;
  useContext: () => T;
} {
  const context = createContext<T | undefined>(undefined);

  return {
    Context: context,
    useContext: (): T => ensureDef(useContext(context)),
  };
}

export function ensureStringToQuantity(value: unknown): number {
  const s = ensureNotEmptyString(value);
  assert(/^\d*$/.test(s), `Not a number: "${s}".`);
  assert(s === '0' || !s.startsWith('0'), `Leading zeroes: "${s}".`);
  const n = parseInt(s, 10);
  assert(n >= 0, `Negative number: "${s}".`);
  assert(!Number.isNaN(n) && Number.isFinite(n), `Not a number: "${s}".`);
  assert(Number.isSafeInteger(n), `Not a safe integer: "${s}.`);
  return n;
}

export function useRender(): () => void {
  const [, setState] = useState({});
  const renderRef = useRef(() => setState({}));
  return renderRef.current;
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export type TIntelligentInfiniteScroll = {
  itemsCount: number | undefined;
  networkStatus: NetworkStatus;
  getMoreAsync: () => Promise<number>;
};

export function useIntelligentInfiniteScroll({
  itemsCount,
  networkStatus,
  getMoreAsync,
}: TIntelligentInfiniteScroll): UseInfiniteScrollHookRefCallback | null {
  const [hasNextPage, setHasNextPage] = useState(true);
  const prevItemsCount = usePrevious(itemsCount);
  const prevNetworkStatus = usePrevious(networkStatus);

  const onLoadMoreAsync = useCallback(
    async () => {
      if ((await getMoreAsync()) < DEFAULT_LIMIT) {
        setHasNextPage(false);
      }
    },
    // @sort
    [getMoreAsync],
  );

  const onLoadMore = useCallback(
    () => void onLoadMoreAsync(),
    // @sort
    [onLoadMoreAsync],
  );

  useEffect(
    () => {
      if (prevItemsCount === undefined && itemsCount !== undefined && itemsCount < DEFAULT_LIMIT) {
        setHasNextPage(false);
      }

      if (prevItemsCount !== undefined && itemsCount !== undefined && prevItemsCount > itemsCount) {
        setHasNextPage(true);
      }
    },
    // @sort
    [itemsCount, prevItemsCount],
  );

  useEffect(() => {
    if (prevNetworkStatus !== NetworkStatus.setVariables && networkStatus === NetworkStatus.setVariables) {
      setHasNextPage(true);
    }
  }, [networkStatus, prevNetworkStatus]);

  const [loaderRef] = useInfiniteScroll({
    loading: networkStatus === NetworkStatus.fetchMore,
    hasNextPage,
    onLoadMore,
    rootMargin: DEFAULT_ROOT_MARGIN,
  });

  return hasNextPage ? loaderRef : null;
}

export function withCssToString<T extends object>(className: string, component: T): T {
  className = `.${className}`;
  set(component, 'toString', (): string => className);
  return component;
}

export function openUrl(url: string): void {
  window.open(url, '_blank');
}

export function maybeOpenUrls(urls: (string | null)[]): void {
  for (const url of urls) {
    if (isDef(url)) {
      openUrl(url);
    }
  }
}

export function downloadUrl(url: string): void {
  window.open(url, '_self');
}
