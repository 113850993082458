import { OrganizationFormularyProductCard } from '@src/components/mixins/cards/OrganizationFormularyProductCard';
import type { TAgentPublicCatalogProductBaseFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentCustomersOrganizationActions } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import { useCallback } from 'react';

export type TAgentCustomersOrganizationFormularyProductCardPart = {
  product: TAgentPublicCatalogProductBaseFragment;
};

export function AgentCustomersOrganizationFormularyProductCardPart({
  product,
}: TAgentCustomersOrganizationFormularyProductCardPart): JSX.Element {
  const { beginPublicCatalogProductView, beginFormularyProductRemove } = useAgentCustomersOrganizationActions();

  const handleClick = useCallback(
    () => beginPublicCatalogProductView({ publicCatalogProductId: product.id }),
    [beginPublicCatalogProductView, product.id],
  );

  return (
    <OrganizationFormularyProductCard
      publicCatalogProduct={product}
      onClick={handleClick}
      onRemove={
        isDef(beginFormularyProductRemove)
          ? (): void =>
              beginFormularyProductRemove({
                publicCatalogProductId: product.id,
                name: product.name,
                secondaryName: product.secondary_name,
              })
          : null
      }
    />
  );
}
