import { Avatar } from '@src/components/appearance/basics/Avatar';
import { PrimaryNav } from '@src/components/appearance/structure/PrimaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { getFullName } from '@src/gen/shared/data/snippets';
import { useAuthAgent } from '@src/modules/auth/AuthProvider';
import { useConfig } from '@src/modules/config/ConfigProvider';
import {
  EAgentRoutes,
  encodeAgentBackOrdersPath,
  encodeAgentCustomersOrganizationsPath,
  encodeAgentCustomersUsersPath,
  encodeAgentMetricsPath,
  encodeAgentOrdersPath,
  encodeAgentReturnsPath,
  encodeAgentUserPath,
  getAgentRoutePageTitle,
  useAgentRouteParams,
} from '@src/modules/routing/agent';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentNavPart(): JSX.Element {
  const agent = useAuthAgent();
  const navigate = useNavigate();
  const routeParams = useAgentRouteParams();
  const { WP_STAGE } = useConfig();
  const pageTitle = useMemo(() => getAgentRoutePageTitle(routeParams.route, agent), [routeParams.route, agent]);

  const message = useMemo(
    () => `Welcome back, ${agent.first_name} ${agent.last_name}, Wellplaece Agent.`,
    // @sort
    [agent.first_name, agent.last_name],
  );

  const isOrdersActive =
    routeParams.route === EAgentRoutes.AGENT_ORDERS ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_SUMMARY ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS ||
    routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS;

  const isCustomersOrganizationsActive =
    routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATIONS ||
    routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW ||
    routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS ||
    routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS ||
    routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY ||
    routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS;

  const isCustomersUsersActive =
    routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_USERS ||
    routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW ||
    routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS;

  const isMetricsActive = routeParams.route === EAgentRoutes.AGENT_METRICS;

  const handleOrdersClick = useCallback(
    () => {
      navigate(encodeAgentOrdersPath());
    },
    // @sort
    [navigate],
  );

  const handleBackOrdersClick = useCallback(
    () => {
      navigate(encodeAgentBackOrdersPath());
    },
    // @sort
    [navigate],
  );

  const handleReturnsClick = useCallback(
    () => {
      navigate(encodeAgentReturnsPath());
    },
    // @sort
    [navigate],
  );

  const handleCustomersOrganizationsClick = useCallback(
    () => {
      navigate(encodeAgentCustomersOrganizationsPath());
    },
    // @sort
    [navigate],
  );

  const handleCustomersUsersClick = useCallback(
    () => {
      navigate(encodeAgentCustomersUsersPath());
    },
    // @sort
    [navigate],
  );

  const handleMetricsClick = useCallback(
    () => navigate(encodeAgentMetricsPath()),
    // @sort
    [navigate],
  );

  const handleUserClick = useCallback(
    () => navigate(encodeAgentUserPath()),
    // @sort
    [navigate],
  );

  return (
    <Structure.Nav
      message={message}
      pageTitle={pageTitle}
      footerChildren={
        <PrimaryNav.Item
          avatar={<Avatar firstName={agent.first_name} lastName={agent.last_name} />}
          active={routeParams.route === EAgentRoutes.AGENT_USER}
          onClick={handleUserClick}
          title={getFullName(agent)}
        />
      }>
      <PrimaryNav.Item active={isOrdersActive} onClick={handleOrdersClick} title='Orders' />
      <PrimaryNav.Item
        active={routeParams.route === EAgentRoutes.AGENT_BACK_ORDERS}
        onClick={handleBackOrdersClick}
        title='Back-Orders'
      />
      <PrimaryNav.Item
        active={routeParams.route === EAgentRoutes.AGENT_RETURNS}
        onClick={handleReturnsClick}
        title='Returns'
      />
      <PrimaryNav.Item
        active={isCustomersOrganizationsActive}
        onClick={handleCustomersOrganizationsClick}
        title='Organizations'
      />
      <PrimaryNav.Item active={isCustomersUsersActive} onClick={handleCustomersUsersClick} title='Users' />
      {WP_STAGE === 'prod' && <PrimaryNav.Item active={isMetricsActive} onClick={handleMetricsClick} title='Metrics' />}
    </Structure.Nav>
  );
}
