import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EOrderSnapshotType {
  PREBILL = 'prebill',
  INVOICE = 'invoice',
  BACKFILL_INVOICE = 'backfill_invoice',
}

const ORDER_SNAPSHOT_TYPE_LOOKUP = mapValues(invert(EOrderSnapshotType), () => true);

export function getOrderSnapshotType(rawOrderSnapshotType: string): EOrderSnapshotType {
  assert(ORDER_SNAPSHOT_TYPE_LOOKUP[rawOrderSnapshotType], `Unknown order snapshot type: "${rawOrderSnapshotType}".`);
  return rawOrderSnapshotType as EOrderSnapshotType; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetOrderSnapshotType(rawOrderSnapshotType: string): EOrderSnapshotType | undefined {
  try {
    return getOrderSnapshotType(rawOrderSnapshotType);
  } catch {
    return undefined;
  }
}

export const ORDER_SNAPSHOT_TYPE_LABELS: { [key in EOrderSnapshotType]: string } = {
  [EOrderSnapshotType.PREBILL]: 'Prebill',
  [EOrderSnapshotType.INVOICE]: 'Invoice',
  [EOrderSnapshotType.BACKFILL_INVOICE]: 'Invoice',
};

export function maybeGetOrderSnapshotTypeLabel(rawOrderSnapshotType: string): string {
  const orderSnapshotType = maybeGetOrderSnapshotType(rawOrderSnapshotType);
  return orderSnapshotType !== undefined
    ? ensureDef(ORDER_SNAPSHOT_TYPE_LABELS[orderSnapshotType])
    : rawOrderSnapshotType;
}
