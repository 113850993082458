import type { EOrderAttachmentType } from '@src/gen/shared/enums/orderAttachmentType';
import type { EOrderBackOrderDecision } from '@src/gen/shared/enums/orderBackOrderDecision';
import type { EOrderPaymentType } from '@src/gen/shared/enums/orderPaymentType';
import type { EOrderSnapshotType } from '@src/gen/shared/enums/orderSnapshotType';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum ENotificationType {
  ORDER_PLACED = 'order_placed',
  ORDER_UPDATED = 'order_updated',
  ORDER_CANCELED = 'order_canceled',
  ORDER_ATTACHMENT_ADDED = 'order_attachment_added',
  ORDER_ATTACHMENT_REMOVED = 'order_attachment_removed',
  ORDER_TRACKING_NUMBER_ADDED = 'order_tracking_number_added',
  ORDER_TRACKING_NUMBER_REMOVED = 'order_tracking_number_removed',
  ORDER_MESSAGE_SENT = 'order_message_sent',
  ORDER_SNAPSHOT_ISSUED = 'order_snapshot_issued',
  ORDER_SNAPSHOT_APPROVED = 'order_snapshot_approved',
  ORDER_SNAPSHOT_REJECTED = 'order_snapshot_rejected',
  ORDER_SHIPMENT_ADDED = 'order_shipment_added',
  ORDER_SHIPMENT_REMOVED = 'order_shipment_removed',
  ORDER_BACK_ORDER_ADDED = 'order_back_order_added',
  ORDER_BACK_ORDER_REPLIED = 'order_back_order_replied',
  ORDER_BACK_ORDER_APPLIED = 'order_back_order_applied',
  ORDER_BACK_ORDER_REMOVED = 'order_back_order_removed',
  ORDER_RETURN_ADDED = 'order_return_added',
  ORDER_RETURN_REPLIED = 'order_return_replied',
  ORDER_RETURN_EXECUTED = 'order_return_executed',
  ORDER_RETURN_APPLIED = 'order_return_applied',
  ORDER_RETURN_REMOVED = 'order_return_removed',
  ORDER_PAYMENT_ADDED = 'order_payment_added',
  ORDER_PAYMENT_REMOVED = 'order_payment_removed',
}

const NOTIFICATION_TYPE_LOOKUP = mapValues(invert(ENotificationType), () => true);

export function getNotificationType(rawNotificationType: string): ENotificationType {
  assert(NOTIFICATION_TYPE_LOOKUP[rawNotificationType], `Unknown notification type: "${rawNotificationType}".`);
  return rawNotificationType as ENotificationType; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetNotificationType(rawNotificationType: string): ENotificationType | undefined {
  try {
    return getNotificationType(rawNotificationType);
  } catch {
    return undefined;
  }
}

export type TNotificationUser = {
  id: string;
  firstName: string;
  lastName: string;
  isWellplaeceAgent: boolean;
};

export type TNotificationOrganization = {
  id: string;
  name: string;
};

export type TNotificationLocation = {
  id: string;
  name: string;
  orderCode: string;
};

export type TNotificationOrder = {
  id: string;
  referenceNumber: number;
  isEmergency?: boolean;
};

export type TNotificationOrderUpdates = {
  status?:
    | {
        from: string;
        to: string;
      }
    | undefined;
  isBlockedOnCustomer?:
    | {
        from: boolean;
        to: boolean;
      }
    | undefined;
  hasBackOrderedProducts?:
    | {
        from: boolean;
        to: boolean;
      }
    | undefined;
  hasPendingReturns?:
    | {
        from: boolean;
        to: boolean;
      }
    | undefined;
  isPaid?:
    | {
        from: boolean;
        to: boolean;
      }
    | undefined;
};

export type TNotificationOrderAttachment = {
  uploadId: string;
  type: EOrderAttachmentType;
  description: string | null;
  revision: number;
};

export type TNotificationOrderTrackingNumber = {
  trackingNumber: string;
};

export type TNotificationOrderMessage = {
  chatContentSummary: string;
};

export type TNotificationOrderSnapshot = {
  id: string;
  type: EOrderSnapshotType;
  revision: number;
  rejectedReasonSummary: string | null;
};

export type TNotificationOrderShipment = {
  id: string;
  uniqueItemsCount: number;
  trackingNumbers: string[];
};

export type TNotificationOrderBackOrder = {
  id: string;
  productName: string;
  quantity: number;
  estimatedDate: string | null;
  repliedDecision: EOrderBackOrderDecision | null;
};

export type TNotificationOrderReturn = {
  id: string;
  productName: string;
  quantity: number;
  skipReturn?: boolean | undefined;
};

export type TNotificationOrderPayment = {
  id: string;
  type: EOrderPaymentType;
  amount: number;
};

export type TNotification =
  | TNotificationOrderAttachmentAdded
  | TNotificationOrderAttachmentRemoved
  | TNotificationOrderBackOrderActions
  | TNotificationOrderCanceled
  | TNotificationOrderMessageSent
  | TNotificationOrderPaymentActions
  | TNotificationOrderPlaced
  | TNotificationOrderReturnActions
  | TNotificationOrderShipmentActions
  | TNotificationOrderSnapshotActions
  | TNotificationOrderTrackingNumberAdded
  | TNotificationOrderTrackingNumberRemoved
  | TNotificationOrderUpdated;

export function castNotificationUnsafe(notification: unknown): TNotification {
  assert(typeof notification === 'object');
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return notification as TNotification;
}

export type TNotificationOrderPlaced = {
  type: ENotificationType.ORDER_PLACED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  at: string;
};

export type TNotificationOrderUpdated = {
  type: ENotificationType.ORDER_UPDATED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderUpdates: TNotificationOrderUpdates;
  at: string;
};

export type TNotificationOrderCanceled = {
  type: ENotificationType.ORDER_CANCELED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  at: string;
};

export type TNotificationOrderAttachmentAdded = {
  type: ENotificationType.ORDER_ATTACHMENT_ADDED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderAttachment: TNotificationOrderAttachment;
  at: string;
};

export type TNotificationOrderAttachmentRemoved = {
  type: ENotificationType.ORDER_ATTACHMENT_REMOVED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderAttachment: TNotificationOrderAttachment;
  at: string;
};

export type TNotificationOrderTrackingNumberAdded = {
  type: ENotificationType.ORDER_TRACKING_NUMBER_ADDED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderTrackingNumber: TNotificationOrderTrackingNumber;
  at: string;
};

export type TNotificationOrderTrackingNumberRemoved = {
  type: ENotificationType.ORDER_TRACKING_NUMBER_REMOVED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderTrackingNumber: TNotificationOrderTrackingNumber;
  at: string;
};

export type TNotificationOrderMessageSent = {
  type: ENotificationType.ORDER_MESSAGE_SENT;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderMessage: TNotificationOrderMessage;
  at: string;
};

export type TNotificationOrderSnapshotActions = {
  type:
    | ENotificationType.ORDER_SNAPSHOT_APPROVED
    | ENotificationType.ORDER_SNAPSHOT_ISSUED
    | ENotificationType.ORDER_SNAPSHOT_REJECTED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderSnapshot: TNotificationOrderSnapshot;
  at: string;
};

export type TNotificationOrderShipmentActions = {
  type: ENotificationType.ORDER_SHIPMENT_ADDED | ENotificationType.ORDER_SHIPMENT_REMOVED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderShipment: TNotificationOrderShipment;
  at: string;
};

export type TNotificationOrderBackOrderActions = {
  type:
    | ENotificationType.ORDER_BACK_ORDER_ADDED
    | ENotificationType.ORDER_BACK_ORDER_APPLIED
    | ENotificationType.ORDER_BACK_ORDER_REMOVED
    | ENotificationType.ORDER_BACK_ORDER_REPLIED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderBackOrder: TNotificationOrderBackOrder;
  at: string;
};

export type TNotificationOrderReturnActions = {
  type:
    | ENotificationType.ORDER_RETURN_ADDED
    | ENotificationType.ORDER_RETURN_APPLIED
    | ENotificationType.ORDER_RETURN_EXECUTED
    | ENotificationType.ORDER_RETURN_REMOVED
    | ENotificationType.ORDER_RETURN_REPLIED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderReturn: TNotificationOrderReturn;
  at: string;
};

export type TNotificationOrderPaymentActions = {
  type: ENotificationType.ORDER_PAYMENT_ADDED | ENotificationType.ORDER_PAYMENT_REMOVED;
  user: TNotificationUser;
  organization: TNotificationOrganization;
  location: TNotificationLocation;
  order: TNotificationOrder;
  orderPayment: TNotificationOrderPayment;
  at: string;
};
