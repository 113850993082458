import { Structure } from '@src/components/appearance/structure/Structure';
import { NextOrderHistoryEmptyAnnouncement } from '@src/components/mixins/announcements/NextOrderHistoryEmptyAnnouncement';
import { NextOrderProductCardLoader } from '@src/components/mixins/cards/NextOrderProductCard';
import { CustomerNextOrderProductCardPart } from '@src/components/parts/customer/nextOrder/CustomerNextOrderProductCardPart';
import { isDef } from '@src/gen/shared/utils/types';
import { useCustomerNextOrderPastOrdersCatalogsFilter } from '@src/modules/data/customer/nextOrder/CustomerNextOrderHistoryProvider';

export function CustomerNextOrderHistoryPart(): JSX.Element {
  const { publicCatalogProducts, loaderRef } = useCustomerNextOrderPastOrdersCatalogsFilter();

  if (publicCatalogProducts.length === 0) {
    return <NextOrderHistoryEmptyAnnouncement />;
  }

  return (
    <Structure.ScrollContent>
      <Structure.Grid>
        {publicCatalogProducts.map((pcp) => (
          <CustomerNextOrderProductCardPart key={pcp.id} product={pcp} eventLocation='history' />
        ))}
        {isDef(loaderRef) && <NextOrderProductCardLoader hasQuantity={true} ref={loaderRef} />}
      </Structure.Grid>
    </Structure.ScrollContent>
  );
}

export function CustomerNextOrderHistoryPartLoader(): JSX.Element {
  return (
    <Structure.ScrollContentLoader>
      <Structure.Grid>
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
        <NextOrderProductCardLoader hasQuantity={false} />
      </Structure.Grid>
    </Structure.ScrollContentLoader>
  );
}
