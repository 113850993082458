import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { Button } from '@src/components/appearance/controls/Button';
import type { TCheckBoxBase } from '@src/components/appearance/controls/CheckBox';
import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import type { TQuantityBase } from '@src/components/appearance/controls/Quantity';
import { Quantity } from '@src/components/appearance/controls/Quantity';
import { Select } from '@src/components/appearance/controls/Select';
import { Annotated, AnnotatedLoader } from '@src/components/appearance/fragments/Annotated';
import { CategoryTree } from '@src/components/appearance/fragments/CategoryTree';
import { Description } from '@src/components/appearance/fragments/Description';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import { Labeled, LabeledLoader } from '@src/components/appearance/fragments/Labeled';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type {
  TCustomerPublicCatalogProductBaseFragment,
  TCustomerVariantProductBaseFragment,
} from '@src/gen/graphql/bindings';
import { castDescriptionUnsafe } from '@src/gen/shared/data/description';
import { getSaleUnitAnnotation } from '@src/gen/shared/data/snippets';
import { castSpecsUnsafe } from '@src/gen/shared/data/specs';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { getSpecsItems, joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TCustomerNextOrderActionsTypes } from '@src/modules/data/customer/nextOrder/CustomerNextOrderActionsProvider';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import type { ChangeEvent } from 'react';
import { Fragment, forwardRef, memo, useCallback, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '256px',
});

export type TNextOrderProductDrawerPanelBase = {
  isSubstitutionAllowed?: TCheckBoxBase | undefined;
  onChangeVariant: TCustomerNextOrderActionsTypes['BeginPublicCatalogProductProductView'];
  publicCatalogProduct: Pick<
    TCustomerPublicCatalogProductBaseFragment,
    | 'category_name'
    | 'description'
    | 'id'
    | 'image_asset_path'
    | 'is_discontinued'
    | 'manufacturer_name'
    | 'manufacturer_sku'
    | 'name'
    | 'product_sku'
    | 'sale_unit'
    | 'sds_asset_path'
    | 'secondary_name'
    | 'source'
    | 'specs'
  >;
  quantity?: TQuantityBase | undefined;
  variantProducts: TCustomerVariantProductBaseFragment[];
};

export type TNextOrderProductDrawerPanel = TProps<false, TNextOrderProductDrawerPanelBase, 'div'>;
export const NEXT_ORDER_PRODUCT_DRAWER_PANEL_CLASS_NAME = 'wp-next-order-product-drawer-panel';

export const NextOrderProductDrawerPanel = withCssToString(
  NEXT_ORDER_PRODUCT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNextOrderProductDrawerPanel>(
      (
        { isSubstitutionAllowed, onChangeVariant, publicCatalogProduct, quantity, variantProducts, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, NEXT_ORDER_PRODUCT_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const productItems = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(publicCatalogProduct.source),
            },
            {
              caption: 'SKU',
              text: publicCatalogProduct.product_sku,
            },
            {
              caption: 'Manufacturer',
              text: publicCatalogProduct.manufacturer_name ?? 'N/A',
            },
            {
              caption: 'Manufacturer SKU',
              text: publicCatalogProduct.manufacturer_sku ?? 'N/A',
            },
          ],
          // @sort
          [
            publicCatalogProduct.manufacturer_name,
            publicCatalogProduct.manufacturer_sku,
            publicCatalogProduct.product_sku,
            publicCatalogProduct.source,
          ],
        );

        const allowSubstitutionsId = useMemo(
          () => `${NEXT_ORDER_PRODUCT_DRAWER_PANEL_CLASS_NAME}-allow-substitutions-${publicCatalogProduct.id}`,
          [publicCatalogProduct.id],
        );

        const handleChangeVariant = useCallback(
          (e: ChangeEvent<HTMLSelectElement>) => onChangeVariant({ publicCatalogProductId: e.target.value }),
          [onChangeVariant],
        );

        const productDescriptionBlocks = useMemo(
          () => castDescriptionUnsafe(publicCatalogProduct.description),
          [publicCatalogProduct.description],
        );

        const specsItems = useMemo<TItemBaseItem[]>(
          () => getSpecsItems(castSpecsUnsafe(publicCatalogProduct.specs)),
          [publicCatalogProduct.specs],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Product' />
            <Drawer.ScrollContent>
              <SImageAsset alt={publicCatalogProduct.name} imageAssetPath={publicCatalogProduct.image_asset_path} />
              <Drawer.Separator />
              <Drawer.Group>
                <Drawer.Title subTitle={publicCatalogProduct.secondary_name} title={publicCatalogProduct.name} />
                <Items items={productItems} />
              </Drawer.Group>
              {(publicCatalogProduct.is_discontinued || isDef(quantity)) && (
                <Fragment>
                  <Drawer.Separator />
                  <Drawer.Group>
                    {publicCatalogProduct.is_discontinued && (
                      <Typography.Caption css={{ alignSelf: 'center' }} text='This product is no longer available.' />
                    )}
                    {!publicCatalogProduct.is_discontinued && isDef(quantity) && (
                      <Fragment>
                        {quantity.value > 0 || isDef(isSubstitutionAllowed) ? (
                          <Fragment>
                            <Annotated text={getSaleUnitAnnotation(publicCatalogProduct.sale_unit)}>
                              <Quantity {...quantity} />
                            </Annotated>
                            {isDef(isSubstitutionAllowed) && (
                              <Labeled htmlFor={allowSubstitutionsId} text='Allow Substitutions'>
                                <CheckBox id={allowSubstitutionsId} {...isSubstitutionAllowed} />
                              </Labeled>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Button onClick={(): void => quantity.onChange(1)} text='Add to Cart' />
                            <Typography.Annotation
                              css={{ alignSelf: 'center' }}
                              expanding={true}
                              rigid={true}
                              text={getSaleUnitAnnotation(publicCatalogProduct.sale_unit)}
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Drawer.Group>
                </Fragment>
              )}
              <Drawer.Separator />
              {variantProducts.length > 1 && (
                <Drawer.Group title='Variants'>
                  <Select onChange={handleChangeVariant} value={publicCatalogProduct.id}>
                    {variantProducts.map((vp) => (
                      <option key={vp.id} value={vp.id}>
                        {vp.name}
                        {vp.secondary_name !== null && ` · ${vp.secondary_name}`}
                      </option>
                    ))}
                  </Select>
                </Drawer.Group>
              )}
              <Drawer.Group title='Category'>
                <CategoryTree category={publicCatalogProduct.category_name} />
              </Drawer.Group>
              {productDescriptionBlocks.length > 0 && (
                <Drawer.Group title='Description'>
                  <Description description={productDescriptionBlocks} />
                </Drawer.Group>
              )}
              {specsItems.length > 0 && (
                <Drawer.Group title='Specifications'>
                  {specsItems.map((item) => (
                    <Item item={item} key={item.caption} />
                  ))}
                </Drawer.Group>
              )}
              {isDef(publicCatalogProduct.sds_asset_path) && (
                <Drawer.Group title='Documents'>
                  <ControlButton icon='download' text='Safety Data Sheet' />
                </Drawer.Group>
              )}
            </Drawer.ScrollContent>
          </Drawer.Panel>
        );
      },
    ),
  ),
);

const SLoaderTopDiv = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const NextOrderProductDrawerPanelLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Drawer.Panel ref={ref}>
        <Drawer.Header title='Product' />
        <Drawer.ScrollContentLoader>
          <SLoaderTopDiv>
            <Illustrations.Loader height={256} uniqueKey='wp-next-order-product-drawer-panel-loader-image' width={320}>
              <rect x='0' y='5' rx='0' ry='0' width='320' height='256' />
            </Illustrations.Loader>
          </SLoaderTopDiv>
          <Drawer.Separator />
          <Drawer.Group>
            <Drawer.TitleLoader />
            <ItemsLoader count={4} />
          </Drawer.Group>
          <Drawer.Separator />
          <Drawer.Group>
            <AnnotatedLoader>
              <Quantity disabled={true} onChange={noop} value={0} />
            </AnnotatedLoader>
            <LabeledLoader>
              <CheckBox disabled={true} />
            </LabeledLoader>
          </Drawer.Group>
        </Drawer.ScrollContentLoader>
      </Drawer.Panel>
    ),
  ),
);
