import { Typography } from '@src/components/appearance/basics/Typography';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { Chrome } from '@src/components/appearance/structure/Chrome';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderOriginalCartProductCardLoader } from '@src/components/mixins/cards/OrderOriginalCartProductCard';
import { ProductHintStructureGroup } from '@src/components/mixins/groups/ProductHintStructureGroup';
import type { TAgentProductHintBaseFragment } from '@src/gen/graphql/bindings';
import { AgentProductHintManager } from '@src/gen/shared/data/agentOrders';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import {
  AgentProductHintsProvider,
  useAgentProductHints,
} from '@src/modules/data/agent/global/operations/AgentProductHintsProvider';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { InlineChrome, useInlineChrome } from '@src/modules/design/ChromeProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { Fragment, useCallback, useMemo } from 'react';

export type TAgentProductHistoryPickerChromeTypes = {
  BeginProductHistoryPickArgs: {
    organizationId: string;
    locationId: string;
    productId: string;
    beginProductHintBaseProductView: TAgentOrderActionsTypes['BeginProductHintBaseProductView'];
    beginProductHintPlanProductView: TAgentOrderActionsTypes['BeginProductHintPlanProductView'];
    callback: (productHint: TAgentProductHintBaseFragment, mode: 'base' | 'both' | 'plan') => void;
  };
  BeginProductHistoryPick: (args: TAgentProductHistoryPickerChromeTypes['BeginProductHistoryPickArgs']) => void;
};

export type TAgentProductHistoryPickerChromeContext = {
  beginProductHistoryPick: TAgentProductHistoryPickerChromeTypes['BeginProductHistoryPick'];
};

export const { Context: AgentProductHistoryPickerChromeContext, useContext: useAgentProductHistoryPickerChrome } =
  createRequiredContext<TAgentProductHistoryPickerChromeContext>();

export function AgentProductHistoryPickerChromeProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const inlineChrome = useInlineChrome<TAgentProductHistoryPickerChromeTypes['BeginProductHistoryPickArgs']>();

  const beginProductHistoryPick = useCallback<TAgentProductHistoryPickerChromeTypes['BeginProductHistoryPick']>(
    (args) => {
      inlineChrome.doChromeOpen({
        metadata: args,
        drawerChildren: (
          <Panel
            beginProductHintBaseProductView={args.beginProductHintBaseProductView}
            beginProductHintPlanProductView={args.beginProductHintPlanProductView}
            organizationId={args.organizationId}
            locationId={args.locationId}
            productId={args.productId}
            onSelect={(productHint, mode): void => {
              args.callback(productHint, mode);
              inlineChrome.doChromeClose();
            }}
          />
        ),
      });
    },
    [inlineChrome],
  );

  const value = useMemo<TAgentProductHistoryPickerChromeContext>(
    () => ({
      beginProductHistoryPick,
    }),
    [beginProductHistoryPick],
  );

  return (
    <AgentProductHistoryPickerChromeContext.Provider value={value}>
      <InlineChrome {...inlineChrome} />
      {children}
    </AgentProductHistoryPickerChromeContext.Provider>
  );
}

type TPanel = {
  beginProductHintBaseProductView: TAgentOrderActionsTypes['BeginProductHintBaseProductView'];
  beginProductHintPlanProductView: TAgentOrderActionsTypes['BeginProductHintPlanProductView'];
  organizationId: string;
  locationId: string;
  productId: string;
  onSelect: TAgentProductHistoryPickerChromeTypes['BeginProductHistoryPickArgs']['callback'];
};

function Panel({
  beginProductHintBaseProductView,
  beginProductHintPlanProductView,
  organizationId,
  locationId,
  productId,
  onSelect,
}: TPanel): JSX.Element {
  return (
    <Chrome.Panel>
      <Chrome.Header title='Product History' />
      <AgentProductHintsProvider
        LoaderComponent={(): JSX.Element => (
          <Chrome.ScrollContentLoader>
            <Structure.GroupLoader hasTitle={true}>
              <OrderOriginalCartProductCardLoader />
              <OrderOriginalCartProductCardLoader />
              <OrderOriginalCartProductCardLoader />
              <OrderOriginalCartProductCardLoader />
              <OrderOriginalCartProductCardLoader />
              <OrderOriginalCartProductCardLoader />
              <OrderOriginalCartProductCardLoader />
              <OrderOriginalCartProductCardLoader />
              <OrderOriginalCartProductCardLoader />
              <OrderOriginalCartProductCardLoader />
            </Structure.GroupLoader>
          </Chrome.ScrollContentLoader>
        )}
        organizationId={organizationId}
        locationId={locationId}
        productId={productId}>
        <History
          beginProductHintBaseProductView={beginProductHintBaseProductView}
          beginProductHintPlanProductView={beginProductHintPlanProductView}
          onSelect={onSelect}
        />
      </AgentProductHintsProvider>
    </Chrome.Panel>
  );
}

type THistory = {
  beginProductHintBaseProductView: TAgentOrderActionsTypes['BeginProductHintBaseProductView'];
  beginProductHintPlanProductView: TAgentOrderActionsTypes['BeginProductHintPlanProductView'];
  onSelect: TAgentProductHistoryPickerChromeTypes['BeginProductHistoryPickArgs']['callback'];
};

function History({
  beginProductHintBaseProductView,
  beginProductHintPlanProductView,
  onSelect,
}: THistory): JSX.Element {
  const productHints = useAgentProductHints();

  return (
    <Chrome.ScrollContent>
      {productHints.forLocation.length === 0 &&
        productHints.forOrganization.length === 0 &&
        productHints.forOthers.length === 0 && (
          <Announcement css={{ paddingTop: '128px' }}>
            <Fragment>
              <Typography.SubTitle text='Nothing to See Here' />
              <Typography.Small text='There is no history for this product.' />
            </Fragment>
          </Announcement>
        )}
      {productHints.forLocation.length > 0 && (
        <Structure.Group title='For This Location' variant='secondary'>
          {productHints.forLocation.map((ph) => (
            <ProductHintStructureGroup
              key={ph.id}
              productHintManager={new AgentProductHintManager(ph)}
              beginProductHintBaseProductView={beginProductHintBaseProductView}
              beginProductHintPlanProductView={beginProductHintPlanProductView}
              onSelect={(mode): void => onSelect(ph, mode)}
            />
          ))}
        </Structure.Group>
      )}
      {productHints.forOrganization.length > 0 && (
        <Structure.Group title='For Other Locations' variant='secondary'>
          {productHints.forOrganization.map((ph) => (
            <ProductHintStructureGroup
              key={ph.id}
              productHintManager={new AgentProductHintManager(ph)}
              beginProductHintBaseProductView={beginProductHintBaseProductView}
              beginProductHintPlanProductView={beginProductHintPlanProductView}
              onSelect={(mode): void => onSelect(ph, mode)}
            />
          ))}
        </Structure.Group>
      )}
      {productHints.forOthers.length > 0 && (
        <Structure.Group title='For Other Customers' variant='secondary'>
          {productHints.forOrganization.map((ph) => (
            <ProductHintStructureGroup
              key={ph.id}
              productHintManager={new AgentProductHintManager(ph)}
              beginProductHintBaseProductView={beginProductHintBaseProductView}
              beginProductHintPlanProductView={beginProductHintPlanProductView}
              onSelect={(mode): void => onSelect(ph, mode)}
            />
          ))}
        </Structure.Group>
      )}
    </Chrome.ScrollContent>
  );
}
