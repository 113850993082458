import { Control } from '@src/components/appearance/controls/Control';
import { RawDatePicker } from '@src/components/appearance/controls/RawDatePicker';
import { newDatePickerValidator } from '@src/logic/internal/data/validation';
import { useMemo } from 'react';
import { useField } from 'react-final-form';

export type TDatePickerControl = {
  id: string;
  label?: string | undefined;
};

export function DatePickerControl({ id, label }: TDatePickerControl): JSX.Element {
  const {
    input: { onBlur, onChange, onFocus, value },
    meta: { error, submitting, visited }, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  } = useField<string>(id, {
    subscription: {
      error: true,
      submitting: true,
      value: true,
      visited: true,
    },
    validate: newDatePickerValidator(true),
  });

  const finalError = useMemo(
    () => (visited === true && typeof error === 'string' && error !== '' ? error : null),
    [error, visited],
  );

  return (
    <Control error={finalError} id={id} required={true} label={label}>
      <RawDatePicker
        disabled={submitting}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
    </Control>
  );
}
