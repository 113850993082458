import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { CategoryTree } from '@src/components/appearance/fragments/CategoryTree';
import { Description } from '@src/components/appearance/fragments/Description';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { TAgentCartProductCompleteFragment } from '@src/gen/graphql/bindings';
import { castDescriptionUnsafe } from '@src/gen/shared/data/description';
import { getSaleUnitQuantity } from '@src/gen/shared/data/snippets';
import { castSpecsUnsafe } from '@src/gen/shared/data/specs';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { getSpecsItems, joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '256px',
});

export type TCartProductDrawerPanelBase = {
  cartProduct: TAgentCartProductCompleteFragment;
};

export type TCartProductDrawerPanel = TProps<false, TCartProductDrawerPanelBase, 'div'>;
export const CART_PRODUCT_DRAWER_PANEL_CLASS_NAME = 'wp-order-product-drawer-panel';

export const CartProductDrawerPanel = withCssToString(
  CART_PRODUCT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TCartProductDrawerPanel>(({ cartProduct, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, CART_PRODUCT_DRAWER_PANEL_CLASS_NAME),
        [className],
      );

      const productItems = useMemo<TItems['items']>(
        () => [
          {
            caption: 'Catalog',
            text: maybeGetSourceName(cartProduct.source),
          },
          {
            caption: 'SKU',
            text: cartProduct.product_sku,
          },
          {
            caption: 'Manufacturer',
            text: cartProduct.manufacturer_name ?? 'N/A',
          },
          {
            caption: 'Manufacturer SKU',
            text: cartProduct.manufacturer_sku ?? 'N/A',
          },
        ],
        // @sort
        [cartProduct.manufacturer_name, cartProduct.manufacturer_sku, cartProduct.product_sku, cartProduct.source],
      );

      const orderItems = useMemo<TItems['items']>(
        () => [
          {
            caption: 'Quantity',
            text: getSaleUnitQuantity(cartProduct.quantity, cartProduct.sale_unit),
          },
          {
            caption: 'Allow Substitutions',
            text: cartProduct.is_substitution_allowed ? 'Yes' : 'No',
          },
        ],
        // @sort
        [cartProduct.is_substitution_allowed, cartProduct.quantity, cartProduct.sale_unit],
      );

      const productDescriptionBlocks = useMemo(
        () => castDescriptionUnsafe(cartProduct.description),
        [cartProduct.description],
      );

      const specsItems = useMemo<TItemBaseItem[]>(
        () => getSpecsItems(castSpecsUnsafe(cartProduct.specs)),
        [cartProduct.specs],
      );

      return (
        <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
          <Drawer.Header title='Product' />
          <Drawer.ScrollContent>
            <SImageAsset alt={cartProduct.name} imageAssetPath={cartProduct.image_asset_path} />
            <Drawer.Separator />
            <Drawer.Group>
              <Drawer.Title subTitle={cartProduct.secondary_name} title={cartProduct.name} />
              <Items items={productItems} />
            </Drawer.Group>
            <Drawer.Separator />
            <Items items={orderItems} />
            <Drawer.Separator />
            <Drawer.Group title='Category'>
              <CategoryTree category={cartProduct.category_name} />
            </Drawer.Group>
            {productDescriptionBlocks.length > 0 && (
              <Drawer.Group title='Description'>
                <Description description={productDescriptionBlocks} />
              </Drawer.Group>
            )}
            {specsItems.length > 0 && (
              <Drawer.Group title='Specifications'>
                {specsItems.map((item) => (
                  <Item item={item} key={item.caption} />
                ))}
              </Drawer.Group>
            )}
            {isDef(cartProduct.sds_asset_path) && (
              <Drawer.Group title='Documents'>
                <ControlButton icon='download' text='Safety Data Sheet' />
              </Drawer.Group>
            )}
          </Drawer.ScrollContent>
        </Drawer.Panel>
      );
    }),
  ),
);
