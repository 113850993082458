import { Root as RxSeparatorRoot } from '@radix-ui/react-separator';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import { CONTAINER_MEDIA_DESKTOP } from '@src/modules/design/breakpoints';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

export type TCard = {
  ContainerBase: {
    flapText?: string | undefined;
    flapWarningText?: string | undefined;
    flush?: boolean | undefined;
    iconButtonActions?: TIconButtonAction[] | undefined;
    interactive?: boolean | undefined;
    selected?: boolean | undefined;
    variant?: 'default' | 'form' | undefined;
  };
  TitleBase: {
    preTitle?: string | null | undefined;
    subTitle?: string | null | undefined;
    title: string;
  };
  Container: TProps<true, TCard['ContainerBase'], 'div'>;
  Title: TProps<false, TCard['TitleBase'], 'div'>;
};

export const CARD_CLASS_NAMES = {
  Container: 'wp-card-container',
  Title: 'wp-card-title',
};

const LinkArea = styled(Link, {
  all: 'unset',

  alignItems: 'stretch',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$cardGap',
});

const ButtonArea = styled('button', {
  all: 'unset',

  alignItems: 'stretch',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$cardGap',
});

const SDiv = styled('div', {
  flexDirection: 'row',
  alignItems: 'stretch',
  display: 'flex',
  gap: '$cardGap',
});

const SContainerDiv = styled('div', {
  alignItems: 'stretch',
  containerType: 'inline-size',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$cardGap',
  padding: '$cardPadding',
  paddingTop: '$cardPaddingTop',
  position: 'relative',

  [`&:has(${LinkArea.toString()}:focus)`]: {
    boxShadow: '$secondaryFocus',
  },

  [`&:has(${ButtonArea.toString()}:focus)`]: {
    boxShadow: '$secondaryFocus',
  },

  variants: {
    flush: {
      false: {
        // intentionally empty
      },
      true: {
        paddingTop: '$cardPadding',
      },
    },
    interactive: {
      false: {
        // intentionally empty
      },
      true: {
        '&:hover': {
          borderColor: '$cardHoverBorder',
        },
      },
    },
    selected: {
      false: {
        // intentionally empty
      },
      true: {
        borderColor: 'black !important',
        backgroundColor: '$gray1',
      },
    },
    variant: {
      default: {
        backgroundColor: '$cardDefaultBackground',
        borderColor: '$cardDefaultBorder',
        borderRadius: '$cardDefault',
        borderStyle: '$regular',
        borderWidth: '$regular',
      },
      form: {
        backgroundColor: '$cardFormBackground',
      },
    },
  },
});

const SContainerFlapDiv = styled('div', {
  alignItems: 'center',
  color: '$flapText',
  display: 'inline-flex',
  flexDirection: 'row',
  height: '$flapHeight',
  justifyContent: 'center',
  paddingX: '$flapPaddingX',
  position: 'absolute',
  right: '0px',
  text: '$annotation',
  textTransform: 'uppercase',
  top: '0px',
  whiteSpace: 'nowrap',

  variants: {
    interactive: {
      false: {
        // intentionally empty
      },
      true: {
        ['div:hover > &']: {
          borderColor: '$flapHoverBorder',
        },
      },
    },
    variant: {
      default: {
        backgroundColor: '$flapDefaultBackground',
        borderColor: '$flapDefaultBorder',
        borderRadius: '$flapDefault',
        borderStyle: '$regular',
        borderWidth: '$regular',
        borderBottomRightRadius: '0px',
        borderRightWidth: '0px',
        borderTopLeftRadius: '0px',
        borderTopWidth: '0px',
      },
      form: {
        backgroundColor: '$flapFormBackground',
      },
    },
  },
});

const SContainerFlapWarningSpan = styled('span', {
  color: '$red1',
  paddingLeft: '4px',
});

const SActionsDiv = styled('div', {
  flexDirection: 'column',
  display: 'flex',
  gap: '$cardGap',
  alignItems: 'stretch',
});

const Container = withCssToString(
  CARD_CLASS_NAMES.Container,
  forwardRef<HTMLDivElement, TCard['Container']>(
    (
      {
        flapText,
        flapWarningText,
        flush,
        iconButtonActions,
        interactive,
        selected,
        variant,
        children,
        className,
        ...rest
      },
      ref,
    ): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, CARD_CLASS_NAMES.Container), [className]);

      return (
        <SDiv {...rest} className={joinedClassName} ref={ref}>
          <SContainerDiv
            flush={flush === true}
            interactive={interactive === true}
            selected={selected === true}
            variant={variant ?? 'default'}>
            {(isDef(flapText) || isDef(flapWarningText)) && (
              <SContainerFlapDiv interactive={interactive === true} variant={variant ?? 'default'}>
                {flapText}
                {isDef(flapWarningText) && (
                  <SContainerFlapWarningSpan>{` · ${flapWarningText}  · `}</SContainerFlapWarningSpan>
                )}
              </SContainerFlapDiv>
            )}
            {children}
          </SContainerDiv>
          {(iconButtonActions?.length ?? 0) > 0 && (
            <SActionsDiv>
              {iconButtonActions?.map((iconButtonAction) => (
                <ActionIconButton
                  key={`${iconButtonAction.icon}-${iconButtonAction.annotation ?? ''}`}
                  action={iconButtonAction}
                />
              ))}
            </SActionsDiv>
          )}
        </SDiv>
      );
    },
  ),
);

const ImageAssetArea = styled('div', {
  display: 'grid',
  gap: '$cardGap',
  gridTemplateColumns: 'auto',
  justifyItems: 'stretch',

  [CONTAINER_MEDIA_DESKTOP]: {
    gridTemplateColumns: '128px auto',
  },

  [`& ${ImageAsset.toString()}`]: {
    display: 'none',
    width: '128px',
    maxHeight: '85px',

    [CONTAINER_MEDIA_DESKTOP]: {
      display: 'block',
    },
  },
});

const ContentArea = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$cardGap',
});

const Separator = memo(
  styled(RxSeparatorRoot, {
    alignSelf: 'stretch',
    backgroundColor: '$separatorBackground',
    height: '$separatorThickness',
  }),
);

const STitleDiv = styled('div', {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexWrap: 'wrap',
  gap: '$cardTitleGap',

  [CONTAINER_MEDIA_DESKTOP]: {
    flexDirection: 'row',
  },
});

const Title = withCssToString(
  CARD_CLASS_NAMES.Title,
  memo(
    forwardRef<HTMLDivElement, TCard['Title']>(
      ({ preTitle, subTitle, title, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, CARD_CLASS_NAMES.Title), [className]);

        return (
          <STitleDiv {...rest} className={joinedClassName} ref={ref}>
            {isDef(preTitle) && <Typography.Small text={preTitle} />}
            <Typography.Cta text={title} />
            {isDef(subTitle) && <Typography.Small text={subTitle} />}
          </STitleDiv>
        );
      },
    ),
  ),
);

const TitleLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(({}, ref) => (
    <STitleDiv ref={ref}>
      <Illustrations.Loader height={24} uniqueKey='wp-card-title-loader' width={192}>
        <rect x='0' y='5' rx='0' ry='0' width='192' height='14' />
      </Illustrations.Loader>
    </STitleDiv>
  )),
);

const Footer = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$cardFormBackground',
  borderBottomLeftRadius: '$cardDefault',
  borderBottomRightRadius: '$cardDefault',
  borderTopColor: '$separatorBackground',
  borderTopStyle: '$regular',
  borderTopWidth: '$regular',
  display: 'flex',
  flexDirection: 'column',
  gap: '$cardGap',
  marginBottom: '-$cardPadding',
  marginLeft: '-$cardPadding',
  marginRight: '-$cardPadding',
  overflow: 'hidden',
  padding: '$cardPadding',
});

const Actions = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '$cardGap',
});

const Label = styled(Typography.Annotation, {
  textTransform: 'uppercase',
});

export const Card = {
  Actions,
  ButtonArea,
  Container,
  ContentArea,
  Footer,
  ImageAssetArea,
  Label,
  LinkArea,
  Separator,
  Title,
  TitleLoader,
};
