import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import type { TAgentCustomersUserBaseWithStatisticsFragment } from '@src/gen/graphql/bindings';
import { getFullName } from '@src/gen/shared/data/snippets';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TUserWithStatisticsCardBase = {
  user: TAgentCustomersUserBaseWithStatisticsFragment;
  to: string;
};

export type TUserWithStatisticsCard = TProps<false, TUserWithStatisticsCardBase, 'div'>;
export const USER_WITH_STATISTICS_CARD_CLASS_NAME = 'wp-user-with-statistics-card';

export const UserWithStatisticsCard = withCssToString(
  USER_WITH_STATISTICS_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TUserWithStatisticsCard>(({ user, to, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, USER_WITH_STATISTICS_CARD_CLASS_NAME),
        [className],
      );

      const items = useMemo<TItemsBase['items']>(
        () => [
          {
            caption: 'Email',
            text: `${user.email}`,
          },
          {
            caption: 'Enabled',
            text: { asBoolean: !user.is_disabled },
          },
          {
            caption: 'Onboarded On',
            text: { asDate: 'on', at: user.created_at },
          },
        ],
        // @sort
        [user.created_at, user.email, user.is_disabled],
      );

      return (
        <Card.Container {...rest} className={joinedClassName} flush={true} interactive={true} ref={ref}>
          <Card.LinkArea to={to}>
            <Card.Title title={getFullName(user)} />
            <Items items={items} />
          </Card.LinkArea>
        </Card.Container>
      );
    }),
  ),
);

export const UserWithStatisticsCardLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Card.Container flush={true} ref={ref}>
        <Card.TitleLoader />
        <ItemsLoader count={4} />
      </Card.Container>
    ),
  ),
);
