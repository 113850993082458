import type { ApolloQueryResult } from '@apollo/client';
import { NetworkStatus } from '@apollo/client';
import type {
  AgentCustomersOrganizationQuery,
  AgentCustomersOrganizationQueryVariables,
  TAgentCustomersOrganizationCompleteFragment,
} from '@src/gen/graphql/bindings';
import { useAgentCustomersOrganizationQuery } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useAgentCustomersOrganizationRouteParams } from '@src/modules/routing/agent';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TAgentCustomersOrganizationContext = {
  organization: TAgentCustomersOrganizationCompleteFragment;
  doRefetchOrganization: () => Promise<ApolloQueryResult<AgentCustomersOrganizationQuery>>;
};

export const { Context: AgentCustomersOrganizationContext, useContext: useAgentCustomersOrganization } =
  createRequiredContext<TAgentCustomersOrganizationContext>();

export type TAgentCustomersOrganizationProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function AgentCustomersOrganizationProvider({
  LoaderComponent,
  children,
}: TAgentCustomersOrganizationProvider): JSX.Element {
  const { organizationId } = useAgentCustomersOrganizationRouteParams();

  const variables = useMemo<AgentCustomersOrganizationQueryVariables>(
    () => ({
      organizationId,
    }),
    // @sort
    [organizationId],
  );

  const { data, error, networkStatus, refetch } = useAgentCustomersOrganizationQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const value = useMemo<TAgentCustomersOrganizationContext | undefined>(
    () =>
      isDef(data) && isDef(data.organizations_by_pk)
        ? {
            organization: data.organizations_by_pk,
            doRefetchOrganization: refetch,
          }
        : undefined,
    // @sort
    [data, refetch],
  );

  if (isDef(error)) {
    throw error;
  }

  if (isDef(data) && !isDef(data.organizations_by_pk)) {
    throw new Error('Not Found');
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentCustomersOrganizationContext.Provider value={value}>{children}</AgentCustomersOrganizationContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
