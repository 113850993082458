import coverThumbJpg from '@src/assets/cover-thumb.jpg';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '$announcementPadding',
});

const SInnerDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '$announcementGap',
  maxWidth: '$announcementMaxWidth',
  textAlign: 'center',
});

const SImg = styled('img', {
  objectFit: 'contain',
  paddingBottom: '$announcementPadding',
  width: '$announcementCoverWidth',
});

export type TAnnouncement = TProps<true, TEmptyObject, 'div'>;
export const ANNOUNCEMENT_CLASS_NAME = 'wp-announcement';

export const Announcement = withCssToString(
  ANNOUNCEMENT_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TAnnouncement>(({ children, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, ANNOUNCEMENT_CLASS_NAME), [className]);

      return (
        <SDiv {...rest} className={joinedClassName} ref={ref}>
          <SInnerDiv>
            <SImg src={coverThumbJpg} />
            {children}
          </SInnerDiv>
        </SDiv>
      );
    }),
  ),
);
