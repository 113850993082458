import assert from 'assert';

export type TArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type TTuple<T, N extends number, R extends readonly T[] = []> = R['length'] extends N
  ? R
  : TTuple<T, N, readonly [T, ...R]>;

export type TParameters = TTuple<string | null, 5>;
export type TOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: T[SubKey] | undefined };

export interface IClass<T> extends Function {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/prefer-function-type
  new (...args: any[]): T;
}

export function isDef<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function ensureDef<T>(value: T | null | undefined): T {
  assert(value !== null && value !== undefined, 'Not defined.');
  return value;
}

export function assertDef<T>(value: T | null | undefined): asserts value is T {
  assert(value !== null && value !== undefined, 'Not defined.');
}

export function ensureClass<T>(classRef: IClass<T>, instanceRef: unknown): T {
  assert(instanceRef instanceof classRef, 'Class guard failed.');
  return instanceRef;
}

export function assertClass<T>(classRef: IClass<T>, instanceRef: unknown): asserts instanceRef is T {
  assert(instanceRef instanceof classRef, 'Class guard failed.');
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function ensureString(value: unknown): string {
  assert(typeof value === 'string', 'Not a string.');
  return value;
}

export function isNotEmptyString(value: unknown): value is string {
  return isString(value) && value !== '';
}

export function ensureNotEmptyString(value: unknown): string {
  const s = ensureString(value);
  assert(s !== '', 'Empty string.');
  return s;
}

export function ensureArray(value: unknown): unknown[] {
  assert(Array.isArray(value), 'Not an array.');
  return value;
}

export function ensureBoolean(value: unknown): boolean {
  assert(typeof value === 'boolean', 'Not a boolean.');
  return value;
}

export function isPlainObject(value: unknown): boolean {
  return value !== undefined && value !== null && value.constructor === Object;
}

export function ensurePlainObject(value: unknown): object {
  assert(value !== undefined && value !== null && value.constructor === Object, 'Not a plain object.');
  return value;
}

export function isFiniteNumber(value: unknown): boolean {
  return typeof value === 'number' && !Number.isNaN(value) && Number.isFinite(value);
}

export function ensureFiniteNumber(value: unknown): number {
  assert(typeof value === 'number' && !Number.isNaN(value) && Number.isFinite(value));
  return value;
}

export function ensureSafeIntegerNumber(value: unknown): number {
  const numValue = ensureFiniteNumber(value);
  assert(Number.isSafeInteger(numValue), 'Not an integer.');
  return numValue;
}

export async function delayMs(ms: number): Promise<void> {
  await new Promise((done) => setTimeout(done, ms));
}
