import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { TAgentOrderReturnCompleteWithOrderSummaryFragment } from '@src/gen/graphql/bindings';
import {
  getCombinedProductName,
  getFullName,
  getOrderReference,
  getSaleUnitQuantity,
} from '@src/gen/shared/data/snippets';
import { getSource, maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderReturnListingCardBase = {
  orderReturn: TAgentOrderReturnCompleteWithOrderSummaryFragment;
  to: string;
};

export type TOrderReturnListingCard = TProps<false, TOrderReturnListingCardBase, 'div'>;
export const ORDER_RETURN_LISTING_CARD_CLASS_NAME = 'wp-order-return-listing-card';

export const OrderReturnListingCard = withCssToString(
  ORDER_RETURN_LISTING_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderReturnListingCard>(({ orderReturn, to, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, ORDER_RETURN_LISTING_CARD_CLASS_NAME),
        [className],
      );

      const items = useMemo<TItems['items']>(
        () => [
          {
            caption: 'Return Quantity',
            text: getSaleUnitQuantity(orderReturn.quantity, orderReturn.sale_unit),
          },
          {
            caption: 'Never Receive Again',
            text: { asBoolean: orderReturn.should_blacklist },
          },
          {
            caption: 'Initiated By',
            text: getFullName(orderReturn.creator),
          },
          {
            caption: 'Initiated On',
            text: { asDate: 'on', at: orderReturn.created_at },
          },
        ],
        // @sort
        [
          orderReturn.created_at,
          orderReturn.creator,
          orderReturn.quantity,
          orderReturn.sale_unit,
          orderReturn.should_blacklist,
        ],
      );

      return (
        <Card.Container {...rest} className={joinedClassName} flush={true} interactive={true} ref={ref}>
          <Card.LinkArea to={to}>
            <Card.Title
              title={`Return in ${getOrderReference(
                orderReturn.order.location.order_code,
                orderReturn.order.reference_number,
              )}`}
              subTitle={`for ${orderReturn.order.location.name}`}
            />
            <Item
              item={{
                caption: `${maybeGetSourceName(getSource(orderReturn.source))} · ${orderReturn.product_sku}`,
                text: getCombinedProductName(orderReturn.name, orderReturn.secondary_name),
              }}
            />
            <ItemsBanner items={items} />
            <Item
              item={{
                caption: 'Return Reason',
                text: orderReturn.reason,
              }}
            />
            {isDef(orderReturn.applied_at) && (
              <Banner icon='apply' accent='success' message='This return is completed.' />
            )}
          </Card.LinkArea>
        </Card.Container>
      );
    }),
  ),
);
