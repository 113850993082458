import { Card } from '@src/components/appearance/fragments/Card';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import type { TAgentCustomersLocationAclBaseFragment } from '@src/gen/graphql/bindings';
import { getFullName } from '@src/gen/shared/data/snippets';
import { useAuthCustomer, useAuthCustomerOrganization } from '@src/modules/auth/AuthProvider';
import { useCustomerUserAccountRouteParams } from '@src/modules/routing/customer';

export function CustomerUserAccountPart(): JSX.Element {
  const routeParams = useCustomerUserAccountRouteParams();
  const customer = useAuthCustomer();
  const organization = useAuthCustomerOrganization(routeParams.organizationId);

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <Structure.Group>
          <ItemsBanner
            items={[
              {
                caption: 'Full Name',
                text: getFullName(customer),
              },
              {
                caption: 'Email Address',
                text: customer.email,
              },
            ]}
          />
          <ItemsBanner
            items={[
              {
                caption: 'Organization',
                text: organization.name,
              },
              {
                caption: 'View Analytics',
                text: organization.acl.can_view_analytics
                  ? 'Yes (All Locations)'
                  : organization.locations.reduce((out, next) => out || next.acl.can_view_analytics, false)
                  ? 'Yes (Selected Locations)'
                  : 'No',
              },
            ]}
          />
        </Structure.Group>
        <Structure.Group title='Location Permissions' variant='secondary'>
          {organization.locations.map((loc) => (
            <Card.Container key={loc.id} flush={true} interactive={false}>
              <Card.ContentArea>
                <Card.Title title={loc.name} subTitle={loc.order_code} />
                <Items
                  items={[
                    {
                      caption: 'Permissions for Orders',
                      text: toValue(loc.acl),
                    },
                    {
                      caption: 'View Analytics',
                      text: organization.acl.can_view_analytics
                        ? 'Yes (Organization Setting)'
                        : loc.acl.can_view_analytics
                        ? 'Yes'
                        : 'No',
                    },
                  ]}
                />
              </Card.ContentArea>
            </Card.Container>
          ))}
        </Structure.Group>
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function toValue(
  acl: Pick<
    TAgentCustomersLocationAclBaseFragment,
    'can_approve_orders' | 'can_place_orders' | 'can_send_order_messages' | 'can_view_orders'
  >,
): string {
  if (acl.can_approve_orders) {
    return 'View + Send Messages + Place + Approve';
  } else if (acl.can_place_orders) {
    return 'View + Send Messages + Place';
  } else if (acl.can_send_order_messages) {
    return 'View + Send Messages';
  } else if (acl.can_view_orders) {
    return 'View';
  } else {
    return 'None';
  }
}
