import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum ERevenueShareTarget {
  TEST = 'test',
  CATAPULT_GROW = 'catapult_grow',
  CATAPULT_EDUCATION = 'catapult_education',
  DELIVERING_WOW = 'delivering_wow',
}

const REVENUE_SHARE_TARGET_LOOKUP = mapValues(invert(ERevenueShareTarget), () => true);

export function getRevenueShareTarget(rawRevenueShareTarget: string): ERevenueShareTarget {
  assert(
    REVENUE_SHARE_TARGET_LOOKUP[rawRevenueShareTarget],
    `Unknown revenue share target: "${rawRevenueShareTarget}".`,
  );
  return rawRevenueShareTarget as ERevenueShareTarget; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetRevenueShareTarget(rawRevenueShareTarget: string): ERevenueShareTarget | undefined {
  try {
    return getRevenueShareTarget(rawRevenueShareTarget);
  } catch {
    return undefined;
  }
}

export const REVENUE_SHARE_TARGET_LABELS: { [key in ERevenueShareTarget]: string } = {
  [ERevenueShareTarget.TEST]: 'Test',
  [ERevenueShareTarget.CATAPULT_GROW]: 'Catapult Grow',
  [ERevenueShareTarget.CATAPULT_EDUCATION]: 'Catapult Education',
  [ERevenueShareTarget.DELIVERING_WOW]: 'Delivering WOW',
};

export function maybeGetRevenueShareTargetLabel(rawRevenueShareTarget: string): string {
  const revenueShareTarget = maybeGetRevenueShareTarget(rawRevenueShareTarget);
  return revenueShareTarget !== undefined
    ? ensureDef(REVENUE_SHARE_TARGET_LABELS[revenueShareTarget])
    : rawRevenueShareTarget;
}

const REVENUE_SHARE_TARGET_PERCENT: { [key in ERevenueShareTarget]: number } = {
  [ERevenueShareTarget.TEST]: 0,
  [ERevenueShareTarget.CATAPULT_GROW]: 3500,
  [ERevenueShareTarget.CATAPULT_EDUCATION]: 3500,
  [ERevenueShareTarget.DELIVERING_WOW]: 1500,
};

export function getRevenueShareTargetPercent(rawRevenueShareTarget: string): number {
  return ensureDef(REVENUE_SHARE_TARGET_PERCENT[getRevenueShareTarget(rawRevenueShareTarget)]);
}

export function maybeGetRevenueShareTargetPercent(rawRevenueShareTarget: string): number {
  const source = maybeGetRevenueShareTarget(rawRevenueShareTarget);
  return source !== undefined ? ensureDef(REVENUE_SHARE_TARGET_PERCENT[source]) : 0;
}
