import { Typography } from '@src/components/appearance/basics/Typography';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

export type TNextOrderHistoryEmptyAnnouncement = TProps<false, TEmptyObject, 'div'>;
export const NEXT_ORDER_HISTORY_EMPTY_ANNOUNCEMENT_CLASS_NAME = 'wp-next-order-history-empty-announcement';

export const NextOrderHistoryEmptyAnnouncement = withCssToString(
  NEXT_ORDER_HISTORY_EMPTY_ANNOUNCEMENT_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNextOrderHistoryEmptyAnnouncement>(({ className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, NEXT_ORDER_HISTORY_EMPTY_ANNOUNCEMENT_CLASS_NAME),
        [className],
      );

      return (
        <Announcement {...rest} className={joinedClassName} css={{ marginTop: '64px' }} ref={ref}>
          <Fragment>
            <Typography.SubTitle text='No History' />
            <Typography.Small text='Products from your history will appear here after you place your first order.' />
          </Fragment>
        </Announcement>
      );
    }),
  ),
);
