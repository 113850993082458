import assert from 'assert';

export type TSpecs = {
  [key: string]: string;
};

export function castSpecsUnsafe(specs: unknown): TSpecs {
  assert(typeof specs === 'object');
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return specs as TSpecs;
}
