import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Card } from '@src/components/appearance/fragments/Card';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import { Chrome } from '@src/components/appearance/structure/Chrome';
import type { TAgentCartProductCompleteFragment } from '@src/gen/graphql/bindings';
import { getSaleUnitQuantity } from '@src/gen/shared/data/snippets';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrganizationCartProductCardBase = {
  onClick?: (() => void) | undefined;
  cartProduct: TAgentCartProductCompleteFragment;
};

export type TOrganizationCartProductCard = TProps<false, TOrganizationCartProductCardBase, 'div'>;
export const ORGANIZATION_CART_PRODUCT_CARD_CLASS_NAME = 'wp-order-original-cart-product-card';

export const OrganizationCartProductCard = withCssToString(
  ORGANIZATION_CART_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrganizationCartProductCard>(
      ({ onClick, cartProduct, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORGANIZATION_CART_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const items = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(cartProduct.source),
            },
            {
              caption: 'SKU',
              text: cartProduct.product_sku,
            },
            {
              caption: 'Quantity',
              text: getSaleUnitQuantity(cartProduct.quantity, cartProduct.sale_unit),
            },
            {
              caption: 'Allow Substitutions',
              text: cartProduct.is_substitution_allowed ? 'Yes' : 'No',
            },
          ],
          // @sort
          [
            cartProduct.is_substitution_allowed,
            cartProduct.product_sku,
            cartProduct.quantity,
            cartProduct.sale_unit,
            cartProduct.source,
          ],
        );

        return (
          <Card.Container {...rest} className={joinedClassName} interactive={isDef(onClick)} ref={ref}>
            {isDef(onClick) ? (
              <Card.ButtonArea onClick={onClick}>
                <Card.ImageAssetArea>
                  <ImageAsset alt={cartProduct.name} imageAssetPath={cartProduct.image_asset_path} />
                  <Card.ContentArea>
                    <Card.Title subTitle={cartProduct.secondary_name} title={cartProduct.name} />
                    <Items items={items} />
                  </Card.ContentArea>
                </Card.ImageAssetArea>
              </Card.ButtonArea>
            ) : (
              <Card.ImageAssetArea>
                <ImageAsset alt={cartProduct.name} imageAssetPath={cartProduct.image_asset_path} />
                <Card.ContentArea>
                  <Card.Title subTitle={cartProduct.secondary_name} title={cartProduct.name} />
                  <Items items={items} />
                </Card.ContentArea>
              </Card.ImageAssetArea>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);

export const OrganizationCartProductCardLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(({}, ref): JSX.Element => {
    return (
      <Card.Container ref={ref}>
        <Card.ImageAssetArea>
          <Illustrations.Loader height={85} uniqueKey='wp-organization-cart-product-card-loader-image' width={128}>
            <rect x='0' y='5' rx='0' ry='0' width='128' height='85' />
          </Illustrations.Loader>
          <Card.ContentArea>
            <Card.TitleLoader />
            <ItemsLoader count={4} />
          </Card.ContentArea>
        </Card.ImageAssetArea>
      </Card.Container>
    );
  }),
);

export const AgentOrganizationCartProductsLoader = (): JSX.Element => {
  return (
    <Chrome.ScrollContentLoader>
      <OrganizationCartProductCardLoader />
      <OrganizationCartProductCardLoader />
      <OrganizationCartProductCardLoader />
      <OrganizationCartProductCardLoader />
      <OrganizationCartProductCardLoader />
      <OrganizationCartProductCardLoader />
      <OrganizationCartProductCardLoader />
    </Chrome.ScrollContentLoader>
  );
};
