import type { TItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderCustomerStructureGroup } from '@src/components/mixins/groups/OrderCustomerStructureGroup';
import {
  AgentOrderBackOrdersAnalyzer,
  AgentOrderPaymentsAnalyzer,
  AgentOrderReturnsAnalyzer,
  AgentOrderShipmentsAnalyzer,
} from '@src/gen/shared/data/agentOrders';
import { maybeGetOrderStatusLabel } from '@src/gen/shared/enums/orderStatus';
import { maybeGetReferralFeeTargetLabel } from '@src/gen/shared/enums/referralFeeTarget';
import { formatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import { useMemo } from 'react';

export function AgentOrderWorksheetDashboardPart(): JSX.Element {
  const { order, orderManager } = useAgentOrder();

  const itemsStatus = useMemo<TItemsBanner['items']>(
    () => [
      {
        caption: 'Order Status',
        text: maybeGetOrderStatusLabel(order.status),
      },
      {
        caption: 'Awaiting Prebill Approval',
        text: { asBoolean: order.is_awaiting_prebill_approval },
      },
      {
        caption: 'Marked Waiting on Customer',
        text: { asBoolean: order.is_blocked_on_customer },
      },
      {
        caption: 'Paid',
        text: { asBoolean: order.is_paid },
      },
    ],
    // @sort
    [order.is_awaiting_prebill_approval, order.is_blocked_on_customer, order.is_paid, order.status],
  );

  const itemsSecond = useMemo<TItemsBanner['items']>(
    () => [
      {
        caption: 'Processing Status',
        text:
          orderManager.areOrderEntriesReady() &&
          orderManager.areOrderBaseGroupsReady() &&
          orderManager.areOrderPlanGroupsReady()
            ? 'Ready'
            : 'TBD',
      },
      {
        caption: 'Products',
        text: orderManager.areOrderEntriesReady() ? 'Ready' : 'TBD',
      },
      {
        caption: 'Base Orders',
        text: orderManager.areOrderBaseGroupsReady() ? 'Ready' : 'TBD',
      },
      {
        caption: 'Plan Orders',
        text: orderManager.areOrderPlanGroupsReady() ? 'Ready' : 'TBD',
      },
    ],
    // @sort
    [orderManager],
  );

  const orderPaymentsAnalyzer = useMemo(
    () =>
      isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())
        ? new AgentOrderPaymentsAnalyzer(
            ensureDef(orderManager.maybeGetOrderSnapshotInvoiceLatest()),
            order.order_payments,
            order.is_paid,
          )
        : null,
    [order.order_payments, orderManager, order.is_paid],
  );

  const orderShipmentsAnalyzer = useMemo(
    () =>
      isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())
        ? new AgentOrderShipmentsAnalyzer(
            ensureDef(orderManager.maybeGetOrderSnapshotInvoiceLatest()),
            order.order_shipments,
            order.order_plan_groups,
          )
        : null,
    [order.order_plan_groups, order.order_shipments, orderManager],
  );

  const orderBackOrdersAnalyzer = useMemo(
    () =>
      isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())
        ? new AgentOrderBackOrdersAnalyzer(order.order_back_orders)
        : null,
    [order.order_back_orders, orderManager],
  );

  const orderReturnsAnalyzer = useMemo(
    () =>
      isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())
        ? new AgentOrderReturnsAnalyzer(order.order_returns)
        : null,
    [order.order_returns, orderManager],
  );

  return (
    <Structure.ScrollContent skipSeparator={true}>
      <Structure.Stack>
        <Structure.Group title='Status' variant='secondary'>
          <ItemsBanner items={itemsStatus} />
          <ItemsBanner items={itemsSecond} />
          {isDef(orderPaymentsAnalyzer) && (
            <ItemsBanner
              items={[
                {
                  caption: 'Payments Status',
                  text: orderPaymentsAnalyzer.getStatusText(),
                  accent: orderPaymentsAnalyzer.getStatusAccent(),
                },
                {
                  caption: 'Latest Invoice Total',
                  text: formatDollarsCurrency(orderPaymentsAnalyzer.latestInvoiceTotal),
                },
                {
                  caption: 'Payments Total',
                  text: formatDollarsCurrency(orderPaymentsAnalyzer.paymentsTotal),
                },
                {
                  caption: 'Expected Adjustment',
                  text:
                    orderPaymentsAnalyzer.latestInvoiceTotal === orderPaymentsAnalyzer.paymentsTotal
                      ? 'None'
                      : orderPaymentsAnalyzer.latestInvoiceTotal > orderPaymentsAnalyzer.paymentsTotal
                      ? `${formatDollarsCurrency(
                          orderPaymentsAnalyzer.latestInvoiceTotal - orderPaymentsAnalyzer.paymentsTotal,
                        )} (Charge)`
                      : `${formatDollarsCurrency(
                          orderPaymentsAnalyzer.paymentsTotal - orderPaymentsAnalyzer.latestInvoiceTotal,
                        )} (Refund)`,
                },
              ]}
            />
          )}
          {isDef(orderShipmentsAnalyzer) && orderShipmentsAnalyzer.orderSnapshotEntries.length > 0 && (
            <ItemsBanner
              items={[
                {
                  caption: 'Shipments Status',
                  text: orderShipmentsAnalyzer.isFullyDelivered()
                    ? 'Fully Delivered'
                    : orderShipmentsAnalyzer.isFullyShipped()
                    ? 'Fully Tracked'
                    : 'TBD',
                },
                {
                  caption: 'Untracked',
                  text: `${orderShipmentsAnalyzer.shipmentStatus.untracked}`,
                },
                {
                  caption: 'Tracked',
                  text: `${orderShipmentsAnalyzer.shipmentStatus.tracked}`,
                },
                {
                  caption: 'Delivered',
                  text: `${orderShipmentsAnalyzer.shipmentStatus.delivered}`,
                },
              ]}
            />
          )}
          {isDef(orderBackOrdersAnalyzer) && (
            <ItemsBanner
              items={[
                {
                  caption: 'Back-Orders Status',
                  text: orderBackOrdersAnalyzer.getStatusText(),
                  accent: orderBackOrdersAnalyzer.getStatusAccent(),
                },
                {
                  caption: 'Waiting for Customer Decision',
                  text: `${orderBackOrdersAnalyzer.waitingForCustomerDecision}`,
                },
                {
                  caption: 'Waiting for Removal from Order',
                  text: `${orderBackOrdersAnalyzer.waitingForRemoval}`,
                },
                {
                  caption: 'Waiting for Fulfillment',
                  text: `${orderBackOrdersAnalyzer.waitingForShipment}`,
                },
              ]}
            />
          )}
          {isDef(orderReturnsAnalyzer) && (
            <ItemsBanner
              items={[
                {
                  caption: 'Returns Status',
                  text: orderReturnsAnalyzer.getStatusText(),
                  accent: orderReturnsAnalyzer.getStatusAccent(),
                },
                {
                  caption: 'Waiting for Instructions',
                  text: `${orderReturnsAnalyzer.waitingForInstructions}`,
                },
                {
                  caption: 'Waiting for Customer to Ship',
                  text: `${orderReturnsAnalyzer.waitingForShipment}`,
                },
                {
                  caption: 'Waiting for Wellplaece to Confirm',
                  text: `${orderReturnsAnalyzer.waitingForAcknowledgment}`,
                },
              ]}
            />
          )}
        </Structure.Group>
        {isDef(order.referral_fee_target) && (
          <Structure.Group title='Referral Fee Attribution' variant='secondary'>
            <ItemsBanner
              items={[
                {
                  caption: 'Referral Fee Program',
                  text: maybeGetReferralFeeTargetLabel(order.referral_fee_target),
                },
                {
                  caption: 'Order Counter',
                  text: `${ensureDef(order.referral_fee_order_index)}`,
                },
                {
                  caption: 'Amount for this Order',
                  text: formatDollarsCurrency(ensureDef(order.referral_fee_amount)),
                },
                {
                  caption: 'Attributed On',
                  text: {
                    asDate: 'on',
                    at: ensureDef(order.referral_fee_at),
                  },
                },
              ]}
            />
          </Structure.Group>
        )}
        <OrderCustomerStructureGroup locationWithOrganization={order.location} variant='secondary' />
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}
