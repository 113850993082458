import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { forwardRef, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$annotatedGap',
});

export type TAnnotatedBase = {
  text: string;
};

export type TAnnotated = TProps<true, TAnnotatedBase, 'div'>;
export const ANNOTATED_CLASS_NAME = 'wp-annotated';

export const Annotated = withCssToString(
  ANNOTATED_CLASS_NAME,
  forwardRef<HTMLDivElement, TAnnotated>(({ text, children, className, ...rest }, ref): JSX.Element => {
    const joinedClassName = useMemo(() => joinClassNames(className, ANNOTATED_CLASS_NAME), [className]);

    return (
      <SDiv {...rest} className={joinedClassName} ref={ref}>
        {children}
        <Typography.Annotation rigid={true} expanding={true} text={text} />
      </SDiv>
    );
  }),
);

export const AnnotatedLoader = forwardRef<HTMLDivElement, PropsWithChildren<TEmptyObject>>(
  ({ children }, ref): JSX.Element => (
    <SDiv ref={ref}>
      {children}
      <Illustrations.Loader height={18} uniqueKey='wp-annotated-loader' width={64}>
        <rect x='0' y='5' rx='0' ry='0' width='64' height='8' />
      </Illustrations.Loader>
    </SDiv>
  ),
);
