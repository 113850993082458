import { ActionButton } from '@src/components/appearance/controls/Button';
import { BannerLoader } from '@src/components/appearance/fragments/Banner';
import { ItemsBannerLoader } from '@src/components/appearance/fragments/ItemsBanner';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderOriginalCartProductCardLoader } from '@src/components/mixins/cards/OrderOriginalCartProductCard';
import { OrderPaymentCard } from '@src/components/mixins/cards/OrderPaymentCard';
import { OrderAttachmentsStructureGroup } from '@src/components/mixins/groups/OrderAttachmentsStructureGroup';
import { OrderBackOrdersStructureGroup } from '@src/components/mixins/groups/OrderBackOrdersStructureGroup';
import { OrderBackfilledInvoiceStructureGroup } from '@src/components/mixins/groups/OrderBackfilledInvoiceStructureGroup';
import { OrderCustomerStructureGroup } from '@src/components/mixins/groups/OrderCustomerStructureGroup';
import { OrderDocumentsStructureGroup } from '@src/components/mixins/groups/OrderDocumentsStructureGroup';
import { OrderOriginalCartStructureGroup } from '@src/components/mixins/groups/OrderOriginalCartStructureGroup';
import { OrderReturnsStructureGroup } from '@src/components/mixins/groups/OrderReturnsStructureGroup';
import { OrderShipmentsStructureGroup } from '@src/components/mixins/groups/OrderShipmentsStructureGroup';
import { OrderSnapshotStructureGroup } from '@src/components/mixins/groups/OrderSnapshotStructureGroup';
import { OrderStatusStructureGroup } from '@src/components/mixins/groups/OrderStatusStructureGroup';
import { OrderTrackingNumbersStructureGroup } from '@src/components/mixins/groups/OrderTrackingNumbersStructureGroup';
import { EOrderStatus, getOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import { useAgentOrderSnapshotReviewChrome } from '@src/modules/data/agent/order/AgentOrderSnapshotReviewChromeProvider';

export function AgentOrderSummaryPart(): JSX.Element {
  const { order } = useAgentOrder();
  return order.version === 1 ? <AgentOrderSummaryPartV1 /> : <AgentOrderSummaryPartV2 />;
}

export function AgentOrderSummaryPartLoader(): JSX.Element {
  return (
    <Structure.ScrollContentLoader>
      <Structure.Stack>
        <Structure.GroupLoader hasTitle={true}>
          <BannerLoader />
          <BannerLoader />
          <ItemsBannerLoader count={4} />
        </Structure.GroupLoader>
        <Structure.GroupLoader hasTitle={true}>
          <BannerLoader />
        </Structure.GroupLoader>
        <Structure.GroupLoader hasTitle={true}>
          <BannerLoader />
        </Structure.GroupLoader>
        <Structure.GroupLoader hasTitle={true}>
          <ItemsBannerLoader count={4} />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
        </Structure.GroupLoader>
      </Structure.Stack>
    </Structure.ScrollContentLoader>
  );
}

export function AgentOrderSummaryPartV1(): JSX.Element {
  const {
    beginBackfillOrderInvoice,
    beginOrderAttachmentAdd,
    beginOrderBackfilledInvoiceRemove,
    beginOrderCancel,
    beginOrderProductView,
    beginOrderStatusEdit,
    beginOrderTrackingHistoryView,
    beginOrderTrackingNumberAdd,
    doOrderAttachmentRemoveAsyncNotify,
    doOrderTrackingNumberRemoveAsyncNotify,
    downloadOrderAttachmentAsyncNotify,
    downloadOrderExportOriginalCartAsyncNotify,
  } = useAgentOrderActions();
  const { order } = useAgentOrder();

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <OrderStatusStructureGroup
          beginOrderCancel={beginOrderCancel}
          beginOrderStatusEdit={beginOrderStatusEdit}
          beginBackfillOrderInvoice={beginBackfillOrderInvoice}
          order={order}
          organizationName={order.location.organization.name}
        />
        <OrderCustomerStructureGroup locationWithOrganization={order.location} />
        {isDef(order.latest_order_snapshot_id) &&
          isDef(order.order_snapshots.find((os) => os.id === order.latest_order_snapshot_id)) && (
            <OrderBackfilledInvoiceStructureGroup
              backfilledInvoice={ensureDef(
                order.order_snapshots.find((os) => os.id === order.latest_order_snapshot_id),
              )}
              beginOrderBackfilledInvoiceRemove={beginOrderBackfilledInvoiceRemove}
            />
          )}
        {(order.status !== EOrderStatus.CANCELED || order.order_attachments.length > 0) && (
          <OrderAttachmentsStructureGroup
            orderAttachments={order.order_attachments}
            downloadOrderAttachmentAsyncNotify={downloadOrderAttachmentAsyncNotify}
            beginOrderAttachmentAdd={order.status !== EOrderStatus.CANCELED ? beginOrderAttachmentAdd : undefined}
            doOrderAttachmentRemoveAsyncNotify={
              order.status !== EOrderStatus.CANCELED ? doOrderAttachmentRemoveAsyncNotify : undefined
            }
          />
        )}
        {(order.status !== EOrderStatus.CANCELED || order.order_tracking_numbers.length > 0) && (
          <OrderTrackingNumbersStructureGroup
            beginOrderTrackingNumberAdd={
              order.status !== EOrderStatus.CANCELED ? beginOrderTrackingNumberAdd : undefined
            }
            doOrderTrackingNumberRemoveAsyncNotify={
              order.status !== EOrderStatus.CANCELED ? doOrderTrackingNumberRemoveAsyncNotify : undefined
            }
            beginOrderTrackingHistoryView={beginOrderTrackingHistoryView}
            orderStatus={getOrderStatus(order.status)}
            orderTrackingNumbers={order.order_tracking_numbers}
          />
        )}
        <OrderOriginalCartStructureGroup
          beginOrderProductView={beginOrderProductView}
          downloadOrderExportOriginalCartAsyncNotify={downloadOrderExportOriginalCartAsyncNotify}
          order={order}
        />
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function AgentOrderSummaryPartV2(): JSX.Element {
  const { order, orderManager } = useAgentOrder();
  const {
    beginOrderBackOrderProductView,
    beginOrderOriginalCartView,
    beginOrderReturnProductView,
    beginOrderReturnSeeInstructionsAsyncNotify,
    beginOrderShipmentProductView,
    beginOrderSnapshotEntryView,
    beginOrderSnapshotView,
    beginOrderTrackingHistoryView,
  } = useAgentOrderActions();
  const { beginOrderSnapshotReview } = useAgentOrderSnapshotReviewChrome();

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <OrderStatusStructureGroup order={order} organizationName={order.location.organization.name} />
        {orderManager.hasSnapshotPrebillPending() && (
          <Structure.Group title='Prebill - Awaiting Approval'>
            <ToolBar>
              <ActionButton
                action={{
                  isAsync: false,
                  onClick: (): void =>
                    beginOrderSnapshotReview({
                      orderSnapshotId: orderManager.mustGetOrderSnapshotPrebillPending().orderSnapshot.id,
                    }),
                  text: 'Review Prebill',
                }}
              />
            </ToolBar>
            <OrderSnapshotStructureGroup
              beginOrderSnapshotEntryPlanQuantityEdit={null}
              beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
              isSummary={true}
              orderBudgetAssistantManager={null}
              orderSnapshotManager={orderManager.mustGetOrderSnapshotPrebillPending()}
            />
          </Structure.Group>
        )}
        <OrderBackOrdersStructureGroup
          allOrderBackOrders={order.order_back_orders}
          beginOrderBackOrderProductView={beginOrderBackOrderProductView}
          doOrderBackOrderReplyAsyncNotify={null}
          mode='customer-awaiting'
        />
        <OrderReturnsStructureGroup
          allOrderReturns={order.order_returns}
          beginOrderReturnAdd={null}
          beginOrderReturnProductView={beginOrderReturnProductView}
          beginOrderReturnSeeInstructionsAsyncNotify={beginOrderReturnSeeInstructionsAsyncNotify}
          doOrderReturnExecuteAsyncNotify={null}
          doesOrderHaveInvoice={isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())}
          mode='customer-awaiting'
        />
        <OrderDocumentsStructureGroup
          beginOrderOriginalCartView={beginOrderOriginalCartView}
          beginOrderSnapshotView={beginOrderSnapshotView}
          order={order}
          orderManager={orderManager}
        />
        <OrderShipmentsStructureGroup
          beginOrderShipmentProductView={beginOrderShipmentProductView}
          beginOrderTrackingHistoryView={beginOrderTrackingHistoryView}
          orderStatus={getOrderStatus(order.status)}
          orderShipments={order.order_shipments}
        />
        <OrderBackOrdersStructureGroup
          allOrderBackOrders={order.order_back_orders}
          beginOrderBackOrderProductView={beginOrderBackOrderProductView}
          doOrderBackOrderReplyAsyncNotify={null}
          mode='customer-other'
        />
        <OrderReturnsStructureGroup
          allOrderReturns={order.order_returns}
          beginOrderReturnAdd={null}
          beginOrderReturnProductView={beginOrderReturnProductView}
          beginOrderReturnSeeInstructionsAsyncNotify={beginOrderReturnSeeInstructionsAsyncNotify}
          doOrderReturnExecuteAsyncNotify={null}
          doesOrderHaveInvoice={isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())}
          mode='customer-other'
        />
        {order.order_payments.length > 0 && (
          <Structure.Group title='Payments'>
            {order.order_payments.map((op) => (
              <OrderPaymentCard key={op.id} onDeleteAsyncNotify={null} orderPayment={op} />
            ))}
          </Structure.Group>
        )}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}
