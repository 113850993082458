import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { ReactElement } from 'react';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$itemsBannerBackground',
  display: 'flex',
  flexDirection: 'row',
  gap: '$toolBarGap',
  padding: '$toolBarPadding',

  variants: {
    variant: {
      default: {
        // intentionally empty
      },
      structure: {
        borderBottomColor: '$separatorBackground',
        borderBottomWidth: '$regular',
        borderBottomStyle: '$regular',
      },
      page: {
        borderColor: '$separatorBackground',
        borderWidth: '$regular',
        borderStyle: '$regular',

        '@desktop': {
          marginTop: '$structureMainPaddingDesktop',
        },
      },
    },
  },
});

const SLeftDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  flexWrap: 'wrap',
  gap: '$toolBarGap',
});

const SRightDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  gap: '$toolBarGap',
});

export type TToolBarBase = {
  RightChildren?: ReactElement | undefined;
  variant?: 'default' | 'page' | 'structure' | undefined;
};

export type TToolBar = TProps<true, TToolBarBase, 'div'>;
export const TOOL_BAR_CLASS_NAME = 'wp-tool-bar';

export const ToolBar = withCssToString(
  TOOL_BAR_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TToolBar>(
      ({ RightChildren, variant, className, children, ...rest }: TToolBar, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, TOOL_BAR_CLASS_NAME), [className]);

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref} variant={variant ?? 'default'}>
            <SLeftDiv>{children}</SLeftDiv>
            {isDef(RightChildren) && <SRightDiv>{RightChildren}</SRightDiv>}
          </SDiv>
        );
      },
    ),
  ),
);
