import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EOrderAttachmentType {
  PREBILL = 'prebill',
  INVOICE = 'invoice',
  RETURN_LABEL = 'return_label',
  OTHER = 'other',
}

const ORDER_ATTACHMENT_TYPE_LOOKUP = mapValues(invert(EOrderAttachmentType), () => true);

export function getOrderAttachmentType(rawOrderAttachmentType: string): EOrderAttachmentType {
  assert(
    ORDER_ATTACHMENT_TYPE_LOOKUP[rawOrderAttachmentType],
    `Unknown order attachment type: "${rawOrderAttachmentType}".`,
  );
  return rawOrderAttachmentType as EOrderAttachmentType; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetOrderAttachmentType(rawOrderAttachmentType: string): EOrderAttachmentType | undefined {
  try {
    return getOrderAttachmentType(rawOrderAttachmentType);
  } catch {
    return undefined;
  }
}

export const ORDER_ATTACHMENT_TYPE_LABELS: { [key in EOrderAttachmentType]: string } = {
  [EOrderAttachmentType.PREBILL]: 'Prebill',
  [EOrderAttachmentType.INVOICE]: 'Invoice',
  [EOrderAttachmentType.RETURN_LABEL]: 'Return Label',
  [EOrderAttachmentType.OTHER]: 'Other',
};

export function maybeGetOrderAttachmentTypeLabel(rawOrderAttachmentType: string): string {
  const orderAttachmentType = maybeGetOrderAttachmentType(rawOrderAttachmentType);
  return orderAttachmentType !== undefined
    ? ensureDef(ORDER_ATTACHMENT_TYPE_LABELS[orderAttachmentType])
    : rawOrderAttachmentType;
}
