import { Typography } from '@src/components/appearance/basics/Typography';
import { Button } from '@src/components/appearance/controls/Button';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { EErrorClientCode } from '@src/gen/shared/enums/errorClientCode';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import type { TErrorSummary } from '@src/modules/errors/errorSummary';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TErrorAnnouncementBase = {
  errorSummary: TErrorSummary;
};

export type TErrorAnnouncement = TProps<false, TErrorAnnouncementBase, 'div'>;
export const ERROR_ANNOUNCEMENT_CLASS_NAME = 'wp-error-announcement';

export const ErrorAnnouncement = withCssToString(
  ERROR_ANNOUNCEMENT_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TErrorAnnouncement>(({ errorSummary, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, ERROR_ANNOUNCEMENT_CLASS_NAME), [className]);

      const handleGoToHome = useCallback(() => window.location.replace('/'), []);
      const handleReload = useCallback(() => window.location.reload(), []);

      return (
        <Announcement {...rest} className={joinedClassName} ref={ref}>
          <Typography.SubTitle text={errorSummary.display.title} />
          <Typography.Small text={errorSummary.display.message} />
          <Button
            onClick={errorSummary.code === EErrorClientCode.NOT_FOUND ? handleGoToHome : handleReload}
            text={errorSummary.code === EErrorClientCode.NOT_FOUND ? 'Go to Home' : 'Reload Page'}
          />
        </Announcement>
      );
    }),
  ),
);
