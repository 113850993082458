import type { EShip24StatusCategory } from '@src/gen/shared/enums/ship24StatusCategory';
import type { EShip24StatusCode } from '@src/gen/shared/enums/ship24StatusCode';
import type { EShip24StatusMilestone } from '@src/gen/shared/enums/ship24StatusMilestone';
import assert from 'assert';

export type TTrackerResult = {
  snapshot: TTrackerResultSnapshot;
  events: TTrackerResultEvent[];
};

export function castTrackingUnsafe(tracking: unknown): TTrackerResult {
  assert(typeof tracking === 'object');
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return tracking as TTrackerResult;
}

export type TTrackerResultSnapshot = {
  shipmentId: string | null;
  statusMilestone: EShip24StatusMilestone | null;
  statusCategory: EShip24StatusCategory | null;
  statusCode: EShip24StatusCode | null;
  deliveryEstimatedDeliveryDate: string | null;
  deliveryService: string | null;
  deliverySignedBy: string | null;
};

export type TTrackerResultEvent = {
  eventId: string;
  statusMilestone: EShip24StatusMilestone | null;
  statusCategory: EShip24StatusCategory | null;
  statusCode: EShip24StatusCode | null;
  displayStatus: string | null;
  displayLocation: string | null;
  at: string;
  atOrder: number | null;
};
