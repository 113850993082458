import { Chrome } from '@src/components/appearance/structure/Chrome';
import { OrderSnapshotStructureGroup } from '@src/components/mixins/groups/OrderSnapshotStructureGroup';
import type { AgentOrderSnapshotManager } from '@src/gen/shared/data/agentOrders';
import type { CustomerOrderSnapshotManager } from '@src/gen/shared/data/customerOrders';
import type { OrderBudgetAssistantManager } from '@src/gen/shared/data/orderBudgetAssistantManager';
import { maybeGetOrderSnapshotTypeLabel } from '@src/gen/shared/enums/orderSnapshotType';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderSnapshotReviewChromePanelBase = {
  beginOrderSnapshotEntryPlanQuantityEdit: TCustomerOrderActionsTypes['BeginOrderSnapshotEntryPlanQuantityEdit'] | null;
  beginOrderSnapshotEntryView: TAgentOrderActionsTypes['BeginOrderSnapshotEntryView'];
  beginOrderSnapshotReject?: TCustomerOrderActionsTypes['BeginOrderSnapshotReject'] | null;
  doOrderSnapshotApproveAsyncNotify?: TCustomerOrderActionsTypes['DoOrderSnapshotApproveAsyncNotify'] | null;
  orderBudgetAssistantManager: OrderBudgetAssistantManager | null;
  orderSnapshotManager: CustomerOrderSnapshotManager | AgentOrderSnapshotManager;
};

export type TOrderSnapshotReviewChromePanel = TProps<false, TOrderSnapshotReviewChromePanelBase, 'div'>;
export const ORDER_SNAPSHOT_REVIEW_CHROME_PANEL_CLASS_NAME = 'wp-order-snapshot-review-chrome-panel';

export const OrderSnapshotReviewChromePanel = withCssToString(
  ORDER_SNAPSHOT_REVIEW_CHROME_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderSnapshotReviewChromePanel>(
      (
        {
          beginOrderSnapshotEntryPlanQuantityEdit,
          beginOrderSnapshotEntryView,
          beginOrderSnapshotReject,
          doOrderSnapshotApproveAsyncNotify,
          orderBudgetAssistantManager,
          orderSnapshotManager,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_SNAPSHOT_REVIEW_CHROME_PANEL_CLASS_NAME),
          [className],
        );

        return (
          <Chrome.Panel {...rest} className={joinedClassName} ref={ref}>
            <Chrome.Header
              title={`${maybeGetOrderSnapshotTypeLabel(orderSnapshotManager.orderSnapshot.type)} - Awaiting Approval`}
            />
            <Chrome.ScrollContent>
              <OrderSnapshotStructureGroup
                isSummary={false}
                beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
                orderSnapshotManager={orderSnapshotManager}
                orderBudgetAssistantManager={orderBudgetAssistantManager}
                beginOrderSnapshotEntryPlanQuantityEdit={beginOrderSnapshotEntryPlanQuantityEdit}
              />
            </Chrome.ScrollContent>
            <Chrome.Footer
              buttonAction={
                isDef(doOrderSnapshotApproveAsyncNotify)
                  ? {
                      isAsync: true,
                      onClick: async (): Promise<void> =>
                        await doOrderSnapshotApproveAsyncNotify({
                          orderSnapshotId: orderSnapshotManager.orderSnapshot.id,
                        }),
                      text: 'Approve Prebill',
                    }
                  : {
                      isAsync: false,
                      onClick: noop,
                      text: 'Approve Prebill',
                      variant: 'disabled',
                    }
              }
              secondaryButtonAction={
                isDef(beginOrderSnapshotReject)
                  ? {
                      isAsync: false,
                      onClick: (): void =>
                        beginOrderSnapshotReject({
                          orderSnapshotId: orderSnapshotManager.orderSnapshot.id,
                        }),
                      text: 'Reject Prebill',
                      variant: 'secondary',
                    }
                  : {
                      isAsync: false,
                      onClick: noop,
                      text: 'Reject Prebill',
                      variant: 'disabled',
                    }
              }
            />
          </Chrome.Panel>
        );
      },
    ),
  ),
);
