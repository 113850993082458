import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TIcon, TProps } from '@src/modules/design/theme';
import { getIconComponent, styled, theme } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SButton = styled('button', {
  all: 'unset',

  alignItems: 'center',
  borderRadius: '$controlButton',
  borderStyle: '$regular',
  borderWidth: '$regular',
  display: 'flex',
  gap: '$cardGap',
  flexShrink: 0,
  height: '$controlButtonHeight',
  justifyContent: 'center',
  paddingX: '$controlButtonPaddingX',

  variants: {
    variant: {
      default: {
        backgroundColor: '$controlButtonDefaultBackground',
        borderColor: '$controlButtonDefaultBorder',
        color: '$controlButtonDefaultText',
        cursor: 'pointer',

        '&:hover': {
          borderColor: '$controlButtonDefaultHoverBorder',
        },

        '&:active': {
          borderColor: '$controlButtonDefaultActiveBorder',
          color: '$controlButtonDefaultActiveText',

          [`${Typography.Small.toString()}`]: {
            color: '$controlButtonDefaultActiveText',
          },
        },

        '&:focus': {
          boxShadow: '$secondaryFocus',
        },

        [`${Typography.Small.toString()}`]: {
          color: '$controlButtonDefaultText',
        },
      },
      disabled: {
        backgroundColor: '$controlButtonDisabledBackground',
        borderStyle: '$disabled',
        color: '$controlButtonDisabledText',
        cursor: 'not-allowed',

        [`${Typography.Small.toString()}`]: {
          color: '$controlButtonDisabledText',
        },
      },
      success: {
        backgroundColor: '$controlButtonSuccessBackground',
        borderColor: '$controlButtonDefaultBorder',
        color: '$controlButtonDefaultText',
        cursor: 'pointer',
        '&:hover': {
          borderColor: '$controlButtonDefaultHoverBorder',
        },

        '&:active': {
          borderColor: '$controlButtonDefaultActiveBorder',
          color: '$controlButtonDefaultActiveText',

          [`${Typography.Small.toString()}`]: {
            color: '$controlButtonDefaultActiveText',
          },
        },

        '&:focus': {
          boxShadow: '$secondaryFocus',
        },

        [`${Typography.Small.toString()}`]: {
          color: '$controlButtonDefaultText',
        },
      },
      loading: {
        backgroundColor: '$controlButtonLoadingBackground',
        borderColor: '$controlButtonLoadingBorder',
        color: '$controlButtonLoadingText',
        cursor: 'default',

        [`${Typography.Small.toString()}`]: {
          color: '$controlButtonLoadingText',
        },
      },
    },
  },
});

export type TControlButtonBase = {
  icon?: TIcon | undefined;
  text: string;
  variant?: 'default' | 'disabled' | 'loading' | 'success' | undefined;
};

export type TControlButton = Omit<TProps<false, TControlButtonBase, 'button'>, 'disabled'>;
export const CONTROL_BUTTON_CLASS_NAME = 'wp-control-button';

export function getControlButtonVariant(options: {
  disabled: boolean;
  loading: boolean;
}): 'default' | 'disabled' | 'loading' {
  if (options.disabled) {
    return 'disabled';
  } else if (options.loading) {
    return 'loading';
  } else {
    return 'default';
  }
}

export const ControlButton = withCssToString(
  CONTROL_BUTTON_CLASS_NAME,
  memo(
    forwardRef<HTMLButtonElement, TControlButton>(({ icon, text, variant, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, CONTROL_BUTTON_CLASS_NAME), [className]);
      const IconComponent = isDef(icon) ? getIconComponent(icon) : undefined;

      return (
        <SButton
          {...rest}
          className={joinedClassName}
          disabled={variant === 'disabled' || variant === 'loading'}
          ref={ref}
          variant={variant ?? 'default'}>
          <Typography.Small expanding={true} rigid={true} text={text} />
          {variant === 'loading' && <Illustrations.Spinner />}
          {variant !== 'loading' && isDef(IconComponent) && (
            <IconComponent
              height={theme.sizes.controlButtonIconSize.value}
              width={theme.sizes.controlButtonIconSize.value}
              style={{ flexShrink: 0 }}
            />
          )}
        </SButton>
      );
    }),
  ),
);

export const ControlButtonLoader = memo(
  forwardRef<HTMLButtonElement, TEmptyObject>(({}, ref) => (
    <SButton disabled={true} variant='disabled' css={{ flexDirection: 'row', justifyContent: 'flex-start' }} ref={ref}>
      <Illustrations.Loader height={10} uniqueKey='wp-control-button-loader' width={96}>
        <rect x='0' y='0' rx='0' ry='0' width='96' height='10' />
      </Illustrations.Loader>
    </SButton>
  )),
);
