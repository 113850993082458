import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderPlacedCard } from '@src/components/mixins/cards/OrderPlacedCard';
import { OrderSnapshotCard } from '@src/components/mixins/cards/OrderSnapshotCard';
import type { TAgentOrderCompleteFragment, TCustomerOrderCompleteFragment } from '@src/gen/graphql/bindings';
import type { AgentOrderManager } from '@src/gen/shared/data/agentOrders';
import type { CustomerOrderManager } from '@src/gen/shared/data/customerOrders';
import { EOrderSnapshotType } from '@src/gen/shared/enums/orderSnapshotType';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderDocumentsStructureGroupBase = {
  beginOrderOriginalCartView:
    | TAgentOrderActionsTypes['BeginOrderOriginalCartView']
    | TCustomerOrderActionsTypes['BeginOrderOriginalCartView'];
  beginOrderSnapshotView:
    | TAgentOrderActionsTypes['BeginOrderSnapshotView']
    | TCustomerOrderActionsTypes['BeginOrderSnapshotView'];
  order: TAgentOrderCompleteFragment | TCustomerOrderCompleteFragment;
  orderManager: AgentOrderManager | CustomerOrderManager;
};

export type TOrderDocumentsStructureGroup = TProps<false, TOrderDocumentsStructureGroupBase, 'div'>;
export const ORDER_DOCUMENTS_STRUCTURE_GROUP_CLASS_NAME = 'wp-order-documents-structure-group';

export const OrderDocumentsStructureGroup = withCssToString(
  ORDER_DOCUMENTS_STRUCTURE_GROUP_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderDocumentsStructureGroup>(
      (
        { beginOrderOriginalCartView, beginOrderSnapshotView, order, orderManager, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_DOCUMENTS_STRUCTURE_GROUP_CLASS_NAME),
          [className],
        );

        return (
          <Structure.Group {...rest} className={joinedClassName} ref={ref} title='Documents'>
            <OrderPlacedCard order={order} onClick={beginOrderOriginalCartView} />
            {orderManager.orderSnapshotManagers
              .filter(
                (osm) =>
                  osm.orderSnapshot.type === EOrderSnapshotType.INVOICE ||
                  isDef(osm.orderSnapshot.approved_at) ||
                  isDef(osm.orderSnapshot.rejected_at),
              )
              .map((osm) => (
                <OrderSnapshotCard
                  key={osm.orderSnapshot.id}
                  onClick={(): void => beginOrderSnapshotView({ orderSnapshotId: osm.orderSnapshot.id })}
                  orderSnapshotManager={osm}
                />
              ))}
          </Structure.Group>
        );
      },
    ),
  ),
);
