import { OrderSnapshotReviewChromePanel } from '@src/components/mixins/chromes/OrderSnapshotReviewChromePanel';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrderBudgetAssistant } from '@src/modules/data/agent/order/AgentOrderBudgetAssistantProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import { InlineChrome, useInlineChrome } from '@src/modules/design/ChromeProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import assert from 'assert';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TAgentOrderSnapshotReviewChromeTypes = {
  BeginOrderSnapshotReviewArgs: {
    orderSnapshotId: string;
  };
  BeginOrderSnapshotReview: (args: TAgentOrderSnapshotReviewChromeTypes['BeginOrderSnapshotReviewArgs']) => void;
};

export type TAgentOrderSnapshotReviewChromeContext = {
  beginOrderSnapshotReview: TAgentOrderSnapshotReviewChromeTypes['BeginOrderSnapshotReview'];
};

export const { Context: AgentOrderSnapshotReviewChromeContext, useContext: useAgentOrderSnapshotReviewChrome } =
  createRequiredContext<TAgentOrderSnapshotReviewChromeContext>();

export function AgentOrderSnapshotReviewChromeProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const inlineChrome = useInlineChrome<TAgentOrderSnapshotReviewChromeTypes['BeginOrderSnapshotReviewArgs']>();

  const beginOrderSnapshotReview = useCallback<TAgentOrderSnapshotReviewChromeTypes['BeginOrderSnapshotReview']>(
    (args) => {
      inlineChrome.doChromeOpen({
        metadata: args,
        drawerChildren: <Panel orderSnapshotId={args.orderSnapshotId} />,
      });
    },
    [inlineChrome],
  );

  const value = useMemo<TAgentOrderSnapshotReviewChromeContext>(
    () => ({
      beginOrderSnapshotReview,
    }),
    [beginOrderSnapshotReview],
  );

  return (
    <AgentOrderSnapshotReviewChromeContext.Provider value={value}>
      <InlineChrome {...inlineChrome} />
      {children}
    </AgentOrderSnapshotReviewChromeContext.Provider>
  );
}

type TPanel = {
  orderSnapshotId: string;
};

function Panel({ orderSnapshotId }: TPanel): JSX.Element {
  const { orderManager } = useAgentOrder();
  const { beginOrderSnapshotEntryView } = useAgentOrderActions();
  const { orderBudgetAssistantManager } = useAgentOrderBudgetAssistant();

  const orderSnapshotManager = orderManager.mustGetOrderSnapshot(orderSnapshotId);

  assert(
    orderSnapshotManager.orderSnapshot.type === 'prebill' &&
      !isDef(orderSnapshotManager.orderSnapshot.approved_at) &&
      !isDef(orderSnapshotManager.orderSnapshot.rejected_at),
  );

  return (
    <OrderSnapshotReviewChromePanel
      beginOrderSnapshotEntryPlanQuantityEdit={null}
      beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
      beginOrderSnapshotReject={null}
      doOrderSnapshotApproveAsyncNotify={null}
      orderBudgetAssistantManager={orderBudgetAssistantManager}
      orderSnapshotManager={orderSnapshotManager}
    />
  );
}
