import { NetworkStatus } from '@apollo/client';
import type { AgentUsersForPickerQueryVariables, TAgentUserBaseFragment } from '@src/gen/graphql/bindings';
import { useAgentUsersForPickerQuery } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, mergeGetMore } from '@src/modules/graphql/utils';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TAgentUsersForPickerContext = {
  users: TAgentUserBaseFragment[];
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const { Context: AgentUsersForPickerContext, useContext: useAgentUsersForPicker } =
  createRequiredContext<TAgentUsersForPickerContext>();

export type TAgentUsersForPickerProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  isWellplaeceAgent: boolean;
}>;

export function AgentUsersForPickerProvider({
  LoaderComponent,
  isWellplaeceAgent,
  children,
}: TAgentUsersForPickerProvider): JSX.Element {
  const variables = useMemo<AgentUsersForPickerQueryVariables>(
    () => ({
      isWellplaeceAgent,
      limit: DEFAULT_LIMIT,
      offset: 0,
    }),
    // @sort
    [isWellplaeceAgent],
  );

  const { data, error, fetchMore, networkStatus } = useAgentUsersForPickerQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          offset: ensureDef(data?.users.length),
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          users: mergeGetMore(prev.users, fetchMoreResult.users),
        }),
      });

      return newData.users.length;
    },
    // @sort
    [fetchMore, data?.users.length],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.users.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TAgentUsersForPickerContext | undefined>(
    () =>
      isDef(data)
        ? {
            users: data.users,
            loaderRef: loaderRef,
          }
        : undefined,
    // @sort
    [data, loaderRef],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentUsersForPickerContext.Provider value={value}>{children}</AgentUsersForPickerContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
