import { Typography } from '@src/components/appearance/basics/Typography';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderBackOrderListingCard } from '@src/components/mixins/cards/OrderBackOrderListingCard';
import { OrderCardLoader } from '@src/components/mixins/cards/OrderCard';
import type { TAgentOrderBackOrderCompleteWithOrderSummaryFragment } from '@src/gen/graphql/bindings';
import { useAgentOrderBackOrdersWaiting } from '@src/modules/data/agent/orderBackOrders/AgentOrderBackOrdersWaitingProvider';
import { encodeAgentOrderWorksheetBackOrdersPath } from '@src/modules/routing/agent';
import { Fragment, memo, useMemo } from 'react';

export function AgentOrderBackOrdersPart(): JSX.Element {
  const { waitingForCustomerDecision, waitingForRemoval, waitingForShipment } = useAgentOrderBackOrdersWaiting();

  return (
    <Structure.ScrollContent skipSeparator={true}>
      <Structure.Stack>
        {waitingForCustomerDecision.length === 0 &&
          waitingForRemoval.length === 0 &&
          waitingForShipment.length === 0 && (
            <Announcement css={{ paddingTop: '128px' }}>
              <Fragment>
                <Typography.SubTitle text='Nothing to See Here' />
                <Typography.Small text='There are currently no active back-orders.' />
              </Fragment>
            </Announcement>
          )}
        {waitingForCustomerDecision.length > 0 && (
          <Structure.Group title='Waiting for Customer Decision'>
            {waitingForCustomerDecision.map((obo) => (
              <CardComponent key={obo.id} orderBackOrder={obo} />
            ))}
          </Structure.Group>
        )}
        {waitingForRemoval.length > 0 && (
          <Structure.Group title='Waiting for Removal from Order'>
            {waitingForRemoval.map((obo) => (
              <CardComponent key={obo.id} orderBackOrder={obo} />
            ))}
          </Structure.Group>
        )}
        {waitingForShipment.length > 0 && (
          <Structure.Group title='Waiting for Fulfillment'>
            {waitingForShipment.map((obo) => (
              <CardComponent key={obo.id} orderBackOrder={obo} />
            ))}
          </Structure.Group>
        )}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function CardComponent({
  orderBackOrder,
}: {
  orderBackOrder: TAgentOrderBackOrderCompleteWithOrderSummaryFragment;
}): JSX.Element {
  const to = useMemo(() => encodeAgentOrderWorksheetBackOrdersPath(orderBackOrder.order_id), [orderBackOrder.order_id]);
  return <OrderBackOrderListingCard to={to} orderBackOrder={orderBackOrder} />;
}

export const AgentOrderBackOrdersPartLoader = memo(
  (): JSX.Element => (
    <Fragment>
      <Structure.ScrollContentLoader>
        <Structure.Stack>
          <Structure.GroupLoader hasTitle={true}>
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
          </Structure.GroupLoader>
        </Structure.Stack>
      </Structure.ScrollContentLoader>
    </Fragment>
  ),
);
