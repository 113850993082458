import { Typography } from '@src/components/appearance/basics/Typography';
import { Structure } from '@src/components/appearance/structure/Structure';
import { TertiaryNav } from '@src/components/appearance/structure/TertiaryNav';
import { NextOrderResultsEmptyAnnouncement } from '@src/components/mixins/announcements/NextOrderResultsEmptyAnnouncement';
import { NextOrderProductCardLoader } from '@src/components/mixins/cards/NextOrderProductCard';
import { AgentCustomersOrganizationFormularyProductCardPart } from '@src/components/parts/agent/customers/organizations/AgentCustomersOrganizationFormularyProductCardPart';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentCustomersOrganizationFormularySearch } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationFormularySearchProvider';
import { useAgentCustomersOrganization } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import { CONTAINER_MEDIA_DESKTOP } from '@src/modules/design/breakpoints';
import { styled } from '@src/modules/design/theme';
import {
  encodeAgentCustomersOrganizationFormularyPath,
  useAgentCustomersOrganizationFormularyRouteParams,
} from '@src/modules/routing/agent';
import { useNavigate } from 'react-router-dom';

const SFiltersDiv = styled('div', {
  display: 'none',

  [CONTAINER_MEDIA_DESKTOP]: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '24px',
    hideScrollbars: true,
    overflow: 'scroll',
    padding: '0px',
  },
});

const SFiltersInnerDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  alignItems: 'stretch',
});

export function AgentCustomersOrganizationFormularySearchPart(): JSX.Element {
  const { searchResponse, loaderRef } = useAgentCustomersOrganizationFormularySearch();

  const hasAggrs =
    isDef(searchResponse.sourceAggregation) ||
    isDef(searchResponse.categoryName01Aggregation) ||
    isDef(searchResponse.categoryName02Aggregation) ||
    isDef(searchResponse.manufacturerNameAggregation);

  if (!hasAggrs) {
    return (
      <Structure.ScrollContent>
        {searchResponse.results.length === 0 ? (
          <NextOrderResultsEmptyAnnouncement />
        ) : (
          <Structure.Grid>
            {searchResponse.results.map((result) => (
              <AgentCustomersOrganizationFormularyProductCardPart
                key={result.legacyProduct.id}
                product={result.legacyProduct}
              />
            ))}
            {isDef(loaderRef) && <NextOrderProductCardLoader hasQuantity={true} ref={loaderRef} />}
          </Structure.Grid>
        )}
      </Structure.ScrollContent>
    );
  }

  return (
    <Structure.ScrollContent>
      <Structure.Columns sideChildren={<Filters />}>
        <Structure.Grid>
          {searchResponse.results.length === 0 && <NextOrderResultsEmptyAnnouncement />}
          {searchResponse.results.map((result) => (
            <AgentCustomersOrganizationFormularyProductCardPart
              key={result.legacyProduct.id}
              product={result.legacyProduct}
            />
          ))}
          {isDef(loaderRef) && <NextOrderProductCardLoader hasQuantity={true} ref={loaderRef} />}
        </Structure.Grid>
      </Structure.Columns>
    </Structure.ScrollContent>
  );
}

function Filters(): JSX.Element {
  const navigate = useNavigate();
  const { organization } = useAgentCustomersOrganization();
  const routeParams = useAgentCustomersOrganizationFormularyRouteParams();
  const { searchResponse } = useAgentCustomersOrganizationFormularySearch();

  return (
    <SFiltersDiv>
      {isDef(searchResponse.sourceAggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Catalog' css={{ paddingBottom: '4px' }} />
          {searchResponse.sourceAggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                navigate(
                  encodeAgentCustomersOrganizationFormularyPath(organization.id, {
                    ...routeParams.config,
                    sourceFilter: bucket.value,
                  }),
                )
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
      {isDef(searchResponse.categoryName01Aggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Category' css={{ paddingBottom: '4px' }} />
          {searchResponse.categoryName01Aggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                navigate(
                  encodeAgentCustomersOrganizationFormularyPath(organization.id, {
                    ...routeParams.config,
                    categoryName01Filter: bucket.value,
                  }),
                )
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
      {isDef(searchResponse.categoryName02Aggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Sub-Category' css={{ paddingBottom: '4px' }} />
          {searchResponse.categoryName02Aggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                navigate(
                  encodeAgentCustomersOrganizationFormularyPath(organization.id, {
                    ...routeParams.config,
                    categoryName02Filter: bucket.value,
                  }),
                )
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
      {isDef(searchResponse.manufacturerNameAggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Manufacturer' css={{ paddingBottom: '4px' }} />
          {searchResponse.manufacturerNameAggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                navigate(
                  encodeAgentCustomersOrganizationFormularyPath(organization.id, {
                    ...routeParams.config,
                    manufacturerNameFilter: bucket.value,
                  }),
                )
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
    </SFiltersDiv>
  );
}

export function AgentCustomersOrganizationFormularySearchPartLoader(): JSX.Element {
  return (
    <Structure.ScrollContentLoader>
      <Structure.Grid>
        <NextOrderProductCardLoader hasQuantity={true} />
        <NextOrderProductCardLoader hasQuantity={true} />
        <NextOrderProductCardLoader hasQuantity={true} />
        <NextOrderProductCardLoader hasQuantity={true} />
        <NextOrderProductCardLoader hasQuantity={true} />
        <NextOrderProductCardLoader hasQuantity={true} />
        <NextOrderProductCardLoader hasQuantity={true} />
        <NextOrderProductCardLoader hasQuantity={true} />
        <NextOrderProductCardLoader hasQuantity={true} />
        <NextOrderProductCardLoader hasQuantity={true} />
      </Structure.Grid>
    </Structure.ScrollContentLoader>
  );
}
