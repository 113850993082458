import type { AgentVerifyUploadMutation } from '@src/gen/graphql/bindings';
import { useAgentBeginUploadMutation, useAgentVerifyUploadMutation } from '@src/gen/graphql/bindings';
import { castPresignedPostUnsafe } from '@src/gen/shared/data/presignedPost';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TAgentUploadsTypes = {
  DoUploadAsync: (file: File) => Promise<NonNullable<AgentVerifyUploadMutation['verifyUpload']>>;
};

export type TAgentUploadsContext = {
  doUploadAsync: TAgentUploadsTypes['DoUploadAsync'];
};

export const { Context: AgentUploadsContext, useContext: useAgentUploads } =
  createRequiredContext<TAgentUploadsContext>();

export function AgentUploadsProvider({ children }: PropsWithChildren): JSX.Element {
  const doUploadAsync = useInitDoUploadAsync();

  const value = useMemo<TAgentUploadsContext>(
    () => ({
      doUploadAsync,
    }),
    [doUploadAsync],
  );

  return <AgentUploadsContext.Provider value={value}>{children}</AgentUploadsContext.Provider>;
}

function useInitDoUploadAsync(): TAgentUploadsTypes['DoUploadAsync'] {
  const [beginUploadMutation] = useAgentBeginUploadMutation();
  const [verifyUploadMutation] = useAgentVerifyUploadMutation();

  return useCallback<TAgentUploadsTypes['DoUploadAsync']>(
    async (file) => {
      const { data: beginUpload, errors: beginUploadErrors } = await beginUploadMutation();

      if (isDef(beginUploadErrors)) {
        throw beginUploadErrors;
      }

      const defBeginUpload = ensureDef(beginUpload?.beginUpload);
      const presignedPost = castPresignedPostUnsafe(defBeginUpload.presignedPost);
      const formData = new FormData();

      for (const [key, value] of Object.entries(presignedPost.fields)) {
        formData.append(key, value);
      }

      formData.append('file', file);

      const resp = await fetch(presignedPost.url, {
        method: 'post',
        body: formData,
      });

      const body = await resp.text();

      if (!resp.ok) {
        throw new Error(`Upload returned ${resp.status}: "${body}".`);
      }

      const { data: verifyUpload, errors: verifyUploadErrors } = await verifyUploadMutation({
        variables: {
          uploadId: defBeginUpload.uploadId,
        },
      });

      if (isDef(verifyUploadErrors)) {
        throw verifyUploadErrors;
      }

      return ensureDef(verifyUpload?.verifyUpload);
    },
    [beginUploadMutation, verifyUploadMutation],
  );
}
