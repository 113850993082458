import { ItemsBannerLoader } from '@src/components/appearance/fragments/ItemsBanner';
import type { TSecondaryNavTab } from '@src/components/appearance/structure/SecondaryNav';
import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { useAgentCustomersOrganization } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import {
  EAgentRoutes,
  encodeAgentCustomersOrganizationAnalyticsOrdersPath,
  encodeAgentCustomersOrganizationFormularyPath,
  encodeAgentCustomersOrganizationLocationsPath,
  encodeAgentCustomersOrganizationOverviewPath,
  encodeAgentCustomersOrganizationUsersPath,
  useAgentCustomersOrganizationRouteParams,
} from '@src/modules/routing/agent';
import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentCustomersOrganizationNavPart(): JSX.Element {
  const { organization } = useAgentCustomersOrganization();
  const tabs = useTabs();

  return <SecondaryNav title='Organization' label={organization.name} tabs={tabs} />;
}

export function AgentCustomersOrganizationNavPartLoader(): JSX.Element {
  const tabs = useTabs();

  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav tabs={tabs} tagLoader={true} title='Organization' />
      </Structure.Content>
      <Structure.ScrollContentLoader>
        <Structure.GroupLoader hasTitle={true}>
          <ItemsBannerLoader count={4} />
        </Structure.GroupLoader>
      </Structure.ScrollContentLoader>
    </Fragment>
  );
}

function useTabs(): TSecondaryNavTab[] {
  const routeParams = useAgentCustomersOrganizationRouteParams();
  const navigate = useNavigate();

  return useMemo<TSecondaryNavTab[]>(
    () => [
      {
        active: routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_OVERVIEW,
        title: 'Overview',
        onClick: () => navigate(encodeAgentCustomersOrganizationOverviewPath(routeParams.organizationId)),
      },
      {
        active: routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_USERS,
        title: 'Users',
        onClick: () => navigate(encodeAgentCustomersOrganizationUsersPath(routeParams.organizationId)),
      },
      {
        active: routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_LOCATIONS,
        title: 'Locations',
        onClick: () => navigate(encodeAgentCustomersOrganizationLocationsPath(routeParams.organizationId)),
      },
      {
        active: routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_FORMULARY,
        title: 'Formulary',
        onClick: () =>
          navigate(
            encodeAgentCustomersOrganizationFormularyPath(routeParams.organizationId, {
              query: null,
              sourceFilter: null,
              categoryName01Filter: null,
              categoryName02Filter: null,
              manufacturerNameFilter: null,
            }),
          ),
      },
      {
        active: routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_ORGANIZATION_ANALYTICS_ORDERS,
        title: 'Analytics',
        onClick: () =>
          navigate(
            encodeAgentCustomersOrganizationAnalyticsOrdersPath(routeParams.organizationId, {
              locationId: null,
              startTime: null,
              endTime: null,
            }),
          ),
      },
    ],
    [routeParams.route, routeParams.organizationId, navigate],
  );
}
