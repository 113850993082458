import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'center',
  backgroundColor: '$avatarBackground',
  borderRadius: '$avatar',
  display: 'inline-flex',
  flexShrink: 0,
  height: '$avatarSize',
  justifyContent: 'center',
  width: '$avatarSize',
});

export type TAvatarBase = {
  firstName: string;
  lastName: string;
};

export type TAvatar = TProps<false, TAvatarBase, 'div'>;
export const AVATAR_CLASS_NAME = 'wp-avatar';

export const Avatar = withCssToString(
  AVATAR_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TAvatar>(({ firstName, lastName, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, AVATAR_CLASS_NAME), [className]);

      const initials = useMemo(
        () => `${firstName.substring(0, 1).toUpperCase()}${lastName.substring(0, 1).toUpperCase()}`,
        // @sort
        [firstName, lastName],
      );

      return (
        <SDiv {...rest} className={joinedClassName} ref={ref}>
          <Typography.Cta text={initials} />
        </SDiv>
      );
    }),
  ),
);

export const AvatarLoader = memo(
  forwardRef<SVGSVGElement, TEmptyObject>(({}, ref) => (
    <Illustrations.Loader height={32} ref={ref} uniqueKey='wp-avatar-loader' width={32}>
      <circle cx='16' cy='16' r='16' />
    </Illustrations.Loader>
  )),
);
