import coverBackgroundJpg from '@src/assets/cover-background.jpg';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$coverBackground',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100dvh',

  '@desktop': {
    flexDirection: 'row',
  },
});

const SIllustrationsLogo = styled(Illustrations.Logo, {
  flexShrink: 0,
  height: '$coverLogoHeightMobile',
  left: '$coverSpaceMobile',
  position: 'absolute',
  top: '$coverSpaceMobile',

  '@desktop': {
    height: '$coverLogoHeightDesktop',
    left: '$coverSpaceDesktop',
    top: '$coverSpaceDesktop',
  },
});

const SIllustrationsWelcome = styled(Illustrations.Welcome, {
  alignSelf: 'flex-start',
  height: '$coverWelcomeHeightMobile',
  marginBottom: '$coverSpaceMobile',
  marginTop: '$coverWelcomeMarginTopMobile',
  maxWidth: '100%',

  '@desktop': {
    alignSelf: 'center',
    height: '$coverWelcomeHeightDesktop',
    marginTop: '0px',
    marginBottom: '0px',
  },
});

const SWelcomeDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  gap: '$coverSpaceMobile',
  justifyContent: 'center',
  padding: '$coverSpaceMobile',
  position: 'relative',

  '@desktop': {
    flexBasis: '50%',
    flexGrow: 1,
    gap: '$coverSpaceDesktop',
    padding: '$coverSpaceDesktop',
  },
});

const SContentDiv = styled('div', {
  alignItems: 'center',
  backgroundColor: '$coverContentPlainBackground',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  flexShrink: 0,
  justifyContent: 'center',
  padding: '$coverSpaceMobile',

  '@desktop': {
    flexBasis: '50%',
    padding: '$coverSpaceDesktop',
  },

  variants: {
    background: {
      plain: {
        // do nothing
      },
      image: {
        backgroundImage: `url(${coverBackgroundJpg})`,
        backgroundColor: '$coverContentImageBackground',

        [`& ${Illustrations.Spinner.toString()}`]: {
          color: '$white',
        },

        [`& ${Typography.Caption.toString()}`]: {
          color: '$white',
        },
      },
    },
  },
});

const SContentInnerDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  maxWidth: '$coverContentMaxWidth',
});

export type TCoverBase = {
  background?: 'image' | 'plain' | undefined;
};

export type TCover = TProps<true, TCoverBase, 'div'>;
const COVER_CLASS_NAME = 'wp-cover';

export const Cover = withCssToString(
  COVER_CLASS_NAME,
  forwardRef<HTMLDivElement, TCover>(({ background = 'plain', children, className, ...rest }, ref): JSX.Element => {
    const joinedClassName = useMemo(() => joinClassNames(className, COVER_CLASS_NAME), [className]);

    return (
      <SDiv {...rest} className={joinedClassName} ref={ref}>
        <SWelcomeDiv>
          <SIllustrationsLogo variant='inverted' />
          <SIllustrationsWelcome />
        </SWelcomeDiv>
        <SContentDiv background={background}>
          <SContentInnerDiv>{children}</SContentInnerDiv>
        </SContentDiv>
      </SDiv>
    );
  }),
);
