import { Typography } from '@src/components/appearance/basics/Typography';
import { Button } from '@src/components/appearance/controls/Button';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

const STitleGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '4px',
});

export type TCompleteOnboardingAnnouncement = TProps<false, { organizationName: string }, 'div'>;
export const COMPLETE_ONBOARDING_ANNOUNCEMENT_CLASS_NAME = 'wp-complete-onboarding-announcement';

export const CompleteOnboardingAnnouncement = withCssToString(
  COMPLETE_ONBOARDING_ANNOUNCEMENT_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TCompleteOnboardingAnnouncement>(
      ({ organizationName, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, COMPLETE_ONBOARDING_ANNOUNCEMENT_CLASS_NAME),
          [className],
        );

        return (
          <Announcement {...rest} className={joinedClassName} ref={ref}>
            <Fragment>
              <STitleGroup>
                <Typography.Regular text='Ready to claim...' />
                <Typography.SubTitle text={`${organizationName}'s`} />
                <Typography.Regular text='...Wellplaece account?' />
              </STitleGroup>
              <Typography.Small text='You’re one step away from activating personalized formularies, additional locations, and from reclaiming significant $$ for your practice with every order!' />
              <Button
                onClick={(): void => {
                  window.open('https://www.wellplaece.com/get-started', '_self');
                }}
                text='Complete Onboarding Now!'
              />
            </Fragment>
          </Announcement>
        );
      },
    ),
  ),
);
