import { ResponsivePie } from '@nivo/pie';
import { Typography } from '@src/components/appearance/basics/Typography';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled, theme } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$chartBackground',
  borderRadius: '$chart',
  display: 'flex',
  flexDirection: 'column',
  gap: '$chartGap',
  padding: '$chartPadding',
});

const SChartDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  height: '392px',
  justifyContent: 'stretch',
  padding: '$chartPadding',
});

export type TPieChartDataItem = {
  id: string;
  value: number;
  label: string;
  color: string;
};

export type TPieChartBase = {
  title: string;
  colorsFromData: boolean;
  valueFormat?: (v: number) => string;
  data: TPieChartDataItem[];
};

export type TPieChart = TProps<false, TPieChartBase, 'div'>;
export const PIE_CHART_CLASS_NAME = 'wp-pie-chart';

export const PieChart = withCssToString(
  PIE_CHART_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TPieChart>(
      ({ title, colorsFromData, valueFormat, data, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, PIE_CHART_CLASS_NAME), [className]);

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            <Typography.Strong rigid={true} expanding={true} text={title} />
            <SChartDiv>
              <ResponsivePie<TPieChartDataItem>
                {...(colorsFromData ? { colors: { datum: 'data.color' } } : { colors: { scheme: 'pastel1' } })}
                {...(isDef(valueFormat) ? { valueFormat } : {})}
                data={data}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                innerRadius={0.5}
                legends={[
                  {
                    anchor: 'left',
                    direction: 'column',
                    itemHeight: 22,
                    itemWidth: 150,
                    itemsSpacing: 8,
                    justify: false,
                    translateX: -150,
                    translateY: 0,
                  },
                ]}
                margin={{
                  left: 150,
                }}
                padAngle={0.5}
                theme={{
                  fontFamily: theme.fonts.caption.value,
                  fontSize: 14,
                  tooltip: {
                    container: {
                      fontFamily: theme.fonts.caption.value,
                      fontSize: 16,
                    },
                  },
                }}
              />
            </SChartDiv>
          </SDiv>
        );
      },
    ),
  ),
);
