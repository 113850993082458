import { ActionButton } from '@src/components/appearance/controls/Button';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import type { TAgentCustomersLocationAclBaseFragment } from '@src/gen/graphql/bindings';
import { type TAgentCustomersUserCompleteFragment } from '@src/gen/graphql/bindings';
import type { TArrayElement } from '@src/gen/shared/utils/types';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentCustomersUserActions } from '@src/modules/data/agent/customers/users/AgentCustomersUserActionsProvider';
import { useAgentCustomersUser } from '@src/modules/data/agent/customers/users/AgentCustomersUserProvider';
import { useMemo } from 'react';

export function AgentCustomersUserOrganizationsPart(): JSX.Element {
  const { user } = useAgentCustomersUser();
  const { beginUserOrganizationAdd } = useAgentCustomersUserActions();

  const activeOrgAcls = useMemo(
    () => user.organization_acls.filter((orgAcl) => orgAcl.is_member && !user.is_disabled),
    [user],
  );

  const inactiveOrgAcls = useMemo(
    () => user.organization_acls.filter((orgAcl) => !orgAcl.is_member || user.is_disabled),
    [user],
  );

  const items = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Active Org. Memberships',
        text: `${activeOrgAcls.length}`,
      },
      {
        caption: 'Inactive Org. Memberships',
        text: `${inactiveOrgAcls.length}`,
      },
    ],
    // @sort
    [activeOrgAcls.length, inactiveOrgAcls.length],
  );

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <ItemsBanner
          items={items}
          ButtonElement={
            isDef(beginUserOrganizationAdd) ? (
              <ActionButton
                action={{
                  isAsync: false,
                  onClick: beginUserOrganizationAdd,
                  text: 'Add',
                }}
              />
            ) : undefined
          }
        />
        {user.organization_acls.map((orgAcl) => (
          <OrganizationAclGroupComponent key={orgAcl.organization_id} user={user} organizationAcl={orgAcl} />
        ))}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function OrganizationAclGroupComponent({
  user,
  organizationAcl,
}: {
  user: TAgentCustomersUserCompleteFragment;
  organizationAcl: TArrayElement<TAgentCustomersUserCompleteFragment['organization_acls']>;
}): JSX.Element {
  const { beginUserOrganizationRemove, beginUserOrganizationRestore, beginLocationAclAdd, beginOrganizationAclEdit } =
    useAgentCustomersUserActions();
  const beginUserOrganization = organizationAcl.is_member ? beginUserOrganizationRemove : beginUserOrganizationRestore;

  const locationAclsWithLocations = useMemo(
    () => user.location_acls.filter((locAcl) => locAcl.location.organization_id === organizationAcl.organization_id),

    // @sort
    [organizationAcl.organization_id, user.location_acls],
  );

  const items = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Org. Member Since',
        text: { asDate: 'on', at: organizationAcl.created_at },
      },
      {
        caption: 'Org. Membership Enabled',
        text: { asBoolean: organizationAcl.is_member },
      },
      {
        caption: 'View Analytics',
        text: { asBoolean: organizationAcl.can_view_analytics },
      },
    ],
    // @sort
    [organizationAcl.can_view_analytics, organizationAcl.created_at, organizationAcl.is_member],
  );

  const iconButtonActions = useMemo<TIconButtonAction[]>(
    () =>
      getAvailableIconButtonActions([
        {
          isAsync: false,
          icon: 'edit',
          onClick:
            isDef(beginOrganizationAclEdit) && organizationAcl.is_member
              ? (): void => beginOrganizationAclEdit({ organizationId: organizationAcl.organization_id })
              : undefined,
        },
        {
          isAsync: false,
          icon: organizationAcl.is_member ? 'exit' : 'enter',
          onClick: isDef(beginUserOrganization)
            ? (): void => beginUserOrganization({ organizationId: organizationAcl.organization_id })
            : undefined,
        },
      ]),
    // @sort
    [beginOrganizationAclEdit, beginUserOrganization, organizationAcl.is_member, organizationAcl.organization_id],
  );
  const locationIconButtonActions = useMemo<TIconButtonAction[]>(
    () =>
      getAvailableIconButtonActions([
        {
          isAsync: false,
          icon: 'add',
          onClick: isDef(beginLocationAclAdd)
            ? (): void =>
                beginLocationAclAdd({
                  organizationId: organizationAcl.organization_id,
                  omitLocationIds: locationAclsWithLocations.reduce<{ [key: string]: true }>(
                    (out, locAcl) => ({ ...out, [locAcl.location_id]: true }),
                    {},
                  ),
                })
            : undefined,
        },
      ]),
    // @sort
    [beginLocationAclAdd, locationAclsWithLocations, organizationAcl.organization_id],
  );

  return (
    <Structure.Group title={organizationAcl.organization.name} variant='section' iconButtonActions={iconButtonActions}>
      <ItemsBanner items={items} />
      {(user.is_disabled || !organizationAcl.is_member) && (
        <Banner
          accent='error'
          icon='warning'
          message='This user is not enabled or their organization membership is not enabled.'
        />
      )}
      {!user.is_disabled && organizationAcl.is_member && (
        <Structure.Group title='Location Permissions' variant='secondary' iconButtonActions={locationIconButtonActions}>
          {locationAclsWithLocations.length === 0 && (
            <Banner
              accent='warning'
              icon='warning'
              message='This user is not enabled to access any location in this organization.'
            />
          )}
          {locationAclsWithLocations.map((locAclWithLocation) => (
            <LocationAclLocationCardComponent
              key={locAclWithLocation.location.id}
              locationAclWithLocation={locAclWithLocation}
              organizationId={organizationAcl.organization_id}
            />
          ))}
        </Structure.Group>
      )}
    </Structure.Group>
  );
}

function LocationAclLocationCardComponent({
  locationAclWithLocation,
  organizationId,
}: {
  locationAclWithLocation: TArrayElement<TAgentCustomersUserCompleteFragment['location_acls']>;
  organizationId: string;
}): JSX.Element {
  const { beginLocationAclEdit, beginLocationAclRemove } = useAgentCustomersUserActions();
  const { user } = useAgentCustomersUser();

  const organizationAcl = user.organization_acls.find(
    (orgAcl) => orgAcl.user_id === locationAclWithLocation.user_id && orgAcl.is_member && !user.is_disabled,
  );

  const items = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Permissions for Orders',
        text: toValue(locationAclWithLocation),
      },
      {
        caption: 'View Analytics',
        text:
          organizationAcl?.can_view_analytics === true
            ? 'Yes (Organization Setting)'
            : locationAclWithLocation.can_view_analytics
            ? 'Yes'
            : 'No',
      },
    ],
    // @sort
    [locationAclWithLocation, organizationAcl?.can_view_analytics],
  );

  const iconButtonActions = useMemo<TIconButtonAction[]>(
    () =>
      getAvailableIconButtonActions([
        {
          icon: 'edit',
          isAsync: false,
          onClick: isDef(beginLocationAclEdit)
            ? (): void =>
                beginLocationAclEdit({
                  locationId: locationAclWithLocation.location_id,
                  userId: locationAclWithLocation.user_id,
                })
            : undefined,
        },
        {
          icon: 'trash',
          isAsync: false,
          onClick: isDef(beginLocationAclRemove)
            ? (): void =>
                beginLocationAclRemove({
                  locationId: locationAclWithLocation.location_id,
                  organizationId: organizationId,
                })
            : undefined,
        },
      ]),
    [
      beginLocationAclEdit,
      beginLocationAclRemove,
      locationAclWithLocation.location_id,
      locationAclWithLocation.user_id,
      organizationId,
    ],
  );

  return (
    <Card.Container iconButtonActions={iconButtonActions} flush={true} interactive={false}>
      <Card.ContentArea>
        <Card.Title
          title={locationAclWithLocation.location.name}
          subTitle={locationAclWithLocation.location.order_code}
        />
        <Items items={items} />
      </Card.ContentArea>
    </Card.Container>
  );
}

function toValue(
  acl: Pick<
    TAgentCustomersLocationAclBaseFragment,
    'can_approve_orders' | 'can_place_orders' | 'can_send_order_messages' | 'can_view_orders'
  >,
): string {
  if (acl.can_approve_orders) {
    return 'View + Send Messages + Place + Approve';
  } else if (acl.can_place_orders) {
    return 'View + Send Messages + Place';
  } else if (acl.can_send_order_messages) {
    return 'View + Send Messages';
  } else if (acl.can_view_orders) {
    return 'View';
  } else {
    return 'None';
  }
}
