import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { ActionIconButton, getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import { ItemButton } from '@src/components/appearance/fragments/ItemButton';
import type { TItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { NotesCard } from '@src/components/mixins/cards/NotesCard';
import type { TAgentUserBaseFragment } from '@src/gen/graphql/bindings';
import type { AgentOrderBaseGroupManager, AgentOrderEntryManager } from '@src/gen/shared/data/agentOrders';
import { getCombinedProductName } from '@src/gen/shared/data/snippets';
import { maybeGetOrderEntryPlanStatusLabel } from '@src/gen/shared/enums/orderEntryPlanStatus';
import { getSource, getSourceName } from '@src/gen/shared/enums/source';
import { formatDollarsCurrency, maybeFormatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { maybeOpenUrls, withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SItemButtonDiv = styled('div', {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  gap: '$cardGap',
});

export type TOrderBaseGroupCardBase = {
  beginOrderBaseGroupEdit: TAgentOrderActionsTypes['BeginOrderBaseGroupEdit'] | null;
  beginOrderEntryBaseProductView: TAgentOrderActionsTypes['BeginOrderEntryBaseProductView'] | null;
  beginOrderEntryEdit: TAgentOrderActionsTypes['BeginOrderEntryEdit'] | null;
  orderBaseGroupManager: AgentOrderBaseGroupManager;
  notesUpdater: TAgentUserBaseFragment | null;
  isEditable: boolean;
};

export type TOrderBaseGroupCard = TProps<false, TOrderBaseGroupCardBase, 'div'>;
export const ORDER_BASE_GROUP_CARD_CLASS_NAME = 'wp-order-base-group-card';

export const OrderBaseGroupCard = withCssToString(
  ORDER_BASE_GROUP_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderBaseGroupCard>(
      (
        {
          beginOrderBaseGroupEdit,
          beginOrderEntryBaseProductView,
          beginOrderEntryEdit,
          orderBaseGroupManager,
          notesUpdater,
          isEditable,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_BASE_GROUP_CARD_CLASS_NAME), [className]);
        const productPageUrls = useMemo<string[]>(
          () =>
            orderBaseGroupManager.orderEntryManagers
              .filter((oem) => !oem.isRemoved())
              .map((oem) => oem.orderEntry.product_page_url),
          [orderBaseGroupManager.orderEntryManagers],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () =>
            getAvailableIconButtonActions([
              {
                isAsync: false,
                variant: orderBaseGroupManager.isRemoved() || !isEditable ? 'disabled' : undefined,
                icon: 'edit',
                onClick: isDef(beginOrderBaseGroupEdit)
                  ? (): void => beginOrderBaseGroupEdit({ orderBaseGroupId: orderBaseGroupManager.orderBaseGroup.id })
                  : undefined,
              },
              {
                annotation: productPageUrls.length > 1 ? `${productPageUrls.length}` : undefined,
                icon: 'link',
                isAsync: false,
                onClick: (): void => maybeOpenUrls(productPageUrls),
              },
            ]),
          // @sort
          [beginOrderBaseGroupEdit, isEditable, orderBaseGroupManager, productPageUrls],
        );

        const baseSubTotal = orderBaseGroupManager.maybeGetBaseSubTotal();
        const baseSubTotalProvisional = orderBaseGroupManager.maybeGetBaseSubTotalProvisional();
        const baseTotal = orderBaseGroupManager.maybeGetBaseTotal();
        const baseTotalProvisional = orderBaseGroupManager.maybeGetBaseTotalProvisional();

        const items = useMemo<TItemsBanner['items']>(
          () => [
            {
              caption: 'Sub Total',
              text: orderBaseGroupManager.isRemoved()
                ? 'Not Needed'
                : maybeFormatDollarsCurrency(baseSubTotal) ??
                  `TBD${isDef(baseSubTotalProvisional) ? `  (${formatDollarsCurrency(baseSubTotalProvisional)})` : ''}`,
              accent: isDef(baseSubTotal) ? 'default' : 'warning',
            },
            {
              caption: 'Shipping',
              text: orderBaseGroupManager.isRemoved()
                ? 'Not Needed'
                : maybeFormatDollarsCurrency(orderBaseGroupManager.orderBaseGroup.shipping) ?? 'TBD',
            },
            {
              caption: 'Tax',
              text: orderBaseGroupManager.isRemoved()
                ? 'Not Needed'
                : maybeFormatDollarsCurrency(orderBaseGroupManager.orderBaseGroup.tax) ?? 'TBD',
            },
            {
              caption: 'Total',
              text: orderBaseGroupManager.isRemoved()
                ? 'Not Needed'
                : maybeFormatDollarsCurrency(baseTotal) ??
                  `TBD${isDef(baseTotalProvisional) ? ` (${formatDollarsCurrency(baseTotalProvisional)})` : ''}`,
              accent: isDef(baseTotal) ? 'default' : 'warning',
            },
          ],
          [baseSubTotal, baseSubTotalProvisional, baseTotal, baseTotalProvisional, orderBaseGroupManager],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flush={true}
            iconButtonActions={iconButtonActions}
            interactive={false}
            ref={ref}>
            <Card.Title title={getSourceName(getSource(orderBaseGroupManager.orderBaseGroup.source))} />
            {orderBaseGroupManager.orderEntryManagers.map((oem) => (
              <SItemButtonDiv key={oem.orderEntry.id}>
                {isDef(beginOrderEntryBaseProductView) ? (
                  <ItemButton
                    icon={oem.isReady() ? undefined : 'warning'}
                    item={getBaseGroupOrderEntryItem(oem)}
                    onClick={(): void => beginOrderEntryBaseProductView({ orderEntryId: oem.orderEntry.id })}
                    variant='control'
                    css={{ flexGrow: 1 }}
                  />
                ) : (
                  <Item item={getBaseGroupOrderEntryItem(oem)} css={{ flexGrow: 1 }} />
                )}
                {isDef(beginOrderEntryEdit) && (
                  <ActionIconButton
                    action={{
                      isAsync: false,
                      icon: 'edit',
                      variant: !isEditable ? 'disabled' : 'default',
                      onClick: (): void => beginOrderEntryEdit({ orderEntryId: oem.orderEntry.id }),
                    }}
                  />
                )}
              </SItemButtonDiv>
            ))}
            <ItemsBanner items={items} />
            {isDef(notesUpdater) && isDef(orderBaseGroupManager.orderBaseGroup.notes) && (
              <NotesCard
                content={orderBaseGroupManager.orderBaseGroup.notes}
                updatedAt={ensureDef(orderBaseGroupManager.orderBaseGroup.notes_updated_at)}
                updater={notesUpdater}
              />
            )}
          </Card.Container>
        );
      },
    ),
  ),
);

function getBaseGroupOrderEntryItem(orderEntryManager: AgentOrderEntryManager): TItemBaseItem {
  const orderEntry = orderEntryManager.orderEntry;

  if (orderEntryManager.isRemoved()) {
    return {
      caption: `${orderEntry.product_sku} — ${maybeGetOrderEntryPlanStatusLabel(orderEntry.plan_status)}`,
      text: getCombinedProductName(orderEntryManager.orderEntry.name, orderEntryManager.orderEntry.secondary_name),
    };
  }

  const prefix = `${orderEntry.product_sku} — Qty. ${orderEntry.quantity}`;
  const unitPrice = maybeFormatDollarsCurrency(orderEntry.unit_price) ?? 'TBD';
  const subTotal = maybeFormatDollarsCurrency(orderEntryManager.maybeGetBaseSubTotal()) ?? 'TBD';
  const suffix = maybeGetOrderEntryPlanStatusLabel(orderEntry.plan_status);

  return {
    caption: `${prefix} x ${unitPrice} = ${subTotal} — ${suffix}`,
    text: getCombinedProductName(orderEntry.name, orderEntry.secondary_name),
  };
}
