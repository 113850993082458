import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { TAgentOrderBackOrderCompleteWithOrderSummaryFragment } from '@src/gen/graphql/bindings';
import {
  getCombinedProductName,
  getFullName,
  getOrderReference,
  getSaleUnitQuantity,
} from '@src/gen/shared/data/snippets';
import { maybeGetOrderBackOrderDecisionLabel } from '@src/gen/shared/enums/orderBackOrderDecision';
import { getSource, maybeGetSourceName } from '@src/gen/shared/enums/source';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderBackOrderListingCardBase = {
  orderBackOrder: TAgentOrderBackOrderCompleteWithOrderSummaryFragment;
  to: string;
};

export type TOrderBackOrderListingCard = TProps<false, TOrderBackOrderListingCardBase, 'div'>;
export const ORDER_BACK_ORDER_LISTING_CARD_CLASS_NAME = 'wp-order-back-order-listing-card';

export const OrderBackOrderListingCard = withCssToString(
  ORDER_BACK_ORDER_LISTING_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderBackOrderListingCard>(
      ({ orderBackOrder, to, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_BACK_ORDER_LISTING_CARD_CLASS_NAME),
          [className],
        );

        const items = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Back-Ordered Quantity',
              text: getSaleUnitQuantity(orderBackOrder.quantity, orderBackOrder.sale_unit),
            },
            {
              caption: 'Estimated Date',
              text: isDef(orderBackOrder.estimated_date)
                ? { asDate: 'date', at: orderBackOrder.estimated_date }
                : 'N/A',
            },
            {
              caption: 'Notified By',
              text: getFullName(orderBackOrder.creator),
            },
            {
              caption: 'Notified On',
              text: { asDate: 'on', at: orderBackOrder.created_at },
            },
          ],
          // @sort
          [
            orderBackOrder.created_at,
            orderBackOrder.creator,
            orderBackOrder.estimated_date,
            orderBackOrder.quantity,
            orderBackOrder.sale_unit,
          ],
        );

        return (
          <Card.Container {...rest} className={joinedClassName} ref={ref} flush={true} interactive={true}>
            <Card.LinkArea to={to}>
              <Card.Title
                title={`Back-Order in ${getOrderReference(
                  orderBackOrder.order.location.order_code,
                  orderBackOrder.order.reference_number,
                )}`}
                subTitle={`for ${orderBackOrder.order.location.name}`}
              />
              <Item
                item={{
                  caption: `${maybeGetSourceName(getSource(orderBackOrder.source))} · ${orderBackOrder.product_sku}`,
                  text: getCombinedProductName(orderBackOrder.name, orderBackOrder.secondary_name),
                }}
              />
              <ItemsBanner items={items} />
              {isDef(orderBackOrder.replied_at) && (
                <ItemsBanner
                  items={[
                    {
                      caption: 'Customer Decision',
                      text: maybeGetOrderBackOrderDecisionLabel(ensureDef(orderBackOrder.replied_decision)),
                    },
                    {
                      caption: 'Decided By',
                      text: getFullName(ensureDef(orderBackOrder.replier)),
                    },
                    {
                      caption: 'Decided On',
                      text: { asDate: 'on', at: ensureDef(orderBackOrder.replied_at) },
                    },
                  ]}
                />
              )}

              {isDef(orderBackOrder.applied_at) && (
                <Banner icon='apply' accent='success' message='This back-order is completed.' />
              )}
            </Card.LinkArea>
          </Card.Container>
        );
      },
    ),
  ),
);
