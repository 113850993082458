import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Structure } from '@src/components/appearance/structure/Structure';
import { NotificationsEmptyAnnouncement } from '@src/components/mixins/announcements/NotificationsEmptyAnnouncement';
import { NotificationCard, NotificationCardLoader } from '@src/components/mixins/cards/NotificationCard';
import type { TCustomerNotificationCompleteFragment } from '@src/gen/graphql/bindings';
import { castNotificationUnsafe } from '@src/gen/shared/data/notification';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { useCustomerNotificationsActions } from '@src/modules/data/customer/notifications/CustomerNotificationsActionsProvider';
import { useCustomerNotifications } from '@src/modules/data/customer/notifications/CustomerNotificationsProvider';
import { encodeCustomerOrderSummaryPath } from '@src/modules/routing/customer';
import { Fragment, memo, useCallback, useMemo } from 'react';

export function CustomerUserNotificationsPart(): JSX.Element {
  const { notifications, loaderRef } = useCustomerNotifications();

  return (
    <Structure.ScrollContent>
      <Structure.Group>
        {notifications.length > 0 ? (
          <Fragment>
            {notifications.map((notification) => (
              <CardComponent key={notification.id} notification={notification} />
            ))}
            {isDef(loaderRef) && <Illustrations.Spinner ref={loaderRef} />}
          </Fragment>
        ) : (
          <NotificationsEmptyAnnouncement />
        )}
      </Structure.Group>
    </Structure.ScrollContent>
  );
}

function CardComponent({ notification }: { notification: TCustomerNotificationCompleteFragment }): JSX.Element {
  const { doNotificationClearOptNotify } = useCustomerNotificationsActions();

  const to = useMemo(
    () => {
      const content = castNotificationUnsafe(ensureDef(notification.order_notifications[0]?.notification_content));
      return encodeCustomerOrderSummaryPath(content.organization.id, ensureDef(notification.order_id));
    },
    // @sort
    [notification.order_id, notification.order_notifications],
  );

  const handleClear = useCallback(
    () => doNotificationClearOptNotify({ notificationId: notification.id }),
    [notification.id, doNotificationClearOptNotify],
  );

  return <NotificationCard notification={notification} onClear={handleClear} to={to} />;
}

export const CustomerUserPartLoader = memo(
  (): JSX.Element => (
    <Structure.ScrollContentLoader>
      <Structure.Group>
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
      </Structure.Group>
    </Structure.ScrollContentLoader>
  ),
);
