import { Typography } from '@src/components/appearance/basics/Typography';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { Structure } from '@src/components/appearance/structure/Structure';
import type { TBackfillOrderInvoiceFormValues } from '@src/components/mixins/forms/BackfillOrderInvoiceForm';
import { BackfillOrderInvoiceForm } from '@src/components/mixins/forms/BackfillOrderInvoiceForm';
import { getDateDate } from '@src/gen/shared/data/snippets';
import { formatDollarsCurrency, formatPercent } from '@src/gen/shared/utils/converters';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo, useState } from 'react';

export type TBackfillOrderInvoiceDrawerPanelBase = {
  doBackfillOrderInvoiceAsync: TAgentOrderActionsTypes['DoBackfillOrderInvoiceAsync'];
};

export type TBackfillOrderInvoiceDrawerPanel = TProps<false, TBackfillOrderInvoiceDrawerPanelBase, 'div'>;
export const BACKFILL_ORDER_INVOICE_DRAWER_PANEL_CLASS_NAME = 'wp-backfill-order-invoice-drawer-panel';

export const BackfillOrderInvoiceDrawerPanel = withCssToString(
  BACKFILL_ORDER_INVOICE_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TBackfillOrderInvoiceDrawerPanel>(
      ({ doBackfillOrderInvoiceAsync, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, BACKFILL_ORDER_INVOICE_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [isReviewing, setIsReviewing] = useState(false);
        const [values, setValues] = useState<TBackfillOrderInvoiceFormValues>({
          baseSubTotal: 0,
          baseShipping: 0,
          baseTax: 0,
          baseTotal: 0,
          wellplaeceSubTotal: 0,
          wellplaeceFees: 0,
          wellplaeceTax: 0,
          wellplaeceTotal: 0,
          agentWellplaeceShipping: 0,
          agentWellplaeceCcFee: 0,
          agentWellplaeceCommission: 0,
          agentWellplaeceCommissionPercent: '0',
          invoiceIssuedAt: '',
        });

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Backfill Order Invoice' />
            {isReviewing ? (
              <Reviewing
                doBackfillOrderInvoiceAsync={doBackfillOrderInvoiceAsync}
                goBack={(): void => setIsReviewing(false)}
                values={values}
              />
            ) : (
              <Drawer.ScrollContent>
                <BackfillOrderInvoiceForm
                  initialValues={values}
                  onSubmit={(newValues): void => {
                    setValues(newValues);
                    setIsReviewing(true);
                  }}
                />
              </Drawer.ScrollContent>
            )}
          </Drawer.Panel>
        );
      },
    ),
  ),
);

type TReviewing = {
  doBackfillOrderInvoiceAsync: TAgentOrderActionsTypes['DoBackfillOrderInvoiceAsync'];
  goBack: () => void;
  values: TBackfillOrderInvoiceFormValues;
};

function Reviewing({ values, doBackfillOrderInvoiceAsync, goBack }: TReviewing): JSX.Element {
  const itemsBase = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Sub Total (Base)',
        text: formatDollarsCurrency(values.baseSubTotal),
      },
      {
        caption: 'Shipping (Base)',
        text: formatDollarsCurrency(values.baseShipping),
      },
      {
        caption: 'Tax (Base)',
        text: formatDollarsCurrency(values.baseTax),
      },
      {
        caption: 'Total (Base)',
        text: formatDollarsCurrency(values.baseTotal),
      },
    ],
    // @sort
    [values.baseShipping, values.baseSubTotal, values.baseTax, values.baseTotal],
  );

  const itemsWellplaece = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Sub Total (Wellplaece)',
        text: formatDollarsCurrency(values.wellplaeceSubTotal),
      },
      {
        caption: 'Shipping and Handling (Wellplaece)',
        text: formatDollarsCurrency(values.wellplaeceFees),
      },
      {
        caption: 'Tax (Wellplaece)',
        text: formatDollarsCurrency(values.wellplaeceTax),
      },
      {
        caption: 'Total (Wellplaece)',
        text: formatDollarsCurrency(values.wellplaeceTotal),
      },
    ],
    // @sort
    [values.wellplaeceFees, values.wellplaeceSubTotal, values.wellplaeceTax, values.wellplaeceTotal],
  );

  const { savings, savingsPercent } = calculateSavings({
    baseTotal: values.baseTotal,
    wellplaeceTotal: values.wellplaeceTotal,
  });

  const itemsAgent = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'CC Fee',
        text: formatDollarsCurrency(values.agentWellplaeceCcFee),
      },
      {
        caption: 'Shipping Fees',
        text: formatDollarsCurrency(values.agentWellplaeceShipping),
      },
      {
        caption: 'Commission',
        text: `${formatDollarsCurrency(values.agentWellplaeceCommission)} (${formatPercent(
          getCommissionPercent(values.agentWellplaeceCommissionPercent),
        )})`,
      },
      {
        caption: 'Savings',
        text: `${formatDollarsCurrency(savings)} (${formatPercent(savingsPercent)})`,
      },
    ],
    [
      savings,
      savingsPercent,
      values.agentWellplaeceCcFee,
      values.agentWellplaeceCommission,
      values.agentWellplaeceCommissionPercent,
      values.agentWellplaeceShipping,
    ],
  );

  const baseWarning = values.baseSubTotal + values.baseTax + values.baseShipping !== values.baseTotal;
  const wpWarning = values.wellplaeceSubTotal + values.wellplaeceTax + values.wellplaeceFees !== values.wellplaeceTotal;
  const svWarning = values.wellplaeceTotal > values.baseTotal;

  const agentWarning =
    values.agentWellplaeceShipping + values.agentWellplaeceCcFee + values.agentWellplaeceCommission !==
    values.wellplaeceFees;

  return (
    <Fragment>
      <Drawer.ScrollContent>
        <Structure.Stack>
          <Structure.Group title='Summary' variant='secondary'>
            <Card.Container flush={true} interactive={false}>
              <Card.ContentArea>
                <Typography.Annotation text={'Before Wellplaece...'.toUpperCase()} />
                <Items items={itemsBase} />
              </Card.ContentArea>
              <Card.Footer>
                <Typography.Annotation text={'...With Wellplaece'.toUpperCase()} />
                <Items items={itemsWellplaece} />
              </Card.Footer>
            </Card.Container>
            {(baseWarning || wpWarning) && (
              <Fragment>
                {baseWarning && <Banner icon='warning' accent='warning' message='Base total does not add up.' />}
                {wpWarning && <Banner icon='warning' accent='warning' message='Wellplaece total does not add up.' />}
              </Fragment>
            )}
          </Structure.Group>
          <Structure.Group title='Agent-Only Details' variant='secondary'>
            <ItemsBanner items={itemsAgent} />
            {(svWarning || agentWarning) && (
              <Fragment>
                {svWarning && <Banner icon='warning' accent='warning' message='Negative savings.' />}
                {agentWarning && <Banner icon='warning' accent='warning' message='Wellplaece fees do not add up.' />}
              </Fragment>
            )}
          </Structure.Group>
          <Structure.Group title='Details' variant='secondary'>
            <ItemsBanner
              items={[
                {
                  caption: 'Invoice Date',
                  text: getDateDate(values.invoiceIssuedAt),
                },
                {
                  caption: '',
                  text: '',
                },
              ]}
            />
          </Structure.Group>
        </Structure.Stack>
      </Drawer.ScrollContent>
      <Drawer.Footer
        buttonAction={{
          isAsync: true,
          onClick: async (): Promise<void> =>
            await doBackfillOrderInvoiceAsync({
              ...values,
              agentWellplaeceCommissionPercent: getCommissionPercent(values.agentWellplaeceCommissionPercent),
            }),
          text: 'Save',
        }}
        secondaryButtonAction={{
          isAsync: false,
          onClick: goBack,
          text: 'Go Back',
          variant: 'secondary',
        }}
      />
    </Fragment>
  );
}

function getCommissionPercent(v: TBackfillOrderInvoiceFormValues['agentWellplaeceCommissionPercent']): number {
  switch (v) {
    case '0':
      return 0;
    case '1':
      return 100;
    case '1.5':
      return 150;
    case '3.5':
      return 350;
    case '4':
      return 400;
    case '4.5':
      return 450;
    case '6':
      return 600;
    case '8':
      return 800;
  }
}

function calculateSavings({ baseTotal, wellplaeceTotal }: { baseTotal: number; wellplaeceTotal: number }): {
  savings: number;
  savingsPercent: number;
} {
  // No savings.
  if (baseTotal === wellplaeceTotal) {
    return {
      savings: 0,
      savingsPercent: 0,
    };
  }

  if (baseTotal > wellplaeceTotal) {
    const savings = baseTotal - wellplaeceTotal;

    return {
      savings,
      savingsPercent: Math.round((savings * 10000) / baseTotal),
    };
  }

  const savings = wellplaeceTotal - baseTotal;

  return {
    savings: -savings,
    savingsPercent: -Math.round((savings * 10000) / wellplaeceTotal),
  };
}
