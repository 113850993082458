import type { CheckedState } from '@radix-ui/react-checkbox';
import type { TCheckBoxBase } from '@src/components/appearance/controls/CheckBox';
import type { TQuantityBase } from '@src/components/appearance/controls/Quantity';
import { Dialog } from '@src/components/appearance/structure/Dialog';
import { CompleteOnboardingAnnouncement } from '@src/components/mixins/announcements/CompleteOnboardingAnnouncement';
import { EmergencyOrderDialogPanel } from '@src/components/mixins/dialogs/EmergencyOrderDialogPanel';
import { SmallOrderDialogPanel } from '@src/components/mixins/dialogs/SmallOrderDialogPanel';
import {
  NextOrderProductDrawerPanel,
  NextOrderProductDrawerPanelLoader,
} from '@src/components/mixins/drawers/NextOrderProductDrawerPanel';
import type {
  CustomerCartProductsQuery,
  CustomerCartProductsQueryVariables,
  TCustomerCartProductBaseFragment,
  TCustomerCartProductCompleteFragment,
  TCustomerLocationBaseFragment,
  TCustomerOrderBaseFragment,
  TCustomerPublicCatalogProductBaseFragment,
} from '@src/gen/graphql/bindings';
import {
  useCustomerCartProductDeleteMutation,
  useCustomerCartProductUpdateIsSubstitutionAllowedMutation,
  useCustomerCartProductUpdateQuantityMutation,
  useCustomerOrderPlaceMutation,
} from '@src/gen/graphql/bindings';
import { getCombinedProductName } from '@src/gen/shared/data/snippets';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useAuthCustomer } from '@src/modules/auth/AuthProvider';
import {
  CustomerPublicCatalogProductProvider,
  useCustomerPublicCatalogProduct,
} from '@src/modules/data/customer/global/CustomerPublicCatalogProductProvider';
import {
  useCustomerNextOrder,
  useCustomerNextOrderCartProduct,
} from '@src/modules/data/customer/nextOrder/CustomerNextOrderProvider';
import {
  EAmplitudeAnalyticsEventId,
  useAmplitudeAnalyticsActions,
} from '@src/modules/data/shared/analytics/AmplitudeAnalyticsProvider';
import { useDialog } from '@src/modules/design/DialogProvider';
import type { TInlineDrawer } from '@src/modules/design/DrawerProvider';
import { InlineDrawer, useInlineDrawer } from '@src/modules/design/DrawerProvider';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import {
  encodeCustomerOrderSummaryPath,
  useCustomerRouteLocation,
  useCustomerRouteOrganization,
} from '@src/modules/routing/customer';
import type { Dispatch, FunctionComponent, PropsWithChildren, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type TCustomerNextOrderActionsTypes = {
  PlacingOrderBase: {
    placedOrder?:
      | {
          order: Pick<TCustomerOrderBaseFragment, 'reference_number'> & {
            location: Pick<TCustomerLocationBaseFragment, 'name' | 'order_code'>;
          };
          doOrderShow: () => void;
        }
      | undefined;
  };
  BeginOrderPlace: (args: { isEmergency: boolean }) => void;
  BeginPublicCatalogProductProductView: (args: { publicCatalogProductId: string }) => void;
  DoCartProductDeleteOptNotify: (args: { cartProduct: TCustomerCartProductCompleteFragment }) => void;
  DoCartProductUpdateIsSubstitutionAllowedOptNotify: (args: {
    cartProduct: TCustomerCartProductCompleteFragment;
    newIsSubstitutionAllowed: boolean;
  }) => void;
  DoCartProductUpdateQuantityOptNotify: (args: {
    cartProduct: TCustomerCartProductBaseFragment | null;
    publicCatalogProduct: TCustomerPublicCatalogProductBaseFragment;
    newQuantity: number;
    eventLocation: 'cart' | 'drawer' | 'history' | 'formulary' | 'catalogs';
  }) => void;
};

export type TCustomerNextOrderActionsContext = {
  beginOrderPlace: TCustomerNextOrderActionsTypes['BeginOrderPlace'] | null;
  beginPublicCatalogProductView: TCustomerNextOrderActionsTypes['BeginPublicCatalogProductProductView'];
  doCartProductDeleteOptNotify: TCustomerNextOrderActionsTypes['DoCartProductDeleteOptNotify'];
  doCartProductUpdateIsSubstitutionAllowedOptNotify: TCustomerNextOrderActionsTypes['DoCartProductUpdateIsSubstitutionAllowedOptNotify'];
  doCartProductUpdateQuantityOptNotify: TCustomerNextOrderActionsTypes['DoCartProductUpdateQuantityOptNotify'];
  canPlaceEmergencyOrders: boolean;
};

export const { Context: CustomerNextOrderActionsContext, useContext: useCustomerNextOrderActions } =
  createRequiredContext<TCustomerNextOrderActionsContext>();

export function useCustomerNextOrderActionsCartProductDelete(args: {
  cartProduct: TCustomerCartProductCompleteFragment;
}): () => void {
  const { doCartProductDeleteOptNotify } = useCustomerNextOrderActions();

  return useCallback(
    () => doCartProductDeleteOptNotify(args),
    // @sort
    [args, doCartProductDeleteOptNotify],
  );
}

export function useCustomerNextOrderActionsCartProductIsSubstitutionAllowed(baseArgs: {
  cartProduct: TCustomerCartProductCompleteFragment | null;
}): TCheckBoxBase | undefined {
  const { doCartProductUpdateIsSubstitutionAllowedOptNotify } = useCustomerNextOrderActions();

  return useMemo(
    () =>
      isDef(baseArgs.cartProduct) && !baseArgs.cartProduct.is_deleted
        ? {
            checked: baseArgs.cartProduct.is_substitution_allowed,
            onCheckedChange: (checked: CheckedState) =>
              doCartProductUpdateIsSubstitutionAllowedOptNotify({
                cartProduct: ensureDef(baseArgs.cartProduct),
                newIsSubstitutionAllowed: checked === true,
              }),
          }
        : undefined,
    // @sort
    [baseArgs, doCartProductUpdateIsSubstitutionAllowedOptNotify],
  );
}

export function useCustomerNextOrderActionsCartProductQuantity(baseArgs: {
  cartProduct: TCustomerCartProductBaseFragment | null;
  publicCatalogProduct: TCustomerPublicCatalogProductBaseFragment;
  eventLocation: 'cart' | 'drawer' | 'history' | 'formulary' | 'catalogs';
}): TQuantityBase {
  const { doCartProductUpdateQuantityOptNotify } = useCustomerNextOrderActions();

  return useMemo(
    () => ({
      value: baseArgs.cartProduct?.quantity ?? 0,
      onChange: (newValue) => doCartProductUpdateQuantityOptNotify({ ...baseArgs, newQuantity: newValue }),
    }),
    // @sort
    [baseArgs, doCartProductUpdateQuantityOptNotify],
  );
}

export type TCustomerNextOrderActionsProvider = PropsWithChildren<{
  PlacingOrderComponent: FunctionComponent<TCustomerNextOrderActionsTypes['PlacingOrderBase']>;
}>;

export function CustomerNextOrderActionsProvider({
  PlacingOrderComponent,
  children,
}: TCustomerNextOrderActionsProvider): JSX.Element {
  const organization = useCustomerRouteOrganization();
  const location = useCustomerRouteLocation();
  const { cartProductsTotalItems } = useCustomerNextOrder();

  const [placingOrderBase, setPlacingOrderBase] = useState<
    TCustomerNextOrderActionsTypes['PlacingOrderBase'] | undefined
  >(undefined);

  const beginOrderPlace = useBeginOrderPlace(setPlacingOrderBase);
  const { beginPublicCatalogProductView, publicCatalogProductViewInlineDrawer } = useBeginPublicCatalogProductView();
  const doCartProductDeleteOptNotify = useDoCartProductDeleteOptNotify();
  const doCartProductUpdateIsSubstitutionAllowedOptNotify = useDoCartProductUpdateIsSubstitutionAllowedOptNotify();
  const doCartProductUpdateQuantityOptNotify = useDoCartProductUpdateQuantityOptNotify();

  const value = useMemo<TCustomerNextOrderActionsContext>(
    () => ({
      beginOrderPlace: location.acl.can_place_orders && cartProductsTotalItems > 0 ? beginOrderPlace : null,
      beginPublicCatalogProductView,
      doCartProductDeleteOptNotify,
      doCartProductUpdateIsSubstitutionAllowedOptNotify,
      doCartProductUpdateQuantityOptNotify,
      canPlaceEmergencyOrders: organization.canPlaceEmergencyOrders,
    }),
    // @sort
    [
      beginOrderPlace,
      beginPublicCatalogProductView,
      cartProductsTotalItems,
      doCartProductDeleteOptNotify,
      doCartProductUpdateIsSubstitutionAllowedOptNotify,
      doCartProductUpdateQuantityOptNotify,
      location.acl.can_place_orders,
      organization.canPlaceEmergencyOrders,
    ],
  );

  return isDef(placingOrderBase) ? (
    <PlacingOrderComponent {...placingOrderBase} />
  ) : (
    <CustomerNextOrderActionsContext.Provider value={value}>
      <InlineDrawer {...publicCatalogProductViewInlineDrawer} />
      {children}
    </CustomerNextOrderActionsContext.Provider>
  );
}

function useBeginOrderPlace(
  setPlacingOrderComponent: Dispatch<SetStateAction<TCustomerNextOrderActionsTypes['PlacingOrderBase'] | undefined>>,
): TCustomerNextOrderActionsTypes['BeginOrderPlace'] {
  const [orderPlaceMutation] = useCustomerOrderPlaceMutation();
  const organization = useCustomerRouteOrganization();
  const location = useCustomerRouteLocation();
  const navigate = useNavigate();
  const { queryRef, cartProductsUniqueItems } = useCustomerNextOrder();
  const { doDialogOpen } = useDialog();

  const doOrderPlaceAsync = useCallback<
    (args: Parameters<TCustomerNextOrderActionsTypes['BeginOrderPlace']>[0]) => Promise<void>
  >(
    async (args) => {
      setPlacingOrderComponent({});

      const { data: newData, errors } = await orderPlaceMutation({
        variables: {
          ...args,
          locationId: location.id,
        },
        context: {
          serializationKey: `nextOrder[${location.id}]`,
        },
        update: (cache) => {
          cache.writeQuery<CustomerCartProductsQuery, CustomerCartProductsQueryVariables>({
            ...queryRef,
            data: {
              cart_products: [],
            },
          });
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      const order = ensureDef(newData?.customer_order_place);

      setPlacingOrderComponent({
        placedOrder: {
          order,
          doOrderShow: () => navigate(encodeCustomerOrderSummaryPath(organization.id, order.id)),
        },
      });
    },
    // @sort
    [navigate, orderPlaceMutation, queryRef, setPlacingOrderComponent, location.id, organization.id],
  );

  const doOrderPlace = useCallback<TCustomerNextOrderActionsTypes['BeginOrderPlace']>(
    (args) => void doOrderPlaceAsync(args),
    [doOrderPlaceAsync],
  );

  return useCallback<TCustomerNextOrderActionsTypes['BeginOrderPlace']>(
    (args) => {
      if (organization.isSandbox) {
        doDialogOpen(
          <Dialog.Panel>
            <CompleteOnboardingAnnouncement organizationName={organization.name} />
          </Dialog.Panel>,
        );
        return;
      }

      if (!args.isEmergency && cartProductsUniqueItems <= 5) {
        doDialogOpen(<SmallOrderDialogPanel doOrderPlace={(): void => doOrderPlace(args)} />);
        return;
      }

      if (args.isEmergency) {
        doDialogOpen(<EmergencyOrderDialogPanel doOrderPlace={(): void => doOrderPlace(args)} />);
        return;
      }

      doOrderPlace(args);
      return;
    },
    // @sort
    [cartProductsUniqueItems, doDialogOpen, doOrderPlace, organization.isSandbox, organization.name],
  );
}

function useBeginPublicCatalogProductView(): {
  beginPublicCatalogProductView: TCustomerNextOrderActionsTypes['BeginPublicCatalogProductProductView'];
  publicCatalogProductViewInlineDrawer: TInlineDrawer<string>;
} {
  const publicCatalogProductViewInlineDrawer = useInlineDrawer<string>();

  const beginPublicCatalogProductView = useCallback<
    TCustomerNextOrderActionsTypes['BeginPublicCatalogProductProductView']
  >(
    ({ publicCatalogProductId }) => {
      publicCatalogProductViewInlineDrawer.doDrawerOpen({
        metadata: publicCatalogProductId,
        drawerChildren: (
          <CustomerPublicCatalogProductProvider
            LoaderComponent={NextOrderProductDrawerPanelLoader}
            publicCatalogProductId={publicCatalogProductId}>
            <PublicCatalogProductViewInlineDrawer beginPublicCatalogProductView={beginPublicCatalogProductView} />
          </CustomerPublicCatalogProductProvider>
        ),
      });
    },
    [publicCatalogProductViewInlineDrawer],
  );

  return useMemo(
    () => ({
      beginPublicCatalogProductView,
      publicCatalogProductViewInlineDrawer,
    }),
    [beginPublicCatalogProductView, publicCatalogProductViewInlineDrawer],
  );
}

type TPublicCatalogProductViewInlineDrawer = {
  beginPublicCatalogProductView: TCustomerNextOrderActionsTypes['BeginPublicCatalogProductProductView'];
};

function PublicCatalogProductViewInlineDrawer({
  beginPublicCatalogProductView,
}: TPublicCatalogProductViewInlineDrawer): JSX.Element {
  const { publicCatalogProduct } = useCustomerPublicCatalogProduct();
  const cartProduct = useCustomerNextOrderCartProduct(publicCatalogProduct.id);

  const quantity = useCustomerNextOrderActionsCartProductQuantity({
    cartProduct: cartProduct ?? null,
    publicCatalogProduct,
    eventLocation: 'drawer',
  });

  const isSubstitutionAllowed = useCustomerNextOrderActionsCartProductIsSubstitutionAllowed({
    cartProduct: cartProduct ?? null,
  });

  return (
    <NextOrderProductDrawerPanel
      isSubstitutionAllowed={isSubstitutionAllowed}
      onChangeVariant={beginPublicCatalogProductView}
      publicCatalogProduct={publicCatalogProduct}
      quantity={quantity}
      variantProducts={publicCatalogProduct.variant_products}
    />
  );
}

function useDoCartProductDeleteOptNotify(): TCustomerNextOrderActionsTypes['DoCartProductDeleteOptNotify'] {
  const [cartProductDeleteMutation] = useCustomerCartProductDeleteMutation();
  const customer = useAuthCustomer();
  const location = useCustomerRouteLocation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { queryRef, serializationKey: baseSerializationKey } = useCustomerNextOrder();

  const doCartProductDeleteAsyncNotify = useCallback<
    (args: Parameters<TCustomerNextOrderActionsTypes['DoCartProductDeleteOptNotify']>[0]) => Promise<void>
  >(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await cartProductDeleteMutation({
          variables: {
            locationId: location.id,
            publicCatalogProductId: args.cartProduct.public_catalog_product_id,
          },
          context: {
            serializationKey: baseSerializationKey,
            debounceKey: `${baseSerializationKey}.publicCatalogProduct[${args.cartProduct.public_catalog_product_id}]`,
          },
          update: (cache, { data: newData }) => {
            const customerCartProductDelete = ensureDef(newData?.customer_cart_product_delete[0]);

            cache.updateQuery<CustomerCartProductsQuery, CustomerCartProductsQueryVariables>(queryRef, (data) => ({
              cart_products: [
                customerCartProductDelete,
                ...(data?.cart_products ?? []).filter(
                  (cp) => cp.public_catalog_product_id !== customerCartProductDelete.public_catalog_product_id,
                ),
              ].sort((a, b) => b.created_at.localeCompare(a.created_at)),
            }));
          },
          optimisticResponse: () => {
            return {
              customer_cart_product_delete: [
                {
                  ...args.cartProduct,
                  quantity: 0,
                  is_deleted: true,
                  updated_by: customer.id,
                  updated_at: new Date().toISOString(),
                  updater: {
                    __typename: 'users',
                    id: customer.id,
                    first_name: customer.first_name,
                    last_name: customer.last_name,
                    is_wellplaece_agent: customer.is_wellplaece_agent,
                  },
                },
              ],
            };
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While updating cart.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    [
      doErrorClear,
      cartProductDeleteMutation,
      location.id,
      baseSerializationKey,
      queryRef,
      customer.id,
      customer.first_name,
      customer.last_name,
      customer.is_wellplaece_agent,
      doErrorNotify,
    ],
  );

  return useCallback<TCustomerNextOrderActionsTypes['DoCartProductDeleteOptNotify']>(
    (args) => void doCartProductDeleteAsyncNotify(args),
    [doCartProductDeleteAsyncNotify],
  );
}

function useDoCartProductUpdateIsSubstitutionAllowedOptNotify(): TCustomerNextOrderActionsTypes['DoCartProductUpdateIsSubstitutionAllowedOptNotify'] {
  const [cartProductUpdateIsSubstitutionAllowedMutation] = useCustomerCartProductUpdateIsSubstitutionAllowedMutation();
  const location = useCustomerRouteLocation();
  const customer = useAuthCustomer();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { queryRef, serializationKey: baseSerializationKey } = useCustomerNextOrder();

  const doCartProductUpdateIsSubstitutionAllowedAsyncNotify = useCallback<
    (
      args: Parameters<TCustomerNextOrderActionsTypes['DoCartProductUpdateIsSubstitutionAllowedOptNotify']>[0],
    ) => Promise<void>
  >(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await cartProductUpdateIsSubstitutionAllowedMutation({
          variables: {
            locationId: location.id,
            publicCatalogProductId: args.cartProduct.public_catalog_product_id,
            isSubstitutionAllowed: args.newIsSubstitutionAllowed,
          },
          context: {
            serializationKey: baseSerializationKey,
            debounceKey: `${baseSerializationKey}.publicCatalogProduct[${args.cartProduct.public_catalog_product_id}]`,
          },
          update: (cache, { data: newData }) => {
            const customerCartProductUpdateIsSubstitutionAllowed = ensureDef(
              newData?.customer_cart_product_update_is_substitution_allowed[0],
            );

            cache.updateQuery<CustomerCartProductsQuery, CustomerCartProductsQueryVariables>(queryRef, (data) => ({
              cart_products: [
                customerCartProductUpdateIsSubstitutionAllowed,
                ...(data?.cart_products ?? []).filter(
                  (cp) =>
                    cp.public_catalog_product_id !==
                    customerCartProductUpdateIsSubstitutionAllowed.public_catalog_product_id,
                ),
              ].sort((a, b) => b.created_at.localeCompare(a.created_at)),
            }));
          },
          optimisticResponse: () => {
            return {
              customer_cart_product_update_is_substitution_allowed: [
                {
                  ...args.cartProduct,
                  is_substitution_allowed: args.newIsSubstitutionAllowed,
                  is_deleted: false,
                  updated_by: customer.id,
                  updated_at: new Date().toISOString(),
                  updater: {
                    __typename: 'users',
                    id: customer.id,
                    first_name: customer.first_name,
                    last_name: customer.last_name,
                    is_wellplaece_agent: customer.is_wellplaece_agent,
                  },
                },
              ],
            };
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While updating cart.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    [
      doErrorClear,
      cartProductUpdateIsSubstitutionAllowedMutation,
      location.id,
      baseSerializationKey,
      queryRef,
      customer.id,
      customer.first_name,
      customer.last_name,
      customer.is_wellplaece_agent,
      doErrorNotify,
    ],
  );

  return useCallback<TCustomerNextOrderActionsTypes['DoCartProductUpdateIsSubstitutionAllowedOptNotify']>(
    (args) => void doCartProductUpdateIsSubstitutionAllowedAsyncNotify(args),
    [doCartProductUpdateIsSubstitutionAllowedAsyncNotify],
  );
}

function useDoCartProductUpdateQuantityOptNotify(): TCustomerNextOrderActionsTypes['DoCartProductUpdateQuantityOptNotify'] {
  const [cartProductUpdateQuantityMutation] = useCustomerCartProductUpdateQuantityMutation();
  const customer = useAuthCustomer();
  const location = useCustomerRouteLocation();
  const organization = useCustomerRouteOrganization();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { queryRef, serializationKey: baseSerializationKey } = useCustomerNextOrder();
  const { doAmplitudeTrack } = useAmplitudeAnalyticsActions();

  const doCartProductUpdateQuantityAsyncNotify = useCallback<
    (args: Parameters<TCustomerNextOrderActionsTypes['DoCartProductUpdateQuantityOptNotify']>[0]) => Promise<void>
  >(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await cartProductUpdateQuantityMutation({
          variables: {
            locationId: location.id,
            publicCatalogProductId: args.publicCatalogProduct.id,
            source: args.publicCatalogProduct.source,
            productPageUrl: args.publicCatalogProduct.product_page_url,
            productSku: args.publicCatalogProduct.product_sku,
            manufacturerName: args.publicCatalogProduct.manufacturer_name,
            manufacturerSku: args.publicCatalogProduct.manufacturer_sku,
            name: args.publicCatalogProduct.name,
            secondaryName: args.publicCatalogProduct.secondary_name,
            saleUnit: args.publicCatalogProduct.sale_unit,
            isDiscontinued: args.publicCatalogProduct.is_discontinued,
            isHouseBrand: args.publicCatalogProduct.is_house_brand,
            categoryPath: args.publicCatalogProduct.category_path,
            categoryName: args.publicCatalogProduct.category_name,
            simplifiedCategory: args.publicCatalogProduct.simplified_category,
            description: args.publicCatalogProduct.description,
            specs: args.publicCatalogProduct.specs,
            imageAssetPath: args.publicCatalogProduct.image_asset_path,
            sdsAssetPath: args.publicCatalogProduct.sds_asset_path,
            quantity: args.newQuantity,
          },
          context: {
            serializationKey: baseSerializationKey,
            debounceKey: `${baseSerializationKey}.publicCatalogProduct[${args.publicCatalogProduct.id}]`,
          },
          update: (cache, { data: newData }) => {
            const customerCartProductUpdateQuantity = ensureDef(newData?.customer_cart_product_update_quantity_v2[0]);

            cache.updateQuery<CustomerCartProductsQuery, CustomerCartProductsQueryVariables>(queryRef, (data) => ({
              cart_products: [
                customerCartProductUpdateQuantity,
                ...(data?.cart_products ?? []).filter(
                  (cp) => cp.public_catalog_product_id !== customerCartProductUpdateQuantity.public_catalog_product_id,
                ),
              ].sort((a, b) => b.created_at.localeCompare(a.created_at)),
            }));
          },
          optimisticResponse: () => {
            return {
              customer_cart_product_update_quantity_v2: [
                {
                  ...(isDef(args.cartProduct) && !args.cartProduct.is_deleted
                    ? args.cartProduct
                    : {
                        __typename: 'cart_products',
                        location_id: location.id,
                        public_catalog_product_id: args.publicCatalogProduct.id,
                        source: args.publicCatalogProduct.source,
                        product_page_url: args.publicCatalogProduct.product_page_url,
                        product_sku: args.publicCatalogProduct.product_sku,
                        manufacturer_name: args.publicCatalogProduct.manufacturer_name,
                        manufacturer_sku: args.publicCatalogProduct.manufacturer_sku,
                        name: args.publicCatalogProduct.name,
                        secondary_name: args.publicCatalogProduct.secondary_name,
                        sale_unit: args.publicCatalogProduct.sale_unit,
                        is_discontinued: args.publicCatalogProduct.is_discontinued,
                        is_house_brand: args.publicCatalogProduct.is_house_brand,
                        category_path: args.publicCatalogProduct.category_path,
                        category_name: args.publicCatalogProduct.category_name,
                        simplified_category: args.publicCatalogProduct.simplified_category,
                        description: args.publicCatalogProduct.description,
                        specs: args.publicCatalogProduct.specs,
                        image_asset_path: args.publicCatalogProduct.image_asset_path,
                        sds_asset_path: args.publicCatalogProduct.sds_asset_path,
                        is_substitution_allowed: true,
                        created_by: customer.id,
                        created_at: new Date().toISOString(),
                        creator: {
                          __typename: 'users',
                          id: customer.id,
                          first_name: customer.first_name,
                          last_name: customer.last_name,
                          is_wellplaece_agent: customer.is_wellplaece_agent,
                        },
                      }),
                  quantity: args.newQuantity,
                  is_deleted: false,
                  updated_by: customer.id,
                  updated_at: new Date().toISOString(),
                  updater: {
                    __typename: 'users',
                    id: customer.id,
                    first_name: customer.first_name,
                    last_name: customer.last_name,
                    is_wellplaece_agent: customer.is_wellplaece_agent,
                  },
                  public_catalog_product: {
                    ...args.publicCatalogProduct,
                  },
                },
              ],
            };
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        if ((!isDef(args.cartProduct) || args.cartProduct.is_deleted) && args.newQuantity === 1) {
          doAmplitudeTrack({
            event: {
              id: EAmplitudeAnalyticsEventId.ADD_TO_CART,
              productId: args.publicCatalogProduct.id,
              productSku: args.publicCatalogProduct.product_sku,
              productName: getCombinedProductName(
                args.publicCatalogProduct.name,
                args.publicCatalogProduct.secondary_name,
              ),
              isProductInFormulary: false,
              eventLocation: args.eventLocation,
              organizationId: organization.id,
              locationId: location.id,
            },
          });
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While updating cart.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    [
      doErrorClear,
      cartProductUpdateQuantityMutation,
      organization.id,
      location.id,
      baseSerializationKey,
      doAmplitudeTrack,
      queryRef,
      customer.id,
      customer.first_name,
      customer.last_name,
      customer.is_wellplaece_agent,
      doErrorNotify,
    ],
  );

  return useCallback<TCustomerNextOrderActionsTypes['DoCartProductUpdateQuantityOptNotify']>(
    (args) => void doCartProductUpdateQuantityAsyncNotify(args),
    [doCartProductUpdateQuantityAsyncNotify],
  );
}
