import { Typography } from '@src/components/appearance/basics/Typography';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import { ensureNotEmptyString, isDef } from '@src/gen/shared/utils/types';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TIcon } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import type { FormApi, Mutator, SubmissionErrors, ValidationErrors } from 'final-form';
import { FORM_ERROR } from 'final-form';
import isEqual from 'lodash/isEqual';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { Form as RFForm, useForm, useFormState } from 'react-final-form';

export type TFormValuesBase = { [key: string]: unknown };

export type TFormMutators<V extends TFormValuesBase> = { [key: string]: Mutator<V, V> };
export type TFormSubmit<V extends TFormValuesBase> = (values: V, form: FormApi<V, V>) => Promise<SubmissionErrors>;
export type TFormValidate<V extends TFormValuesBase> = (values: V) => Promise<ValidationErrors> | ValidationErrors;

const SDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
});
const SChildrenDiv = styled('div', {
  flex: 1,
});
const SSubmitIconButtonDiv = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  flexBasis: '$submitIconButtonWidth',
  paddingTop: '$submitIconButtonPadding',
});

export type TFormBase<V extends TFormValuesBase> = {
  initialValues: V;
  onSubmit: TFormSubmit<V>;
  requireChanges?: boolean | undefined;
  validate?: TFormValidate<V> | undefined;
};

export type TForm<V extends TFormValuesBase> = PropsWithChildren<TFormBase<V>>;
export const FORM_CLASS_NAME = 'wp-message-send-form';

export const MessageSendForm = withCssToString(
  FORM_CLASS_NAME,
  <V extends TFormValuesBase>({
    initialValues,
    onSubmit,
    requireChanges,
    validate,
    children,
  }: TForm<V>): JSX.Element => {
    const { doErrorNotify } = useErrors();

    const mutators = useMemo<TFormMutators<V>>(
      () => ({
        setFieldTouched: function ([name, touched]: [string, boolean], state): void {
          const field = state.fields[name];

          if (isDef(field)) {
            field.touched = touched;
          }
        },
      }),
      [],
    );

    const handleSubmit = useCallback<TFormSubmit<V>>(
      async (values, form) => {
        try {
          await onSubmit(values, form);
          form.restart();
          return undefined;
        } catch (thrown: unknown) {
          const errorSummary = getErrorSummary(thrown, { forceDisplayMode: EErrorSummaryDisplayMode.SILENT });
          doErrorNotify(errorSummary);

          return {
            [FORM_ERROR]: errorSummary.display.message,
          };
        }
      },
      [doErrorNotify, onSubmit],
    );

    return (
      <RFForm<V, V>
        className={FORM_CLASS_NAME}
        initialValues={initialValues}
        mutators={mutators}
        onSubmit={handleSubmit}
        {...(validate !== undefined ? { validate } : {})}>
        {({ submitErrors }): JSX.Element => {
          return (
            <Card.Container flush={true} variant='form'>
              <SDiv>
                <SChildrenDiv> {children}</SChildrenDiv>
                <SSubmitIconButton requireChanges={requireChanges} icon='send' />
              </SDiv>

              {isDef(submitErrors?.[FORM_ERROR]) && (
                <Typography.ErrorCaption
                  error={isDef(submitErrors?.[FORM_ERROR]) ? ensureNotEmptyString(submitErrors?.[FORM_ERROR]) : null}
                />
              )}
            </Card.Container>
          );
        }}
      </RFForm>
    );
  },
);

function SSubmitIconButton<V extends TFormValuesBase>({
  requireChanges,
  icon,
}: {
  requireChanges?: boolean | undefined;
  icon: TIcon;
}): JSX.Element {
  const { hasValidationErrors, initialValues, submitting, values } = useFormState<V, V>({
    subscription: {
      hasValidationErrors: true,
      initialValues: true,
      submitting: true,
      values: true,
    },
  });

  const { submit } = useForm();
  const disabled = hasValidationErrors || (requireChanges === true && isEqual(initialValues, values));
  const handleClick = useCallback(() => void submit(), [submit]);

  return (
    <SSubmitIconButtonDiv>
      <IconButton
        icon={icon}
        onClick={handleClick}
        variant={disabled ? 'disabled' : submitting ? 'loading' : 'default'}
      />
    </SSubmitIconButtonDiv>
  );
}
