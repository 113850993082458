import { Drawer } from '@src/components/appearance/structure/Drawer';
import { OrganizationAddForm } from '@src/components/mixins/forms/OrganizationAddForm';
import { OrganizationAddSimplifiedForm } from '@src/components/mixins/forms/OrganizationAddSimplifiedForm';
import type {
  AgentCustomersOrganizationAddMutationVariables,
  AgentCustomersOrganizationAddSimplifiedMutationVariables,
} from '@src/gen/graphql/bindings';
import {
  useAgentCustomersOrganizationAddMutation,
  useAgentCustomersOrganizationAddSimplifiedMutation,
} from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useAuthAgent } from '@src/modules/auth/AuthProvider';
import { useDrawer } from '@src/modules/design/DrawerProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { encodeAgentCustomersOrganizationOverviewPath } from '@src/modules/routing/agent';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export type TAgentCustomersOrganizationsActionsTypes = {
  BeginOrganizationAdd: () => void;
  DoCustomersOrganizationAddAsync: (args: AgentCustomersOrganizationAddMutationVariables) => Promise<void>;
  BeginOrganizationAddSimplified: () => void;
  DoCustomersOrganizationAddSimplifiedAsync: (
    args: AgentCustomersOrganizationAddSimplifiedMutationVariables,
  ) => Promise<void>;
};

export type TAgentCustomersOrganizationsActionsContext = {
  beginOrganizationAdd: TAgentCustomersOrganizationsActionsTypes['BeginOrganizationAdd'] | null;
  beginOrganizationAddSimplified: TAgentCustomersOrganizationsActionsTypes['BeginOrganizationAddSimplified'] | null;
};

export const { Context: AgentCustomersOrganizationsActionsContext, useContext: useAgentCustomersOrganizationsActions } =
  createRequiredContext<TAgentCustomersOrganizationsActionsContext>();

export function AgentCustomersOrganizationsActionsProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const user = useAuthAgent();

  const beginOrganizationAdd = useBeginOrganizationAdd();
  const beginOrganizationAddSimplified = useBeginOrganizationAddSimplified();
  const canCreateOrgLoc = user.agent_can_create_organizations_and_locations;
  const canCreateSandbox = user.agent_can_create_sandbox;

  const value = useMemo<TAgentCustomersOrganizationsActionsContext>(
    () => ({
      beginOrganizationAdd: canCreateOrgLoc ? beginOrganizationAdd : null,
      beginOrganizationAddSimplified: canCreateSandbox || canCreateOrgLoc ? beginOrganizationAddSimplified : null,
    }),
    // @sort
    [beginOrganizationAdd, beginOrganizationAddSimplified, canCreateOrgLoc, canCreateSandbox],
  );

  return (
    <AgentCustomersOrganizationsActionsContext.Provider value={value}>
      {children}
    </AgentCustomersOrganizationsActionsContext.Provider>
  );
}

function useBeginOrganizationAdd(): TAgentCustomersOrganizationsActionsTypes['BeginOrganizationAdd'] {
  const [customersOrganizationAddMutation] = useAgentCustomersOrganizationAddMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const navigate = useNavigate();

  const doCustomersOrganizationAddAsync = useCallback<
    TAgentCustomersOrganizationsActionsTypes['DoCustomersOrganizationAddAsync']
  >(
    async (args) => {
      const { data, errors } = await customersOrganizationAddMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
      navigate(encodeAgentCustomersOrganizationOverviewPath(ensureDef(data?.agent_organization_add_v3?.id)));
    },
    // @sort
    [customersOrganizationAddMutation, doDrawerClose, navigate],
  );

  return useCallback<TAgentCustomersOrganizationsActionsTypes['BeginOrganizationAdd']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Add Organization' />
          <Drawer.ScrollContent>
            <OrganizationAddForm doCustomersOrganizationAddAsync={doCustomersOrganizationAddAsync} />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersOrganizationAddAsync, doDrawerOpen],
  );
}

function useBeginOrganizationAddSimplified(): TAgentCustomersOrganizationsActionsTypes['BeginOrganizationAddSimplified'] {
  const [customersOrganizationAddSimplifiedMutation] = useAgentCustomersOrganizationAddSimplifiedMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const navigate = useNavigate();

  const doCustomersOrganizationAddSimplifiedAsync = useCallback<
    TAgentCustomersOrganizationsActionsTypes['DoCustomersOrganizationAddSimplifiedAsync']
  >(
    async (args) => {
      const { data, errors } = await customersOrganizationAddSimplifiedMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
      navigate(encodeAgentCustomersOrganizationOverviewPath(ensureDef(data?.agent_organization_add_simplified_v3?.id)));
    },
    // @sort
    [customersOrganizationAddSimplifiedMutation, doDrawerClose, navigate],
  );

  return useCallback<TAgentCustomersOrganizationsActionsTypes['BeginOrganizationAdd']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Add Sandboxed Customer' />
          <Drawer.ScrollContent>
            <OrganizationAddSimplifiedForm
              doCustomersOrganizationAddSimplifiedAsync={doCustomersOrganizationAddSimplifiedAsync}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersOrganizationAddSimplifiedAsync, doDrawerOpen],
  );
}
