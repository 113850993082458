import type { TSecondaryNavTab } from '@src/components/appearance/structure/SecondaryNav';
import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { CustomerOrderSummaryPartLoader } from '@src/components/parts/customer/order/CustomerOrderSummaryPart';
import { CustomerOrderUpdatesAndSupportPartLoader } from '@src/components/parts/customer/order/CustomerOrderUpdatesAndSupportPart';
import { getOrderReference } from '@src/gen/shared/data/snippets';
import { useCustomerOrder } from '@src/modules/data/customer/order/CustomerOrderProvider';
import {
  ECustomerRoutes,
  encodeCustomerOrderSummaryPath,
  encodeCustomerOrderUpdatesAndSupportPath,
  useCustomerOrderRouteParams,
} from '@src/modules/routing/customer';
import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function CustomerOrderNavPart(): JSX.Element {
  const { order } = useCustomerOrder();
  const tabs = useTabs();

  return (
    <SecondaryNav
      highlight={getOrderReference(order.location.order_code, order.reference_number)}
      label={`for ${order.location.name}`}
      tabs={tabs}
      tag={order.status}
      errorTag={order.is_emergency ? 'Emergency' : undefined}
      title='Order'
    />
  );
}

export function CustomerOrderNavPartLoader(): JSX.Element {
  const tabs = useTabs();
  const routeParams = useCustomerOrderRouteParams();

  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav tabs={tabs} tagLoader={true} title='Order' />
      </Structure.Content>
      {routeParams.route === ECustomerRoutes.CUSTOMER_ORDER_SUMMARY && <CustomerOrderSummaryPartLoader />}
      {routeParams.route === ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT && (
        <CustomerOrderUpdatesAndSupportPartLoader />
      )}
    </Fragment>
  );
}

function useTabs(): TSecondaryNavTab[] {
  const routeParams = useCustomerOrderRouteParams();
  const navigate = useNavigate();

  return useMemo<TSecondaryNavTab[]>(
    () => [
      {
        active: routeParams.route === ECustomerRoutes.CUSTOMER_ORDER_SUMMARY,
        title: 'Summary',
        onClick: () => navigate(encodeCustomerOrderSummaryPath(routeParams.organizationId, routeParams.orderId)),
      },
      {
        active: routeParams.route === ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT,
        title: 'Updates and Support',
        onClick: () =>
          navigate(encodeCustomerOrderUpdatesAndSupportPath(routeParams.organizationId, routeParams.orderId)),
      },
    ],
    // @sort
    [navigate, routeParams],
  );
}
