import { Typography } from '@src/components/appearance/basics/Typography';
import { ActionButton } from '@src/components/appearance/controls/Button';
import { Chrome } from '@src/components/appearance/structure/Chrome';
import { Dialog } from '@src/components/appearance/structure/Dialog';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { OrderSnapshotChromePanel } from '@src/components/mixins/chromes/OrderSnapshotChromePanel';
import { OrderCancelDialogPanel } from '@src/components/mixins/dialogs/OrderCancelDialogPanel';
import { OrderReturnInstructionsDialogPanel } from '@src/components/mixins/dialogs/OrderReturnInstructionsDialogPanel';
import { BackfillOrderInvoiceDrawerPanel } from '@src/components/mixins/drawers/BackfillOrderInvoiceDrawerPanel';
import { OrderBackOrderAddDrawerPanel } from '@src/components/mixins/drawers/OrderBackOrderAddDrawerPanel';
import { OrderBackOrderProductDrawerPanel } from '@src/components/mixins/drawers/OrderBackOrderProductDrawerPanel';
import { OrderBaseGroupEditDrawerPanel } from '@src/components/mixins/drawers/OrderBaseGroupEditDrawerPanel';
import { OrderEntryAddDrawerPanel } from '@src/components/mixins/drawers/OrderEntryAddDrawerPanel';
import { OrderEntryBaseProductDrawerPanel } from '@src/components/mixins/drawers/OrderEntryBaseProductDrawerPanel';
import { OrderEntryEditDrawerPanel } from '@src/components/mixins/drawers/OrderEntryEditDrawerPanel';
import { OrderEntryPlanProductDrawerPanel } from '@src/components/mixins/drawers/OrderEntryPlanProductDrawerPanel';
import { OrderPlanGroupEditDrawerPanel } from '@src/components/mixins/drawers/OrderPlanGroupEditDrawerPanel';
import { OrderProductDrawerPanel } from '@src/components/mixins/drawers/OrderProductDrawerPanel';
import {
  OrderProductTrackingHistoryDrawerPanel,
  OrderProductTrackingHistoryDrawerPanelLoader,
} from '@src/components/mixins/drawers/OrderProductTrackingHistoryDrawerPanel';
import { OrderReturnProductDrawerPanel } from '@src/components/mixins/drawers/OrderReturnProductDrawerPanel';
import { OrderShipmentAddDrawerPanel } from '@src/components/mixins/drawers/OrderShipmentAddDrawerPanel';
import { OrderShipmentProductDrawerPanel } from '@src/components/mixins/drawers/OrderShipmentProductDrawerPanel';
import { OrderSnapshotEntryBaseProductDrawerPanel } from '@src/components/mixins/drawers/OrderSnapshotEntryBaseProductDrawerPanel';
import { OrderSnapshotEntryPlanProductDrawerPanel } from '@src/components/mixins/drawers/OrderSnapshotEntryPlanProductDrawerPanel';
import { ProductHintBaseProductDrawerPanel } from '@src/components/mixins/drawers/ProductHintBaseProductDrawerPanel';
import { ProductHintPlanProductDrawerPanel } from '@src/components/mixins/drawers/ProductHintPlanProductDrawerPanel';
import { OrderAttachmentAddForm } from '@src/components/mixins/forms/OrderAttachmentAddForm';
import { OrderPaymentAddForm } from '@src/components/mixins/forms/OrderPaymentAddForm';
import { OrderReturnReplyForm } from '@src/components/mixins/forms/OrderReturnReplyForm';
import { OrderStatusEditForm } from '@src/components/mixins/forms/OrderStatusEditForm';
import { OrderStatusEditFormV2 } from '@src/components/mixins/forms/OrderStatusEditFormV2';
import { OrderTrackingNumberAddForm } from '@src/components/mixins/forms/OrderTrackingNumberAddForm';
import { OrderPlacedStructureGroup } from '@src/components/mixins/groups/OrderPlacedStructureGroup';
import { OrderSnapshotStructureGroup } from '@src/components/mixins/groups/OrderSnapshotStructureGroup';
import type {
  AgentAddOrderShipmentMutationVariables,
  AgentBackfillOrderInvoiceMutationVariables,
  AgentGenerateOrderReturnUrlsMutation,
  AgentIssueOrderSnapshotMutationVariables,
  AgentOrderAttachmentAddMutationVariables,
  AgentOrderBackOrderAddMutationVariables,
  AgentOrderBackOrderApplyMutationVariables,
  AgentOrderBackOrderRemoveMutationVariables,
  AgentOrderBaseGroupUpdateMutationVariables,
  AgentOrderEntryAddMutationVariables,
  AgentOrderEntryUpdateMutationVariables,
  AgentOrderMessageSendMutationVariables,
  AgentOrderPaymentAddMutationVariables,
  AgentOrderPaymentRemoveMutationVariables,
  AgentOrderPlanGroupUpdateMutationVariables,
  AgentOrderReturnApplyMutationVariables,
  AgentOrderReturnRemoveMutationVariables,
  AgentOrderReturnReplyMutationVariables,
  AgentOrderShipmentRemoveMutationVariables,
  AgentOrderShipmentSetDeliveredMutationVariables,
  AgentOrderSnapshotRetractMutationVariables,
  AgentOrderUpdateMutationVariables,
  TAgentOrderBackOrderBaseFragment,
  TAgentOrderReturnBaseFragment,
  TAgentOrderShipmentProductBaseFragment,
  TAgentOrderSnapshotCompleteFragment,
} from '@src/gen/graphql/bindings';
import {
  useAgentAddOrderShipmentMutation,
  useAgentBackfillOrderInvoiceMutation,
  useAgentGenerateOrderAttachmentUrlMutation,
  useAgentGenerateOrderDashboardExportUrlMutation,
  useAgentGenerateOrderExportUrlMutation,
  useAgentGenerateOrderReturnUrlsMutation,
  useAgentGenerateOrderSnapshotExportUrlMutation,
  useAgentIssueOrderSnapshotMutation,
  useAgentOrderAttachmentAddMutation,
  useAgentOrderAttachmentRemoveMutation,
  useAgentOrderBackOrderAddMutation,
  useAgentOrderBackOrderApplyMutation,
  useAgentOrderBackOrderRemoveMutation,
  useAgentOrderBackfilledInvoiceRemoveMutation,
  useAgentOrderBaseGroupUpdateMutation,
  useAgentOrderCancelMutation,
  useAgentOrderEntryAddMutation,
  useAgentOrderEntryUpdateMutation,
  useAgentOrderMessageSendMutation,
  useAgentOrderPaymentAddMutation,
  useAgentOrderPaymentRemoveMutation,
  useAgentOrderPlanGroupUpdateMutation,
  useAgentOrderReturnApplyMutation,
  useAgentOrderReturnRemoveMutation,
  useAgentOrderReturnReplyMutation,
  useAgentOrderShipmentRemoveMutation,
  useAgentOrderShipmentSetDeliveredMutation,
  useAgentOrderSnapshotRetractMutation,
  useAgentOrderTrackingNumberAddMutation,
  useAgentOrderTrackingNumberRemoveMutation,
  useAgentOrderUpdateMutation,
} from '@src/gen/graphql/bindings';
import type { AgentOrderSnapshotEntryManager, AgentProductHintManager } from '@src/gen/shared/data/agentOrders';
import { AgentOrderPaymentsAnalyzer, AgentOrderSnapshotManager } from '@src/gen/shared/data/agentOrders';
import type { CustomerOrderSnapshotEntryManager } from '@src/gen/shared/data/customerOrders';
import { EExportFormat } from '@src/gen/shared/enums/exportFormat';
import { EOrderExportType } from '@src/gen/shared/enums/orderExportType';
import type { EOrderSnapshotType } from '@src/gen/shared/enums/orderSnapshotType';
import { maybeGetOrderSnapshotTypeLabel } from '@src/gen/shared/enums/orderSnapshotType';
import type { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, downloadUrl } from '@src/logic/internal/utils/utils';
import { useAgentProductComparePickerChrome } from '@src/modules/data/agent/global/overlays/AgentProductComparePickerChromeProvider';
import { useAgentProductHistoryPickerChrome } from '@src/modules/data/agent/global/overlays/AgentProductHistoryPickerChromeProvider';
import { useAgentProductPickerChrome } from '@src/modules/data/agent/global/overlays/AgentProductPickerChromeProvider';
import { useAgentUploads } from '@src/modules/data/agent/global/uploads/AgentUploadsProvider';
import {
  AgentGetTrackerResultProvider,
  useAgentGetTrackerResult,
} from '@src/modules/data/agent/order/AgentGetTrackerResultProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import { useChrome } from '@src/modules/design/ChromeProvider';
import { useDialog } from '@src/modules/design/DialogProvider';
import { useDrawer, useTopDrawer } from '@src/modules/design/DrawerProvider';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import { useAgentOrderRouteParams } from '@src/modules/routing/agent';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TAgentOrderActionsTypes = {
  BeginBackfillOrderInvoice: () => void;
  BeginOrderAttachmentAdd: () => void;
  BeginOrderBackfilledInvoiceRemove: () => void;
  BeginOrderBackOrderAdd: () => void;
  BeginOrderBackOrderProductView: (args: { orderBackOrder: TAgentOrderBackOrderBaseFragment }) => void;
  BeginOrderBaseGroupEdit: (args: { orderBaseGroupId: string }) => void;
  BeginOrderCancel: () => void;
  BeginOrderEntryAdd: () => void;
  BeginOrderEntryBaseProductView: (args: { orderEntryId: string }) => void;
  BeginOrderEntryEdit: (args: { orderEntryId: string }) => void;
  BeginOrderEntryPlanProductView: (args: { orderEntryId: string }) => void;
  BeginOrderOriginalCartView: () => void;
  BeginOrderPaymentAdd: () => void;
  BeginOrderPlanGroupEdit: (args: { orderPlanGroupId: string }) => void;
  BeginOrderProductView: (args: { productId: string }) => void;
  BeginOrderReturnProductView: (args: { orderReturn: TAgentOrderReturnBaseFragment }) => void;
  BeginOrderReturnReply: (args: { orderReturnId: string }) => void;
  BeginOrderReturnSeeInstructionsAsyncNotify: (args: { orderReturnId: string }) => Promise<void>;
  BeginOrderShipmentAdd: () => void;
  BeginOrderShipmentProductView: (args: { orderShipmentProduct: TAgentOrderShipmentProductBaseFragment }) => void;
  BeginOrderSnapshotEntryView: (args: {
    type: 'base' | 'plan';
    orderSnapshotEntryManager: AgentOrderSnapshotEntryManager | CustomerOrderSnapshotEntryManager;
  }) => void;
  BeginOrderSnapshotPreviewAsyncNotify: (args: { type: EOrderSnapshotType }) => Promise<void>;
  BeginOrderSnapshotView: (args: { orderSnapshotId: string }) => void;
  BeginOrderStatusEdit: () => void;
  BeginOrderStatusEditV2: () => void;
  BeginOrderTrackingHistoryView: (args: { ship24TrackerId: string }) => void;
  BeginOrderTrackingNumberAdd: () => void;
  BeginProductHintBaseProductView: (args: { productHintManager: AgentProductHintManager }) => void;
  BeginProductHintPlanProductView: (args: { productHintManager: AgentProductHintManager }) => void;
  DoAddOrderShipmentAsync: (args: AgentAddOrderShipmentMutationVariables) => Promise<void>;
  DoBackfillOrderInvoiceAsync: (args: Omit<AgentBackfillOrderInvoiceMutationVariables, 'orderId'>) => Promise<void>;
  DoIssueOrderSnapshotAsync: (
    args: Omit<AgentIssueOrderSnapshotMutationVariables, 'orderId'>,
  ) => Promise<{ preview: unknown }>;
  DoOrderAttachmentAddAsync: (args: Omit<AgentOrderAttachmentAddMutationVariables, 'orderId'>) => Promise<void>;
  DoOrderAttachmentRemoveAsyncNotify: (args: { uploadId: string }) => Promise<void>;
  DoOrderBackfilledInvoiceRemove: () => Promise<void>;
  DoOrderBackOrderAddAsync: (args: AgentOrderBackOrderAddMutationVariables) => Promise<void>;
  DoOrderBackOrderApplyAsyncNotify: (args: AgentOrderBackOrderApplyMutationVariables) => Promise<void>;
  DoOrderBackOrderRemoveAsyncNotify: (args: AgentOrderBackOrderRemoveMutationVariables) => Promise<void>;
  DoOrderBaseGroupUpdateAsync: (args: AgentOrderBaseGroupUpdateMutationVariables) => Promise<void>;
  DoOrderCancelAsync: () => Promise<void>;
  DoOrderEntryAddAsync: (args: Omit<AgentOrderEntryAddMutationVariables, 'orderId'>) => Promise<void>;
  DoOrderEntryUpdateAsync: (args: AgentOrderEntryUpdateMutationVariables) => Promise<void>;
  DoOrderMessageSendAsync: (args: Omit<AgentOrderMessageSendMutationVariables, 'orderId'>) => Promise<void>;
  DoOrderPaymentAddAsync: (args: Omit<AgentOrderPaymentAddMutationVariables, 'orderId'>) => Promise<void>;
  DoOrderPaymentRemoveAsyncNotify: (args: AgentOrderPaymentRemoveMutationVariables) => Promise<void>;
  DoOrderPlanGroupUpdateAsync: (args: AgentOrderPlanGroupUpdateMutationVariables) => Promise<void>;
  DoOrderReturnApplyAsyncNotify: (args: AgentOrderReturnApplyMutationVariables) => Promise<void>;
  DoOrderReturnRemoveAsyncNotify: (args: AgentOrderReturnRemoveMutationVariables) => Promise<void>;
  DoOrderReturnReplyAsync: (args: AgentOrderReturnReplyMutationVariables) => Promise<void>;
  DoOrderShipmentSetDeliveredAsyncNotify: (args: AgentOrderShipmentSetDeliveredMutationVariables) => Promise<void>;
  DoOrderShipmentRemoveAsyncNotify: (args: AgentOrderShipmentRemoveMutationVariables) => Promise<void>;
  DoOrderSnapshotRetractAsyncNotify: (args: AgentOrderSnapshotRetractMutationVariables) => Promise<void>;
  DoOrderTrackingNumberAddAsync: (args: { trackingNumber: string }) => Promise<void>;
  DoOrderTrackingNumberRemoveAsyncNotify: (args: { trackingNumber: string }) => Promise<void>;
  DoOrderUpdateAsync: (args: Omit<AgentOrderUpdateMutationVariables, 'orderId'>) => Promise<void>;
  DoOrderUpdateStatusAsyncNotify: (args: { status: EOrderStatus }) => Promise<void>;
  DownloadOrderAttachmentAsyncNotify: (args: { uploadId: string }) => Promise<void>;
  DownloadOrderExportOriginalCartAsyncNotify: () => Promise<void>;
  DownloadOrderReturnPictureAsyncNotify: (args: { orderReturnId: string }) => Promise<void>;
  DownloadOrderSnapshotAsyncNotify: (args: { orderSnapshotId: string; exportFormat: EExportFormat }) => Promise<void>;
  DownloadOrderDashboardAsyncNotify: (args: { orderId: string }) => Promise<void>;
};

export type TAgentOrderActionsContext = {
  beginBackfillOrderInvoice: TAgentOrderActionsTypes['BeginBackfillOrderInvoice'];
  beginOrderAttachmentAdd: TAgentOrderActionsTypes['BeginOrderAttachmentAdd'];
  beginOrderBackfilledInvoiceRemove: TAgentOrderActionsTypes['BeginOrderBackfilledInvoiceRemove'];
  beginOrderBackOrderAdd: TAgentOrderActionsTypes['BeginOrderBackOrderAdd'];
  beginOrderBackOrderProductView: TAgentOrderActionsTypes['BeginOrderBackOrderProductView'];
  beginOrderBaseGroupEdit: TAgentOrderActionsTypes['BeginOrderBaseGroupEdit'];
  beginOrderCancel: TAgentOrderActionsTypes['BeginOrderCancel'];
  beginOrderEntryAdd: TAgentOrderActionsTypes['BeginOrderEntryAdd'];
  beginOrderEntryBaseProductView: TAgentOrderActionsTypes['BeginOrderEntryBaseProductView'];
  beginOrderEntryEdit: TAgentOrderActionsTypes['BeginOrderEntryEdit'];
  beginOrderEntryPlanProductView: TAgentOrderActionsTypes['BeginOrderEntryPlanProductView'];
  beginOrderOriginalCartView: TAgentOrderActionsTypes['BeginOrderOriginalCartView'];
  beginOrderPaymentAdd: TAgentOrderActionsTypes['BeginOrderPaymentAdd'];
  beginOrderPlanGroupEdit: TAgentOrderActionsTypes['BeginOrderPlanGroupEdit'];
  beginOrderProductView: TAgentOrderActionsTypes['BeginOrderProductView'];
  beginOrderReturnProductView: TAgentOrderActionsTypes['BeginOrderReturnProductView'];
  beginOrderReturnReply: TAgentOrderActionsTypes['BeginOrderReturnReply'];
  beginOrderReturnSeeInstructionsAsyncNotify: TAgentOrderActionsTypes['BeginOrderReturnSeeInstructionsAsyncNotify'];
  beginOrderShipmentAdd: TAgentOrderActionsTypes['BeginOrderShipmentAdd'];
  beginOrderShipmentProductView: TAgentOrderActionsTypes['BeginOrderShipmentProductView'];
  beginOrderSnapshotEntryView: TAgentOrderActionsTypes['BeginOrderSnapshotEntryView'];
  beginOrderSnapshotPreviewAsyncNotify: TAgentOrderActionsTypes['BeginOrderSnapshotPreviewAsyncNotify'];
  beginOrderSnapshotView: TAgentOrderActionsTypes['BeginOrderSnapshotView'];
  beginOrderStatusEdit: TAgentOrderActionsTypes['BeginOrderStatusEdit'];
  beginOrderStatusEditV2: TAgentOrderActionsTypes['BeginOrderStatusEdit'];
  beginOrderTrackingHistoryView: TAgentOrderActionsTypes['BeginOrderTrackingHistoryView'];
  beginOrderTrackingNumberAdd: TAgentOrderActionsTypes['BeginOrderTrackingNumberAdd'];
  beginProductHintBaseProductView: TAgentOrderActionsTypes['BeginProductHintBaseProductView'];
  beginProductHintPlanProductView: TAgentOrderActionsTypes['BeginProductHintPlanProductView'];
  doOrderAttachmentRemoveAsyncNotify: TAgentOrderActionsTypes['DoOrderAttachmentRemoveAsyncNotify'];
  doOrderBackOrderApplyAsyncNotify: TAgentOrderActionsTypes['DoOrderBackOrderApplyAsyncNotify'];
  doOrderBackOrderRemoveAsyncNotify: TAgentOrderActionsTypes['DoOrderBackOrderRemoveAsyncNotify'];
  doOrderMessageSendAsync: TAgentOrderActionsTypes['DoOrderMessageSendAsync'];
  doOrderPaymentRemoveAsyncNotify: TAgentOrderActionsTypes['DoOrderPaymentRemoveAsyncNotify'];
  doOrderReturnApplyAsyncNotify: TAgentOrderActionsTypes['DoOrderReturnApplyAsyncNotify'];
  doOrderReturnRemoveAsyncNotify: TAgentOrderActionsTypes['DoOrderReturnRemoveAsyncNotify'];
  doOrderShipmentSetDeliveredAsyncNotify: TAgentOrderActionsTypes['DoOrderShipmentSetDeliveredAsyncNotify'];
  doOrderShipmentRemoveAsyncNotify: TAgentOrderActionsTypes['DoOrderShipmentRemoveAsyncNotify'];
  doOrderSnapshotRetractAsyncNotify: TAgentOrderActionsTypes['DoOrderSnapshotRetractAsyncNotify'];
  doOrderTrackingNumberRemoveAsyncNotify: TAgentOrderActionsTypes['DoOrderTrackingNumberRemoveAsyncNotify'];
  doOrderUpdateStatusAsyncNotify: TAgentOrderActionsTypes['DoOrderUpdateStatusAsyncNotify'];
  downloadOrderAttachmentAsyncNotify: TAgentOrderActionsTypes['DownloadOrderAttachmentAsyncNotify'];
  downloadOrderExportOriginalCartAsyncNotify: TAgentOrderActionsTypes['DownloadOrderExportOriginalCartAsyncNotify'];
  downloadOrderReturnPictureAsyncNotify: TAgentOrderActionsTypes['DownloadOrderReturnPictureAsyncNotify'];
  downloadOrderSnapshotAsyncNotify: TAgentOrderActionsTypes['DownloadOrderSnapshotAsyncNotify'];
  downloadOrderDashboardAsyncNotify: TAgentOrderActionsTypes['DownloadOrderDashboardAsyncNotify'];
};

export const { Context: AgentOrderActionsContext, useContext: useAgentOrderActions } =
  createRequiredContext<TAgentOrderActionsContext>();

export function AgentOrderActionsProvider({ children }: PropsWithChildren): JSX.Element {
  const beginBackfillOrderInvoice = useBeginBackfillOrderInvoice();
  const beginOrderAttachmentAdd = useBeginOrderAttachmentAdd();
  const beginOrderBackfilledInvoiceRemove = useBeginOrderBackfilledInvoiceRemove();
  const beginOrderBackOrderAdd = useBeginOrderBackOrderAdd();
  const beginOrderBackOrderProductView = useBeginOrderBackOrderProductView();
  const beginOrderBaseGroupEdit = useBeginOrderBaseGroupEdit();
  const beginOrderCancel = useBeginOrderCancel();
  const beginOrderEntryAdd = useBeginOrderEntryAdd();
  const beginOrderEntryBaseProductView = useBeginOrderEntryBaseProductView();
  const beginOrderEntryEdit = useBeginOrderEntryEdit();
  const beginOrderEntryPlanProductView = useBeginOrderEntryPlanProductView();
  const beginOrderOriginalCartView = useBeginOrderOriginalCartView();
  const beginOrderPaymentAdd = useBeginOrderPaymentAdd();
  const beginOrderPlanGroupEdit = useBeginOrderPlanGroupEdit();
  const beginOrderProductView = useBeginOrderProductView();
  const beginOrderReturnProductView = useBeginOrderReturnProductView();
  const beginOrderReturnReply = useBeginOrderReturnReply();
  const beginOrderReturnSeeInstructionsAsyncNotify = useBeginOrderReturnSeeInstructionsAsyncNotify();
  const beginOrderShipmentAdd = useBeginOrderShipmentAdd();
  const beginOrderShipmentProductView = useBeginOrderShipmentProductView();
  const beginOrderSnapshotEntryView = useBeginOrderSnapshotEntryView();
  const beginOrderSnapshotPreviewAsyncNotify = useBeginOrderSnapshotPreviewAsyncNotify();
  const beginOrderSnapshotView = useBeginOrderSnapshotView();
  const beginOrderStatusEdit = useBeginOrderStatusEdit();
  const beginOrderStatusEditV2 = useBeginOrderStatusEditV2();
  const beginOrderTrackingHistoryView = useBeginOrderTrackingHistoryView();
  const beginOrderTrackingNumberAdd = useBeginOrderTrackingNumberAdd();
  const beginProductHintBaseProductView = useBeginProductHintBaseProductView();
  const beginProductHintPlanProductView = useBeginProductHintPlanProductView();
  const doOrderAttachmentRemoveAsyncNotify = useDoOrderAttachmentRemoveAsyncNotify();
  const doOrderBackOrderApplyAsyncNotify = useDoOrderBackOrderApplyAsyncNotify();
  const doOrderBackOrderRemoveAsyncNotify = useDoOrderBackOrderRemoveAsyncNotify();
  const doOrderMessageSendAsync = useDoOrderMessageSendAsync();
  const doOrderPaymentRemoveAsyncNotify = useDoOrderPaymentRemoveAsyncNotify();
  const doOrderReturnApplyAsyncNotify = useDoOrderReturnApplyAsyncNotify();
  const doOrderReturnRemoveAsyncNotify = useDoOrderReturnRemoveAsyncNotify();
  const doOrderShipmentSetDeliveredAsyncNotify = useDoOrderShipmentSetDeliveredAsyncNotify();
  const doOrderShipmentRemoveAsyncNotify = useDoOrderShipmentRemoveAsyncNotify();
  const doOrderSnapshotRetractAsyncNotify = useDoOrderSnapshotRetractAsyncNotify();
  const doOrderTrackingNumberRemoveAsyncNotify = useDoOrderTrackingNumberRemoveAsyncNotify();
  const doOrderUpdateStatusAsyncNotify = useDoOrderUpdateStatusAsyncNotify();
  const downloadOrderAttachmentAsyncNotify = useDownloadOrderAttachmentAsyncNotify();
  const downloadOrderExportOriginalCartAsyncNotify = useDownloadOrderExportOriginalCartAsyncNotify();
  const downloadOrderReturnPictureAsyncNotify = useDownloadOrderReturnPictureAsyncNotify();
  const downloadOrderSnapshotAsyncNotify = useDownloadOrderSnapshotAsyncNotify();
  const downloadOrderDashboardAsyncNotify = useDownloadOrderDashboardAsyncNotify();

  const value = useMemo<TAgentOrderActionsContext>(
    () => ({
      beginBackfillOrderInvoice,
      beginOrderAttachmentAdd,
      beginOrderBackfilledInvoiceRemove,
      beginOrderBackOrderAdd,
      beginOrderBackOrderProductView,
      beginOrderBaseGroupEdit,
      beginOrderCancel,
      beginOrderEntryAdd,
      beginOrderEntryBaseProductView,
      beginOrderEntryEdit,
      beginOrderEntryPlanProductView,
      beginOrderOriginalCartView,
      beginOrderPaymentAdd,
      beginOrderPlanGroupEdit,
      beginOrderProductView,
      beginOrderReturnProductView,
      beginOrderReturnReply,
      beginOrderReturnSeeInstructionsAsyncNotify,
      beginOrderShipmentAdd,
      beginOrderShipmentProductView,
      beginOrderSnapshotEntryView,
      beginOrderSnapshotPreviewAsyncNotify,
      beginOrderSnapshotView,
      beginOrderStatusEdit,
      beginOrderStatusEditV2,
      beginOrderTrackingHistoryView,
      beginOrderTrackingNumberAdd,
      beginProductHintBaseProductView,
      beginProductHintPlanProductView,
      doOrderAttachmentRemoveAsyncNotify,
      doOrderBackOrderApplyAsyncNotify,
      doOrderBackOrderRemoveAsyncNotify,
      doOrderMessageSendAsync,
      doOrderPaymentRemoveAsyncNotify,
      doOrderReturnApplyAsyncNotify,
      doOrderReturnRemoveAsyncNotify,
      doOrderShipmentRemoveAsyncNotify,
      doOrderShipmentSetDeliveredAsyncNotify,
      doOrderSnapshotRetractAsyncNotify,
      doOrderTrackingNumberRemoveAsyncNotify,
      doOrderUpdateStatusAsyncNotify,
      downloadOrderAttachmentAsyncNotify,
      downloadOrderExportOriginalCartAsyncNotify,
      downloadOrderReturnPictureAsyncNotify,
      downloadOrderSnapshotAsyncNotify,
      downloadOrderDashboardAsyncNotify,
    }),
    // @sort
    [
      beginBackfillOrderInvoice,
      beginOrderAttachmentAdd,
      beginOrderBackOrderAdd,
      beginOrderBackOrderProductView,
      beginOrderBackfilledInvoiceRemove,
      beginOrderBaseGroupEdit,
      beginOrderCancel,
      beginOrderEntryAdd,
      beginOrderEntryBaseProductView,
      beginOrderEntryEdit,
      beginOrderEntryPlanProductView,
      beginOrderOriginalCartView,
      beginOrderPaymentAdd,
      beginOrderPlanGroupEdit,
      beginOrderProductView,
      beginOrderReturnProductView,
      beginOrderReturnReply,
      beginOrderReturnSeeInstructionsAsyncNotify,
      beginOrderShipmentAdd,
      beginOrderShipmentProductView,
      beginOrderSnapshotEntryView,
      beginOrderSnapshotPreviewAsyncNotify,
      beginOrderSnapshotView,
      beginOrderStatusEdit,
      beginOrderStatusEditV2,
      beginOrderTrackingHistoryView,
      beginOrderTrackingNumberAdd,
      beginProductHintBaseProductView,
      beginProductHintPlanProductView,
      doOrderAttachmentRemoveAsyncNotify,
      doOrderBackOrderApplyAsyncNotify,
      doOrderBackOrderRemoveAsyncNotify,
      doOrderMessageSendAsync,
      doOrderPaymentRemoveAsyncNotify,
      doOrderReturnApplyAsyncNotify,
      doOrderReturnRemoveAsyncNotify,
      doOrderShipmentRemoveAsyncNotify,
      doOrderShipmentSetDeliveredAsyncNotify,
      doOrderSnapshotRetractAsyncNotify,
      doOrderTrackingNumberRemoveAsyncNotify,
      doOrderUpdateStatusAsyncNotify,
      downloadOrderAttachmentAsyncNotify,
      downloadOrderDashboardAsyncNotify,
      downloadOrderExportOriginalCartAsyncNotify,
      downloadOrderReturnPictureAsyncNotify,
      downloadOrderSnapshotAsyncNotify,
    ],
  );

  return <AgentOrderActionsContext.Provider value={value}>{children}</AgentOrderActionsContext.Provider>;
}

function useBeginBackfillOrderInvoice(): TAgentOrderActionsTypes['BeginBackfillOrderInvoice'] {
  const [backfillOrderInvoiceMutation] = useAgentBackfillOrderInvoiceMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { serializationKey } = useAgentOrder();

  const doBackfillOrderInvoiceAsync = useCallback<TAgentOrderActionsTypes['DoBackfillOrderInvoiceAsync']>(
    async (args) => {
      const { errors } = await backfillOrderInvoiceMutation({
        variables: {
          orderId: routeParams.orderId,
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [backfillOrderInvoiceMutation, doDrawerClose, serializationKey, routeParams.orderId],
  );

  return useCallback<TAgentOrderActionsTypes['BeginBackfillOrderInvoice']>(
    () => {
      doDrawerOpen(<BackfillOrderInvoiceDrawerPanel doBackfillOrderInvoiceAsync={doBackfillOrderInvoiceAsync} />);
    },
    // @sort
    [doBackfillOrderInvoiceAsync, doDrawerOpen],
  );
}

function useBeginOrderAttachmentAdd(): TAgentOrderActionsTypes['BeginOrderAttachmentAdd'] {
  const [orderAttachmentAddMutation] = useAgentOrderAttachmentAddMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { doUploadAsync } = useAgentUploads();
  const { serializationKey } = useAgentOrder();

  const doOrderAttachmentAddAsync = useCallback<TAgentOrderActionsTypes['DoOrderAttachmentAddAsync']>(
    async (args) => {
      const { errors } = await orderAttachmentAddMutation({
        variables: {
          orderId: routeParams.orderId,
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderAttachmentAddMutation, serializationKey, routeParams.orderId],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderAttachmentAdd']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Add Order Attachment' />
          <Drawer.ScrollContent>
            <OrderAttachmentAddForm
              doUploadAsync={doUploadAsync}
              doOrderAttachmentAddAsync={doOrderAttachmentAddAsync}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doDrawerOpen, doOrderAttachmentAddAsync, doUploadAsync],
  );
}

function useBeginOrderBackfilledInvoiceRemove(): TAgentOrderActionsTypes['BeginOrderBackfilledInvoiceRemove'] {
  const [orderBackfilledInvoiceRemoveMutation] = useAgentOrderBackfilledInvoiceRemoveMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doDialogOpen } = useDialog();
  const { serializationKey } = useAgentOrder();

  const doOrderBackfilledInvoiceRemove = useCallback<TAgentOrderActionsTypes['DoOrderBackfilledInvoiceRemove']>(
    async () => {
      const { errors } = await orderBackfilledInvoiceRemoveMutation({
        variables: {
          orderId: routeParams.orderId,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }
    },
    // @sort
    [orderBackfilledInvoiceRemoveMutation, serializationKey, routeParams.orderId],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderBackfilledInvoiceRemove']>(
    () =>
      doDialogOpen(
        <Dialog.Panel>
          <Dialog.Header title='Remove Back-filled Invoice' />
          <Typography.Small text='Are you sure you want to remove the back-filled invoice for this order?' />
          <Dialog.Footer
            buttonAction={{
              isAsync: true,
              text: 'Remove Back-filled Invoice',
              onClick: doOrderBackfilledInvoiceRemove,
            }}
          />
        </Dialog.Panel>,
      ),
    // @sort
    [doDialogOpen, doOrderBackfilledInvoiceRemove],
  );
}

function useBeginOrderBackOrderAdd(): TAgentOrderActionsTypes['BeginOrderBackOrderAdd'] {
  const [orderBackOrderAddMutation] = useAgentOrderBackOrderAddMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { orderManager, serializationKey } = useAgentOrder();

  const doOrderBackOrderAddAsync = useCallback<TAgentOrderActionsTypes['DoOrderBackOrderAddAsync']>(
    async (args) => {
      const { errors } = await orderBackOrderAddMutation({
        variables: {
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderBackOrderAddMutation, serializationKey],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderBackOrderAdd']>(
    () => {
      doDrawerOpen(
        <OrderBackOrderAddDrawerPanel
          doOrderBackOrderAddAsync={doOrderBackOrderAddAsync}
          orderSnapshotEntryManagers={
            ensureDef(orderManager.maybeGetOrderSnapshotInvoiceLatest()).orderSnapshotEntryManagers
          }
        />,
      );
    },
    // @sort
    [doDrawerOpen, doOrderBackOrderAddAsync, orderManager],
  );
}

function useBeginOrderBackOrderProductView(): TAgentOrderActionsTypes['BeginOrderBackOrderProductView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TAgentOrderActionsTypes['BeginOrderBackOrderProductView']>(
    ({ orderBackOrder }) => {
      doTopDrawerOpen(<OrderBackOrderProductDrawerPanel orderBackOrder={orderBackOrder} />);
    },
    [doTopDrawerOpen],
  );
}

function useBeginOrderBaseGroupEdit(): TAgentOrderActionsTypes['BeginOrderBaseGroupEdit'] {
  const [orderBaseGroupUpdateMutation] = useAgentOrderBaseGroupUpdateMutation();
  const { doDrawerOpen, doDrawerClose } = useDrawer();
  const { orderManager } = useAgentOrder();
  const { serializationKey } = useAgentOrder();

  const doOrderBaseGroupUpdateAsync = useCallback<TAgentOrderActionsTypes['DoOrderBaseGroupUpdateAsync']>(
    async (args) => {
      const notes = (args.notes ?? '').replaceAll(/\n\n\n+/g, '\n\n').trim();

      const { errors } = await orderBaseGroupUpdateMutation({
        variables: {
          ...args,
          notes: notes.length > 0 ? notes : null,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderBaseGroupUpdateMutation, serializationKey],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderBaseGroupEdit']>(
    ({ orderBaseGroupId }) => {
      doDrawerOpen(
        <OrderBaseGroupEditDrawerPanel
          doOrderBaseGroupUpdateAsync={doOrderBaseGroupUpdateAsync}
          orderBaseGroupManager={orderManager.mustGetOrderBaseGroup(orderBaseGroupId)}
        />,
      );
    },
    // @sort
    [doDrawerOpen, doOrderBaseGroupUpdateAsync, orderManager],
  );
}

function useBeginOrderCancel(): TAgentOrderActionsTypes['BeginOrderCancel'] {
  const [orderCancelMutation] = useAgentOrderCancelMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doDialogOpen } = useDialog();
  const { serializationKey } = useAgentOrder();

  const doOrderCancelAsync = useCallback<TAgentOrderActionsTypes['DoOrderCancelAsync']>(
    async () => {
      const { errors } = await orderCancelMutation({
        variables: {
          orderId: routeParams.orderId,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }
    },
    // @sort
    [orderCancelMutation, serializationKey, routeParams.orderId],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderCancel']>(
    () => doDialogOpen(<OrderCancelDialogPanel doOrderCancelAsync={doOrderCancelAsync} />),
    // @sort
    [doDialogOpen, doOrderCancelAsync],
  );
}

function useBeginOrderEntryAdd(): TAgentOrderActionsTypes['BeginOrderEntryAdd'] {
  const [orderEntryAddMutation] = useAgentOrderEntryAddMutation();
  const routeParams = useAgentOrderRouteParams();
  const { beginProductPick } = useAgentProductPickerChrome();
  const { doDrawerOpen, doDrawerClose } = useDrawer();
  const { serializationKey } = useAgentOrder();

  const doOrderEntryAddAsync = useCallback<TAgentOrderActionsTypes['DoOrderEntryAddAsync']>(
    async (args) => {
      const notes = (args.notes ?? '').replaceAll(/\n\n\n+/g, '\n\n').trim();

      const { errors } = await orderEntryAddMutation({
        variables: {
          orderId: routeParams.orderId,
          ...args,
          notes: notes.length > 0 ? notes : null,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderEntryAddMutation, serializationKey, routeParams.orderId],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderEntryAdd']>(
    () => {
      doDrawerOpen(
        <OrderEntryAddDrawerPanel beginProductPick={beginProductPick} doOrderEntryAddAsync={doOrderEntryAddAsync} />,
      );
    },
    // @sort
    [beginProductPick, doDrawerOpen, doOrderEntryAddAsync],
  );
}

function useBeginOrderEntryBaseProductView(): TAgentOrderActionsTypes['BeginOrderEntryBaseProductView'] {
  const { doDrawerOpen } = useDrawer();
  const { orderManager } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['BeginOrderEntryBaseProductView']>(
    ({ orderEntryId }) => {
      doDrawerOpen(
        <OrderEntryBaseProductDrawerPanel orderEntryManager={orderManager.mustGetOrderEntry(orderEntryId)} />,
      );
    },
    // @sort
    [doDrawerOpen, orderManager],
  );
}

function useBeginOrderEntryEdit(): TAgentOrderActionsTypes['BeginOrderEntryEdit'] {
  const [orderEntryUpdateMutation] = useAgentOrderEntryUpdateMutation();
  const { beginProductComparePick } = useAgentProductComparePickerChrome();
  const { beginProductHistoryPick } = useAgentProductHistoryPickerChrome();
  const { doDrawerOpen, doDrawerClose } = useDrawer();
  const { orderManager, serializationKey } = useAgentOrder();
  const { doTopDrawerOpen } = useTopDrawer();

  const doOrderEntryUpdateAsync = useCallback<TAgentOrderActionsTypes['DoOrderEntryUpdateAsync']>(
    async (args) => {
      const notes = (args.notes ?? '').replaceAll(/\n\n\n+/g, '\n\n').trim();
      const customerVisibleNotes = (args.customerVisibleNotes ?? '').replaceAll(/\n\n\n+/g, '\n\n').trim();

      const { errors } = await orderEntryUpdateMutation({
        variables: {
          ...args,
          notes: notes.length > 0 ? notes : null,
          customerVisibleNotes: customerVisibleNotes.length > 0 ? customerVisibleNotes : null,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderEntryUpdateMutation, serializationKey],
  );

  const beginProductHintBaseProductView = useCallback<TAgentOrderActionsTypes['BeginProductHintBaseProductView']>(
    ({ productHintManager }) => {
      doTopDrawerOpen(<ProductHintBaseProductDrawerPanel productHintManager={productHintManager} />);
    },
    [doTopDrawerOpen],
  );

  const beginProductHintPlanProductView = useCallback<TAgentOrderActionsTypes['BeginProductHintPlanProductView']>(
    ({ productHintManager }) => {
      doTopDrawerOpen(<ProductHintPlanProductDrawerPanel productHintManager={productHintManager} />);
    },
    [doTopDrawerOpen],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderEntryEdit']>(
    ({ orderEntryId }) => {
      const orderEntry = orderManager.mustGetOrderEntry(orderEntryId);

      doDrawerOpen(
        <OrderEntryEditDrawerPanel
          beginProductComparePick={beginProductComparePick}
          beginProductHistoryPick={(callback): void => {
            beginProductHistoryPick({
              beginProductHintBaseProductView,
              beginProductHintPlanProductView,
              organizationId: orderManager.order.location.organization_id,
              locationId: orderManager.order.location_id,
              productId: orderEntry.orderEntry.product_id,
              callback,
            });
          }}
          doOrderEntryUpdateAsync={doOrderEntryUpdateAsync}
          orderEntryManager={orderEntry}
        />,
      );
    },
    // @sort
    [
      beginProductComparePick,
      beginProductHintBaseProductView,
      beginProductHintPlanProductView,
      beginProductHistoryPick,
      doDrawerOpen,
      doOrderEntryUpdateAsync,
      orderManager,
    ],
  );
}

function useBeginOrderEntryPlanProductView(): TAgentOrderActionsTypes['BeginOrderEntryPlanProductView'] {
  const { doDrawerOpen } = useDrawer();
  const { orderManager } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['BeginOrderEntryPlanProductView']>(
    ({ orderEntryId }) => {
      doDrawerOpen(
        <OrderEntryPlanProductDrawerPanel orderEntryManager={orderManager.mustGetOrderEntry(orderEntryId)} />,
      );
    },
    // @sort
    [doDrawerOpen, orderManager],
  );
}

function useBeginOrderOriginalCartView(): TAgentOrderActionsTypes['BeginOrderOriginalCartView'] {
  const { doChromeOpen } = useChrome();
  const { order } = useAgentOrder();
  const { doTopDrawerOpen } = useTopDrawer();

  const beginOrderProductView = useCallback<TAgentOrderActionsTypes['BeginOrderProductView']>(
    (args) => {
      const orderProduct = ensureDef(order.order_products.find((op) => op.product_id === args.productId));
      doTopDrawerOpen(<OrderProductDrawerPanel orderProduct={orderProduct} />);
    },
    // @sort
    [doTopDrawerOpen, order.order_products],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderOriginalCartView']>(
    async (): Promise<void> => {
      doChromeOpen(
        <Chrome.Panel>
          <Chrome.Header title='Original Cart' />
          <Chrome.ScrollContent>
            <OrderPlacedStructureGroup beginOrderProductView={beginOrderProductView} order={order} />
          </Chrome.ScrollContent>
        </Chrome.Panel>,
      );
    },
    // @sort
    [beginOrderProductView, doChromeOpen, order],
  );
}

function useBeginOrderPaymentAdd(): TAgentOrderActionsTypes['BeginOrderPaymentAdd'] {
  const [orderPaymentAddMutation] = useAgentOrderPaymentAddMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { serializationKey, order, orderManager } = useAgentOrder();

  const doOrderPaymentAddAsync = useCallback<TAgentOrderActionsTypes['DoOrderPaymentAddAsync']>(
    async (args) => {
      const { errors } = await orderPaymentAddMutation({
        variables: {
          orderId: routeParams.orderId,
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderPaymentAddMutation, routeParams, serializationKey],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderPaymentAdd']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Add Payment' />
          <Drawer.ScrollContent>
            <OrderPaymentAddForm
              orderPaymentsAnalyzer={
                new AgentOrderPaymentsAnalyzer(
                  ensureDef(orderManager.maybeGetOrderSnapshotInvoiceLatest()),
                  order.order_payments,
                  order.is_paid,
                )
              }
              doOrderPaymentAddAsync={doOrderPaymentAddAsync}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doDrawerOpen, doOrderPaymentAddAsync, orderManager, order.is_paid, order.order_payments],
  );
}

function useBeginOrderPlanGroupEdit(): TAgentOrderActionsTypes['BeginOrderPlanGroupEdit'] {
  const [orderPlanGroupUpdateMutation] = useAgentOrderPlanGroupUpdateMutation();
  const { doDrawerOpen, doDrawerClose } = useDrawer();
  const { orderManager } = useAgentOrder();
  const { serializationKey } = useAgentOrder();

  const doOrderPlanGroupUpdateAsync = useCallback<TAgentOrderActionsTypes['DoOrderPlanGroupUpdateAsync']>(
    async (args) => {
      const notes = (args.notes ?? '').replaceAll(/\n\n\n+/g, '\n\n').trim();

      const { errors } = await orderPlanGroupUpdateMutation({
        variables: {
          ...args,
          notes: notes.length > 0 ? notes : null,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderPlanGroupUpdateMutation, serializationKey],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderPlanGroupEdit']>(
    ({ orderPlanGroupId }) => {
      doDrawerOpen(
        <OrderPlanGroupEditDrawerPanel
          doOrderPlanGroupUpdateAsync={doOrderPlanGroupUpdateAsync}
          orderPlanGroupManager={orderManager.mustGetOrderPlanGroup(orderPlanGroupId)}
        />,
      );
    },
    // @sort
    [doDrawerOpen, doOrderPlanGroupUpdateAsync, orderManager],
  );
}

function useBeginOrderProductView(): TAgentOrderActionsTypes['BeginOrderProductView'] {
  const { doTopDrawerOpen } = useTopDrawer();
  const { order } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['BeginOrderProductView']>(
    (args) => {
      const orderProduct = ensureDef(order.order_products.find((op) => op.product_id === args.productId));
      doTopDrawerOpen(<OrderProductDrawerPanel orderProduct={orderProduct} />);
    },
    // @sort
    [doTopDrawerOpen, order.order_products],
  );
}

function useBeginOrderReturnProductView(): TAgentOrderActionsTypes['BeginOrderReturnProductView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TAgentOrderActionsTypes['BeginOrderReturnProductView']>(
    ({ orderReturn }) => {
      doTopDrawerOpen(<OrderReturnProductDrawerPanel orderReturn={orderReturn} />);
    },
    [doTopDrawerOpen],
  );
}

function useBeginOrderReturnReply(): TAgentOrderActionsTypes['BeginOrderReturnReply'] {
  const [orderReturnReplyMutation] = useAgentOrderReturnReplyMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { doUploadAsync } = useAgentUploads();
  const { order, serializationKey } = useAgentOrder();

  const doOrderReturnReplyAsync = useCallback<TAgentOrderActionsTypes['DoOrderReturnReplyAsync']>(
    async (args) => {
      const { errors } = await orderReturnReplyMutation({
        variables: {
          ...args,
          repliedInstructions: args.repliedInstructions?.replaceAll(/\n\n\n+/g, '\n\n').trim() ?? null,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderReturnReplyMutation, serializationKey],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderReturnReply']>(
    ({ orderReturnId }) => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Add Return Instructions' />
          <Drawer.ScrollContent>
            <OrderReturnReplyForm
              doOrderReturnReplyAsync={doOrderReturnReplyAsync}
              doUploadAsync={doUploadAsync}
              orderReturn={ensureDef(order.order_returns.find((or) => or.id === orderReturnId))}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doDrawerOpen, doOrderReturnReplyAsync, doUploadAsync, order.order_returns],
  );
}

function useBeginOrderReturnSeeInstructionsAsyncNotify(): TAgentOrderActionsTypes['BeginOrderReturnSeeInstructionsAsyncNotify'] {
  const [generateOrderReturnUrlsMutation] = useAgentGenerateOrderReturnUrlsMutation();
  const { doDialogOpen, doDialogClose } = useDialog();
  const { doErrorClear, doErrorNotify } = useErrors();
  const { order, serializationKey } = useAgentOrder();

  const doGenerateOrderReturnUrlsAsyncNotify = useCallback<
    (args: { orderReturnId: string }) => Promise<AgentGenerateOrderReturnUrlsMutation['generateOrderReturnUrls'] | null>
  >(
    async (args) => {
      try {
        doErrorClear();

        const { errors, data } = await generateOrderReturnUrlsMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        return ensureDef(data).generateOrderReturnUrls;
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While preparing return instructions.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
        return null;
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderReturnUrlsMutation, serializationKey],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderReturnSeeInstructionsAsyncNotify']>(
    async ({ orderReturnId }): Promise<void> => {
      const maybeUrls = await doGenerateOrderReturnUrlsAsyncNotify({
        orderReturnId,
      });

      if (!isDef(maybeUrls)) {
        return;
      }

      const orderReturn = ensureDef(order.order_returns.find((or) => or.id === orderReturnId));
      const repliedShippingLabelUrl = maybeUrls.repliedShippingLabelUrl;

      doDialogOpen(
        <OrderReturnInstructionsDialogPanel
          downloadShippingLabel={
            isDef(repliedShippingLabelUrl) ? (): void => downloadUrl(repliedShippingLabelUrl) : null
          }
          instructions={ensureDef(orderReturn.replied_instructions)}
          onClose={doDialogClose}
        />,
      );
    },
    // @sort
    [doDialogClose, doDialogOpen, doGenerateOrderReturnUrlsAsyncNotify, order.order_returns],
  );
}

function useBeginOrderShipmentAdd(): TAgentOrderActionsTypes['BeginOrderShipmentAdd'] {
  const [addOrderShipmentMutation] = useAgentAddOrderShipmentMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { orderManager, serializationKey } = useAgentOrder();

  const doAddOrderShipmentAsync = useCallback<TAgentOrderActionsTypes['DoAddOrderShipmentAsync']>(
    async (args) => {
      const { errors } = await addOrderShipmentMutation({
        variables: {
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [addOrderShipmentMutation, doDrawerClose, serializationKey],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderShipmentAdd']>(
    () => {
      doDrawerOpen(
        <OrderShipmentAddDrawerPanel
          doAddOrderShipmentAsync={doAddOrderShipmentAsync}
          orderSnapshotEntryManagers={
            ensureDef(orderManager.maybeGetOrderSnapshotInvoiceLatest()).orderSnapshotEntryManagers
          }
        />,
      );
    },
    // @sort
    [doAddOrderShipmentAsync, doDrawerOpen, orderManager],
  );
}

function useBeginOrderShipmentProductView(): TAgentOrderActionsTypes['BeginOrderShipmentProductView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TAgentOrderActionsTypes['BeginOrderShipmentProductView']>(
    ({ orderShipmentProduct }) => {
      doTopDrawerOpen(<OrderShipmentProductDrawerPanel orderShipmentProduct={orderShipmentProduct} />);
    },
    [doTopDrawerOpen],
  );
}

function useBeginOrderSnapshotEntryView(): TAgentOrderActionsTypes['BeginOrderSnapshotEntryView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TAgentOrderActionsTypes['BeginOrderSnapshotEntryView']>(
    ({ type, orderSnapshotEntryManager }) => {
      doTopDrawerOpen(
        type === 'base' ? (
          <OrderSnapshotEntryBaseProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ) : (
          <OrderSnapshotEntryPlanProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ),
      );
    },
    [doTopDrawerOpen],
  );
}

function useBeginOrderSnapshotPreviewAsyncNotify(): TAgentOrderActionsTypes['BeginOrderSnapshotPreviewAsyncNotify'] {
  const [issueOrderSnapshotMutation] = useAgentIssueOrderSnapshotMutation();
  const routeParams = useAgentOrderRouteParams();
  const { serializationKey } = useAgentOrder();
  const { doTopDrawerOpen } = useTopDrawer();
  const { doChromeOpen, doChromeClose } = useChrome();

  const beginOrderSnapshotEntryView = useCallback<TAgentOrderActionsTypes['BeginOrderSnapshotEntryView']>(
    ({ type, orderSnapshotEntryManager }) => {
      doTopDrawerOpen(
        type === 'base' ? (
          <OrderSnapshotEntryBaseProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ) : (
          <OrderSnapshotEntryPlanProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ),
      );
    },
    [doTopDrawerOpen],
  );

  const doIssueOrderSnapshotAsync = useCallback<TAgentOrderActionsTypes['DoIssueOrderSnapshotAsync']>(
    async (args) => {
      const { errors, data } = await issueOrderSnapshotMutation({
        variables: {
          orderId: routeParams.orderId,
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      return {
        preview: data?.issueOrderSnapshot.preview ?? null,
      };
    },
    // @sort
    [issueOrderSnapshotMutation, serializationKey, routeParams.orderId],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderSnapshotPreviewAsyncNotify']>(
    async ({ type }): Promise<void> => {
      const { preview } = await doIssueOrderSnapshotAsync({
        type,
        previewMode: true,
      });

      const orderSnapshotManager = new AgentOrderSnapshotManager(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ensureDef(preview) as TAgentOrderSnapshotCompleteFragment,
      );

      doChromeOpen(
        <OrderSnapshotChromePanel
          beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
          orderSnapshotManager={orderSnapshotManager}
          doIssueAsync={async (): Promise<void> => {
            await doIssueOrderSnapshotAsync({
              type,
              previewMode: false,
            });
            doChromeClose();
          }}
        />,
      );
    },
    // @sort
    [beginOrderSnapshotEntryView, doChromeClose, doChromeOpen, doIssueOrderSnapshotAsync],
  );
}

function useBeginOrderSnapshotView(): TAgentOrderActionsTypes['BeginOrderSnapshotView'] {
  const [generateOrderSnapshotExportUrlMutation] = useAgentGenerateOrderSnapshotExportUrlMutation();
  const { doChromeOpen } = useChrome();
  const { doErrorClear, doErrorNotify } = useErrors();
  const { doTopDrawerOpen } = useTopDrawer();
  const { orderManager } = useAgentOrder();

  const beginOrderSnapshotEntryView = useCallback<TAgentOrderActionsTypes['BeginOrderSnapshotEntryView']>(
    ({ type, orderSnapshotEntryManager }) => {
      doTopDrawerOpen(
        type === 'base' ? (
          <OrderSnapshotEntryBaseProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ) : (
          <OrderSnapshotEntryPlanProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ),
      );
    },
    [doTopDrawerOpen],
  );

  const downloadOrderSnapshotAsyncNotify = useCallback<TAgentOrderActionsTypes['DownloadOrderSnapshotAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderSnapshotExportUrlMutation({
          variables: {
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderSnapshotExportUrl.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderSnapshotExportUrlMutation],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderSnapshotView']>(
    async ({ orderSnapshotId }): Promise<void> => {
      const orderSnapshotManager = orderManager.mustGetOrderSnapshot(orderSnapshotId);

      doChromeOpen(
        <Chrome.Panel>
          <Chrome.Header title={maybeGetOrderSnapshotTypeLabel(orderSnapshotManager.orderSnapshot.type)} />
          <Chrome.ScrollContent>
            <OrderSnapshotStructureGroup
              beginOrderSnapshotEntryPlanQuantityEdit={null}
              beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
              isSummary={false}
              orderBudgetAssistantManager={null}
              orderSnapshotManager={orderSnapshotManager}
            />
          </Chrome.ScrollContent>
          {!orderSnapshotManager.isEditedByCustomer() && (
            <Chrome.FooterToolBar>
              <ActionButton
                action={{
                  isAsync: true,
                  onClick: async (): Promise<void> =>
                    await downloadOrderSnapshotAsyncNotify({ orderSnapshotId, exportFormat: EExportFormat.XLSX }),
                  text: 'Download XLSX',
                }}
              />
              <ActionButton
                action={{
                  isAsync: true,
                  onClick: async (): Promise<void> =>
                    await downloadOrderSnapshotAsyncNotify({ orderSnapshotId, exportFormat: EExportFormat.PDF }),
                  text: 'Download PDF',
                }}
              />
            </Chrome.FooterToolBar>
          )}
        </Chrome.Panel>,
      );
    },
    // @sort
    [beginOrderSnapshotEntryView, doChromeOpen, downloadOrderSnapshotAsyncNotify, orderManager],
  );
}

function useBeginOrderStatusEdit(): TAgentOrderActionsTypes['BeginOrderStatusEdit'] {
  const [orderUpdateMutation] = useAgentOrderUpdateMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { order, serializationKey } = useAgentOrder();

  const doOrderUpdateAsync = useCallback<TAgentOrderActionsTypes['DoOrderUpdateAsync']>(
    async (args) => {
      const { errors } = await orderUpdateMutation({
        variables: {
          orderId: routeParams.orderId,
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderUpdateMutation, serializationKey, routeParams.orderId],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderStatusEdit']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Edit Order Status' />
          <Drawer.ScrollContent>
            <OrderStatusEditForm order={order} doOrderUpdateAsync={doOrderUpdateAsync} />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doDrawerOpen, doOrderUpdateAsync, order],
  );
}

function useBeginOrderStatusEditV2(): TAgentOrderActionsTypes['BeginOrderStatusEditV2'] {
  const [orderUpdateMutation] = useAgentOrderUpdateMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { order, serializationKey } = useAgentOrder();

  const doOrderUpdateAsync = useCallback<TAgentOrderActionsTypes['DoOrderUpdateAsync']>(
    async (args) => {
      const { errors } = await orderUpdateMutation({
        variables: {
          orderId: routeParams.orderId,
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderUpdateMutation, serializationKey, routeParams.orderId],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderStatusEdit']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Edit Order Status' />
          <Drawer.ScrollContent>
            <OrderStatusEditFormV2 order={order} doOrderUpdateAsync={doOrderUpdateAsync} />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doDrawerOpen, doOrderUpdateAsync, order],
  );
}

function useBeginOrderTrackingHistoryView(): TAgentOrderActionsTypes['BeginOrderTrackingHistoryView'] {
  const { doDrawerOpen } = useDrawer();

  return useCallback<TAgentOrderActionsTypes['BeginOrderTrackingHistoryView']>(
    (args) => {
      doDrawerOpen(
        <AgentGetTrackerResultProvider
          LoaderComponent={OrderProductTrackingHistoryDrawerPanelLoader}
          ship24TrackerId={args.ship24TrackerId}>
          <BeginOrderTrackingHistoryViewConsumer />
        </AgentGetTrackerResultProvider>,
      );
    },
    // @sort
    [doDrawerOpen],
  );
}

function BeginOrderTrackingHistoryViewConsumer(): JSX.Element {
  const { trackerResult, error } = useAgentGetTrackerResult();
  return <OrderProductTrackingHistoryDrawerPanel trackerResult={trackerResult} error={error} />;
}

function useBeginOrderTrackingNumberAdd(): TAgentOrderActionsTypes['BeginOrderTrackingNumberAdd'] {
  const [orderTrackingNumberAddMutation] = useAgentOrderTrackingNumberAddMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { serializationKey } = useAgentOrder();

  const doOrderTrackingNumberAddAsync = useCallback<TAgentOrderActionsTypes['DoOrderTrackingNumberAddAsync']>(
    async (args) => {
      const { errors } = await orderTrackingNumberAddMutation({
        variables: {
          orderId: routeParams.orderId,
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderTrackingNumberAddMutation, serializationKey, routeParams.orderId],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderTrackingNumberAdd']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Add Order Tracking Number' />
          <Drawer.ScrollContent>
            <OrderTrackingNumberAddForm doOrderTrackingNumberAddAsync={doOrderTrackingNumberAddAsync} />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doDrawerOpen, doOrderTrackingNumberAddAsync],
  );
}

function useBeginProductHintBaseProductView(): TAgentOrderActionsTypes['BeginProductHintBaseProductView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TAgentOrderActionsTypes['BeginProductHintBaseProductView']>(
    ({ productHintManager }) => {
      doTopDrawerOpen(<ProductHintBaseProductDrawerPanel productHintManager={productHintManager} />);
    },
    [doTopDrawerOpen],
  );
}

function useBeginProductHintPlanProductView(): TAgentOrderActionsTypes['BeginProductHintPlanProductView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TAgentOrderActionsTypes['BeginProductHintPlanProductView']>(
    ({ productHintManager }) => {
      doTopDrawerOpen(<ProductHintPlanProductDrawerPanel productHintManager={productHintManager} />);
    },
    [doTopDrawerOpen],
  );
}

function useDoOrderAttachmentRemoveAsyncNotify(): TAgentOrderActionsTypes['DoOrderAttachmentRemoveAsyncNotify'] {
  const [orderAttachmentRemoveMutation] = useAgentOrderAttachmentRemoveMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderAttachmentRemoveAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderAttachmentRemoveMutation({
          variables: {
            orderId: routeParams.orderId,
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While removing an attachment.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderAttachmentRemoveMutation, serializationKey, routeParams.orderId],
  );
}

function useDoOrderBackOrderApplyAsyncNotify(): TAgentOrderActionsTypes['DoOrderBackOrderApplyAsyncNotify'] {
  const [orderBackOrderApplyMutation] = useAgentOrderBackOrderApplyMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderBackOrderApplyAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderBackOrderApplyMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While applying a back-order.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderBackOrderApplyMutation, serializationKey],
  );
}

function useDoOrderBackOrderRemoveAsyncNotify(): TAgentOrderActionsTypes['DoOrderBackOrderRemoveAsyncNotify'] {
  const [orderBackOrderRemoveMutation] = useAgentOrderBackOrderRemoveMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderBackOrderRemoveAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderBackOrderRemoveMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While removing a back-order.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderBackOrderRemoveMutation, serializationKey],
  );
}

function useDoOrderMessageSendAsync(): TAgentOrderActionsTypes['DoOrderMessageSendAsync'] {
  const [orderMessageSendMutation] = useAgentOrderMessageSendMutation();
  const routeParams = useAgentOrderRouteParams();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderMessageSendAsync']>(
    async (args) => {
      const { errors } = await orderMessageSendMutation({
        variables: {
          orderId: routeParams.orderId,
          message: args.message.replaceAll(/\n\n\n+/g, '\n\n').trim(),
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }
    },
    // @sort
    [orderMessageSendMutation, serializationKey, routeParams.orderId],
  );
}

function useDoOrderPaymentRemoveAsyncNotify(): TAgentOrderActionsTypes['DoOrderPaymentRemoveAsyncNotify'] {
  const [orderPaymentRemoveMutation] = useAgentOrderPaymentRemoveMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderPaymentRemoveAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderPaymentRemoveMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While removing a payment.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderPaymentRemoveMutation, serializationKey],
  );
}

function useDoOrderReturnApplyAsyncNotify(): TAgentOrderActionsTypes['DoOrderReturnApplyAsyncNotify'] {
  const [orderReturnApplyMutation] = useAgentOrderReturnApplyMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderReturnApplyAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderReturnApplyMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While applying a return.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderReturnApplyMutation, serializationKey],
  );
}

function useDoOrderReturnRemoveAsyncNotify(): TAgentOrderActionsTypes['DoOrderReturnRemoveAsyncNotify'] {
  const [orderReturnRemoveMutation] = useAgentOrderReturnRemoveMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderReturnRemoveAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderReturnRemoveMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While removing a return.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderReturnRemoveMutation, serializationKey],
  );
}

function useDoOrderShipmentSetDeliveredAsyncNotify(): TAgentOrderActionsTypes['DoOrderShipmentSetDeliveredAsyncNotify'] {
  const [orderShipmentSetDeliveredMutation] = useAgentOrderShipmentSetDeliveredMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderShipmentSetDeliveredAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderShipmentSetDeliveredMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While updating a shipment.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderShipmentSetDeliveredMutation, serializationKey],
  );
}

function useDoOrderShipmentRemoveAsyncNotify(): TAgentOrderActionsTypes['DoOrderShipmentRemoveAsyncNotify'] {
  const [orderShipmentRemoveMutation] = useAgentOrderShipmentRemoveMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderShipmentRemoveAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderShipmentRemoveMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While removing a shipment.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderShipmentRemoveMutation, serializationKey],
  );
}

function useDoOrderSnapshotRetractAsyncNotify(): TAgentOrderActionsTypes['DoOrderSnapshotRetractAsyncNotify'] {
  const [orderSnapshotRetractMutation] = useAgentOrderSnapshotRetractMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderSnapshotRetractAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderSnapshotRetractMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While retracting a prebill.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderSnapshotRetractMutation, serializationKey],
  );
}

function useDoOrderTrackingNumberRemoveAsyncNotify(): TAgentOrderActionsTypes['DoOrderTrackingNumberRemoveAsyncNotify'] {
  const [orderTrackingNumberRemoveMutation] = useAgentOrderTrackingNumberRemoveMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { serializationKey } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DoOrderTrackingNumberRemoveAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderTrackingNumberRemoveMutation({
          variables: {
            orderId: routeParams.orderId,
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While adding a tracking number.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderTrackingNumberRemoveMutation, serializationKey, routeParams.orderId],
  );
}

function useDoOrderUpdateStatusAsyncNotify(): TAgentOrderActionsTypes['DoOrderUpdateStatusAsyncNotify'] {
  const [orderUpdateMutation] = useAgentOrderUpdateMutation();
  const routeParams = useAgentOrderRouteParams();
  const { order, serializationKey } = useAgentOrder();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TAgentOrderActionsTypes['DoOrderUpdateStatusAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderUpdateMutation({
          variables: {
            orderId: routeParams.orderId,
            status: args.status,
            isBlockedOnCustomer: order.is_blocked_on_customer,
            hasBackOrderedProducts: order.has_back_ordered_products,
            hasPendingReturns: order.has_pending_returns,
            isPaid: order.is_paid,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While updating order status.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [
      doErrorClear,
      doErrorNotify,
      orderUpdateMutation,
      serializationKey,
      order.has_back_ordered_products,
      order.has_pending_returns,
      order.is_blocked_on_customer,
      order.is_paid,
      routeParams.orderId,
    ],
  );
}

function useDownloadOrderAttachmentAsyncNotify(): TAgentOrderActionsTypes['DownloadOrderAttachmentAsyncNotify'] {
  const [generateOrderAttachmentUrlMutation] = useAgentGenerateOrderAttachmentUrlMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { order } = useAgentOrder();

  return useCallback<TAgentOrderActionsTypes['DownloadOrderAttachmentAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderAttachmentUrlMutation({
          variables: {
            orderId: order.id,
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderAttachmentUrl?.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderAttachmentUrlMutation, order.id],
  );
}

function useDownloadOrderExportOriginalCartAsyncNotify(): TAgentOrderActionsTypes['DownloadOrderExportOriginalCartAsyncNotify'] {
  const [generateOrderExportUrlMutation] = useAgentGenerateOrderExportUrlMutation();
  const routeParams = useAgentOrderRouteParams();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TAgentOrderActionsTypes['DownloadOrderExportOriginalCartAsyncNotify']>(
    async () => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderExportUrlMutation({
          variables: {
            orderId: routeParams.orderId,
            exportType: EOrderExportType.ORIGINAL_CART,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderExportUrl?.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderExportUrlMutation, routeParams.orderId],
  );
}

function useDownloadOrderReturnPictureAsyncNotify(): TAgentOrderActionsTypes['DownloadOrderReturnPictureAsyncNotify'] {
  const [generateOrderReturnUrlsMutation] = useAgentGenerateOrderReturnUrlsMutation();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TAgentOrderActionsTypes['DownloadOrderReturnPictureAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderReturnUrlsMutation({
          variables: {
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderReturnUrls.pictureUrl));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderReturnUrlsMutation],
  );
}

function useDownloadOrderSnapshotAsyncNotify(): TAgentOrderActionsTypes['DownloadOrderSnapshotAsyncNotify'] {
  const [generateOrderSnapshotExportUrlMutation] = useAgentGenerateOrderSnapshotExportUrlMutation();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TAgentOrderActionsTypes['DownloadOrderSnapshotAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderSnapshotExportUrlMutation({
          variables: {
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderSnapshotExportUrl.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderSnapshotExportUrlMutation],
  );
}

function useDownloadOrderDashboardAsyncNotify(): TAgentOrderActionsTypes['DownloadOrderDashboardAsyncNotify'] {
  const [generateOrderDashboardExportUrlMutation] = useAgentGenerateOrderDashboardExportUrlMutation();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TAgentOrderActionsTypes['DownloadOrderDashboardAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderDashboardExportUrlMutation({
          variables: {
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderDashboardExportUrl.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderDashboardExportUrlMutation],
  );
}
