import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Structure } from '@src/components/appearance/structure/Structure';
import { NotificationsEmptyAnnouncement } from '@src/components/mixins/announcements/NotificationsEmptyAnnouncement';
import { NotificationCard, NotificationCardLoader } from '@src/components/mixins/cards/NotificationCard';
import type { TAgentNotificationCompleteFragment } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { useAgentNotificationsActions } from '@src/modules/data/agent/notifications/AgentNotificationsActionsProvider';
import { useAgentNotifications } from '@src/modules/data/agent/notifications/AgentNotificationsProvider';
import { encodeAgentOrderSummaryPath } from '@src/modules/routing/agent';
import { Fragment, memo, useCallback, useMemo } from 'react';

export function AgentUserPart(): JSX.Element {
  const { notifications, loaderRef } = useAgentNotifications();

  return (
    <Structure.ScrollContent>
      <Structure.Group title='Notifications'>
        {notifications.length > 0 ? (
          <Fragment>
            {notifications.map((notification) => (
              <CardComponent key={notification.id} notification={notification} />
            ))}
            {isDef(loaderRef) && <Illustrations.Spinner ref={loaderRef} />}
          </Fragment>
        ) : (
          <NotificationsEmptyAnnouncement />
        )}
      </Structure.Group>
    </Structure.ScrollContent>
  );
}

function CardComponent({ notification }: { notification: TAgentNotificationCompleteFragment }): JSX.Element {
  const { doNotificationClearOptNotify } = useAgentNotificationsActions();

  const to = useMemo(
    () => encodeAgentOrderSummaryPath(ensureDef(notification.order_id)),
    // @sort
    [notification.order_id],
  );

  const handleClear = useCallback(
    () => doNotificationClearOptNotify({ notificationId: notification.id }),
    // @sort
    [doNotificationClearOptNotify, notification.id],
  );

  return <NotificationCard notification={notification} onClear={handleClear} to={to} />;
}

export const AgentUserPartLoader = memo(
  (): JSX.Element => (
    <Structure.ScrollContentLoader>
      <Structure.Group title='Notifications'>
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
        <NotificationCardLoader />
      </Structure.Group>
    </Structure.ScrollContentLoader>
  ),
);
