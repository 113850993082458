import { Typography } from '@src/components/appearance/basics/Typography';
import type { TAsyncButtonAction, TSyncButtonAction } from '@src/components/appearance/controls/Button';
import type { TCheckBoxBase } from '@src/components/appearance/controls/CheckBox';
import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import type { TCurrencyBase } from '@src/components/appearance/controls/Currency';
import { Currency } from '@src/components/appearance/controls/Currency';
import type { TQuantityBase } from '@src/components/appearance/controls/Quantity';
import { Quantity } from '@src/components/appearance/controls/Quantity';
import type { TSelectBase } from '@src/components/appearance/controls/Select';
import { Select } from '@src/components/appearance/controls/Select';
import type { TTextAreaBase } from '@src/components/appearance/controls/TextArea';
import { TextArea } from '@src/components/appearance/controls/TextArea';
import type { TTextBoxBase } from '@src/components/appearance/controls/TextBox';
import { TextBox } from '@src/components/appearance/controls/TextBox';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import { Labeled } from '@src/components/appearance/fragments/Labeled';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { OrderEntryBaseProductCard } from '@src/components/mixins/cards/OrderEntryBaseProductCard';
import { OrderEntryPlanProductCard } from '@src/components/mixins/cards/OrderEntryPlanProductCard';
import type { TAgentOrderEntryBaseFragment, TAgentProductHintBaseFragment } from '@src/gen/graphql/bindings';
import { AgentOrderEntryManager } from '@src/gen/shared/data/agentOrders';
import { getOrderEntryOrigin, maybeGetOrderEntryOriginLabel } from '@src/gen/shared/enums/orderEntryOrigin';
import {
  EOrderEntryPlanStatus,
  getOrderEntryPlanStatus,
  maybeGetOrderEntryPlanStatusLabel,
} from '@src/gen/shared/enums/orderEntryPlanStatus';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentProductComparePickerChromeTypes } from '@src/modules/data/agent/global/overlays/AgentProductComparePickerChromeProvider';
import type { TAgentProductHistoryPickerChromeTypes } from '@src/modules/data/agent/global/overlays/AgentProductHistoryPickerChromeProvider';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';

export type TOrderEntryEditDrawerPanelBase = {
  beginProductComparePick: TAgentProductComparePickerChromeTypes['BeginProductComparePick'];
  beginProductHistoryPick: (
    callback: TAgentProductHistoryPickerChromeTypes['BeginProductHistoryPickArgs']['callback'],
  ) => void;
  doOrderEntryUpdateAsync: TAgentOrderActionsTypes['DoOrderEntryUpdateAsync'];
  orderEntryManager: AgentOrderEntryManager;
};

type TOrderEntryEditDrawerPanelState = {
  orderEntryManager: AgentOrderEntryManager;
};

type TMutateOrderEntryEditDrawerPanelState = (
  callback: (orderEntry: TAgentOrderEntryBaseFragment) => TAgentOrderEntryBaseFragment,
) => void;

export type TOrderEntryEditDrawerPanel = TProps<false, TOrderEntryEditDrawerPanelBase, 'div'>;
export const ORDER_ENTRY_EDIT_DRAWER_PANEL_CLASS_NAME = 'wp-order-entry-edit-drawer-panel';

export const OrderEntryEditDrawerPanel = withCssToString(
  ORDER_ENTRY_EDIT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderEntryEditDrawerPanel>(
      (
        {
          beginProductComparePick,
          beginProductHistoryPick,
          doOrderEntryUpdateAsync,
          orderEntryManager,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ENTRY_EDIT_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [state, setState] = useState<TOrderEntryEditDrawerPanelState>({
          orderEntryManager: new AgentOrderEntryManager({ ...orderEntryManager.orderEntry }),
        });

        const mutateState = useCallback<TMutateOrderEntryEditDrawerPanelState>((callback) => {
          setState((prevState) => ({
            orderEntryManager: new AgentOrderEntryManager(callback(prevState.orderEntryManager.orderEntry)),
          }));
        }, []);

        const handleSave = useCallback(async (): Promise<void> => {
          const orderEntry = state.orderEntryManager.orderEntry;
          await doOrderEntryUpdateAsync({
            orderEntryId: orderEntry.id,
            quantity: orderEntry.quantity,
            planStatus: orderEntry.plan_status,
            unitPrice: orderEntry.unit_price,
            customerVisibleNotes: orderEntry.customer_visible_notes ?? '',
            planProductId:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_product_id
                : null,
            planSource:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_source
                : null,
            planProductPageUrl:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_product_page_url
                : null,
            planProductSku:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_product_sku
                : null,
            planManufacturerName:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_manufacturer_name
                : null,
            planManufacturerSku:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_manufacturer_sku
                : null,
            planName:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER ? orderEntry.plan_name : null,
            planSecondaryName:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_secondary_name
                : null,
            planSaleUnit:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_sale_unit
                : null,
            planIsDiscontinued:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_is_discontinued
                : null,
            planIsHouseBrand:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_is_house_brand
                : null,
            planCategoryPath:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_category_path
                : null,
            planCategoryName:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_category_name
                : null,
            planSimplifiedCategory:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_simplified_category
                : null,
            planDescription:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_description
                : null,
            planSpecs:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_specs
                : null,
            planImageAssetPath:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_image_asset_path
                : null,
            planSdsAssetPath:
              orderEntry.plan_status !== EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER
                ? orderEntry.plan_sds_asset_path
                : null,
            planQuantity: orderEntry.plan_quantity,
            planUnitPrice: orderEntry.plan_unit_price,
            notes: orderEntry.notes,
            isUnderReview: orderEntry.is_under_review,
            isBlockedOnPriceConfirmation: orderEntry.is_blocked_on_price_confirmation,
            isBlockedOnProductAvailabilityConfirmation: orderEntry.is_blocked_on_product_availability_confirmation,
          });
        }, [state.orderEntryManager.orderEntry, doOrderEntryUpdateAsync]);

        const buttonAction = useMemo<TAsyncButtonAction>(
          () => ({
            isAsync: true,
            onClick: handleSave,
            text: 'Save',
            variant:
              !state.orderEntryManager.isPurchasedByWellplaece() ||
              isDef(state.orderEntryManager.orderEntry.plan_product_id)
                ? 'default'
                : 'disabled',
          }),
          [handleSave, state.orderEntryManager],
        );

        const handleHistoryPick = useCallback<
          TAgentProductHistoryPickerChromeTypes['BeginProductHistoryPickArgs']['callback']
        >(
          (productHint, mode) => {
            const applyBase = (
              oe: TAgentOrderEntryBaseFragment,
              ph: TAgentProductHintBaseFragment,
            ): TAgentOrderEntryBaseFragment => ({
              ...oe,
              unit_price: ph.unit_price,
            });

            const applyPlan = (
              oe: TAgentOrderEntryBaseFragment,
              ph: TAgentProductHintBaseFragment,
            ): TAgentOrderEntryBaseFragment => {
              switch (getOrderEntryPlanStatus(productHint.plan_status)) {
                case EOrderEntryPlanStatus.NONE:
                case EOrderEntryPlanStatus.REMOVED_REASON_BACK_ORDERED:
                case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_REQUESTED:
                case EOrderEntryPlanStatus.REMOVED_REASON_DISCONTINUED:
                case EOrderEntryPlanStatus.REMOVED_REASON_OUT_OF_STOCK:
                case EOrderEntryPlanStatus.REMOVED_REASON_RETURNED:
                case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
                case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
                case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
                  return {
                    ...oe,
                    plan_status: ph.plan_status,
                    plan_product_id: null,
                    plan_source: null,
                    plan_product_page_url: null,
                    plan_product_sku: null,
                    plan_manufacturer_name: null,
                    plan_manufacturer_sku: null,
                    plan_name: null,
                    plan_secondary_name: null,
                    plan_sale_unit: null,
                    plan_is_discontinued: null,
                    plan_is_house_brand: null,
                    plan_category_path: null,
                    plan_category_name: null,
                    plan_simplified_category: null,
                    plan_description: null,
                    plan_specs: null,
                    plan_image_asset_path: null,
                    plan_sds_asset_path: null,
                    plan_quantity: null,
                    plan_unit_price: null,
                  };
                case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
                  return {
                    ...oe,
                    plan_status: ph.plan_status,
                    plan_product_id: oe.product_id,
                    plan_source: oe.source,
                    plan_product_page_url: oe.product_page_url,
                    plan_product_sku: oe.product_sku,
                    plan_manufacturer_name: oe.manufacturer_name,
                    plan_manufacturer_sku: oe.manufacturer_sku,
                    plan_name: oe.name,
                    plan_secondary_name: oe.secondary_name,
                    plan_sale_unit: oe.sale_unit,
                    plan_is_discontinued: oe.is_discontinued,
                    plan_is_house_brand: oe.is_house_brand,
                    plan_category_path: oe.category_path,
                    plan_category_name: oe.category_name,
                    plan_simplified_category: oe.simplified_category,
                    plan_description: oe.description,
                    plan_specs: oe.specs,
                    plan_image_asset_path: oe.image_asset_path,
                    plan_sds_asset_path: oe.sds_asset_path,
                    plan_quantity: oe.quantity,
                    plan_unit_price: ph.plan_unit_price,
                  };
                case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
                case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
                case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
                case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
                case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
                  let planQuantity =
                    isDef(ph.quantity) && isDef(ph.plan_quantity)
                      ? (oe.quantity * ph.plan_quantity) / ph.quantity
                      : null;
                  if (!Number.isInteger(planQuantity)) {
                    planQuantity = null;
                  }

                  return {
                    ...oe,
                    plan_status: ph.plan_status,
                    plan_product_id: ph.plan_product_id,
                    plan_source: ph.plan_source,
                    plan_product_page_url: ph.plan_product_page_url,
                    plan_product_sku: ph.plan_product_sku,
                    plan_manufacturer_name: ph.plan_manufacturer_name,
                    plan_manufacturer_sku: ph.plan_manufacturer_sku,
                    plan_name: ph.plan_name,
                    plan_secondary_name: ph.plan_secondary_name,
                    plan_sale_unit: ph.plan_sale_unit,
                    plan_is_discontinued: ph.plan_is_discontinued,
                    plan_is_house_brand: ph.plan_is_house_brand,
                    plan_category_path: ph.plan_category_path,
                    plan_category_name: ph.plan_category_name,
                    plan_simplified_category: ph.plan_simplified_category,
                    plan_description: ph.plan_description,
                    plan_specs: ph.plan_specs,
                    plan_image_asset_path: ph.plan_image_asset_path,
                    plan_sds_asset_path: ph.plan_sds_asset_path,
                    plan_quantity: planQuantity,
                    plan_unit_price: ph.plan_unit_price,
                  };
              }
            };

            mutateState((orderEntry) => {
              switch (mode) {
                case 'base':
                  return applyBase(orderEntry, productHint);
                case 'plan':
                  return applyPlan(orderEntry, productHint);
                case 'both':
                  return applyPlan(applyBase(orderEntry, productHint), productHint);
              }
            });
          },
          [mutateState],
        );

        const secondaryButtonAction = useMemo<TSyncButtonAction>(
          () => ({
            isAsync: false,
            onClick: (): void => beginProductHistoryPick(handleHistoryPick),
            text: 'See History',
            variant: 'secondary',
          }),
          [beginProductHistoryPick, handleHistoryPick],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Edit Product' />
            <Drawer.ScrollContent>
              <Drawer.Group
                title='Base'
                tag={maybeGetOrderEntryOriginLabel(getOrderEntryOrigin(state.orderEntryManager.orderEntry.origin))}>
                <OrderEntryBaseProductCard onClick={null} orderEntryManager={state.orderEntryManager} />
                <BaseQuantityAndUnitPriceEditComponent state={state} mutateState={mutateState} />
              </Drawer.Group>
              <Drawer.Group title='Plan'>
                <PlanStatusSelectComponent state={state} mutateState={mutateState} />
                {!state.orderEntryManager.orderEntry.is_substitution_allowed &&
                  state.orderEntryManager.orderEntry.plan_status.startsWith('substitution_') && (
                    <Banner accent='warning' icon='warning' message='Substitutions are not allowed.' />
                  )}
                {state.orderEntryManager.isPurchasedByWellplaece() &&
                  state.orderEntryManager.orderEntry.plan_status !==
                    EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER && (
                    <PlanProductCardPickerComponent
                      beginProductComparePick={beginProductComparePick}
                      state={state}
                      mutateState={mutateState}
                    />
                  )}
                {state.orderEntryManager.orderEntry.plan_status ===
                  EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER && (
                  <OrderEntryPlanProductCard onClick={null} orderEntryManager={state.orderEntryManager} />
                )}
                {state.orderEntryManager.isPurchasedByWellplaece() && (
                  <PlanQuantityAndUnitPriceEditComponent state={state} mutateState={mutateState} />
                )}
              </Drawer.Group>
              <Drawer.Group title='Flags and Notes'>
                <FlagsAndNotesEditComponent state={state} mutateState={mutateState} />
              </Drawer.Group>
            </Drawer.ScrollContent>
            <Drawer.Footer buttonAction={buttonAction} secondaryButtonAction={secondaryButtonAction} />
          </Drawer.Panel>
        );
      },
    ),
  ),
);

const SBaseQuantityAndUnitPriceEditComponentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$controlGap',
});

function BaseQuantityAndUnitPriceEditComponent({
  state,
  mutateState,
}: {
  state: TOrderEntryEditDrawerPanelState;
  mutateState: TMutateOrderEntryEditDrawerPanelState;
}): JSX.Element {
  const handleQuantityChange = useCallback<TQuantityBase['onChange']>(
    (newValue) => {
      mutateState((orderEntry) => ({
        ...orderEntry,
        quantity: newValue,
      }));
    },
    [mutateState],
  );

  const handleUnitPriceChange = useCallback<TCurrencyBase['onChange']>(
    (newValue) => {
      mutateState((orderEntry) => ({
        ...orderEntry,
        unit_price: newValue,
      }));
    },
    [mutateState],
  );

  return (
    <Card.Container flush={true} interactive={false} variant='form'>
      <SBaseQuantityAndUnitPriceEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-entry-base-quantity-edit'
          required={false}
          rigid={true}
          text='Quantity (Base)'
        />
        <Quantity
          id='order-entry-plan-base-price-edit'
          value={state.orderEntryManager.orderEntry.quantity}
          onChange={handleQuantityChange}
        />
      </SBaseQuantityAndUnitPriceEditComponentDiv>
      <SBaseQuantityAndUnitPriceEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-entry-base-unit-price-edit'
          required={false}
          rigid={true}
          text='Unit Price (Base)'
        />
        <Currency
          id='order-entry-base-unit-price-edit'
          value={state.orderEntryManager.orderEntry.unit_price ?? 0}
          onChange={handleUnitPriceChange}
        />
      </SBaseQuantityAndUnitPriceEditComponentDiv>
    </Card.Container>
  );
}

function PlanStatusSelectComponent({
  state,
  mutateState,
}: {
  state: TOrderEntryEditDrawerPanelState;
  mutateState: TMutateOrderEntryEditDrawerPanelState;
}): JSX.Element {
  const handleChange = useCallback<TSelectBase['onChange']>(
    (e) => {
      const planStatus = getOrderEntryPlanStatus(e.target.value);

      mutateState((orderEntry) => {
        switch (planStatus) {
          case EOrderEntryPlanStatus.NONE:
          case EOrderEntryPlanStatus.REMOVED_REASON_BACK_ORDERED:
          case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_REQUESTED:
          case EOrderEntryPlanStatus.REMOVED_REASON_DISCONTINUED:
          case EOrderEntryPlanStatus.REMOVED_REASON_OUT_OF_STOCK:
          case EOrderEntryPlanStatus.REMOVED_REASON_RETURNED:
          case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE:
          case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED:
          case EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION:
            return {
              ...orderEntry,
              plan_status: planStatus,
              plan_product_id: null,
              plan_source: null,
              plan_product_page_url: null,
              plan_product_sku: null,
              plan_manufacturer_name: null,
              plan_manufacturer_sku: null,
              plan_name: null,
              plan_secondary_name: null,
              plan_sale_unit: null,
              plan_is_discontinued: null,
              plan_is_house_brand: null,
              plan_category_path: null,
              plan_category_name: null,
              plan_simplified_category: null,
              plan_description: null,
              plan_specs: null,
              plan_image_asset_path: null,
              plan_sds_asset_path: null,
              plan_quantity: null,
              plan_unit_price: null,
            };
          case EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER:
            return {
              ...orderEntry,
              plan_status: planStatus,
              plan_product_id: orderEntry.product_id,
              plan_source: orderEntry.source,
              plan_product_page_url: orderEntry.product_page_url,
              plan_product_sku: orderEntry.product_sku,
              plan_manufacturer_name: orderEntry.manufacturer_name,
              plan_manufacturer_sku: orderEntry.manufacturer_sku,
              plan_name: orderEntry.name,
              plan_secondary_name: orderEntry.secondary_name,
              plan_sale_unit: orderEntry.sale_unit,
              plan_is_discontinued: orderEntry.is_discontinued,
              plan_is_house_brand: orderEntry.is_house_brand,
              plan_category_path: orderEntry.category_path,
              plan_category_name: orderEntry.category_name,
              plan_simplified_category: orderEntry.simplified_category,
              plan_description: orderEntry.description,
              plan_specs: orderEntry.specs,
              plan_image_asset_path: orderEntry.image_asset_path,
              plan_sds_asset_path: orderEntry.sds_asset_path,
            };
          case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
          case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
          case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
          case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
          case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
            return {
              ...orderEntry,
              plan_status: planStatus,
            };
        }
      });
    },
    // @sort
    [mutateState],
  );

  return (
    <Select onChange={handleChange} value={state.orderEntryManager.orderEntry.plan_status}>
      {Object.values(EOrderEntryPlanStatus).map((ps) => (
        <option key={ps} value={ps}>
          {maybeGetOrderEntryPlanStatusLabel(ps)}
        </option>
      ))}
    </Select>
  );
}

const SPlanProductCardPickerComponent = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: '128px',
  justifyContent: 'center',
});

function PlanProductCardPickerComponent({
  beginProductComparePick,
  state,
  mutateState,
}: {
  beginProductComparePick: TAgentProductComparePickerChromeTypes['BeginProductComparePick'];
  state: TOrderEntryEditDrawerPanelState;
  mutateState: TMutateOrderEntryEditDrawerPanelState;
}): JSX.Element {
  const callback = useCallback<TAgentProductComparePickerChromeTypes['BeginProductComparePickArgs']['callback']>(
    (publicCatalogProduct) => {
      mutateState((orderEntry) => {
        const planStatus = getOrderEntryPlanStatus(orderEntry.plan_status);

        switch (planStatus) {
          case EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER:
          case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED:
          case EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE:
          case EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED:
          case EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK:
            break;
          default:
            throw new Error('Unexpected plan status');
        }

        return {
          ...orderEntry,
          plan_status: planStatus,
          plan_product_id: publicCatalogProduct.id,
          plan_source: publicCatalogProduct.source,
          plan_product_page_url: publicCatalogProduct.product_page_url,
          plan_product_sku: publicCatalogProduct.product_sku,
          plan_manufacturer_name: publicCatalogProduct.manufacturer_name,
          plan_manufacturer_sku: publicCatalogProduct.manufacturer_sku,
          plan_name: publicCatalogProduct.name,
          plan_secondary_name: publicCatalogProduct.secondary_name,
          plan_sale_unit: publicCatalogProduct.sale_unit,
          plan_is_discontinued: publicCatalogProduct.is_discontinued,
          plan_is_house_brand: publicCatalogProduct.is_house_brand,
          plan_category_path: publicCatalogProduct.category_path,
          plan_category_name: publicCatalogProduct.category_name,
          plan_simplified_category: publicCatalogProduct.simplified_category,
          plan_description: publicCatalogProduct.description,
          plan_specs: publicCatalogProduct.specs,
          plan_image_asset_path: publicCatalogProduct.image_asset_path,
          plan_sds_asset_path: publicCatalogProduct.sds_asset_path,
        };
      });
    },
    [mutateState],
  );

  const handleSelectProductClick = useCallback((): void => {
    beginProductComparePick({
      orderEntry: { ...state.orderEntryManager.orderEntry },
      showPreferredCatalogsOption: true,
      restrictSourceOptionsTo: null,
      initialSearchQuery: null,
      callback,
    });
  }, [beginProductComparePick, callback, state.orderEntryManager.orderEntry]);

  if (!isDef(state.orderEntryManager.orderEntry.plan_product_id)) {
    return (
      <Card.Container flush={true} interactive={true}>
        <Card.ButtonArea onClick={handleSelectProductClick}>
          <SPlanProductCardPickerComponent>
            <Typography.Caption text='Select Product' />
          </SPlanProductCardPickerComponent>
        </Card.ButtonArea>
      </Card.Container>
    );
  }

  return <OrderEntryPlanProductCard orderEntryManager={state.orderEntryManager} onClick={handleSelectProductClick} />;
}

const SPlanQuantityAndUnitPriceEditComponentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$controlGap',
});

function PlanQuantityAndUnitPriceEditComponent({
  state,
  mutateState,
}: {
  state: TOrderEntryEditDrawerPanelState;
  mutateState: TMutateOrderEntryEditDrawerPanelState;
}): JSX.Element {
  const handleQuantityChange = useCallback<TQuantityBase['onChange']>(
    (newValue) => {
      mutateState((orderEntry) => ({
        ...orderEntry,
        plan_quantity: newValue,
      }));
    },
    [mutateState],
  );

  const handleUnitPriceChange = useCallback<TCurrencyBase['onChange']>(
    (newValue) => {
      mutateState((orderEntry) => ({
        ...orderEntry,
        plan_unit_price: newValue,
      }));
    },
    [mutateState],
  );

  return (
    <Card.Container flush={true} interactive={false} variant='form'>
      <SPlanQuantityAndUnitPriceEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-entry-plan-quantity-edit'
          required={false}
          rigid={true}
          text='Quantity (Wellplaece)'
        />
        <Quantity
          id='order-entry-plan-unit-price-edit'
          value={state.orderEntryManager.orderEntry.plan_quantity ?? 0}
          onChange={handleQuantityChange}
        />
      </SPlanQuantityAndUnitPriceEditComponentDiv>
      <SPlanQuantityAndUnitPriceEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-entry-plan-unit-price-edit'
          required={false}
          rigid={true}
          text='Unit Price (Wellplaece)'
        />
        <Currency
          id='order-entry-plan-unit-price-edit'
          value={state.orderEntryManager.orderEntry.plan_unit_price ?? 0}
          onChange={handleUnitPriceChange}
        />
      </SPlanQuantityAndUnitPriceEditComponentDiv>
    </Card.Container>
  );
}

const SFlagsAndNotesEditComponentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$controlGap',
});

function FlagsAndNotesEditComponent({
  state,
  mutateState,
}: {
  state: TOrderEntryEditDrawerPanelState;
  mutateState: TMutateOrderEntryEditDrawerPanelState;
}): JSX.Element {
  const handleIsUnderReviewChange = useCallback<NonNullable<TCheckBoxBase['onCheckedChange']>>(
    (checked) => {
      mutateState((orderEntry) => ({
        ...orderEntry,
        is_under_review: checked === true,
      }));
    },
    [mutateState],
  );

  const handleIsBlockedOnPriceConfirmationChange = useCallback<NonNullable<TCheckBoxBase['onCheckedChange']>>(
    (checked) => {
      mutateState((orderEntry) => ({
        ...orderEntry,
        is_blocked_on_price_confirmation: checked === true,
      }));
    },
    [mutateState],
  );

  const handleIsBlockedOnProductAvailabilityConfirmationChange = useCallback<
    NonNullable<TCheckBoxBase['onCheckedChange']>
  >(
    (checked) => {
      mutateState((orderEntry) => ({
        ...orderEntry,
        is_blocked_on_product_availability_confirmation: checked === true,
      }));
    },
    [mutateState],
  );

  const handleNotesChange = useCallback<TTextAreaBase['onChange']>(
    (e) => {
      mutateState((orderEntry) => ({
        ...orderEntry,
        notes: e.target.value,
      }));
    },
    [mutateState],
  );

  const handleCustomerVisibleNotesChange = useCallback<TTextBoxBase['onChange']>(
    (e) => {
      mutateState((orderEntry) => ({
        ...orderEntry,
        customer_visible_notes: e.target.value,
      }));
    },
    [mutateState],
  );

  return (
    <Card.Container flush={true} interactive={false} variant='form'>
      <SFlagsAndNotesEditComponentDiv>
        <Typography.Label
          expanding={false}
          htmlFor='order-entry-customer_visible_notes-edit'
          required={false}
          rigid={true}
          text='Customer Notes'
        />
        <TextBox
          id='order-entry-customer_visible_notes-edit'
          value={state.orderEntryManager.orderEntry.customer_visible_notes ?? ''}
          onChange={handleCustomerVisibleNotesChange}
        />
      </SFlagsAndNotesEditComponentDiv>
      <SFlagsAndNotesEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-entry-notes-edit'
          required={false}
          rigid={true}
          text='Internal Notes'
        />
        <TextArea
          id='order-entry-add-notes-edit'
          value={state.orderEntryManager.orderEntry.notes ?? ''}
          onChange={handleNotesChange}
          maxLength={2048}
        />
        <Typography.Annotation
          text={`(${state.orderEntryManager.orderEntry.notes?.length ?? 0} / 2048)`}
          css={{ alignSelf: 'flex-end', paddingTop: '4px' }}
        />
      </SFlagsAndNotesEditComponentDiv>
      <SFlagsAndNotesEditComponentDiv>
        <Typography.Caption expanding={true} rigid={true} text='Flags' />
        <Labeled htmlFor='order-entry-is-under-review-edit' text='Under Review'>
          <CheckBox
            id='order-entry-is-under-review-edit'
            checked={state.orderEntryManager.orderEntry.is_under_review}
            onCheckedChange={handleIsUnderReviewChange}
          />
        </Labeled>
        <Labeled htmlFor='order-entry-is-blocked-on-price-confirmation-edit' text='Waiting on Price Confirmation'>
          <CheckBox
            id='order-entry-is-blocked-on-price-confirmation-edit'
            checked={state.orderEntryManager.orderEntry.is_blocked_on_price_confirmation}
            onCheckedChange={handleIsBlockedOnPriceConfirmationChange}
          />
        </Labeled>
        <Labeled
          htmlFor='order-entry-is-blocked-on-product-availability-confirmation-edit'
          text='Waiting on Availability Confirmation'>
          <CheckBox
            id='order-entry-is-blocked-on-product-availability-confirmation-edit'
            checked={state.orderEntryManager.orderEntry.is_blocked_on_product_availability_confirmation}
            onCheckedChange={handleIsBlockedOnProductAvailabilityConfirmationChange}
          />
        </Labeled>
      </SFlagsAndNotesEditComponentDiv>
    </Card.Container>
  );
}
