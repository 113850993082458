import type {
  AgentOrderMessagesQueryVariables,
  CustomerOrderMessagesQueryVariables,
  TCustomerOrderMessageBaseFragment,
} from '@src/gen/graphql/bindings';
import { CustomerOrderMessagesDocument, useCustomerOrderMessagesQuery } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import { useCustomerOrder } from '@src/modules/data/customer/order/CustomerOrderProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, FUTURE_DATE, mergeGetMore } from '@src/modules/graphql/utils';
import type { DocumentNode } from 'graphql/language';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TCustomerOrderMessagesContext = {
  orderMessages: TCustomerOrderMessageBaseFragment[];
  queryRef: { query: DocumentNode; variables: CustomerOrderMessagesQueryVariables };
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const { Context: CustomerOrderMessagesContext, useContext: useCustomerOrderMessages } =
  createRequiredContext<TCustomerOrderMessagesContext>();

export function CustomerOrderMessagesProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const { order } = useCustomerOrder();

  const initialCreatedAtCursor = useRef(
    order.order_messages.length > 0
      ? ensureDef(order.order_messages[order.order_messages.length - 1]).created_at
      : FUTURE_DATE.toISOString(),
  );

  const variables = useMemo<AgentOrderMessagesQueryVariables>(
    () => ({
      orderId: order.id,
      createdAtCursor: initialCreatedAtCursor.current,
      limit: DEFAULT_LIMIT,
    }),
    // @sort
    [order.id],
  );

  const { data, error, fetchMore, networkStatus } = useCustomerOrderMessagesQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          createdAtCursor:
            (data?.order_messages.length ?? 0) > 0
              ? ensureDef(data?.order_messages[data.order_messages.length - 1]).created_at
              : initialCreatedAtCursor.current,
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          order_messages: mergeGetMore(prev.order_messages, fetchMoreResult.order_messages),
        }),
      });

      return newData.order_messages.length;
    },
    // @sort
    [fetchMore, data?.order_messages],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.order_messages.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TCustomerOrderMessagesContext | undefined>(
    () => ({
      orderMessages: mergeGetMore(order.order_messages, data?.order_messages ?? []),
      queryRef: {
        query: CustomerOrderMessagesDocument,
        variables,
      },
      loaderRef,
    }),
    // @sort
    [data, loaderRef, variables, order.order_messages],
  );

  if (isDef(error)) {
    throw error;
  }

  return <CustomerOrderMessagesContext.Provider value={value}>{children}</CustomerOrderMessagesContext.Provider>;
}
