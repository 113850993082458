import { NetworkStatus } from '@apollo/client';
import type { AgentCustomersGetCustomerOrdersDashboardQueryVariables } from '@src/gen/graphql/bindings';
import { useAgentCustomersGetCustomerOrdersDashboardQuery } from '@src/gen/graphql/bindings';
import type { TCustomerOrdersDashboard } from '@src/gen/shared/data/dashboards';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useAgentCustomersOrganizationAnalyticsOrdersRouteParams } from '@src/modules/routing/agent';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TAgentCustomersOrganizationAnalyticsOrdersContext = {
  dashboard: TCustomerOrdersDashboard;
};

export const {
  Context: AgentCustomersOrganizationAnalyticsOrdersContext,
  useContext: useAgentCustomersOrganizationAnalyticsOrders,
} = createRequiredContext<TAgentCustomersOrganizationAnalyticsOrdersContext>();

export type TAgentCustomersOrganizationAnalyticsOrdersProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function AgentCustomersOrganizationAnalyticsOrdersProvider({
  LoaderComponent,
  children,
}: TAgentCustomersOrganizationAnalyticsOrdersProvider): JSX.Element {
  const routeParams = useAgentCustomersOrganizationAnalyticsOrdersRouteParams();

  const variables = useMemo<AgentCustomersGetCustomerOrdersDashboardQueryVariables>(
    () => ({
      organizationId: routeParams.organizationId,
      locationId: routeParams.config.locationId,
      startTime: routeParams.config.startTime,
      endTime: routeParams.config.endTime,
    }),
    // @sort
    [
      routeParams.config.endTime,
      routeParams.config.locationId,
      routeParams.config.startTime,
      routeParams.organizationId,
    ],
  );

  const { data, error, networkStatus } = useAgentCustomersGetCustomerOrdersDashboardQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const value = useMemo<TAgentCustomersOrganizationAnalyticsOrdersContext | undefined>(
    () =>
      isDef(data)
        ? {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            dashboard: data.getCustomerOrdersDashboard.dashboard as TCustomerOrdersDashboard,
          }
        : undefined,
    // @sort
    [data],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentCustomersOrganizationAnalyticsOrdersContext.Provider value={value}>
      {children}
    </AgentCustomersOrganizationAnalyticsOrdersContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
