import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { Button } from '@src/components/appearance/controls/Button';
import type { TCheckBoxBase } from '@src/components/appearance/controls/CheckBox';
import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import type { TQuantityBase } from '@src/components/appearance/controls/Quantity';
import { Quantity } from '@src/components/appearance/controls/Quantity';
import { Annotated, AnnotatedLoader } from '@src/components/appearance/fragments/Annotated';
import { Card } from '@src/components/appearance/fragments/Card';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item, ItemLoader } from '@src/components/appearance/fragments/Item';
import { Labeled } from '@src/components/appearance/fragments/Labeled';
import type { TCustomerPublicCatalogProductBaseFragment } from '@src/gen/graphql/bindings';
import { getSaleUnitAnnotation } from '@src/gen/shared/data/snippets';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import { EBreakpoints, useBreakpoint } from '@src/modules/design/breakpoints';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { Fragment, forwardRef, memo, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '128px',
});

const SMobileItemsDiv = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '8px',
});

export type TNextOrderProductCardBase = {
  isSubstitutionAllowed?: TCheckBoxBase | undefined;
  onClick: () => void;
  publicCatalogProduct: Pick<
    TCustomerPublicCatalogProductBaseFragment,
    'id' | 'image_asset_path' | 'is_discontinued' | 'name' | 'product_sku' | 'sale_unit' | 'secondary_name' | 'source'
  >;
  quantity?: TQuantityBase | undefined;
  selected?: boolean | undefined;
};

export type TNextOrderProductCard = TProps<false, TNextOrderProductCardBase, 'div'>;
export const NEXT_ORDER_PRODUCT_CARD_CLASS_NAME = 'wp-next-order-product-card';

export const NextOrderProductCard = withCssToString(
  NEXT_ORDER_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNextOrderProductCard>(
      (
        { isSubstitutionAllowed, onClick, quantity, publicCatalogProduct, selected, className, ...rest },
        ref,
      ): JSX.Element => {
        const breakpoint = useBreakpoint();
        const isMobile = useMemo(() => breakpoint === EBreakpoints.PHONE, [breakpoint]);

        const joinedClassName = useMemo(
          () => joinClassNames(className, NEXT_ORDER_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const catalogItem = useMemo<TItemBaseItem>(
          () => ({ caption: 'Catalog', text: maybeGetSourceName(publicCatalogProduct.source) }),
          [publicCatalogProduct.source],
        );

        const skuItem = useMemo<TItemBaseItem>(
          () => ({ caption: 'SKU', text: publicCatalogProduct.product_sku }),
          [publicCatalogProduct.product_sku],
        );

        const allowSubstitutionsId = useMemo(
          () => `${NEXT_ORDER_PRODUCT_CARD_CLASS_NAME}-allow-substitutions-${publicCatalogProduct.id}`,
          [publicCatalogProduct.id],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            interactive={true}
            flush={true}
            selected={selected === true}
            ref={ref}>
            <Card.ButtonArea onClick={onClick}>
              {!isMobile && (
                <>
                  <SImageAsset alt={publicCatalogProduct.name} imageAssetPath={publicCatalogProduct.image_asset_path} />
                  <Card.Separator />
                </>
              )}
              <Card.Title subTitle={publicCatalogProduct.secondary_name} title={publicCatalogProduct.name} />
              {isMobile ? (
                <SMobileItemsDiv>
                  <Item item={catalogItem} rigid={true} />
                  <Item item={skuItem} rigid={true} />
                </SMobileItemsDiv>
              ) : (
                <Fragment>
                  <Item item={catalogItem} rigid={true} />
                  <Item item={skuItem} rigid={true} />
                </Fragment>
              )}
            </Card.ButtonArea>
            {publicCatalogProduct.is_discontinued && (
              <Card.ContentArea>
                <Card.Separator />
                <Typography.Annotation css={{ alignSelf: 'center' }} text='This product is no longer available.' />
              </Card.ContentArea>
            )}
            {!publicCatalogProduct.is_discontinued && isDef(quantity) && (
              <Card.ContentArea>
                <Card.Separator />
                {quantity.value > 0 || isDef(isSubstitutionAllowed) ? (
                  <Fragment>
                    <Annotated text={getSaleUnitAnnotation(publicCatalogProduct.sale_unit)}>
                      <Quantity {...quantity} />
                    </Annotated>
                    {isDef(isSubstitutionAllowed) && (
                      <Labeled htmlFor={allowSubstitutionsId} text='Allow Substitutions'>
                        <CheckBox id={allowSubstitutionsId} {...isSubstitutionAllowed} />
                      </Labeled>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Button onClick={(): void => quantity.onChange(1)} text='Add to Cart' />
                    <Typography.Annotation
                      css={{ alignSelf: 'center' }}
                      expanding={true}
                      rigid={true}
                      text={getSaleUnitAnnotation(publicCatalogProduct.sale_unit)}
                    />
                  </Fragment>
                )}
              </Card.ContentArea>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);

const SLoaderTopDiv = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const NextOrderProductCardLoader = memo(
  forwardRef<HTMLDivElement, { hasQuantity: boolean }>(({ hasQuantity }, ref): JSX.Element => {
    const breakpoint = useBreakpoint();
    const isMobile = useMemo(() => breakpoint === EBreakpoints.PHONE, [breakpoint]);

    return (
      <Card.Container ref={ref}>
        {!isMobile && (
          <Fragment>
            <SLoaderTopDiv>
              <Illustrations.Loader height={128} uniqueKey='wp-next-order-product-card-loader-image' width={192}>
                <rect x='0' y='5' rx='0' ry='0' width='192' height='128' />
              </Illustrations.Loader>
            </SLoaderTopDiv>
            <Card.Separator />
          </Fragment>
        )}
        <Card.TitleLoader />
        <ItemLoader />
        <ItemLoader />
        {hasQuantity && (
          <Fragment>
            <Card.Separator />
            <AnnotatedLoader>
              <Quantity disabled={true} value={0} onChange={noop} />
            </AnnotatedLoader>
          </Fragment>
        )}
      </Card.Container>
    );
  }),
);
