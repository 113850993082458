import { Description } from '@src/components/appearance/fragments/Description';
import { Dialog } from '@src/components/appearance/structure/Dialog';
import { EDescriptionBlockType } from '@src/gen/shared/data/description';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TSmallOrderDialogPanelBase = {
  doOrderPlace: () => void;
};

export type TSmallOrderDialogPanel = TProps<false, TSmallOrderDialogPanelBase, 'div'>;
export const SMALL_ORDER_DIALOG_PANEL_CLASS_NAME = 'wp-small-order-dialog-panel';

export const SmallOrderDialogPanel = withCssToString(
  SMALL_ORDER_DIALOG_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TSmallOrderDialogPanel>(({ doOrderPlace, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, SMALL_ORDER_DIALOG_PANEL_CLASS_NAME),
        [className],
      );

      return (
        <Dialog.Panel {...rest} className={joinedClassName} ref={ref}>
          <Dialog.Header title='About Small Orders' />
          <Description
            description={[
              {
                type: EDescriptionBlockType.PARAGRAPH,
                paragraph:
                  'Small orders contain five or less unique items. While our price optimization technology is always at work for you, it can yield even better results with larger orders by leveraging special offers and reducing shipping costs.',
              },
              {
                type: EDescriptionBlockType.TITLE,
                title: 'What to Expect',
              },
              {
                type: EDescriptionBlockType.FEATURES,
                features: ['The same processing timeline as larger orders.', 'Less optimized savings.'],
              },
              {
                type: EDescriptionBlockType.TITLE,
                title: ' Are you sure you need a small order?',
              },
              {
                type: EDescriptionBlockType.PARAGRAPH,
                paragraph:
                  'If not, we recommend going back and placing a larger order later. Your cart is permanently saved, so you can keep adding products over time.',
              },
            ]}
          />
          <Dialog.Footer buttonAction={{ isAsync: false, text: 'Place Small Order', onClick: doOrderPlace }} />
        </Dialog.Panel>
      );
    }),
  ),
);
