import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import type { TSpecs } from '@src/gen/shared/data/specs';
import { isNotEmptyString } from '@src/gen/shared/utils/types';

export function joinClassNames(existingClassName: string | undefined, newClassName: string | undefined): string {
  return [existingClassName, newClassName]
    .filter((className) => isNotEmptyString(className))
    .map((className) => className?.trim())
    .join(' ');
}

export function getSpecsItems(specs: TSpecs): TItemBaseItem[] {
  return Object.entries(specs)
    .map(([caption, text]) => ({
      caption,
      text,
    }))
    .sort((a, b) => a.caption.localeCompare(b.caption));
}
