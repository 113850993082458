import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderEntryBaseProductCard } from '@src/components/mixins/cards/OrderEntryBaseProductCard';
import { OrderEntryFlagsAndNotesCard } from '@src/components/mixins/cards/OrderEntryFlagsAndNotesCard';
import { OrderEntryPlanProductCard } from '@src/components/mixins/cards/OrderEntryPlanProductCard';
import type { TAgentOrderEntryCompleteFragment } from '@src/gen/graphql/bindings';
import type { AgentOrderEntryManager } from '@src/gen/shared/data/agentOrders';
import { getOrderEntryOrigin, maybeGetOrderEntryOriginLabel } from '@src/gen/shared/enums/orderEntryOrigin';
import { maybeGetOrderEntryPlanStatusLabel } from '@src/gen/shared/enums/orderEntryPlanStatus';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { maybeOpenUrls, withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SOuterDiv = styled('div', {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  gap: '$cardGap',
});

const SInnerDiv = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$gray1',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$structureGroupGapDesktop',
  padding: '$cardPadding',
});

const SActionsDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$cardGap',
});

export type TOrderEntryStructureGroupBase = {
  beginOrderEntryBaseProductView: TAgentOrderActionsTypes['BeginOrderEntryBaseProductView'];
  beginOrderEntryEdit: TAgentOrderActionsTypes['BeginOrderEntryEdit'];
  beginOrderEntryPlanProductView: TAgentOrderActionsTypes['BeginOrderEntryPlanProductView'];
  orderEntry: TAgentOrderEntryCompleteFragment;
  orderEntryManager: AgentOrderEntryManager;
  isEditable: boolean;
};

export type TOrderEntryStructureGroup = TProps<false, TOrderEntryStructureGroupBase, 'div'>;
export const ORDER_ENTRY_STRUCTURE_GROUP = 'wp-order-entry-structure-group';

export const OrderEntryStructureGroup = withCssToString(
  ORDER_ENTRY_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderEntryStructureGroup>(
      (
        {
          beginOrderEntryBaseProductView,
          beginOrderEntryEdit,
          beginOrderEntryPlanProductView,
          orderEntry,
          orderEntryManager,
          isEditable,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_ENTRY_STRUCTURE_GROUP), [className]);

        const iconButtonAction = useMemo<TIconButtonAction | undefined>(
          () =>
            isDef(beginOrderEntryEdit)
              ? {
                  isAsync: false,
                  onClick: () => beginOrderEntryEdit({ orderEntryId: orderEntryManager.orderEntry.id }),
                  icon: 'edit',
                  variant: isEditable ? 'default' : 'disabled',
                }
              : undefined,
          [isEditable, beginOrderEntryEdit, orderEntryManager.orderEntry.id],
        );

        const productPageUrls = useMemo<string[]>(
          () =>
            [orderEntryManager.orderEntry.product_page_url, orderEntryManager.orderEntry.plan_product_page_url].filter(
              (url): url is string => isDef(url),
            ),
          // @sort
          [orderEntryManager.orderEntry.plan_product_page_url, orderEntryManager.orderEntry.product_page_url],
        );

        return (
          <SOuterDiv>
            <SInnerDiv>
              <Structure.Group
                {...rest}
                className={joinedClassName}
                ref={ref}
                title='Base'
                tag={maybeGetOrderEntryOriginLabel(getOrderEntryOrigin(orderEntryManager.orderEntry.origin))}
                variant='secondary'>
                <OrderEntryBaseProductCard
                  orderEntryManager={orderEntryManager}
                  onClick={(): void =>
                    beginOrderEntryBaseProductView({ orderEntryId: orderEntryManager.orderEntry.id })
                  }
                />
              </Structure.Group>
              <Structure.Group
                title='Plan'
                tag={maybeGetOrderEntryPlanStatusLabel(orderEntryManager.orderEntry.plan_status)}
                variant='secondary'
                icon={orderEntryManager.isPlanned() ? undefined : 'warning'}>
                {orderEntryManager.isPurchasedByWellplaece() && (
                  <OrderEntryPlanProductCard
                    orderEntryManager={orderEntryManager}
                    onClick={(): void =>
                      beginOrderEntryPlanProductView({
                        orderEntryId: orderEntryManager.orderEntry.id,
                      })
                    }
                  />
                )}
              </Structure.Group>
              <Structure.Group title='Notes and Flags' variant='secondary'>
                <OrderEntryFlagsAndNotesCard orderEntry={orderEntry} />
              </Structure.Group>
            </SInnerDiv>
            {isDef(iconButtonAction) && (
              <SActionsDiv>
                <ActionIconButton action={iconButtonAction} />
                <ActionIconButton
                  action={{
                    annotation: productPageUrls.length > 1 ? `${productPageUrls.length}` : undefined,
                    icon: 'link',
                    isAsync: false,
                    onClick: () => maybeOpenUrls(productPageUrls),
                  }}
                />
              </SActionsDiv>
            )}
          </SOuterDiv>
        );
      },
    ),
  ),
);
