import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum ESource {
  TEST = 'test',
  AFTER_ACTION_MEDICAL = 'after_action_medical',
  AMTOUCH_DENTAL_SUPPLY = 'amtouch_dental_supply',
  BENCO_DENTAL = 'benco_dental',
  BIO_HORIZONS = 'bio_horizons',
  BISCO = 'bisco',
  BRASSELER_USA = 'brasseler_usa',
  BURKHART_DENTAL_SUPPLY = 'burkhart_dental_supply',
  COLGATE = 'colgate',
  COSMEDENT = 'cosmedent',
  DARBY_DENTAL = 'darby_dental',
  DC_DENTAL = 'dc_dental',
  DDS_DENTAL_SUPPLIES = 'dds_dental_supplies',
  DENOVO_DENTAL = 'denovo_dental',
  DENTAL_CITY = 'dental_city',
  DENTAL_HEALTH_PRODUCTS = 'dental_health_products',
  DENTATUS = 'dentatus',
  DENTSPLY_SIRONA = 'dentsply_sirona',
  DYNA_FLEX = 'dyna_flex',
  EDGE_ENDO = 'edge_endo',
  FRONTIER_DENTAL = 'frontier_dental',
  GC_ORTHODONTICS = 'gc_orthodontics',
  HENRY_SCHEIN = 'henry_schein',
  IMPLANT_DIRECT = 'implant_direct',
  ITERO = 'itero',
  KETTENBACH_DENTAL = 'kettenbach_dental',
  MADRID_DENTAL_SUPPLY = 'madrid_dental_supply',
  MIDWEST_DENTAL = 'midwest_dental',
  MVP_DENTAL_SUPPLY = 'mvp_dental_supply',
  NET32 = 'net32',
  NOBLE_DENTAL_SUPPLIES = 'noble_dental_supplies',
  NU_SMILE = 'nu_smile',
  OSE = 'ose',
  PATTERSON_DENTAL = 'patterson_dental',
  PEARSON_DENTAL = 'pearson_dental',
  PRACTICON = 'practicon',
  PURE_LIFE = 'pure_life',
  RELIANCE_ORTHODONTICS = 'reliance_orthodontics',
  SAFCO_DENTAL_SUPPLY = 'safco_dental_supply',
  SCOTTS_DENTAL_SUPPLY = 'scotts_dental_supply',
  SKY_DENTAL_SUPPLY = 'sky_dental_supply',
  SUPPLY_DOC = 'supply_doc',
  TDSC = 'tdsc',
  TOP_QUALITY_GLOVES = 'top_quality_gloves',
  ULTRADENT = 'ultradent',
  VISTA_APEX = 'vista_apex',
  YOUNG_SPECIALTIES = 'young_specialties',
  ZIRC_DENTAL_PRODUCTS = 'zirc_dental_products',
}

const SOURCE_LOOKUP = mapValues(invert(ESource), () => true);

export function getSource(rawSource: string): ESource {
  assert(SOURCE_LOOKUP[rawSource], `Unknown source: "${rawSource}".`);
  return rawSource as ESource; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetSource(rawSource: string): ESource | undefined {
  try {
    return getSource(rawSource);
  } catch {
    return undefined;
  }
}

const SOURCE_NAMES: { [key in ESource]: string } = {
  [ESource.TEST]: 'Test',
  [ESource.AFTER_ACTION_MEDICAL]: 'After Action Medical',
  [ESource.AMTOUCH_DENTAL_SUPPLY]: 'Amtouch Dental Supply',
  [ESource.BENCO_DENTAL]: 'Benco Dental',
  [ESource.BIO_HORIZONS]: 'BioHorizons',
  [ESource.BISCO]: 'Bisco',
  [ESource.BRASSELER_USA]: 'Brasseler USA',
  [ESource.BURKHART_DENTAL_SUPPLY]: 'Burkhart Dental Supply',
  [ESource.COLGATE]: 'Colgate',
  [ESource.COSMEDENT]: 'Cosmedent',
  [ESource.DARBY_DENTAL]: 'Darby Dental',
  [ESource.DC_DENTAL]: 'DC Dental',
  [ESource.DDS_DENTAL_SUPPLIES]: 'DDS Dental Supplies',
  [ESource.DENOVO_DENTAL]: 'Denovo Dental',
  [ESource.DENTAL_CITY]: 'Dental City',
  [ESource.DENTAL_HEALTH_PRODUCTS]: 'Dental Health Products',
  [ESource.DENTATUS]: 'Dentatus',
  [ESource.DENTSPLY_SIRONA]: 'Dentsply Sirona',
  [ESource.DYNA_FLEX]: 'DynaFlex',
  [ESource.EDGE_ENDO]: 'EdgeEndo',
  [ESource.FRONTIER_DENTAL]: 'Frontier Dental',
  [ESource.GC_ORTHODONTICS]: 'GC Orthodontics',
  [ESource.HENRY_SCHEIN]: 'Henry Schein',
  [ESource.IMPLANT_DIRECT]: 'Implant Direct',
  [ESource.ITERO]: 'iTero',
  [ESource.KETTENBACH_DENTAL]: 'Kettenbach Dental',
  [ESource.MADRID_DENTAL_SUPPLY]: 'Madrid Dental Supply',
  [ESource.MIDWEST_DENTAL]: 'Midwest Dental',
  [ESource.MVP_DENTAL_SUPPLY]: 'MVP Dental Supply',
  [ESource.NET32]: 'Net32',
  [ESource.NOBLE_DENTAL_SUPPLIES]: 'Noble Dental Supplies',
  [ESource.NU_SMILE]: 'NuSmile',
  [ESource.OSE]: 'OSE - Orthodontic Supply & Equipment',
  [ESource.PATTERSON_DENTAL]: 'Patterson Dental',
  [ESource.PEARSON_DENTAL]: 'Pearson Dental',
  [ESource.PRACTICON]: 'Practicon',
  [ESource.PURE_LIFE]: 'PureLife',
  [ESource.RELIANCE_ORTHODONTICS]: 'Reliance Orthodontics',
  [ESource.SAFCO_DENTAL_SUPPLY]: 'Safco Dental Supply',
  [ESource.SCOTTS_DENTAL_SUPPLY]: 'Scott\u0027s Dental Supply',
  [ESource.SKY_DENTAL_SUPPLY]: 'Sky Dental Supply',
  [ESource.SUPPLY_DOC]: 'Supply Doc',
  [ESource.TDSC]: 'TDSC',
  [ESource.TOP_QUALITY_GLOVES]: 'Top Quality Gloves',
  [ESource.ULTRADENT]: 'Ultradent',
  [ESource.VISTA_APEX]: 'Vista Apex',
  [ESource.YOUNG_SPECIALTIES]: 'Young Specialties',
  [ESource.ZIRC_DENTAL_PRODUCTS]: 'Zirc Dental Products',
};

export function getSourceName(rawSource: string): string {
  return ensureDef(SOURCE_NAMES[getSource(rawSource)]);
}

export function maybeGetSourceName(rawSource: string): string {
  const source = maybeGetSource(rawSource);
  return source !== undefined ? ensureDef(SOURCE_NAMES[source]) : rawSource;
}

const SOURCE_CODES: { [key in ESource]: string } = {
  [ESource.TEST]: 'TST',
  [ESource.AFTER_ACTION_MEDICAL]: 'AAM',
  [ESource.AMTOUCH_DENTAL_SUPPLY]: 'ADS',
  [ESource.BENCO_DENTAL]: 'BCD',
  [ESource.BIO_HORIZONS]: 'BHR',
  [ESource.BISCO]: 'BSC',
  [ESource.BRASSELER_USA]: 'BRS',
  [ESource.BURKHART_DENTAL_SUPPLY]: 'BDS',
  [ESource.COLGATE]: 'CLG',
  [ESource.COSMEDENT]: 'CSM',
  [ESource.DARBY_DENTAL]: 'DYD',
  [ESource.DC_DENTAL]: 'DCD',
  [ESource.DDS_DENTAL_SUPPLIES]: 'DDS',
  [ESource.DENOVO_DENTAL]: 'DNV',
  [ESource.DENTAL_CITY]: 'DNC',
  [ESource.DENTAL_HEALTH_PRODUCTS]: 'DHP',
  [ESource.DENTATUS]: 'DTS',
  [ESource.DENTSPLY_SIRONA]: 'DSR',
  [ESource.DYNA_FLEX]: 'DYF',
  [ESource.EDGE_ENDO]: 'EDG',
  [ESource.FRONTIER_DENTAL]: 'FTD',
  [ESource.GC_ORTHODONTICS]: 'GCO',
  [ESource.HENRY_SCHEIN]: 'HSC',
  [ESource.IMPLANT_DIRECT]: 'IMD',
  [ESource.ITERO]: 'ITR',
  [ESource.KETTENBACH_DENTAL]: 'KTB',
  [ESource.MADRID_DENTAL_SUPPLY]: 'MDR',
  [ESource.MIDWEST_DENTAL]: 'MWD',
  [ESource.MVP_DENTAL_SUPPLY]: 'MDS',
  [ESource.NET32]: 'N32',
  [ESource.NOBLE_DENTAL_SUPPLIES]: 'NDS',
  [ESource.NU_SMILE]: 'NSM',
  [ESource.OSE]: 'OSE',
  [ESource.PATTERSON_DENTAL]: 'PTD',
  [ESource.PEARSON_DENTAL]: 'PED',
  [ESource.PRACTICON]: 'PRT',
  [ESource.PURE_LIFE]: 'PLF',
  [ESource.RELIANCE_ORTHODONTICS]: 'ROD',
  [ESource.SAFCO_DENTAL_SUPPLY]: 'SFC',
  [ESource.SCOTTS_DENTAL_SUPPLY]: 'SCD',
  [ESource.SKY_DENTAL_SUPPLY]: 'SDS',
  [ESource.SUPPLY_DOC]: 'SDC',
  [ESource.TDSC]: 'TDS',
  [ESource.TOP_QUALITY_GLOVES]: 'TQG',
  [ESource.ULTRADENT]: 'UDT',
  [ESource.VISTA_APEX]: 'VTX',
  [ESource.YOUNG_SPECIALTIES]: 'YSP',
  [ESource.ZIRC_DENTAL_PRODUCTS]: 'ZRC',
};

export function getSourceCode(rawSource: string): string {
  return ensureDef(SOURCE_CODES[getSource(rawSource)]);
}

export function maybeGetSourceCode(rawSource: string): string {
  const source = maybeGetSource(rawSource);
  return source !== undefined ? ensureDef(SOURCE_CODES[source]) : rawSource;
}

const SOURCE_CODES_LOOKUP = invert(SOURCE_CODES);

export function getSourceByCode(code: string): ESource {
  const maybeRawSource = SOURCE_CODES_LOOKUP[code.toUpperCase()];
  assert(maybeRawSource !== undefined, `Unknown source code: "${code}".`);
  return getSource(maybeRawSource);
}

export const DEFAULT_SOURCE_PARALLELISMS: { [key in ESource]: number } = {
  [ESource.TEST]: 10,
  [ESource.AFTER_ACTION_MEDICAL]: 10,
  [ESource.AMTOUCH_DENTAL_SUPPLY]: 10,
  [ESource.BENCO_DENTAL]: 15,
  [ESource.BIO_HORIZONS]: 10,
  [ESource.BISCO]: 10,
  [ESource.BRASSELER_USA]: 10,
  [ESource.BURKHART_DENTAL_SUPPLY]: 10,
  [ESource.COLGATE]: 10,
  [ESource.COSMEDENT]: 10,
  [ESource.DARBY_DENTAL]: 10,
  [ESource.DC_DENTAL]: 10,
  [ESource.DDS_DENTAL_SUPPLIES]: 10,
  [ESource.DENOVO_DENTAL]: 10,
  [ESource.DENTAL_CITY]: 10,
  [ESource.DENTAL_HEALTH_PRODUCTS]: 10,
  [ESource.DENTATUS]: 10,
  [ESource.DENTSPLY_SIRONA]: 10,
  [ESource.DYNA_FLEX]: 10,
  [ESource.EDGE_ENDO]: 10,
  [ESource.FRONTIER_DENTAL]: 10,
  [ESource.GC_ORTHODONTICS]: 10,
  [ESource.HENRY_SCHEIN]: 10,
  [ESource.IMPLANT_DIRECT]: 10,
  [ESource.ITERO]: 10,
  [ESource.KETTENBACH_DENTAL]: 10,
  [ESource.MADRID_DENTAL_SUPPLY]: 10,
  [ESource.MIDWEST_DENTAL]: 2,
  [ESource.MVP_DENTAL_SUPPLY]: 10,
  [ESource.NET32]: 10,
  [ESource.NOBLE_DENTAL_SUPPLIES]: 10,
  [ESource.NU_SMILE]: 10,
  [ESource.OSE]: 10,
  [ESource.PATTERSON_DENTAL]: 10,
  [ESource.PEARSON_DENTAL]: 10,
  [ESource.PRACTICON]: 10,
  [ESource.PURE_LIFE]: 10,
  [ESource.RELIANCE_ORTHODONTICS]: 10,
  [ESource.SAFCO_DENTAL_SUPPLY]: 10,
  [ESource.SCOTTS_DENTAL_SUPPLY]: 10,
  [ESource.SKY_DENTAL_SUPPLY]: 10,
  [ESource.SUPPLY_DOC]: 1,
  [ESource.TDSC]: 10,
  [ESource.TOP_QUALITY_GLOVES]: 10,
  [ESource.ULTRADENT]: 10,
  [ESource.VISTA_APEX]: 10,
  [ESource.YOUNG_SPECIALTIES]: 10,
  [ESource.ZIRC_DENTAL_PRODUCTS]: 10,
};

export function getDefaultSourceParallelism(source: ESource): number {
  return DEFAULT_SOURCE_PARALLELISMS[source];
}

export const PRIVATE_SOURCE_PARALLELISMS: { [key in ESource]: number } = {
  [ESource.TEST]: 1,
  [ESource.AFTER_ACTION_MEDICAL]: 1,
  [ESource.AMTOUCH_DENTAL_SUPPLY]: 1,
  [ESource.BENCO_DENTAL]: 1,
  [ESource.BIO_HORIZONS]: 1,
  [ESource.BISCO]: 1,
  [ESource.BRASSELER_USA]: 1,
  [ESource.BURKHART_DENTAL_SUPPLY]: 1,
  [ESource.COLGATE]: 1,
  [ESource.COSMEDENT]: 1,
  [ESource.DARBY_DENTAL]: 1,
  [ESource.DC_DENTAL]: 1,
  [ESource.DDS_DENTAL_SUPPLIES]: 1,
  [ESource.DENOVO_DENTAL]: 1,
  [ESource.DENTAL_CITY]: 1,
  [ESource.DENTAL_HEALTH_PRODUCTS]: 1,
  [ESource.DENTATUS]: 1,
  [ESource.DENTSPLY_SIRONA]: 1,
  [ESource.DYNA_FLEX]: 1,
  [ESource.EDGE_ENDO]: 1,
  [ESource.FRONTIER_DENTAL]: 1,
  [ESource.GC_ORTHODONTICS]: 1,
  [ESource.HENRY_SCHEIN]: 1,
  [ESource.IMPLANT_DIRECT]: 1,
  [ESource.ITERO]: 1,
  [ESource.KETTENBACH_DENTAL]: 1,
  [ESource.MADRID_DENTAL_SUPPLY]: 1,
  [ESource.MIDWEST_DENTAL]: 1,
  [ESource.MVP_DENTAL_SUPPLY]: 1,
  [ESource.NET32]: 1,
  [ESource.NOBLE_DENTAL_SUPPLIES]: 1,
  [ESource.NU_SMILE]: 1,
  [ESource.OSE]: 1,
  [ESource.PATTERSON_DENTAL]: 1,
  [ESource.PEARSON_DENTAL]: 1,
  [ESource.PRACTICON]: 1,
  [ESource.PURE_LIFE]: 1,
  [ESource.RELIANCE_ORTHODONTICS]: 1,
  [ESource.SAFCO_DENTAL_SUPPLY]: 1,
  [ESource.SCOTTS_DENTAL_SUPPLY]: 1,
  [ESource.SKY_DENTAL_SUPPLY]: 1,
  [ESource.SUPPLY_DOC]: 1,
  [ESource.TDSC]: 1,
  [ESource.TOP_QUALITY_GLOVES]: 1,
  [ESource.ULTRADENT]: 1,
  [ESource.VISTA_APEX]: 1,
  [ESource.YOUNG_SPECIALTIES]: 1,
  [ESource.ZIRC_DENTAL_PRODUCTS]: 1,
};

export function getPrivateSourceParallelism(source: ESource): number {
  return PRIVATE_SOURCE_PARALLELISMS[source];
}

export const PREFERRED_SOURCES: { [key in ESource]?: true } = {
  [ESource.DC_DENTAL]: true,
  [ESource.DENTAL_CITY]: true,
  [ESource.FRONTIER_DENTAL]: true,
  [ESource.KETTENBACH_DENTAL]: true,
  [ESource.MVP_DENTAL_SUPPLY]: true,
  [ESource.NET32]: true,
  [ESource.SKY_DENTAL_SUPPLY]: true,
  [ESource.TOP_QUALITY_GLOVES]: true,
};

export const SOURCE_TAX_ESTIMATES: { [key in ESource]?: number } = {
  [ESource.DC_DENTAL]: 800,
  [ESource.DENTAL_CITY]: 800,
  [ESource.SKY_DENTAL_SUPPLY]: 800,
};
