import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import type { TAgentOrderBaseFragment, TCustomerOrderBaseFragment } from '@src/gen/graphql/bindings';
import { getOrderReference } from '@src/gen/shared/data/snippets';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderPlacedCardBase = {
  order: Pick<
    TAgentOrderBaseFragment | TCustomerOrderBaseFragment,
    'created_at' | 'creator' | 'is_emergency' | 'location' | 'reference_number' | 'status'
  >;
  onClick: () => void;
};

export type TOrderPlacedCard = TProps<false, TOrderPlacedCardBase, 'div'>;
export const ORDER_PLACED_CARD_CLASS_NAME = 'wp-order-placed-card';

export const OrderPlacedCard = withCssToString(
  ORDER_PLACED_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderPlacedCard>(({ order, onClick, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, ORDER_PLACED_CARD_CLASS_NAME), [className]);

      const orderRef = useMemo(
        () => getOrderReference(order.location.order_code, order.reference_number),
        // @sort
        [order.location.order_code, order.reference_number],
      );

      const items = useMemo<TItemsBase['items']>(
        () => [
          {
            caption: 'Order Ref.',
            text: orderRef,
          },
          {
            caption: 'Placed By',
            text: `${order.creator.first_name} ${order.creator.last_name}`,
          },
          {
            caption: 'Placed On',
            text: { asDate: 'on', at: order.created_at },
          },
        ],
        // @sort
        [orderRef, order.created_at, order.creator.first_name, order.creator.last_name],
      );

      return (
        <Card.Container {...rest} className={joinedClassName} flush={true} interactive={true} ref={ref}>
          <Card.ButtonArea onClick={onClick}>
            <Card.Title title='Original Cart' />
            <Items items={items} />
          </Card.ButtonArea>
        </Card.Container>
      );
    }),
  ),
);
