import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  ArrowLeftOnRectangleIcon,
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  ArrowRightOnRectangleIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUpOnSquareStackIcon,
  Bars3Icon,
  BeakerIcon,
  CheckIcon,
  ClockIcon,
  CloudArrowUpIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  FunnelIcon,
  GiftIcon,
  InboxArrowDownIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  MinusCircleIcon,
  MinusSmallIcon,
  NoSymbolIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  PlusSmallIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  ShoppingCartIcon,
  TrashIcon,
  ViewfinderCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { THEME_COLORS } from '@src/gen/shared/utils/design';
import { BREAKPOINT_DESKTOP, BREAKPOINT_LARGE_DESKTOP, BREAKPOINT_TABLET } from '@src/modules/design/breakpoints';
import type { CSS, ScaleValue } from '@stitches/react';
import { createStitches } from '@stitches/react';
import type {
  ComponentProps,
  ForwardRefExoticComponent,
  PropsWithChildren,
  PropsWithoutRef,
  RefAttributes,
  SVGProps,
} from 'react';

export const { config, globalCss, styled, theme, keyframes } = createStitches({
  theme: {
    borderStyles: {
      disabled: 'dashed',
      regular: 'solid',
    },
    borderWidths: {
      regular: '1px',
      secondaryNavTab: '2px',
    },
    colors: {
      black: THEME_COLORS.Black,
      white: THEME_COLORS.White,
      gray1: THEME_COLORS.Gray1,
      gray2: THEME_COLORS.Gray2,
      gray3: THEME_COLORS.Gray3,
      gray4: THEME_COLORS.Gray4,
      gray5: THEME_COLORS.Gray5,
      gray6: THEME_COLORS.Gray6,
      green2: THEME_COLORS.Green2,
      yellow1: THEME_COLORS.Yellow1,
      yellow2: THEME_COLORS.Yellow2,
      red1: THEME_COLORS.Red1,
      red2: THEME_COLORS.Red2,
      brand: THEME_COLORS.Brand,
      primary: THEME_COLORS.Primary,

      avatarBackground: '$gray3',
      bannerDefaultBackground: '$gray1',
      bannerErrorBackground: '$red2',
      bannerSuccessBackground: '$green2',
      bannerWarningBackground: '$yellow2',
      buttonDefaultActiveBackground: '$gray5',
      buttonDefaultBackground: '$primary',
      buttonDefaultHoverBackground: '$gray4',
      buttonDefaultText: '$white',
      buttonDisabledBackground: '$gray3',
      buttonDisabledText: '$white',
      buttonInvertedBackground: '$gray6',
      buttonInvertedHoverBackground: '$gray4',
      buttonInvertedText: '$white',
      buttonLoadingBackground: '$gray3',
      buttonLoadingText: '$gray3',
      buttonSecondaryActiveBorder: '$gray5',
      buttonSecondaryActiveText: '$gray5',
      buttonSecondaryBackground: '$white',
      buttonSecondaryBorder: '$gray3',
      buttonSecondaryHoverBorder: '$gray4',
      buttonSecondaryText: '$gray4',
      cardDefaultBackground: '$white',
      cardDefaultBorder: '$gray3',
      cardFormBackground: '$gray1',
      cardHoverBorder: '$gray4',
      chatBubbleAgentBackground: '$gray1',
      chatBubbleCustomerBackground: '$white',
      chatBubbleCustomerBorder: '$gray3',
      checkboxBackground: '$white',
      checkboxBorder: '$gray3',
      checkboxDisabledBorder: '$gray3',
      checkboxFocusBorder: '$black',
      checkboxHoverBorder: '$gray4',
      chromeContentBackground: '$white',
      chromeFooterBackground: '$gray1',
      chromeOverlayBackground: `${THEME_COLORS.Black}80`,
      controlActiveBorder: '$black',
      controlBackground: '$white',
      controlBorder: '$gray3',
      controlButtonDefaultActiveBorder: '$gray5',
      controlButtonDefaultActiveText: '$gray5',
      controlButtonDefaultBackground: '$white',
      controlButtonDefaultBorder: '$gray3',
      controlButtonDefaultFocusBorder: '$gray5',
      controlButtonDefaultHoverBorder: '$gray4',
      controlButtonDefaultText: '$black',
      controlButtonDisabledBackground: '$white',
      controlButtonDisabledText: '$gray3',
      controlButtonLoadingBackground: '$white',
      controlButtonLoadingBorder: '$gray3',
      controlButtonLoadingText: '$black',
      controlButtonSuccessBackground: '$green2',
      controlDisabledBorder: '$gray3',
      controlDisabledText: '$gray3',
      controlHoverBorder: '$gray4',
      controlPlaceholderText: '$gray4',
      controlPopupActiveBackground: '$gray1',
      controlText: '$black',
      coverBackground: '$gray4',
      coverContentImageBackground: `${THEME_COLORS.Gray4}5A`,
      coverContentPlainBackground: '$gray1',
      dialogContentBackground: '$white',
      dialogOverlayBackground: `${THEME_COLORS.Black}80`,
      dismissableTagDefaultActiveBackground: '$gray5',
      dismissableTagDefaultBackground: '$primary',
      dismissableTagDefaultHoverBackground: '$gray4',
      dismissableTagDisabledBackground: '$gray3',
      dismissableTagText: '$white',
      drawerContentBackground: '$white',
      drawerFooterBackground: '$gray1',
      drawerOverlayBackground: `${THEME_COLORS.Black}80`,
      flapDefaultBackground: '$white',
      flapDefaultBorder: '$gray3',
      flapFormBackground: '$gray2',
      flapHoverBorder: '$gray4',
      flapText: '$gray4',
      iconButtonDefaultActiveBorder: '$gray5',
      iconButtonDefaultActiveText: '$gray5',
      iconButtonDefaultBackground: '$white',
      iconButtonDefaultBorder: '$gray3',
      iconButtonDefaultHoverBorder: '$gray4',
      iconButtonDefaultText: '$gray4',
      iconButtonDisabledBackground: '$white',
      iconButtonDisabledBorder: '$gray3',
      iconButtonDisabledText: '$gray3',
      iconButtonInvertedActiveBackground: '$gray4',
      iconButtonInvertedActiveText: '$gray2',
      iconButtonInvertedBackground: '$gray6',
      iconButtonInvertedHoverBackground: '$gray4',
      iconButtonInvertedText: '$white',
      iconButtonLoadingBackground: '$white',
      iconButtonLoadingBorder: '$gray3',
      iconButtonLoadingText: '$gray4',
      illustrationsSpinnerFill: '$gray4',
      itemButtonHoverBackground: '$gray1',
      itemsBannerBackground: '$gray1',
      primaryNavBackground: '$gray6',
      primaryNavItemActiveBackground: '$gray4',
      primaryNavItemHoverBackground: '$gray4',
      primaryNavSeparatorBackground: '$gray4',
      primaryNavText: '$white',
      secondaryNavTabActiveBorder: '$primary',
      secondaryNavTabActiveText: '$black',
      secondaryNavTabHoverText: '$black',
      secondaryNavTabText: '$gray4',
      selectBackground: '$white',
      selectBorder: '$gray3',
      selectDisabledBorder: '$gray3',
      selectDisabledText: '$gray3',
      selectHoverBorder: '$gray4',
      selectText: '$black',
      separatorBackground: '$gray3',
      statBackground: '$gray1',
      chartBackground: '$gray1',
      summaryCardBackground: '$gray1',
      tagDefaultBackground: '$gray3',
      tagErrorBackground: '$red2',
      tagErrorText: '$red1',
      tagSuccessBackground: '$green2',
      tagText: '$gray5',
      tagWarningBackground: '$yellow2',
      tertiaryNavItemActiveBackground: '$gray2',
      tertiaryNavItemHoverBackground: '$gray1',
      tertiaryNavText: '$black',
      textAreaBackground: '$white',
      textAreaBorder: '$gray3',
      textAreaDisabledBorder: '$gray3',
      textAreaDisabledText: '$gray3',
      textAreaHoverBorder: '$gray4',
      textAreaPlaceholderText: '$gray4',
      textBoxBackground: '$white',
      textBoxBorder: '$gray3',
      textBoxDisabledBorder: '$gray3',
      textBoxDisabledText: '$gray3',
      textBoxHoverBorder: '$gray4',
      textBoxPlaceholderText: '$gray4',
      toastContentErrorBackground: '$red2',
      toastContentErrorBorder: '$red1',
      toastContentNeutralBackground: '$white',
      toastContentNeutralBorder: '$gray3',
      toolBarBackground: '$gray1',
      typographyAnnotationText: '$gray4',
      typographyCaptionText: '$gray4',
      typographyCtaText: '$black',
      typographyErrorCaptionText: '$red1',
      typographyLabelRequiredText: '$red1',
      typographyLabelText: '$gray4',
      typographyRegularText: '$black',
      typographySmallText: '$black',
      typographyStrongText: '$black',
      typographySubTitleText: '$black',
      typographyTitleText: '$black',
      typographyNoteText: '$black',
    },
    fonts: {
      default: 'Poppins, sans-serif',

      title: '$default',
      subTitle: '$default',
      regular: '$default',
      strong: '$default',
      small: '$default',
      cta: '$default',
      caption: '$default',
      label: '$default',
      annotation: '$default',
      note: '$default',
    },
    fontSizes: {
      title: '36px',
      subTitle: '22px',
      regular: '18px',
      strong: '18px',
      small: '16px',
      cta: '16px',
      caption: '14px',
      label: '14px',
      annotation: '12px',
      note: '14px',
    },
    fontWeights: {
      default: 400,
      medium: 500,
      semiBold: 600,

      title: '$medium',
      subTitle: '$medium',
      regular: '$default',
      strong: '$semiBold',
      small: '$default',
      cta: '$semiBold',
      caption: '$default',
      label: '$default',
      annotation: '$default',
      note: '$default',
    },
    letterSpacings: {
      title: '-0.5px',
      subTitle: '0px',
      regular: '0.25px',
      strong: '0.25px',
      small: '0.25px',
      cta: '0.25px',
      caption: '0.1px',
      label: '0.1px',
      annotation: '0.1px',
      note: '0.1px',
    },
    lineHeights: {
      title: '150%',
      subTitle: '150%',
      regular: '150%',
      strong: '150%',
      small: '150%',
      cta: '150%',
      caption: '150%',
      label: '150%',
      annotation: '150%',
      note: '150%',
    },
    radii: {
      avatar: '16px',
      button: '20px',
      cardDefault: '4px',
      chart: '16px',
      chatBubble: '2px',
      checkbox: '2px',
      chromeContent: '4px',
      control: '2px',
      controlButton: '2px',
      controlPopup: '4px',
      dialogContent: '4px',
      flapDefault: '4px',
      iconButton: '2px',
      primaryNavItem: '4px',
      select: '2px',
      stat: '16px',
      tag: '2px',
      tertiaryNavItem: '4px',
      textArea: '2px',
      textBox: '2px',
      toastContent: '4px',
    },
    shadows: {
      chromeContent: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
      controlPopup: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
      dialogContent: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
      invertedFocus: `0 0 0 2px ${THEME_COLORS.Gray6}, 0 0 0 4px ${THEME_COLORS.Gray4}C0`,
      primaryFocus: `0 0 0 2px #FFFFFF, 0 0 0 4px ${THEME_COLORS.Primary}C0`,
      secondaryFocus: `0 0 0 2px #FFFFFF, 0 0 0 4px ${THEME_COLORS.Gray3}C0`,
      toastNotification: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
    },
    sizes: {
      announcementCoverWidth: '264px',
      announcementMaxWidth: '384px',
      avatarSize: '32px',
      bannerIconSize: '24px',
      buttonHeight: '40px',
      checkboxSize: '20px',
      chromeContentHeightMobile: '100dvh',
      chromeContentWidthMobile: '100vw',
      chromeContentHeightDesktop: 'calc(100dvh - 48px)',
      chromeContentWidthDesktop: 'calc(100vw - 48px)',
      controlButtonHeight: '40px',
      controlButtonIconSize: '20px',
      itemButtonIconSize: '20px',
      controlHeight: '40px',
      controlIconSize: '20px',
      coverContentMaxWidth: '420px',
      coverLogoHeightDesktop: '32px',
      coverLogoHeightMobile: '28px',
      coverWelcomeHeightDesktop: '128px',
      coverWelcomeHeightMobile: '64px',
      dialogContentMaxHeight: '90dvh',
      dialogContentMaxWidth: '90vw',
      dialogContentWidth: '600px',
      dialogFooterButtonMinWidth: '128px',
      dismissableTagHeight: '30px',
      dismissableTagIconSize: '20px',
      drawerContentMaxWidth: '600px',
      drawerContentWidth: '90vw',
      drawerFooterButtonMinWidth: '128px',
      chromeFooterButtonMinWidth: '128px',
      flapHeight: '22px',
      formSubmitButtonMinWidth: '128px',
      iconButtonIconSize: '24px',
      iconButtonSize: '40px',
      illustrationsSpinnerSize: '24px',
      itemsBannerButtonWidth: '150px',
      primaryNavGroupTitleHeight: '32px',
      primaryNavHeaderHeightMobile: '60px',
      primaryNavHeaderLogoHeightDesktop: '32px',
      primaryNavHeaderLogoHeightMobile: '28px',
      primaryNavItemAvatarHeight: '48px',
      primaryNavItemHeight: '40px',
      primaryNavWidthDesktop: '280px',
      primaryNavWidthLargeDesktop: '320px',
      selectHeight: '40px',
      separatorThickness: '1px',
      structureContentMaxWidth: '1440px',
      tagHeight: '30px',
      tertiaryNavItemHeight: '32px',
      textBoxHeight: '40px',
      toastViewportWidth: '500px',
      typographyErrorCaptionHeight: '21px',
      submitIconButtonWidth: '55px',
    },
    space: {
      annotatedGap: '4px',
      announcementGap: '24px',
      announcementPadding: '12px',
      bannerChildrenGap: '8px',
      bannerGap: '12px',
      bannerPadding: '12px',
      buttonPaddingX: '20px',
      cardGap: '12px',
      cardPadding: '12px',
      cardPaddingTop: '24px',
      cardTitleGap: '8px',
      categoryTreeGap: '4px',
      categoryTreeIndent: '20px',
      chatBubbleCompactSpace: '8px',
      chatBubbleInnerGap: '8px',
      chatBubbleSpace: '12px',
      chromeFooterPadding: '12px',
      chromePanelGapDesktop: '24px',
      chromePanelGapMobile: '16px',
      chromePanelPaddingDesktop: '24px',
      chromePanelPaddingMobile: '16px',
      controlButtonPaddingX: '12px',
      controlFooterGap: '12px',
      controlGap: '4px',
      controlPaddingX: '12px',
      coverSpaceDesktop: '24px',
      coverSpaceMobile: '16px',
      coverWelcomeMarginTopMobile: '96px',
      descriptionFeaturesGap: '4px',
      descriptionFeaturesPaddingLeft: '16px',
      descriptionGap: '12px',
      dialogPanelGapDesktop: '24px',
      dialogPanelGapMobile: '16px',
      dialogPanelPaddingDesktop: '24px',
      dialogPanelPaddingMobile: '16px',
      dismissableTagPaddingLeft: '12px',
      dismissableTagPaddingRight: '6px',
      drawerContainerGapDesktop: '24px',
      drawerContainerGapMobile: '16px',
      drawerContainerPaddingDesktop: '24px',
      drawerContainerPaddingMobile: '16px',
      drawerFooterPadding: '12px',
      drawerGroupGap: '12px',
      drawerGroupInnerGap: '12px',
      drawerPanelGapDesktop: '24px',
      drawerPanelGapMobile: '16px',
      drawerPanelPaddingDesktop: '24px',
      drawerPanelPaddingMobile: '16px',
      drawerTitleGap: '4px',
      flapPaddingX: '12px',
      formFooterGap: '12px',
      headerGap: '12px',
      itemButtonPadding: '12px',
      itemGap: '4px',
      itemsBannerChildrenGap: '8px',
      itemsBannerGap: '12px',
      itemsBannerPadding: '12px',
      itemsGap: '12px',
      labeledGap: '8px',
      notificationGap: '4px',
      primaryNavGroupGap: '8px',
      primaryNavGroupTitlePaddingXDesktop: '12px',
      primaryNavGroupTitlePaddingXMobile: '8px',
      primaryNavHeaderHeightMobile: '60px',
      primaryNavItemGap: '8px',
      primaryNavItemPaddingXDesktop: '12px',
      primaryNavItemPaddingXMobile: '8px',
      primaryNavPanelContentGap: '8px',
      primaryNavSpaceDesktop: '24px',
      primaryNavSpaceMobile: '16px',
      primaryNavWidthDesktop: '280px',
      primaryNavWidthLargeDesktop: '320px',
      searchGap: '12px',
      secondaryNavDetailsSpaceDesktop: '12px',
      secondaryNavDetailsSpaceMobile: '8px',
      secondaryNavSpaceDesktop: '16px',
      secondaryNavSpaceMobile: '16px',
      secondaryNavTabsGap: '40px',
      sectionGapDesktop: '24px',
      sectionGapMobile: '16px',
      sectionInnerGapDesktop: '12px',
      sectionInnerGapMobile: '8px',
      selectPaddingLeft: '12px',
      selectPaddingRight: '44px',
      statGap: '8px',
      statPadding: '12px',
      chartGap: '12px',
      chartPadding: '16px',
      structureContentInnerPaddingDesktop: '12px',
      structureContentInnerPaddingMobile: '8px',
      structureContentPaddingDesktop: '24px',
      structureContentPaddingMobile: '16px',
      structureGridInnerGapDesktop: '12px',
      structureGridInnerGapMobile: '8px',
      structureGroupGapDesktop: '24px',
      structureGroupGapMobile: '16px',
      structureGroupInnerGapDesktop: '12px',
      structureGroupInnerGapMobile: '8px',
      structureMainPaddingDesktop: '32px',
      structureMainPaddingMobile: '24px',
      structureScrollContentPaddingDesktop: '24px',
      structureScrollContentPaddingMobile: '16px',
      structureStackGapDesktop: '32px',
      structureStackGapMobile: '24px',
      tagPaddingX: '12px',
      tertiaryNavGap: '6px',
      tertiaryNavItemPaddingX: '8px',
      tertiaryNavSeparatorMarginY: '8px',
      textAreaPadding: '12px',
      textBoxIconPaddingRight: '44px',
      textBoxPaddingX: '12px',
      toastContentGap: '16px',
      toastContentPadding: '16px',
      toastViewportGap: '16px',
      toastViewportPadding: '32px',
      toolBarGap: '12px',
      toolBarPadding: '12px',
      uploadGap: '4px',
      submitIconButtonPadding: '25px',
    },
    // @sort-keys
    zIndices: {
      chrome: 3000,
      dialog: 5000,
      drawerDefault: 2000,
      drawerTop: 4000,
      primaryNav: 10000,
      primaryNavOverride: 10001,
      sticky: 100,
      toast: 6000,
    },
  },
  media: {
    tablet: `(min-width: ${BREAKPOINT_TABLET}px)`,
    desktop: `(min-width: ${BREAKPOINT_DESKTOP}px)`,
    largeDesktop: `(min-width: ${BREAKPOINT_LARGE_DESKTOP}px)`,
  },
  utils: {
    paddingX: (value: ScaleValue<'space'>) => ({ paddingLeft: value, paddingRight: value }),
    paddingY: (value: ScaleValue<'space'>) => ({ paddingBottom: value, paddingTop: value }),
    marginX: (value: ScaleValue<'space'>) => ({ marginLeft: value, marginRight: value }),

    /*text: (value: Omit<ScaleValue<'fonts'>, 'default'>) => {
      const anyValue: unknown = value;
      const mobileValue = typeof anyValue === 'string' ? `calc(${anyValue} * 0.9)` : value;

      return {
        fontFamily: value,
        fontSize: mobileValue,
        lineHeight: mobileValue,
        fontWeight: value,
        letterSpacing: value,

        '@desktop': {
          fontSize: value,
          lineHeight: value,
        },
      };
    },*/

    text: (value: Omit<ScaleValue<'fonts'>, 'default'>) => ({
      fontFamily: value,
      fontSize: value,
      fontWeight: value,
      letterSpacing: value,
      lineHeight: value,
    }),

    hideScrollbars: () => ({
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }),
  },
});

export const styledKeyframes = {
  fadeIn: keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  }),
  fadeOut: keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
  }),
  slideIn: keyframes({
    '0%': { transform: 'translateX(100%)' },
    '100%': { transform: 'translateX(0px)' },
  }),
  slideOut: keyframes({
    '0%': { transform: 'translateX(0px)' },
    '100%': { transform: 'translateX(100%)' },
  }),
  swipeIn: keyframes({
    from: { transform: 'translateX(calc(100% + 32px))' },
    to: { transform: 'translateX(0)' },
  }),
  swipeOut: keyframes({
    from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
    to: { transform: 'translateX(calc(100% + 32px))' },
  }),
  spin: keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  }),
};

const ICON_COMPONENTS = {
  add: PlusSmallIcon,
  apply: CheckIcon,
  back: ArrowLeftIcon,
  clock: ClockIcon,
  close: XMarkIcon,
  'credit-card': CreditCardIcon,
  dollar: CurrencyDollarIcon,
  download: ArrowDownTrayIcon,
  edit: PencilSquareIcon,
  enter: ArrowLeftOnRectangleIcon,
  exit: ArrowRightOnRectangleIcon,
  forbidden: NoSymbolIcon,
  funnel: FunnelIcon,
  gift: GiftIcon,
  inbound: InboxArrowDownIcon,
  info: InformationCircleIcon,
  link: ArrowTopRightOnSquareIcon,
  loading: ArrowPathIcon,
  menu: Bars3Icon,
  outbound: ArrowUpOnSquareStackIcon,
  processing: ArrowPathRoundedSquareIcon,
  question: QuestionMarkCircleIcon,
  remove: MinusSmallIcon,
  rocket: RocketLaunchIcon,
  search: MagnifyingGlassIcon,
  send: PaperAirplaneIcon,
  test: BeakerIcon,
  trash: TrashIcon,
  view: ViewfinderCircleIcon,
  upload: CloudArrowUpIcon,
  waiting: ClockIcon,
  warning: ExclamationTriangleIcon,
  pin: PlusCircleIcon,
  unpin: MinusCircleIcon,
  'shopping-cart': ShoppingCartIcon,
};

export type TIcon = keyof typeof ICON_COMPONENTS;

export type TIconComponent = ForwardRefExoticComponent<
  PropsWithoutRef<SVGProps<SVGSVGElement>> & RefAttributes<SVGSVGElement> & { title?: string; titleId?: string }
>;

export function getIconComponent(icon: TIcon): TIconComponent {
  return ICON_COMPONENTS[icon];
}

export type TEmptyObject = object;
export type TCss = CSS<typeof config>;

export type TProps<
  HasChildren extends boolean,
  ExtraProps extends object,
  Element extends keyof JSX.IntrinsicElements | undefined,
> = (HasChildren extends true
  ? PropsWithChildren<
      ExtraProps &
        Omit<Element extends keyof JSX.IntrinsicElements ? ComponentProps<Element> : TEmptyObject, keyof ExtraProps>
    >
  : Omit<
      ExtraProps &
        Omit<Element extends keyof JSX.IntrinsicElements ? ComponentProps<Element> : TEmptyObject, keyof ExtraProps>,
      'children'
    >) & {
  css?: TCss;
};

export const globalStyles = globalCss({
  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
  },
  html: {
    height: '100%',
    overscrollBehavior: 'none',
  },
  body: {
    height: '100%',
    overscrollBehavior: 'none',
  },
});

export const SSvg = styled('svg', { flexShrink: 0 });
export const SDiv = styled('div', {});
