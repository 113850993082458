import { Typography } from '@src/components/appearance/basics/Typography';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { BarChart } from '@src/components/appearance/fragments/BarChart';
import { PieChart } from '@src/components/appearance/fragments/PieChart';
import { Stat } from '@src/components/appearance/fragments/Stat';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrdersDashboardInvoiceCard } from '@src/components/mixins/cards/OrdersDashboardInvoiceCard';
import type {
  TCustomerOrdersDashboard,
  TCustomerOrdersDashboardInput,
  TCustomerOrdersDashboardOrdersInvoice,
  TCustomerOrdersDashboardOrdersTimeBreakdown,
} from '@src/gen/shared/data/dashboards';
import { getDateDate } from '@src/gen/shared/data/snippets';
import type { EExportFormat } from '@src/gen/shared/enums/exportFormat';
import { formatDollarsCurrency, formatPercent } from '@src/gen/shared/utils/converters';
import { THEME_COLORS } from '@src/gen/shared/utils/design';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TCustomerAnalyticsOrdersActionsTypes } from '@src/modules/data/customer/analytics/CustomerAnalyticsOrdersActionsProvider';
import { CONTAINER_MEDIA_DESKTOP } from '@src/modules/design/breakpoints';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { eachMonthOfInterval, endOfMonth, startOfMonth } from 'date-fns';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  containerType: 'inline-size',
  display: 'flex',
  flexDirection: 'column',
  gap: '$structureGroupGapMobile',

  '@desktop': {
    gap: '$structureGroupGapDesktop',
  },
});

const SRowDiv = styled('div', {
  display: 'grid',
  gap: '$structureGroupGapMobile',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',

  [CONTAINER_MEDIA_DESKTOP]: {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  },

  '@desktop': {
    gap: '$structureGroupGapDesktop',
  },
});

const SCellDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  flexShrink: 1,
});

const SStatsDiv = styled('div', {
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  flexGrow: 1,
});

export type TAnalyticsOrdersDashboardBase = {
  dashboard: TCustomerOrdersDashboard;
  doGenerateOrderTo?: TCustomerAnalyticsOrdersActionsTypes['DoGenerateOrderTo'] | undefined;
  downloadOrderSnapshotAsyncNotify?:
    | TCustomerAnalyticsOrdersActionsTypes['DownloadOrderSnapshotAsyncNotify']
    | undefined;
};

export type TAnalyticsOrdersDashboard = TProps<false, TAnalyticsOrdersDashboardBase, 'div'>;
export const ANALYTICS_ORDERS_DASHBOARD_CLASS_NAME = 'wp-analytics-orders-dashboard';

export const AnalyticsOrdersDashboard = withCssToString(
  ANALYTICS_ORDERS_DASHBOARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TAnalyticsOrdersDashboard>(
      ({ dashboard, doGenerateOrderTo, downloadOrderSnapshotAsyncNotify, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ANALYTICS_ORDERS_DASHBOARD_CLASS_NAME),
          [className],
        );

        const { savings, savingsPercent } = calculateSavings({
          baseTotal: dashboard.orders.baseTotalSum,
          wellplaeceTotal: dashboard.orders.wellplaeceTotalSum,
        });

        const cleanedUpTimeBreakdowns = useMemo(() => cleanUpTimeBreakdowns(dashboard), [dashboard]);

        if (dashboard.orders.totalCount === 0) {
          return (
            <Announcement css={{ marginTop: '64px' }}>
              <Typography.SubTitle text='No Data' />
              <Typography.Small text={getAnnouncementText(dashboard.input)} />
            </Announcement>
          );
        }

        return (
          <Structure.Stack {...rest} className={joinedClassName} ref={ref}>
            <SDiv>
              <SRowDiv>
                <SCellDiv>
                  <SStatsDiv>
                    <Stat caption='Total Spend' stat={formatDollarsCurrency(dashboard.orders.wellplaeceTotalSum)} />
                    <Stat caption='Orders (Invoiced)' stat={`${dashboard.orders.invoicedCount}`} />
                    <Stat caption='Wellplaece Savings ($)' stat={formatDollarsCurrency(savings)} />
                    <Stat caption='Orders (Pending)' stat={`${dashboard.orders.pendingCount}`} />
                    <Stat caption='Wellplaece Savings (%)' stat={formatPercent(savingsPercent)} />
                    <Stat caption='Orders (Canceled)' stat={`${dashboard.orders.canceledCount}`} />
                  </SStatsDiv>
                </SCellDiv>
                <SCellDiv>
                  <PieChart
                    title='Orders'
                    colorsFromData={true}
                    data={[
                      {
                        id: 'Invoiced',
                        value: dashboard.orders.invoicedCount,
                        label: 'Invoiced',
                        color: THEME_COLORS.Brand,
                      },
                      {
                        id: 'Pending',
                        value: dashboard.orders.pendingCount,
                        label: 'Pending',
                        color: '#DECAE4',
                      },
                      {
                        id: 'Canceled',
                        value: dashboard.orders.canceledCount,
                        label: 'Canceled',
                        color: '#CCCCCC',
                      },
                    ]}
                  />
                </SCellDiv>
              </SRowDiv>
              {dashboard.locationBreakdowns.length > 1 && (
                <SCellDiv>
                  <PieChart
                    title='Total Spend by Location'
                    colorsFromData={false}
                    valueFormat={formatDollarsCurrency}
                    data={dashboard.locationBreakdowns.map((b) => ({
                      id: b.locationName,
                      value: b.orders.wellplaeceTotalSum,
                      label: b.locationName,
                      color: THEME_COLORS.Brand,
                    }))}
                  />
                </SCellDiv>
              )}
              {cleanedUpTimeBreakdowns.length > 0 && (
                <SCellDiv>
                  <BarChart
                    title='Total Spend by Month'
                    valueFormat={formatDollarsCurrency}
                    data={cleanedUpTimeBreakdowns.map((b) => ({
                      id: `${b.year}-${`${b.month}`.padStart(2, '0')}`,
                      value: b.orders.wellplaeceTotalSum,
                      label: `${b.year}-${b.month}`,
                      color: THEME_COLORS.Brand,
                    }))}
                  />
                </SCellDiv>
              )}
              {(dashboard.invoices.length > 0 || dashboard.v1OrdersCount > 0) && (
                <Structure.Group title='Invoices'>
                  {dashboard.v1OrdersCount > 0 && (
                    <Banner
                      icon='warning'
                      accent='warning'
                      message='Some invoices for orders placed in older versions of Wellplaece are not displayed here.'
                    />
                  )}
                  {dashboard.invoices.map((inv) => (
                    <InvoiceCardComponent
                      key={inv.orderSnapshotId}
                      invoice={inv}
                      doGenerateOrderTo={doGenerateOrderTo}
                      downloadOrderSnapshotAsyncNotify={downloadOrderSnapshotAsyncNotify}
                    />
                  ))}
                </Structure.Group>
              )}
            </SDiv>
          </Structure.Stack>
        );
      },
    ),
  ),
);

type TInvoiceCardComponent = {
  invoice: TCustomerOrdersDashboardOrdersInvoice;
  doGenerateOrderTo: TAnalyticsOrdersDashboard['doGenerateOrderTo'];
  downloadOrderSnapshotAsyncNotify: TAnalyticsOrdersDashboard['downloadOrderSnapshotAsyncNotify'];
};

function InvoiceCardComponent({
  invoice,
  doGenerateOrderTo,
  downloadOrderSnapshotAsyncNotify,
}: TInvoiceCardComponent): JSX.Element {
  const to = useMemo(
    () =>
      isDef(doGenerateOrderTo) ? doGenerateOrderTo({ locationId: invoice.locationId, orderId: invoice.orderId }) : null,
    [doGenerateOrderTo, invoice.locationId, invoice.orderId],
  );

  const downloadAsyncNotify = useMemo(
    () =>
      isDef(to) && isDef(downloadOrderSnapshotAsyncNotify)
        ? async (exportFormat: EExportFormat): Promise<void> =>
            await downloadOrderSnapshotAsyncNotify({
              orderSnapshotId: invoice.orderSnapshotId,
              exportFormat,
            })
        : null,
    [downloadOrderSnapshotAsyncNotify, invoice.orderSnapshotId, to],
  );

  return (
    <OrdersDashboardInvoiceCard
      key={invoice.orderSnapshotId}
      invoice={invoice}
      to={to}
      downloadAsyncNotify={downloadAsyncNotify}
    />
  );
}

function calculateSavings({ baseTotal, wellplaeceTotal }: { baseTotal: number; wellplaeceTotal: number }): {
  savings: number;
  savingsPercent: number;
} {
  // No savings.
  if (baseTotal === wellplaeceTotal) {
    return {
      savings: 0,
      savingsPercent: 0,
    };
  }

  if (baseTotal > wellplaeceTotal) {
    const savings = baseTotal - wellplaeceTotal;

    return {
      savings,
      savingsPercent: Math.round((savings * 10000) / baseTotal),
    };
  }

  const savings = wellplaeceTotal - baseTotal;

  return {
    savings: -savings,
    savingsPercent: -Math.round((savings * 10000) / wellplaeceTotal),
  };
}

function cleanUpTimeBreakdowns(dashboard: TCustomerOrdersDashboard): TCustomerOrdersDashboardOrdersTimeBreakdown[] {
  const startTime = isDef(dashboard.input.startTime) ? new Date(dashboard.input.startTime) : null;
  const endTime = isDef(dashboard.input.endTime) ? new Date(dashboard.input.endTime) : null;

  const isStartMonthClipped = isDef(startTime) && startTime.getTime() !== startOfMonth(startTime).getTime();
  const isEndMonthClipped = isDef(endTime) && endTime.getTime() !== endOfMonth(endTime).getTime();
  let filteredOutput = dashboard.timeBreakdowns;

  if (isStartMonthClipped) {
    filteredOutput = filteredOutput.filter(
      (tb) => tb.year !== startTime.getFullYear() || tb.month !== startTime.getMonth() + 1,
    );
  }

  if (isEndMonthClipped) {
    filteredOutput = filteredOutput.filter(
      (tb) => tb.year !== endTime.getFullYear() || tb.month !== endTime.getMonth() + 1,
    );
  }

  if (filteredOutput.length < 2) {
    return [];
  }

  const first = ensureDef(filteredOutput[0]);
  const last = ensureDef(filteredOutput[filteredOutput.length - 1]);
  const paddedOutput: TCustomerOrdersDashboardOrdersTimeBreakdown[] = [];

  const months = eachMonthOfInterval({
    start: new Date(first.year, first.month - 1, 1, 0, 0, 0, 0),
    end: new Date(last.year, last.month - 1, 1, 0, 0, 0, 0),
  });

  for (const current of months) {
    const currentTb = filteredOutput.find(
      (tb) => tb.year === current.getFullYear() && tb.month === current.getMonth() + 1,
    );

    if (isDef(currentTb)) {
      paddedOutput.push(currentTb);
    } else {
      paddedOutput.push({
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        orders: {
          totalCount: 0,
          invoicedCount: 0,
          pendingCount: 0,
          canceledCount: 0,
          wellplaeceTotalAverage: 0,
          wellplaeceTotalSum: 0,
          baseTotalSum: 0,
        },
      });
    }
  }

  return paddedOutput;
}

function getAnnouncementText(input: TCustomerOrdersDashboardInput): string {
  if (!isDef(input.startTime) && !isDef(input.endTime)) {
    if (isDef(input.locationId)) {
      return 'Your analytics will appear here after the selected location places its first order.';
    } else {
      return 'Your analytics will appear here after the first order is placed.';
    }
  }

  const parts: string[] = ['No orders placed'];

  if (isDef(input.locationId)) {
    parts.push('by the selected location');
  } else {
    parts.push('by any location');
  }

  if (isDef(input.startTime) && isDef(input.endTime)) {
    parts.push(`between ${getDateDate(input.startTime)} and ${getDateDate(input.endTime)}.`);
  } else if (!isDef(input.startTime) && isDef(input.endTime)) {
    parts.push(`before ${getDateDate(input.endTime)}.`);
  } else if (isDef(input.startTime) && !isDef(input.endTime)) {
    parts.push(`after ${getDateDate(input.startTime)}.`);
  } else {
    //return 'All Time';
  }

  return parts.join(' ');
}
