import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EShip24StatusCode {
  DATA_ORDER_CREATED = 'data_order_created',
  DATA_ORDER_CANCELLED = 'data_order_cancelled',
  DATA_DELIVERY_PROPOSED = 'data_delivery_proposed',
  DATA_DELIVERY_DECIDED = 'data_delivery_decided',
  DESTINATION_ARRIVAL = 'destination_arrival',
  CUSTOMS_RECEIVED = 'customs_received',
  CUSTOMS_EXCEPTION = 'customs_exception',
  CUSTOMS_REJECTED = 'customs_rejected',
  CUSTOMS_CLEARED = 'customs_cleared',
  DELIVERY_AVAILABLE_FOR_PICKUP = 'delivery_available_for_pickup',
  DELIVERY_OUT_FOR_DELIVERY = 'delivery_out_for_delivery',
  DELIVERY_ATTEMPTED = 'delivery_attempted',
  DELIVERY_EXCEPTION = 'delivery_exception',
  DELIVERY_DELIVERED = 'delivery_delivered',
  EXCEPTION_RETURN = 'exception_return',
}

const SHIP24_STATUS_CODE_LOOKUP = mapValues(invert(EShip24StatusCode), () => true);

export function getShip24StatusCode(rawShip24StatusCode: string): EShip24StatusCode {
  assert(SHIP24_STATUS_CODE_LOOKUP[rawShip24StatusCode], `Unknown ship24 status code: "${rawShip24StatusCode}".`);
  return rawShip24StatusCode as EShip24StatusCode; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetShip24StatusCode(rawShip24StatusCode: string): EShip24StatusCode | undefined {
  try {
    return getShip24StatusCode(rawShip24StatusCode);
  } catch {
    return undefined;
  }
}

const SHIP24_STATUS_CODE_LABELS: { [key in EShip24StatusCode]: string } = {
  [EShip24StatusCode.DATA_ORDER_CREATED]: 'Order Created',
  [EShip24StatusCode.DATA_ORDER_CANCELLED]: 'Order Cancelled',
  [EShip24StatusCode.DATA_DELIVERY_PROPOSED]: 'Delivery Proposed',
  [EShip24StatusCode.DATA_DELIVERY_DECIDED]: 'Delivery Decided',
  [EShip24StatusCode.DESTINATION_ARRIVAL]: 'Destination Arrival',
  [EShip24StatusCode.CUSTOMS_RECEIVED]: 'Customs Received',
  [EShip24StatusCode.CUSTOMS_EXCEPTION]: 'Customs Exception',
  [EShip24StatusCode.CUSTOMS_REJECTED]: 'Customs Rejected',
  [EShip24StatusCode.CUSTOMS_CLEARED]: 'Customs Cleared',
  [EShip24StatusCode.DELIVERY_AVAILABLE_FOR_PICKUP]: 'Delivery Available for Pickup',
  [EShip24StatusCode.DELIVERY_OUT_FOR_DELIVERY]: 'Delivery out for Delivery',
  [EShip24StatusCode.DELIVERY_ATTEMPTED]: 'Delivery Attempted',
  [EShip24StatusCode.DELIVERY_EXCEPTION]: 'Delivery Exception',
  [EShip24StatusCode.DELIVERY_DELIVERED]: 'Delivered',
  [EShip24StatusCode.EXCEPTION_RETURN]: 'Exception Return',
};

export function maybeGetShip24StatusCodeLabel(rawShip24StatusCodeLabel: string): string {
  const ship24StatusCode = maybeGetShip24StatusCode(rawShip24StatusCodeLabel);
  return ship24StatusCode !== undefined
    ? ensureDef(SHIP24_STATUS_CODE_LABELS[ship24StatusCode])
    : rawShip24StatusCodeLabel;
}
