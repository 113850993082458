import { Typography } from '@src/components/appearance/basics/Typography';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

export type TOrdersEmptyAnnouncement = TProps<false, TEmptyObject, 'div'>;
export const ORDERS_EMPTY_ANNOUNCEMENT_CLASS_NAME = 'wp-orders-empty-announcement';

export const OrdersEmptyAnnouncement = withCssToString(
  ORDERS_EMPTY_ANNOUNCEMENT_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrdersEmptyAnnouncement>(({ className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, ORDERS_EMPTY_ANNOUNCEMENT_CLASS_NAME),
        [className],
      );

      return (
        <Announcement {...rest} className={joinedClassName} ref={ref}>
          <Fragment>
            <Typography.SubTitle text='Welcome to Wellplaece' />
            <Typography.Small text='Select a location under "Next Order" to begin your first Wellplaece order.' />
          </Fragment>
        </Announcement>
      );
    }),
  ),
);
