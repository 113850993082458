import { Typography } from '@src/components/appearance/basics/Typography';
import type { TAsyncButtonAction } from '@src/components/appearance/controls/Button';
import type { TCheckBoxBase } from '@src/components/appearance/controls/CheckBox';
import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import type { TQuantityBase } from '@src/components/appearance/controls/Quantity';
import { Quantity } from '@src/components/appearance/controls/Quantity';
import type { TTextAreaBase } from '@src/components/appearance/controls/TextArea';
import { TextArea } from '@src/components/appearance/controls/TextArea';
import { Card } from '@src/components/appearance/fragments/Card';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { OrderOriginalCartProductCard } from '@src/components/mixins/cards/OrderOriginalCartProductCard';
import type { TAgentPublicCatalogProductBaseFragment } from '@src/gen/graphql/bindings';
import { EOrderEntryOrigin } from '@src/gen/shared/enums/orderEntryOrigin';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentProductPickerChromeTypes } from '@src/modules/data/agent/global/overlays/AgentProductPickerChromeProvider';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { Dispatch, SetStateAction } from 'react';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';

export type TOrderEntryAddDrawerPanelBase = {
  beginProductPick: TAgentProductPickerChromeTypes['BeginProductPick'];
  doOrderEntryAddAsync: TAgentOrderActionsTypes['DoOrderEntryAddAsync'];
};

type TOrderEntryAddDrawerPanelState = {
  product: TAgentPublicCatalogProductBaseFragment | null;
  isSubstitutionAllowed: boolean;
  quantity: number;
  notes: string;
};

export type TOrderEntryAddDrawerPanel = TProps<false, TOrderEntryAddDrawerPanelBase, 'div'>;
export const ORDER_ENTRY_ADD_DRAWER_PANEL_CLASS_NAME = 'wp-order-entry-add-drawer-panel';

export const OrderEntryAddDrawerPanel = withCssToString(
  ORDER_ENTRY_ADD_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderEntryAddDrawerPanel>(
      ({ beginProductPick, doOrderEntryAddAsync, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ENTRY_ADD_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [state, setState] = useState<TOrderEntryAddDrawerPanelState>({
          product: null,
          isSubstitutionAllowed: false,
          quantity: 0,
          notes: '',
        });

        const handleSave = useCallback(
          async (): Promise<void> => {
            await doOrderEntryAddAsync({
              productId: ensureDef(state.product).id,
              source: ensureDef(state.product).source,
              productPageUrl: ensureDef(state.product).product_page_url,
              productSku: ensureDef(state.product).product_sku,
              manufacturerName: ensureDef(state.product).manufacturer_name,
              manufacturerSku: ensureDef(state.product).manufacturer_sku,
              name: ensureDef(state.product).name,
              secondaryName: ensureDef(state.product).secondary_name,
              saleUnit: ensureDef(state.product).sale_unit,
              isDiscontinued: ensureDef(state.product).is_discontinued,
              isHouseBrand: ensureDef(state.product).is_house_brand,
              categoryPath: ensureDef(state.product).category_path,
              categoryName: ensureDef(state.product).category_name,
              simplifiedCategory: ensureDef(state.product).simplified_category,
              description: ensureDef(state.product).description,
              specs: ensureDef(state.product).specs,
              imageAssetPath: ensureDef(state.product).image_asset_path,
              sdsAssetPath: ensureDef(state.product).sds_asset_path,
              origin: EOrderEntryOrigin.CUSTOMER_REQUESTED,
              isSubstitutionAllowed: state.isSubstitutionAllowed,
              quantity: state.quantity,
              notes: state.notes.trim().length > 0 ? state.notes : null,
            });
          },
          // @sort
          [doOrderEntryAddAsync, state.isSubstitutionAllowed, state.notes, state.product, state.quantity],
        );

        const buttonAction = useMemo<TAsyncButtonAction>(
          () => ({
            isAsync: true,
            onClick: handleSave,
            text: 'Save',
            variant: isDef(state.product) && state.quantity > 0 ? 'default' : 'disabled',
          }),
          [handleSave, state.product, state.quantity],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Add Product' />
            <Drawer.ScrollContent>
              <Drawer.Group>
                <ProductCardPickerComponent beginProductPick={beginProductPick} state={state} setState={setState} />
                <IsSubstitutionAllowedAndQuantityEditComponent state={state} setState={setState} />
              </Drawer.Group>
            </Drawer.ScrollContent>
            <Drawer.Footer buttonAction={buttonAction} />
          </Drawer.Panel>
        );
      },
    ),
  ),
);

const SProductCardPickerComponent = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: '128px',
  justifyContent: 'center',
});

function ProductCardPickerComponent({
  beginProductPick,
  state,
  setState,
}: {
  beginProductPick: TAgentProductPickerChromeTypes['BeginProductPick'];
  state: TOrderEntryAddDrawerPanelState;
  setState: Dispatch<SetStateAction<TOrderEntryAddDrawerPanelState>>;
}): JSX.Element {
  const callback = useCallback<TAgentProductPickerChromeTypes['BeginProductPickArgs']['callback']>(
    (product) => {
      setState((prev) => ({
        ...prev,
        product,
      }));
    },
    [setState],
  );

  const handleSelectProductClick = useCallback((): void => {
    beginProductPick({
      showPreferredCatalogsOption: true,
      restrictSourceOptionsTo: null,
      initialSearchQuery: null,
      callback,
    });
  }, [beginProductPick, callback]);

  if (!isDef(state.product)) {
    return (
      <Card.Container flush={true} interactive={true}>
        <Card.ButtonArea onClick={handleSelectProductClick}>
          <SProductCardPickerComponent>
            <Typography.Caption text='Select Product' />
          </SProductCardPickerComponent>
        </Card.ButtonArea>
      </Card.Container>
    );
  }

  return (
    <OrderOriginalCartProductCard
      orderProduct={{
        ...state.product,
        is_substitution_allowed: state.isSubstitutionAllowed,
        quantity: state.quantity,
      }}
      onClick={handleSelectProductClick}
    />
  );
}

const SIsSubstitutionAllowedAndQuantityEditComponentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$controlGap',
});

function IsSubstitutionAllowedAndQuantityEditComponent({
  state,
  setState,
}: {
  state: TOrderEntryAddDrawerPanelState;
  setState: Dispatch<SetStateAction<TOrderEntryAddDrawerPanelState>>;
}): JSX.Element {
  const handleIsSubstitutionAllowedChange = useCallback<NonNullable<TCheckBoxBase['onCheckedChange']>>(
    (newValue) => {
      setState((prev) => ({
        ...prev,
        isSubstitutionAllowed: newValue === true,
      }));
    },
    [setState],
  );

  const handleQuantityChange = useCallback<TQuantityBase['onChange']>(
    (newValue) => {
      setState((prev) => ({
        ...prev,
        quantity: newValue,
      }));
    },
    [setState],
  );

  const handleNotesChange = useCallback<TTextAreaBase['onChange']>(
    (e) => {
      setState((prev) => ({
        ...prev,
        notes: e.target.value,
      }));
    },
    [setState],
  );

  return (
    <Card.Container flush={true} interactive={false} variant='form'>
      <SIsSubstitutionAllowedAndQuantityEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-entry-add-is-substitution-allowed-edit'
          required={false}
          rigid={true}
          text='Allow Substitutions'
        />
        <CheckBox
          id='order-entry-add-is-substitution-allowed-edit'
          checked={state.isSubstitutionAllowed}
          onCheckedChange={handleIsSubstitutionAllowedChange}
        />
      </SIsSubstitutionAllowedAndQuantityEditComponentDiv>
      <SIsSubstitutionAllowedAndQuantityEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-entry-add-quantity-edit'
          required={true}
          rigid={true}
          text='Quantity'
        />
        <Quantity id='order-entry-add-quantity-edit' value={state.quantity} onChange={handleQuantityChange} />
      </SIsSubstitutionAllowedAndQuantityEditComponentDiv>
      <SIsSubstitutionAllowedAndQuantityEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-entry-add-notes-edit'
          required={false}
          rigid={true}
          text='Notes'
        />
        <TextArea id='order-entry-add-notes-edit' value={state.notes} onChange={handleNotesChange} maxLength={2048} />
        <Typography.Annotation
          text={`(${state.notes.length} / 2048)`}
          css={{ alignSelf: 'flex-end', paddingTop: '4px' }}
        />
      </SIsSubstitutionAllowedAndQuantityEditComponentDiv>
    </Card.Container>
  );
}
