import { Chrome } from '@src/components/appearance/structure/Chrome';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { Structure } from '@src/components/appearance/structure/Structure';
import {
  AgentOrganizationCartProductsLoader,
  OrganizationCartProductCard,
} from '@src/components/mixins/cards/OrganizationCartProductCard';
import { CartProductDrawerPanel } from '@src/components/mixins/drawers/CartProductDrawerPanel';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import {
  AgentCustomersOrganizationCartProductsProvider,
  useAgentCustomersOrganizationCartProducts,
} from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationCartProductsProvider';
import { useAgentCustomersOrganization } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import { InlineChrome, useInlineChrome } from '@src/modules/design/ChromeProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TAgentCartProductsChromeTypes = {
  BeginCartProductsViewArgs: {
    locationId: string;
  };
  BeginCartProductsView: (args: TAgentCartProductsChromeTypes['BeginCartProductsViewArgs']) => void;
};

export type TAgentCartProductsChromeContext = {
  beginCartProductsView: TAgentCartProductsChromeTypes['BeginCartProductsView'];
};

export const { Context: AgentCartProductsChromeContext, useContext: useAgentCartProductsChrome } =
  createRequiredContext<TAgentCartProductsChromeContext>();

export function AgentCartProductsChromeProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const inlineChrome = useInlineChrome<TAgentCartProductsChromeTypes['BeginCartProductsViewArgs']>();

  const beginCartProductsView = useCallback<TAgentCartProductsChromeTypes['BeginCartProductsView']>(
    (args) => {
      inlineChrome.doChromeOpen({
        metadata: args,
        drawerChildren: <Panel locationId={args.locationId} />,
      });
    },
    [inlineChrome],
  );

  const value = useMemo<TAgentCartProductsChromeContext>(
    () => ({
      beginCartProductsView,
    }),
    [beginCartProductsView],
  );

  return (
    <AgentCartProductsChromeContext.Provider value={value}>
      <InlineChrome {...inlineChrome} />
      {children}
    </AgentCartProductsChromeContext.Provider>
  );
}

type TPanel = {
  locationId: string;
};

function Panel({ locationId }: TPanel): JSX.Element {
  const { organization } = useAgentCustomersOrganization();

  const locationName = organization.locations.find((loc) => loc.id === locationId)?.name;
  return (
    <Chrome.Panel>
      <Chrome.Header title={`Cart for location "${locationName}"`} />
      <Chrome.ScrollContent>
        <AgentCustomersOrganizationCartProductsProvider
          locationId={locationId}
          LoaderComponent={AgentOrganizationCartProductsLoader}>
          <Products />
        </AgentCustomersOrganizationCartProductsProvider>
      </Chrome.ScrollContent>
    </Chrome.Panel>
  );
}

function Products(): JSX.Element {
  const { cartProducts } = useAgentCustomersOrganizationCartProducts();

  const [viewingProductId, setViewingProductId] = useState<string | null>(null);

  const handleOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setViewingProductId(null);
    }
  }, []);

  return (
    <Structure.Group>
      {cartProducts.map((cp) => (
        <OrganizationCartProductCard
          key={cp.public_catalog_product_id}
          cartProduct={cp}
          onClick={(): void => setViewingProductId(cp.public_catalog_product_id)}
        />
      ))}

      <Drawer.Root onOpenChange={handleOpenChange} open={isDef(viewingProductId)} level='top'>
        {isDef(viewingProductId) && (
          <CartProductDrawerPanel
            cartProduct={ensureDef(cartProducts.find((cp) => cp.public_catalog_product_id === viewingProductId))}
          />
        )}
      </Drawer.Root>
    </Structure.Group>
  );
}
