import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { CategoryTree } from '@src/components/appearance/fragments/CategoryTree';
import { Description } from '@src/components/appearance/fragments/Description';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { AgentOrderEntryManager } from '@src/gen/shared/data/agentOrders';
import { castDescriptionUnsafe } from '@src/gen/shared/data/description';
import { getSaleUnitQuantityWithFallbackPrefix } from '@src/gen/shared/data/snippets';
import { castSpecsUnsafe } from '@src/gen/shared/data/specs';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { maybeFormatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { isDef } from '@src/gen/shared/utils/types';
import { getSpecsItems, joinClassNames } from '@src/logic/internal/data/utils';
import { openUrl, withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '256px',
});

export type TOrderEntryPlanProductDrawerPanelBase = {
  orderEntryManager: AgentOrderEntryManager;
};

export type TOrderEntryPlanProductDrawerPanel = TProps<false, TOrderEntryPlanProductDrawerPanelBase, 'div'>;
export const ORDER_ENTRY_PLAN_PRODUCT_DRAWER_PANEL_CLASS_NAME = 'wp-order-entry-plan-product-drawer-panel';

export const OrderEntryPlanProductDrawerPanel = withCssToString(
  ORDER_ENTRY_PLAN_PRODUCT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderEntryPlanProductDrawerPanel>(
      ({ orderEntryManager, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ENTRY_PLAN_PRODUCT_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const planProduct = useMemo(() => orderEntryManager.mustGetPlanProduct(), [orderEntryManager]);

        const itemsFirst = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(planProduct.source),
            },
            {
              caption: 'SKU',
              text: planProduct.product_sku,
            },
            {
              caption: 'Manufacturer',
              text: planProduct.manufacturer_name,
            },
            {
              caption: 'Manufacturer SKU',
              text: planProduct.manufacturer_sku,
            },
          ],
          // @sort
          [planProduct.manufacturer_name, planProduct.manufacturer_sku, planProduct.product_sku, planProduct.source],
        );

        const itemsSecond = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Approx. Savings',
              text: orderEntryManager.getSavingsText(),
            },
            {
              caption: 'Quantity',
              text: getSaleUnitQuantityWithFallbackPrefix(
                'TBD',
                orderEntryManager.orderEntry.plan_quantity,
                planProduct.sale_unit,
              ),
            },
            {
              caption: 'Unit Price (Wellplaece)',
              text: maybeFormatDollarsCurrency(orderEntryManager.orderEntry.plan_unit_price) ?? 'TBD',
            },
            {
              caption: 'Sub Total (Wellplaece)',
              text: maybeFormatDollarsCurrency(orderEntryManager.maybeGetPlanSubTotal()) ?? 'TBD',
            },
          ],
          // @sort
          [orderEntryManager, planProduct.sale_unit],
        );

        const productDescriptionBlocks = useMemo(
          () => castDescriptionUnsafe(planProduct.description),
          [planProduct.description],
        );

        const specsItems = useMemo<TItemBaseItem[]>(
          () => getSpecsItems(castSpecsUnsafe(planProduct.specs)),
          [planProduct.specs],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Product' />
            <Drawer.ScrollContent>
              <SImageAsset alt={planProduct.name} imageAssetPath={planProduct.image_asset_path} />
              <Drawer.Separator />
              <Drawer.Group>
                <Drawer.Title subTitle={planProduct.secondary_name} title={planProduct.name} />
                <Items items={itemsFirst} />
              </Drawer.Group>
              <Drawer.Separator />
              <ItemsBanner items={itemsSecond} />
              <Drawer.Separator />
              <Drawer.Group title='References'>
                <ControlButton
                  icon='link'
                  text='Product Page'
                  onClick={(): void => openUrl(planProduct.product_page_url)}
                />
              </Drawer.Group>
              <Drawer.Group title='Category'>
                <CategoryTree category={planProduct.category_name} />
              </Drawer.Group>
              {productDescriptionBlocks.length > 0 && (
                <Drawer.Group title='Description'>
                  <Description description={productDescriptionBlocks} />
                </Drawer.Group>
              )}
              {specsItems.length > 0 && (
                <Drawer.Group title='Specifications'>
                  {specsItems.map((item) => (
                    <Item item={item} key={item.caption} />
                  ))}
                </Drawer.Group>
              )}
              {isDef(planProduct.sds_asset_path) && (
                <Drawer.Group title='Documents'>
                  <ControlButton icon='download' text='Safety Data Sheet' />
                </Drawer.Group>
              )}
            </Drawer.ScrollContent>
          </Drawer.Panel>
        );
      },
    ),
  ),
);
