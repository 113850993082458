import type { AgentOrderMessagesQueryVariables, TAgentOrderMessageBaseFragment } from '@src/gen/graphql/bindings';
import { AgentOrderMessagesDocument, useAgentOrderMessagesQuery } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, FUTURE_DATE, mergeGetMore } from '@src/modules/graphql/utils';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TAgentOrderMessagesContext = {
  orderMessages: TAgentOrderMessageBaseFragment[];
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const { Context: AgentOrderMessagesContext, useContext: useAgentOrderMessages } =
  createRequiredContext<TAgentOrderMessagesContext>();

export function AgentOrderMessagesProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const { order } = useAgentOrder();

  const initialCreatedAtCursor = useRef(
    order.order_messages.length > 0
      ? ensureDef(order.order_messages[order.order_messages.length - 1]).created_at
      : FUTURE_DATE.toISOString(),
  );

  const variables = useMemo<AgentOrderMessagesQueryVariables>(
    () => ({
      orderId: order.id,
      createdAtCursor: initialCreatedAtCursor.current,
      limit: DEFAULT_LIMIT,
    }),
    // @sort
    [order.id],
  );

  const { data, error, fetchMore, networkStatus } = useAgentOrderMessagesQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          createdAtCursor:
            (data?.order_messages.length ?? 0) > 0
              ? ensureDef(data?.order_messages[data.order_messages.length - 1]).created_at
              : initialCreatedAtCursor.current,
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          order_messages: mergeGetMore(prev.order_messages, fetchMoreResult.order_messages),
        }),
      });

      return newData.order_messages.length;
    },
    // @sort
    [fetchMore, data?.order_messages],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.order_messages.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TAgentOrderMessagesContext | undefined>(
    () => ({
      orderMessages: mergeGetMore(order.order_messages, data?.order_messages ?? []),
      queryRef: {
        query: AgentOrderMessagesDocument,
        variables,
      },
      loaderRef,
    }),
    // @sort
    [data, loaderRef, variables, order.order_messages],
  );

  if (isDef(error)) {
    throw error;
  }

  return <AgentOrderMessagesContext.Provider value={value}>{children}</AgentOrderMessagesContext.Provider>;
}
