import { Ago } from '@src/components/appearance/basics/Ago';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { getDateDate, getDateOn, getFileType } from '@src/gen/shared/data/snippets';
import { ensureNotEmptyString, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { filesize } from 'filesize';
import type { ReactElement } from 'react';
import { Fragment, forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$itemGap',
  overflow: 'hidden',
});

export type TItemBaseItemTextAsDate = {
  asDate: 'ago' | 'date' | 'on';
  at: Date | string;
};

export type TItemBaseItemTextAsContentType = {
  asContentType: string;
};

export type TItemBaseItemTextAsContentLength = {
  asContentLength: number;
};

export type TItemBaseItemTextAsBoolean = {
  asBoolean: boolean;
};

export type TItemBaseItemAsReactElement = {
  asReactElement: ReactElement;
};

export type TItemBaseItem = {
  caption: string;
  text:
    | TItemBaseItemAsReactElement
    | TItemBaseItemTextAsBoolean
    | TItemBaseItemTextAsContentLength
    | TItemBaseItemTextAsContentType
    | TItemBaseItemTextAsDate
    | string
    | null;
  accent?: 'default' | 'error' | 'warning' | undefined;
};

export type TItemBase = {
  item: TItemBaseItem;
  rigid?: boolean;
};

export type TItem = TProps<false, TItemBase, 'div'>;
export const ITEM_CLASS_NAME = 'wp-item';

export const Item = withCssToString(
  ITEM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TItem>(
      ({ item: { accent, caption, text }, rigid = false, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ITEM_CLASS_NAME), [className]);

        const textValue = useMemo(() => {
          if (!isDef(text)) {
            return 'N/A';
          } else if (typeof text === 'string') {
            return text;
          } else if (typeof text === 'object' && 'asDate' in text && text.asDate === 'on') {
            return getDateOn(text.at);
          } else if (typeof text === 'object' && 'asDate' in text && text.asDate === 'date') {
            return getDateDate(text.at);
          } else if (typeof text === 'object' && 'asContentType' in text) {
            return getFileType(text.asContentType);
          } else if (typeof text === 'object' && 'asContentLength' in text) {
            return ensureNotEmptyString(filesize(text.asContentLength, { base: 2 }));
          } else if (typeof text === 'object' && 'asBoolean' in text) {
            return text.asBoolean ? 'Yes' : 'No';
          } else {
            return undefined;
          }
        }, [text]);

        const agoValue = useMemo(() => {
          if (isDef(text) && typeof text === 'object' && 'asDate' in text && text.asDate === 'ago') {
            return text.at;
          } else {
            return undefined;
          }
        }, [text]);

        const reactElementValue = useMemo(() => {
          if (isDef(text) && typeof text === 'object' && 'asReactElement' in text) {
            return text.asReactElement;
          } else {
            return undefined;
          }
        }, [text]);

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            <Typography.Caption rigid={rigid} text={caption} />
            {isDef(textValue) && (
              <Typography.Small
                rigid={rigid}
                text={textValue}
                css={{
                  backgroundColor: getBackgroundColor(accent, textValue),
                }}
              />
            )}
            {isDef(agoValue) && <Ago typography='small' rigid={true} at={agoValue} />}
            {isDef(reactElementValue) && <Fragment>{reactElementValue}</Fragment>}
          </SDiv>
        );
      },
    ),
  ),
);

function getBackgroundColor(
  accent: 'default' | 'error' | 'warning' | undefined,
  textValue: string | undefined,
): string {
  if (accent === 'warning') {
    return '$yellow2';
  } else if (accent === 'error') {
    return '$yellow2';
  } else if (textValue === 'TBD') {
    return '$yellow2';
  } else {
    return 'transparent';
  }
}

export const ItemLoader = memo(
  forwardRef<SVGSVGElement, TEmptyObject>(({}, ref) => (
    <Illustrations.Loader height={49} ref={ref} uniqueKey='wp-item-loader' width={96}>
      <rect x='0' y='5' rx='0' ry='0' width='64' height='10' />
      <rect x='0' y='31' rx='0' ry='0' width='96' height='12' />
    </Illustrations.Loader>
  )),
);
