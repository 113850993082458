import { Card } from '@src/components/appearance/fragments/Card';
import { Item } from '@src/components/appearance/fragments/Item';
import { Items } from '@src/components/appearance/fragments/Items';
import type { TItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { TAgentOrderEntryCompleteFragment } from '@src/gen/graphql/bindings';
import { getDateOn, getFullName } from '@src/gen/shared/data/snippets';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderEntryFlagsAndNotesCardBase = {
  orderEntry: TAgentOrderEntryCompleteFragment;
};

export type TOrderEntryFlagsAndNotesCard = TProps<false, TOrderEntryFlagsAndNotesCardBase, 'div'>;
export const ORDER_ENTRY_FLAGS_AND_NOTES_CARD_CLASS_NAME = 'wp-order-entry-flags-and-notes-card';

export const OrderEntryFlagsAndNotesCard = withCssToString(
  ORDER_ENTRY_FLAGS_AND_NOTES_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderEntryFlagsAndNotesCard>(({ orderEntry, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, ORDER_ENTRY_FLAGS_AND_NOTES_CARD_CLASS_NAME),
        [className],
      );

      const items = useMemo<TItemsBanner['items']>(
        () => [
          {
            caption: 'Under Review',
            text: { asBoolean: orderEntry.is_under_review },
          },
          {
            caption: '',
            text: '',
          },
          {
            caption: 'Waiting on Price Confirmation',
            text: { asBoolean: orderEntry.is_blocked_on_price_confirmation },
          },
          {
            caption: 'Waiting on Availability Confirmation',
            text: { asBoolean: orderEntry.is_blocked_on_product_availability_confirmation },
          },
        ],
        [
          orderEntry.is_blocked_on_price_confirmation,
          orderEntry.is_blocked_on_product_availability_confirmation,
          orderEntry.is_under_review,
        ],
      );

      const notesCaption = isDef(orderEntry.notes_updater)
        ? `Internal Notes (Updated by ${getFullName(orderEntry.notes_updater)} on ${getDateOn(
            ensureDef(orderEntry.notes_updated_at),
          )})`
        : 'Internal Notes';

      return (
        <Card.Container {...rest} className={joinedClassName} flush={true} interactive={false} ref={ref}>
          <Card.ContentArea>
            <Item
              item={{
                caption: 'Customer Notes',
                text: orderEntry.customer_visible_notes ?? 'N/A',
              }}
            />
            <Item
              item={{
                caption: notesCaption,
                text: orderEntry.notes ?? 'N/A',
              }}
            />
          </Card.ContentArea>
          <Card.Footer>
            <Items items={items} />
          </Card.Footer>
        </Card.Container>
      );
    }),
  ),
);
