export const THEME_COLORS = {
  Black: '#24282A',
  White: '#FFFFFF',
  Gray1: '#F8F9FA',
  Gray2: '#DFE5EC',
  Gray3: '#CAD7DD',
  Gray4: '#617798',
  Gray5: '#40526D',
  Gray6: '#3F516D',
  Green2: '#EDFDF1',
  Yellow1: '#FFEA47',
  Yellow2: '#FFFAD6',
  Red1: '#CE2D4F',
  Red2: '#FFF8F8',
  Brand: '#8ECCA4',
  Primary: '#31489B',
};
