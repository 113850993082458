import { EErrorClientCode } from '@src/gen/shared/enums/errorClientCode';
import { ensureNotEmptyString, isDef } from '@src/gen/shared/utils/types';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import type { TErrorSummary } from '@src/modules/errors/errorSummary';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import {
  encodeAgentOrderSummaryPath,
  encodeAgentOrderUpdatesAndSupportPath,
  encodeAgentOrderWorksheetDashboardPath,
} from '@src/modules/routing/agent';
import {
  encodeCustomerOrderSummaryPath,
  encodeCustomerOrderUpdatesAndSupportPath,
} from '@src/modules/routing/customer';
import { Fragment, useEffect } from 'react';
import type { ParamParseKey, Params } from 'react-router-dom';
import { Navigate, matchPath, useLocation, useRouteError } from 'react-router-dom';

export function RouterErrorElement(): JSX.Element {
  const error = useRouteError();
  const errorSummary = getErrorSummary(error, { forceDisplayMode: EErrorSummaryDisplayMode.ANNOUNCEMENT });
  const shouldRedirect = useShouldRedirect(errorSummary);
  return isDef(shouldRedirect) ? (
    <Navigate replace={true} to={shouldRedirect} />
  ) : (
    <Throw errorSummary={errorSummary} />
  );
}

function Throw({ errorSummary }: { errorSummary: TErrorSummary }): JSX.Element {
  const { doErrorNotify } = useErrors();

  useEffect(() => {
    doErrorNotify(errorSummary);
  }, [doErrorNotify, errorSummary]);

  return <Fragment />;
}

function useShouldRedirect(errorSummary: TErrorSummary): string | null {
  const { pathname } = useLocation();

  if (errorSummary.code !== EErrorClientCode.NOT_FOUND) {
    return null;
  }

  try {
    maybeTriggerRedirect('/customer/:organizationId/orders/:orderId/original-cart', pathname, (params) =>
      encodeCustomerOrderSummaryPath(ensureNotEmptyString(params.organizationId), ensureNotEmptyString(params.orderId)),
    );

    maybeTriggerRedirect('/customer/:organizationId/orders/:orderId/summary', pathname, (params) =>
      encodeCustomerOrderSummaryPath(ensureNotEmptyString(params.organizationId), ensureNotEmptyString(params.orderId)),
    );

    maybeTriggerRedirect('/customer/:organizationId/orders/:orderId/updates-and-support', pathname, (params) =>
      encodeCustomerOrderUpdatesAndSupportPath(
        ensureNotEmptyString(params.organizationId),
        ensureNotEmptyString(params.orderId),
      ),
    );

    maybeTriggerRedirect('/agent/orders/:orderId/original-cart', pathname, (params) =>
      encodeAgentOrderSummaryPath(ensureNotEmptyString(params.orderId)),
    );

    maybeTriggerRedirect('/agent/orders/:orderId/summary', pathname, (params) =>
      encodeAgentOrderSummaryPath(ensureNotEmptyString(params.orderId)),
    );

    maybeTriggerRedirect('/agent/orders/:orderId/updates-and-support', pathname, (params) =>
      encodeAgentOrderUpdatesAndSupportPath(ensureNotEmptyString(params.orderId)),
    );

    maybeTriggerRedirect('/agent/orders/:orderId/worksheet', pathname, (params) =>
      encodeAgentOrderWorksheetDashboardPath(ensureNotEmptyString(params.orderId)),
    );
  } catch (thrown: unknown) {
    if (typeof thrown === 'string') {
      return thrown;
    }

    throw thrown;
  }

  return null;
}

function maybeTriggerRedirect<Path extends string>(
  pattern: Path,
  pathname: string,
  callback: (params: Params<ParamParseKey<Path>>) => string,
): void {
  const params = matchPath(pattern, pathname);

  if (isDef(params)) {
    throw callback(params.params);
  }
}
