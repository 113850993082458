import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import type { TCheckBoxBase } from '@src/components/appearance/controls/CheckBox';
import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import type { TQuantityBase } from '@src/components/appearance/controls/Quantity';
import { Quantity } from '@src/components/appearance/controls/Quantity';
import { Annotated, AnnotatedLoader } from '@src/components/appearance/fragments/Annotated';
import { Card } from '@src/components/appearance/fragments/Card';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import { Labeled, LabeledLoader } from '@src/components/appearance/fragments/Labeled';
import type { TCustomerCartProductBaseFragment } from '@src/gen/graphql/bindings';
import { getSaleUnitAnnotation } from '@src/gen/shared/data/snippets';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { forwardRef, memo, useMemo } from 'react';

const SFooterDiv = styled('div', {
  display: 'flex',
  gap: '$cardGap',
  flexDirection: 'column-reverse',
  alignItems: 'stretch',

  '@container (min-width: 600px)': {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const SQuantityDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$cardGap',
});

const SQuantity = styled(Quantity, {
  flexGrow: 1,

  '@container (min-width: 600px)': {
    width: '300px',
  },
});

export type TNextOrderCartProductCardBase = {
  cartProduct: Omit<
    TCustomerCartProductBaseFragment,
    | 'location_id'
    | 'is_substitution_allowed'
    | 'quantity'
    | 'is_deleted'
    | 'created_by'
    | 'created_at'
    | 'creator'
    | 'updated_by'
  >;
  isSubstitutionAllowed: TCheckBoxBase;
  onClick: () => void;
  onRemove: () => void;
  quantity: TQuantityBase;
};

export type TNextOrderCartProductCard = TProps<false, TNextOrderCartProductCardBase, 'div'>;
export const NEXT_ORDER_CART_PRODUCT_CARD_CLASS_NAME = 'wp-next-order-cart-product-card';

export const NextOrderCartProductCard = withCssToString(
  NEXT_ORDER_CART_PRODUCT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNextOrderCartProductCard>(
      ({ cartProduct, isSubstitutionAllowed, onClick, onRemove, quantity, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, NEXT_ORDER_CART_PRODUCT_CARD_CLASS_NAME),
          [className],
        );

        const allowSubstitutionsId = useMemo(
          () =>
            `${NEXT_ORDER_CART_PRODUCT_CARD_CLASS_NAME}-allow-substitutions-${cartProduct.public_catalog_product_id}`,
          [cartProduct.public_catalog_product_id],
        );

        const items = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(cartProduct.source),
            },
            {
              caption: 'SKU',
              text: cartProduct.product_sku,
            },
            {
              caption: 'Last Updated By',
              text: `${cartProduct.updater.first_name} ${cartProduct.updater.last_name}`,
            },
            {
              caption: 'Updated',
              text: { asDate: 'ago', at: cartProduct.updated_at },
            },
          ],
          // @sort
          [
            cartProduct.product_sku,
            cartProduct.source,
            cartProduct.updated_at,
            cartProduct.updater.first_name,
            cartProduct.updater.last_name,
          ],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () => [{ isAsync: false, icon: 'trash', onClick: onRemove }],
          [onRemove],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            iconButtonActions={iconButtonActions}
            interactive={true}
            flush={true}
            ref={ref}>
            <Card.ButtonArea onClick={onClick}>
              <Card.ImageAssetArea>
                <ImageAsset alt={cartProduct.name} imageAssetPath={cartProduct.image_asset_path} />
                <Card.ContentArea>
                  <Card.Title subTitle={cartProduct.secondary_name} title={cartProduct.name} />
                  <Items items={items} />
                </Card.ContentArea>
              </Card.ImageAssetArea>
            </Card.ButtonArea>
            <Card.Separator />
            <SFooterDiv>
              <Labeled htmlFor={allowSubstitutionsId} text='Allow Substitutions'>
                <CheckBox id={allowSubstitutionsId} {...isSubstitutionAllowed} />
              </Labeled>
              <Annotated text={getSaleUnitAnnotation(cartProduct.sale_unit)}>
                <SQuantityDiv>
                  <SQuantity css={{ minWidth: 0 }} {...quantity} />
                </SQuantityDiv>
              </Annotated>
            </SFooterDiv>
          </Card.Container>
        );
      },
    ),
  ),
);

export const NextOrderCartProductCardLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Card.Container
        iconButtonActions={[
          {
            isAsync: false,
            icon: 'trash',
            onClick: noop,
            variant: 'disabled',
          },
        ]}
        ref={ref}>
        <Card.ImageAssetArea>
          <Illustrations.Loader height={85} uniqueKey='wp-next-order-cart-product-card-loader-image' width={128}>
            <rect x='0' y='5' rx='0' ry='0' width='128' height='85' />
          </Illustrations.Loader>
          <Card.ContentArea>
            <Card.TitleLoader />
            <ItemsLoader count={4} />
          </Card.ContentArea>
        </Card.ImageAssetArea>
        <Card.Separator />
        <SFooterDiv>
          <LabeledLoader>
            <CheckBox disabled={true} />
          </LabeledLoader>
          <AnnotatedLoader>
            <SQuantityDiv>
              <SQuantity disabled={true} value={0} onChange={noop} />
            </SQuantityDiv>
          </AnnotatedLoader>
        </SFooterDiv>
      </Card.Container>
    ),
  ),
);
