import { Chrome } from '@src/components/appearance/structure/Chrome';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { PropsWithChildren, ReactElement } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TChromeTypes = {
  DoChromeOpen: (newChromeChildren: ReactElement) => void;
  DoChromeClose: () => void;
};

export type TChromeContext = {
  doChromeOpen: TChromeTypes['DoChromeOpen'];
  doChromeClose: TChromeTypes['DoChromeClose'];
};

export const { Context: ChromeContext, useContext: useChrome } = createRequiredContext<TChromeContext>();

export function ChromeProvider({ children }: PropsWithChildren): JSX.Element {
  const inlineChrome = useInlineChrome<undefined>();

  const value = useMemo<TChromeContext>(
    () => ({
      doChromeOpen: (newChromeChildren) =>
        inlineChrome.doChromeOpen({
          metadata: undefined,
          drawerChildren: newChromeChildren,
        }),
      doChromeClose: inlineChrome.doChromeClose,
    }),
    [inlineChrome],
  );

  return (
    <ChromeContext.Provider value={value}>
      <InlineChrome {...inlineChrome} />
      {children}
    </ChromeContext.Provider>
  );
}

export type TInlineChromeState<T> = {
  metadata: T;
  drawerChildren: ReactElement;
};

export type TInlineChrome<T> = {
  doChromeOpen: (newState: TInlineChromeState<T>) => void;
  doChromeClose: () => void;
  drawerState: TInlineChromeState<T> | undefined;
};

export function useInlineChrome<T>(): TInlineChrome<T> {
  const [drawerState, setChromeState] = useState<TInlineChromeState<T> | undefined>();

  return useMemo<TInlineChrome<T>>(
    () => ({
      doChromeOpen: (newState: TInlineChromeState<T>) => setChromeState(newState),
      doChromeClose: () => setChromeState(undefined),
      drawerState,
    }),
    [drawerState],
  );
}

export function InlineChrome<T>({ doChromeClose, drawerState }: TInlineChrome<T>): JSX.Element {
  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        doChromeClose();
      }
    },
    [doChromeClose],
  );

  return (
    <Chrome.Root onOpenChange={handleOpenChange} open={isDef(drawerState)}>
      {drawerState?.drawerChildren}
    </Chrome.Root>
  );
}
