import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { Select } from '@src/components/appearance/controls/Select';
import type { TTabSelectBase } from '@src/components/appearance/controls/TabSelect';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { AnalyticsOrdersDashboard } from '@src/components/mixins/dashboards/AnalyticsOrdersDashboard';
import { getDateDate } from '@src/gen/shared/data/snippets';
import { isDef } from '@src/gen/shared/utils/types';
import type { TDateRangePickerDrawerTypes } from '@src/modules/data/shared/overlays/DateRangePickerDrawerProvider';
import { useDateRangePickerDrawer } from '@src/modules/data/shared/overlays/DateRangePickerDrawerProvider';

import { useAgentCustomersOrganizationAnalyticsOrders } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationAnalyticsOrdersProvider';
import { useAgentCustomersOrganization } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import type { TCustomerAnalyticsOrdersActionsTypes } from '@src/modules/data/customer/analytics/CustomerAnalyticsOrdersActionsProvider';
import {
  encodeAgentCustomersOrganizationAnalyticsOrdersPath,
  encodeAgentOrderSummaryPath,
  useAgentCustomersOrganizationAnalyticsOrdersRouteParams,
} from '@src/modules/routing/agent';
import { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentCustomersOrganizationAnalyticsOrdersPart(): JSX.Element {
  const { dashboard } = useAgentCustomersOrganizationAnalyticsOrders();

  const doGenerateOrderTo = useCallback<TCustomerAnalyticsOrdersActionsTypes['DoGenerateOrderTo']>(
    ({ orderId }) => encodeAgentOrderSummaryPath(orderId),
    [],
  );

  return (
    <Fragment>
      <Structure.Content>
        <Filters disabled={false} />
      </Structure.Content>
      <Structure.ScrollContent skipSeparator={true}>
        <AnalyticsOrdersDashboard dashboard={dashboard} doGenerateOrderTo={doGenerateOrderTo} />
      </Structure.ScrollContent>
    </Fragment>
  );
}

function Filters({ disabled }: { disabled: boolean }): JSX.Element {
  const navigate = useNavigate();
  const routeParams = useAgentCustomersOrganizationAnalyticsOrdersRouteParams();
  const { organization } = useAgentCustomersOrganization();
  const { beginDateRangePick } = useDateRangePickerDrawer();

  const handleLocationSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentCustomersOrganizationAnalyticsOrdersPath(routeParams.organizationId, {
          ...routeParams.config,
          locationId: e.target.value === 'all' ? null : e.target.value,
        }),
      );
    },
    [navigate, routeParams.config, routeParams.organizationId],
  );

  const handleDateRangeChange = useCallback<TDateRangePickerDrawerTypes['BeginLocationPickCallback']>(
    (range) => {
      navigate(
        encodeAgentCustomersOrganizationAnalyticsOrdersPath(routeParams.organizationId, {
          ...routeParams.config,
          ...range,
        }),
      );
    },
    [navigate, routeParams.config, routeParams.organizationId],
  );

  const datesFilterText = useMemo(() => {
    if (isDef(routeParams.config.startTime) && isDef(routeParams.config.endTime)) {
      return `${getDateDate(routeParams.config.startTime)} — ${getDateDate(routeParams.config.endTime)} (Inclusive)`;
    } else if (!isDef(routeParams.config.startTime) && isDef(routeParams.config.endTime)) {
      return `Before ${getDateDate(routeParams.config.endTime)} (Inclusive)`;
    } else if (isDef(routeParams.config.startTime) && !isDef(routeParams.config.endTime)) {
      return `After ${getDateDate(routeParams.config.startTime)} (Inclusive)`;
    } else {
      return 'All Time';
    }
  }, [routeParams.config.endTime, routeParams.config.startTime]);

  return (
    <ToolBar variant='page'>
      <Select disabled={disabled} onChange={handleLocationSelectChange} value={routeParams.config.locationId ?? 'all'}>
        <option value='all'>Location: All</option>
        {organization.locations.map((loc) => (
          <option key={loc.id} value={loc.id}>
            Location: {loc.name}
          </option>
        ))}
      </Select>
      <ControlButton
        icon='funnel'
        variant={disabled ? 'disabled' : 'default'}
        onClick={(): void => beginDateRangePick({ callback: handleDateRangeChange })}
        text={datesFilterText}
      />
    </ToolBar>
  );
}

export function AgentCustomersOrganizationAnalyticsOrdersPartLoader(): JSX.Element {
  return (
    <Structure.Content>
      <Filters disabled={true} />
    </Structure.Content>
  );
}
