import { ItemsBannerLoader } from '@src/components/appearance/fragments/ItemsBanner';
import type { TSecondaryNavTab } from '@src/components/appearance/structure/SecondaryNav';
import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { getFullName } from '@src/gen/shared/data/snippets';
import { useAgentCustomersUser } from '@src/modules/data/agent/customers/users/AgentCustomersUserProvider';
import {
  EAgentRoutes,
  encodeAgentCustomersUserOrganizationsPath,
  encodeAgentCustomersUserOverviewPath,
  useAgentCustomersUserRouteParams,
} from '@src/modules/routing/agent';
import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentCustomersUserNavPart(): JSX.Element {
  const { user } = useAgentCustomersUser();
  const tabs = useTabs();

  return <SecondaryNav title='User' label={getFullName(user)} tabs={tabs} />;
}

export function AgentCustomersUserNavPartLoader(): JSX.Element {
  const tabs = useTabs();

  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav tabs={tabs} tagLoader={true} title='User' />
      </Structure.Content>
      <Structure.ScrollContentLoader>
        <Structure.GroupLoader hasTitle={true}>
          <ItemsBannerLoader count={4} />
        </Structure.GroupLoader>
      </Structure.ScrollContentLoader>
    </Fragment>
  );
}

function useTabs(): TSecondaryNavTab[] {
  const routeParams = useAgentCustomersUserRouteParams();
  const navigate = useNavigate();

  return useMemo<TSecondaryNavTab[]>(
    () => [
      {
        active: routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_USER_OVERVIEW,
        title: 'Overview',
        onClick: () => navigate(encodeAgentCustomersUserOverviewPath(routeParams.userId)),
      },
      {
        active: routeParams.route === EAgentRoutes.AGENT_CUSTOMERS_USER_ORGANIZATIONS,
        title: 'Organizations',
        onClick: () => navigate(encodeAgentCustomersUserOrganizationsPath(routeParams.userId)),
      },
    ],
    [routeParams.route, routeParams.userId, navigate],
  );
}
