import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import type { AgentOrderSnapshotManager } from '@src/gen/shared/data/agentOrders';
import type { CustomerOrderSnapshotManager } from '@src/gen/shared/data/customerOrders';
import { getFullName, getOrderReference } from '@src/gen/shared/data/snippets';
import { EOrderSnapshotType, maybeGetOrderSnapshotTypeLabel } from '@src/gen/shared/enums/orderSnapshotType';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderSnapshotCardBase = {
  orderSnapshotManager: AgentOrderSnapshotManager | CustomerOrderSnapshotManager;
  onClick: () => void;
};

export type TOrderSnapshotCard = TProps<false, TOrderSnapshotCardBase, 'div'>;
export const ORDER_SNAPSHOT_CARD_CLASS_NAME = 'wp-order-snapshot-card';

export const OrderSnapshotCard = withCssToString(
  ORDER_SNAPSHOT_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderSnapshotCard>(
      ({ orderSnapshotManager, onClick, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_SNAPSHOT_CARD_CLASS_NAME), [className]);

        const items = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Order Ref.',
              text: getOrderReference(
                orderSnapshotManager.orderSnapshot.location_order_code,
                orderSnapshotManager.orderSnapshot.order_reference_number,
              ),
            },
            {
              caption: 'Revision',
              text:
                orderSnapshotManager.orderSnapshot.revision > 0
                  ? `#${orderSnapshotManager.orderSnapshot.revision}`
                  : 'Initial',
            },
            {
              caption: 'Issued By',
              text: getFullName(orderSnapshotManager.orderSnapshot.creator),
            },
            {
              caption: 'Issued On',
              text: { asDate: 'on', at: orderSnapshotManager.orderSnapshot.created_at },
            },
          ],
          // @sort
          [
            orderSnapshotManager.orderSnapshot.created_at,
            orderSnapshotManager.orderSnapshot.creator,
            orderSnapshotManager.orderSnapshot.location_order_code,
            orderSnapshotManager.orderSnapshot.order_reference_number,
            orderSnapshotManager.orderSnapshot.revision,
          ],
        );

        const flapText = useMemo<string | undefined>(() => {
          if (orderSnapshotManager.orderSnapshot.type === EOrderSnapshotType.INVOICE) {
            return undefined;
          }

          if (isDef(orderSnapshotManager.orderSnapshot.approved_at)) {
            return 'Approved';
          }

          if (
            isDef(orderSnapshotManager.orderSnapshot.rejected_at) &&
            isDef(orderSnapshotManager.orderSnapshot.rejector)
          ) {
            return orderSnapshotManager.orderSnapshot.rejector.is_wellplaece_agent ? 'Retracted' : 'Rejected';
          }

          return 'Waiting';
        }, [
          orderSnapshotManager.orderSnapshot.approved_at,
          orderSnapshotManager.orderSnapshot.rejected_at,
          orderSnapshotManager.orderSnapshot.rejector,
          orderSnapshotManager.orderSnapshot.type,
        ]);

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flapText={flapText}
            flush={true}
            interactive={true}
            ref={ref}>
            <Card.ButtonArea onClick={onClick}>
              <Card.Title title={maybeGetOrderSnapshotTypeLabel(orderSnapshotManager.orderSnapshot.type)} />
              <Items items={items} />
            </Card.ButtonArea>
          </Card.Container>
        );
      },
    ),
  ),
);
