import { CheckBoxControl } from '@src/components/appearance/controls/CheckBox';
import { ConditionalControl } from '@src/components/appearance/controls/ConditionalControl';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { TextAreaControl } from '@src/components/appearance/controls/TextArea';
import { UploadControl } from '@src/components/appearance/controls/Upload';
import type { TAgentOrderReturnCompleteFragment } from '@src/gen/graphql/bindings';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentUploadsTypes } from '@src/modules/data/agent/global/uploads/AgentUploadsProvider';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, Fragment, memo, useCallback, useMemo } from 'react';

export type TOrderReturnReplyFormValues = {
  repliedInstructions: string;
  repliedShippingLabelUploadId?: string | undefined;
  mustReturnItems: boolean;
};

export type TOrderReturnReplyFormBase = {
  doOrderReturnReplyAsync: TAgentOrderActionsTypes['DoOrderReturnReplyAsync'];
  doUploadAsync: TAgentUploadsTypes['DoUploadAsync'];
  orderReturn: TAgentOrderReturnCompleteFragment;
};

export type TOrderReturnReplyForm = TProps<false, TOrderReturnReplyFormBase, 'div'>;
export const ORDER_RETURN_REPLY_FORM_CLASS_NAME = 'wp-order-return-reply-form';

export const OrderReturnReplyForm = withCssToString(
  ORDER_RETURN_REPLY_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderReturnReplyForm>(
      ({ doOrderReturnReplyAsync, doUploadAsync, orderReturn, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_RETURN_REPLY_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TOrderReturnReplyFormValues>(
          () => ({
            repliedInstructions: '',
            repliedShippingLabelUploadId: undefined,
            mustReturnItems: true,
          }),
          [],
        );

        const handleSubmit = useCallback<TFormSubmit<TOrderReturnReplyFormValues>>(
          async (values) => {
            await doOrderReturnReplyAsync({
              orderReturnId: orderReturn.id,
              mustReturnItems: values.mustReturnItems,
              repliedInstructions: values.repliedInstructions,
              repliedShippingLabelUploadId: values.repliedShippingLabelUploadId ?? null,
            });
            return undefined;
          },
          [doOrderReturnReplyAsync, orderReturn.id],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TOrderReturnReplyFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              submitButtonText='Save'>
              <CheckBoxControl id='mustReturnItems' label='Must Return Items' />
              <ConditionalControl<boolean>
                subscription='mustReturnItems'
                predicate={(mustReturnItems): boolean => mustReturnItems}
                render={(): JSX.Element => (
                  <Fragment>
                    <TextAreaControl
                      id='repliedInstructions'
                      required={true}
                      label='Return Instructions'
                      maxLength={2048}
                    />
                    <UploadControl
                      doUploadAsync={doUploadAsync}
                      label='Shipping Label'
                      id='repliedShippingLabelUploadId'
                      required={false}
                    />
                  </Fragment>
                )}
              />
            </Form>
          </div>
        );
      },
    ),
  ),
);
