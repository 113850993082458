import { NetworkStatus } from '@apollo/client';
import type { CustomerGetCustomerOrdersDashboardQueryVariables } from '@src/gen/graphql/bindings';
import { useCustomerGetCustomerOrdersDashboardQuery } from '@src/gen/graphql/bindings';
import type { TCustomerOrdersDashboard } from '@src/gen/shared/data/dashboards';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useCustomerAnalyticsOrdersRouteParams } from '@src/modules/routing/customer';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TCustomerAnalyticsOrdersContext = {
  dashboard: TCustomerOrdersDashboard;
};

export const { Context: CustomerAnalyticsOrdersContext, useContext: useCustomerAnalyticsOrders } =
  createRequiredContext<TCustomerAnalyticsOrdersContext>();

export type TCustomerAnalyticsOrdersProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function CustomerAnalyticsOrdersProvider({
  LoaderComponent,
  children,
}: TCustomerAnalyticsOrdersProvider): JSX.Element {
  const routeParams = useCustomerAnalyticsOrdersRouteParams();

  const variables = useMemo<CustomerGetCustomerOrdersDashboardQueryVariables>(
    () => ({
      organizationId: routeParams.organizationId,
      locationId: routeParams.config.locationId,
      startTime: routeParams.config.startTime,
      endTime: routeParams.config.endTime,
    }),
    // @sort
    [
      routeParams.config.endTime,
      routeParams.config.locationId,
      routeParams.config.startTime,
      routeParams.organizationId,
    ],
  );

  const { data, error, networkStatus } = useCustomerGetCustomerOrdersDashboardQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const value = useMemo<TCustomerAnalyticsOrdersContext | undefined>(
    () =>
      isDef(data)
        ? {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            dashboard: data.getCustomerOrdersDashboard.dashboard as TCustomerOrdersDashboard,
          }
        : undefined,
    // @sort
    [data],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <CustomerAnalyticsOrdersContext.Provider value={value}>{children}</CustomerAnalyticsOrdersContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
