import { Typography } from '@src/components/appearance/basics/Typography';
import type { TNotification as TNotificationData } from '@src/gen/shared/data/notification';
import { maybeGetNotificationTextParts } from '@src/gen/shared/data/snippets';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$notificationGap',
});

export type TNotificationBase = {
  notification: TNotificationData;
};

export type TNotification = TProps<false, TNotificationBase, 'div'>;
export const NOTIFICATION_CLASS_NAME = 'wp-notification';

export const Notification = withCssToString(
  NOTIFICATION_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNotification>(({ notification, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, NOTIFICATION_CLASS_NAME), [className]);
      const parts = maybeGetNotificationTextParts(notification);

      if (parts.length === 0) {
        return <Fragment />;
      }

      return (
        <SDiv {...rest} className={joinedClassName} ref={ref}>
          {parts.map((part) => (
            <Typography.Notification key={part} text={part} />
          ))}
        </SDiv>
      );
    }),
  ),
);
