import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { ControlButton, ControlButtonLoader } from '@src/components/appearance/controls/ControlButton';
import type { TDrawer } from '@src/components/appearance/structure/Drawer';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { TAgentLocationForPickerFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { noop } from 'lodash';
import { forwardRef, memo, useMemo, useState } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

type TLocationPickerDrawerPanelBase = {
  buttonActionText?: string | undefined;
  drawerHeaderTitle?: string | undefined;
  loaderRef: UseInfiniteScrollHookRefCallback | null;
  locations: TAgentLocationForPickerFragment[];
  onBack?: (() => void) | undefined;
  onSelectLocation: (location: TAgentLocationForPickerFragment) => Promise<void>;
};

export type TLocationPickerDrawerPanel = TProps<false, TLocationPickerDrawerPanelBase, 'div'>;
export const LOCATION_PICKER_DRAWER_PANEL_CLASS_NAME = 'wp-location-picker-drawer-panel';

export const LocationPickerDrawerPanel = withCssToString(
  LOCATION_PICKER_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TLocationPickerDrawerPanel>(
      (
        { buttonActionText, drawerHeaderTitle, loaderRef, locations, onBack, onSelectLocation, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, LOCATION_PICKER_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [selectedLocation, setSelectedLocation] = useState<TAgentLocationForPickerFragment | null>(null);

        const buttonAction = useMemo<TDrawer['FooterBase']['buttonAction']>(
          () =>
            isDef(selectedLocation)
              ? {
                  isAsync: true,
                  onClick: async (): Promise<void> => await onSelectLocation(selectedLocation),
                  text: buttonActionText ?? 'Select Location',
                }
              : {
                  isAsync: false,
                  onClick: noop,
                  text: buttonActionText ?? 'Select Location',
                  variant: 'disabled',
                },
          [buttonActionText, onSelectLocation, selectedLocation],
        );

        const secondaryButtonAction = useMemo<TDrawer['FooterBase']['secondaryButtonAction']>(
          () =>
            isDef(onBack)
              ? {
                  isAsync: false,
                  onClick: onBack,
                  text: 'Go Back',
                  variant: 'secondary',
                }
              : undefined,
          [onBack],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title={drawerHeaderTitle ?? 'Select Location'} />
            <Drawer.ScrollContent>
              <Drawer.Group>
                {locations.map((loc) => (
                  <ControlButton
                    key={loc.id}
                    icon={loc.id === selectedLocation?.id ? 'apply' : undefined}
                    onClick={(): void => setSelectedLocation(loc)}
                    text={loc.name}
                  />
                ))}
              </Drawer.Group>
              {isDef(loaderRef) && <Illustrations.Spinner ref={loaderRef} />}
            </Drawer.ScrollContent>
            <Drawer.Footer buttonAction={buttonAction} secondaryButtonAction={secondaryButtonAction} />
          </Drawer.Panel>
        );
      },
    ),
  ),
);

export const LocationPickerDrawerPanelLoader = memo(
  forwardRef<HTMLDivElement, Pick<TLocationPickerDrawerPanelBase, 'buttonActionText' | 'drawerHeaderTitle'>>(
    ({ buttonActionText, drawerHeaderTitle }, ref): JSX.Element => (
      <Drawer.Panel ref={ref}>
        <Drawer.Header title={drawerHeaderTitle ?? 'Select Location'} />
        <Drawer.ScrollContentLoader>
          <Drawer.Group>
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
          </Drawer.Group>
        </Drawer.ScrollContentLoader>
        <Drawer.FooterLoader buttonText={buttonActionText ?? 'Select Location'} />
      </Drawer.Panel>
    ),
  ),
);
