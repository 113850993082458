import { Typography } from '@src/components/appearance/basics/Typography';
import { ActionButton, Button } from '@src/components/appearance/controls/Button';
import { BannerLoader } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import { ItemsBannerLoader } from '@src/components/appearance/fragments/ItemsBanner';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderOriginalCartProductCardLoader } from '@src/components/mixins/cards/OrderOriginalCartProductCard';
import { OrderPaymentCard } from '@src/components/mixins/cards/OrderPaymentCard';
import { OrderAttachmentsStructureGroup } from '@src/components/mixins/groups/OrderAttachmentsStructureGroup';
import { OrderBackOrdersStructureGroup } from '@src/components/mixins/groups/OrderBackOrdersStructureGroup';
import { OrderDocumentsStructureGroup } from '@src/components/mixins/groups/OrderDocumentsStructureGroup';
import { OrderOriginalCartStructureGroup } from '@src/components/mixins/groups/OrderOriginalCartStructureGroup';
import { OrderReturnsStructureGroup } from '@src/components/mixins/groups/OrderReturnsStructureGroup';
import { OrderShipmentsStructureGroup } from '@src/components/mixins/groups/OrderShipmentsStructureGroup';
import { OrderSnapshotStructureGroup } from '@src/components/mixins/groups/OrderSnapshotStructureGroup';
import { OrderStatusStructureGroup } from '@src/components/mixins/groups/OrderStatusStructureGroup';
import { OrderTrackingNumbersStructureGroup } from '@src/components/mixins/groups/OrderTrackingNumbersStructureGroup';
import { getOrderReference } from '@src/gen/shared/data/snippets';
import { EOrderStatus, getOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { isDef } from '@src/gen/shared/utils/types';
import { useAuthCustomer } from '@src/modules/auth/AuthProvider';
import { useCustomerOrderActions } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import { useCustomerOrder } from '@src/modules/data/customer/order/CustomerOrderProvider';
import { useCustomerOrderSnapshotReviewChrome } from '@src/modules/data/customer/order/CustomerOrderSnapshotReviewChromeProvider';
import { useEffect, useRef } from 'react';

export function CustomerOrderSummaryPart(): JSX.Element {
  const { order } = useCustomerOrder();
  return order.version === 1 ? <CustomerOrderSummaryPartV1 /> : <CustomerOrderSummaryPartV2 />;
}

export function CustomerOrderSummaryPartLoader(): JSX.Element {
  return (
    <Structure.ScrollContentLoader>
      <Structure.Stack>
        <Structure.GroupLoader hasTitle={true}>
          <BannerLoader />
          <BannerLoader />
          <ItemsBannerLoader count={4} />
        </Structure.GroupLoader>
        <Structure.GroupLoader hasTitle={true}>
          <BannerLoader />
        </Structure.GroupLoader>
        <Structure.GroupLoader hasTitle={true}>
          <BannerLoader />
        </Structure.GroupLoader>
        <Structure.GroupLoader hasTitle={true}>
          <ItemsBannerLoader count={4} />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
          <OrderOriginalCartProductCardLoader />
        </Structure.GroupLoader>
      </Structure.Stack>
    </Structure.ScrollContentLoader>
  );
}

function CustomerOrderSummaryPartV1(): JSX.Element {
  const { beginOrderProductView, downloadOrderAttachmentAsyncNotify, beginOrderTrackingHistoryView } =
    useCustomerOrderActions();
  const { order } = useCustomerOrder();
  const customer = useAuthCustomer();
  const orderReference = getOrderReference(order.location.order_code, order.reference_number);

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <OrderStatusStructureGroup order={order} organizationName={order.location.organization.name} />
        {(order.status !== EOrderStatus.CANCELED || order.order_attachments.length > 0) && (
          <OrderAttachmentsStructureGroup
            orderAttachments={order.order_attachments}
            downloadOrderAttachmentAsyncNotify={downloadOrderAttachmentAsyncNotify}
          />
        )}
        {(order.status !== EOrderStatus.CANCELED || order.order_tracking_numbers.length > 0) && (
          <OrderTrackingNumbersStructureGroup
            beginOrderTrackingHistoryView={beginOrderTrackingHistoryView}
            orderStatus={getOrderStatus(order.status)}
            orderTrackingNumbers={order.order_tracking_numbers}
          />
        )}
        {order.status !== EOrderStatus.CANCELED && (
          <Structure.Group title='Returns'>
            <Button
              onClick={(): void => {
                window.open(
                  `https://share.hsforms.com/1bnJBDJp3QWWFcKgpAaCRSg51xue?email=${encodeURIComponent(
                    customer.email,
                  )}&order_reference=${orderReference.replaceAll(/^# +/g, '')}`,
                  '_blank',
                );
              }}
              text='Initiate a Return'
              css={{ alignSelf: 'flex-start' }}
            />
            <Card.Container flush={true} variant='form'>
              <Card.ContentArea css={{ gap: '4px' }}>
                <Typography.Caption text='We are working on an improved experience for returns. In the meantime, use the button above to access a form.' />
                <Typography.Caption
                  text={`Provide your email (${customer.email}) and this order reference (${orderReference}).`}
                />
              </Card.ContentArea>
            </Card.Container>
          </Structure.Group>
        )}
        <OrderOriginalCartStructureGroup beginOrderProductView={beginOrderProductView} order={order} />
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function CustomerOrderSummaryPartV2(): JSX.Element {
  const { order, orderManager } = useCustomerOrder();
  const {
    beginOrderBackOrderProductView,
    beginOrderOriginalCartView,
    beginOrderReturnAdd,
    beginOrderReturnProductView,
    beginOrderReturnSeeInstructionsAsyncNotify,
    beginOrderShipmentProductView,
    beginOrderSnapshotEntryView,
    beginOrderSnapshotView,
    beginOrderTrackingHistoryView,
    doOrderBackOrderReplyAsyncNotify,
    doOrderReturnExecuteAsyncNotify,
  } = useCustomerOrderActions();
  const { beginOrderSnapshotReview } = useCustomerOrderSnapshotReviewChrome();

  const doOpenRef = useRef(
    orderManager.hasSnapshotPrebillPending()
      ? (): void =>
          beginOrderSnapshotReview({
            orderSnapshotId: orderManager.mustGetOrderSnapshotPrebillPending().orderSnapshot.id,
          })
      : null,
  );

  useEffect(() => {
    if (maybeGetOpenRequest() && isDef(doOpenRef.current)) {
      doOpenRef.current();
    }
  }, []);

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <OrderStatusStructureGroup order={order} organizationName={order.location.organization.name} />
        {orderManager.hasSnapshotPrebillPending() && (
          <Structure.Group title='Prebill - Awaiting Approval'>
            <ToolBar>
              <ActionButton
                action={{
                  isAsync: false,
                  onClick: (): void =>
                    beginOrderSnapshotReview({
                      orderSnapshotId: orderManager.mustGetOrderSnapshotPrebillPending().orderSnapshot.id,
                    }),
                  text: 'Review Prebill',
                }}
              />
            </ToolBar>
            <OrderSnapshotStructureGroup
              beginOrderSnapshotEntryPlanQuantityEdit={null}
              beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
              isSummary={true}
              orderBudgetAssistantManager={null}
              orderSnapshotManager={orderManager.mustGetOrderSnapshotPrebillPending()}
            />
          </Structure.Group>
        )}
        <OrderBackOrdersStructureGroup
          allOrderBackOrders={order.order_back_orders}
          beginOrderBackOrderProductView={beginOrderBackOrderProductView}
          doOrderBackOrderReplyAsyncNotify={doOrderBackOrderReplyAsyncNotify}
          mode='customer-awaiting'
        />
        <OrderReturnsStructureGroup
          allOrderReturns={order.order_returns}
          beginOrderReturnAdd={beginOrderReturnAdd}
          beginOrderReturnProductView={beginOrderReturnProductView}
          beginOrderReturnSeeInstructionsAsyncNotify={beginOrderReturnSeeInstructionsAsyncNotify}
          doOrderReturnExecuteAsyncNotify={doOrderReturnExecuteAsyncNotify}
          doesOrderHaveInvoice={isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())}
          mode='customer-awaiting'
        />
        <OrderDocumentsStructureGroup
          beginOrderOriginalCartView={beginOrderOriginalCartView}
          beginOrderSnapshotView={beginOrderSnapshotView}
          order={order}
          orderManager={orderManager}
        />
        <OrderShipmentsStructureGroup
          beginOrderShipmentProductView={beginOrderShipmentProductView}
          beginOrderTrackingHistoryView={beginOrderTrackingHistoryView}
          orderStatus={getOrderStatus(order.status)}
          orderShipments={order.order_shipments}
        />
        <OrderBackOrdersStructureGroup
          allOrderBackOrders={order.order_back_orders}
          beginOrderBackOrderProductView={beginOrderBackOrderProductView}
          doOrderBackOrderReplyAsyncNotify={doOrderBackOrderReplyAsyncNotify}
          mode='customer-other'
        />
        <OrderReturnsStructureGroup
          allOrderReturns={order.order_returns}
          beginOrderReturnAdd={beginOrderReturnAdd}
          beginOrderReturnProductView={beginOrderReturnProductView}
          beginOrderReturnSeeInstructionsAsyncNotify={beginOrderReturnSeeInstructionsAsyncNotify}
          doOrderReturnExecuteAsyncNotify={doOrderReturnExecuteAsyncNotify}
          doesOrderHaveInvoice={isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())}
          mode='customer-other'
        />
        {order.order_payments.length > 0 && (
          <Structure.Group title='Payments'>
            {order.order_payments.map((op) => (
              <OrderPaymentCard key={op.id} onDeleteAsyncNotify={null} orderPayment={op} />
            ))}
          </Structure.Group>
        )}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

export function maybeGetOpenRequest(): boolean {
  const hash = window.location.hash;
  const hasOpenRequest = hash === '#open';

  if (hasOpenRequest) {
    window.location.hash = '';
  }

  return hasOpenRequest;
}
