import { Avatar } from '@src/components/appearance/basics/Avatar';
import { Select } from '@src/components/appearance/controls/Select';
import { PrimaryNav } from '@src/components/appearance/structure/PrimaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { getFullName } from '@src/gen/shared/data/snippets';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import {
  useAuthCustomer,
  useAuthCustomerNextOrderLocations,
  useAuthCustomerOrganization,
} from '@src/modules/auth/AuthProvider';
import type { TAuthLocation, TAuthOrganization } from '@src/modules/auth/subject';
import {
  ECustomerRoutes,
  encodeCustomerAnalyticsOrdersPath,
  encodeCustomerNextOrderCartPath,
  encodeCustomerOrdersPath,
  encodeCustomerUserAccountPath,
  getCustomerRoutePageTitle,
  useCustomerRouteParams,
} from '@src/modules/routing/customer';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function CustomerNavPart(): JSX.Element {
  const customer = useAuthCustomer();
  const navigate = useNavigate();
  const routeParams = useCustomerRouteParams();

  const organization = useAuthCustomerOrganization(routeParams.organizationId);
  const nextOrderLocations = useAuthCustomerNextOrderLocations(organization.id);

  const message = useMemo(
    () =>
      `Welcome back, ${customer.first_name}${customer.organizations.length > 1 ? '.' : ` from ${organization.name}.`} `,
    // @sort
    [customer.first_name, customer.organizations.length, organization.name],
  );

  const activeNextOrderLocationId =
    routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_CATALOGS ||
    routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_FORMULARY ||
    routeParams.route === ECustomerRoutes.CUSTOMER_NEXT_ORDER_CART
      ? routeParams.locationId
      : undefined;

  const isOrdersActive =
    routeParams.route === ECustomerRoutes.CUSTOMER_ORDERS ||
    routeParams.route === ECustomerRoutes.CUSTOMER_ORDER_SUMMARY ||
    routeParams.route === ECustomerRoutes.CUSTOMER_ORDER_UPDATES_AND_SUPPORT;

  const handleOrdersClick = useCallback(
    () => navigate(encodeCustomerOrdersPath(organization.id)),
    // @sort
    [navigate, organization.id],
  );

  const handleAnalyticsClick = useCallback(
    (): void => {
      navigate(
        encodeCustomerAnalyticsOrdersPath(organization.id, {
          locationId: organization.acl.can_view_analytics
            ? null
            : ensureDef(organization.locations.find((loc) => loc.acl.can_view_analytics)).id,
          startTime: null,
          endTime: null,
        }),
      );
    },
    // @sort
    [navigate, organization.acl.can_view_analytics, organization.id, organization.locations],
  );

  const handleUserClick = useCallback(
    () => navigate(encodeCustomerUserAccountPath(organization.id)),
    // @sort
    [navigate, organization.id],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      return navigate(encodeCustomerOrdersPath(e.target.value));
    },
    [navigate],
  );

  const pageTitle = useMemo(
    () => getCustomerRoutePageTitle(routeParams.route, customer),
    [routeParams.route, customer],
  );

  return (
    <Structure.Nav
      message={message}
      pageTitle={pageTitle}
      headerChildren={
        customer.organizations.length > 1 ? (
          <Select value={organization.id} onChange={handleChange} variant='inverted'>
            {customer.organizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </Select>
        ) : null
      }
      footerChildren={
        <PrimaryNav.Item
          avatar={<Avatar firstName={customer.first_name} lastName={customer.last_name} />}
          active={
            routeParams.route === ECustomerRoutes.CUSTOMER_USER_ACCOUNT ||
            routeParams.route === ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS
          }
          onClick={handleUserClick}
          title={getFullName(customer)}
        />
      }>
      {nextOrderLocations.length > 0 && (
        <PrimaryNav.Group title='Next Order'>
          {nextOrderLocations.map((loc) => (
            <NextOrderPrimaryNavItem
              key={loc.id}
              activeNextOrderLocationId={activeNextOrderLocationId}
              organization={organization}
              location={loc}
            />
          ))}
        </PrimaryNav.Group>
      )}

      <PrimaryNav.Item active={isOrdersActive} onClick={handleOrdersClick} title='Orders' />
      {(organization.acl.can_view_analytics ||
        isDef(organization.locations.find((loc) => loc.acl.can_view_analytics))) && (
        <PrimaryNav.Item
          active={routeParams.route === ECustomerRoutes.CUSTOMER_ANALYTICS_ORDERS}
          onClick={handleAnalyticsClick}
          title='Analytics'
        />
      )}
    </Structure.Nav>
  );
}

function NextOrderPrimaryNavItem({
  activeNextOrderLocationId,
  organization,
  location,
}: {
  activeNextOrderLocationId: string | undefined;
  organization: TAuthOrganization;
  location: TAuthLocation;
}): JSX.Element {
  const navigate = useNavigate();

  const handleClick = useCallback(
    () => {
      navigate(encodeCustomerNextOrderCartPath(organization.id, location.id));
    },
    // @sort
    [navigate, location.id, organization.id],
  );

  return (
    <PrimaryNav.Item active={activeNextOrderLocationId === location.id} onClick={handleClick} title={location.name} />
  );
}
