import { ActionButton } from '@src/components/appearance/controls/Button';
import type { TTabSelectBase } from '@src/components/appearance/controls/TabSelect';
import { TabSelect } from '@src/components/appearance/controls/TabSelect';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { EOrderSnapshotType } from '@src/gen/shared/enums/orderSnapshotType';
import { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import {
  EAgentRoutes,
  encodeAgentOrderWorksheetBackOrdersPath,
  encodeAgentOrderWorksheetBaseOrdersPath,
  encodeAgentOrderWorksheetDashboardPath,
  encodeAgentOrderWorksheetPaymentsPath,
  encodeAgentOrderWorksheetPlanOrdersPath,
  encodeAgentOrderWorksheetProductsPath,
  encodeAgentOrderWorksheetReturnsPath,
  encodeAgentOrderWorksheetShipmentsAnalyzerPath,
  encodeAgentOrderWorksheetShipmentsPath,
  useAgentOrderWorksheetRouteParams,
} from '@src/modules/routing/agent';
import noop from 'lodash/noop';
import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentOrderWorksheetNavPart(): JSX.Element {
  const navigate = useNavigate();
  const routeParams = useAgentOrderWorksheetRouteParams();
  const { order, orderManager } = useAgentOrder();

  const {
    beginOrderCancel,
    beginOrderSnapshotPreviewAsyncNotify,
    beginOrderStatusEditV2,
    doOrderSnapshotRetractAsyncNotify,
    downloadOrderDashboardAsyncNotify,
  } = useAgentOrderActions();

  const handleTabSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      switch (e.target.value) {
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD:
          navigate(encodeAgentOrderWorksheetDashboardPath(routeParams.orderId));
          break;
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS:
          navigate(
            encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
              status: 'any',
              origin: 'any',
              baseSource: 'any',
              planSource: 'any',
              allowSubstitutions: 'any',
              savings: 'any',
              planStatus: 'any',
              search: '',
              flags: 'any',
            }),
          );
          break;
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS:
          navigate(encodeAgentOrderWorksheetBaseOrdersPath(routeParams.orderId));
          break;
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS:
          navigate(encodeAgentOrderWorksheetPlanOrdersPath(routeParams.orderId));
          break;
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS:
          navigate(
            encodeAgentOrderWorksheetPaymentsPath(routeParams.orderId, {
              type: 'any',
            }),
          );
          break;
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS:
          navigate(encodeAgentOrderWorksheetShipmentsPath(routeParams.orderId, { status: 'any' }));
          break;
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER:
          navigate(encodeAgentOrderWorksheetShipmentsAnalyzerPath(routeParams.orderId));
          break;
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS:
          navigate(encodeAgentOrderWorksheetBackOrdersPath(routeParams.orderId));
          break;
        case EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS:
          navigate(encodeAgentOrderWorksheetReturnsPath(routeParams.orderId));
          break;
      }
    },
    [navigate, routeParams.orderId],
  );

  return (
    <Structure.Content>
      <Structure.Stack
        css={{
          paddingTop: '$structureScrollContentPaddingMobile',
          '@desktop': { paddingTop: '$structureScrollContentPaddingDesktop' },
        }}>
        {orderManager.isCanceled() && <Banner accent='warning' icon='warning' message='This order is canceled.' />}
        {!orderManager.isCanceled() && (
          <Fragment>
            <ToolBar>
              <ActionButton
                action={{
                  isAsync: false,
                  onClick: beginOrderStatusEditV2,
                  text: 'Edit Status',
                }}
              />
              {orderManager.canIssueSnapshotPrebill() && (
                <ActionButton
                  action={{
                    isAsync: true,
                    onClick: async (): Promise<void> =>
                      await beginOrderSnapshotPreviewAsyncNotify({ type: EOrderSnapshotType.PREBILL }),
                    text: 'Issue Prebill',
                  }}
                />
              )}
              {orderManager.hasSnapshotPrebillPending() && (
                <ActionButton
                  action={{
                    isAsync: true,
                    onClick: async (): Promise<void> =>
                      await doOrderSnapshotRetractAsyncNotify({
                        orderSnapshotId: orderManager.mustGetOrderSnapshotPrebillPending().orderSnapshot.id,
                      }),
                    text: 'Retract Prebill',
                  }}
                />
              )}
              {orderManager.canIssueSnapshotInvoice() && (
                <ActionButton
                  action={{
                    isAsync: true,
                    onClick: async (): Promise<void> =>
                      await beginOrderSnapshotPreviewAsyncNotify({ type: EOrderSnapshotType.INVOICE }),
                    text: 'Issue Invoice',
                  }}
                />
              )}
              {orderManager.hasSnapshotInvoice() && (
                <ActionButton
                  action={{
                    isAsync: true,
                    onClick: async (): Promise<void> => await downloadOrderDashboardAsyncNotify({ orderId: order.id }),
                    text: 'Export for Dashboard',
                  }}
                />
              )}
              {!orderManager.isCanceled() && order.status !== EOrderStatus.COMPLETED && (
                <ActionButton
                  action={{
                    isAsync: false,
                    onClick: order.status !== EOrderStatus.CANCELED ? beginOrderCancel : noop,
                    text: 'Cancel Order',
                    variant: order.status !== EOrderStatus.CANCELED ? 'default' : 'disabled',
                  }}
                />
              )}
            </ToolBar>
            <TabSelect onChange={handleTabSelectChange} value={routeParams.route}>
              <option value={EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD}>Dashboard</option>
              <option value={EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS}>Products</option>
              <option value={EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS}>Base Orders</option>
              <option value={EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS}>Plan Orders</option>
              <option value={EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS}>Payments</option>
              <option value={EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS}>Shipments</option>
              <option value={EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS_ANALYZER}>Shipments Analyzer</option>
              <option value={EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS}>Back-Orders</option>
              <option value={EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS}>Returns</option>
            </TabSelect>
          </Fragment>
        )}
      </Structure.Stack>
    </Structure.Content>
  );
}
