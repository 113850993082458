import { NetworkStatus } from '@apollo/client';
import type {
  AgentWpxLegacyProductsSearchQuery,
  AgentWpxLegacyProductsSearchQueryVariables,
} from '@src/gen/graphql/bindings';
import { useAgentWpxLegacyProductsSearchQuery } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import type { TWpxSearchLegacyProductsResponse } from '@src/logic/internal/data/wpx';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT } from '@src/modules/graphql/utils';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TAgentProductsSearchContext = {
  searchResponse: TWpxSearchLegacyProductsResponse;
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export type TAgentProductsSearchConfig = Omit<AgentWpxLegacyProductsSearchQueryVariables, 'limit' | 'offset'>;

export const { Context: AgentProductsSearchContext, useContext: useAgentProductsSearch } =
  createRequiredContext<TAgentProductsSearchContext>();

export type TAgentProductsSearchProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  config: TAgentProductsSearchConfig;
}>;

export function AgentProductsSearchProvider({
  LoaderComponent,
  config,
  children,
}: TAgentProductsSearchProvider): JSX.Element {
  const { data, error, fetchMore, networkStatus } = useAgentWpxLegacyProductsSearchQuery({
    variables: {
      ...config,
      limit: DEFAULT_LIMIT,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          offset: isDef(data) ? castQuery(data).results.length : 0,
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          wpxSearchLegacyProducts: {
            ...castQuery(fetchMoreResult),
            results: [...castQuery(prev).results, ...castQuery(fetchMoreResult).results],
          },
        }),
      });

      return castQuery(newData).results.length;
    }, // @sort
    [data, fetchMore],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: isDef(data) ? castQuery(data).results.length : undefined,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TAgentProductsSearchContext | undefined>(
    () =>
      isDef(data)
        ? {
            searchResponse: castQuery(data),
            loaderRef: loaderRef,
          }
        : undefined, // @sort
    [data, loaderRef],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading && networkStatus !== NetworkStatus.setVariables ? (
    <AgentProductsSearchContext.Provider value={value}>{children}</AgentProductsSearchContext.Provider>
  ) : (
    <LoaderComponent />
  );
}

function castQuery(query: AgentWpxLegacyProductsSearchQuery): TWpxSearchLegacyProductsResponse {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return query.wpxSearchLegacyProducts as TWpxSearchLegacyProductsResponse;
}
