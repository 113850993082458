import { Chrome } from '@src/components/appearance/structure/Chrome';
import { OrderSnapshotStructureGroup } from '@src/components/mixins/groups/OrderSnapshotStructureGroup';
import type { AgentOrderSnapshotManager } from '@src/gen/shared/data/agentOrders';
import { maybeGetOrderSnapshotTypeLabel } from '@src/gen/shared/enums/orderSnapshotType';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderSnapshotChromePanelBase = {
  beginOrderSnapshotEntryView: TAgentOrderActionsTypes['BeginOrderSnapshotEntryView'];
  orderSnapshotManager: AgentOrderSnapshotManager;
  doIssueAsync?: (() => Promise<void>) | undefined;
};

export type TOrderSnapshotChromePanel = TProps<false, TOrderSnapshotChromePanelBase, 'div'>;
export const ORDER_SNAPSHOT_CHROME_PANEL_CLASS_NAME = 'wp-order-snapshot-chrome-panel';

export const OrderSnapshotChromePanel = withCssToString(
  ORDER_SNAPSHOT_CHROME_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderSnapshotChromePanel>(
      ({ beginOrderSnapshotEntryView, orderSnapshotManager, doIssueAsync, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_SNAPSHOT_CHROME_PANEL_CLASS_NAME),
          [className],
        );

        return (
          <Chrome.Panel {...rest} className={joinedClassName} ref={ref}>
            <Chrome.Header
              title={`${maybeGetOrderSnapshotTypeLabel(orderSnapshotManager.orderSnapshot.type)} Preview`}
            />
            <Chrome.ScrollContent>
              <OrderSnapshotStructureGroup
                beginOrderSnapshotEntryPlanQuantityEdit={null}
                beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
                isSummary={false}
                orderBudgetAssistantManager={null}
                orderSnapshotManager={orderSnapshotManager}
              />
            </Chrome.ScrollContent>
            {isDef(doIssueAsync) && (
              <Chrome.Footer
                buttonAction={{
                  isAsync: true,
                  onClick: doIssueAsync,
                  text: `Issue ${maybeGetOrderSnapshotTypeLabel(orderSnapshotManager.orderSnapshot.type)}`,
                }}
              />
            )}
          </Chrome.Panel>
        );
      },
    ),
  ),
);
