import { Banner } from '@src/components/appearance/fragments/Banner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderReturnCard } from '@src/components/mixins/cards/OrderReturnCard';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import { useMemo } from 'react';

export function AgentOrderWorksheetReturnsPart(): JSX.Element {
  const { order } = useAgentOrder();
  const {
    beginOrderReturnProductView,
    beginOrderReturnReply,
    doOrderReturnApplyAsyncNotify,
    doOrderReturnRemoveAsyncNotify,
    downloadOrderReturnPictureAsyncNotify,
  } = useAgentOrderActions();

  const waitingForInstructions = useMemo(
    () => order.order_returns.filter((or) => !isDef(or.replied_at) && !isDef(or.executed_at) && !isDef(or.applied_at)),
    [order.order_returns],
  );

  const waitingForShipment = useMemo(
    () => order.order_returns.filter((or) => isDef(or.replied_at) && !isDef(or.executed_at) && !isDef(or.applied_at)),
    [order.order_returns],
  );

  const waitingForAcknowledgment = useMemo(
    () => order.order_returns.filter((or) => isDef(or.replied_at) && isDef(or.executed_at) && !isDef(or.applied_at)),
    [order.order_returns],
  );

  const completed = useMemo(() => order.order_returns.filter((or) => isDef(or.applied_at)), [order.order_returns]);

  return (
    <Structure.ScrollContent skipSeparator={true}>
      <Structure.Stack>
        {order.order_returns.length === 0 && (
          <Banner icon='info' message='Returns will appear here when initiated by customers.' />
        )}
        {waitingForInstructions.length > 0 && (
          <Structure.Group title='Waiting for Instructions' variant='secondary'>
            {waitingForInstructions.map((or) => (
              <OrderReturnCard
                key={or.id}
                beginOrderReturnProductView={beginOrderReturnProductView}
                downloadPictureAsyncNotify={downloadOrderReturnPictureAsyncNotify}
                onApplyAsyncNotify={async (): Promise<void> =>
                  await doOrderReturnApplyAsyncNotify({ orderReturnId: or.id })
                }
                onDeleteAsyncNotify={async (): Promise<void> =>
                  await doOrderReturnRemoveAsyncNotify({ orderReturnId: or.id })
                }
                onExecuteAsyncNotify={null}
                onReply={(): void => beginOrderReturnReply({ orderReturnId: or.id })}
                onSeeInstructionsAsyncNotify={null}
                orderReturn={or}
                variant='agent'
              />
            ))}
          </Structure.Group>
        )}
        {waitingForShipment.length > 0 && (
          <Structure.Group title='Waiting for Customer to Ship' variant='secondary'>
            {waitingForShipment.map((or) => (
              <OrderReturnCard
                key={or.id}
                beginOrderReturnProductView={beginOrderReturnProductView}
                downloadPictureAsyncNotify={downloadOrderReturnPictureAsyncNotify}
                onApplyAsyncNotify={async (): Promise<void> =>
                  await doOrderReturnApplyAsyncNotify({ orderReturnId: or.id })
                }
                onDeleteAsyncNotify={async (): Promise<void> =>
                  await doOrderReturnRemoveAsyncNotify({ orderReturnId: or.id })
                }
                onExecuteAsyncNotify={null}
                onReply={(): void => beginOrderReturnReply({ orderReturnId: or.id })}
                onSeeInstructionsAsyncNotify={null}
                orderReturn={or}
                variant='agent'
              />
            ))}
          </Structure.Group>
        )}
        {waitingForAcknowledgment.length > 0 && (
          <Structure.Group title='Waiting for Wellplaece to Confirm' variant='secondary'>
            {waitingForAcknowledgment.map((or) => (
              <OrderReturnCard
                key={or.id}
                beginOrderReturnProductView={beginOrderReturnProductView}
                downloadPictureAsyncNotify={downloadOrderReturnPictureAsyncNotify}
                onApplyAsyncNotify={async (): Promise<void> =>
                  await doOrderReturnApplyAsyncNotify({ orderReturnId: or.id })
                }
                onDeleteAsyncNotify={async (): Promise<void> =>
                  await doOrderReturnRemoveAsyncNotify({ orderReturnId: or.id })
                }
                onExecuteAsyncNotify={null}
                onReply={(): void => beginOrderReturnReply({ orderReturnId: or.id })}
                onSeeInstructionsAsyncNotify={null}
                orderReturn={or}
                variant='agent'
              />
            ))}
          </Structure.Group>
        )}
        {completed.length > 0 && (
          <Structure.Group title='Completed' variant='secondary'>
            {completed.map((or) => (
              <OrderReturnCard
                key={or.id}
                beginOrderReturnProductView={beginOrderReturnProductView}
                downloadPictureAsyncNotify={downloadOrderReturnPictureAsyncNotify}
                onApplyAsyncNotify={async (): Promise<void> =>
                  await doOrderReturnApplyAsyncNotify({ orderReturnId: or.id })
                }
                onDeleteAsyncNotify={async (): Promise<void> =>
                  await doOrderReturnRemoveAsyncNotify({ orderReturnId: or.id })
                }
                onExecuteAsyncNotify={null}
                onReply={(): void => beginOrderReturnReply({ orderReturnId: or.id })}
                onSeeInstructionsAsyncNotify={null}
                orderReturn={or}
                variant='agent'
              />
            ))}
          </Structure.Group>
        )}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}
