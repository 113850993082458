import { useRender } from '@src/logic/internal/utils/utils';
import { useEffect, useRef } from 'react';

export const BREAKPOINT_TABLET = 769;
export const BREAKPOINT_DESKTOP = 1025;
export const BREAKPOINT_LARGE_DESKTOP = 1921;

export const CONTAINER_MEDIA_TABLET = '@container (min-width: 400px)';
export const CONTAINER_MEDIA_DESKTOP = '@container (min-width: 740px)';

export const DEFAULT_ROOT_MARGIN = '0px 0px 512px 0px';

export enum EBreakpoints {
  PHONE = 'PHONE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
  LARGE_DESKTOP = 'LARGE_DESKTOP',
}

export function getBreakpoint(): EBreakpoints {
  if (window.innerWidth >= BREAKPOINT_LARGE_DESKTOP) {
    return EBreakpoints.LARGE_DESKTOP;
  } else if (window.innerWidth >= BREAKPOINT_DESKTOP) {
    return EBreakpoints.DESKTOP;
  } else if (window.innerWidth >= BREAKPOINT_TABLET) {
    return EBreakpoints.TABLET;
  } else {
    return EBreakpoints.PHONE;
  }
}

export function useBreakpoint(): EBreakpoints {
  const breakpointRef = useRef(getBreakpoint());
  const render = useRender();

  useEffect(() => {
    const handleResize = (): void => {
      const newBreakpoint = getBreakpoint();

      if (breakpointRef.current !== newBreakpoint) {
        breakpointRef.current = newBreakpoint;
        render();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpointRef, render]);

  return breakpointRef.current;
}

export function useIsDesktop(): boolean {
  const breakpoint = useBreakpoint();
  return breakpoint === EBreakpoints.DESKTOP || breakpoint === EBreakpoints.LARGE_DESKTOP;
}
