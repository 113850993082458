import coverThumbJpg from '@src/assets/cover-thumb.jpg';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import { useConfig } from '@src/modules/config/ConfigProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';

const SImg = styled('img', {
  variants: {
    fallback: {
      false: {
        objectFit: 'contain',
      },
      true: {
        objectFit: 'cover',
      },
    },
  },
});

export type TImageAssetBase = {
  alt: string;
  imageAssetPath?: string | null | undefined;
};

export type TImageAsset = Omit<TProps<false, TImageAssetBase, 'img'>, 'src'>;
export const IMAGE_ASSET_CLASS_NAME = 'wp-image-asset';

export const ImageAsset = withCssToString(
  IMAGE_ASSET_CLASS_NAME,
  memo(
    forwardRef<HTMLImageElement, TImageAsset>(({ imageAssetPath, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, IMAGE_ASSET_CLASS_NAME), [className]);
      const { getPublicImageUrl } = useConfig();
      const [hasError, setHasError] = useState(false);
      const fallback = !isDef(imageAssetPath) || hasError;
      const handleError = useCallback(() => setHasError(true), []);

      return (
        <SImg
          {...rest}
          className={joinedClassName}
          fallback={fallback}
          onError={handleError}
          ref={ref}
          src={fallback ? coverThumbJpg : getPublicImageUrl(imageAssetPath)}
        />
      );
    }),
  ),
);
