import { Drawer } from '@src/components/appearance/structure/Drawer';
import { DateRangePickerDrawerPanel } from '@src/components/mixins/drawers/DateRangePickerDrawerPanel';
import type { TDateRange } from '@src/gen/shared/data/dateRanges';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TDateRangePickerDrawerTypes = {
  BeginLocationPickCallback: (range: TDateRange) => void;
  BeginLocationPickArgs: {
    callback: TDateRangePickerDrawerTypes['BeginLocationPickCallback'];
  };
  BeginLocationPick: (args: TDateRangePickerDrawerTypes['BeginLocationPickArgs']) => void;
};

export type TDateRangePickerDrawerContext = {
  beginDateRangePick: TDateRangePickerDrawerTypes['BeginLocationPick'];
};

export const { Context: DateRangePickerDrawerContext, useContext: useDateRangePickerDrawer } =
  createRequiredContext<TDateRangePickerDrawerContext>();

export function DateRangePickerDrawerProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const [state, setState] = useState<TDateRangePickerDrawerTypes['BeginLocationPickArgs'] | undefined>(undefined);

  const handleOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setState(undefined);
    }
  }, []);

  const handleCallback = useCallback<TDateRangePickerDrawerTypes['BeginLocationPickCallback']>(
    (range): void => {
      state?.callback(range);
      setState(undefined);
    },
    [state],
  );

  const value = useMemo<TDateRangePickerDrawerContext>(
    () => ({
      beginDateRangePick: setState,
    }),
    [],
  );

  return (
    <DateRangePickerDrawerContext.Provider value={value}>
      <Drawer.Root open={isDef(state)} onOpenChange={handleOpenChange}>
        {isDef(state) && <DateRangePickerDrawerPanel callback={handleCallback} />}
      </Drawer.Root>
      {children}
    </DateRangePickerDrawerContext.Provider>
  );
}
