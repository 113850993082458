import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import type { TOptional } from '@src/gen/shared/utils/types';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TIcon, TProps } from '@src/modules/design/theme';
import { getIconComponent, styled, theme } from '@src/modules/design/theme';
import { omit } from 'lodash';
import { Fragment, forwardRef, memo, useCallback, useMemo, useState } from 'react';

const SButton = styled('button', {
  all: 'unset',

  alignItems: 'center',
  borderRadius: '$iconButton',
  borderStyle: '$regular',
  borderWidth: '$regular',
  display: 'flex',
  flexShrink: 0,
  height: '$iconButtonSize',
  justifyContent: 'center',
  position: 'relative',
  width: '$iconButtonSize',

  variants: {
    variant: {
      default: {
        backgroundColor: '$iconButtonDefaultBackground',
        borderColor: '$iconButtonDefaultBorder',
        color: '$iconButtonDefaultText',
        cursor: 'pointer',

        '&:hover': {
          borderColor: '$iconButtonDefaultHoverBorder',
        },

        '&:focus': {
          boxShadow: '$secondaryFocus',
        },

        '&:active': {
          borderColor: '$iconButtonDefaultActiveBorder',
          color: '$iconButtonDefaultActiveText',
        },
      },
      disabled: {
        backgroundColor: '$iconButtonDisabledBackground',
        borderColor: '$iconButtonDisabledBorder',
        borderStyle: '$disabled',
        color: '$iconButtonDisabledText',
        cursor: 'not-allowed',
      },
      inverted: {
        backgroundColor: '$iconButtonInvertedBackground',
        borderColor: '$iconButtonInvertedBackground',
        color: '$iconButtonInvertedText',
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: '$iconButtonInvertedHoverBackground',
          borderColor: '$iconButtonInvertedHoverBackground',
        },

        '&:focus': {
          boxShadow: '$invertedFocus',
        },

        '&:active': {
          backgroundColor: '$iconButtonInvertedActiveBackground',
          borderColor: '$iconButtonInvertedActiveBackground',
          color: '$iconButtonInvertedActiveText',
        },
      },
      loading: {
        backgroundColor: '$iconButtonLoadingBackground',
        borderColor: '$iconButtonLoadingBorder',
        color: '$iconButtonLoadingText',
        cursor: 'default',
      },
    },
  },
});

const SAnnotation = styled('span', {
  backgroundColor: '$white',
  borderColor: '$iconButtonDefaultBorder',
  borderRadius: '$iconButton',
  borderStyle: '$regular',
  borderWidth: '$regular',
  color: '$iconButtonDefaultText',
  minWidth: '18px',
  display: 'block',
  padding: '2px',
  position: 'absolute',
  right: '-9px',
  text: '$annotation',
  top: '-9px',
  lineHeight: '100%',
  textAlign: 'center',
  textTransform: 'uppercase',
});

export type TIconButtonBase = {
  annotation?: string | undefined;
  icon: TIcon;
  variant?: 'default' | 'disabled' | 'inverted' | 'loading' | undefined;
};

export type TIconButton = Omit<TProps<false, TIconButtonBase, 'button'>, 'disabled'>;
export type TSyncIconButtonAction = TIconButtonBase & { isAsync: false; onClick: () => void };
export type TAsyncIconButtonAction = TIconButtonBase & { isAsync: true; onClick: () => Promise<void> };
export type TIconButtonAction = TAsyncIconButtonAction | TSyncIconButtonAction;
export const ICON_BUTTON_CLASS_NAME = 'wp-icon-button';

export function getIconButtonVariant(options: {
  base: 'default' | 'inverted';
  disabled: boolean;
  loading: boolean;
}): 'default' | 'disabled' | 'inverted' | 'loading' {
  if (options.disabled) {
    return 'disabled';
  } else if (options.loading) {
    return 'loading';
  } else {
    return options.base;
  }
}

export function getAvailableIconButtonActions(
  iconButtonActions: (TOptional<TAsyncIconButtonAction, 'onClick'> | TOptional<TSyncIconButtonAction, 'onClick'>)[],
): TIconButtonAction[] {
  return iconButtonActions.filter(
    (iconButtonAction): iconButtonAction is TIconButtonAction => iconButtonAction.onClick !== undefined,
  );
}

export const IconButton = withCssToString(
  ICON_BUTTON_CLASS_NAME,
  memo(
    forwardRef<HTMLButtonElement, TIconButton>(
      ({ annotation, icon, variant, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ICON_BUTTON_CLASS_NAME), [className]);
        const IconComponent = getIconComponent(icon);

        return (
          <SButton
            {...rest}
            className={joinedClassName}
            disabled={variant === 'disabled' || variant === 'loading'}
            ref={ref}
            variant={variant ?? 'default'}>
            {variant === 'loading' ? (
              <Illustrations.Spinner />
            ) : (
              <Fragment>
                <IconComponent
                  height={theme.sizes.iconButtonIconSize.value}
                  width={theme.sizes.iconButtonIconSize.value}
                />
                {isDef(annotation) && <SAnnotation>{annotation}</SAnnotation>}
              </Fragment>
            )}
          </SButton>
        );
      },
    ),
  ),
);

export type TSyncActionIconButtonBase = { action: TSyncIconButtonAction };
export type TSyncActionIconButton = Omit<TProps<false, TSyncActionIconButtonBase, 'button'>, 'disabled'>;

export const SyncActionIconButton = memo(
  forwardRef<HTMLButtonElement, TSyncActionIconButton>(({ action, className, ...rest }, ref): JSX.Element => {
    return <IconButton {...rest} {...omit(action, 'isAsync')} className={className} ref={ref} />;
  }),
);

export type TAsyncActionIconButtonBase = { action: TAsyncIconButtonAction };
export type TAsyncActionIconButton = Omit<TProps<false, TAsyncActionIconButtonBase, 'button'>, 'disabled'>;

export const AsyncActionIconButton = memo(
  forwardRef<HTMLButtonElement, TAsyncActionIconButton>(({ action, className, ...rest }, ref): JSX.Element => {
    const [loading, setLoading] = useState(false);

    const handleClickAsync = useCallback(async () => {
      try {
        setLoading(true);
        await action.onClick();
      } finally {
        setLoading(false);
      }
    }, [action]);

    const handleClick = useCallback(() => {
      void handleClickAsync();
    }, [handleClickAsync]);

    return (
      <IconButton
        {...rest}
        {...omit(action, 'isAsync')}
        className={className}
        onClick={handleClick}
        ref={ref}
        variant={loading ? 'loading' : action.variant}
      />
    );
  }),
);

export type TActionIconButtonBase = { action: TIconButtonAction };
export type TActionIconButton = Omit<TProps<false, TActionIconButtonBase, 'button'>, 'disabled'>;

export const ActionIconButton = memo(
  forwardRef<HTMLButtonElement, TActionIconButton>(({ action, className, ...rest }, ref): JSX.Element => {
    return action.isAsync ? (
      <AsyncActionIconButton {...rest} action={action} className={className} ref={ref} />
    ) : (
      <SyncActionIconButton {...rest} action={action} className={className} ref={ref} />
    );
  }),
);
