import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderOriginalCartProductCard } from '@src/components/mixins/cards/OrderOriginalCartProductCard';
import type {
  TAgentOrderCompleteFragment,
  TAgentOrderProductBaseFragment,
  TCustomerOrderCompleteFragment,
  TCustomerOrderProductBaseFragment,
} from '@src/gen/graphql/bindings';
import { getOrderReference } from '@src/gen/shared/data/snippets';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderPlacedStructureGroupBase = {
  beginOrderProductView:
    | TAgentOrderActionsTypes['BeginOrderProductView']
    | TCustomerOrderActionsTypes['BeginOrderProductView'];
  order: Pick<
    TAgentOrderCompleteFragment | TCustomerOrderCompleteFragment,
    'created_at' | 'creator' | 'is_emergency' | 'location' | 'reference_number' | 'status'
  > & {
    order_products: (TAgentOrderProductBaseFragment | TCustomerOrderProductBaseFragment)[];
  };
};

export type TOrderPlacedStructureGroup = TProps<false, TOrderPlacedStructureGroupBase, 'div'>;
export const ORDER_PLACED_STRUCTURE_GROUP = 'wp-order-placed-structure-group';

export const OrderPlacedStructureGroup = withCssToString(
  ORDER_PLACED_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderPlacedStructureGroup>(
      ({ beginOrderProductView, order, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_PLACED_STRUCTURE_GROUP), [className]);

        const orderRef = useMemo(
          () => getOrderReference(order.location.order_code, order.reference_number),
          // @sort
          [order.location.order_code, order.reference_number],
        );

        const items = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Order Ref.',
              text: orderRef,
            },
            {
              caption: 'Placed By',
              text: `${order.creator.first_name} ${order.creator.last_name}`,
            },
            {
              caption: 'Placed On',
              text: { asDate: 'on', at: order.created_at },
            },
          ],
          // @sort
          [orderRef, order.created_at, order.creator.first_name, order.creator.last_name],
        );

        return (
          <Structure.Group {...rest} className={joinedClassName} ref={ref}>
            <ItemsBanner items={items} />
            {order.order_products.map((op) => (
              <OrderOriginalCartProductCard
                key={op.product_id}
                onClick={(): void => beginOrderProductView({ productId: op.product_id })}
                orderProduct={op}
              />
            ))}
          </Structure.Group>
        );
      },
    ),
  ),
);
