import { NetworkStatus } from '@apollo/client';
import type {
  AgentCustomersUsersWithStatisticsQueryVariables,
  TAgentCustomersUserBaseWithStatisticsFragment,
} from '@src/gen/graphql/bindings';
import { useAgentCustomersUsersWithStatisticsQuery } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, mergeGetMore } from '@src/modules/graphql/utils';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TAgentCustomersUsersWithStatisticsContext = {
  users: TAgentCustomersUserBaseWithStatisticsFragment[];
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const { Context: AgentCustomersUsersWithStatisticsContext, useContext: useAgentCustomersUsersWithStatistics } =
  createRequiredContext<TAgentCustomersUsersWithStatisticsContext>();

export type TAgentCustomersUsersWithStatisticsProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function AgentCustomersUsersWithStatisticsWithStatisticsProvider({
  LoaderComponent,
  children,
}: TAgentCustomersUsersWithStatisticsProvider): JSX.Element {
  const variables = useMemo<AgentCustomersUsersWithStatisticsQueryVariables>(
    () => ({
      limit: DEFAULT_LIMIT,
      offset: 0,
    }),
    // @sort
    [],
  );

  const { data, error, fetchMore, networkStatus } = useAgentCustomersUsersWithStatisticsQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          offset: ensureDef(data?.users.length),
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          users: mergeGetMore(prev.users, fetchMoreResult.users),
        }),
      });

      return newData.users.length;
    },
    // @sort
    [fetchMore, data?.users.length],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.users.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TAgentCustomersUsersWithStatisticsContext | undefined>(
    () =>
      isDef(data)
        ? {
            users: data.users,
            loaderRef: loaderRef,
          }
        : undefined,
    // @sort
    [data, loaderRef],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentCustomersUsersWithStatisticsContext.Provider value={value}>
      {children}
    </AgentCustomersUsersWithStatisticsContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
