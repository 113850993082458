import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Control } from '@src/components/appearance/controls/Control';
import { THEME_COLORS } from '@src/gen/shared/utils/design';
import { newStringValidator } from '@src/logic/internal/data/validation';
import { styled } from '@src/modules/design/theme';
import type { ComponentProps } from 'react';
import { memo, useMemo } from 'react';
import { useField } from 'react-final-form';

export type TTextBoxBase = {
  onChange: NonNullable<ComponentProps<'input'>['onChange']>;
  value: string;
};

export const TextBox = memo(
  styled('input', {
    all: 'unset',

    alignItems: 'center',
    backgroundColor: '$textBoxBackground',
    backgroundPosition: 'top 50% right 12px',
    backgroundRepeat: 'no-repeat',
    borderColor: '$textBoxBorder',
    borderRadius: '$textBox',
    borderStyle: '$regular',
    borderWidth: '$regular',
    height: '$textBoxHeight',
    overflow: 'hidden',
    paddingX: '$textBoxPaddingX',
    position: 'relative',
    text: '$small',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:hover': {
      borderColor: '$textBoxHoverBorder',
    },

    '&:focus': {
      boxShadow: '$secondaryFocus',
    },

    '&::placeholder': {
      color: '$textBoxPlaceholderText',
    },

    '&[disabled]': {
      borderColor: '$textBoxDisabledBorder',
      borderStyle: '$disabled',
      color: '$textBoxDisabledText',
      cursor: 'not-allowed',

      '&::placeholder': {
        color: '$textBoxDisabledText',
      },
    },

    variants: {
      icon: {
        search: {
          backgroundImage: Illustrations.getMagnifyingGlassDataUrl(THEME_COLORS.Black),
          paddingRight: '$textBoxIconPaddingRight',

          '&[disabled]': {
            backgroundImage: Illustrations.getMagnifyingGlassDataUrl(THEME_COLORS.Gray4),
          },
        },
      },
    },
  }),
);

export type TTextBoxControl = {
  orderCode?: boolean | undefined;
  email?: boolean | undefined;
  phoneNumber?: boolean | undefined;
  id: string;
  label?: string | undefined;
  placeholder?: string | undefined;
  required?: boolean | undefined;
  stripeCustomerId?: boolean | undefined;
};

export function TextBoxControl({
  orderCode,
  email,
  id,
  label,
  placeholder,
  required,
  stripeCustomerId,
  phoneNumber,
}: TTextBoxControl): JSX.Element {
  const {
    input: { onBlur, onChange, onFocus, value },
    meta: { error, submitting, visited }, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  } = useField<string>(id, {
    subscription: {
      error: true,
      submitting: true,
      value: true,
      visited: true,
    },
    validate: newStringValidator(
      required === true,
      email === true,
      orderCode === true,
      stripeCustomerId === true,
      phoneNumber === true,
    ),
  });

  const finalError = useMemo(
    () => (visited === true && typeof error === 'string' && error !== '' ? error : null),
    [error, visited],
  );

  return (
    <Control error={finalError} id={id} required={required} label={label}>
      <TextBox
        disabled={submitting}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        value={value}
      />
    </Control>
  );
}
