export function maybeGetEmailTokenHash(): string | null {
  const EMAIL_TOKEN_PREFIX = '#emailToken-';
  const hash = window.location.hash;

  if (!hash.startsWith(EMAIL_TOKEN_PREFIX)) {
    return null;
  }

  window.location.hash = '';
  return hash.substring(EMAIL_TOKEN_PREFIX.length);
}
