import { IconButton } from '@src/components/appearance/controls/IconButton';
import { SecondaryNav, type TSecondaryNavTab } from '@src/components/appearance/structure/SecondaryNav';
import { getFullName } from '@src/gen/shared/data/snippets';
import { useAuth, useAuthCustomer } from '@src/modules/auth/AuthProvider';
import {
  ECustomerRoutes,
  encodeCustomerUserAccountPath,
  encodeCustomerUserNotificationsPath,
  useCustomerUserRouteParams,
} from '@src/modules/routing/customer';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function CustomerUserNavPart(): JSX.Element {
  const customer = useAuthCustomer();
  const { doSignOut } = useAuth();
  const tabs = useTabs();

  return (
    <SecondaryNav
      IconButtonElement={<IconButton icon='exit' onClick={doSignOut} />}
      tabs={tabs}
      title={getFullName(customer)}
    />
  );
}

function useTabs(): TSecondaryNavTab[] {
  const routeParams = useCustomerUserRouteParams();
  const navigate = useNavigate();

  return useMemo<TSecondaryNavTab[]>(
    () => [
      {
        active: routeParams.route === ECustomerRoutes.CUSTOMER_USER_ACCOUNT,
        title: 'My Account',
        onClick: () => navigate(encodeCustomerUserAccountPath(routeParams.organizationId)),
      },
      {
        active: routeParams.route === ECustomerRoutes.CUSTOMER_USER_NOTIFICATIONS,
        title: 'Notifications',
        onClick: () => navigate(encodeCustomerUserNotificationsPath(routeParams.organizationId)),
      },
    ],
    // @sort
    [navigate, routeParams],
  );
}
