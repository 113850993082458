import { Typography } from '@src/components/appearance/basics/Typography';
import { TertiaryNav } from '@src/components/appearance/structure/TertiaryNav';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { useCustomerNextOrderSearch } from '@src/modules/data/customer/nextOrder/CustomerNextOrderSearchProvider';
import { styled } from '@src/modules/design/theme';
import {
  encodeCustomerEncodeNextOrderCatalogsPath,
  useCustomerNextOrderCatalogsRouteParams,
  useCustomerRouteLocation,
  useCustomerRouteOrganization,
} from '@src/modules/routing/customer';
import { useNavigate } from 'react-router-dom';

const SFiltersDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '24px',
  hideScrollbars: true,
  overflow: 'scroll',
  padding: '0px',
});

const SFiltersInnerDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  alignItems: 'stretch',
});

export function CustomerNextOrderCatalogsFiltersPart(): JSX.Element {
  const location = useCustomerRouteLocation();
  const navigate = useNavigate();
  const organization = useCustomerRouteOrganization();
  const routeParams = useCustomerNextOrderCatalogsRouteParams();
  const { searchResponse } = useCustomerNextOrderSearch();

  return (
    <SFiltersDiv>
      {isDef(searchResponse.sourceAggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Catalog' css={{ paddingBottom: '4px' }} />
          {searchResponse.sourceAggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                navigate(
                  encodeCustomerEncodeNextOrderCatalogsPath(organization.id, location.id, {
                    ...routeParams.config,
                    sourceFilter: bucket.value,
                  }),
                )
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
      {isDef(searchResponse.categoryName01Aggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Category' css={{ paddingBottom: '4px' }} />
          {searchResponse.categoryName01Aggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                navigate(
                  encodeCustomerEncodeNextOrderCatalogsPath(organization.id, location.id, {
                    ...routeParams.config,
                    categoryName01Filter: bucket.value,
                  }),
                )
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
      {isDef(searchResponse.categoryName02Aggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Sub-Category' css={{ paddingBottom: '4px' }} />
          {searchResponse.categoryName02Aggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                navigate(
                  encodeCustomerEncodeNextOrderCatalogsPath(organization.id, location.id, {
                    ...routeParams.config,
                    categoryName02Filter: bucket.value,
                  }),
                )
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
      {isDef(searchResponse.manufacturerNameAggregation) && (
        <SFiltersInnerDiv>
          <Typography.Cta text='Refine by Manufacturer' css={{ paddingBottom: '4px' }} />
          {searchResponse.manufacturerNameAggregation.buckets.map((bucket) => (
            <TertiaryNav.AlternateItem
              active={false}
              key={bucket.value}
              title={`${maybeGetSourceName(bucket.value)} (${bucket.count})`}
              onClick={(): void =>
                navigate(
                  encodeCustomerEncodeNextOrderCatalogsPath(organization.id, location.id, {
                    ...routeParams.config,
                    manufacturerNameFilter: bucket.value,
                  }),
                )
              }
            />
          ))}
        </SFiltersInnerDiv>
      )}
    </SFiltersDiv>
  );
}
