import { Provider as RxToastProvider, Root as RxToastRoot, Viewport as RxToastViewport } from '@radix-ui/react-toast';
import { Typography } from '@src/components/appearance/basics/Typography';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled, styledKeyframes } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { createContext, forwardRef, memo, useCallback, useContext, useMemo } from 'react';

export type TToast = {
  HeaderBase: {
    title: string;
    notDismissable?: boolean | undefined;
  };
  RootBase: {
    onOpenChange: (open: boolean) => void;
    open: boolean;
  };

  Header: TProps<false, TToast['HeaderBase'], 'div'>;
  Root: PropsWithChildren<TToast['RootBase']>;
};

export const TOAST_CLASS_NAMES = {
  Header: 'wp-toast-header',
  Notification: 'wp-toast-notification',
};

type TRootContext = { doToastClose: () => void };
const RootContext = createContext<TRootContext | undefined>(undefined);

const Panel = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$toastContentNeutralBackground',
  borderColor: '$toastContentNeutralBorder',
  borderRadius: '$toastContent',
  borderStyle: '$regular',
  borderWidth: '$regular',
  display: 'flex',
  flexDirection: 'column',
  gap: '$toastContentGap',
  padding: '$toastContentPadding',

  variants: {
    accent: {
      neutral: {
        // do nothing
      },
      error: {
        backgroundColor: '$toastContentErrorBackground',
        borderColor: '$toastContentErrorBorder',
      },
    },
  },
});

const SHeaderDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

const Header = withCssToString(
  TOAST_CLASS_NAMES.Header,
  memo(
    forwardRef<HTMLDivElement, TToast['Header']>(({ title, notDismissable, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TOAST_CLASS_NAMES.Header), [className]);
      const rootContext = useContext(RootContext);
      const doToastClose = useCallback(() => rootContext?.doToastClose(), [rootContext]);

      return (
        <SHeaderDiv {...rest} className={joinedClassName} ref={ref}>
          <Typography.Cta expanding={true} rigid={true} text={title} />
          {notDismissable !== true && <IconButton icon='close' onClick={doToastClose} />}
        </SHeaderDiv>
      );
    }),
  ),
);

const SRootRxToastRoot = styled(RxToastRoot, {
  boxShadow: '$toastNotification',

  '&[data-state="open"]': {
    animation: `${styledKeyframes.swipeIn.toString()} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${styledKeyframes.fadeOut.toString()} 100ms ease-in`,
  },
  '&[data-swipe="move"]': {
    transform: 'translateX(var(--radix-toast-swipe-move-x))',
  },
  '&[data-swipe="cancel"]': {
    transform: 'translateX(0)',
    transition: 'transform 200ms ease-out',
  },
  '&[data-swipe="end"]': {
    animation: `${styledKeyframes.swipeOut.toString()} 100ms ease-out`,
  },
});

const SRootRxToastViewport = styled(RxToastViewport, {
  alignItems: 'stretch',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '$toastViewportGap',
  listStyle: 'none',
  maxWidth: '100vw',
  outline: 'none',
  padding: '$toastViewportPadding',
  position: 'fixed',
  right: 0,
  width: '$toastViewportWidth',
  zIndex: '$toast',
});

function Root({ open, onOpenChange, children }: TToast['Root']): JSX.Element {
  const value = useMemo<TRootContext>(
    () => ({
      doToastClose: () => onOpenChange(false),
    }),
    [onOpenChange],
  );

  return (
    <RootContext.Provider value={value}>
      <RxToastProvider duration={120000}>
        <SRootRxToastRoot onOpenChange={onOpenChange} open={open}>
          {children}
        </SRootRxToastRoot>
        <SRootRxToastViewport />
      </RxToastProvider>
    </RootContext.Provider>
  );
}

export const Toast = { Panel, Header, Root };
