import { Typography } from '@src/components/appearance/basics/Typography';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

export type TNotificationsEmptyAnnouncement = TProps<false, TEmptyObject, 'div'>;
export const NOTIFICATIONS_EMPTY_ANNOUNCEMENT_CLASS_NAME = 'wp-notifications-empty-announcement';

export const NotificationsEmptyAnnouncement = withCssToString(
  NOTIFICATIONS_EMPTY_ANNOUNCEMENT_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNotificationsEmptyAnnouncement>(({ className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, NOTIFICATIONS_EMPTY_ANNOUNCEMENT_CLASS_NAME),
        [className],
      );

      return (
        <Announcement {...rest} className={joinedClassName} ref={ref}>
          <Fragment>
            <Typography.SubTitle text='All Set' />
            <Typography.Small text='Your notifications will appear here after you place your first order.' />
          </Fragment>
        </Announcement>
      );
    }),
  ),
);
