import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'center',
  backgroundColor: '$statBackground',
  borderRadius: '$stat',
  display: 'flex',
  flexDirection: 'column',
  gap: '$statGap',
  padding: '$statPadding',
});

export type TStatBase = {
  caption: string;
  stat: string;
};

export type TStat = TProps<false, TStatBase, 'div'>;
export const STAT_CLASS_NAME = 'wp-stat';

export const Stat = withCssToString(
  STAT_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TStat>(({ caption, stat, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, STAT_CLASS_NAME), [className]);

      return (
        <SDiv {...rest} className={joinedClassName} ref={ref}>
          <Typography.Caption rigid={true} expanding={true} text={caption} />
          <Typography.Title rigid={true} expanding={true} text={stat} />
        </SDiv>
      );
    }),
  ),
);

export const StatLoader = memo(
  forwardRef<SVGSVGElement, TEmptyObject>(({}, ref) => (
    <SDiv css={{ justifyContent: 'center' }}>
      <Illustrations.Loader darker={true} height={43} ref={ref} uniqueKey='wp-stat-loader' width={96}>
        <rect x='0' y='5' rx='0' ry='0' width='64' height='10' />
        <rect x='0' y='31' rx='0' ry='0' width='96' height='12' />
      </Illustrations.Loader>
    </SDiv>
  )),
);
