import {
  Root as RXDialogRoot,
  Content as RxDialogContent,
  Overlay as RxDialogOverlay,
  Portal as RxDialogPortal,
} from '@radix-ui/react-dialog';
import { Root as RxSeparatorRoot } from '@radix-ui/react-separator';
import { Typography } from '@src/components/appearance/basics/Typography';
import type { TAsyncButtonAction, TButtonAction, TSyncButtonAction } from '@src/components/appearance/controls/Button';
import { ActionButton, Button } from '@src/components/appearance/controls/Button';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled, styledKeyframes } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import type { PropsWithChildren } from 'react';
import { Fragment, createContext, forwardRef, memo, useCallback, useContext, useMemo, useState } from 'react';

export type TDialog = {
  HeaderBase: {
    title: string;
  };
  FooterBase: {
    buttonAction: Omit<TAsyncButtonAction, 'variant'> | Omit<TSyncButtonAction, 'variant'>;
  };
  RootBase: {
    onOpenChange: (open: boolean) => void;
    open: boolean;
  };

  Header: TProps<false, TDialog['HeaderBase'], 'div'>;
  Footer: TProps<false, TDialog['FooterBase'], 'div'>;
  Root: PropsWithChildren<TDialog['RootBase']>;
};

export const DIALOG_CLASS_NAMES = {
  Header: 'wp-dialog-header',
  Footer: 'wp-dialog-footer',
};

type TRootContext = { doDialogClose: () => void };
const RootContext = createContext<TRootContext | undefined>(undefined);

const Separator = memo(
  styled(RxSeparatorRoot, {
    alignSelf: 'stretch',
    backgroundColor: '$separatorBackground',
    height: '$separatorThickness',
  }),
);

const SHeaderDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

const Header = withCssToString(
  DIALOG_CLASS_NAMES.Header,
  memo(
    forwardRef<HTMLDivElement, TDialog['Header']>(({ title, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, DIALOG_CLASS_NAMES.Header), [className]);

      return (
        <Fragment>
          <SHeaderDiv {...rest} className={joinedClassName} ref={ref}>
            <Typography.SubTitle expanding={true} rigid={true} text={title} />
          </SHeaderDiv>
        </Fragment>
      );
    }),
  ),
);

const SFooterDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

const SFooterSpacerDiv = styled('div', {
  flexGrow: 1,
});

const SFooterActionButton = styled(ActionButton, {
  minWidth: '$dialogFooterButtonMinWidth',
});

const Footer = withCssToString(
  DIALOG_CLASS_NAMES.Footer,
  memo(
    forwardRef<HTMLDivElement, TDialog['Footer']>(({ buttonAction, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, DIALOG_CLASS_NAMES.Footer), [className]);
      const rootContext = useContext(RootContext);
      const doDialogClose = useCallback(() => rootContext?.doDialogClose(), [rootContext]);
      const [error, setError] = useState<string | null>(null);
      const { doErrorNotify } = useErrors();

      const secondaryButtonAction = useMemo<TSyncButtonAction>(
        () => ({
          isAsync: false,
          onClick: doDialogClose,
          text: 'Go Back',
          variant: 'secondary',
        }),
        [doDialogClose],
      );

      const primaryButtonAction = useMemo<TButtonAction>(
        () =>
          buttonAction.isAsync
            ? {
                ...buttonAction,
                onClick: async (): Promise<void> => {
                  try {
                    setError(null);
                    await buttonAction.onClick();
                    doDialogClose();
                  } catch (thrown: unknown) {
                    const errorSummary = getErrorSummary(thrown, { forceDisplayMode: EErrorSummaryDisplayMode.SILENT });
                    doErrorNotify(errorSummary);
                    setError(errorSummary.display.message);
                  }
                },
              }
            : {
                ...buttonAction,
                onClick: (): void => {
                  try {
                    setError(null);
                    buttonAction.onClick();
                    doDialogClose();
                  } catch (thrown: unknown) {
                    const errorSummary = getErrorSummary(thrown, { forceDisplayMode: EErrorSummaryDisplayMode.SILENT });
                    doErrorNotify(errorSummary);
                    setError(errorSummary.display.message);
                  }
                },
              },
        // @sort
        [buttonAction, doDialogClose, doErrorNotify],
      );

      return (
        <Fragment>
          <Separator />
          <SFooterDiv {...rest} className={joinedClassName} ref={ref}>
            {isDef(error) ? (
              <Fragment>
                <Typography.ErrorCaption error={error} />
                <Button onClick={(): void => setError(null)} text='Reset' variant='secondary' />
              </Fragment>
            ) : (
              <Fragment>
                <SFooterActionButton action={secondaryButtonAction} />
                <SFooterSpacerDiv />
                <SFooterActionButton action={primaryButtonAction} />
              </Fragment>
            )}
          </SFooterDiv>
        </Fragment>
      );
    }),
  ),
);

const Panel = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$dialogPanelGapMobile',
  padding: '$dialogPanelPaddingMobile',

  '@desktop': {
    gap: '$dialogPanelGapDesktop',
    padding: '$dialogPanelPaddingDesktop',
  },
});

const SRootRxDialogOverlay = memo(
  styled(RxDialogOverlay, {
    backgroundColor: '$dialogOverlayBackground',
    inset: 0,
    position: 'fixed',
    zIndex: '$dialog',

    '&[data-state=open]': {
      animation: `${styledKeyframes.fadeIn.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },

    '&[data-state=closed]': {
      animation: `${styledKeyframes.fadeOut.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
  }),
);

const SRootRxDialogContent = styled(RxDialogContent, {
  alignItems: 'stretch',
  backgroundColor: '$dialogContentBackground',
  borderRadius: '$dialogContent',
  boxShadow: '$dialogContent',
  display: 'flex',
  flexDirection: 'column',
  hideScrollbars: true,
  left: '50%',
  maxHeight: '$dialogContentMaxHeight',
  maxWidth: '$dialogContentMaxWidth',
  overflow: 'scroll',
  position: 'fixed',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  width: '$dialogContentWidth',
  zIndex: '$dialog',

  '&[data-state=open]': {
    animation: `${styledKeyframes.fadeIn.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },

  '&[data-state=closed]': {
    animation: `${styledKeyframes.fadeOut.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

function Root({ open, onOpenChange, children }: TDialog['Root']): JSX.Element {
  const value = useMemo<TRootContext>(
    () => ({
      doDialogClose: () => onOpenChange(false),
    }),
    [onOpenChange],
  );

  return (
    <RootContext.Provider value={value}>
      <RXDialogRoot onOpenChange={onOpenChange} open={open}>
        <RxDialogPortal>
          <SRootRxDialogOverlay />
          <SRootRxDialogContent>{children}</SRootRxDialogContent>
        </RxDialogPortal>
      </RXDialogRoot>
    </RootContext.Provider>
  );
}

export const Dialog = {
  Footer,
  Header,
  Panel,
  Root,
  Separator,
};
