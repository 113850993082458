import type { TCheckBoxBase } from '@src/components/appearance/controls/CheckBox';
import { ItemsBannerLoader } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { NextOrderCartEmptyAnnouncement } from '@src/components/mixins/announcements/NextOrderCartEmptyAnnouncement';
import { NextOrderCartItemsBanner } from '@src/components/mixins/banners/NextOrderCartItemsBanner';
import {
  NextOrderCartProductCard,
  NextOrderCartProductCardLoader,
} from '@src/components/mixins/cards/NextOrderCartProductCard';
import type { TCustomerCartProductCompleteFragment } from '@src/gen/graphql/bindings';
import { ensureDef } from '@src/gen/shared/utils/types';
import {
  useCustomerNextOrderActions,
  useCustomerNextOrderActionsCartProductDelete,
  useCustomerNextOrderActionsCartProductIsSubstitutionAllowed,
  useCustomerNextOrderActionsCartProductQuantity,
} from '@src/modules/data/customer/nextOrder/CustomerNextOrderActionsProvider';
import { useCustomerNextOrder } from '@src/modules/data/customer/nextOrder/CustomerNextOrderProvider';
import { useCallback, useMemo, useState } from 'react';

export function CustomerNextOrderCartPart(): JSX.Element {
  const { nonDeletedCartProducts, cartProductsUniqueItems, cartProductsTotalItems, lastUpdatedCartProduct } =
    useCustomerNextOrder();
  const { beginOrderPlace, canPlaceEmergencyOrders } = useCustomerNextOrderActions();
  const [isEmergencyValue, setIsEmergencyValue] = useState(false);

  const isEmergencyOrder = useMemo<TCheckBoxBase>(
    () => ({
      checked: isEmergencyValue,
      onCheckedChange: (newValue) => setIsEmergencyValue(newValue === true),
    }),
    [isEmergencyValue],
  );

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <NextOrderCartItemsBanner
          cartProductsUniqueItems={cartProductsUniqueItems}
          cartProductsTotalItems={cartProductsTotalItems}
          lastUpdatedCartProduct={lastUpdatedCartProduct}
          doOrderPlace={beginOrderPlace ?? undefined}
          isEmergencyOrder={canPlaceEmergencyOrders ? isEmergencyOrder : undefined}
          isEmergencyOrderIndex={0}
        />
        <Structure.Group>
          {nonDeletedCartProducts.length === 0 && <NextOrderCartEmptyAnnouncement />}
          {nonDeletedCartProducts.map((cp) => (
            <CardComponent key={cp.public_catalog_product_id} cartProduct={cp} />
          ))}
        </Structure.Group>
        {nonDeletedCartProducts.length > 5 && (
          <NextOrderCartItemsBanner
            cartProductsUniqueItems={cartProductsUniqueItems}
            cartProductsTotalItems={cartProductsTotalItems}
            lastUpdatedCartProduct={lastUpdatedCartProduct}
            doOrderPlace={beginOrderPlace ?? undefined}
            isEmergencyOrder={canPlaceEmergencyOrders ? isEmergencyOrder : undefined}
            isEmergencyOrderIndex={1}
          />
        )}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function CardComponent({ cartProduct }: { cartProduct: TCustomerCartProductCompleteFragment }): JSX.Element {
  const { beginPublicCatalogProductView } = useCustomerNextOrderActions();

  const quantity = useCustomerNextOrderActionsCartProductQuantity({
    cartProduct,
    publicCatalogProduct: {
      id: cartProduct.public_catalog_product_id,
      source: cartProduct.source,
      product_page_url: cartProduct.product_page_url,
      product_sku: cartProduct.product_sku,
      manufacturer_name: cartProduct.manufacturer_name,
      manufacturer_sku: cartProduct.manufacturer_sku,
      name: cartProduct.name,
      secondary_name: cartProduct.secondary_name,
      sale_unit: cartProduct.sale_unit,
      is_discontinued: cartProduct.is_discontinued,
      is_house_brand: cartProduct.is_house_brand,
      category_path: cartProduct.category_path,
      category_name: cartProduct.category_name,
      simplified_category: cartProduct.simplified_category,
      description: cartProduct.description,
      specs: cartProduct.specs,
      image_asset_path: cartProduct.image_asset_path,
      sds_asset_path: cartProduct.sds_asset_path,
    },
    eventLocation: 'cart',
  });

  const isSubstitutionAllowed = ensureDef(
    useCustomerNextOrderActionsCartProductIsSubstitutionAllowed({
      cartProduct,
    }),
  );

  const handleDelete = useCustomerNextOrderActionsCartProductDelete({
    cartProduct,
  });

  const handleClick = useCallback(
    () => beginPublicCatalogProductView({ publicCatalogProductId: cartProduct.public_catalog_product_id }),
    [beginPublicCatalogProductView, cartProduct.public_catalog_product_id],
  );

  return (
    <NextOrderCartProductCard
      cartProduct={cartProduct}
      isSubstitutionAllowed={isSubstitutionAllowed}
      onClick={handleClick}
      onRemove={handleDelete}
      quantity={quantity}
    />
  );
}

export function CustomerNextOrderCartPartLoader(): JSX.Element {
  return (
    <Structure.ScrollContentLoader>
      <Structure.Stack>
        <ItemsBannerLoader buttonText='Place Order' count={4} />
        <NextOrderCartProductCardLoader />
        <NextOrderCartProductCardLoader />
        <NextOrderCartProductCardLoader />
        <NextOrderCartProductCardLoader />
        <NextOrderCartProductCardLoader />
        <NextOrderCartProductCardLoader />
        <NextOrderCartProductCardLoader />
        <NextOrderCartProductCardLoader />
        <NextOrderCartProductCardLoader />
        <NextOrderCartProductCardLoader />
      </Structure.Stack>
    </Structure.ScrollContentLoader>
  );
}
