import type { TItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type { TAgentOrderCompleteFragment, TCustomerOrderCompleteFragment } from '@src/gen/graphql/bindings';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderStatusItemsBannerBase = {
  order: Pick<
    TAgentOrderCompleteFragment | TCustomerOrderCompleteFragment,
    'has_back_ordered_products' | 'has_pending_returns' | 'is_blocked_on_customer' | 'is_paid'
  >;
};

export type TOrderStatusItemsBanner = TProps<false, TOrderStatusItemsBannerBase, 'div'>;
export const ORDER_STATUS_ITEMS_BANNER_CLASS_NAME = 'wp-order-status-items-banner';

export const OrderStatusItemsBanner = withCssToString(
  ORDER_STATUS_ITEMS_BANNER_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderStatusItemsBanner>(({ order, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, ORDER_STATUS_ITEMS_BANNER_CLASS_NAME),
        [className],
      );

      const items = useMemo<TItemsBanner['items']>(
        () => [
          {
            caption: 'Waiting on Customer',
            text: { asBoolean: order.is_blocked_on_customer },
          },
          {
            caption: 'Back-Ordered Products',
            text: { asBoolean: order.has_back_ordered_products },
          },
          {
            caption: 'Pending Returns',
            text: { asBoolean: order.has_pending_returns },
          },
          {
            caption: 'Paid',
            text: { asBoolean: order.is_paid },
          },
        ],
        // @sort
        [order.has_back_ordered_products, order.has_pending_returns, order.is_blocked_on_customer, order.is_paid],
      );

      return <ItemsBanner {...rest} className={joinedClassName} items={items} ref={ref} />;
    }),
  ),
);
