import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderTrackingNumberCard } from '@src/components/mixins/cards/OrderTrackingNumberCard';
import type {
  TAgentOrderTrackingNumberBaseFragment,
  TCustomerOrderTrackingNumberBaseFragment,
} from '@src/gen/graphql/bindings';
import { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { ensureNotEmptyString, isDef, isNotEmptyString } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderTrackingNumbersStructureGroupBase = {
  beginOrderTrackingNumberAdd?: TAgentOrderActionsTypes['BeginOrderTrackingNumberAdd'] | undefined;
  beginOrderTrackingHistoryView?: TAgentOrderActionsTypes['BeginOrderTrackingHistoryView'] | undefined;
  doOrderTrackingNumberRemoveAsyncNotify?:
    | TAgentOrderActionsTypes['DoOrderTrackingNumberRemoveAsyncNotify']
    | undefined;
  orderStatus: EOrderStatus;
  orderTrackingNumbers: TAgentOrderTrackingNumberBaseFragment[] | TCustomerOrderTrackingNumberBaseFragment[];
};

export type TOrderTrackingNumbersStructureGroup = TProps<false, TOrderTrackingNumbersStructureGroupBase, 'div'>;
export const ORDER_TRACKING_NUMBERS_STRUCTURE_GROUP = 'wp-order-tracking-numbers-structure-group';

export const OrderTrackingNumbersStructureGroup = withCssToString(
  ORDER_TRACKING_NUMBERS_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderTrackingNumbersStructureGroup>(
      (
        {
          beginOrderTrackingNumberAdd,
          beginOrderTrackingHistoryView,
          doOrderTrackingNumberRemoveAsyncNotify,
          orderStatus,
          orderTrackingNumbers,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_TRACKING_NUMBERS_STRUCTURE_GROUP),
          [className],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () => getAvailableIconButtonActions([{ isAsync: false, icon: 'add', onClick: beginOrderTrackingNumberAdd }]),
          // @sort
          [beginOrderTrackingNumberAdd],
        );

        return (
          <Structure.Group
            {...rest}
            className={joinedClassName}
            iconButtonActions={iconButtonActions}
            ref={ref}
            title='Tracking Numbers'>
            {orderTrackingNumbers.length === 0 && (
              <Banner icon='info' message='Tracking numbers for your order will appear here when available.' />
            )}
            {orderTrackingNumbers.length > 0 &&
              (orderStatus === EOrderStatus.RECEIVED ||
                orderStatus === EOrderStatus.PREPARING ||
                orderStatus === EOrderStatus.PROCESSING) && (
                <Banner
                  icon='warning'
                  message='Not all items have shipped. Additional tracking numbers will appear here when available.'
                />
              )}
            {orderTrackingNumbers.map((otn) => (
              <CardComponent
                key={otn.tracking_number}
                orderTrackingNumber={otn}
                beginOrderTrackingHistoryView={beginOrderTrackingHistoryView}
                doOrderTrackingNumberRemoveAsyncNotify={doOrderTrackingNumberRemoveAsyncNotify}
              />
            ))}
          </Structure.Group>
        );
      },
    ),
  ),
);

function CardComponent({
  orderTrackingNumber,
  doOrderTrackingNumberRemoveAsyncNotify,
  beginOrderTrackingHistoryView,
}: {
  orderTrackingNumber: TAgentOrderTrackingNumberBaseFragment | TCustomerOrderTrackingNumberBaseFragment;
  doOrderTrackingNumberRemoveAsyncNotify?:
    | TAgentOrderActionsTypes['DoOrderTrackingNumberRemoveAsyncNotify']
    | undefined;
  beginOrderTrackingHistoryView?: TAgentOrderActionsTypes['BeginOrderTrackingHistoryView'] | undefined;
}): JSX.Element {
  const handleDelete = useMemo(
    () =>
      isDef(doOrderTrackingNumberRemoveAsyncNotify)
        ? async (): Promise<void> =>
            await doOrderTrackingNumberRemoveAsyncNotify({ trackingNumber: orderTrackingNumber.tracking_number })
        : undefined,
    // @sort
    [doOrderTrackingNumberRemoveAsyncNotify, orderTrackingNumber.tracking_number],
  );

  const handleViewHistory = useMemo(
    () =>
      isDef(beginOrderTrackingHistoryView) && isNotEmptyString(orderTrackingNumber.ship24_tracker_id)
        ? (): void =>
            beginOrderTrackingHistoryView({
              ship24TrackerId: ensureNotEmptyString(orderTrackingNumber.ship24_tracker_id),
            })
        : undefined,
    // @sort
    [beginOrderTrackingHistoryView, orderTrackingNumber.ship24_tracker_id],
  );

  return (
    <OrderTrackingNumberCard
      orderTrackingNumber={orderTrackingNumber}
      onRemoveAsync={handleDelete}
      onViewHistory={handleViewHistory}
    />
  );
}
