import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { TextAreaControl } from '@src/components/appearance/controls/TextArea';
import { Description } from '@src/components/appearance/fragments/Description';
import { Dialog } from '@src/components/appearance/structure/Dialog';
import { EDescriptionBlockType } from '@src/gen/shared/data/description';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TOrderSnapshotRejectDialogPanelBase = {
  orderSnapshotId: string;
  doOrderSnapshotRejectAsync: TCustomerOrderActionsTypes['DoOrderSnapshotRejectAsync'];
};

type TOrderSnapshotRejectDialogPanelFormValues = {
  rejectedReason: string;
};

export type TOrderSnapshotRejectDialogPanel = TProps<false, TOrderSnapshotRejectDialogPanelBase, 'div'>;
export const ORDER_SNAPSHOT_REJECT_DIALOG_PANEL_CLASS_NAME = 'wp-order-snapshot-reject-dialog-panel';

export const OrderSnapshotRejectDialogPanel = withCssToString(
  ORDER_SNAPSHOT_REJECT_DIALOG_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderSnapshotRejectDialogPanel>(
      ({ orderSnapshotId, doOrderSnapshotRejectAsync, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_SNAPSHOT_REJECT_DIALOG_PANEL_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TOrderSnapshotRejectDialogPanelFormValues>(
          () => ({
            rejectedReason: '',
          }),
          [],
        );

        const handleSubmit = useCallback<TFormSubmit<TOrderSnapshotRejectDialogPanelFormValues>>(
          async (values) => {
            await doOrderSnapshotRejectAsync({
              orderSnapshotId: orderSnapshotId,
              rejectedReason: values.rejectedReason,
            });
            return undefined;
          },
          [doOrderSnapshotRejectAsync, orderSnapshotId],
        );

        return (
          <Dialog.Panel {...rest} className={joinedClassName} ref={ref}>
            <Dialog.Header title='Reject Prebill' />
            <Description
              description={[
                {
                  type: EDescriptionBlockType.PARAGRAPH,
                  paragraph:
                    'We are sorry this prebill did not meet your expectations. Please let us know how to make it right and we will issue an updated prebill ASAP.',
                },
              ]}
            />
            <Form<TOrderSnapshotRejectDialogPanelFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              submitButtonText='Reject Prebill'>
              <TextAreaControl
                id='rejectedReason'
                label='Desired Changes / Reason for Rejection'
                maxLength={2048}
                required={true}
              />
            </Form>
          </Dialog.Panel>
        );
      },
    ),
  ),
);
