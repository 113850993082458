import { captureException } from '@sentry/react';
import { Typography } from '@src/components/appearance/basics/Typography';
import { Cover } from '@src/components/appearance/structure/Cover';
import { Toast } from '@src/components/appearance/structure/Toast';
import { ErrorAnnouncement } from '@src/components/mixins/announcements/ErrorAnnouncement';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useToast } from '@src/modules/design/ToastProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { TErrorSummary } from '@src/modules/errors/errorSummary';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import type { PropsWithChildren } from 'react';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export type TErrorsTypes = {
  DoErrorNotify: (newErrorSummary: TErrorSummary) => void;
  DoErrorClear: () => void;
};

export type TErrorsContext = {
  doErrorNotify: TErrorsTypes['DoErrorNotify'];
  doErrorClear: TErrorsTypes['DoErrorClear'];
};

export const { Context: ErrorsContext, useContext: useErrors } = createRequiredContext<TErrorsContext>();

export function ErrorsProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const { doToastOpen, doToastClose } = useToast();
  const [errorSummary, setErrorSummary] = useState<TErrorSummary | undefined>(undefined);

  const doErrorNotify = useCallback<TErrorsTypes['DoErrorNotify']>(
    (newErrorSummary) => {
      captureException(newErrorSummary.thrown, {
        extra: {
          display: newErrorSummary.display,
          code: newErrorSummary.code,
        },
      });

      switch (newErrorSummary.display.mode) {
        case EErrorSummaryDisplayMode.TOAST:
          return doToastOpen(
            <Toast.Panel accent='error'>
              <Toast.Header title={newErrorSummary.display.title} />
              <Typography.Small text={newErrorSummary.display.message} />
              {isDef(newErrorSummary.display.context) && <Typography.Caption text={newErrorSummary.display.context} />}
            </Toast.Panel>,
          );

        case EErrorSummaryDisplayMode.SILENT:
          return;
        case EErrorSummaryDisplayMode.ANNOUNCEMENT:
          return setErrorSummary(newErrorSummary);
      }
    },
    [doToastOpen],
  );

  const doErrorClear = useCallback<TErrorsTypes['DoErrorClear']>(() => {
    doToastClose();
  }, [doToastClose]);

  const handleError = useCallback(
    (thrown: unknown) =>
      doErrorNotify(getErrorSummary(thrown, { forceDisplayMode: EErrorSummaryDisplayMode.ANNOUNCEMENT })),
    [doErrorNotify],
  );

  const value = useMemo<TErrorsContext>(
    () => ({
      doErrorNotify,
      doErrorClear,
    }),
    // @sort
    [doErrorClear, doErrorNotify],
  );

  return (
    <ErrorsContext.Provider value={value}>
      {isDef(errorSummary) ? (
        <Cover background='plain'>
          <ErrorAnnouncement errorSummary={errorSummary} />
        </Cover>
      ) : (
        <ErrorBoundary fallback={<Fragment />} onError={handleError}>
          {children}
        </ErrorBoundary>
      )}
    </ErrorsContext.Provider>
  );
}
