import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { Select } from '@src/components/appearance/controls/Select';
import { CategoryTree } from '@src/components/appearance/fragments/CategoryTree';
import { Description } from '@src/components/appearance/fragments/Description';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { TAgentPublicCatalogProductCompleteWithVariantsFragment } from '@src/gen/graphql/bindings';
import { castDescriptionUnsafe } from '@src/gen/shared/data/description';
import { castSpecsUnsafe } from '@src/gen/shared/data/specs';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { getSpecsItems, joinClassNames } from '@src/logic/internal/data/utils';
import { openUrl, withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { ChangeEvent } from 'react';
import { Fragment, forwardRef, memo, useCallback, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '256px',
});

export type TProductPickerDrawerPanelBase = {
  onChangeVariant: (productId: string) => void;
  onSelect: () => void;
  product: TAgentPublicCatalogProductCompleteWithVariantsFragment;
};

export type TProductPickerDrawerPanel = TProps<false, TProductPickerDrawerPanelBase, 'div'>;
export const PRODUCT_PICKER_DRAWER_PANEL_CLASS_NAME = 'wp-product-picker-drawer-panel';

export const ProductPickerDrawerPanel = withCssToString(
  PRODUCT_PICKER_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TProductPickerDrawerPanel>(
      ({ product, onChangeVariant, onSelect, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, PRODUCT_PICKER_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const productItems = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(product.source),
            },
            {
              caption: 'SKU',
              text: product.product_sku,
            },
            {
              caption: 'Manufacturer',
              text: product.manufacturer_name ?? 'N/A',
            },
            {
              caption: 'Manufacturer SKU',
              text: product.manufacturer_sku ?? 'N/A',
            },
          ],
          // @sort
          [product.manufacturer_name, product.manufacturer_sku, product.product_sku, product.source],
        );

        const productDescriptionBlocks = useMemo(
          () => castDescriptionUnsafe(product.description),
          [product.description],
        );

        const specsItems = useMemo<TItemBaseItem[]>(
          () => getSpecsItems(castSpecsUnsafe(product.specs)),
          [product.specs],
        );

        const handleChangeVariant = useCallback(
          (e: ChangeEvent<HTMLSelectElement>) => onChangeVariant(e.target.value),
          [onChangeVariant],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Product' />
            <Drawer.ScrollContent>
              <SImageAsset alt={product.name} imageAssetPath={product.image_asset_path} />
              <Drawer.Separator />
              <Drawer.Group>
                <Drawer.Title subTitle={product.secondary_name} title={product.name} />
                <Items items={productItems} />
              </Drawer.Group>
              <Drawer.Separator />
              {product.variant_products.length > 1 && (
                <Fragment>
                  <Drawer.Group title='Variants'>
                    <Select onChange={handleChangeVariant} value={product.id}>
                      {product.variant_products.map((vp) => (
                        <option key={vp.id} value={vp.id}>
                          {vp.name}
                          {vp.secondary_name !== null && ` · ${vp.secondary_name}`}
                        </option>
                      ))}
                    </Select>
                  </Drawer.Group>
                  <Drawer.Separator />
                </Fragment>
              )}
              <Drawer.Group title='References'>
                <ControlButton
                  icon='link'
                  text='Product Page'
                  onClick={(): void => openUrl(product.product_page_url)}
                />
              </Drawer.Group>
              <Drawer.Group title='Category'>
                <CategoryTree category={product.category_name} />
              </Drawer.Group>
              {productDescriptionBlocks.length > 0 && (
                <Drawer.Group title='Description'>
                  <Description description={productDescriptionBlocks} />
                </Drawer.Group>
              )}
              {specsItems.length > 0 && (
                <Drawer.Group title='Specifications'>
                  {specsItems.map((item) => (
                    <Item item={item} key={item.caption} />
                  ))}
                </Drawer.Group>
              )}
              {isDef(product.sds_asset_path) && (
                <Drawer.Group title='Documents'>
                  <ControlButton icon='download' text='Safety Data Sheet' />
                </Drawer.Group>
              )}
            </Drawer.ScrollContent>
            <Drawer.Footer
              buttonAction={{
                isAsync: false,
                onClick: onSelect,
                text: 'Select Product',
              }}
            />
          </Drawer.Panel>
        );
      },
    ),
  ),
);

const SLoaderTopDiv = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const ProductPickerDrawerPanelLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Drawer.Panel ref={ref}>
        <Drawer.Header title='Product' />
        <Drawer.ScrollContentLoader>
          <SLoaderTopDiv>
            <Illustrations.Loader height={256} uniqueKey='wp-order-product-drawer-panel-loader-image' width={320}>
              <rect x='0' y='5' rx='0' ry='0' width='320' height='256' />
            </Illustrations.Loader>
          </SLoaderTopDiv>
          <Drawer.Separator />
          <Drawer.Group>
            <Drawer.TitleLoader />
            <ItemsLoader count={4} />
          </Drawer.Group>
          <Drawer.Separator />
          <Drawer.Group>
            <ItemsLoader count={2} />
          </Drawer.Group>
        </Drawer.ScrollContentLoader>
      </Drawer.Panel>
    ),
  ),
);
