import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderBackOrderCard } from '@src/components/mixins/cards/OrderBackOrderCard';
import type { TAgentOrderBackOrderCompleteFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderBackOrdersStructureGroupBase = {
  allOrderBackOrders: TAgentOrderBackOrderCompleteFragment[];
  beginOrderBackOrderProductView:
    | TAgentOrderActionsTypes['BeginOrderBackOrderProductView']
    | TCustomerOrderActionsTypes['BeginOrderBackOrderProductView'];
  doOrderBackOrderReplyAsyncNotify: TCustomerOrderActionsTypes['DoOrderBackOrderReplyAsyncNotify'] | null;
  mode: 'customer-awaiting' | 'customer-other';
};

export type TOrderBackOrdersStructureGroup = TProps<false, TOrderBackOrdersStructureGroupBase, 'div'>;
export const ORDER_BACK_ORDERS_STRUCTURE_GROUP_CLASS_NAME = 'wp-order-back-orders-structure-group';

export const OrderBackOrdersStructureGroup = withCssToString(
  ORDER_BACK_ORDERS_STRUCTURE_GROUP_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderBackOrdersStructureGroup>(
      (
        {
          allOrderBackOrders,
          beginOrderBackOrderProductView,
          doOrderBackOrderReplyAsyncNotify,
          mode,
          className,
          ...rest
        },
        ref,
      ): JSX.Element | null => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_BACK_ORDERS_STRUCTURE_GROUP_CLASS_NAME),
          [className],
        );

        const orderBackOrders = useMemo(
          () =>
            allOrderBackOrders.filter((obo) =>
              mode === 'customer-awaiting' ? !isDef(obo.replied_at) : isDef(obo.replied_at) && !isDef(obo.applied_at),
            ),
          [allOrderBackOrders, mode],
        );

        if (orderBackOrders.length === 0) {
          return null;
        }

        return (
          <Structure.Group
            {...rest}
            className={joinedClassName}
            ref={ref}
            title={`Back-Orders${mode === 'customer-awaiting' ? ' - Awaiting Decision' : ''}`}>
            {orderBackOrders.map((obo) => (
              <OrderBackOrderCard
                key={obo.id}
                beginOrderBackOrderProductView={beginOrderBackOrderProductView}
                orderBackOrder={obo}
                onDecisionAsyncNotify={
                  isDef(doOrderBackOrderReplyAsyncNotify)
                    ? async (repliedDecision): Promise<void> =>
                        await doOrderBackOrderReplyAsyncNotify({ orderBackOrderId: obo.id, repliedDecision })
                    : null
                }
                onApplyAsyncNotify={null}
                onDeleteAsyncNotify={null}
                variant='customer'
              />
            ))}
          </Structure.Group>
        );
      },
    ),
  ),
);
