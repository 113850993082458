import { NetworkStatus } from '@apollo/client';
import type { AgentLocationsForPickerQueryVariables, TAgentLocationForPickerFragment } from '@src/gen/graphql/bindings';
import { useAgentLocationsForPickerQuery } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, mergeGetMore } from '@src/modules/graphql/utils';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TAgentLocationsForPickerContext = {
  locations: TAgentLocationForPickerFragment[];
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const { Context: AgentLocationsForPickerContext, useContext: useAgentLocationsForPicker } =
  createRequiredContext<TAgentLocationsForPickerContext>();

export type TAgentLocationsForPickerProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  organizationId: string;
}>;

export function AgentLocationsForPickerProvider({
  LoaderComponent,
  organizationId,
  children,
}: TAgentLocationsForPickerProvider): JSX.Element {
  const variables = useMemo<AgentLocationsForPickerQueryVariables>(
    () => ({
      organizationId,
      limit: DEFAULT_LIMIT,
      offset: 0,
    }),
    // @sort
    [organizationId],
  );

  const { data, error, fetchMore, networkStatus } = useAgentLocationsForPickerQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          offset: ensureDef(data?.locations.length),
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          locations: mergeGetMore(prev.locations, fetchMoreResult.locations),
        }),
      });

      return newData.locations.length;
    },
    // @sort
    [fetchMore, data?.locations.length],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.locations.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TAgentLocationsForPickerContext | undefined>(
    () =>
      isDef(data)
        ? {
            locations: data.locations,
            loaderRef: loaderRef,
          }
        : undefined,
    // @sort
    [data, loaderRef],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentLocationsForPickerContext.Provider value={value}>{children}</AgentLocationsForPickerContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
