import { Card } from '@src/components/appearance/fragments/Card';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TAgentUserBaseFragment, TCustomerUserBaseFragment } from '@src/gen/graphql/bindings';
import { getDateOn, getFullName } from '@src/gen/shared/data/snippets';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TNotesCardBase = {
  content: string;
  updatedAt: Date | string;
  updater: TAgentUserBaseFragment | TCustomerUserBaseFragment;
};

export type TNotesCard = TProps<false, TNotesCardBase, 'div'>;
export const NOTES_CARD_CLASS_NAME = 'wp-notes-card';

export const NotesCard = withCssToString(
  NOTES_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNotesCard>(({ content, updatedAt, updater, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, NOTES_CARD_CLASS_NAME), [className]);

      return (
        <Card.Container {...rest} className={joinedClassName} flush={true} interactive={false} variant='form' ref={ref}>
          <Item
            item={{
              caption: `Notes (Updated by ${getFullName(updater)} on ${getDateOn(updatedAt)})`,
              text: content,
            }}
          />
        </Card.Container>
      );
    }),
  ),
);
