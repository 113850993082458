import type { CustomerGetBudgetAssistantOrdersQueryVariables } from '@src/gen/graphql/bindings';
import { useCustomerGetBudgetAssistantOrdersQuery } from '@src/gen/graphql/bindings';
import { OrderBudgetAssistantManager } from '@src/gen/shared/data/orderBudgetAssistantManager';
import { getDate } from '@src/gen/shared/data/snippets';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useCustomerOrder } from '@src/modules/data/customer/order/CustomerOrderProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { endOfMonth, startOfMonth } from 'date-fns';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TCustomerOrderBudgetAssistantContext = {
  orderBudgetAssistantManager: OrderBudgetAssistantManager | null;
};

export const { Context: CustomerOrderBudgetAssistantContext, useContext: useCustomerOrderBudgetAssistant } =
  createRequiredContext<TCustomerOrderBudgetAssistantContext>();

export function CustomerOrderBudgetAssistantProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const { orderManager } = useCustomerOrder();
  const staticValue = useMemo<TCustomerOrderBudgetAssistantContext>(() => ({ orderBudgetAssistantManager: null }), []);

  return orderManager.hasSnapshotPrebillPending() ? (
    <Inner>{children}</Inner>
  ) : (
    <CustomerOrderBudgetAssistantContext.Provider value={staticValue}>
      {children}
    </CustomerOrderBudgetAssistantContext.Provider>
  );
}

function Inner({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const { order } = useCustomerOrder();

  const variables = useMemo<CustomerGetBudgetAssistantOrdersQueryVariables>(
    () => ({
      locationId: order.location_id,
      startTime: startOfMonth(getDate(order.created_at)).toISOString(),
      endTime: endOfMonth(getDate(order.created_at)).toISOString(),
    }),
    // @sort
    [order.created_at, order.location_id],
  );

  const { data, error } = useCustomerGetBudgetAssistantOrdersQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const value = useMemo<TCustomerOrderBudgetAssistantContext | undefined>(
    () =>
      // @sort
      ({
        orderBudgetAssistantManager:
          isDef(data) && data.orders.length > 0
            ? new OrderBudgetAssistantManager(order.id, order.location.order_code, data.orders)
            : null,
      }),
    [data, order.id, order.location.order_code],
  );

  if (isDef(error)) {
    throw error;
  }

  if (isDef(error)) {
    throw error;
  }

  return (
    <CustomerOrderBudgetAssistantContext.Provider value={value}>
      {children}
    </CustomerOrderBudgetAssistantContext.Provider>
  );
}
