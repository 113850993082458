import { Control } from '@src/components/appearance/controls/Control';
import { TextBox } from '@src/components/appearance/controls/TextBox';
import { ensureStringToDollarsCurrencyEditable, formatDollarsCurrencyEditable } from '@src/gen/shared/utils/converters';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { ChangeEvent } from 'react';
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField } from 'react-final-form';

const SDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
});

export type TCurrencyBase = {
  disabled?: boolean | undefined;
  onChange: (value: number) => void;
  value: number;
};

export type TCurrency = TProps<false, TCurrencyBase, 'div'>;
export const CURRENCY_CLASS_NAME = 'wp-currency';

export const Currency = withCssToString(
  CURRENCY_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TCurrency>(
      ({ disabled, onChange, value, className, ...rest }: TCurrency, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, CURRENCY_CLASS_NAME), [className]);
        const inputRef = useRef<HTMLInputElement>(null);
        const [valueStr, setValueStr] = useState<string>(formatDollarsCurrencyEditable(value));
        const lastValueRef = useRef(value);

        useEffect(() => {
          if (lastValueRef.current !== value) {
            setValueStr(formatDollarsCurrencyEditable(value));
          }
        }, [value]);

        const handleChange = useCallback(
          (e: ChangeEvent<HTMLInputElement>): void => {
            try {
              const newValue = ensureStringToDollarsCurrencyEditable(e.target.value);
              setValueStr(e.target.value);
              lastValueRef.current = newValue;
              onChange(newValue);
            } catch {
              if (isDef(inputRef.current)) {
                let selectionStart = ensureDef(inputRef.current.selectionStart) - 1;
                if (selectionStart <= 0 && valueStr.length > e.target.value.length) {
                  selectionStart += 2;
                }

                setTimeout(() => inputRef.current?.setSelectionRange(selectionStart, selectionStart), 0);
              }
            }
          },
          [onChange, valueStr],
        );

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            <TextBox disabled={disabled} onChange={handleChange} ref={inputRef} type='text' value={valueStr} />
          </SDiv>
        );
      },
    ),
  ),
);

export type TCurrencyControl = {
  id: string;
  label?: string | undefined;
  required?: boolean | undefined;
};

export function CurrencyControl({ id, label, required }: TCurrencyControl): JSX.Element {
  const {
    input: { onBlur, onChange, onFocus, value },
    meta: { error, submitting, visited }, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  } = useField<number>(id, {
    subscription: {
      error: true,
      submitting: true,
      value: true,
      visited: true,
    },
    validate: (v) => {
      if (required !== true || v > 0) {
        return null;
      }
      return 'Please provide an amount.';
    },
  });

  const finalError = useMemo(
    () => (visited === true && typeof error === 'string' && error !== '' ? error : null),
    [error, visited],
  );

  return (
    <Control error={finalError} id={id} required={required} label={label}>
      <Currency disabled={submitting} id={id} onBlur={onBlur} onChange={onChange} onFocus={onFocus} value={value} />
    </Control>
  );
}
