import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import {
  UserWithStatisticsCard,
  UserWithStatisticsCardLoader,
} from '@src/components/mixins/cards/UserWithStatisticsCard';
import type { TAgentCustomersUserBaseWithStatisticsFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentCustomersUsersActions } from '@src/modules/data/agent/customers/users/AgentCustomersUsersActionsProvider';
import { useAgentCustomersUsersWithStatistics } from '@src/modules/data/agent/customers/users/AgentCustomersUsersWithStatisticsProvider';
import { encodeAgentCustomersUserOverviewPath } from '@src/modules/routing/agent';
import { Fragment, memo, useMemo } from 'react';

export function AgentCustomersUsersPart(): JSX.Element {
  const { users, loaderRef } = useAgentCustomersUsersWithStatistics();
  const { beginUserCustomerAdd } = useAgentCustomersUsersActions();

  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav
          title='Users'
          IconButtonElement={
            isDef(beginUserCustomerAdd) ? <IconButton icon='add' onClick={beginUserCustomerAdd} /> : undefined
          }
        />
      </Structure.Content>
      <Structure.ScrollContent>
        <Structure.Group>
          {users.map((u) => (
            <CardComponent key={u.id} user={u} />
          ))}
          {isDef(loaderRef) && <Illustrations.Spinner ref={loaderRef} />}
        </Structure.Group>
      </Structure.ScrollContent>
    </Fragment>
  );
}

function CardComponent({ user }: { user: TAgentCustomersUserBaseWithStatisticsFragment }): JSX.Element {
  const to = useMemo(() => encodeAgentCustomersUserOverviewPath(user.id), [user.id]);
  return <UserWithStatisticsCard user={user} to={to} />;
}

export const AgentCustomersUsersPartLoader = memo(
  (): JSX.Element => (
    <Fragment>
      <Structure.Content>
        <SecondaryNav title='Users' />
      </Structure.Content>
      <Structure.ScrollContentLoader>
        <Structure.Stack>
          <Structure.GroupLoader hasTitle={false}>
            <UserWithStatisticsCardLoader />
            <UserWithStatisticsCardLoader />
            <UserWithStatisticsCardLoader />
            <UserWithStatisticsCardLoader />
            <UserWithStatisticsCardLoader />
            <UserWithStatisticsCardLoader />
            <UserWithStatisticsCardLoader />
            <UserWithStatisticsCardLoader />
            <UserWithStatisticsCardLoader />
            <UserWithStatisticsCardLoader />
          </Structure.GroupLoader>
        </Structure.Stack>
      </Structure.ScrollContentLoader>
    </Fragment>
  ),
);
