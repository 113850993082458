import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrdersEmptyAnnouncement } from '@src/components/mixins/announcements/OrdersEmptyAnnouncement';
import { OrderCard, OrderCardLoader } from '@src/components/mixins/cards/OrderCard';
import type { TCustomerOrderBaseFragment } from '@src/gen/graphql/bindings';
import { CustomerOrderSnapshotManager } from '@src/gen/shared/data/customerOrders';
import { EOrderSnapshotType } from '@src/gen/shared/enums/orderSnapshotType';
import { isDef } from '@src/gen/shared/utils/types';
import { useCustomerOrders } from '@src/modules/data/customer/orders/CustomerOrdersProvider';
import { encodeCustomerOrderSummaryPath, useCustomerOrdersRouteParams } from '@src/modules/routing/customer';
import { memo, useMemo } from 'react';

export function CustomerOrdersPart(): JSX.Element {
  const customerOrders = useCustomerOrders();

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        {customerOrders.orders.waitingOnCustomer.length === 0 &&
          customerOrders.orders.inProgress.length === 0 &&
          customerOrders.orders.completed.length === 0 && <OrdersEmptyAnnouncement />}
        {customerOrders.orders.waitingOnCustomer.length > 0 && (
          <Structure.Group title={'Awaiting Approval'}>
            {customerOrders.orders.waitingOnCustomer.map((order) => {
              const orderSnapshot = order.order_snapshots.find(
                (os) => os.type === EOrderSnapshotType.PREBILL && !isDef(os.approved_at) && !isDef(os.rejected_at),
              );

              return isDef(orderSnapshot) ? (
                <CardComponent
                  key={order.id}
                  order={order}
                  orderSnapshotManager={new CustomerOrderSnapshotManager(orderSnapshot)}
                />
              ) : (
                <CardComponent key={order.id} order={order} orderSnapshotManager={null} />
              );
            })}
          </Structure.Group>
        )}
        {customerOrders.orders.inProgress.length > 0 && (
          <Structure.Group title='In Progress'>
            {customerOrders.orders.inProgress.map((order) => (
              <CardComponent key={order.id} order={order} orderSnapshotManager={null} />
            ))}
          </Structure.Group>
        )}
        {customerOrders.orders.completed.length > 0 && (
          <Structure.Group title='Completed'>
            {customerOrders.orders.completed.map((order) => (
              <CardComponent key={order.id} order={order} orderSnapshotManager={null} />
            ))}
            {isDef(customerOrders.loaderRef) && <Illustrations.Spinner ref={customerOrders.loaderRef} />}
          </Structure.Group>
        )}
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}

function CardComponent({
  order,
  orderSnapshotManager,
}: {
  order: TCustomerOrderBaseFragment;
  orderSnapshotManager: CustomerOrderSnapshotManager | null;
}): JSX.Element {
  const routeParams = useCustomerOrdersRouteParams();

  const to = useMemo(
    () =>
      `${encodeCustomerOrderSummaryPath(routeParams.organizationId, order.id)}${
        isDef(orderSnapshotManager) ? '#open' : ''
      }`,
    // @sort
    [orderSnapshotManager, order.id, routeParams.organizationId],
  );

  return <OrderCard order={order} to={to} orderSnapshotManager={orderSnapshotManager} />;
}

export const CustomerOrdersPartLoader = memo(
  (): JSX.Element => (
    <Structure.ScrollContentLoader>
      <Structure.Stack>
        <Structure.GroupLoader hasTitle={true}>
          <OrderCardLoader />
          <OrderCardLoader />
          <OrderCardLoader />
          <OrderCardLoader />
          <OrderCardLoader />
          <OrderCardLoader />
          <OrderCardLoader />
          <OrderCardLoader />
          <OrderCardLoader />
          <OrderCardLoader />
        </Structure.GroupLoader>
      </Structure.Stack>
    </Structure.ScrollContentLoader>
  ),
);
