import {
  Root as RXDialogRoot,
  Content as RxDialogContent,
  Overlay as RxDialogOverlay,
  Portal as RxDialogPortal,
} from '@radix-ui/react-dialog';
import { Root as RxSeparatorRoot } from '@radix-ui/react-separator';
import { Typography } from '@src/components/appearance/basics/Typography';
import type { TAsyncButtonAction, TButtonAction, TSyncButtonAction } from '@src/components/appearance/controls/Button';
import { ActionButton, Button } from '@src/components/appearance/controls/Button';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { DIALOG_CLASS_NAMES } from '@src/components/appearance/structure/Dialog';
import type { TDrawer } from '@src/components/appearance/structure/Drawer';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled, styledKeyframes } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import noop from 'lodash/noop';
import type { PropsWithChildren } from 'react';
import { Fragment, createContext, forwardRef, memo, useCallback, useContext, useMemo, useState } from 'react';
import { AnimatedBlock, interpolate, useAnimatedValue, useScroll } from 'react-ui-animate';

export type TChrome = {
  FooterBase: {
    buttonAction: TAsyncButtonAction | TSyncButtonAction;
    secondaryButtonAction?: TAsyncButtonAction | TSyncButtonAction | undefined;
  };
  HeaderBase: {
    title: string;
  };
  RootBase: {
    onOpenChange: (open: boolean) => void;
    open: boolean;
  };

  Footer: TProps<false, TChrome['FooterBase'], 'div'>;
  Header: TProps<false, TChrome['HeaderBase'], 'div'>;
  Root: PropsWithChildren<TChrome['RootBase']>;
};

export const CHROME_CLASS_NAMES = {
  Header: 'wp-chrome-header',
};

type TRootContext = { doChromeClose: () => void };
const RootContext = createContext<TRootContext | undefined>(undefined);

const Separator = memo(
  styled(RxSeparatorRoot, {
    alignSelf: 'stretch',
    backgroundColor: '$separatorBackground',
    height: '$separatorThickness',
  }),
);

const SHeaderDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  padding: '$chromePanelPaddingMobile',

  '@desktop': {
    padding: '$chromePanelPaddingDesktop',
  },
});

const Header = withCssToString(
  CHROME_CLASS_NAMES.Header,
  memo(
    forwardRef<HTMLDivElement, TChrome['Header']>(({ title, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, CHROME_CLASS_NAMES.Header), [className]);
      const rootContext = useContext(RootContext);
      const doChromeClose = useCallback(() => rootContext?.doChromeClose(), [rootContext]);

      return (
        <SHeaderDiv {...rest} className={joinedClassName} ref={ref}>
          <Typography.SubTitle expanding={true} rigid={true} text={title} />
          <IconButton icon='close' onClick={doChromeClose} />
        </SHeaderDiv>
      );
    }),
  ),
);

const Panel = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
});

const SubHeader = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  gap: '$chromePanelGapMobile',
  overflowY: 'scroll',
  paddingX: '$chromePanelPaddingMobile',

  '@desktop': {
    paddingX: '$chromePanelPaddingDesktop',
    gap: '$chromePanelGapDesktop',
  },
});

const SScrollContentRxSeparatorRoot = memo(
  styled(RxSeparatorRoot, {
    alignSelf: 'stretch',
    backgroundColor: '$separatorBackground',
    height: '$separatorThickness',
    marginX: '$chromePanelPaddingMobile',

    '@desktop': {
      marginX: '$chromePanelPaddingDesktop',
    },
  }),
);

const SScrollContentInnerDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$chromePanelGapMobile',
  //hideScrollbars: true,
  overflowY: 'scroll',
  padding: '$chromePanelPaddingMobile',

  '@desktop': {
    padding: '$chromePanelPaddingDesktop',
    gap: '$chromePanelGapDesktop',
  },
});

function ScrollContent({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const boundScrollY = useAnimatedValue(0, { immediate: true });
  const opacity: unknown = interpolate(boundScrollY.value, [0, 64], [0, 1], { extrapolate: 'clamp' });

  const bindScroll = useScroll(({ scrollY }) => {
    boundScrollY.value = scrollY;
  });

  return (
    <Fragment>
      <AnimatedBlock style={{ opacity }}>
        <SScrollContentRxSeparatorRoot />
      </AnimatedBlock>
      <SScrollContentInnerDiv {...bindScroll()}>{children}</SScrollContentInnerDiv>
    </Fragment>
  );
}

const ScrollContentLoader = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$chromePanelGapMobile',
  overflowY: 'hidden',
  padding: '$chromePanelPaddingMobile',

  '@desktop': {
    gap: '$chromePanelGapDesktop',
    padding: '$chromePanelPaddingDesktop',
  },
});

const SFooterDiv = styled('div', {
  alignItems: 'center',
  backgroundColor: '$chromeFooterBackground',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  marginBottom: '$chromePanelPaddingMobile',
  marginX: '$chromePanelPaddingMobile',
  padding: '$chromeFooterPadding',

  '@desktop': {
    marginBottom: '$chromePanelPaddingDesktop',
    marginX: '$chromePanelPaddingDesktop',
    padding: '$chromeFooterPadding',
  },
});

const SFooterActionButton = styled(ActionButton, {
  minWidth: '$chromeFooterButtonMinWidth',
});

const Footer = withCssToString(
  DIALOG_CLASS_NAMES.Footer,
  memo(
    forwardRef<HTMLDivElement, TDrawer['Footer']>(
      ({ buttonAction, secondaryButtonAction, className, ...rest }, ref) => {
        const joinedClassName = useMemo(() => joinClassNames(className, DIALOG_CLASS_NAMES.Footer), [className]);
        const [error, setError] = useState<string | null>(null);
        const { doErrorNotify } = useErrors();

        const primaryButtonAction = useMemo<TButtonAction>(
          () =>
            buttonAction.isAsync
              ? {
                  ...buttonAction,
                  onClick: async (): Promise<void> => {
                    try {
                      setError(null);
                      await buttonAction.onClick();
                    } catch (thrown: unknown) {
                      const errorSummary = getErrorSummary(thrown, {
                        forceDisplayMode: EErrorSummaryDisplayMode.SILENT,
                      });
                      doErrorNotify(errorSummary);
                      setError(errorSummary.display.message);
                    }
                  },
                }
              : {
                  ...buttonAction,
                  onClick: (): void => {
                    try {
                      setError(null);
                      buttonAction.onClick();
                    } catch (thrown: unknown) {
                      const errorSummary = getErrorSummary(thrown, {
                        forceDisplayMode: EErrorSummaryDisplayMode.SILENT,
                      });
                      doErrorNotify(errorSummary);
                      setError(errorSummary.display.message);
                    }
                  },
                },
          // @sort
          [buttonAction, doErrorNotify],
        );

        const secondaryButton = useMemo<TButtonAction | undefined>(
          () => {
            if (!isDef(secondaryButtonAction)) return undefined;
            return secondaryButtonAction.isAsync
              ? {
                  ...secondaryButtonAction,
                  onClick: async (): Promise<void> => {
                    try {
                      setError(null);
                      await secondaryButtonAction.onClick();
                    } catch (thrown: unknown) {
                      const errorSummary = getErrorSummary(thrown, {
                        forceDisplayMode: EErrorSummaryDisplayMode.SILENT,
                      });
                      doErrorNotify(errorSummary);
                      setError(errorSummary.display.message);
                    }
                  },
                }
              : {
                  ...secondaryButtonAction,
                  onClick: (): void => {
                    try {
                      setError(null);
                      secondaryButtonAction.onClick();
                    } catch (thrown: unknown) {
                      const errorSummary = getErrorSummary(thrown, {
                        forceDisplayMode: EErrorSummaryDisplayMode.SILENT,
                      });
                      doErrorNotify(errorSummary);
                      setError(errorSummary.display.message);
                    }
                  },
                };
          },
          // @sort
          [doErrorNotify, secondaryButtonAction],
        );

        return (
          <Fragment>
            <SFooterDiv {...rest} className={joinedClassName} ref={ref}>
              {isDef(error) ? (
                <Fragment>
                  <Typography.ErrorCaption error={error} />
                  <Button onClick={(): void => setError(null)} text='Reset' variant='secondary' />
                </Fragment>
              ) : (
                <Fragment>
                  {isDef(secondaryButtonAction) ? <SFooterActionButton action={ensureDef(secondaryButton)} /> : null}
                  <div style={{ flexGrow: 1 }} />
                  <SFooterActionButton action={primaryButtonAction} />
                </Fragment>
              )}
            </SFooterDiv>
          </Fragment>
        );
      },
    ),
  ),
);

const FooterToolBar = styled(ToolBar, {
  marginBottom: '$chromePanelPaddingMobile',
  marginX: '$chromePanelPaddingMobile',
  padding: '$chromeFooterPadding',

  '@desktop': {
    marginBottom: '$chromePanelPaddingDesktop',
    marginX: '$chromePanelPaddingDesktop',
    padding: '$chromeFooterPadding',
  },
});

const FooterLoader = memo(
  forwardRef<HTMLDivElement, { buttonText: string; secondaryButtonText?: string | undefined }>(
    ({ buttonText, secondaryButtonText }, ref): JSX.Element => (
      <SFooterDiv ref={ref}>
        {isDef(secondaryButtonText) && (
          <SFooterActionButton
            action={{
              isAsync: false,
              onClick: noop,
              text: secondaryButtonText,
              variant: 'disabled',
            }}
          />
        )}
        <div style={{ flexGrow: 1 }} />
        <SFooterActionButton
          action={{
            isAsync: false,
            onClick: noop,
            text: buttonText,
            variant: 'disabled',
          }}
        />
      </SFooterDiv>
    ),
  ),
);

const SRootRxDialogOverlay = memo(
  styled(RxDialogOverlay, {
    backgroundColor: '$chromeOverlayBackground',
    inset: 0,
    position: 'fixed',
    zIndex: '$chrome',

    '&[data-state=open]': {
      animation: `${styledKeyframes.fadeIn.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },

    '&[data-state=closed]': {
      animation: `${styledKeyframes.fadeOut.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
  }),
);

const SRootRxDialogContent = styled(RxDialogContent, {
  alignItems: 'stretch',
  backgroundColor: '$chromeContentBackground',
  boxShadow: '$chromeContent',
  display: 'flex',
  flexDirection: 'column',
  height: '$chromeContentHeightMobile',
  width: '$chromeContentWidthMobile',
  hideScrollbars: true,
  left: '50%',
  overflow: 'scroll',
  position: 'fixed',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',

  '@desktop': {
    borderRadius: '$chromeContent',
    width: '$chromeContentWidthDesktop',
    height: '$chromeContentHeightDesktop',
  },

  zIndex: '$chrome',

  '&[data-state=open]': {
    animation: `${styledKeyframes.fadeIn.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },

  '&[data-state=closed]': {
    animation: `${styledKeyframes.fadeOut.toString()} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

function Root({ open, onOpenChange, children }: TChrome['Root']): JSX.Element {
  const value = useMemo<TRootContext>(
    () => ({
      doChromeClose: () => onOpenChange(false),
    }),
    [onOpenChange],
  );

  return (
    <RootContext.Provider value={value}>
      <RXDialogRoot onOpenChange={onOpenChange} open={open}>
        <RxDialogPortal>
          <SRootRxDialogOverlay />
          <SRootRxDialogContent>{children}</SRootRxDialogContent>
        </RxDialogPortal>
      </RXDialogRoot>
    </RootContext.Provider>
  );
}

export const Chrome = {
  SubHeader,
  Footer,
  FooterLoader,
  FooterToolBar,
  Header,
  Panel,
  Root,
  ScrollContent,
  ScrollContentLoader,
  Separator,
};
