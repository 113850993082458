import { Drawer } from '@src/components/appearance/structure/Drawer';
import {
  LocationPickerDrawerPanel,
  LocationPickerDrawerPanelLoader,
} from '@src/components/mixins/drawers/LocationPickerDrawerPanel';
import type { TAgentLocationForPickerFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import {
  AgentLocationsForPickerProvider,
  useAgentLocationsForPicker,
} from '@src/modules/data/agent/global/operations/AgentLocationsForPickerProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TAgentLocationPickerDrawerTypes = {
  BeginLocationPickCallback: (location: TAgentLocationForPickerFragment) => Promise<void>;
  BeginLocationPickArgs: {
    organizationId: string;
    callback: TAgentLocationPickerDrawerTypes['BeginLocationPickCallback'];
    buttonActionText?: string | undefined;
    drawerHeaderTitle?: string | undefined;
    omitLocationIds?: { [key: string]: true } | undefined;
  };
  BeginLocationPick: (args: TAgentLocationPickerDrawerTypes['BeginLocationPickArgs']) => void;
};

export type TAgentLocationPickerDrawerContext = {
  beginLocationPick: TAgentLocationPickerDrawerTypes['BeginLocationPick'];
};

export const { Context: AgentLocationPickerDrawerContext, useContext: useAgentLocationPickerDrawer } =
  createRequiredContext<TAgentLocationPickerDrawerContext>();

export function AgentLocationPickerDrawerProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const [state, setState] = useState<TAgentLocationPickerDrawerTypes['BeginLocationPickArgs'] | undefined>(undefined);

  const handleOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setState(undefined);
    }
  }, []);

  const handleCallback = useCallback(
    async (location: TAgentLocationForPickerFragment): Promise<void> => {
      await state?.callback(location);
      setState(undefined);
    },
    [state],
  );

  const value = useMemo<TAgentLocationPickerDrawerContext>(
    () => ({
      beginLocationPick: setState,
    }),
    [],
  );

  return (
    <AgentLocationPickerDrawerContext.Provider value={value}>
      <Drawer.Root open={isDef(state)} onOpenChange={handleOpenChange}>
        {isDef(state) && (
          <AgentLocationsForPickerProvider
            LoaderComponent={(): JSX.Element => (
              <LocationPickerDrawerPanelLoader
                buttonActionText={state.buttonActionText}
                drawerHeaderTitle={state.drawerHeaderTitle}
              />
            )}
            organizationId={state.organizationId}>
            <ConsumerComponent
              callback={handleCallback}
              buttonActionText={state.buttonActionText}
              drawerHeaderTitle={state.drawerHeaderTitle}
              omitLocationIds={state.omitLocationIds}
            />
          </AgentLocationsForPickerProvider>
        )}
      </Drawer.Root>
      {children}
    </AgentLocationPickerDrawerContext.Provider>
  );
}

function ConsumerComponent({
  callback,
  buttonActionText,
  drawerHeaderTitle,
  omitLocationIds,
}: Omit<TAgentLocationPickerDrawerTypes['BeginLocationPickArgs'], 'organizationId'>): JSX.Element {
  const { locations, loaderRef } = useAgentLocationsForPicker();

  const filteredLocations = useMemo(() => {
    return locations.filter((loc) => !isDef(omitLocationIds?.[loc.id]));
  }, [locations, omitLocationIds]);

  return (
    <LocationPickerDrawerPanel
      loaderRef={loaderRef}
      locations={filteredLocations}
      onSelectLocation={callback}
      buttonActionText={buttonActionText}
      drawerHeaderTitle={drawerHeaderTitle}
    />
  );
}
