import { Description } from '@src/components/appearance/fragments/Description';
import { Dialog } from '@src/components/appearance/structure/Dialog';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { ConfirmationDialogPanel } from '@src/components/mixins/dialogs/ConfirmationDialogPanel';
import { FormularyProductsImportDrawerPanel } from '@src/components/mixins/drawers/FormularyProductsImportDrawerPanel';
import {
  OrganizationFormularyProductDrawerPanel,
  OrganizationFormularyProductDrawerPanelLoader,
} from '@src/components/mixins/drawers/OrganizationFormularyProductDrawerPanel';
import { LocationAclEditForm } from '@src/components/mixins/forms/LocationAclEditForm';
import { LocationAddForm } from '@src/components/mixins/forms/LocationAddForm';
import { LocationEditForm } from '@src/components/mixins/forms/LocationEditForm';
import { OrganizationAclEditForm } from '@src/components/mixins/forms/OrganizationAclEditForm';
import { OrganizationEditForm } from '@src/components/mixins/forms/OrganizationEditForm';
import type {
  AgentCustomersLocationAclRemoveMutationVariables,
  AgentCustomersLocationAclUpsertMutationVariables,
  AgentCustomersLocationAddMutationVariables,
  AgentCustomersLocationUpdateMutationVariables,
  AgentCustomersOrganizationAclUpsertMutationVariables,
  AgentCustomersOrganizationImportFormularyProductsMutation,
  AgentCustomersOrganizationImportFormularyProductsMutationVariables,
  AgentCustomersOrganizationSandboxDisableMutationVariables,
  AgentCustomersOrganizationSourceAddMutationVariables,
  AgentCustomersOrganizationSourceRemoveMutationVariables,
  AgentCustomersOrganizationUpdateMutationVariables,
  TAgentPublicCatalogProductBaseFragment,
} from '@src/gen/graphql/bindings';
import {
  AgentWpxLegacyProductsSearchDocument,
  useAgentCustomersLocationAclRemoveMutation,
  useAgentCustomersLocationAclUpsertMutation,
  useAgentCustomersLocationAddMutation,
  useAgentCustomersLocationUpdateMutation,
  useAgentCustomersOrganizationAclUpsertMutation,
  useAgentCustomersOrganizationImportFormularyProductsMutation,
  useAgentCustomersOrganizationSandboxDisableMutation,
  useAgentCustomersOrganizationSourceAddMutation,
  useAgentCustomersOrganizationSourceRemoveMutation,
  useAgentCustomersOrganizationUpdateMutation,
  useAgentWpxProductListMutateMutation,
} from '@src/gen/graphql/bindings';
import { EDescriptionBlockType } from '@src/gen/shared/data/description';
import { getCombinedProductName, getFullName } from '@src/gen/shared/data/snippets';
import type { ESource } from '@src/gen/shared/enums/source';
import { getSource, getSourceName } from '@src/gen/shared/enums/source';
import { ensureDef, ensureNotEmptyString, isDef, isNotEmptyString } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useAuthAgent } from '@src/modules/auth/AuthProvider';
import { useAgentCustomersOrganization } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import {
  AgentPublicCatalogProductProvider,
  useAgentPublicCatalogProduct,
} from '@src/modules/data/agent/global/operations/AgentPublicCatalogProductProvider';
import { useAgentLocationPickerDrawer } from '@src/modules/data/agent/global/overlays/AgentLocationPickerDrawerProvider';
import { useAgentCartProductsChrome } from '@src/modules/data/agent/global/overlays/AgentOrganizationCartProductsChromeProvider';
import { useAgentOrganizationSourcePickerDrawer } from '@src/modules/data/agent/global/overlays/AgentOrganizationSourcePickerDrawerProvider';
import { useAgentOrganizationUserPickerDrawer } from '@src/modules/data/agent/global/overlays/AgentOrganizationUserPickerDrawerProvider';
import { useAgentProductPickerChrome } from '@src/modules/data/agent/global/overlays/AgentProductPickerChromeProvider';
import { useAgentUserPickerDrawer } from '@src/modules/data/agent/global/overlays/AgentUserPickerDrawerProvider';
import { useAgentUploads } from '@src/modules/data/agent/global/uploads/AgentUploadsProvider';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useDialog } from '@src/modules/design/DialogProvider';
import type { TInlineDrawer } from '@src/modules/design/DrawerProvider';
import { InlineDrawer, useDrawer, useInlineDrawer } from '@src/modules/design/DrawerProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TAgentCustomersOrganizationActionsTypes = {
  BeginFormularyProductAdd: () => void;
  BeginFormularyProductRemove: (args: {
    publicCatalogProductId: string;
    name: string;
    secondaryName: string | null;
  }) => void;
  BeginPublicCatalogProductProductView: (args: { publicCatalogProductId: string }) => void;
  BeginLocationEdit: (args: { locationId: string }) => void;
  BeginLocationAdd: () => void;
  BeginLocationAclEdit: (args: { locationId: string; userId: string }) => void;
  BeginLocationAclAddGivenLocation: (args: {
    locationId: string;
    omitUserIds: { [key: string]: true } | undefined;
  }) => void;
  BeginLocationAclAddGivenUser: (args: {
    userId: string;
    omitLocationIds: { [key: string]: true } | undefined;
  }) => void;
  BeginLocationAclRemove: (args: { locationId: string; userId: string }) => void;
  BeginOrganizationAclEdit: (args: { userId: string }) => void;
  BeginOrganizationEdit: () => void;
  BeginOrganizationSandboxDisable: () => void;
  BeginOrganizationFormularyProductsImport: () => void;
  BeginOrganizationSourceRemove: (args: { source: ESource }) => void;
  BeginOrganizationSourceAdd: () => void;
  BeginOrganizationUserAdd: () => void;
  BeginOrganizationUserRemove: (args: { userId: string }) => void;
  BeginOrganizationUserRestore: (args: { userId: string }) => void;
  BeginCartProductsView: (args: { locationId: string }) => void;
  DoCustomersOrganizationAclUpsertAsync: (
    args: Omit<AgentCustomersOrganizationAclUpsertMutationVariables, 'organizationId'>,
  ) => Promise<void>;
  DoCustomersLocationUpdateAsync: (args: AgentCustomersLocationUpdateMutationVariables) => Promise<void>;
  DoCustomersLocationAddAsync: (args: AgentCustomersLocationAddMutationVariables) => Promise<void>;
  DoCustomersLocationAclUpsertAsync: (args: AgentCustomersLocationAclUpsertMutationVariables) => Promise<void>;
  DoCustomersLocationAclRemoveAsync: (args: AgentCustomersLocationAclRemoveMutationVariables) => Promise<void>;
  DoCustomersOrganizationSourceRemoveAsync: (
    args: AgentCustomersOrganizationSourceRemoveMutationVariables,
  ) => Promise<void>;
  DoCustomersOrganizationSourceAddAsync: (args: AgentCustomersOrganizationSourceAddMutationVariables) => Promise<void>;
  DoCustomersOrganizationUpdateAsync: (
    args: Omit<AgentCustomersOrganizationUpdateMutationVariables, 'organizationId'>,
  ) => Promise<void>;
  DoCustomersOrganizationSandboxDisableAsync: (
    args: Omit<AgentCustomersOrganizationSandboxDisableMutationVariables, 'organizationId'>,
  ) => Promise<void>;
  DoCustomersOrganizationImportResult: AgentCustomersOrganizationImportFormularyProductsMutation['importFormularyProducts'];
  DoCustomersOrganizationImportFormularyProductsAsync: (
    args: Omit<AgentCustomersOrganizationImportFormularyProductsMutationVariables, 'organizationId'>,
  ) => Promise<TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationImportResult']>;
  DoFormularyProductAddAsyncNotify: (args: { publicCatalogProductId: string }) => Promise<void>;
  DoFormularyProductRemoveAsync: (args: { publicCatalogProductId: string }) => Promise<void>;
};

export type TAgentCustomersOrganizationActionsContext = {
  beginFormularyProductAdd: TAgentCustomersOrganizationActionsTypes['BeginFormularyProductAdd'] | null;
  beginFormularyProductRemove: TAgentCustomersOrganizationActionsTypes['BeginFormularyProductRemove'] | null;
  beginLocationAclAddGivenLocation: TAgentCustomersOrganizationActionsTypes['BeginLocationAclAddGivenLocation'] | null;
  beginLocationAclAddGivenUser: TAgentCustomersOrganizationActionsTypes['BeginLocationAclAddGivenUser'] | null;
  beginLocationAclEdit: TAgentCustomersOrganizationActionsTypes['BeginLocationAclEdit'] | null;
  beginLocationAclRemove: TAgentCustomersOrganizationActionsTypes['BeginLocationAclRemove'] | null;
  beginLocationAdd: TAgentCustomersOrganizationActionsTypes['BeginLocationAdd'] | null;
  beginLocationEdit: TAgentCustomersOrganizationActionsTypes['BeginLocationEdit'] | null;
  beginOrganizationAclEdit: TAgentCustomersOrganizationActionsTypes['BeginOrganizationAclEdit'] | null;
  beginOrganizationFormularyProductsImport:
    | TAgentCustomersOrganizationActionsTypes['BeginOrganizationFormularyProductsImport']
    | null;
  beginOrganizationSandboxDisable: TAgentCustomersOrganizationActionsTypes['BeginOrganizationSandboxDisable'] | null;
  beginOrganizationEdit: TAgentCustomersOrganizationActionsTypes['BeginOrganizationEdit'] | null;
  beginOrganizationUserAdd: TAgentCustomersOrganizationActionsTypes['BeginOrganizationUserAdd'] | null;
  beginOrganizationUserRemove: TAgentCustomersOrganizationActionsTypes['BeginOrganizationUserRemove'] | null;
  beginOrganizationUserRestore: TAgentCustomersOrganizationActionsTypes['BeginOrganizationUserRestore'] | null;
  beginPublicCatalogProductView: TAgentCustomersOrganizationActionsTypes['BeginPublicCatalogProductProductView'];
  beginOrganizationSourceRemove: TAgentCustomersOrganizationActionsTypes['BeginOrganizationSourceRemove'] | null;
  beginOrganizationSourceAdd: TAgentCustomersOrganizationActionsTypes['BeginOrganizationSourceAdd'] | null;
  beginCartProductsView: TAgentCustomersOrganizationActionsTypes['BeginCartProductsView'];
};

export const { Context: AgentCustomersOrganizationActionsContext, useContext: useAgentCustomersOrganizationActions } =
  createRequiredContext<TAgentCustomersOrganizationActionsContext>();

export function AgentCustomersOrganizationActionsProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const user = useAuthAgent();
  const { organization } = useAgentCustomersOrganization();

  const beginFormularyProductAdd = useBeginFormularyProductAdd();
  const beginFormularyProductRemove = useBeginFormularyProductRemove();
  const beginLocationAclAddGivenLocation = useBeginLocationAclAddGivenLocation();
  const beginLocationAclAddGivenUser = useBeginLocationAclAddGivenUser();
  const beginLocationAclEdit = useBeginLocationAclEdit();
  const beginLocationAclRemove = useBeginLocationAclRemove();
  const beginLocationEdit = useBeginLocationEdit();
  const beginLocationAdd = useBeginLocationAdd();
  const beginOrganizationAclEdit = useBeginOrganizationAclEdit();
  const beginOrganizationFormularyProductsImport = useBeginOrganizationFormularyProductsImport();
  const beginOrganizationEdit = useBeginOrganizationEdit();
  const beginOrganizationSandboxDisable = useBeginOrganizationSandboxDisable();
  const beginOrganizationUserAdd = useBeginOrganizationUserAdd();
  const beginOrganizationUserRemove = useBeginOrganizationUserRemove();
  const beginOrganizationUserRestore = useBeginOrganizationUserRestore();
  const beginOrganizationSourceRemove = useBeginOrganizationSourceRemove();
  const beginOrganizationSourceAdd = useBeginOrganizationSourceAdd();
  const { beginPublicCatalogProductView, publicCatalogProductViewInlineDrawer } = useBeginPublicCatalogProductView();
  const beginCartProductsView = useBeginCartProductsView();
  const canEditOrgLoc = user.agent_can_edit_organizations_and_locations;
  const canCreateOrgLoc = user.agent_can_create_organizations_and_locations;
  const canEditOrgLocAcls = user.agent_can_edit_organization_acls_and_location_acls;

  const value = useMemo<TAgentCustomersOrganizationActionsContext>(
    () => ({
      beginFormularyProductAdd:
        canEditOrgLoc && organization.organization_sources.length > 0 ? beginFormularyProductAdd : null,
      beginFormularyProductRemove: canEditOrgLoc ? beginFormularyProductRemove : null,
      beginLocationAclAddGivenLocation: canEditOrgLocAcls ? beginLocationAclAddGivenLocation : null,
      beginLocationAclAddGivenUser: canEditOrgLocAcls ? beginLocationAclAddGivenUser : null,
      beginLocationAclEdit: canEditOrgLocAcls ? beginLocationAclEdit : null,
      beginLocationAclRemove: canEditOrgLocAcls ? beginLocationAclRemove : null,
      beginLocationEdit: canEditOrgLoc ? beginLocationEdit : null,
      beginLocationAdd: canCreateOrgLoc ? beginLocationAdd : null,
      beginOrganizationAclEdit: canEditOrgLocAcls ? beginOrganizationAclEdit : null,
      beginOrganizationFormularyProductsImport: canEditOrgLoc ? beginOrganizationFormularyProductsImport : null,
      beginOrganizationEdit: canEditOrgLoc ? beginOrganizationEdit : null,
      beginOrganizationSandboxDisable: canEditOrgLoc ? beginOrganizationSandboxDisable : null,
      beginOrganizationUserAdd: canEditOrgLocAcls ? beginOrganizationUserAdd : null,
      beginOrganizationUserRemove: canEditOrgLocAcls ? beginOrganizationUserRemove : null,
      beginOrganizationUserRestore: canEditOrgLocAcls ? beginOrganizationUserRestore : null,
      beginOrganizationSourceRemove: canEditOrgLoc ? beginOrganizationSourceRemove : null,
      beginOrganizationSourceAdd: canEditOrgLoc ? beginOrganizationSourceAdd : null,
      beginPublicCatalogProductView,
      beginCartProductsView,
    }),
    // @sort
    [
      beginCartProductsView,
      beginFormularyProductAdd,
      beginFormularyProductRemove,
      beginLocationAclAddGivenLocation,
      beginLocationAclAddGivenUser,
      beginLocationAclEdit,
      beginLocationAclRemove,
      beginLocationAdd,
      beginLocationEdit,
      beginOrganizationAclEdit,
      beginOrganizationEdit,
      beginOrganizationFormularyProductsImport,
      beginOrganizationSandboxDisable,
      beginOrganizationSourceAdd,
      beginOrganizationSourceRemove,
      beginOrganizationUserAdd,
      beginOrganizationUserRemove,
      beginOrganizationUserRestore,
      beginPublicCatalogProductView,
      canCreateOrgLoc,
      canEditOrgLoc,
      canEditOrgLocAcls,
      organization.organization_sources.length,
    ],
  );

  return (
    <AgentCustomersOrganizationActionsContext.Provider value={value}>
      <InlineDrawer {...publicCatalogProductViewInlineDrawer} />
      {children}
    </AgentCustomersOrganizationActionsContext.Provider>
  );
}

function useBeginFormularyProductAdd(): TAgentCustomersOrganizationActionsTypes['BeginFormularyProductAdd'] {
  const [wpxProductListMutateMutation] = useAgentWpxProductListMutateMutation();
  const { beginProductPick } = useAgentProductPickerChrome();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { organization } = useAgentCustomersOrganization();

  const doFormularyProductAddAsyncNotify = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoFormularyProductAddAsyncNotify']
  >(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await wpxProductListMutateMutation({
          variables: {
            productListId: organization.id,
            addProductIds: [args.publicCatalogProductId],
            removeProductIds: [],
          },
          refetchQueries: [AgentWpxLegacyProductsSearchDocument],
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While adding formulary product.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, wpxProductListMutateMutation, organization.id],
  );

  return useCallback<TAgentOrderActionsTypes['BeginOrderEntryAdd']>(
    () => {
      beginProductPick({
        showPreferredCatalogsOption: false,
        restrictSourceOptionsTo: organization.organization_sources.reduce<{ [key in ESource]?: true }>(
          (out, os) => ({
            ...out,
            [getSource(os.source)]: true,
          }),
          {},
        ),
        initialSearchQuery: null,
        callback: (publicCatalogProduct: TAgentPublicCatalogProductBaseFragment): void => {
          void doFormularyProductAddAsyncNotify({ publicCatalogProductId: publicCatalogProduct.id });
        },
      });
    },
    // @sort
    [beginProductPick, doFormularyProductAddAsyncNotify, organization.organization_sources],
  );
}

function useBeginFormularyProductRemove(): TAgentCustomersOrganizationActionsTypes['BeginFormularyProductRemove'] {
  const { doDialogClose, doDialogOpen } = useDialog();
  const { organization } = useAgentCustomersOrganization();
  const [wpxProductListMutateMutation] = useAgentWpxProductListMutateMutation();

  const doFormularyProductRemoveAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoFormularyProductRemoveAsync']
  >(
    async (args) => {
      const { errors } = await wpxProductListMutateMutation({
        variables: {
          productListId: organization.id,
          addProductIds: [],
          removeProductIds: [args.publicCatalogProductId],
        },
        refetchQueries: [AgentWpxLegacyProductsSearchDocument],
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDialogClose();
      // TODO(ibrt): Refetch.
    },
    // @sort
    [doDialogClose, wpxProductListMutateMutation, organization.id],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginFormularyProductRemove']>(
    (args) => {
      doDialogOpen(
        <ConfirmationDialogPanel
          title='Remove Formulary Product'
          message={[
            `Remove product "${getCombinedProductName(args.name, args.secondaryName)}"`,
            `from the formulary of organization "${organization.name}"?`,
          ].join(' ')}
          action='Remove'
          onConfirm={async (): Promise<void> =>
            await doFormularyProductRemoveAsync({
              publicCatalogProductId: args.publicCatalogProductId,
            })
          }
        />,
      );
    },
    // @sort
    [doDialogOpen, doFormularyProductRemoveAsync, organization.name],
  );
}

function useBeginLocationAclAddGivenLocation(): TAgentCustomersOrganizationActionsTypes['BeginLocationAclAddGivenLocation'] {
  const [customersLocationAclUpsertMutation] = useAgentCustomersLocationAclUpsertMutation();
  const { beginUserPick } = useAgentOrganizationUserPickerDrawer();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersLocationAclUpsertAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersLocationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersLocationAclUpsertMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }
      await doRefetchOrganization();
    },
    // @sort
    [customersLocationAclUpsertMutation, doRefetchOrganization],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginLocationAclAddGivenLocation']>(
    (args) => {
      beginUserPick({
        buttonActionText: 'Add',
        drawerHeaderTitle: 'Add User to Location',
        callback: async (user): Promise<void> => {
          await doCustomersLocationAclUpsertAsync({
            locationId: args.locationId,
            userId: user.id,
            canViewOrders: true,
            canSendOrderMessages: false,
            canPlaceOrders: false,
            canApproveOrders: false,
            canViewAnalytics: false,
          });
        },
        organizationId: organization.id,
        omitUserIds: args.omitUserIds,
      });
    },
    // @sort
    [beginUserPick, doCustomersLocationAclUpsertAsync, organization.id],
  );
}

function useBeginLocationAclAddGivenUser(): TAgentCustomersOrganizationActionsTypes['BeginLocationAclAddGivenUser'] {
  const [customersLocationAclUpsertMutation] = useAgentCustomersLocationAclUpsertMutation();
  const { beginLocationPick } = useAgentLocationPickerDrawer();

  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersLocationAclUpsertAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersLocationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersLocationAclUpsertMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }
      await doRefetchOrganization();
    },
    // @sort
    [customersLocationAclUpsertMutation, doRefetchOrganization],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginLocationAclAddGivenUser']>(
    (args) => {
      beginLocationPick({
        organizationId: organization.id,
        buttonActionText: 'Add',
        drawerHeaderTitle: 'Add User to Location',
        callback: async (location): Promise<void> => {
          await doCustomersLocationAclUpsertAsync({
            locationId: location.id,
            userId: args.userId,
            canViewOrders: true,
            canSendOrderMessages: false,
            canPlaceOrders: false,
            canApproveOrders: false,
            canViewAnalytics: false,
          });
        },
        omitLocationIds: args.omitLocationIds,
      });
    },
    // @sort
    [beginLocationPick, doCustomersLocationAclUpsertAsync, organization.id],
  );
}

function useBeginLocationAclEdit(): TAgentCustomersOrganizationActionsTypes['BeginLocationAclEdit'] {
  const [customersLocationAclUpsertMutation] = useAgentCustomersLocationAclUpsertMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersLocationAclUpsertAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersLocationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersLocationAclUpsertMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDrawerClose();
    },
    // @sort
    [customersLocationAclUpsertMutation, doDrawerClose, doRefetchOrganization],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginLocationAclEdit']>(
    (args) => {
      const location = ensureDef(organization.locations.find((loc) => loc.id === args.locationId));
      const locationAcl = ensureDef(location.location_acls.find((locAcl) => locAcl.user_id === args.userId));
      const organizationAcl = ensureDef(
        organization.organization_acls.find((orgAcl) => orgAcl.user_id === args.userId),
      );

      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Edit User Permissions' />
          <Drawer.ScrollContent>
            <LocationAclEditForm
              doCustomersLocationAclUpsertAsync={doCustomersLocationAclUpsertAsync}
              locationAcl={locationAcl}
              locationName={location.name}
              userFullName={getFullName(locationAcl.user)}
              canEditCanViewAnalytics={!organizationAcl.can_view_analytics}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersLocationAclUpsertAsync, doDrawerOpen, organization.locations, organization.organization_acls],
  );
}

function useBeginLocationAclRemove(): TAgentCustomersOrganizationActionsTypes['BeginLocationAclRemove'] {
  const [customersLocationAclRemoveMutation] = useAgentCustomersLocationAclRemoveMutation();
  const { doDialogClose, doDialogOpen } = useDialog();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersLocationAclRemoveAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersLocationAclRemoveAsync']
  >(
    async (args) => {
      const { errors } = await customersLocationAclRemoveMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDialogClose();
    },
    // @sort
    [customersLocationAclRemoveMutation, doDialogClose, doRefetchOrganization],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginLocationAclRemove']>(
    (args) => {
      const location = ensureDef(organization.locations.find((loc) => loc.id === args.locationId));
      const locationAcl = ensureDef(location.location_acls.find((locAcl) => locAcl.user_id === args.userId));

      doDialogOpen(
        <ConfirmationDialogPanel
          title='Remove User from Location'
          message={[`Remove user "${getFullName(locationAcl.user)}"`, `from location "${location.name}"?`].join(' ')}
          action='Remove'
          onConfirm={async (): Promise<void> =>
            await doCustomersLocationAclRemoveAsync({ userId: args.userId, locationId: args.locationId })
          }
        />,
      );
    },
    // @sort
    [doCustomersLocationAclRemoveAsync, doDialogOpen, organization.locations],
  );
}

function useBeginLocationAdd(): TAgentCustomersOrganizationActionsTypes['BeginLocationAdd'] {
  const [customersLocationAddMutation] = useAgentCustomersLocationAddMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersLocationAddAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersLocationAddAsync']
  >(
    async (args) => {
      const trimmedShipTo = args.shipTo
        .split('\n')
        .map((l) => l.trim())
        .filter((l) => l.length > 0)
        .join('\n')
        .trim();

      const trimmedBillTo = args.billTo
        .split('\n')
        .map((l) => l.trim())
        .filter((l) => l.length > 0)
        .join('\n')
        .trim();

      const { errors } = await customersLocationAddMutation({
        variables: {
          ...args,
          shipTo: ensureNotEmptyString(trimmedShipTo),
          billTo: ensureNotEmptyString(trimmedBillTo),
          stripeCustomerId: isNotEmptyString(args.stripeCustomerId) ? args.stripeCustomerId : null,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDrawerClose();
    },
    // @sort
    [customersLocationAddMutation, doDrawerClose, doRefetchOrganization],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginLocationAdd']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Add Location' />
          <Drawer.ScrollContent>
            <LocationAddForm
              doCustomersLocationAddAsync={doCustomersLocationAddAsync}
              organizationId={organization.id}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersLocationAddAsync, doDrawerOpen, organization.id],
  );
}

function useBeginLocationEdit(): TAgentCustomersOrganizationActionsTypes['BeginLocationEdit'] {
  const [customersLocationUpdateMutation] = useAgentCustomersLocationUpdateMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersLocationUpdateAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersLocationUpdateAsync']
  >(
    async (args) => {
      const trimmedShipTo =
        args.shipTo
          ?.split('\n')
          .map((l) => l.trim())
          .filter((l) => l.length > 0)
          .join('\n')
          .trim() ?? null;

      const trimmedBillTo =
        args.billTo
          ?.split('\n')
          .map((l) => l.trim())
          .filter((l) => l.length > 0)
          .join('\n')
          .trim() ?? null;

      const shipTo = (trimmedShipTo?.length ?? 0) > 0 ? trimmedShipTo : null;
      const billTo = (trimmedBillTo?.length ?? 0) > 0 ? trimmedBillTo : null;

      const { errors } = await customersLocationUpdateMutation({
        variables: {
          locationId: args.locationId,
          requiresPrebillApproval: args.requiresPrebillApproval,
          requiresPrebillApprovalThreshold: args.requiresPrebillApprovalThreshold,
          hasAccountStewardship: args.hasAccountStewardship,
          shipTo,
          billTo,
          stripeCustomerId: isNotEmptyString(args.stripeCustomerId) ? args.stripeCustomerId : null,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDrawerClose();
    },
    // @sort
    [customersLocationUpdateMutation, doDrawerClose, doRefetchOrganization],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginLocationEdit']>(
    (args) => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Edit Location' />
          <Drawer.ScrollContent>
            <LocationEditForm
              doCustomersLocationUpdateAsync={doCustomersLocationUpdateAsync}
              location={ensureDef(organization.locations.find((loc) => loc.id === args.locationId))}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersLocationUpdateAsync, doDrawerOpen, organization.locations],
  );
}

function useBeginOrganizationAclEdit(): TAgentCustomersOrganizationActionsTypes['BeginOrganizationAclEdit'] {
  const [customersOrganizationAclUpsertMutation] = useAgentCustomersOrganizationAclUpsertMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersOrganizationAclUpsertAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationAclUpsertMutation({
        variables: {
          ...args,
          organizationId: organization.id,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDrawerClose();
    },
    // @sort
    [customersOrganizationAclUpsertMutation, doDrawerClose, doRefetchOrganization, organization.id],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginOrganizationAclEdit']>(
    (args) => {
      const organizationAcl = ensureDef(
        organization.organization_acls.find((orgAcl) => orgAcl.user_id === args.userId),
      );

      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Edit User Permissions' />
          <Drawer.ScrollContent>
            <OrganizationAclEditForm
              doCustomersOrganizationAclUpsertAsyncGivenOrganization={doCustomersOrganizationAclUpsertAsync}
              doCustomersOrganizationAclUpsertAsyncGivenUser={null}
              organizationAcl={organizationAcl}
              organizationName={organization.name}
              userFullName={getFullName(organizationAcl.user)}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersOrganizationAclUpsertAsync, doDrawerOpen, organization.name, organization.organization_acls],
  );
}

function useBeginOrganizationFormularyProductsImport(): TAgentCustomersOrganizationActionsTypes['BeginOrganizationFormularyProductsImport'] {
  const [customersOrganizationImportFormularyProductsMutation] =
    useAgentCustomersOrganizationImportFormularyProductsMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();
  const { doUploadAsync } = useAgentUploads();

  const doCustomersOrganizationImportFormularyProductsAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationImportFormularyProductsAsync']
  >(
    async (args) => {
      const { data, errors } = await customersOrganizationImportFormularyProductsMutation({
        variables: {
          ...args,
          organizationId: organization.id,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      return ensureDef(data).importFormularyProducts;
    },
    // @sort
    [customersOrganizationImportFormularyProductsMutation, doRefetchOrganization, organization.id],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginOrganizationFormularyProductsImport']>(
    () => {
      doDrawerOpen(
        <FormularyProductsImportDrawerPanel
          doCustomersOrganizationImportFormularyProductsAsync={doCustomersOrganizationImportFormularyProductsAsync}
          doUploadAsync={doUploadAsync}
          doDrawerClose={doDrawerClose}
        />,
      );
    },
    // @sort
    [doCustomersOrganizationImportFormularyProductsAsync, doDrawerClose, doDrawerOpen, doUploadAsync],
  );
}

function useBeginOrganizationSandboxDisable(): TAgentCustomersOrganizationActionsTypes['BeginOrganizationSandboxDisable'] {
  const [customersOrganizationSandboxDisableMutation] = useAgentCustomersOrganizationSandboxDisableMutation();
  const { doDialogOpen, doDialogClose } = useDialog();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersOrganizationSandboxDisableAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationSandboxDisableAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationSandboxDisableMutation({
        variables: {
          organizationId: organization.id,
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDialogClose();
    },
    [customersOrganizationSandboxDisableMutation, doDialogClose, doRefetchOrganization, organization.id],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginOrganizationSandboxDisable']>(
    () => {
      doDialogOpen(
        <Dialog.Panel>
          <Dialog.Header title='Disable Sandbox' />
          <Description
            description={[
              {
                type: EDescriptionBlockType.TITLE,
                title: 'What Happens Next',
              },
              {
                type: EDescriptionBlockType.FEATURES,
                features: [
                  'Products from disabled catalogs will be removed from carts.',
                  'Customers will be able to place orders.',
                ],
              },
              {
                type: EDescriptionBlockType.TITLE,
                title: 'Note',
              },
              {
                type: EDescriptionBlockType.PARAGRAPH,
                paragraph: 'This operation cannot be undone.',
              },
            ]}
          />
          <Dialog.Footer
            buttonAction={{
              isAsync: true,
              text: 'Disable Sandbox',
              onClick: async () => await doCustomersOrganizationSandboxDisableAsync({}),
            }}
          />
        </Dialog.Panel>,
      );
    },
    // @sort
    [doCustomersOrganizationSandboxDisableAsync, doDialogOpen],
  );
}

function useBeginOrganizationEdit(): TAgentCustomersOrganizationActionsTypes['BeginOrganizationEdit'] {
  const [customersOrganizationUpdateMutation] = useAgentCustomersOrganizationUpdateMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersOrganizationUpdateAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationUpdateAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationUpdateMutation({
        variables: {
          organizationId: organization.id,
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDrawerClose();
    },
    // @sort
    [customersOrganizationUpdateMutation, doDrawerClose, doRefetchOrganization, organization.id],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginOrganizationEdit']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Edit Organization' />
          <Drawer.ScrollContent>
            <OrganizationEditForm
              doCustomersOrganizationUpdateAsync={doCustomersOrganizationUpdateAsync}
              organization={organization}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersOrganizationUpdateAsync, doDrawerOpen, organization],
  );
}

function useBeginOrganizationSourceAdd(): TAgentCustomersOrganizationActionsTypes['BeginOrganizationSourceAdd'] {
  const [customersOrganizationSourceAddMutation] = useAgentCustomersOrganizationSourceAddMutation();
  const { beginOrganizationSourcePick } = useAgentOrganizationSourcePickerDrawer();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersOrganizationSourceCreateAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationSourceAddAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationSourceAddMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
    },
    // @sort
    [customersOrganizationSourceAddMutation, doRefetchOrganization],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginOrganizationSourceAdd']>(
    () => {
      beginOrganizationSourcePick({
        buttonActionText: 'Add',
        drawerHeaderTitle: 'Add Catalog to Organization',
        callback: async (source): Promise<void> => {
          await doCustomersOrganizationSourceCreateAsync({
            source: source,
            organizationId: organization.id,
          });
        },
        omitSources: organization.organization_sources.reduce<{ [key in ESource]?: true }>(
          (out, os) => ({ ...out, [getSource(os.source)]: true }),
          {},
        ),
      });
    },
    // @sort
    [
      beginOrganizationSourcePick,
      doCustomersOrganizationSourceCreateAsync,
      organization.id,
      organization.organization_sources,
    ],
  );
}

function useBeginOrganizationSourceRemove(): TAgentCustomersOrganizationActionsTypes['BeginOrganizationSourceRemove'] {
  const [customersOrganizationSourceRemoveMutation] = useAgentCustomersOrganizationSourceRemoveMutation();
  const { doDialogClose, doDialogOpen } = useDialog();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersOrganizationSourceRemoveAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationSourceRemoveAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationSourceRemoveMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDialogClose();
    },
    // @sort
    [customersOrganizationSourceRemoveMutation, doDialogClose, doRefetchOrganization],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginOrganizationSourceRemove']>(
    (args) => {
      doDialogOpen(
        <ConfirmationDialogPanel
          title='Remove Catalog from Organization'
          message={[`Remove catalog "${getSourceName(args.source)}"`, `from organization "${organization.name}"?`].join(
            ' ',
          )}
          action='Remove'
          onConfirm={async (): Promise<void> =>
            await doCustomersOrganizationSourceRemoveAsync({ organizationId: organization.id, source: args.source })
          }
        />,
      );
    },
    // @sort
    [doCustomersOrganizationSourceRemoveAsync, doDialogOpen, organization.id, organization.name],
  );
}

function useBeginOrganizationUserAdd(): TAgentCustomersOrganizationActionsTypes['BeginOrganizationUserAdd'] {
  const [customersOrganizationAclUpsertMutation] = useAgentCustomersOrganizationAclUpsertMutation();
  const { beginUserPick } = useAgentUserPickerDrawer();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersOrganizationAclUpsertAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationAclUpsertMutation({
        variables: {
          organizationId: organization.id,
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
    },
    // @sort
    [customersOrganizationAclUpsertMutation, doRefetchOrganization, organization.id],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginOrganizationUserAdd']>(
    () => {
      beginUserPick({
        buttonActionText: 'Add',
        drawerHeaderTitle: 'Add User to Organization',
        callback: async (user): Promise<void> => {
          await doCustomersOrganizationAclUpsertAsync({
            userId: user.id,
            isMember: true,
            canViewAnalytics: false,
          });
        },
        isWellplaeceAgent: false,
        omitUserIds: organization.organization_acls.reduce<{ [key: string]: true }>(
          (out, orgAcl) => ({ ...out, [orgAcl.user_id]: true }),
          {},
        ),
      });
    },
    // @sort
    [beginUserPick, doCustomersOrganizationAclUpsertAsync, organization.organization_acls],
  );
}

function useBeginOrganizationUserRemove(): TAgentCustomersOrganizationActionsTypes['BeginOrganizationUserRemove'] {
  const [customersOrganizationAclUpsertMutation] = useAgentCustomersOrganizationAclUpsertMutation();
  const { doDialogClose, doDialogOpen } = useDialog();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersOrganizationAclUpsertAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationAclUpsertMutation({
        variables: {
          organizationId: organization.id,
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDialogClose();
    },
    // @sort
    [customersOrganizationAclUpsertMutation, doDialogClose, doRefetchOrganization, organization.id],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginOrganizationUserRemove']>(
    (args) => {
      const user = ensureDef(organization.organization_acls.find((oa) => oa.user_id === args.userId)).user;

      doDialogOpen(
        <ConfirmationDialogPanel
          title='Remove User from Organization'
          message={[
            `Remove user "${getFullName(user)}"`,
            `from organization "${organization.name}"?`,
            'This action will also remove all location permissions for this user within the organization.',
          ].join(' ')}
          action='Remove'
          onConfirm={async (): Promise<void> =>
            await doCustomersOrganizationAclUpsertAsync({
              userId: args.userId,
              isMember: false,
              canViewAnalytics: false,
            })
          }
        />,
      );
    },
    // @sort
    [doCustomersOrganizationAclUpsertAsync, doDialogOpen, organization],
  );
}

function useBeginOrganizationUserRestore(): TAgentCustomersOrganizationActionsTypes['BeginOrganizationUserRestore'] {
  const [customersOrganizationAclUpsertMutation] = useAgentCustomersOrganizationAclUpsertMutation();
  const { doDialogClose, doDialogOpen } = useDialog();
  const { organization, doRefetchOrganization } = useAgentCustomersOrganization();

  const doCustomersOrganizationAclUpsertAsync = useCallback<
    TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationAclUpsertMutation({
        variables: {
          organizationId: organization.id,
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchOrganization();
      doDialogClose();
    },
    // @sort
    [customersOrganizationAclUpsertMutation, doDialogClose, doRefetchOrganization, organization.id],
  );

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginOrganizationUserRestore']>(
    (args) => {
      const user = ensureDef(organization.organization_acls.find((oa) => oa.user_id === args.userId)).user;

      doDialogOpen(
        <ConfirmationDialogPanel
          title='Restore User to Organization'
          message={[
            `Restore user "${getFullName(user)}"`,
            `to organization "${organization.name}"?`,
            'Note that any location permissions the user previously had will have to be added again.',
          ].join(' ')}
          action='Restore'
          onConfirm={async (): Promise<void> =>
            await doCustomersOrganizationAclUpsertAsync({
              userId: args.userId,
              isMember: true,
              canViewAnalytics: false,
            })
          }
        />,
      );
    },
    // @sort
    [doCustomersOrganizationAclUpsertAsync, doDialogOpen, organization],
  );
}

function useBeginPublicCatalogProductView(): {
  beginPublicCatalogProductView: TAgentCustomersOrganizationActionsTypes['BeginPublicCatalogProductProductView'];
  publicCatalogProductViewInlineDrawer: TInlineDrawer<string>;
} {
  const publicCatalogProductViewInlineDrawer = useInlineDrawer<string>();

  const beginPublicCatalogProductView = useCallback<
    TAgentCustomersOrganizationActionsTypes['BeginPublicCatalogProductProductView']
  >(
    ({ publicCatalogProductId }) => {
      publicCatalogProductViewInlineDrawer.doDrawerOpen({
        metadata: publicCatalogProductId,
        drawerChildren: (
          <AgentPublicCatalogProductProvider
            LoaderComponent={OrganizationFormularyProductDrawerPanelLoader}
            publicCatalogProductId={publicCatalogProductId}>
            <PublicCatalogProductViewInlineDrawer beginPublicCatalogProductView={beginPublicCatalogProductView} />
          </AgentPublicCatalogProductProvider>
        ),
      });
    },
    [publicCatalogProductViewInlineDrawer],
  );

  return useMemo(
    () => ({
      beginPublicCatalogProductView,
      publicCatalogProductViewInlineDrawer,
    }),
    [beginPublicCatalogProductView, publicCatalogProductViewInlineDrawer],
  );
}

type TPublicCatalogProductViewInlineDrawer = {
  beginPublicCatalogProductView: TAgentCustomersOrganizationActionsTypes['BeginPublicCatalogProductProductView'];
};

function PublicCatalogProductViewInlineDrawer({
  beginPublicCatalogProductView,
}: TPublicCatalogProductViewInlineDrawer): JSX.Element {
  const { publicCatalogProduct } = useAgentPublicCatalogProduct();

  return (
    <OrganizationFormularyProductDrawerPanel
      onChangeVariant={beginPublicCatalogProductView}
      publicCatalogProduct={publicCatalogProduct}
      variantProducts={publicCatalogProduct.variant_products}
    />
  );
}

function useBeginCartProductsView(): TAgentCustomersOrganizationActionsTypes['BeginCartProductsView'] {
  const { beginCartProductsView } = useAgentCartProductsChrome();

  return useCallback<TAgentCustomersOrganizationActionsTypes['BeginCartProductsView']>(
    (args) => {
      beginCartProductsView({
        locationId: args.locationId,
      });
    },
    // @sort
    [beginCartProductsView],
  );
}
