import { CheckBoxControl } from '@src/components/appearance/controls/CheckBox';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { SelectControl } from '@src/components/appearance/controls/Select';
import { TextBoxControl } from '@src/components/appearance/controls/TextBox';
import { EDonationTarget, maybeGetDonationTargetLabel } from '@src/gen/shared/enums/donationTarget';
import { EReferralFeeTarget, maybeGetReferralFeeTargetLabel } from '@src/gen/shared/enums/referralFeeTarget';
import { ERevenueShareTarget, maybeGetRevenueShareTargetLabel } from '@src/gen/shared/enums/revenueShareTarget';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersOrganizationsActionsTypes } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationsActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import random from 'lodash/random';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TOrganizationAddSimplifiedFormValues = {
  organizationName: string;
  organizationIsCatapultGrow: boolean;
  organizationDonationTarget: string;
  organizationRevenueShareTarget: string;
  organizationReferralFeeTarget: string;
  locationName: string;
  locationOrderCode: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
};

export type TOrganizationAddSimplifiedFormBase = {
  doCustomersOrganizationAddSimplifiedAsync: TAgentCustomersOrganizationsActionsTypes['DoCustomersOrganizationAddSimplifiedAsync'];
};

export type TOrganizationAddSimplifiedForm = TProps<false, TOrganizationAddSimplifiedFormBase, 'div'>;
export const ORGANIZATION_ADD_SIMPLIFIED_FORM_CLASS_NAME = 'wp-organization-add-simplified-form';

export const OrganizationAddSimplifiedForm = withCssToString(
  ORGANIZATION_ADD_SIMPLIFIED_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrganizationAddSimplifiedForm>(
      ({ doCustomersOrganizationAddSimplifiedAsync, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORGANIZATION_ADD_SIMPLIFIED_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TOrganizationAddSimplifiedFormValues>(
          () => ({
            organizationName: '',
            organizationIsCatapultGrow: false,
            organizationDonationTarget: 'none',
            organizationRevenueShareTarget: 'none',
            organizationReferralFeeTarget: 'none',
            locationName: '',
            locationOrderCode: makeOrderCode(),
            userEmail: '',
            userFirstName: '',
            userLastName: '',
          }),
          [],
        );

        const handleSubmit = useCallback<TFormSubmit<TOrganizationAddSimplifiedFormValues>>(
          async (values) => {
            await doCustomersOrganizationAddSimplifiedAsync({
              ...values,
              organizationDonationTarget:
                values.organizationDonationTarget !== 'none' ? values.organizationDonationTarget : null,
              organizationRevenueShareTarget:
                values.organizationRevenueShareTarget !== 'none' ? values.organizationRevenueShareTarget : null,
              organizationReferralFeeTarget:
                values.organizationReferralFeeTarget !== 'none' ? values.organizationReferralFeeTarget : null,
            });
            return undefined;
          },
          // @sort
          [doCustomersOrganizationAddSimplifiedAsync],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TOrganizationAddSimplifiedFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              requireChanges={true}
              submitButtonText='Save'>
              <TextBoxControl id='organizationName' required={true} label='Organization Name' />
              <CheckBoxControl id='organizationIsCatapultGrow' label='Catapult Grow GPO Formulary' />
              <SelectControl id='organizationDonationTarget' label='Charity Program'>
                <option value='none'>None</option>
                {Object.values(EDonationTarget)
                  .filter((dt) => dt !== EDonationTarget.TEST)
                  .map((dt) => (
                    <option key={dt} value={dt}>
                      {maybeGetDonationTargetLabel(dt)}
                    </option>
                  ))}
              </SelectControl>
              <SelectControl id='organizationRevenueShareTarget' label='Revenue Share Program'>
                <option value='none'>None</option>
                {Object.values(ERevenueShareTarget)
                  .filter((rst) => rst !== ERevenueShareTarget.TEST)
                  .map((rst) => (
                    <option key={rst} value={rst}>
                      {maybeGetRevenueShareTargetLabel(rst)}
                    </option>
                  ))}
              </SelectControl>
              <SelectControl id='organizationReferralFeeTarget' label='Referral Fee Program'>
                <option value='none'>None</option>
                {Object.values(EReferralFeeTarget)
                  .filter((rft) => rft !== EReferralFeeTarget.TEST)
                  .map((rft) => (
                    <option key={rft} value={rft}>
                      {maybeGetReferralFeeTargetLabel(rft)}
                    </option>
                  ))}
              </SelectControl>
              <TextBoxControl id='locationName' required={true} label='Location Name' />
              <TextBoxControl id='locationOrderCode' required={true} label='Location Order Code' orderCode={true} />
              <TextBoxControl id='userEmail' required={true} label='Email' email={true} />
              <TextBoxControl id='userFirstName' required={true} label='First Name' />
              <TextBoxControl id='userLastName' required={true} label='Last Name' />
            </Form>
          </div>
        );
      },
    ),
  ),
);

function makeOrderCode(): string {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  return (
    alphabet.charAt(Math.floor(Math.random() * alphabet.length)) +
    alphabet.charAt(Math.floor(Math.random() * alphabet.length)) +
    alphabet.charAt(Math.floor(Math.random() * alphabet.length)) +
    `${random(100, 999, false)}`
  );
}
