import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { Select } from '@src/components/appearance/controls/Select';
import type { TTabSelectBase } from '@src/components/appearance/controls/TabSelect';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderCard, OrderCardLoader } from '@src/components/mixins/cards/OrderCard';
import type { TAgentOrderBaseFragment } from '@src/gen/graphql/bindings';
import { EOrderStatus, getOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentOrdersFilterActions } from '@src/modules/data/agent/orders/AgentOrdersFilterActionsProvider';
import { useAgentOrdersFilter } from '@src/modules/data/agent/orders/AgentOrdersFilterProvider';
import {
  encodeAgentOrderSummaryPath,
  encodeAgentOrdersPathWithConfig,
  useAgentOrdersRouteParams,
} from '@src/modules/routing/agent';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentOrdersPart(): JSX.Element {
  const { orders, loaderRef } = useAgentOrdersFilter();

  return (
    <Fragment>
      <Structure.Content>
        <Filters disabled={false} />
      </Structure.Content>
      <Structure.ScrollContent skipSeparator={true}>
        <Structure.Stack>
          <Structure.Group>
            {orders.length === 0 && <Banner icon='apply' message='No orders matching these filters.' />}
            {orders.map((order) => (
              <CardComponent key={order.id} order={order} />
            ))}
            {isDef(loaderRef) && <Illustrations.Spinner ref={loaderRef} />}
          </Structure.Group>
        </Structure.Stack>
      </Structure.ScrollContent>
    </Fragment>
  );
}

function Filters({ disabled }: { disabled: boolean }): JSX.Element {
  const navigate = useNavigate();
  const { beginOrganizationFilter, clearOrganizationFilter, doSetStatusFilter, doSetIsPaidFilter } =
    useAgentOrdersFilterActions();
  const { config } = useAgentOrdersRouteParams();

  const handleStatusSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      doSetStatusFilter({ status: e.target.value === 'any' ? null : getOrderStatus(e.target.value) });
    },
    [doSetStatusFilter],
  );

  const handleWaitingSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      switch (e.target.value) {
        case 'any':
          navigate(
            encodeAgentOrdersPathWithConfig({
              ...config,
              isAwaitingPrebillApproval: null,
              isBlockedOnCustomer: null,
            }),
          );
          break;
        case 'prebill':
          navigate(
            encodeAgentOrdersPathWithConfig({
              ...config,
              isAwaitingPrebillApproval: true,
              isBlockedOnCustomer: null,
            }),
          );
          break;
        case 'flag':
          navigate(
            encodeAgentOrdersPathWithConfig({
              ...config,
              isAwaitingPrebillApproval: null,
              isBlockedOnCustomer: true,
            }),
          );
          break;
      }
    },
    [config, navigate],
  );

  const waitingSelectValue = useMemo(() => {
    if (config.isAwaitingPrebillApproval === true) {
      return 'prebill';
    } else if (config.isBlockedOnCustomer === true) {
      return 'flag';
    } else {
      return 'any';
    }
  }, [config.isAwaitingPrebillApproval, config.isBlockedOnCustomer]);

  const handlePaidSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      doSetIsPaidFilter({ isPaid: e.target.value === 'any' ? null : e.target.value === 'yes' });
    },
    [doSetIsPaidFilter],
  );

  const paidSelectValue = useMemo(() => {
    return isDef(config.isPaid) ? (config.isPaid ? 'yes' : 'no') : 'any';
  }, [config.isPaid]);

  return (
    <ToolBar variant='page'>
      <ControlButton
        icon={isDef(config.organizationId) ? 'close' : 'funnel'}
        onClick={isDef(config.organizationId) ? clearOrganizationFilter : beginOrganizationFilter}
        text='Organization'
        variant={disabled ? 'disabled' : 'default'}
      />
      <Select
        disabled={disabled}
        onChange={handleStatusSelectChange}
        value={isDef(config.status) ? config.status : 'any'}>
        <option value='any'>Status: Any</option>
        <option value={EOrderStatus.RECEIVED}>Status: Received</option>
        <option value={EOrderStatus.PREPARING}>Status: Preparing</option>
        <option value={EOrderStatus.PROCESSING}>Status: Processing</option>
        <option value={EOrderStatus.SHIPPING}>Status: Shipping</option>
        <option value={EOrderStatus.COMPLETED}>Status: Completed</option>
        <option value={EOrderStatus.CANCELED}>Status: Canceled</option>
      </Select>
      <Select disabled={disabled} onChange={handleWaitingSelectChange} value={waitingSelectValue}>
        <option value='any'>Waiting: Any</option>
        <option value='prebill'>Waiting: Prebill Approval</option>
        <option value='flag'>Waiting: Customer</option>
      </Select>
      <Select disabled={disabled} onChange={handlePaidSelectChange} value={paidSelectValue}>
        <option value='any'>Paid: Any</option>
        <option value='yes'>Paid: Yes</option>
        <option value='no'>Paid: No</option>
      </Select>
    </ToolBar>
  );
}

function CardComponent({ order }: { order: TAgentOrderBaseFragment }): JSX.Element {
  const to = useMemo(() => encodeAgentOrderSummaryPath(order.id), [order.id]);
  return <OrderCard order={order} to={to} />;
}

export const AgentOrdersPartLoader = memo(
  (): JSX.Element => (
    <Fragment>
      <Structure.Content>
        <Filters disabled={true} />
      </Structure.Content>
      <Structure.ScrollContentLoader>
        <Structure.Stack>
          <Structure.Group>
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
            <OrderCardLoader />
          </Structure.Group>
        </Structure.Stack>
      </Structure.ScrollContentLoader>
    </Fragment>
  ),
);
