import type { TItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { ItemsBanner, ItemsBannerLoader } from '@src/components/appearance/fragments/ItemsBanner';
import type {
  TAgentOrderCompleteFragment,
  TAgentOrderProductBaseFragment,
  TCustomerOrderCompleteFragment,
  TCustomerOrderProductBaseFragment,
} from '@src/gen/graphql/bindings';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderOriginalCartItemsBannerBase = {
  order: Pick<TAgentOrderCompleteFragment | TCustomerOrderCompleteFragment, 'created_at' | 'creator'> & {
    order_products: Pick<TAgentOrderProductBaseFragment | TCustomerOrderProductBaseFragment, 'quantity'>[];
  };
};

export type TOrderOriginalCartItemsBanner = TProps<false, TOrderOriginalCartItemsBannerBase, 'div'>;
export const ORDER_ORIGINAL_CART_ITEMS_BANNER_CLASS_NAME = 'wp-order-original-cart-items-banner';

export const OrderOriginalCartItemsBanner = withCssToString(
  ORDER_ORIGINAL_CART_ITEMS_BANNER_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderOriginalCartItemsBanner>(({ order, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, ORDER_ORIGINAL_CART_ITEMS_BANNER_CLASS_NAME),
        [className],
      );

      const totalItems = useMemo(
        () => order.order_products.map((op) => op.quantity).reduce((out, qty) => out + qty, 0),
        [order.order_products],
      );

      const items = useMemo<TItemsBanner['items']>(
        () => [
          {
            caption: 'Unique Items',
            text: `${order.order_products.length}`,
          },
          {
            caption: 'Total Items',
            text: `${totalItems}`,
          },
          {
            caption: 'Placed By',
            text: `${order.creator.first_name} ${order.creator.last_name}`,
          },
          {
            caption: 'Placed On',
            text: { asDate: 'on', at: order.created_at },
          },
        ],
        // @sort
        [totalItems, order.created_at, order.creator.first_name, order.creator.last_name, order.order_products.length],
      );

      return <ItemsBanner {...rest} className={joinedClassName} items={items} ref={ref} />;
    }),
  ),
);

export const OrderOriginalCartItemsBannerLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(({}, ref): JSX.Element => <ItemsBannerLoader count={4} ref={ref} />),
);
