import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import { Quantity } from '@src/components/appearance/controls/Quantity';
import { Select } from '@src/components/appearance/controls/Select';
import { AnnotatedLoader } from '@src/components/appearance/fragments/Annotated';
import { CategoryTree } from '@src/components/appearance/fragments/CategoryTree';
import { Description } from '@src/components/appearance/fragments/Description';
import { ImageAsset } from '@src/components/appearance/fragments/ImageAsset';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import { LabeledLoader } from '@src/components/appearance/fragments/Labeled';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type {
  TAgentPublicCatalogProductCompleteWithVariantsFragment,
  TCustomerVariantProductBaseFragment,
} from '@src/gen/graphql/bindings';
import { castDescriptionUnsafe } from '@src/gen/shared/data/description';
import { castSpecsUnsafe } from '@src/gen/shared/data/specs';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { getSpecsItems, joinClassNames } from '@src/logic/internal/data/utils';
import { openUrl, withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersOrganizationActionsTypes } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import type { ChangeEvent } from 'react';
import { Fragment, forwardRef, memo, useCallback, useMemo } from 'react';

const SImageAsset = styled(ImageAsset, {
  height: '256px',
});

export type TOrganizationFormularyProductDrawerPanelBase = {
  onChangeVariant: TAgentCustomersOrganizationActionsTypes['BeginPublicCatalogProductProductView'];
  publicCatalogProduct: TAgentPublicCatalogProductCompleteWithVariantsFragment;
  variantProducts: TCustomerVariantProductBaseFragment[];
};

export type TOrganizationFormularyProductDrawerPanel = TProps<
  false,
  TOrganizationFormularyProductDrawerPanelBase,
  'div'
>;
export const ORGANIZATION_FORMULARY_PRODUCT_DRAWER_PANEL_CLASS_NAME = 'wp-organization-formulary-product-drawer-panel';

export const OrganizationFormularyProductDrawerPanel = withCssToString(
  ORGANIZATION_FORMULARY_PRODUCT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrganizationFormularyProductDrawerPanel>(
      ({ onChangeVariant, publicCatalogProduct, variantProducts, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORGANIZATION_FORMULARY_PRODUCT_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const productItems = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Catalog',
              text: maybeGetSourceName(publicCatalogProduct.source),
            },
            {
              caption: 'SKU',
              text: publicCatalogProduct.product_sku,
            },
            {
              caption: 'Manufacturer',
              text: publicCatalogProduct.manufacturer_name ?? 'N/A',
            },
            {
              caption: 'Manufacturer SKU',
              text: publicCatalogProduct.manufacturer_sku ?? 'N/A',
            },
          ],
          // @sort
          [
            publicCatalogProduct.manufacturer_name,
            publicCatalogProduct.manufacturer_sku,
            publicCatalogProduct.product_sku,
            publicCatalogProduct.source,
          ],
        );

        const handleChangeVariant = useCallback(
          (e: ChangeEvent<HTMLSelectElement>) => onChangeVariant({ publicCatalogProductId: e.target.value }),
          [onChangeVariant],
        );

        const productDescriptionBlocks = useMemo(
          () => castDescriptionUnsafe(publicCatalogProduct.description),
          [publicCatalogProduct.description],
        );

        const specsItems = useMemo<TItemBaseItem[]>(
          () => getSpecsItems(castSpecsUnsafe(publicCatalogProduct.specs)),
          [publicCatalogProduct.specs],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Formulary Product' />
            <Drawer.ScrollContent>
              <SImageAsset alt={publicCatalogProduct.name} imageAssetPath={publicCatalogProduct.image_asset_path} />
              <Drawer.Separator />
              <Drawer.Group>
                <Drawer.Title subTitle={publicCatalogProduct.secondary_name} title={publicCatalogProduct.name} />
                <Items items={productItems} />
              </Drawer.Group>
              {publicCatalogProduct.is_discontinued && (
                <Fragment>
                  <Drawer.Separator />
                  <Drawer.Group>
                    <Typography.Caption css={{ alignSelf: 'center' }} text='This product is no longer available.' />
                  </Drawer.Group>
                </Fragment>
              )}
              <Drawer.Separator />
              {variantProducts.length > 1 && (
                <Drawer.Group title='Variants'>
                  <Select onChange={handleChangeVariant} value={publicCatalogProduct.id}>
                    {variantProducts.map((vp) => (
                      <option key={vp.id} value={vp.id}>
                        {vp.name}
                        {vp.secondary_name !== null && ` · ${vp.secondary_name}`}
                      </option>
                    ))}
                  </Select>
                </Drawer.Group>
              )}
              <Drawer.Group title='References'>
                <ControlButton
                  icon='link'
                  text='Product Page'
                  onClick={(): void => openUrl(publicCatalogProduct.product_page_url)}
                />
              </Drawer.Group>
              <Drawer.Group title='Category'>
                <CategoryTree category={publicCatalogProduct.category_name} />
              </Drawer.Group>
              {productDescriptionBlocks.length > 0 && (
                <Drawer.Group title='Description'>
                  <Description description={productDescriptionBlocks} />
                </Drawer.Group>
              )}
              {specsItems.length > 0 && (
                <Drawer.Group title='Specifications'>
                  {specsItems.map((item) => (
                    <Item item={item} key={item.caption} />
                  ))}
                </Drawer.Group>
              )}
              {isDef(publicCatalogProduct.sds_asset_path) && (
                <Drawer.Group title='Documents'>
                  <ControlButton icon='download' text='Safety Data Sheet' />
                </Drawer.Group>
              )}
            </Drawer.ScrollContent>
          </Drawer.Panel>
        );
      },
    ),
  ),
);

const SLoaderTopDiv = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const OrganizationFormularyProductDrawerPanelLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Drawer.Panel ref={ref}>
        <Drawer.Header title='Formulary Product' />
        <Drawer.ScrollContentLoader>
          <SLoaderTopDiv>
            <Illustrations.Loader
              height={256}
              uniqueKey='wp-organization-formulary-product-drawer-panel-loader-image'
              width={320}>
              <rect x='0' y='5' rx='0' ry='0' width='320' height='256' />
            </Illustrations.Loader>
          </SLoaderTopDiv>
          <Drawer.Separator />
          <Drawer.Group>
            <Drawer.TitleLoader />
            <ItemsLoader count={4} />
          </Drawer.Group>
          <Drawer.Separator />
          <Drawer.Group>
            <AnnotatedLoader>
              <Quantity disabled={true} onChange={noop} value={0} />
            </AnnotatedLoader>
            <LabeledLoader>
              <CheckBox disabled={true} />
            </LabeledLoader>
          </Drawer.Group>
        </Drawer.ScrollContentLoader>
      </Drawer.Panel>
    ),
  ),
);
