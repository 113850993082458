import type { TButtonAction } from '@src/components/appearance/controls/Button';
import { ActionButton } from '@src/components/appearance/controls/Button';
import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import { Item } from '@src/components/appearance/fragments/Item';
import { ItemButton } from '@src/components/appearance/fragments/ItemButton';
import type { TItems } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import type {
  TAgentOrderReturnCompleteFragment,
  TCustomerOrderReturnCompleteFragment,
} from '@src/gen/graphql/bindings';
import { getCombinedProductName, getDateOn, getFullName, getSaleUnitQuantity } from '@src/gen/shared/data/snippets';
import { getSource, maybeGetSourceName } from '@src/gen/shared/enums/source';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TCustomerOrderActionsTypes } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderReturnCardBase = {
  beginOrderReturnProductView:
    | TAgentOrderActionsTypes['BeginOrderReturnProductView']
    | TCustomerOrderActionsTypes['BeginOrderReturnProductView'];
  downloadPictureAsyncNotify: TAgentOrderActionsTypes['DownloadOrderReturnPictureAsyncNotify'] | null;
  onApplyAsyncNotify: (() => Promise<void>) | null;
  onDeleteAsyncNotify: (() => Promise<void>) | null;
  onExecuteAsyncNotify: (() => Promise<void>) | null;
  onReply: (() => void) | null;
  onSeeInstructionsAsyncNotify: (() => Promise<void>) | null;
  orderReturn: TAgentOrderReturnCompleteFragment | TCustomerOrderReturnCompleteFragment;
  variant: 'agent' | 'customer';
};

export type TOrderReturnCard = TProps<false, TOrderReturnCardBase, 'div'>;
export const ORDER_RETURN_CARD_CLASS_NAME = 'wp-order-return-card';

export const OrderReturnCard = withCssToString(
  ORDER_RETURN_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderReturnCard>(
      (
        {
          beginOrderReturnProductView,
          downloadPictureAsyncNotify,
          onApplyAsyncNotify,
          onDeleteAsyncNotify,
          onExecuteAsyncNotify,
          onReply,
          onSeeInstructionsAsyncNotify,
          orderReturn,
          variant,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_RETURN_CARD_CLASS_NAME), [className]);

        const items = useMemo<TItems['items']>(
          () => [
            {
              caption: 'Return Quantity',
              text: getSaleUnitQuantity(orderReturn.quantity, orderReturn.sale_unit),
            },
            {
              caption: 'Never Receive Again',
              text: { asBoolean: orderReturn.should_blacklist },
            },
            {
              caption: 'Initiated By',
              text: getFullName(orderReturn.creator),
            },
            {
              caption: 'Initiated On',
              text: { asDate: 'on', at: orderReturn.created_at },
            },
          ],
          // @sort
          [
            orderReturn.created_at,
            orderReturn.creator,
            orderReturn.quantity,
            orderReturn.sale_unit,
            orderReturn.should_blacklist,
          ],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () =>
            getAvailableIconButtonActions([
              {
                isAsync: false,
                icon: 'edit',
                onClick: isDef(onReply) && !isDef(orderReturn.replied_at) ? onReply : undefined,
              },
              {
                isAsync: true,
                icon: 'apply',
                onClick:
                  isDef(onApplyAsyncNotify) && isDef(orderReturn.replied_at) && !isDef(orderReturn.applied_at)
                    ? onApplyAsyncNotify
                    : undefined,
              },
              {
                isAsync: true,
                icon: 'download',
                onClick:
                  isDef(downloadPictureAsyncNotify) && isDef(orderReturn.picture_upload_id)
                    ? async (): Promise<void> => await downloadPictureAsyncNotify({ orderReturnId: orderReturn.id })
                    : undefined,
              },
              {
                isAsync: true,
                icon: 'trash',
                onClick: isDef(onDeleteAsyncNotify) && !isDef(orderReturn.applied_at) ? onDeleteAsyncNotify : undefined,
              },
            ]),
          // @sort
          [
            downloadPictureAsyncNotify,
            onApplyAsyncNotify,
            onDeleteAsyncNotify,
            onReply,
            orderReturn.applied_at,
            orderReturn.id,
            orderReturn.picture_upload_id,
            orderReturn.replied_at,
          ],
        );

        const executeButtonAction = useMemo<TButtonAction>(
          () =>
            isDef(onExecuteAsyncNotify)
              ? {
                  isAsync: true,
                  onClick: onExecuteAsyncNotify,
                  text: 'Report Shipped',
                }
              : {
                  isAsync: false,
                  onClick: noop,
                  text: 'Report Shipped',
                  variant: 'disabled',
                },
          [onExecuteAsyncNotify],
        );

        const seeInstructionsAction = useMemo<TButtonAction>(
          () =>
            isDef(onSeeInstructionsAsyncNotify) && isDef(orderReturn.replied_at)
              ? {
                  isAsync: true,
                  onClick: onSeeInstructionsAsyncNotify,
                  text: 'See Return Instructions',
                  variant: 'secondary',
                }
              : {
                  isAsync: false,
                  onClick: noop,
                  text: 'See Return Instructions',
                  variant: 'disabled',
                },
          // @sort
          [onSeeInstructionsAsyncNotify, orderReturn.replied_at],
        );

        const flapText = useMemo<string>(
          () => {
            if (!isDef(orderReturn.replied_at)) {
              return 'Waiting on Wellplaece';
            }

            if (!isDef(orderReturn.executed_at)) {
              return 'Waiting on Customer';
            }

            if (!isDef(orderReturn.applied_at)) {
              return `Reported Shipped By ${getFullName(ensureDef(orderReturn.executor))} on ${getDateOn(
                ensureDef(orderReturn.executed_at),
              )}`;
            }

            return `Completed By ${getFullName(ensureDef(orderReturn.applier))} on ${getDateOn(
              ensureDef(orderReturn.applied_at),
            )}`;
          },
          // @sort
          [
            orderReturn.applied_at,
            orderReturn.applier,
            orderReturn.executed_at,
            orderReturn.executor,
            orderReturn.replied_at,
          ],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flapText={flapText}
            flush={true}
            iconButtonActions={iconButtonActions}
            interactive={false}
            ref={ref}>
            <Card.ContentArea>
              <Card.Title title='Return' />
              <ItemButton
                key={orderReturn.id}
                variant='card'
                onClick={(): void => beginOrderReturnProductView({ orderReturn })}
                item={{
                  caption: `${maybeGetSourceName(getSource(orderReturn.source))} · ${orderReturn.product_sku}`,
                  text: getCombinedProductName(orderReturn.name, orderReturn.secondary_name),
                }}
              />
              <ItemsBanner items={items} />
              <Item
                item={{
                  caption: 'Return Reason',
                  text: orderReturn.reason,
                }}
              />
              {variant === 'customer' && !isDef(orderReturn.replied_at) && (
                <Banner
                  icon='processing'
                  accent='warning'
                  message='We are processing your request. Return instructions will appear here when available.'
                />
              )}
              {variant === 'customer' && isDef(orderReturn.executed_at) && !isDef(orderReturn.applied_at) && (
                <Banner
                  icon='processing'
                  accent='warning'
                  message='You reported this return as shipped. We will resume processing upon confirmation from the supplier.'
                />
              )}
              {isDef(orderReturn.applied_at) && (
                <Banner icon='apply' accent='success' message='This return is completed.' />
              )}
            </Card.ContentArea>
            {variant === 'customer' && isDef(orderReturn.replied_at) && !isDef(orderReturn.executed_at) && (
              <Card.Footer>
                <Card.Label text='Customer Actions' />
                <Card.Actions>
                  <ActionButton action={executeButtonAction} />
                  <ActionButton action={seeInstructionsAction} />
                </Card.Actions>
              </Card.Footer>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);
