import { Drawer } from '@src/components/appearance/structure/Drawer';
import {
  OrganizationPickerDrawerPanel,
  OrganizationPickerDrawerPanelLoader,
} from '@src/components/mixins/drawers/OrganizationPickerDrawerPanel';
import type { TAgentOrganizationForPickerFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import {
  AgentOrganizationsForPickerProvider,
  useAgentOrganizationsForPicker,
} from '@src/modules/data/agent/global/operations/AgentOrganizationsForPickerProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TAgentOrganizationPickerDrawerTypes = {
  BeginOrganizationPickCallback: (organization: TAgentOrganizationForPickerFragment) => Promise<void>;
  BeginOrganizationPickArgs: {
    buttonActionText?: string | undefined;
    callback: TAgentOrganizationPickerDrawerTypes['BeginOrganizationPickCallback'];
    drawerHeaderTitle?: string | undefined;
    omitOrganizationIds?: { [key: string]: true } | undefined;
  };
  BeginOrganizationPick: (args: TAgentOrganizationPickerDrawerTypes['BeginOrganizationPickArgs']) => void;
};

export type TAgentOrganizationPickerDrawerContext = {
  beginOrganizationPick: TAgentOrganizationPickerDrawerTypes['BeginOrganizationPick'];
};

export const { Context: AgentOrganizationPickerDrawerContext, useContext: useAgentOrganizationPickerDrawer } =
  createRequiredContext<TAgentOrganizationPickerDrawerContext>();

export function AgentOrganizationPickerDrawerProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const [state, setState] = useState<TAgentOrganizationPickerDrawerTypes['BeginOrganizationPickArgs'] | undefined>(
    undefined,
  );

  const handleOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setState(undefined);
    }
  }, []);

  const handleCallback = useCallback(
    async (organization: TAgentOrganizationForPickerFragment): Promise<void> => {
      await state?.callback(organization);
      setState(undefined);
    },
    [state],
  );

  const value = useMemo<TAgentOrganizationPickerDrawerContext>(
    () => ({
      beginOrganizationPick: setState,
    }),
    [],
  );

  return (
    <AgentOrganizationPickerDrawerContext.Provider value={value}>
      <Drawer.Root open={isDef(state)} onOpenChange={handleOpenChange}>
        {isDef(state) && (
          <AgentOrganizationsForPickerProvider
            LoaderComponent={(): JSX.Element => (
              <OrganizationPickerDrawerPanelLoader
                buttonActionText={state.buttonActionText}
                drawerHeaderTitle={state.drawerHeaderTitle}
              />
            )}>
            <ConsumerComponent
              buttonActionText={state.buttonActionText}
              drawerHeaderTitle={state.drawerHeaderTitle}
              omitOrganizationIds={state.omitOrganizationIds}
              callback={handleCallback}
            />
          </AgentOrganizationsForPickerProvider>
        )}
      </Drawer.Root>
      {children}
    </AgentOrganizationPickerDrawerContext.Provider>
  );
}

function ConsumerComponent({
  buttonActionText,
  callback,
  drawerHeaderTitle,
  omitOrganizationIds,
}: TAgentOrganizationPickerDrawerTypes['BeginOrganizationPickArgs']): JSX.Element {
  const { organizations, loaderRef } = useAgentOrganizationsForPicker();

  const filteredOrganizations = useMemo(() => {
    return organizations.filter((org) => !isDef(omitOrganizationIds?.[org.id]));
  }, [omitOrganizationIds, organizations]);

  return (
    <OrganizationPickerDrawerPanel
      buttonActionText={buttonActionText}
      drawerHeaderTitle={drawerHeaderTitle}
      loaderRef={loaderRef}
      onSelectOrganization={callback}
      organizations={filteredOrganizations}
    />
  );
}
