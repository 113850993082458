import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EOrderExportType {
  ORIGINAL_CART = 'original_cart',
}

const ORDER_EXPORT_TYPE_LOOKUP = mapValues(invert(EOrderExportType), () => true);

export function getOrderExportType(rawOrderExportType: string): EOrderExportType {
  assert(ORDER_EXPORT_TYPE_LOOKUP[rawOrderExportType], `Unknown order export type: "${rawOrderExportType}".`);
  return rawOrderExportType as EOrderExportType; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetOrderExportType(rawOrderExportType: string): EOrderExportType | undefined {
  try {
    return getOrderExportType(rawOrderExportType);
  } catch {
    return undefined;
  }
}

export const ORDER_EXPORT_TYPE_KEYS: { [key in EOrderExportType]: string } = {
  [EOrderExportType.ORIGINAL_CART]: 'original-cart',
};

export function getOrderExportTypeKey(rawOrderExportType: string): string {
  return ensureDef(ORDER_EXPORT_TYPE_KEYS[getOrderExportType(rawOrderExportType)]);
}
