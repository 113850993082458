import type { ApolloQueryResult } from '@apollo/client';
import { NetworkStatus } from '@apollo/client';
import type {
  AgentCartProductsQuery,
  AgentCartProductsQueryVariables,
  TAgentCartProductCompleteFragment,
} from '@src/gen/graphql/bindings';
import { useAgentCartProductsQuery } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TAgentCustomersOrganizationCartProductsContext = {
  cartProducts: TAgentCartProductCompleteFragment[];
  doRefetchCartProducts: () => Promise<ApolloQueryResult<AgentCartProductsQuery>>;
};

export const {
  Context: AgentCustomersOrganizationCartProductsContext,
  useContext: useAgentCustomersOrganizationCartProducts,
} = createRequiredContext<TAgentCustomersOrganizationCartProductsContext>();

export type TAgentCustomersOrganizationCartProductsProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  locationId: string;
}>;

export function AgentCustomersOrganizationCartProductsProvider({
  LoaderComponent,
  locationId,
  children,
}: TAgentCustomersOrganizationCartProductsProvider): JSX.Element {
  const variables = useMemo<AgentCartProductsQueryVariables>(
    () => ({
      locationId,
    }),
    // @sort
    [locationId],
  );

  const { data, error, networkStatus, refetch } = useAgentCartProductsQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const value = useMemo<TAgentCustomersOrganizationCartProductsContext | undefined>(
    () =>
      isDef(data) && isDef(data.cart_products)
        ? {
            cartProducts: data.cart_products,
            doRefetchCartProducts: refetch,
          }
        : undefined,
    // @sort
    [data, refetch],
  );

  if (isDef(error)) {
    throw error;
  }

  if (isDef(data) && !isDef(data.cart_products)) {
    throw new Error('Not Found');
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentCustomersOrganizationCartProductsContext.Provider value={value}>
      {children}
    </AgentCustomersOrganizationCartProductsContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
