import { Root as RxSeparatorRoot } from '@radix-ui/react-separator';
import type { TAvatar } from '@src/components/appearance/basics/Avatar';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import type { TIconButtonBase } from '@src/components/appearance/controls/IconButton';
import { IconButton } from '@src/components/appearance/controls/IconButton';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import { useIsDesktop } from '@src/modules/design/breakpoints';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { ReactElement, ReactNode } from 'react';
import { Fragment, forwardRef, memo, useMemo } from 'react';

export type TPrimaryNav = {
  helperTypes: {
    Action: {
      icon: TIconButtonBase['icon'];
      onClick: () => void;
    };
  };
  HeaderBase: {
    action?: TPrimaryNav['helperTypes']['Action'] | undefined;
    pageTitle?: string | null | undefined;
  };
  PanelBase: {
    action?: TPrimaryNav['helperTypes']['Action'] | undefined;
    footerChildren?: ReactNode | undefined;
    headerChildren?: ReactNode | undefined;
    message?: string | undefined;
  };
  GroupBase: {
    title: string;
  };
  ItemBase: {
    active: boolean;
    avatar?: ReactElement<TAvatar> | undefined;
    title: string;
  };

  Header: TProps<false, TPrimaryNav['HeaderBase'], 'div'>;
  Panel: TProps<true, TPrimaryNav['PanelBase'], 'div'>;
  Group: TProps<true, TPrimaryNav['GroupBase'], 'div'>;
  Item: TProps<false, TPrimaryNav['ItemBase'], 'button'>;
};

export const PRIMARY_NAV_CLASS_NAMES = {
  Header: 'wp-primary-nav-header',
  Panel: 'wp-primary-nav-panel',
  Group: 'wp-primary-nav-group',
  Item: 'wp-primary-nav-item',
  User: 'wp-primary-nav-user',
};

const SHeaderDiv = styled('div', {
  alignItems: 'center',
  backgroundColor: '$primaryNavBackground',
  display: 'flex',
  flexDirection: 'row',
  padding: '$primaryNavSpaceMobile',

  '@desktop': {
    padding: '$primaryNavSpaceDesktop',
  },
});

const SHeaderLogoDiv = styled('div', {
  flexGrow: 1,
  height: '$primaryNavHeaderLogoHeightMobile',
  display: 'flex',
  gap: '$primaryNavSpaceMobile',

  '@desktop': {
    height: '$primaryNavHeaderLogoHeightDesktop',
    gap: '$primaryNavSpaceDesktop',
  },
});

const SHeaderIllustrationsLogo = styled(Illustrations.Logo, {
  flexShrink: 0,
  height: '$primaryNavHeaderLogoHeightMobile',

  '@desktop': {
    height: '$primaryNavHeaderLogoHeightDesktop',
  },
});

const SPageTitle = styled(Typography.SubTitle, {
  alignSelf: 'center',
  color: '$white',
});

const Header = withCssToString(
  PRIMARY_NAV_CLASS_NAMES.Header,
  memo(
    forwardRef<HTMLDivElement, TPrimaryNav['Header']>(({ action, pageTitle, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, PRIMARY_NAV_CLASS_NAMES.Header), [className]);
      const isDesktop = useIsDesktop();

      return (
        <SHeaderDiv {...rest} className={joinedClassName} ref={ref}>
          <SHeaderLogoDiv>
            <SHeaderIllustrationsLogo variant={isDesktop || !isDef(pageTitle) ? 'inverted' : 'simple'} />
            {isDef(pageTitle) && <SPageTitle rigid={true} expanding={true} text={pageTitle} />}
          </SHeaderLogoDiv>
          {isDef(action) && <IconButton icon={action.icon} onClick={action.onClick} variant='inverted' />}
        </SHeaderDiv>
      );
    }),
  ),
);

const SPanelDiv = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$primaryNavBackground',
  display: 'flex',
  flexDirection: 'column',
});

const SPanelMessageTypographyCaption = memo(
  styled(Typography.Caption, {
    paddingBottom: '$primaryNavSpaceMobile',
    paddingX: '$primaryNavSpaceMobile',
    color: '$primaryNavText',

    '@desktop': {
      paddingBottom: '$primaryNavSpaceDesktop',
      paddingX: '$primaryNavSpaceDesktop',
    },
  }),
);

const SPanelSeparator = memo(
  styled(RxSeparatorRoot, {
    backgroundColor: '$primaryNavSeparatorBackground',
    flexShrink: 0,
    height: '$separatorThickness',
    marginX: '$primaryNavSpaceMobile',

    '@desktop': {
      marginX: '$primaryNavSpaceDesktop',
    },
  }),
);

const SPanelHeaderContentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$primaryNavPanelContentGap',
  paddingX: '$primaryNavSpaceMobile',
  paddingY: '$primaryNavPanelContentGap',

  '@desktop': {
    paddingX: '$primaryNavSpaceDesktop',
  },
});

const SPanelContentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$primaryNavPanelContentGap',
  padding: '$primaryNavSpaceMobile',

  '@desktop': {
    padding: '$primaryNavSpaceDesktop',
  },

  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
        hideScrollbars: true,
        overflow: 'scroll',
      },
    },
  },
});

const Panel = withCssToString(
  PRIMARY_NAV_CLASS_NAMES.Header,
  forwardRef<HTMLDivElement, TPrimaryNav['Panel']>(
    ({ action, footerChildren, headerChildren, message, children, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, PRIMARY_NAV_CLASS_NAMES.Panel), [className]);
      return (
        <SPanelDiv {...rest} className={joinedClassName} ref={ref}>
          <Header action={action} />
          {isDef(message) && <SPanelMessageTypographyCaption text={message} />}
          <SPanelSeparator />
          {isDef(headerChildren) && (
            <Fragment>
              <SPanelHeaderContentDiv>{headerChildren}</SPanelHeaderContentDiv>
              <SPanelSeparator />
            </Fragment>
          )}
          <SPanelContentDiv expanding={true}>{children}</SPanelContentDiv>
          {isDef(footerChildren) && (
            <Fragment>
              <SPanelSeparator />
              <SPanelContentDiv>{footerChildren}</SPanelContentDiv>
            </Fragment>
          )}
        </SPanelDiv>
      );
    },
  ),
);

const SGroupDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$primaryNavGroupGap',
});

const SGroupTitleDiv = styled('div', {
  alignItems: 'center',
  color: '$primaryNavText',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  height: '$primaryNavGroupTitleHeight',
  paddingX: '$primaryNavGroupTitlePaddingXMobile',
  text: '$cta',

  '@desktop': {
    paddingX: '$primaryNavGroupTitlePaddingXDesktop',
  },
});

const Group = withCssToString(
  PRIMARY_NAV_CLASS_NAMES.Group,
  forwardRef<HTMLDivElement, TPrimaryNav['Group']>(({ title, children, className, ...rest }, ref) => {
    const joinedClassName = useMemo(() => joinClassNames(className, PRIMARY_NAV_CLASS_NAMES.Group), [className]);

    return (
      <SGroupDiv {...rest} className={joinedClassName} ref={ref}>
        <SGroupTitleDiv>{title}</SGroupTitleDiv>
        {children}
      </SGroupDiv>
    );
  }),
);

const SItemButton = styled('button', {
  all: 'unset',

  alignItems: 'center',
  borderRadius: '$primaryNavItem',
  color: '$primaryNavText',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  gap: '$primaryNavItemGap',
  height: '$primaryNavItemHeight',
  paddingX: '$primaryNavItemPaddingXMobile',
  text: '$cta',
  textDecoration: 'none',

  [`${Group.toString()} &`]: {
    text: '$small',
  },

  '&:hover': {
    backgroundColor: '$primaryNavItemHoverBackground',
  },

  '&:focus': {
    boxShadow: '$invertedFocus',
  },

  '@desktop': {
    paddingX: '$primaryNavItemPaddingXDesktop',
  },

  variants: {
    active: {
      true: {
        backgroundColor: '$primaryNavItemActiveBackground',
      },
    },
    avatar: {
      true: {
        height: '$primaryNavItemAvatarHeight',
        text: '$small',
      },
    },
  },
});

const Item = withCssToString(
  PRIMARY_NAV_CLASS_NAMES.Item,
  memo(
    forwardRef<HTMLButtonElement, TPrimaryNav['Item']>(({ active, avatar, title, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, PRIMARY_NAV_CLASS_NAMES.Item), [className]);

      return (
        <SItemButton {...rest} active={active} avatar={avatar !== undefined} className={joinedClassName} ref={ref}>
          {avatar}
          {title}
        </SItemButton>
      );
    }),
  ),
);

export const PrimaryNav = {
  Header,
  Panel,
  Group,
  Item,
};
