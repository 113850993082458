import { ResponsiveBar } from '@nivo/bar';
import { Typography } from '@src/components/appearance/basics/Typography';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled, theme } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$chartBackground',
  borderRadius: '$chart',
  display: 'flex',
  flexDirection: 'column',
  gap: '$chartGap',
  padding: '$chartPadding',
});

const SChartDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  height: '500px',
  justifyContent: 'stretch',
  padding: '$chartPadding',
});

export type TBarChartDataItem = {
  id: string;
  value: number;
  label: string;
  color: string;
};

export type TBarChartBase = {
  title: string;
  valueFormat?: (v: number) => string;
  data: TBarChartDataItem[];
};

export type TBarChart = TProps<false, TBarChartBase, 'div'>;
export const BAR_CHART_CLASS_NAME = 'wp-bar-chart';

export const BarChart = withCssToString(
  BAR_CHART_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TBarChart>(({ title, valueFormat, data, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, BAR_CHART_CLASS_NAME), [className]);

      return (
        <SDiv {...rest} className={joinedClassName} ref={ref}>
          <Typography.Strong rigid={true} expanding={true} text={title} />
          <SChartDiv>
            <ResponsiveBar<TBarChartDataItem>
              {...(isDef(valueFormat) ? { valueFormat } : {})}
              axisBottom={{
                tickRotation: -33,
              }}
              axisLeft={{
                format: (v: number) => (isDef(valueFormat) ? valueFormat(v) : v),
              }}
              enableLabel={false}
              colors={{ datum: 'data.color' }}
              data={data}
              margin={{
                left: 150,
                top: 12,
                bottom: 45,
              }}
              theme={{
                fontFamily: theme.fonts.caption.value,
                fontSize: 14,
                tooltip: {
                  container: {
                    fontFamily: theme.fonts.caption.value,
                    fontSize: 16,
                  },
                },
              }}
            />
          </SChartDiv>
        </SDiv>
      );
    }),
  ),
);

/*
legends={[
                {
                  anchor: 'left',
                  direction: 'column',
                  itemHeight: 22,
                  itemWidth: 150,
                  itemsSpacing: 8,
                  justify: false,
                  translateX: -150,
                  translateY: 0,
                },
              ]}
 */
