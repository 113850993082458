import { NetworkStatus } from '@apollo/client';
import type { AgentOrganizationUsersForPickerQueryVariables, TAgentUserBaseFragment } from '@src/gen/graphql/bindings';
import { useAgentOrganizationUsersForPickerQuery } from '@src/gen/graphql/bindings';

import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, mergeGetMore } from '@src/modules/graphql/utils';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TAgentOrganizationUsersForPickerContext = {
  users: TAgentUserBaseFragment[];
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const { Context: AgentOrganizationUsersForPickerContext, useContext: useAgentOrganizationUsersForPicker } =
  createRequiredContext<TAgentOrganizationUsersForPickerContext>();

export type TAgentOrganizationUsersForPickerProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  organizationId: string;
}>;

export function AgentOrganizationUsersForPickerProvider({
  organizationId,
  LoaderComponent,
  children,
}: TAgentOrganizationUsersForPickerProvider): JSX.Element {
  const variables = useMemo<AgentOrganizationUsersForPickerQueryVariables>(
    () => ({
      limit: DEFAULT_LIMIT,
      offset: 0,
      organizationId,
    }),
    // @sort
    [organizationId],
  );

  const { data, error, fetchMore, networkStatus } = useAgentOrganizationUsersForPickerQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          offset: ensureDef(data?.users.length),
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          users: mergeGetMore(prev.users, fetchMoreResult.users),
        }),
      });

      return newData.users.length;
    },
    // @sort
    [fetchMore, data?.users.length],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.users.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TAgentOrganizationUsersForPickerContext | undefined>(
    () =>
      isDef(data)
        ? {
            users: data.users,
            loaderRef: loaderRef,
          }
        : undefined,
    // @sort
    [data, loaderRef],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentOrganizationUsersForPickerContext.Provider value={value}>
      {children}
    </AgentOrganizationUsersForPickerContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
