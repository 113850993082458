import { Control } from '@src/components/appearance/controls/Control';
import { getIconButtonVariant, IconButton } from '@src/components/appearance/controls/IconButton';
import { TextBox } from '@src/components/appearance/controls/TextBox';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { ensureStringToQuantity, withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { forwardRef, Fragment, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useField } from 'react-final-form';

const SDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$cardGap',
});

const SInput = styled(TextBox, {
  flexGrow: 1,

  variants: {
    editing: {
      true: {
        color: '$controlPlaceholderText',
      },
    },
  },
});

export type TQuantityBase = {
  disabled?: boolean | undefined;
  onChange: (value: number) => void;
  value: number;
};

export type TQuantity = TProps<false, TQuantityBase, 'div'>;
export const QUANTITY_CLASS_NAME = 'wp-quantity';

export const Quantity = withCssToString(
  QUANTITY_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TQuantity>(
      ({ disabled, onChange, value, className, ...rest }: TQuantity, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, QUANTITY_CLASS_NAME), [className]);
        const [editingValue, setEditingValue] = useState<number | undefined>(undefined);
        const inputRef = useRef<HTMLInputElement>(null);
        const inputValue = useMemo(() => editingValue ?? value, [editingValue, value]);
        const inputValueStr = useMemo(() => (inputValue > 0 ? `${inputValue}` : ''), [inputValue]);

        const handleApply = useCallback(
          () => {
            if (isDef(editingValue)) {
              setEditingValue(undefined);
              onChange(editingValue);
            }
          },
          // @sort
          [editingValue, onChange],
        );

        const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
          try {
            setEditingValue(e.target.value === '' ? 0 : ensureStringToQuantity(e.target.value));
          } catch {
            if (isDef(inputRef.current)) {
              // TODO(ibrt): Figure out if this can be made less janky.
              const selectionStart = ensureDef(inputRef.current.selectionStart) - 1;
              setTimeout(() => inputRef.current?.setSelectionRange(selectionStart, selectionStart), 0);
            }
          }
        }, []);

        const handleClickMinus = useCallback((): void => onChange(value - 1), [onChange, value]);
        const handleClickPlus = useCallback((): void => onChange(value + 1), [onChange, value]);

        const handleKeyDown = useCallback(
          (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter') {
              handleApply();
            } else if (e.code === 'Escape') {
              setEditingValue(undefined);
            }
          },
          [handleApply],
        );

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            <SInput
              disabled={disabled}
              editing={isDef(editingValue)}
              inputMode='numeric'
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder={'0'}
              ref={inputRef}
              type='text'
              value={inputValueStr}
            />
            {isDef(editingValue) ? (
              <Fragment>
                <IconButton
                  icon='close'
                  onClick={(): void => setEditingValue(undefined)}
                  variant={getIconButtonVariant({
                    base: 'default',
                    disabled: disabled === true,
                    loading: false,
                  })}
                />
                <IconButton
                  icon='apply'
                  onClick={handleApply}
                  variant={getIconButtonVariant({
                    base: 'default',
                    disabled: disabled === true,
                    loading: false,
                  })}
                />
              </Fragment>
            ) : (
              <Fragment>
                <IconButton
                  icon='remove'
                  onClick={handleClickMinus}
                  variant={getIconButtonVariant({
                    base: 'default',
                    disabled: disabled === true || value <= 0,
                    loading: false,
                  })}
                />
                <IconButton
                  icon='add'
                  onClick={handleClickPlus}
                  variant={getIconButtonVariant({
                    base: 'default',
                    disabled: disabled === true,
                    loading: false,
                  })}
                />
              </Fragment>
            )}
          </SDiv>
        );
      },
    ),
  ),
);

export type TQuantityControl = {
  id: string;
  label?: string | undefined;
  required?: boolean | undefined;
};

export function QuantityControl({ id, label, required }: TQuantityControl): JSX.Element {
  const {
    input: { onBlur, onChange, onFocus, value },
    meta: { error, submitting, visited }, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
  } = useField<number>(id, {
    subscription: {
      error: true,
      submitting: true,
      value: true,
      visited: true,
    },
    validate: (v) => {
      if (required !== true || v > 0) {
        return null;
      }
      return 'Please provide a quantity.';
    },
  });

  const finalError = useMemo(
    () => (visited === true && typeof error === 'string' && error !== '' ? error : null),
    [error, visited],
  );

  return (
    <Control error={finalError} id={id} required={required} label={label}>
      <Quantity disabled={submitting} id={id} onBlur={onBlur} onChange={onChange} onFocus={onFocus} value={value} />
    </Control>
  );
}
