import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { MessageSendForm } from '@src/components/appearance/controls/MessageSendForm';
import { TextAreaControl } from '@src/components/appearance/controls/TextArea';
import { ChatBubble, ChatBubbleLoader } from '@src/components/appearance/fragments/ChatBubble';
import { Structure } from '@src/components/appearance/structure/Structure';
import { castNotificationUnsafe } from '@src/gen/shared/data/notification';
import { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { useCustomerOrderActions } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import { useCustomerOrderMessages } from '@src/modules/data/customer/order/CustomerOrderMessagesProvider';
import { useCustomerOrder } from '@src/modules/data/customer/order/CustomerOrderProvider';
import { styled } from '@src/modules/design/theme';
import { Fragment, useCallback, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: '$structureGroupGapMobile',
});

export function CustomerOrderUpdatesAndSupportPart(): JSX.Element {
  const { doOrderMessageSendAsync } = useCustomerOrderActions();
  const { order } = useCustomerOrder();
  const { orderMessages, loaderRef } = useCustomerOrderMessages();

  return (
    <Fragment>
      <Structure.ScrollContent reverse={true}>
        <SDiv>
          {orderMessages.map((om) => (
            <ChatBubble
              key={om.id}
              firstName={om.creator.first_name}
              lastName={om.creator.last_name}
              wellplaeceAgent={om.creator.is_wellplaece_agent}
              content={ensureDef(om.chat_content ?? castNotificationUnsafe(ensureDef(om.notification_content)))}
              at={om.created_at}
            />
          ))}
          {isDef(loaderRef) && <Illustrations.Spinner ref={loaderRef} />}
        </SDiv>
      </Structure.ScrollContent>
      {order.status !== EOrderStatus.CANCELED && isDef(doOrderMessageSendAsync) && <OrderMessageSend />}
    </Fragment>
  );
}

function OrderMessageSend(): JSX.Element {
  const { doOrderMessageSendAsync } = useCustomerOrderActions();

  type TFormValues = {
    message: string;
  };

  const initialValues = useMemo<TFormValues>(
    () => ({
      message: '',
    }),
    [],
  );

  const handleSubmit = useCallback<TFormSubmit<TFormValues>>(
    async (values) => {
      await ensureDef(doOrderMessageSendAsync)(values);
      return undefined;
    },
    [doOrderMessageSendAsync],
  );

  return (
    <Structure.Content>
      <MessageSendForm<TFormValues> initialValues={initialValues} onSubmit={handleSubmit}>
        <TextAreaControl id='message' label='Message' maxLength={2048} minRows={2} required={true} />
      </MessageSendForm>
    </Structure.Content>
  );
}

export function CustomerOrderUpdatesAndSupportPartLoader(): JSX.Element {
  return (
    <Structure.ScrollContentLoader>
      <Structure.GroupLoader hasTitle={false}>
        <ChatBubbleLoader wellplaeceAgent={true} />
        <ChatBubbleLoader wellplaeceAgent={false} />
        <ChatBubbleLoader wellplaeceAgent={true} />
        <ChatBubbleLoader wellplaeceAgent={false} />
        <ChatBubbleLoader wellplaeceAgent={true} />
        <ChatBubbleLoader wellplaeceAgent={false} />
        <ChatBubbleLoader wellplaeceAgent={true} />
        <ChatBubbleLoader wellplaeceAgent={false} />
        <ChatBubbleLoader wellplaeceAgent={true} />
        <ChatBubbleLoader wellplaeceAgent={false} />
      </Structure.GroupLoader>
    </Structure.ScrollContentLoader>
  );
}
