import { Drawer } from '@src/components/appearance/structure/Drawer';
import { ConfirmationDialogPanel } from '@src/components/mixins/dialogs/ConfirmationDialogPanel';
import { LocationAclEditForm } from '@src/components/mixins/forms/LocationAclEditForm';
import { OrganizationAclEditForm } from '@src/components/mixins/forms/OrganizationAclEditForm';
import { UserCustomerEditForm } from '@src/components/mixins/forms/UserCustomerEditForm';
import type {
  AgentCustomersLocationAclRemoveMutationVariables,
  AgentCustomersLocationAclUpsertMutationVariables,
  AgentCustomersOrganizationAclUpsertMutationVariables,
  AgentCustomersUserCustomerUpdateMutationVariables,
} from '@src/gen/graphql/bindings';
import {
  useAgentCustomersLocationAclRemoveMutation,
  useAgentCustomersLocationAclUpsertMutation,
  useAgentCustomersOrganizationAclUpsertMutation,
  useAgentCustomersUserCustomerUpdateMutation,
} from '@src/gen/graphql/bindings';
import { getFullName } from '@src/gen/shared/data/snippets';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useAuthAgent } from '@src/modules/auth/AuthProvider';
import { useAgentCustomersUser } from '@src/modules/data/agent/customers/users/AgentCustomersUserProvider';
import { useAgentLocationPickerDrawer } from '@src/modules/data/agent/global/overlays/AgentLocationPickerDrawerProvider';
import { useAgentOrganizationPickerDrawer } from '@src/modules/data/agent/global/overlays/AgentOrganizationPickerDrawerProvider';
import { useDialog } from '@src/modules/design/DialogProvider';
import { useDrawer } from '@src/modules/design/DrawerProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TAgentCustomersUserActionsTypes = {
  BeginLocationAclAdd: (args: { organizationId: string; omitLocationIds: { [key: string]: true } | undefined }) => void;
  BeginLocationAclRemove: (args: { locationId: string; organizationId: string }) => void;
  BeginLocationAclEdit: (args: { locationId: string; userId: string }) => void;
  BeginOrganizationAclEdit: (args: { organizationId: string }) => void;
  BeginUserCustomerUpdate: () => void;
  BeginUserOrganizationAdd: () => void;
  BeginUserOrganizationRemove: (args: { organizationId: string }) => void;
  BeginUserOrganizationRestore: (args: { organizationId: string }) => void;
  DoCustomersLocationAclUpsertAsync: (args: AgentCustomersLocationAclUpsertMutationVariables) => Promise<void>;
  DoCustomersLocationAclRemoveAsync: (args: AgentCustomersLocationAclRemoveMutationVariables) => Promise<void>;
  DoCustomersOrganizationAclUpsertAsync: (
    args: Omit<AgentCustomersOrganizationAclUpsertMutationVariables, 'userId'>,
  ) => Promise<void>;
  DoCustomersUserCustomerUpdateAsync: (
    args: Omit<AgentCustomersUserCustomerUpdateMutationVariables, 'userId'>,
  ) => Promise<void>;
};

export type TAgentCustomersUserActionsContext = {
  beginLocationAclAdd: TAgentCustomersUserActionsTypes['BeginLocationAclAdd'] | null;
  beginLocationAclRemove: TAgentCustomersUserActionsTypes['BeginLocationAclRemove'] | null;
  beginLocationAclEdit: TAgentCustomersUserActionsTypes['BeginLocationAclEdit'] | null;
  beginOrganizationAclEdit: TAgentCustomersUserActionsTypes['BeginOrganizationAclEdit'] | null;
  beginUserCustomerUpdate: TAgentCustomersUserActionsTypes['BeginUserCustomerUpdate'] | null;
  beginUserOrganizationAdd: TAgentCustomersUserActionsTypes['BeginUserOrganizationAdd'] | null;
  beginUserOrganizationRemove: TAgentCustomersUserActionsTypes['BeginUserOrganizationRemove'] | null;
  beginUserOrganizationRestore: TAgentCustomersUserActionsTypes['BeginUserOrganizationRestore'] | null;
};

export const { Context: AgentCustomersUserActionsContext, useContext: useAgentCustomersUserActions } =
  createRequiredContext<TAgentCustomersUserActionsContext>();

export function AgentCustomersUserActionsProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const user = useAuthAgent();
  const { user: customersUser } = useAgentCustomersUser();

  const beginLocationAclAdd = useBeginLocationAclAdd();
  const beginLocationAclRemove = useBeginLocationAclRemove();
  const beginLocationAclEdit = useBeginLocationAclEdit();
  const beginOrganizationAclEdit = useBeginOrganizationAclEdit();
  const beginUserCustomerUpdate = useBeginUserCustomerUpdate();
  const beginUserOrganizationAdd = useBeginUserOrganizationAdd();
  const beginUserOrganizationRemove = useBeginUserOrganizationRemove();
  const beginUserOrganizationRestore = useBeginUserOrganizationRestore();

  const canEditOrgLocAcls = user.agent_can_edit_organization_acls_and_location_acls;

  const value = useMemo<TAgentCustomersUserActionsContext>(
    () => ({
      beginLocationAclAdd: canEditOrgLocAcls ? beginLocationAclAdd : null,
      beginLocationAclRemove: canEditOrgLocAcls ? beginLocationAclRemove : null,
      beginLocationAclEdit: canEditOrgLocAcls ? beginLocationAclEdit : null,
      beginOrganizationAclEdit: canEditOrgLocAcls ? beginOrganizationAclEdit : null,
      beginUserCustomerUpdate: user.agent_can_edit_users ? beginUserCustomerUpdate : null,
      beginUserOrganizationAdd: !customersUser.is_disabled && canEditOrgLocAcls ? beginUserOrganizationAdd : null,
      beginUserOrganizationRemove: canEditOrgLocAcls ? beginUserOrganizationRemove : null,
      beginUserOrganizationRestore:
        !customersUser.is_disabled && canEditOrgLocAcls ? beginUserOrganizationRestore : null,
    }),
    // @sort
    [
      beginLocationAclAdd,
      beginLocationAclEdit,
      beginLocationAclRemove,
      beginOrganizationAclEdit,
      beginUserCustomerUpdate,
      beginUserOrganizationAdd,
      beginUserOrganizationRemove,
      beginUserOrganizationRestore,
      canEditOrgLocAcls,
      customersUser.is_disabled,
      user.agent_can_edit_users,
    ],
  );

  return (
    <AgentCustomersUserActionsContext.Provider value={value}>{children}</AgentCustomersUserActionsContext.Provider>
  );
}

function useBeginLocationAclEdit(): TAgentCustomersUserActionsTypes['BeginLocationAclEdit'] {
  const [customersLocationAclUpsertMutation] = useAgentCustomersLocationAclUpsertMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { user, doRefetchUser } = useAgentCustomersUser();

  const doCustomersLocationAclUpsertAsync = useCallback<
    TAgentCustomersUserActionsTypes['DoCustomersLocationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersLocationAclUpsertMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchUser();
      doDrawerClose();
    },
    // @sort
    [customersLocationAclUpsertMutation, doDrawerClose, doRefetchUser],
  );

  return useCallback<TAgentCustomersUserActionsTypes['BeginLocationAclEdit']>(
    (args) => {
      const locationAcl = ensureDef(
        user.location_acls.find((locAcl) => locAcl.user_id === args.userId && locAcl.location_id === args.locationId),
      );

      const organizationAcl = ensureDef(
        user.organization_acls.find(
          (orgAcl) => orgAcl.user_id === args.userId && orgAcl.organization_id === locationAcl.location.organization_id,
        ),
      );

      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Edit User Permissions' />
          <Drawer.ScrollContent>
            <LocationAclEditForm
              doCustomersLocationAclUpsertAsync={doCustomersLocationAclUpsertAsync}
              locationAcl={locationAcl}
              locationName={locationAcl.location.name}
              userFullName={getFullName(user)}
              canEditCanViewAnalytics={!organizationAcl.can_view_analytics}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersLocationAclUpsertAsync, doDrawerOpen, user],
  );
}

function useBeginUserCustomerUpdate(): TAgentCustomersUserActionsTypes['BeginUserCustomerUpdate'] {
  const [customersUserCustomerUpdateMutation] = useAgentCustomersUserCustomerUpdateMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { user, doRefetchUser } = useAgentCustomersUser();

  const doCustomersUserCustomerUpdateAsync = useCallback<
    TAgentCustomersUserActionsTypes['DoCustomersUserCustomerUpdateAsync']
  >(
    async (args) => {
      const { errors } = await customersUserCustomerUpdateMutation({
        variables: {
          ...args,
          userId: user.id,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchUser();
      doDrawerClose();
    },
    // @sort
    [customersUserCustomerUpdateMutation, doDrawerClose, doRefetchUser, user.id],
  );

  return useCallback<TAgentCustomersUserActionsTypes['BeginUserCustomerUpdate']>(
    () => {
      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Edit User (Customer)' />
          <Drawer.ScrollContent>
            <UserCustomerEditForm doCustomersUserCustomerUpdateAsync={doCustomersUserCustomerUpdateAsync} user={user} />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersUserCustomerUpdateAsync, doDrawerOpen, user],
  );
}

function useBeginUserOrganizationAdd(): TAgentCustomersUserActionsTypes['BeginUserOrganizationAdd'] {
  const [customersOrganizationAclUpsertMutation] = useAgentCustomersOrganizationAclUpsertMutation();
  const { beginOrganizationPick } = useAgentOrganizationPickerDrawer();
  const { user, doRefetchUser } = useAgentCustomersUser();

  const doCustomersOrganizationAclUpsertAsync = useCallback<
    TAgentCustomersUserActionsTypes['DoCustomersOrganizationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationAclUpsertMutation({
        variables: {
          userId: user.id,
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchUser();
    },
    // @sort
    [customersOrganizationAclUpsertMutation, doRefetchUser, user.id],
  );

  return useCallback<TAgentCustomersUserActionsTypes['BeginUserOrganizationAdd']>(
    () => {
      beginOrganizationPick({
        buttonActionText: 'Add',
        drawerHeaderTitle: 'Add User to Organization',
        callback: async (organization): Promise<void> => {
          await doCustomersOrganizationAclUpsertAsync({
            organizationId: organization.id,
            isMember: true,
            canViewAnalytics: false,
          });
        },
        omitOrganizationIds: user.organization_acls.reduce<{ [key: string]: true }>(
          (out, orgAcl) => ({ ...out, [orgAcl.organization_id]: true }),
          {},
        ),
      });
    },
    // @sort
    [beginOrganizationPick, doCustomersOrganizationAclUpsertAsync, user.organization_acls],
  );
}
function useBeginLocationAclAdd(): TAgentCustomersUserActionsTypes['BeginLocationAclAdd'] {
  const [customersLocationAclUpsertMutation] = useAgentCustomersLocationAclUpsertMutation();

  const { beginLocationPick } = useAgentLocationPickerDrawer();
  const { user, doRefetchUser } = useAgentCustomersUser();

  const doCustomersLocationAclUpsertAsync = useCallback<
    TAgentCustomersUserActionsTypes['DoCustomersLocationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersLocationAclUpsertMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchUser();
    },
    // @sort
    [customersLocationAclUpsertMutation, doRefetchUser],
  );

  return useCallback<TAgentCustomersUserActionsTypes['BeginLocationAclAdd']>(
    (args) => {
      beginLocationPick({
        organizationId: args.organizationId,
        buttonActionText: 'Add',
        drawerHeaderTitle: 'Add User to Location',
        callback: async (location): Promise<void> => {
          await doCustomersLocationAclUpsertAsync({
            locationId: location.id,
            userId: user.id,
            canViewOrders: true,
            canSendOrderMessages: false,
            canPlaceOrders: false,
            canApproveOrders: false,
            canViewAnalytics: false,
          });
        },
        omitLocationIds: args.omitLocationIds,
      });
    },
    // @sort
    [beginLocationPick, doCustomersLocationAclUpsertAsync, user.id],
  );
}

function useBeginLocationAclRemove(): TAgentCustomersUserActionsTypes['BeginLocationAclRemove'] {
  const [customersLocationAclRemoveMutation] = useAgentCustomersLocationAclRemoveMutation();
  const { doDialogClose, doDialogOpen } = useDialog();
  const { user, doRefetchUser } = useAgentCustomersUser();

  const doCustomersLocationAclRemoveAsync = useCallback<
    TAgentCustomersUserActionsTypes['DoCustomersLocationAclRemoveAsync']
  >(
    async (args) => {
      const { errors } = await customersLocationAclRemoveMutation({
        variables: {
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchUser();
      doDialogClose();
    },
    // @sort
    [customersLocationAclRemoveMutation, doDialogClose, doRefetchUser],
  );

  return useCallback<TAgentCustomersUserActionsTypes['BeginLocationAclRemove']>(
    (args) => {
      const location = ensureDef(user.location_acls.find((loc) => loc.location_id === args.locationId)).location;

      doDialogOpen(
        <ConfirmationDialogPanel
          title='Remove User from Location'
          message={[`Remove user "${getFullName(user)}"`, `from location "${location.name}"?`].join(' ')}
          action='Remove'
          onConfirm={async (): Promise<void> =>
            await doCustomersLocationAclRemoveAsync({ userId: user.id, locationId: args.locationId })
          }
        />,
      );
    },
    // @sort
    [doCustomersLocationAclRemoveAsync, doDialogOpen, user],
  );
}

function useBeginOrganizationAclEdit(): TAgentCustomersUserActionsTypes['BeginOrganizationAclEdit'] {
  const [customersOrganizationAclUpsertMutation] = useAgentCustomersOrganizationAclUpsertMutation();
  const { doDrawerClose, doDrawerOpen } = useDrawer();
  const { user, doRefetchUser } = useAgentCustomersUser();

  const doCustomersOrganizationAclUpsertAsync = useCallback<
    TAgentCustomersUserActionsTypes['DoCustomersOrganizationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationAclUpsertMutation({
        variables: {
          ...args,
          userId: user.id,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchUser();
      doDrawerClose();
    },
    // @sort
    [customersOrganizationAclUpsertMutation, doDrawerClose, doRefetchUser, user.id],
  );

  return useCallback<TAgentCustomersUserActionsTypes['BeginOrganizationAclEdit']>(
    (args) => {
      const organizationAcl = ensureDef(
        user.organization_acls.find(
          (orgAcl) => orgAcl.user_id === user.id && orgAcl.organization_id === args.organizationId,
        ),
      );

      doDrawerOpen(
        <Drawer.Panel>
          <Drawer.Header title='Edit User Permissions' />
          <Drawer.ScrollContent>
            <OrganizationAclEditForm
              doCustomersOrganizationAclUpsertAsyncGivenUser={doCustomersOrganizationAclUpsertAsync}
              doCustomersOrganizationAclUpsertAsyncGivenOrganization={null}
              organizationAcl={organizationAcl}
              organizationName={organizationAcl.organization.name}
              userFullName={getFullName(user)}
            />
          </Drawer.ScrollContent>
        </Drawer.Panel>,
      );
    },
    // @sort
    [doCustomersOrganizationAclUpsertAsync, doDrawerOpen, user],
  );
}

function useBeginUserOrganizationRemove(): TAgentCustomersUserActionsTypes['BeginUserOrganizationRemove'] {
  const [customersOrganizationAclUpsertMutation] = useAgentCustomersOrganizationAclUpsertMutation();
  const { doDialogClose, doDialogOpen } = useDialog();
  const { user, doRefetchUser } = useAgentCustomersUser();

  const doCustomersOrganizationAclUpsertAsync = useCallback<
    TAgentCustomersUserActionsTypes['DoCustomersOrganizationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationAclUpsertMutation({
        variables: {
          userId: user.id,
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchUser();
      doDialogClose();
    },
    // @sort
    [customersOrganizationAclUpsertMutation, doDialogClose, doRefetchUser, user.id],
  );

  return useCallback<TAgentCustomersUserActionsTypes['BeginUserOrganizationRemove']>(
    (args) => {
      const organization = ensureDef(
        user.organization_acls.find((oa) => oa.organization_id === args.organizationId),
      ).organization;

      doDialogOpen(
        <ConfirmationDialogPanel
          title='Remove User from Organization'
          message={[
            `Remove user "${getFullName(user)}"`,
            `from organization "${organization.name}"?`,
            'This action will also remove all location permissions for this user within the organization.',
          ].join(' ')}
          action='Remove'
          onConfirm={async (): Promise<void> =>
            await doCustomersOrganizationAclUpsertAsync({
              organizationId: args.organizationId,
              isMember: false,
              canViewAnalytics: false,
            })
          }
        />,
      );
    },
    // @sort
    [doCustomersOrganizationAclUpsertAsync, doDialogOpen, user],
  );
}

function useBeginUserOrganizationRestore(): TAgentCustomersUserActionsTypes['BeginUserOrganizationRestore'] {
  const [customersOrganizationAclUpsertMutation] = useAgentCustomersOrganizationAclUpsertMutation();
  const { doDialogClose, doDialogOpen } = useDialog();
  const { user, doRefetchUser } = useAgentCustomersUser();

  const doCustomersOrganizationAclUpsertAsync = useCallback<
    TAgentCustomersUserActionsTypes['DoCustomersOrganizationAclUpsertAsync']
  >(
    async (args) => {
      const { errors } = await customersOrganizationAclUpsertMutation({
        variables: {
          userId: user.id,
          ...args,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      await doRefetchUser();
      doDialogClose();
    },
    // @sort
    [customersOrganizationAclUpsertMutation, doDialogClose, doRefetchUser, user.id],
  );

  return useCallback<TAgentCustomersUserActionsTypes['BeginUserOrganizationRestore']>(
    (args) => {
      const organization = ensureDef(
        user.organization_acls.find((oa) => oa.organization_id === args.organizationId),
      ).organization;

      doDialogOpen(
        <ConfirmationDialogPanel
          title='Restore User to Organization'
          message={[
            `Restore user "${getFullName(user)}"`,
            `to organization "${organization.name}"?`,
            'Note that any location permissions the user previously had will have to be added again.',
          ].join(' ')}
          action='Restore'
          onConfirm={async (): Promise<void> =>
            await doCustomersOrganizationAclUpsertAsync({
              organizationId: args.organizationId,
              isMember: true,
              canViewAnalytics: false,
            })
          }
        />,
      );
    },
    // @sort
    [doCustomersOrganizationAclUpsertAsync, doDialogOpen, user],
  );
}
