import { Types as AmplitudeTypes, init as initAmplitude } from '@amplitude/analytics-browser';
import { BrowserTracing, init as initSentry, reactRouterV6Instrumentation } from '@sentry/react';
import { ensureNotEmptyString } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { parseConfigFromEnv } from '@src/modules/config/env';
import type { PropsWithChildren } from 'react';
import { useEffect, useRef } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

export type TConfig = {
  NODE_ENV: 'development' | 'production';
  WP_ENTRY_POINT: 'app' | 'showcase';
  WP_STAGE: 'local' | 'prod' | 'staging';
  WP_VERSION: string;
  WP_HASURA_BASE_URL: string;
  WP_STORAGE_PUBLIC_BASE_URL: string;
  WP_WPX_STORAGE_PUBLIC_BASE_URL: string;
  WP_SENTRY_DSN: string;
  WP_AMPLITUDE_API_KEY: string;
};

export type TConfigContext = TConfig & {
  getPublicImageUrl: (assetPath: string) => string;
};

export const { Context: ConfigContext, useContext: useConfig } = createRequiredContext<TConfigContext>();

export function ConfigProvider({ children }: PropsWithChildren): JSX.Element {
  const configRef = useRef(parseConfigFromEnv());

  const valueRef = useRef<TConfigContext>({
    ...configRef.current,
    getPublicImageUrl: (assetPath: string) =>
      `${
        assetPath.startsWith('products')
          ? configRef.current.WP_WPX_STORAGE_PUBLIC_BASE_URL
          : configRef.current.WP_STORAGE_PUBLIC_BASE_URL
      }/${assetPath}`,
  });

  return <ConfigContext.Provider value={valueRef.current}>{children}</ConfigContext.Provider>;
}

export function initStaticConfig(): true {
  const config = parseConfigFromEnv();
  console.log(`[wp-${config.WP_STAGE}-${config.WP_VERSION}]`);

  initAmplitude(config.WP_AMPLITUDE_API_KEY, {
    defaultTracking: false,
    logLevel: config.WP_STAGE === 'local' ? AmplitudeTypes.LogLevel.Verbose : AmplitudeTypes.LogLevel.Error,
  });

  initSentry({
    autoSessionTracking: true,
    debug: config.WP_STAGE !== 'prod',
    dsn: ensureNotEmptyString(config.WP_SENTRY_DSN),
    environment: config.WP_STAGE,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
        traceFetch: false,
        traceXHR: false,
        tracePropagationTargets: ['localhost', /^https:\/\/app\.(staging\.)?wellplaece\.com/],
      }),
    ],
    release: config.WP_VERSION,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1.0,
  });

  return true;
}
