import assert from 'assert';

export type TPresignedPost = {
  url: string;
  fields: { [key: string]: string };
};

export function castPresignedPostUnsafe(presignedPost: unknown): TPresignedPost {
  assert(typeof presignedPost === 'object');
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return presignedPost as TPresignedPost;
}
