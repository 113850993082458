import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import { getCombinedProductName } from '@src/gen/shared/data/snippets';
import { getSource, getSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TMinimalProductItemBase = {
  name: string;
  productSku: string;
  secondaryName: string | null;
  source: string | null;
};

export type TMinimalProductItem = TProps<false, TMinimalProductItemBase, 'div'>;
export const MINIMAL_PRODUCT_ITEM_CLASS_NAME = 'wp-minimal-product-item';

export const MinimalProductItem = withCssToString(
  MINIMAL_PRODUCT_ITEM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TMinimalProductItem>(
      ({ name, productSku, secondaryName, source, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, MINIMAL_PRODUCT_ITEM_CLASS_NAME), [className]);

        const item = useMemo<TItemBaseItem>(
          () => ({
            caption: isDef(source) ? `${getSourceName(getSource(source))} · ${productSku}` : productSku,
            text: getCombinedProductName(name, secondaryName),
          }),
          // @sort
          [name, productSku, secondaryName, source],
        );

        return <Item {...rest} className={joinedClassName} item={item} ref={ref} rigid={true} />;
      },
    ),
  ),
);
