import type { TNotification } from '@src/gen/shared/data/notification';
import { ENotificationType } from '@src/gen/shared/data/notification';
import { EOrderAttachmentType } from '@src/gen/shared/enums/orderAttachmentType';
import { maybeGetOrderBackOrderDecisionLabel } from '@src/gen/shared/enums/orderBackOrderDecision';
import { EOrderPaymentType } from '@src/gen/shared/enums/orderPaymentType';
import { EOrderSnapshotType } from '@src/gen/shared/enums/orderSnapshotType';
import { maybeGetOrderStatusLabel } from '@src/gen/shared/enums/orderStatus';
import { formatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { ensureDef, ensureNotEmptyString, isDef } from '@src/gen/shared/utils/types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parse from 'date-fns/parse';
import slug from 'slug';

export function getFullName(
  user: { first_name: string; last_name: string } | { firstName: string; lastName: string },
): string;
export function getFullName(firstName: string, lastName: string): string;
export function getFullName(a: unknown, b?: unknown): unknown {
  if (a !== null && typeof a === 'object') {
    if ('first_name' in a && 'last_name' in a && typeof a.first_name === 'string' && typeof a.last_name === 'string') {
      return `${a.first_name} ${a.last_name}`;
    }
    if ('firstName' in a && 'lastName' in a && typeof a.firstName === 'string' && typeof a.lastName === 'string') {
      return `${a.firstName} ${a.lastName}`;
    }
  }

  if (typeof a === 'string' && typeof b === 'string') {
    return `${a} ${b}`;
  }

  /* istanbul ignore next: untestable */
  throw new Error('Unreachable.');
}

export function getCombinedProductName(name: string, secondaryName: string | null): string {
  return [name, secondaryName]
    .map((n) => n?.trim() ?? '')
    .filter((n) => n !== '')
    .join(' · ');
}

export function getOrderReference(
  location: { order_code: string } | { orderCode: string },
  order: { reference_number: number } | { referenceNumber: number },
): string;
export function getOrderReference(locationOrderCode: string, orderReferenceNumber: number): string;
export function getOrderReference(a: unknown, b: unknown): unknown {
  let locationOrderCode: string | undefined = undefined;
  let orderReferenceNumber: number | undefined = undefined;

  if (a !== null && typeof a === 'object') {
    if ('order_code' in a && typeof a.order_code === 'string') {
      locationOrderCode = a.order_code;
    }
    if ('orderCode' in a && typeof a.orderCode === 'string') {
      locationOrderCode = a.orderCode;
    }
  }

  if (typeof a === 'string') {
    locationOrderCode = a;
  }

  if (b !== null && typeof b === 'object') {
    if ('reference_number' in b && typeof b.reference_number === 'number') {
      orderReferenceNumber = b.reference_number;
    }
    if ('referenceNumber' in b && typeof b.referenceNumber === 'number') {
      orderReferenceNumber = b.referenceNumber;
    }
  }

  if (typeof b === 'number') {
    orderReferenceNumber = b;
  }

  return `# ${ensureNotEmptyString(locationOrderCode)}-${`${ensureDef(orderReferenceNumber)}`.padStart(6, '0')}`;
}

export function getSaleUnitAnnotation(saleUnit: string | null | undefined): string {
  return `Sale Unit: ${saleUnit?.toUpperCase() ?? 'As Described'}`;
}

export function getSaleUnitQuantity(quantity: number, saleUnit: string | null | undefined): string {
  return `${quantity}${isDef(saleUnit) ? ` (${saleUnit.toUpperCase()})` : ''}`;
}

export function getSaleUnitQuantityWithFallbackPrefix(
  fallbackPrefix: string,
  quantity: number | null | undefined,
  saleUnit: string | null | undefined,
): string {
  return `${isDef(quantity) ? `${quantity}` : fallbackPrefix}${isDef(saleUnit) ? ` (${saleUnit.toUpperCase()})` : ''}`;
}

export function getSaleUnitQuantityWithOverrideAndFallbackPrefix(
  fallbackPrefix: string,
  quantity: number | null | undefined,
  overrideQuantity: number | null | undefined,
  saleUnit: string | null | undefined,
): string {
  if (!isDef(overrideQuantity) || !isDef(quantity)) {
    return getSaleUnitQuantityWithFallbackPrefix(fallbackPrefix, quantity, saleUnit);
  }

  return `${overrideQuantity} (was ${quantity})${isDef(saleUnit) ? ` (${saleUnit.toUpperCase()})` : ''}`;
}

export function getSaleUnitQuantityWithRequestAndFallbackPrefix(
  fallbackPrefix: string,
  quantity: number | null | undefined,
  requestQuantity: number | null | undefined,
  saleUnit: string | null | undefined,
): string {
  if (!isDef(requestQuantity) || !isDef(quantity)) {
    return getSaleUnitQuantityWithFallbackPrefix(fallbackPrefix, quantity, saleUnit);
  }

  return `${quantity} (requested ${requestQuantity})${isDef(saleUnit) ? ` (${saleUnit.toUpperCase()})` : ''}`;
}

export function getOrderAttachmentTitle(type: EOrderAttachmentType, description: string | null): string {
  switch (type) {
    case EOrderAttachmentType.PREBILL:
      return 'Prebill';
    case EOrderAttachmentType.INVOICE:
      return 'Invoice';
    case EOrderAttachmentType.RETURN_LABEL:
      return 'Return Label';
    case EOrderAttachmentType.OTHER:
      return ensureNotEmptyString(description);
  }
}

export function getOrderAttachmentSubTitle(
  type: EOrderAttachmentType,
  description: string | null,
  revision: number,
): string | undefined {
  switch (type) {
    case EOrderAttachmentType.INVOICE:
    case EOrderAttachmentType.PREBILL:
      return revision > 0 ? `Revision #${revision}` : undefined;
    case EOrderAttachmentType.RETURN_LABEL:
      return `for "${ensureNotEmptyString(description)}"`;
    default:
      return undefined;
  }
}

export function getOrderAttachmentFileNamePart(
  type: EOrderAttachmentType,
  description: string | null,
  revision: number,
): string {
  switch (type) {
    case EOrderAttachmentType.INVOICE:
    case EOrderAttachmentType.PREBILL:
      return slug(`${type}${revision > 0 ? `-rev-${`${revision}`.padStart(3, '0')}` : ''}`);
    case EOrderAttachmentType.RETURN_LABEL:
      return slug(`return-label-for-${ensureDef(description)}`);
    case EOrderAttachmentType.OTHER:
      return slug(ensureDef(description));
  }
}

export function maybeGetNotificationTextParts(notification: TNotification): string[] {
  switch (notification.type) {
    case ENotificationType.ORDER_PLACED:
      const orderReference = getOrderReference(notification.location.orderCode, notification.order.referenceNumber);
      return [`Placed order ${orderReference} for ${notification.location.name}.`];
    case ENotificationType.ORDER_UPDATED:
      const parts: string[] = [];

      if (notification.orderUpdates.status !== undefined) {
        const from = maybeGetOrderStatusLabel(notification.orderUpdates.status.from);
        const to = maybeGetOrderStatusLabel(notification.orderUpdates.status.to);
        parts.push(`Updated the status of the order from ${from} to ${to}.`);
      }

      if (notification.orderUpdates.isBlockedOnCustomer !== undefined) {
        const fill = notification.orderUpdates.isBlockedOnCustomer.to ? ' ' : ' not ';
        parts.push(`Marked the order as${fill}waiting on customer.`);
      }

      if (notification.orderUpdates.hasBackOrderedProducts !== undefined) {
        const fill = notification.orderUpdates.hasBackOrderedProducts.to ? ' ' : ' not ';
        parts.push(`Marked the order as${fill}having back-ordered products.`);
      }

      if (notification.orderUpdates.hasPendingReturns !== undefined) {
        const fill = notification.orderUpdates.hasPendingReturns.to ? ' ' : ' not ';
        parts.push(`Marked the order as${fill}having products currently being returned.`);
      }

      if (notification.orderUpdates.isPaid !== undefined) {
        const fill = notification.orderUpdates.isPaid.to ? ' ' : ' not ';
        parts.push(`Marked the order as${fill}paid.`);
      }

      return parts;
    case ENotificationType.ORDER_CANCELED:
      return ['Canceled the order.'];
    case ENotificationType.ORDER_ATTACHMENT_ADDED:
      const oaa = notification.orderAttachment;

      switch (oaa.type) {
        case EOrderAttachmentType.INVOICE:
          return [`Attached an invoice${oaa.revision > 0 ? ` (revision #${oaa.revision})` : ''} to the order.`];
        case EOrderAttachmentType.PREBILL:
          return [`Attached a prebill${oaa.revision > 0 ? ` (revision #${oaa.revision})` : ''} to the order.`];
        case EOrderAttachmentType.RETURN_LABEL:
          return [`Attached a return label for "${ensureDef(oaa.description)}" to the order.`];
        case EOrderAttachmentType.OTHER:
          return [`Added attachment "${ensureDef(oaa.description)}" to the order.`];
        /* istanbul ignore next: untestable */
        default:
          throw new Error('Unreachable.');
      }
    case ENotificationType.ORDER_ATTACHMENT_REMOVED:
      const oar = notification.orderAttachment;

      switch (oar.type) {
        case EOrderAttachmentType.INVOICE:
          return [`Removed an invoice${oar.revision > 0 ? ` (revision #${oar.revision})` : ''} from the order.`];
        case EOrderAttachmentType.PREBILL:
          return [`Removed a prebill${oar.revision > 0 ? ` (revision #${oar.revision})` : ''} from the order.`];
        case EOrderAttachmentType.RETURN_LABEL:
          return [`Removed a return label for "${ensureDef(oar.description)}" from the order.`];
        case EOrderAttachmentType.OTHER:
          return [`Removed attachment "${ensureDef(oar.description)}" from the order.`];
        /* istanbul ignore next: untestable */
        default:
          throw new Error('Unreachable.');
      }
    case ENotificationType.ORDER_TRACKING_NUMBER_ADDED:
      return [`Added tracking number "${notification.orderTrackingNumber.trackingNumber}" to the order.`];
    case ENotificationType.ORDER_TRACKING_NUMBER_REMOVED:
      return [`Removed tracking number "${notification.orderTrackingNumber.trackingNumber}" from the order.`];
    case ENotificationType.ORDER_MESSAGE_SENT:
      return [`Sent message "${notification.orderMessage.chatContentSummary}".`];
    case ENotificationType.ORDER_SNAPSHOT_ISSUED:
      const osi = notification.orderSnapshot;
      return [
        `Issued ${osi.type === EOrderSnapshotType.PREBILL ? 'a prebill' : 'an invoice'}${
          osi.revision > 0 ? ` (revision #${osi.revision})` : ''
        } for the order.`,
      ];
    case ENotificationType.ORDER_SNAPSHOT_APPROVED:
      const osa = notification.orderSnapshot;
      return [`Approved a prebill${osa.revision > 0 ? ` (revision #${osa.revision})` : ''} for the order.`];
    case ENotificationType.ORDER_SNAPSHOT_REJECTED:
      const osr = notification.orderSnapshot;

      if (notification.user.isWellplaeceAgent) {
        return [`Retracted a prebill${osr.revision > 0 ? ` (revision #${osr.revision})` : ''} for the order.`];
      } else {
        return [
          `Rejected a prebill${osr.revision > 0 ? ` (revision #${osr.revision})` : ''} for the order.`,
          `Reason: ${ensureDef(osr.rejectedReasonSummary)}`,
        ];
      }
    case ENotificationType.ORDER_SHIPMENT_ADDED:
      const osha = notification.orderShipment;
      return [
        `Added a shipment with ${osha.uniqueItemsCount} unique item(s) to the order.`,
        `Tracking number(s): ${osha.trackingNumbers.join(', ')}.`,
      ];
    case ENotificationType.ORDER_SHIPMENT_REMOVED:
      const oshr = notification.orderShipment;
      return [
        `Removed a shipment with ${oshr.uniqueItemsCount} unique item(s) from the order.`,
        `Tracking number(s): ${oshr.trackingNumbers.join(', ')}.`,
      ];
    case ENotificationType.ORDER_BACK_ORDER_ADDED:
      const oboad = notification.orderBackOrder;
      return [
        `Notified of a back-order for qty. ${oboad.quantity} of product "${oboad.productName}".`,
        'Please advise on how to proceed.',
      ];
    case ENotificationType.ORDER_BACK_ORDER_REPLIED:
      const obor = notification.orderBackOrder;
      return [
        `Made a decision regarding a back-order for qty. ${obor.quantity} of product "${obor.productName}".`,
        `Decision: ${maybeGetOrderBackOrderDecisionLabel(ensureDef(obor.repliedDecision))}`,
      ];
    case ENotificationType.ORDER_BACK_ORDER_APPLIED:
      const oboap = notification.orderBackOrder;
      return [`Applied a back-order for qty. ${oboap.quantity} of product "${oboap.productName}" to the order.`];
    case ENotificationType.ORDER_BACK_ORDER_REMOVED:
      const oborm = notification.orderBackOrder;
      return [`Removed a back-order for qty. ${oborm.quantity} of product "${oborm.productName}" from the order.`];
    case ENotificationType.ORDER_RETURN_ADDED:
      const orad = notification.orderReturn;
      return [`Initiated a return for qty. ${orad.quantity} of product "${orad.productName}".`];
    case ENotificationType.ORDER_RETURN_REPLIED:
      const orrp = notification.orderReturn;
      return [
        `Provided return instructions for qty. ${orrp.quantity} of product "${orrp.productName}".`,
        'Please go ahead and return the product.',
      ];
    case ENotificationType.ORDER_RETURN_EXECUTED:
      const orre = notification.orderReturn;
      return [`Reported that a return for qty. ${orre.quantity} of product "${orre.productName}" is in progress.`];
    case ENotificationType.ORDER_RETURN_APPLIED:
      const orap = notification.orderReturn;
      const message = [`Applied a return for qty. ${orap.quantity} of product "${orap.productName}" to the order.`];
      if (orap.skipReturn === true) {
        message.push('You do not need to physically return these items. Please dispose of them responsibly.');
      }
      return message;
    case ENotificationType.ORDER_RETURN_REMOVED:
      const orrm = notification.orderReturn;
      return [`Removed a return for qty. ${orrm.quantity} of product "${orrm.productName}" from the order.`];
    case ENotificationType.ORDER_PAYMENT_ADDED:
      const opa = notification.orderPayment;
      return [
        `Reported a ${opa.type === EOrderPaymentType.CHARGE ? 'charge' : 'refund'} of ${formatDollarsCurrency(
          opa.amount,
        )} applied to the customer's payment method for this order.`,
      ];
    case ENotificationType.ORDER_PAYMENT_REMOVED:
      const opr = notification.orderPayment;
      return [
        `Removed a ${opr.type === EOrderPaymentType.CHARGE ? 'charge' : 'refund'} of ${formatDollarsCurrency(
          opr.amount,
        )} for this order.`,
      ];
  }
}

export function getDate(date: Date | string): Date {
  return typeof date === 'string' ? new Date(date) : date;
}

export function getDateOn(date: Date | string): string {
  return getDate(date).toLocaleString();
}

export function getDateAgo(date: Date | string): string {
  return `${formatDistanceToNow(getDate(date))} ago`;
}

export function getDateDate(date: Date | string): string {
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return parse(date, 'yyyy-MM-dd', new Date()).toLocaleDateString();
  }

  return getDate(date).toLocaleDateString();
}

export function getFileType(contentType: string): string {
  switch (contentType) {
    case 'application/pdf':
      return 'PDF';
    case 'application/rtf':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'Text';
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'Presentation';
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'Spreadsheet';
    case 'image/jpeg':
    case 'image/png':
      return 'Image';
    default:
      return 'Unknown';
  }
}

export function getFileExtension(contentType: string): string {
  switch (contentType) {
    case 'application/pdf':
      return 'pdf';
    case 'application/rtf':
      return 'rtf';
    case 'application/vnd.oasis.opendocument.text':
      return 'odt';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.oasis.opendocument.presentation':
      return 'odp';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx';
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return 'ods';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    default:
      return 'bin';
  }
}
