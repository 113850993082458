import { CheckBoxControl } from '@src/components/appearance/controls/CheckBox';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { SelectControl } from '@src/components/appearance/controls/Select';
import { Card } from '@src/components/appearance/fragments/Card';
import { Item } from '@src/components/appearance/fragments/Item';
import type {
  AgentCustomersLocationAclUpsertMutationVariables,
  TAgentCustomersLocationAclBaseFragment,
} from '@src/gen/graphql/bindings';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersOrganizationActionsTypes } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TLocationAclEditFormValues = {
  value: 'approve_orders' | 'place_orders' | 'send_order_messages' | 'view_orders';
  canViewAnalytics: boolean;
};

export type TLocationAclEditFormBase = {
  doCustomersLocationAclUpsertAsync: TAgentCustomersOrganizationActionsTypes['DoCustomersLocationAclUpsertAsync'];
  locationAcl: Pick<
    TAgentCustomersLocationAclBaseFragment,
    | 'can_approve_orders'
    | 'can_place_orders'
    | 'can_send_order_messages'
    | 'can_view_orders'
    | 'location_id'
    | 'user_id'
    | 'can_view_analytics'
  >;
  locationName: string;
  userFullName: string;
  canEditCanViewAnalytics: boolean;
};

export type TLocationAclEditForm = TProps<false, TLocationAclEditFormBase, 'div'>;
export const LOCATION_ACL_EDIT_FORM_CLASS_NAME = 'wp-location-acl-edit-form';

export const LocationAclEditForm = withCssToString(
  LOCATION_ACL_EDIT_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TLocationAclEditForm>(
      (
        {
          doCustomersLocationAclUpsertAsync,
          locationAcl,
          locationName,
          userFullName,
          canEditCanViewAnalytics,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, LOCATION_ACL_EDIT_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TLocationAclEditFormValues>(
          () => ({
            value: toValue(locationAcl),
            canViewAnalytics: locationAcl.can_view_analytics,
          }),
          [locationAcl],
        );

        const handleSubmit = useCallback<TFormSubmit<TLocationAclEditFormValues>>(
          async (values) => {
            await doCustomersLocationAclUpsertAsync({
              ...fromValue(values.value),
              locationId: locationAcl.location_id,
              userId: locationAcl.user_id,
              canViewAnalytics: values.canViewAnalytics,
            });

            return undefined;
          },
          // @sort
          [doCustomersLocationAclUpsertAsync, locationAcl.location_id, locationAcl.user_id],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TLocationAclEditFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              requireChanges={true}
              submitButtonText='Save'>
              <Item item={{ caption: 'Location', text: locationName }} />
              <Item item={{ caption: 'User', text: userFullName }} />
              <Card.Separator />
              <SelectControl id='value' label='Permissions for Orders'>
                <option value='view_orders'>View</option>
                <option value='send_order_messages'>View + Send Messages</option>
                <option value='place_orders'>View + Send Messages + Place</option>
                <option value='approve_orders'>View + Send Messages + Place + Approve</option>
              </SelectControl>
              {canEditCanViewAnalytics ? (
                <CheckBoxControl id='canViewAnalytics' label='View Analytics' disabled={!canEditCanViewAnalytics} />
              ) : (
                <Item item={{ caption: 'View Analytics', text: 'Yes (Organization Setting)' }} />
              )}
            </Form>
          </div>
        );
      },
    ),
  ),
);

function fromValue(
  value: TLocationAclEditFormValues['value'],
): Pick<
  AgentCustomersLocationAclUpsertMutationVariables,
  'canApproveOrders' | 'canPlaceOrders' | 'canSendOrderMessages' | 'canViewOrders'
> {
  switch (value) {
    case 'view_orders':
      return {
        canViewOrders: true,
        canSendOrderMessages: false,
        canPlaceOrders: false,
        canApproveOrders: false,
      };
    case 'send_order_messages':
      return {
        canViewOrders: true,
        canSendOrderMessages: true,
        canPlaceOrders: false,
        canApproveOrders: false,
      };
    case 'place_orders':
      return {
        canViewOrders: true,
        canSendOrderMessages: true,
        canPlaceOrders: true,
        canApproveOrders: false,
      };
    case 'approve_orders':
      return {
        canViewOrders: true,
        canSendOrderMessages: true,
        canPlaceOrders: true,
        canApproveOrders: true,
      };
  }
}

function toValue(
  acl: Pick<
    TAgentCustomersLocationAclBaseFragment,
    'can_approve_orders' | 'can_place_orders' | 'can_send_order_messages' | 'can_view_orders'
  >,
): TLocationAclEditFormValues['value'] {
  if (acl.can_approve_orders) {
    return 'approve_orders';
  } else if (acl.can_place_orders) {
    return 'place_orders';
  } else if (acl.can_send_order_messages) {
    return 'send_order_messages';
  } else if (acl.can_view_orders) {
    return 'view_orders';
  } else {
    throw new Error(`Unexpected ACL: "${JSON.stringify(acl)}".`);
  }
}
