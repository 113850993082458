import { Typography } from '@src/components/appearance/basics/Typography';
import { Button, getButtonVariant } from '@src/components/appearance/controls/Button';
import { Card } from '@src/components/appearance/fragments/Card';
import { ensureNotEmptyString, isDef } from '@src/gen/shared/utils/types';
import { withCssToString } from '@src/logic/internal/utils/utils';
import { styled } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import type { FormApi, Mutator, SubmissionErrors, ValidationErrors } from 'final-form';
import { FORM_ERROR } from 'final-form';
import isEqual from 'lodash/isEqual';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { Form as RFForm, useForm, useFormState } from 'react-final-form';

export type TFormValuesBase = { [key: string]: unknown };

export type TFormMutators<V extends TFormValuesBase> = { [key: string]: Mutator<V, V> };
export type TFormSubmit<V extends TFormValuesBase> = (values: V, form: FormApi<V, V>) => Promise<SubmissionErrors>;
export type TFormValidate<V extends TFormValuesBase> = (values: V) => Promise<ValidationErrors> | ValidationErrors;

const SFooterDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$cardGap',
});

export type TFormBase<V extends TFormValuesBase> = {
  initialValues: V;
  onSubmit: TFormSubmit<V>;
  requireChanges?: boolean | undefined;
  submitButtonText: string;
  validate?: TFormValidate<V> | undefined;
};

export type TForm<V extends TFormValuesBase> = PropsWithChildren<TFormBase<V>>;
export const FORM_CLASS_NAME = 'wp-form';

export const Form = withCssToString(
  FORM_CLASS_NAME,
  <V extends TFormValuesBase>({
    initialValues,
    onSubmit,
    requireChanges,
    submitButtonText,
    validate,
    children,
  }: TForm<V>): JSX.Element => {
    const { doErrorNotify } = useErrors();

    const mutators = useMemo<TFormMutators<V>>(
      () => ({
        setFieldTouched: function ([name, touched]: [string, boolean], state): void {
          const field = state.fields[name];

          if (isDef(field)) {
            field.touched = touched;
          }
        },
      }),
      [],
    );

    const handleSubmit = useCallback<TFormSubmit<V>>(
      async (values, form) => {
        try {
          await onSubmit(values, form);
          form.restart();
          return undefined;
        } catch (thrown: unknown) {
          const errorSummary = getErrorSummary(thrown, { forceDisplayMode: EErrorSummaryDisplayMode.SILENT });
          doErrorNotify(errorSummary);

          return {
            [FORM_ERROR]: errorSummary.display.message,
          };
        }
      },
      [doErrorNotify, onSubmit],
    );

    return (
      <RFForm<V, V>
        className={FORM_CLASS_NAME}
        initialValues={initialValues}
        mutators={mutators}
        onSubmit={handleSubmit}
        {...(validate !== undefined ? { validate } : {})}>
        {({ submitErrors }): JSX.Element => {
          return (
            <Card.Container flush={true} variant='form'>
              {children}
              <Card.Separator />
              <SFooterDiv>
                <Typography.ErrorCaption
                  error={isDef(submitErrors?.[FORM_ERROR]) ? ensureNotEmptyString(submitErrors?.[FORM_ERROR]) : null}
                />
                <SubmitButton requireChanges={requireChanges} text={submitButtonText} />
              </SFooterDiv>
            </Card.Container>
          );
        }}
      </RFForm>
    );
  },
);

const SSubmitButton = styled(Button, {
  alignSelf: 'flex-start',
  minWidth: '$formSubmitButtonMinWidth',
});

function SubmitButton<V extends TFormValuesBase>({
  requireChanges,
  text,
}: {
  requireChanges?: boolean | undefined;
  text: string;
}): JSX.Element {
  const { hasValidationErrors, initialValues, submitting, values } = useFormState<V, V>({
    subscription: {
      hasValidationErrors: true,
      initialValues: true,
      submitting: true,
      values: true,
    },
  });

  const { submit } = useForm();
  const disabled = hasValidationErrors || (requireChanges === true && isEqual(initialValues, values));
  const handleClick = useCallback(() => void submit(), [submit]);

  return (
    <SSubmitButton
      variant={getButtonVariant({ base: 'default', disabled, loading: submitting })}
      onClick={handleClick}
      text={text}
    />
  );
}
