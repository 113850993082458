import { useAgentGetTrackerResultQuery } from '@src/gen/graphql/bindings';
import type { TTrackerResult } from '@src/gen/shared/data/tracking';
import { castTrackingUnsafe } from '@src/gen/shared/data/tracking';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';

import type { ApolloError } from '@apollo/client';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TAgentGetTrackerResultContext = {
  trackerResult?: TTrackerResult | undefined;
  error?: ApolloError | undefined;
};

export const { Context: AgentGetTrackerResultContext, useContext: useAgentGetTrackerResult } =
  createRequiredContext<TAgentGetTrackerResultContext>();

export type TAgentGetTrackerResultProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  ship24TrackerId: string;
}>;

export function AgentGetTrackerResultProvider({
  LoaderComponent,
  ship24TrackerId,
  children,
}: TAgentGetTrackerResultProvider): JSX.Element {
  const { data, error } = useAgentGetTrackerResultQuery({
    variables: {
      ship24TrackerId,
    },
  });

  const value = useMemo<TAgentGetTrackerResultContext | undefined>(
    () =>
      isDef(data) && isDef(data.getTrackerResult)
        ? {
            trackerResult: castTrackingUnsafe(data.getTrackerResult.trackerResult),
          }
        : isDef(error)
        ? {
            error,
          }
        : undefined,
    [error, data],
  );

  return isDef(value) ? (
    <AgentGetTrackerResultContext.Provider value={value}>{children}</AgentGetTrackerResultContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
