import type { AgentUserSelfQuery, CustomerUserSelfQuery } from '@src/gen/graphql/bindings';
import { EErrorClientCode } from '@src/gen/shared/enums/errorClientCode';
import { maybeGetSourceName } from '@src/gen/shared/enums/source';
import type { TArrayElement } from '@src/gen/shared/utils/types';
import { ensureDef, ensureNotEmptyString, isDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import { GraphQLError } from 'graphql/error';

export type TAuthSubject = TAuthAgent | TAuthCustomer;

export type TAuthBaseSubject = {
  id: string;
  email: string;
  phone_number: string | null;
  first_name: string;
  last_name: string;
  agent_can_edit_organizations_and_locations: boolean;
  agent_can_edit_organization_acls_and_location_acls: boolean;
  agent_can_create_users: boolean;
  agent_can_edit_users: boolean;
  agent_can_create_organizations_and_locations: boolean;
  agent_can_create_sandbox: boolean;
};

export type TAuthAgent = TAuthBaseSubject & {
  is_wellplaece_agent: true;
};

export type TAuthCustomer = TAuthBaseSubject & {
  is_wellplaece_agent: false;
  organizations: TAuthOrganization[];
};

export type TAuthOrganization = {
  id: string;
  name: string;
  canPlaceEmergencyOrders: boolean;
  isSandbox: boolean;
  sources: string[];
  acl: TAuthOrganizationAcl;
  locations: TAuthLocation[];
  allLocations: TAuthAllLocationsLocation[];
};

export type TAuthOrganizationAcl = {
  can_view_analytics: boolean;
};

export type TAuthLocation = {
  id: string;
  name: string;
  order_code: string;
  acl: TAuthLocationAcl;
};

export type TAuthLocationAcl = {
  can_view_orders: boolean;
  can_send_order_messages: boolean;
  can_place_orders: boolean;
  can_approve_orders: boolean;
  can_view_analytics: boolean;
};

export type TAuthAllLocationsLocation = {
  id: string;
  name: string;
};

export function parseAuthSubject(
  userSelf: TArrayElement<AgentUserSelfQuery['user_self']> | TArrayElement<CustomerUserSelfQuery['user_self']>,
): TAuthSubject {
  const baseSubject: TAuthBaseSubject = {
    id: ensureNotEmptyString(userSelf.id),
    email: ensureNotEmptyString(userSelf.email),
    phone_number: userSelf.phone_number !== '' ? userSelf.phone_number : null,
    first_name: ensureNotEmptyString(userSelf.first_name),
    last_name: ensureNotEmptyString(userSelf.last_name),
    agent_can_edit_organizations_and_locations: ensureDef(userSelf.agent_can_edit_organizations_and_locations),
    agent_can_edit_organization_acls_and_location_acls: ensureDef(
      userSelf.agent_can_edit_organization_acls_and_location_acls,
    ),
    agent_can_create_users: ensureDef(userSelf.agent_can_create_users),
    agent_can_edit_users: ensureDef(userSelf.agent_can_edit_users),
    agent_can_create_organizations_and_locations: ensureDef(userSelf.agent_can_create_organizations_and_locations),
    agent_can_create_sandbox: ensureDef(userSelf.agent_can_create_sandbox),
  };

  if (userSelf.is_wellplaece_agent === true) {
    return {
      ...baseSubject,
      is_wellplaece_agent: true,
    };
  }

  assert('organization_acls' in userSelf);
  assert('location_acls' in userSelf);

  const locAcls = userSelf.location_acls;

  if (userSelf.organization_acls.length === 0) {
    throw new GraphQLError('User unauthorized.', {
      extensions: {
        code: EErrorClientCode.UNAUTHORIZED_USER,
      },
    });
  }

  const orgAclIds = userSelf.organization_acls.reduce<{ [key: string]: true }>(
    (out, orgAcl) => ({ ...out, [orgAcl.organization_id]: true }),
    {},
  );

  assert(!isDef(locAcls.find((locAcl) => orgAclIds[locAcl.location.organization_id] !== true)));
  userSelf.organization_acls.sort((a, b) => a.organization.name.localeCompare(b.organization.name));

  return {
    ...baseSubject,
    is_wellplaece_agent: false,
    organizations: userSelf.organization_acls.map(
      (org): TAuthOrganization => ({
        id: org.organization.id,
        name: org.organization.name,
        canPlaceEmergencyOrders: org.organization.can_place_emergency_orders,
        isSandbox: org.organization.is_sandbox,
        sources: org.organization.organization_sources
          .map((os) => os.source)
          .sort((a, b) => maybeGetSourceName(a).localeCompare(maybeGetSourceName(b))),
        acl: {
          can_view_analytics: org.can_view_analytics,
        },
        locations: locAcls
          .filter((loc) => loc.location.organization_id === org.organization_id)
          .map(
            (locAcl): TAuthLocation => ({
              id: locAcl.location.id,
              name: locAcl.location.name,
              order_code: locAcl.location.order_code,
              acl: {
                can_view_orders: locAcl.can_view_orders,
                can_send_order_messages: locAcl.can_send_order_messages,
                can_place_orders: locAcl.can_place_orders,
                can_approve_orders: locAcl.can_approve_orders,
                can_view_analytics: locAcl.can_view_analytics,
              },
            }),
          ),
        allLocations: org.organization.all_locations.sort((a, b) => a.name.localeCompare(b.name)),
      }),
    ),
  };
}
