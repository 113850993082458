import { Typography } from '@src/components/appearance/basics/Typography';
import type { TDialog } from '@src/components/appearance/structure/Dialog';
import { Dialog } from '@src/components/appearance/structure/Dialog';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderCancelDialogPanelBase = {
  doOrderCancelAsync: TAgentOrderActionsTypes['DoOrderCancelAsync'];
};

export type TOrderCancelDialogPanel = TProps<false, TOrderCancelDialogPanelBase, 'div'>;
export const ORDER_CANCEL_DIALOG_PANEL_CLASS_NAME = 'wp-order-cancel-dialog-panel';

export const OrderCancelDialogPanel = withCssToString(
  ORDER_CANCEL_DIALOG_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderCancelDialogPanel>(
      ({ doOrderCancelAsync, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_CANCEL_DIALOG_PANEL_CLASS_NAME),
          [className],
        );

        const buttonAction = useMemo<TDialog['FooterBase']['buttonAction']>(
          () => ({
            isAsync: true,
            text: 'Cancel Order',
            onClick: doOrderCancelAsync,
          }),
          [doOrderCancelAsync],
        );

        return (
          <Dialog.Panel {...rest} className={joinedClassName} ref={ref}>
            <Dialog.Header title='Cancel Order' />
            <Typography.Small text='Canceling an order is an irreversible operation. After canceling, it will no longer be possible to edit the order or to send messages.' />
            <Dialog.Footer buttonAction={buttonAction} />
          </Dialog.Panel>
        );
      },
    ),
  ),
);
