import type {
  CustomerNotificationClearMutationVariables,
  CustomerNotificationsQuery,
  CustomerNotificationsQueryVariables,
} from '@src/gen/graphql/bindings';
import { useCustomerNotificationClearMutation } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useCustomerNotifications } from '@src/modules/data/customer/notifications/CustomerNotificationsProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TCustomerNotificationsActionsTypes = {
  DoNotificationClearOptNotify: (args: CustomerNotificationClearMutationVariables) => void;
};

export type TCustomerNotificationsActionsContext = {
  doNotificationClearOptNotify: TCustomerNotificationsActionsTypes['DoNotificationClearOptNotify'];
};

export const { Context: CustomerNotificationsActionsContext, useContext: useCustomerNotificationsActions } =
  createRequiredContext<TCustomerNotificationsActionsContext>();

export function CustomerNotificationsActionsProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const doNotificationClearOptNotify = useDoNotificationClearOptNotify();

  const value = useMemo<TCustomerNotificationsActionsContext>(
    () => ({
      doNotificationClearOptNotify,
    }),
    // @sort
    [doNotificationClearOptNotify],
  );

  return (
    <CustomerNotificationsActionsContext.Provider value={value}>
      {children}
    </CustomerNotificationsActionsContext.Provider>
  );
}

function useDoNotificationClearOptNotify(): TCustomerNotificationsActionsTypes['DoNotificationClearOptNotify'] {
  const [notificationClearMutation] = useCustomerNotificationClearMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { queryRef, serializationKey } = useCustomerNotifications();

  const doNotificationClearOptNotifyAsync = useCallback(
    async (args: CustomerNotificationClearMutationVariables): Promise<void> => {
      try {
        doErrorClear();

        const { errors } = await notificationClearMutation({
          variables: args,
          context: {
            serializationKey,
          },
          update: (cache, { data: newData }) => {
            const notificationClear = ensureDef(newData?.notification_clear);

            cache.updateQuery<CustomerNotificationsQuery, CustomerNotificationsQueryVariables>(queryRef, (data) => {
              return {
                notifications: ensureDef(
                  data?.notifications.map((n) =>
                    n.id === newData?.notification_clear?.id
                      ? {
                          ...n,
                          cleared_at: notificationClear.cleared_at,
                        }
                      : n,
                  ),
                ),
              };
            });
          },
          optimisticResponse: () => ({
            notification_clear: {
              __typename: 'notifications',
              id: args.notificationId,
              cleared_at: new Date().toISOString(),
            },
          }),
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While clearing a notification.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, notificationClearMutation, queryRef, serializationKey],
  );

  return useCallback<TCustomerNotificationsActionsTypes['DoNotificationClearOptNotify']>(
    async (args) => void doNotificationClearOptNotifyAsync(args),
    [doNotificationClearOptNotifyAsync],
  );
}
