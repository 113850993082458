import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EShip24StatusMilestone {
  PENDING = 'pending',
  INFO_RECEIVED = 'info_received',
  IN_TRANSIT = 'in_transit',
  OUT_FOR_DELIVERY = 'out_for_delivery',
  FAILED_ATTEMPT = 'failed_attempt',
  AVAILABLE_FOR_PICKUP = 'available_for_pickup',
  DELIVERED = 'delivered',
  EXCEPTION = 'exception',
}

const SHIP24_STATUS_MILESTONE_LOOKUP = mapValues(invert(EShip24StatusMilestone), () => true);

export function getShip24StatusMilestone(rawShip24StatusMilestone: string): EShip24StatusMilestone {
  assert(
    SHIP24_STATUS_MILESTONE_LOOKUP[rawShip24StatusMilestone],
    `Unknown ship24 status milestone: "${rawShip24StatusMilestone}".`,
  );
  return rawShip24StatusMilestone as EShip24StatusMilestone; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetShip24StatusMilestone(rawShip24StatusMilestone: string): EShip24StatusMilestone | undefined {
  try {
    return getShip24StatusMilestone(rawShip24StatusMilestone);
  } catch {
    return undefined;
  }
}

const SHIP24_STATUS_MILESTONE_LABELS: { [key in EShip24StatusMilestone]: string } = {
  [EShip24StatusMilestone.PENDING]: 'Pending',
  [EShip24StatusMilestone.INFO_RECEIVED]: 'Info Received',
  [EShip24StatusMilestone.IN_TRANSIT]: 'In Transit',
  [EShip24StatusMilestone.OUT_FOR_DELIVERY]: 'Out for Delivery',
  [EShip24StatusMilestone.FAILED_ATTEMPT]: 'Failed Attempt',
  [EShip24StatusMilestone.AVAILABLE_FOR_PICKUP]: 'Available for Pickup',
  [EShip24StatusMilestone.DELIVERED]: 'Delivered',
  [EShip24StatusMilestone.EXCEPTION]: 'Exception',
};

export function maybeGetShip24StatusMilestoneLabel(rawShip24StatusMilestoneLabel: string): string {
  const ship24StatusMilestone = maybeGetShip24StatusMilestone(rawShip24StatusMilestoneLabel);
  return ship24StatusMilestone !== undefined
    ? ensureDef(SHIP24_STATUS_MILESTONE_LABELS[ship24StatusMilestone])
    : rawShip24StatusMilestoneLabel;
}
