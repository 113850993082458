import { Structure } from '@src/components/appearance/structure/Structure';
import { NextOrderResultsEmptyAnnouncement } from '@src/components/mixins/announcements/NextOrderResultsEmptyAnnouncement';
import { NextOrderProductCardLoader } from '@src/components/mixins/cards/NextOrderProductCard';
import { CustomerNextOrderFormularyFiltersPart } from '@src/components/parts/customer/nextOrder/CustomerNextOrderFormularyFiltersPart';
import {
  CustomerNextOrderFormularyNavPart,
  CustomerNextOrderFormularyNavPartLoader,
} from '@src/components/parts/customer/nextOrder/CustomerNextOrderFormularyNavPart';
import { CustomerNextOrderProductCardPart } from '@src/components/parts/customer/nextOrder/CustomerNextOrderProductCardPart';
import { isDef } from '@src/gen/shared/utils/types';
import { useCustomerNextOrderSearch } from '@src/modules/data/customer/nextOrder/CustomerNextOrderSearchProvider';
import { Fragment } from 'react';

export function CustomerNextOrderFormularyPart(): JSX.Element {
  const { searchResponse, loaderRef } = useCustomerNextOrderSearch();

  const hasAggrs =
    isDef(searchResponse.sourceAggregation) ||
    isDef(searchResponse.categoryName01Aggregation) ||
    isDef(searchResponse.categoryName02Aggregation) ||
    isDef(searchResponse.manufacturerNameAggregation);

  if (!hasAggrs) {
    return (
      <Fragment>
        <Structure.Content>
          <CustomerNextOrderFormularyNavPart />
        </Structure.Content>
        <Structure.ScrollContent>
          {searchResponse.results.length === 0 ? (
            <NextOrderResultsEmptyAnnouncement />
          ) : (
            <Structure.Grid>
              {searchResponse.results.map((result) => (
                <CustomerNextOrderProductCardPart
                  key={result.legacyProduct.id}
                  product={result.legacyProduct}
                  eventLocation='formulary'
                />
              ))}
              {isDef(loaderRef) && <NextOrderProductCardLoader hasQuantity={true} ref={loaderRef} />}
            </Structure.Grid>
          )}
        </Structure.ScrollContent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Structure.Content>
        <CustomerNextOrderFormularyNavPart />
      </Structure.Content>
      <Structure.ScrollContent>
        <Structure.Columns sideChildren={<CustomerNextOrderFormularyFiltersPart />}>
          <Structure.Grid>
            {searchResponse.results.length === 0 && <NextOrderResultsEmptyAnnouncement />}
            {searchResponse.results.map((result) => (
              <CustomerNextOrderProductCardPart
                key={result.legacyProduct.id}
                product={result.legacyProduct}
                eventLocation='formulary'
              />
            ))}
            {isDef(loaderRef) && <NextOrderProductCardLoader hasQuantity={true} ref={loaderRef} />}
          </Structure.Grid>
        </Structure.Columns>
      </Structure.ScrollContent>
    </Fragment>
  );
}

export function CustomerNextOrderFormularyPartLoader(): JSX.Element {
  return (
    <Fragment>
      <Structure.Content>
        <CustomerNextOrderFormularyNavPartLoader />
      </Structure.Content>
      <Structure.ScrollContentLoader>
        <Structure.Grid>
          <NextOrderProductCardLoader hasQuantity={true} />
          <NextOrderProductCardLoader hasQuantity={true} />
          <NextOrderProductCardLoader hasQuantity={true} />
          <NextOrderProductCardLoader hasQuantity={true} />
          <NextOrderProductCardLoader hasQuantity={true} />
          <NextOrderProductCardLoader hasQuantity={true} />
          <NextOrderProductCardLoader hasQuantity={true} />
          <NextOrderProductCardLoader hasQuantity={true} />
          <NextOrderProductCardLoader hasQuantity={true} />
          <NextOrderProductCardLoader hasQuantity={true} />
        </Structure.Grid>
      </Structure.ScrollContentLoader>
    </Fragment>
  );
}
