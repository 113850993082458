import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import type { TSearchBase, TSearchBaseChip } from '@src/components/appearance/controls/Search';
import { Search } from '@src/components/appearance/controls/Search';
import { Select } from '@src/components/appearance/controls/Select';
import { Chrome } from '@src/components/appearance/structure/Chrome';
import { ProductComparePickerProductCardLoader } from '@src/components/mixins/cards/ProductComparePickerProductCard';
import type { TProductPickerChrome } from '@src/components/mixins/chromes/ProductPickerChrome';
import type { TAgentOrderEntryBaseFragment, TAgentPublicCatalogProductBaseFragment } from '@src/gen/graphql/bindings';
import { getCombinedProductName } from '@src/gen/shared/data/snippets';
import { getSourceCode, getSourceName } from '@src/gen/shared/enums/source';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import type { TAgentProductsSearchConfig } from '@src/modules/data/agent/global/operations/AgentProductsSearchProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';

export type TProductComparePickerChrome = {
  FooterBase: {
    beginOrderEntryBaseProductView: () => void;
    beginOrderEntryPlanProductView: () => void;
    onSearch: (searchQuery: string) => void;
    orderEntry: TAgentOrderEntryBaseFragment;
    pinnedProducts: TAgentPublicCatalogProductBaseFragment[];
    setViewingProductId: (publicCatalogProductId: string) => void;
  };
  SearchAndFiltersBase: {
    showPreferredCatalogsOption: boolean;
    config: Omit<TAgentProductsSearchConfig, 'allowableSources'> & { sourceMode: 'all' | 'preferred' };
    setConfig: Dispatch<
      SetStateAction<Omit<TAgentProductsSearchConfig, 'allowableSources'> & { sourceMode: 'all' | 'preferred' }>
    >;
  };
  Footer: TProductComparePickerChrome['FooterBase'];
  Panel: PropsWithChildren<TEmptyObject>;
  SearchAndFilters: TProductComparePickerChrome['SearchAndFiltersBase'];
};

function Panel({ children }: TProductComparePickerChrome['Panel']): JSX.Element {
  return (
    <Chrome.Panel>
      <Chrome.Header title='Select Product' />
      {children}
    </Chrome.Panel>
  );
}

const SSearchAndFiltersDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '$cardGap',
  paddingTop: '4px',
  paddingBottom: '4px',
});

function SearchAndFilters({
  showPreferredCatalogsOption,
  config,
  setConfig,
}: TProductPickerChrome['SearchAndFilters']): JSX.Element {
  const handleSearch = useCallback<TSearchBase['onSearch']>(
    (query) =>
      setConfig((oldConfig) => ({
        ...oldConfig,
        query,
      })),
    // @sort
    [setConfig],
  );

  const chips = useMemo<TSearchBaseChip[]>(() => {
    const res: TSearchBaseChip[] = [];

    if (isDef(config.sourceFilter)) {
      res.push({
        label: `Catalog: ${getSourceName(config.sourceFilter)}`,
        onClose: () =>
          setConfig((oldConfig) => ({
            ...oldConfig,
            sourceFilter: null,
            categoryName01Filter: null,
            categoryName02Filter: null,
            manufacturerNameFilter: null,
          })),
      });
    }

    if (isDef(config.categoryName01Filter)) {
      res.push({
        label: `Category: ${config.categoryName01Filter}`,
        onClose: () =>
          setConfig((oldConfig) => ({
            ...oldConfig,
            categoryName01Filter: null,
            categoryName02Filter: null,
          })),
      });
    }

    if (isDef(config.categoryName02Filter)) {
      res.push({
        label: `Sub-Category: ${config.categoryName02Filter}`,
        onClose: () =>
          setConfig((oldConfig) => ({
            ...oldConfig,
            categoryName02Filter: null,
          })),
      });
    }

    if (isDef(config.manufacturerNameFilter)) {
      res.push({
        label: `Manufacturer: ${config.manufacturerNameFilter}`,
        onClose: () =>
          setConfig((oldConfig) => ({
            ...oldConfig,
            manufacturerNameFilter: null,
          })),
      });
    }

    return res;
  }, [
    config.categoryName01Filter,
    config.categoryName02Filter,
    config.manufacturerNameFilter,
    config.sourceFilter,
    setConfig,
  ]);

  return (
    <Chrome.SubHeader>
      <SSearchAndFiltersDiv>
        {showPreferredCatalogsOption && (
          <Select
            onChange={(e): void => {
              switch (e.target.value) {
                case 'all':
                  setConfig((oldConfig) => ({
                    ...oldConfig,
                    sourceMode: 'all',
                    sourceFilter: null,
                  }));
                  break;
                case 'preferred':
                  setConfig((oldConfig) => ({
                    ...oldConfig,
                    sourceMode: 'preferred',
                    sourceFilter: null,
                  }));
              }
            }}
            value={config.sourceMode}>
            <option value='all'>All Catalogs</option>
            <option value='preferred'>Preferred Catalogs</option>
          </Select>
        )}
        <Search css={{ flexGrow: 1 }} searchQuery={config.query} onSearch={handleSearch} chips={chips} />
      </SSearchAndFiltersDiv>
    </Chrome.SubHeader>
  );
}

const SGridDiv = styled('div', {
  display: 'grid',
  gap: '$structureGridInnerGapMobile',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',

  '@desktop': {
    gap: '$structureGridInnerGapDesktop',
  },
});

function Grid({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  return (
    <Chrome.ScrollContent>
      <SGridDiv>{children}</SGridDiv>
    </Chrome.ScrollContent>
  );
}

const SFooterDiv = styled('div', {
  alignItems: 'center',
  backgroundColor: '$chromeFooterBackground',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  flexWrap: 'wrap',
  gap: '$cardGap',
  marginBottom: '$chromePanelPaddingMobile',
  marginX: '$chromePanelPaddingMobile',
  padding: '$chromeFooterPadding',

  '@desktop': {
    marginBottom: '$chromePanelPaddingDesktop',
    marginX: '$chromePanelPaddingDesktop',
    padding: '$chromeFooterPadding',
  },
});

const GridLoader = (): JSX.Element => {
  return (
    <Chrome.ScrollContentLoader>
      <SGridDiv>
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
        <ProductComparePickerProductCardLoader />
      </SGridDiv>
    </Chrome.ScrollContentLoader>
  );
};

function Footer({
  setViewingProductId,
  beginOrderEntryBaseProductView,
  beginOrderEntryPlanProductView,
  onSearch,
  orderEntry,
  pinnedProducts,
}: TProductComparePickerChrome['Footer']): JSX.Element {
  return (
    <SFooterDiv>
      <ControlButton
        icon='view'
        onClick={beginOrderEntryBaseProductView}
        text={`Base ${getSourceCode(orderEntry.source)}-${orderEntry.product_sku}`}
      />
      {isDef(orderEntry.plan_product_id) && (
        <ControlButton
          icon='view'
          onClick={beginOrderEntryPlanProductView}
          text={`Plan ${getSourceCode(ensureDef(orderEntry.plan_source))}-${ensureDef(orderEntry.plan_product_sku)}`}
        />
      )}
      {isDef(orderEntry.manufacturer_sku) && (
        <ControlButton
          icon='search'
          onClick={(): void => onSearch(ensureDef(orderEntry.manufacturer_sku))}
          text={'Base Mfg. SKU'}
        />
      )}
      <ControlButton
        icon='search'
        onClick={(): void => onSearch(getCombinedProductName(orderEntry.name, orderEntry.secondary_name))}
        text={'Base Name'}
      />

      {pinnedProducts.map((pp) => (
        <ControlButton
          icon='view'
          onClick={(): void => setViewingProductId(pp.id)}
          text={`Pin ${getSourceCode(pp.source)}-${pp.product_sku}`}
        />
      ))}
    </SFooterDiv>
  );
}

export const ProductComparePickerChrome = {
  Footer,
  Grid,
  GridLoader,
  Panel,
  SearchAndFilters,
};
