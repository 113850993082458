import type {
  AgentNotificationClearMutationVariables,
  AgentNotificationsQuery,
  AgentNotificationsQueryVariables,
} from '@src/gen/graphql/bindings';
import { useAgentNotificationClearMutation } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useAgentNotifications } from '@src/modules/data/agent/notifications/AgentNotificationsProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TAgentNotificationsActionsTypes = {
  DoNotificationClearOptNotify: (args: AgentNotificationClearMutationVariables) => void;
};

export type TAgentNotificationsActionsContext = {
  doNotificationClearOptNotify: TAgentNotificationsActionsTypes['DoNotificationClearOptNotify'];
};

export const { Context: AgentNotificationsActionsContext, useContext: useAgentNotificationsActions } =
  createRequiredContext<TAgentNotificationsActionsContext>();

export function AgentNotificationsActionsProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const doNotificationClearOptNotify = useDoNotificationClearOptNotify();

  const value = useMemo<TAgentNotificationsActionsContext>(
    () => ({
      doNotificationClearOptNotify,
    }),
    // @sort
    [doNotificationClearOptNotify],
  );

  return (
    <AgentNotificationsActionsContext.Provider value={value}>{children}</AgentNotificationsActionsContext.Provider>
  );
}

function useDoNotificationClearOptNotify(): TAgentNotificationsActionsTypes['DoNotificationClearOptNotify'] {
  const [notificationClearMutation] = useAgentNotificationClearMutation();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { queryRef, serializationKey } = useAgentNotifications();

  const doNotificationClearOptNotifyAsync = useCallback(
    async (args: AgentNotificationClearMutationVariables): Promise<void> => {
      try {
        doErrorClear();

        const { errors } = await notificationClearMutation({
          variables: args,
          context: {
            serializationKey,
          },
          update: (cache, { data: newData }) => {
            const notificationClear = ensureDef(newData?.notification_clear);

            cache.updateQuery<AgentNotificationsQuery, AgentNotificationsQueryVariables>(queryRef, (data) => {
              return {
                notifications: ensureDef(
                  data?.notifications.map((n) =>
                    n.id === newData?.notification_clear?.id
                      ? {
                          ...n,
                          cleared_at: notificationClear.cleared_at,
                        }
                      : n,
                  ),
                ),
              };
            });
          },
          optimisticResponse: () => ({
            notification_clear: {
              __typename: 'notifications',
              id: args.notificationId,
              cleared_at: new Date().toISOString(),
            },
          }),
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While clearing a notification.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, notificationClearMutation, queryRef, serializationKey],
  );

  return useCallback<TAgentNotificationsActionsTypes['DoNotificationClearOptNotify']>(
    (args) => void doNotificationClearOptNotifyAsync(args),
    [doNotificationClearOptNotifyAsync],
  );
}
