import {
  ensureDef,
  ensureNotEmptyString,
  ensureSafeIntegerNumber,
  ensureString,
  isDef,
} from '@src/gen/shared/utils/types';
import assert from 'assert';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';

export function ensureStringToNumber(value: unknown): number {
  const s = ensureNotEmptyString(value);
  assert(/^-?\d*$/.test(s), `Not a number: "${s}".`);
  const n = parseInt(s, 10);
  assert(!Number.isNaN(n) && Number.isFinite(n), `Not a number: "${s}".`);
  return n;
}

export function ensureStringToDate(value: unknown, formatString: string): Date {
  const s = ensureNotEmptyString(value);
  const n = parse(s, formatString, new Date(0, 0, 0, 0, 0, 0, 0));
  assert(!isNaN(n.valueOf()), 'Invalid date.');
  return n;
}

export function ensureStringToDollarsCurrency(
  value: string,
  {
    hasDollarSign,
    allowParenthesisForNegativeValue,
  }: {
    hasDollarSign: boolean;
    allowParenthesisForNegativeValue?: boolean | undefined;
  },
): number {
  let s = ensureNotEmptyString(value);
  let sign = 1;

  if (allowParenthesisForNegativeValue === true) {
    if (s.startsWith('(') && s.endsWith(')')) {
      s = s.substring(1, s.length - 1);
      sign = -1;
    }
  }

  assert(hasDollarSign === s.startsWith('$'), `Invalid value: "${s}".`);
  assert(/^\$?(0|([1-9][\d,]*))(\.\d{1,2})?$/.test(s), `Invalid value: "${s}".`);

  if (hasDollarSign) {
    s = s.substring(1);
  }

  const parts = s.split('.');
  let n = ensureStringToNumber(`${ensureNotEmptyString(parts[0]).replaceAll(',', '')}00`);

  if (parts.length === 2) {
    let strCents = ensureNotEmptyString(parts[1]);
    if (strCents.length === 1) {
      strCents = `${strCents}0`;
    }

    n = n + ensureStringToNumber(strCents);
  }

  return n === 0 || sign > 0 ? n : -n;
}

export function ensureStringToPercent(value: string): number {
  const matches = value.match(/^(0|[1-9]\d*)\.(\d{2})%$/);
  assert(isDef(matches), `Invalid value: "${value}".`);

  let cStr = ensureString(matches[2]).replaceAll(/^0+/g, '');
  if (cStr === '') {
    cStr = '0';
  }

  return ensureSafeIntegerNumber(
    ensureSafeIntegerNumber(ensureStringToNumber(matches[1])) * 100 +
      ensureSafeIntegerNumber(ensureStringToNumber(cStr)),
  );
}

export function ensureStringToDollarsCurrencyEditable(value: string): number {
  if (value === '') {
    return 0;
  }

  let s = ensureNotEmptyString(value);
  assert(/^(0|([1-9]\d*))?(\.\d{0,2})?$/.test(s), `Invalid value: "${s}".`);
  const parts = s.split('.');

  let left = ensureDef(parts[0]);
  if (left === '') {
    left = '0';
  }

  let n = ensureStringToNumber(`${left}00`);

  if (parts.length === 2) {
    let right = ensureDef(parts[1]);
    if (right === '') {
      right = '0';
    } else if (right.length === 1) {
      right = `${right}0`;
    }

    n = n + ensureStringToNumber(right);
  }

  return n;
}

export function formatDollarsCurrency(value: number): string {
  ensureSafeIntegerNumber(value);
  const sign = value >= 0 ? '' : '- ';

  if (value < 0) {
    value = -value;
  }

  return `${sign}$${`${Math.floor(value / 100)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${`${value % 100}`.padStart(
    2,
    '0',
  )}`;
}

export function maybeFormatDollarsCurrency(value: number | null | undefined): string | null {
  return isDef(value) ? formatDollarsCurrency(value) : null;
}

export function formatDollarsCurrencyEditable(value: number): string {
  ensureSafeIntegerNumber(value);
  return `${Math.floor(value / 100)}.${`${value % 100}`.padStart(2, '0')}`;
}

export function formatPercent(value: number): string {
  ensureSafeIntegerNumber(value);
  const sign = value >= 0 ? '' : '- ';

  if (value < 0) {
    value = -value;
  }

  return `${sign}${`${Math.floor(value / 100)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${`${value % 100}`.padStart(
    2,
    '0',
  )}%`;
}

export type TDatePickerDate = {
  year: number;
  month: number;
  day: number;
};

export function mustFormatDatePickerDate(v: TDatePickerDate): string {
  assert(v.day >= 1 && v.day <= 31 && v.month >= 1 && v.month <= 12 && v.year >= 0, 'invalid date');
  const dateStr = `${`${v.year}`.padStart(4, '0')}-${`${v.month}`.padStart(2, '0')}-${`${v.day}`.padStart(2, '0')}`;
  const pv = mustParseDatePickerDate(dateStr);
  assert(pv.day === v.day && pv.month === v.month && pv.year === v.year, 'invalid date');
  return dateStr;
}

export function mustParseDatePickerDate(dateStr: string): TDatePickerDate {
  assert(/^\d{4}-\d{2}-\d{2}$/.test(dateStr), `invalid date: ${dateStr}`);
  const date = parse(dateStr, 'yyyy-MM-dd', new Date());
  assert(isValid(date), `invalid date: ${dateStr}`);

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}
