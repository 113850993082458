import { track as amplitudeTrack } from '@amplitude/analytics-browser';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import omit from 'lodash/omit';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export enum EAmplitudeAnalyticsEventId {
  ADD_TO_CART = 'add_to_cart',
  SEARCH_PRODUCTS = 'search_products',
  SELECT_CATEGORY = 'select_category',
  SELECT_SIMPLIFIED_CATEGORY = 'select_simplified_category',
}

export type TAmplitudeAnalyticsEvent =
  | TAmplitudeAnalyticsEventAddToCart
  | TAmplitudeAnalyticsEventSearchProducts
  | TAmplitudeAnalyticsEventSelectCategory
  | TAmplitudeAnalyticsEventSelectSimplifiedCategory;

export type TAmplitudeAnalyticsEventAddToCart = {
  id: EAmplitudeAnalyticsEventId.ADD_TO_CART;
  productId: string;
  productSku: string;
  productName: string;
  isProductInFormulary: boolean;
  eventLocation: 'cart' | 'drawer' | 'history' | 'formulary' | 'catalogs';
  organizationId: string;
  locationId: string;
};

export type TAmplitudeAnalyticsEventSearchProducts = {
  id: EAmplitudeAnalyticsEventId.SEARCH_PRODUCTS;
  searchQuery: string;
  hasNoResults: boolean;
  eventLocation: 'formulary' | 'catalogs';
  organizationId: string;
  locationId: string;
};

export type TAmplitudeAnalyticsEventSelectCategory = {
  id: EAmplitudeAnalyticsEventId.SELECT_CATEGORY;
  category: string;
  organizationId: string;
  locationId: string;
};

export type TAmplitudeAnalyticsEventSelectSimplifiedCategory = {
  id: EAmplitudeAnalyticsEventId.SELECT_SIMPLIFIED_CATEGORY;
  simplifiedCategory: string;
  organizationId: string;
  locationId: string;
};

export type TAmplitudeAnalyticsActionsTypes = {
  DoAmplitudeTrack: (args: { event: TAmplitudeAnalyticsEvent }) => void;
};

export type TAmplitudeAnalyticsActionsContext = {
  doAmplitudeTrack: TAmplitudeAnalyticsActionsTypes['DoAmplitudeTrack'];
};

export const { Context: AmplitudeAnalyticsActionsContext, useContext: useAmplitudeAnalyticsActions } =
  createRequiredContext<TAmplitudeAnalyticsActionsContext>();

export function AmplitudeAnalyticsActionsProvider({ children }: PropsWithChildren): JSX.Element {
  const doAmplitudeTrack = useDoAmplitudeTrack();

  const value = useMemo<TAmplitudeAnalyticsActionsContext>(
    () => ({
      doAmplitudeTrack,
    }),
    // @sort
    [doAmplitudeTrack],
  );

  return (
    <AmplitudeAnalyticsActionsContext.Provider value={value}>{children}</AmplitudeAnalyticsActionsContext.Provider>
  );
}

function useDoAmplitudeTrack(): TAmplitudeAnalyticsActionsTypes['DoAmplitudeTrack'] {
  return useCallback<TAmplitudeAnalyticsActionsTypes['DoAmplitudeTrack']>(({ event }) => {
    amplitudeTrack({
      event_type: event.id,
      event_properties: omit(event, ['id']),
    });
  }, []);
}
