import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TIcon, TProps } from '@src/modules/design/theme';
import { getIconComponent, styled, theme } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SButton = styled('button', {
  all: 'unset',

  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '$controlButton',
  display: 'flex',
  flexDirection: 'row',
  gap: '$cardGap',
  padding: '$itemButtonPadding',
  userSelect: 'none',

  variants: {
    variant: {
      default: {
        '&:hover': {
          backgroundColor: '$itemButtonHoverBackground',
        },

        '&:focus': {
          boxShadow: '$secondaryFocus',
        },
      },
      card: {
        borderBottomWidth: '$regular',
        borderColor: '$white',
        borderLeftWidth: '0px',
        borderRadius: '0px',
        borderRightWidth: '0px',
        borderStyle: '$regular',
        borderTopWidth: '$regular',
        marginLeft: '-12px',
        marginRight: '-12px',
        paddingBottom: '6px',
        paddingTop: '6px',

        '&:hover': {
          backgroundColor: '$itemButtonHoverBackground',
          borderColor: '$cardDefaultBorder',
        },
      },
      'card-inverted': {
        backgroundColor: '$itemButtonHoverBackground',
        borderBottomWidth: '$regular',
        borderColor: '$itemButtonHoverBackground',
        borderLeftWidth: '0px',
        borderRadius: '0px',
        borderRightWidth: '0px',
        borderStyle: '$regular',
        borderTopWidth: '$regular',
        marginLeft: '-12px',
        marginRight: '-12px',
        paddingBottom: '6px',
        paddingTop: '6px',

        '&:hover': {
          borderColor: '$cardDefaultBorder',
          backgroundColor: '$white',
        },
      },
      control: {
        backgroundColor: '$controlButtonDefaultBackground',
        borderColor: '$controlButtonDefaultBorder',
        borderStyle: '$regular',
        borderWidth: '$regular',
        cursor: 'pointer',

        '&:hover': {
          borderColor: '$controlButtonDefaultHoverBorder',
        },

        '&:active': {
          borderColor: '$controlButtonDefaultActiveBorder',
        },

        '&:focus': {
          boxShadow: '$secondaryFocus',
        },
      },
    },
  },
});

export type TItemButtonBase = {
  icon?: TIcon | undefined;
  item: TItemBaseItem;
  variant?: 'card-inverted' | 'card' | 'control' | 'default' | undefined;
};

export type TItemButton = TProps<false, TItemButtonBase, 'button'>;
export const ITEM_BUTTON_CLASS_NAME = 'wp-item-button';

export const ItemButton = withCssToString(
  ITEM_BUTTON_CLASS_NAME,
  memo(
    forwardRef<HTMLButtonElement, TItemButton>(({ icon, item, variant, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, ITEM_BUTTON_CLASS_NAME), [className]);
      const IconComponent = isDef(icon) ? getIconComponent(icon) : undefined;

      return (
        <SButton {...rest} className={joinedClassName} ref={ref} variant={variant ?? 'default'}>
          <Item item={item} rigid={false} css={{ flexGrow: 1 }} />
          {isDef(IconComponent) && (
            <IconComponent
              height={theme.sizes.itemButtonIconSize.value}
              width={theme.sizes.itemButtonIconSize.value}
              style={{ flexShrink: 0, backgroundColor: icon === 'warning' ? theme.colors.yellow2.value : 'inherit' }}
            />
          )}
        </SButton>
      );
    }),
  ),
);
