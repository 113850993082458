import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import type { TDrawer } from '@src/components/appearance/structure/Drawer';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { ESource } from '@src/gen/shared/enums/source';
import { getSourceName } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { forwardRef, memo, useMemo, useState } from 'react';

type TOrganizationSourcePickerDrawerPanelBase = {
  buttonActionText?: string | undefined;
  drawerHeaderTitle?: string | undefined;
  onBack?: (() => void) | undefined;
  onSelectSource: (source: ESource) => Promise<void>;
  sources: ESource[];
};

export type TOrganizationSourcePickerDrawerPanel = TProps<false, TOrganizationSourcePickerDrawerPanelBase, 'div'>;
export const ORGANIZATION_SOURCE_PICKER_DRAWER_PANEL_CLASS_NAME = 'wp-organization-source-picker-drawer-panel';

export const OrganizationSourcePickerDrawerPanel = withCssToString(
  ORGANIZATION_SOURCE_PICKER_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrganizationSourcePickerDrawerPanel>(
      (
        { buttonActionText, drawerHeaderTitle, onBack, onSelectSource, sources, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORGANIZATION_SOURCE_PICKER_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [selectedSource, setSelectedSource] = useState<ESource | null>(null);

        const buttonAction = useMemo<TDrawer['FooterBase']['buttonAction']>(
          () =>
            isDef(selectedSource) && sources.length > 0
              ? {
                  isAsync: true,
                  onClick: async (): Promise<void> => await onSelectSource(selectedSource),
                  text: buttonActionText ?? 'Select Source',
                }
              : {
                  isAsync: false,
                  onClick: noop,
                  text: buttonActionText ?? 'Select Source',
                  variant: 'disabled',
                },
          [buttonActionText, onSelectSource, selectedSource, sources.length],
        );

        const secondaryButtonAction = useMemo<TDrawer['FooterBase']['secondaryButtonAction']>(
          () =>
            isDef(onBack)
              ? {
                  isAsync: false,
                  onClick: onBack,
                  text: 'Go Back',
                  variant: 'secondary',
                }
              : undefined,
          [onBack],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title={drawerHeaderTitle ?? 'Select Organization'} />
            <Drawer.ScrollContent>
              <Drawer.Group>
                {sources.map((source) => (
                  <ControlButton
                    key={source}
                    icon={source === selectedSource ? 'apply' : undefined}
                    onClick={(): void => setSelectedSource(source)}
                    text={getSourceName(source)}
                  />
                ))}
              </Drawer.Group>
            </Drawer.ScrollContent>
            <Drawer.Footer buttonAction={buttonAction} secondaryButtonAction={secondaryButtonAction} />
          </Drawer.Panel>
        );
      },
    ),
  ),
);
