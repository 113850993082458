import type { AgentOrderSubscriptionVariables, TAgentOrderCompleteFragment } from '@src/gen/graphql/bindings';
import { useAgentOrderSubscription } from '@src/gen/graphql/bindings';
import { AgentOrderManager } from '@src/gen/shared/data/agentOrders';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useAgentOrderRouteParams } from '@src/modules/routing/agent';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TAgentOrderContext = {
  order: TAgentOrderCompleteFragment;
  orderManager: AgentOrderManager;
  serializationKey: string;
};

export const { Context: AgentOrderContext, useContext: useAgentOrder } = createRequiredContext<TAgentOrderContext>();

export type TAgentOrderProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function AgentOrderProvider({ LoaderComponent, children }: TAgentOrderProvider): JSX.Element {
  const routeParams = useAgentOrderRouteParams();

  const variables = useMemo<AgentOrderSubscriptionVariables>(
    () => ({
      orderId: routeParams.orderId,
    }),
    // @sort
    [routeParams.orderId],
  );

  const { data, error, loading } = useAgentOrderSubscription({
    variables,
  });

  const value = useMemo<TAgentOrderContext | undefined>(
    () =>
      isDef(data) && isDef(data.orders_by_pk)
        ? {
            order: data.orders_by_pk,
            orderManager: new AgentOrderManager(data.orders_by_pk),
            serializationKey: `order[${data.orders_by_pk.id}]`,
          }
        : undefined,
    // @sort
    [data],
  );

  if (isDef(error)) {
    throw error;
  }

  if (isDef(data) && !isDef(data.orders_by_pk)) {
    throw new Error('Not Found');
  }

  return isDef(value) && !loading ? (
    <AgentOrderContext.Provider value={value}>{children}</AgentOrderContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
