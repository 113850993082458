import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { type TItemsBase } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrganizationSourceCard } from '@src/components/mixins/cards/OrganizationSourceCard';
import { maybeGetDonationTargetLabel } from '@src/gen/shared/enums/donationTarget';
import { maybeGetReferralFeeTargetLabel } from '@src/gen/shared/enums/referralFeeTarget';
import { maybeGetRevenueShareTargetLabel } from '@src/gen/shared/enums/revenueShareTarget';
import { getSource } from '@src/gen/shared/enums/source';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentCustomersOrganizationActions } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import { useAgentCustomersOrganization } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationProvider';
import { useMemo } from 'react';

export function AgentCustomersOrganizationOverviewPart(): JSX.Element {
  const { organization } = useAgentCustomersOrganization();
  const {
    beginOrganizationEdit,
    beginOrganizationSourceAdd,
    beginOrganizationSourceRemove,
    beginOrganizationFormularyProductsImport,
    beginOrganizationSandboxDisable,
  } = useAgentCustomersOrganizationActions();

  const activeOrgAcls = useMemo(
    () => organization.organization_acls.filter((orgAcl) => orgAcl.is_member && !orgAcl.user.is_disabled),
    [organization.organization_acls],
  );

  const inactiveOrgAcls = useMemo(
    () => organization.organization_acls.filter((orgAcl) => !orgAcl.is_member || orgAcl.user.is_disabled),
    [organization.organization_acls],
  );

  const lastOrderPlacedOn = useMemo(
    () =>
      organization.lastOrderByLocation
        .reduce<(string | undefined)[]>((out, location) => [...out, location.orders[0]?.created_at], [])
        .filter((createdAt): createdAt is string => isDef(createdAt))
        .sort((a, b) => b.localeCompare(a))[0],
    [organization.lastOrderByLocation],
  );

  const itemsFirst = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Name',
        text: organization.name,
      },
      {
        caption: 'Active Users',
        text: `${activeOrgAcls.length}`,
      },
      {
        caption: 'Inactive Users',
        text: `${inactiveOrgAcls.length}`,
      },
      {
        caption: 'Locations',
        text: `${organization.locations.length}`,
      },
    ],
    [activeOrgAcls.length, inactiveOrgAcls.length, organization.locations.length, organization.name],
  );

  const itemsSecond = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'GPO Formularies',
        accent: organization.is_catapult_grow ? 'warning' : undefined,
        text: organization.is_catapult_grow ? 'Catapult Grow' : 'None',
      },
      {
        caption: 'Emergency Orders Enabled',
        text: { asBoolean: organization.can_place_emergency_orders },
      },
      {
        caption: 'Ordering System Version',
        text: `${organization.default_order_version === 1 ? 'v1.0' : 'v2'}`,
      },
    ],
    [organization.can_place_emergency_orders, organization.default_order_version, organization.is_catapult_grow],
  );

  const itemsThird = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Charity Program',
        text: isDef(organization.donation_target) ? maybeGetDonationTargetLabel(organization.donation_target) : 'None',
      },
      {
        caption: 'Revenue Share Program',
        text: maybeGetRevenueShareTargetLabel(organization.revenue_share_target ?? 'None'),
      },
      {
        caption: 'Referral Fee Program',
        text: maybeGetReferralFeeTargetLabel(organization.referral_fee_target ?? 'None'),
      },
    ],
    [organization.donation_target, organization.revenue_share_target, organization.referral_fee_target],
  );

  const itemsFourth = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Onboarded On',
        text: { asDate: 'on', at: organization.created_at },
      },
      {
        caption: 'Last Order Placed',
        text: isDef(lastOrderPlacedOn) ? { asDate: 'ago', at: lastOrderPlacedOn } : 'N/A',
      },
    ],
    [lastOrderPlacedOn, organization.created_at],
  );

  const summaryIconButtonActions = useMemo(
    () =>
      getAvailableIconButtonActions([
        {
          isAsync: false,
          icon: 'edit',
          onClick: beginOrganizationEdit ?? undefined,
        },
        {
          isAsync: false,
          icon: 'rocket',
          onClick:
            organization.is_sandbox && isDef(beginOrganizationSandboxDisable)
              ? beginOrganizationSandboxDisable
              : undefined,
        },
      ]),
    [beginOrganizationEdit, beginOrganizationSandboxDisable, organization.is_sandbox],
  );

  const sourcesIconButtonActions = useMemo(
    () =>
      getAvailableIconButtonActions([
        {
          isAsync: false,
          icon: 'add',
          onClick: beginOrganizationSourceAdd ?? undefined,
        },
        {
          isAsync: false,
          icon: 'upload',
          onClick: beginOrganizationFormularyProductsImport ?? undefined,
        },
      ]),
    [beginOrganizationFormularyProductsImport, beginOrganizationSourceAdd],
  );

  return (
    <Structure.ScrollContent>
      <Structure.Stack>
        <Structure.Group iconButtonActions={summaryIconButtonActions} title='Summary and Settings'>
          {organization.is_sandbox && (
            <Banner
              accent='warning'
              icon='warning'
              message='This organization is sandboxed. No orders can be placed.'
            />
          )}
          <ItemsBanner items={itemsFirst} />
          <ItemsBanner items={itemsSecond} />
          <ItemsBanner items={itemsThird} />
          <ItemsBanner items={itemsFourth} />
        </Structure.Group>
        <Structure.Group iconButtonActions={sourcesIconButtonActions} title='Catalogs'>
          {organization.organization_sources.map((os) => (
            <OrganizationSourceCard
              key={os.organization_id}
              onRemove={
                isDef(beginOrganizationSourceRemove)
                  ? (): void => beginOrganizationSourceRemove({ source: getSource(os.source) })
                  : null
              }
              organizationSource={os}
            />
          ))}
        </Structure.Group>
      </Structure.Stack>
    </Structure.ScrollContent>
  );
}
