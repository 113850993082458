import { AGO_CLASS_NAME } from '@src/components/appearance/basics/Ago';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { CheckBox } from '@src/components/appearance/controls/CheckBox';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { PropsWithChildren } from 'react';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  gap: '$labeledGap',

  [`&:has(${Typography.Label.toString()}:active) > ${CheckBox.toString()}`]: {
    boxShadow: '$secondaryFocus',
  },
});

export type TLabeledBase = {
  htmlFor: string;
  text: string;
};

export type TLabeled = TProps<true, TLabeledBase, 'div'>;
export const LABELED_CLASS_NAME = 'wp-labeled';

export const Labeled = withCssToString(
  LABELED_CLASS_NAME,
  forwardRef<HTMLDivElement, TLabeled>(({ htmlFor, text, children, className, ...rest }, ref) => {
    const joinedClassName = useMemo(() => joinClassNames(className, AGO_CLASS_NAME), [className]);

    return (
      <SDiv {...rest} className={joinedClassName} ref={ref}>
        {children}
        <Typography.Label expanding={true} htmlFor={htmlFor} rigid={true} text={text} />
      </SDiv>
    );
  }),
);

export const LabeledLoader = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<TEmptyObject>>(
    ({ children }, ref): JSX.Element => (
      <SDiv ref={ref}>
        {children}
        <Illustrations.Loader height={21} uniqueKey='wp-labeled-loader' width={96}>
          <rect x='0' y='5' rx='0' ry='0' width='96' height='11' />
        </Illustrations.Loader>
      </SDiv>
    ),
  ),
);
