import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import type { TSearchFilter } from '@src/components/appearance/controls/SearchFilter';
import { SearchFilter } from '@src/components/appearance/controls/SearchFilter';
import { Select } from '@src/components/appearance/controls/Select';
import type { TTabSelectBase } from '@src/components/appearance/controls/TabSelect';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderEntryStructureGroup } from '@src/components/mixins/groups/OrderEntryStructureGroup';
import type { AgentOrderEntryManager } from '@src/gen/shared/data/agentOrders';
import { EOrderEntryOrigin } from '@src/gen/shared/enums/orderEntryOrigin';
import { EOrderEntryPlanStatus, maybeGetOrderEntryPlanStatusLabel } from '@src/gen/shared/enums/orderEntryPlanStatus';
import type { ESource } from '@src/gen/shared/enums/source';
import { getSourceName } from '@src/gen/shared/enums/source';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import type { TAgentOrderWorksheetProductsRouteParamsConfig } from '@src/modules/routing/agent';
import {
  encodeAgentOrderWorksheetProductsPath,
  useAgentOrderWorksheetProductsRouteParams,
} from '@src/modules/routing/agent';
import { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentOrderWorksheetProductsPart(): JSX.Element {
  const routeParams = useAgentOrderWorksheetProductsRouteParams();
  const navigate = useNavigate();
  const { order, orderManager } = useAgentOrder();
  const { beginOrderEntryAdd, beginOrderEntryBaseProductView, beginOrderEntryEdit, beginOrderEntryPlanProductView } =
    useAgentOrderActions();

  const handleFilterStatusSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
          ...routeParams.config,
          status: e.target.value as TAgentOrderWorksheetProductsRouteParamsConfig['status'], // eslint-disable-line @typescript-eslint/consistent-type-assertions
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const handleFilterOriginSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
          ...routeParams.config,
          origin: e.target.value as TAgentOrderWorksheetProductsRouteParamsConfig['origin'], // eslint-disable-line @typescript-eslint/consistent-type-assertions
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const handleFilterBaseSourceSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
          ...routeParams.config,
          baseSource: e.target.value as TAgentOrderWorksheetProductsRouteParamsConfig['baseSource'], // eslint-disable-line @typescript-eslint/consistent-type-assertions
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const handleFilterPlanSourceSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
          ...routeParams.config,
          planSource: e.target.value as TAgentOrderWorksheetProductsRouteParamsConfig['planSource'], // eslint-disable-line @typescript-eslint/consistent-type-assertions
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const handleFilterAllowSubstitutionsSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
          ...routeParams.config,
          allowSubstitutions: e.target.value === 'any' ? 'any' : e.target.value === 'true',
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const handleFilterSavingsSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
          ...routeParams.config,
          savings: e.target.value as TAgentOrderWorksheetProductsRouteParamsConfig['savings'], // eslint-disable-line @typescript-eslint/consistent-type-assertions
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const handleFilterPlanStatusChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
          ...routeParams.config,
          planStatus: e.target.value as TAgentOrderWorksheetProductsRouteParamsConfig['planStatus'], // eslint-disable-line @typescript-eslint/consistent-type-assertions
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const handleFilterSearchApply = useCallback<TSearchFilter['onApply']>(
    (searchFilter) => {
      navigate(
        encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
          ...routeParams.config,
          search: searchFilter,
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const handleFilterFlagsSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetProductsPath(routeParams.orderId, {
          ...routeParams.config,
          flags: e.target.value as TAgentOrderWorksheetProductsRouteParamsConfig['flags'], // eslint-disable-line @typescript-eslint/consistent-type-assertions
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const showOrderEntryManagers = useMemo<AgentOrderEntryManager[]>(
    () =>
      orderManager.orderEntryManagers
        .filter((oem) => {
          switch (routeParams.config.status) {
            case 'any':
              return true;
            case 'ready':
              return oem.isReady();
            case 'tbd':
              return !oem.isReady();
          }
        })
        .filter((oem) => {
          switch (routeParams.config.origin) {
            case 'any':
              return true;
            default:
              return oem.orderEntry.origin === routeParams.config.origin;
          }
        })
        .filter((oem) => {
          switch (routeParams.config.baseSource) {
            case 'any':
              return true;
            default:
              return oem.orderEntry.source === routeParams.config.baseSource;
          }
        })
        .filter((oem) => {
          switch (routeParams.config.planSource) {
            case 'any':
              return true;
            default:
              return oem.orderEntry.plan_source === routeParams.config.planSource;
          }
        })
        .filter((oem) => {
          switch (routeParams.config.allowSubstitutions) {
            case 'any':
              return true;
            default:
              return oem.orderEntry.is_substitution_allowed === routeParams.config.allowSubstitutions;
          }
        })
        .filter((oem) => {
          const savings = oem.getSavingsPercent();

          switch (routeParams.config.savings) {
            case 'any':
              return true;
            case 'none':
              return isDef(savings) && savings === 0;
            case '<5':
              return isDef(savings) && savings < 500;
            case '<10':
              return isDef(savings) && savings < 1000;
            case '<15':
              return isDef(savings) && savings < 1500;
            case '<20':
              return isDef(savings) && savings < 2000;
            case '<50':
              return isDef(savings) && savings < 5000;
            case '>=50':
              return isDef(savings) && savings >= 5000;
          }
        })
        .filter((oem) => {
          switch (routeParams.config.planStatus) {
            case 'any':
              return true;
            default:
              return oem.orderEntry.plan_status === routeParams.config.planStatus;
          }
        })
        .filter((oem) => {
          const q = routeParams.config.search;

          switch (q) {
            case '':
              return true;
            default:
              return (
                oem.orderEntry.name.toLowerCase().includes(q) ||
                (oem.orderEntry.secondary_name ?? '').toLowerCase().includes(q) ||
                (oem.orderEntry.plan_name ?? '').toLowerCase().includes(q) ||
                (oem.orderEntry.plan_secondary_name ?? '').toLowerCase().includes(q)
              );
          }
        })
        .filter((oem) => {
          switch (routeParams.config.flags) {
            case 'any':
              return true;
            case 'is-under-review':
              return oem.orderEntry.is_under_review;
            case 'is-blocked-on-price-confirmation':
              return oem.orderEntry.is_blocked_on_price_confirmation;
            case 'is-blocked-on-product-availability-confirmation':
              return oem.orderEntry.is_blocked_on_product_availability_confirmation;
          }
        }),
    // @sort
    [
      orderManager.orderEntryManagers,
      routeParams.config.allowSubstitutions,
      routeParams.config.baseSource,
      routeParams.config.flags,
      routeParams.config.origin,
      routeParams.config.planSource,
      routeParams.config.planStatus,
      routeParams.config.savings,
      routeParams.config.search,
      routeParams.config.status,
    ],
  );

  return (
    <Fragment>
      <Structure.Content>
        <ToolBar
          RightChildren={
            <Fragment>
              <ActionIconButton action={{ isAsync: false, icon: 'add', onClick: beginOrderEntryAdd }} />
            </Fragment>
          }
          variant='structure'>
          <Select onChange={handleFilterStatusSelectChange} value={routeParams.config.status}>
            <option value='any'>Status: Any</option>
            <option value='tbd'>Status: TBD</option>
            <option value='ready'>Status: Ready</option>
          </Select>
          <Select onChange={handleFilterOriginSelectChange} value={routeParams.config.origin}>
            <option value='any'>Origin: Any</option>
            <option value={EOrderEntryOrigin.ORIGINAL_CART}>Origin: Original Cart</option>
            <option value={EOrderEntryOrigin.CUSTOMER_REQUESTED}>Origin: Customer Requested</option>
          </Select>
          <Select onChange={handleFilterBaseSourceSelectChange} value={routeParams.config.baseSource}>
            <option value='any'>Base Source: Any</option>
            {Object.keys(
              orderManager.orderEntryManagers.reduce<{ [key in ESource]?: true }>(
                (out, oem) => ({
                  ...out,
                  [oem.orderEntry.source]: true,
                }),
                {},
              ),
            )
              .sort((a, b) => a.localeCompare(b))
              .map((source) => (
                <option key={source} value={source}>
                  Base Source: {getSourceName(source)}
                </option>
              ))}
          </Select>
          <Select onChange={handleFilterPlanSourceSelectChange} value={routeParams.config.planSource}>
            <option value='any'>Plan Source: Any</option>
            {Object.keys(
              orderManager.orderEntryManagers.reduce<{ [key in ESource]?: true }>(
                (out, oem) =>
                  isDef(oem.orderEntry.plan_source)
                    ? {
                        ...out,
                        [oem.orderEntry.plan_source]: true,
                      }
                    : out,
                {},
              ),
            )
              .sort((a, b) => a.localeCompare(b))
              .map((source) => (
                <option key={source} value={source}>
                  Plan Source: {getSourceName(source)}
                </option>
              ))}
          </Select>
          <Select
            onChange={handleFilterAllowSubstitutionsSelectChange}
            value={
              routeParams.config.allowSubstitutions === 'any'
                ? 'any'
                : routeParams.config.allowSubstitutions
                ? 'true'
                : 'false'
            }>
            <option value='any'>Allow Subs: Any</option>
            <option value='true'>Allow Subs: Yes</option>
            <option value='false'>Allow Subs: No</option>
          </Select>
          <Select onChange={handleFilterSavingsSelectChange} value={routeParams.config.savings}>
            <option value='any'>Savings: Any</option>
            <option value='none'>Savings: {'None'}</option>
            <option value='<5'>Savings: {'< 5%'}</option>
            <option value='<10'>Savings: {'< 10%'}</option>
            <option value='<15'>Savings: {'< 15%'}</option>
            <option value='<20'>Savings: {'< 20%'}</option>
            <option value='<50'>Savings: {'< 50%'}</option>
            <option value='>=50'>Savings: {'>= 50%'}</option>
          </Select>
          <Select onChange={handleFilterPlanStatusChange} value={routeParams.config.planStatus}>
            <option value='any'>Plan: Any</option>
            {Object.values(EOrderEntryPlanStatus).map((ps) => (
              <option key={ps} value={ps}>
                Plan: {maybeGetOrderEntryPlanStatusLabel(ps)}
              </option>
            ))}
          </Select>
          <Select onChange={handleFilterFlagsSelectChange} value={routeParams.config.flags}>
            <option value='any'>Flags: Any</option>
            <option value='is-under-review'>Flags: Under Review</option>
            <option value='is-blocked-on-price-confirmation'>Flags: Waiting on Price Conf.</option>
            <option value='is-blocked-on-product-availability-confirmation'>
              Flags: Waiting on Availability Conf.
            </option>
          </Select>
          <SearchFilter
            onApply={handleFilterSearchApply}
            searchFilter={routeParams.config.search}
            placeholder={'Search'}
          />
        </ToolBar>
      </Structure.Content>
      <Structure.ScrollContent skipSeparator={true}>
        <Structure.Group>
          {showOrderEntryManagers.length === 0 && <Banner icon='apply' message='No products matching these filters.' />}
          {showOrderEntryManagers.map((oem) => (
            <OrderEntryStructureGroup
              key={oem.orderEntry.id}
              isEditable={!orderManager.hasSnapshotPrebillPending()}
              beginOrderEntryBaseProductView={beginOrderEntryBaseProductView}
              beginOrderEntryEdit={beginOrderEntryEdit}
              beginOrderEntryPlanProductView={beginOrderEntryPlanProductView}
              orderEntryManager={oem}
              orderEntry={ensureDef(order.order_entries.find((oe) => oe.id === oem.orderEntry.id))}
            />
          ))}
        </Structure.Group>
      </Structure.ScrollContent>
    </Fragment>
  );
}
