import { Typography } from '@src/components/appearance/basics/Typography';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Card } from '@src/components/appearance/fragments/Card';
import { Description } from '@src/components/appearance/fragments/Description';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { TFormularyProductsImportFormBase } from '@src/components/mixins/forms/FormularyProductsImportForm';
import { FormularyProductsImportForm } from '@src/components/mixins/forms/FormularyProductsImportForm';
import { EDescriptionBlockType } from '@src/gen/shared/data/description';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersOrganizationActionsTypes } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import type { TAgentUploadsTypes } from '@src/modules/data/agent/global/uploads/AgentUploadsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import type { Dispatch, SetStateAction } from 'react';
import { Fragment, forwardRef, memo, useCallback, useMemo, useState } from 'react';

export type TFormularyProductsImportDrawerPanelBase = {
  doCustomersOrganizationImportFormularyProductsAsync: TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationImportFormularyProductsAsync'];
  doUploadAsync: TAgentUploadsTypes['DoUploadAsync'];
  doDrawerClose: () => void;
};

type TFormularyProductsImportDrawerPanelState = {
  result: TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationImportResult'] | null;
};

export type TFormularyProductsImportDrawerPanel = TProps<false, TFormularyProductsImportDrawerPanelBase, 'div'>;
export const DATE_RANGE_PICKER_DRAWER_PANEL_CLASS_NAME = 'wp-date-range-picker-drawer-panel';

export const FormularyProductsImportDrawerPanel = withCssToString(
  DATE_RANGE_PICKER_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TFormularyProductsImportDrawerPanel>(
      (
        { doCustomersOrganizationImportFormularyProductsAsync, doUploadAsync, doDrawerClose, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, DATE_RANGE_PICKER_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [state, setState] = useState<TFormularyProductsImportDrawerPanelState>({
          result: null,
        });

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Import Formulary Products' />
            {!isDef(state.result) && (
              <ImportPhase
                doCustomersOrganizationImportFormularyProductsAsync={
                  doCustomersOrganizationImportFormularyProductsAsync
                }
                doUploadAsync={doUploadAsync}
                setState={setState}
              />
            )}
            {isDef(state.result) && <ResultPhase result={state.result} doDrawerClose={doDrawerClose} />}
          </Drawer.Panel>
        );
      },
    ),
  ),
);

function ImportPhase({
  doCustomersOrganizationImportFormularyProductsAsync,
  doUploadAsync,
  setState,
}: {
  doCustomersOrganizationImportFormularyProductsAsync: TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationImportFormularyProductsAsync'];
  doUploadAsync: TAgentUploadsTypes['DoUploadAsync'];
  setState: Dispatch<SetStateAction<TFormularyProductsImportDrawerPanelState>>;
}): JSX.Element {
  const handleImport = useCallback<TFormularyProductsImportFormBase['onImport']>(
    (result) => {
      setState((prevState) => ({
        ...prevState,
        result,
      }));
    },
    [setState],
  );

  return (
    <Drawer.ScrollContent>
      <Card.Container flush={true} interactive={false} variant='form'>
        <Description
          description={[
            {
              type: EDescriptionBlockType.TITLE,
              title: 'Instructions',
            },
            {
              type: EDescriptionBlockType.FEATURES,
              features: [
                'Provide a plain text file (*.txt) with one entry per row. Blank rows are ignored. Max 5000 rows.',
                'Each row can be either a qualified product SKU (e.g. ABC-123A-12) or product page URL.',
              ],
            },
          ]}
        />
      </Card.Container>
      <FormularyProductsImportForm
        doCustomersOrganizationImportFormularyProductsAsync={doCustomersOrganizationImportFormularyProductsAsync}
        doUploadAsync={doUploadAsync}
        onImport={handleImport}
      />
    </Drawer.ScrollContent>
  );
}

const SRows = styled('div', {
  alignItems: 'stretch',
  borderColor: '$gray2',
  borderStyle: '$regular',
  borderWidth: '$regular',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '8px',
});

const SRowDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  paddingBottom: '6px',
  paddingLeft: '12px',
  paddingRight: '12px',
  paddingTop: '6px',

  variants: {
    odd: {
      false: {
        // intentionally empty
      },
      true: {
        backgroundColor: '$gray1',
      },
    },
  },
});

function ResultPhase({
  result,
  doDrawerClose,
}: {
  result: TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationImportResult'];
  doDrawerClose: () => void;
}): JSX.Element {
  return (
    <Fragment>
      <Drawer.ScrollContent>
        {result.importedCount > 0 && (
          <Banner accent='success' icon='apply' message={`Successfully imported ${result.importedCount} product(s).`} />
        )}
        {result.invalidInputs.length > 0 && (
          <SRows>
            <Banner
              css={{ marginBottom: '8px' }}
              accent='error'
              icon='warning'
              message={`Uploaded file contains ${result.invalidInputs.length} invalid entries.`}
            />
            {result.invalidInputs
              .sort((a, b) => a.localeCompare(b))
              .map((v, i) => (
                <SRowDiv key={v} odd={result.invalidInputs.length > 2 && i % 2 === 1}>
                  <Typography.Small text={v} rigid={true} expanding={true} />
                </SRowDiv>
              ))}
          </SRows>
        )}
        {result.missingReferences.length > 0 && (
          <SRows>
            <Banner
              css={{ marginBottom: '8px' }}
              accent='warning'
              icon='warning'
              message={`Uploaded file contains ${result.missingReferences.length} unknown entries.`}
            />
            {result.missingReferences
              .sort((a, b) => a.input.localeCompare(b.input))
              .map((v, i) => (
                <SRowDiv key={v.input} odd={result.missingReferences.length > 2 && i % 2 === 1}>
                  <Typography.Small text={v.input} rigid={true} expanding={true} />
                </SRowDiv>
              ))}
          </SRows>
        )}
      </Drawer.ScrollContent>
      <Drawer.Footer
        buttonAction={{
          isAsync: false,
          onClick: doDrawerClose,
          text: 'Close',
        }}
      />
    </Fragment>
  );
}
