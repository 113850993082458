import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'center',
  borderRadius: '$tag',
  color: '$tagText',
  display: 'inline-flex',
  flexDirection: 'row',
  height: '$tagHeight',
  justifyContent: 'center',
  overflow: 'hidden',
  paddingX: '$tagPaddingX',
  text: '$caption',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',

  variants: {
    accent: {
      default: {
        backgroundColor: '$tagDefaultBackground',
      },
      success: {
        backgroundColor: '$tagSuccessBackground',
      },
      warning: {
        backgroundColor: '$tagWarningBackground',
      },
      error: {
        color: '$tagErrorText',
        backgroundColor: '$tagErrorBackground',
      },
    },
  },
});

export type TTagBase = {
  accent?: 'default' | 'error' | 'success' | 'warning' | undefined;
  text: string;
};

export type TTag = TProps<false, TTagBase, 'div'>;
export const TAG_CLASS_NAME = 'wp-tag';

export const Tag = withCssToString(
  TAG_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TTag>(({ accent, text, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, TAG_CLASS_NAME), [className]);

      return (
        <SDiv {...rest} className={joinedClassName} accent={accent ?? 'default'} ref={ref}>
          {text}
        </SDiv>
      );
    }),
  ),
);

export const TagLoader = memo(
  forwardRef<SVGSVGElement, TEmptyObject>(({}, ref) => (
    <Illustrations.Loader height={30} ref={ref} uniqueKey='wp-tag-loader' width={96}>
      <rect x='0' y='0' rx='0' ry='0' width='96' height='30' />
    </Illustrations.Loader>
  )),
);
