import { Typography } from '@src/components/appearance/basics/Typography';
import { Button } from '@src/components/appearance/controls/Button';
import { Toast } from '@src/components/appearance/structure/Toast';
import { isDef } from '@src/gen/shared/utils/types';
import { useConfig } from '@src/modules/config/ConfigProvider';
import { useToast } from '@src/modules/design/ToastProvider';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import noop from 'lodash/noop';
import type { PropsWithChildren } from 'react';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import useAsyncEffect from 'use-async-effect';

export function WorkersProvider({ children }: PropsWithChildren): JSX.Element {
  const config = useConfig();
  const { doErrorNotify } = useErrors();
  const { doToastOpen } = useToast();

  const hasServiceWorkerRef = useRef('serviceWorker' in navigator);
  const isProductionModeRef = useRef(config.NODE_ENV === 'production');

  const doNotifyUpdate = useCallback(
    (sw: ServiceWorker) => {
      let refreshing = false;

      doToastOpen(
        <Toast.Panel accent='neutral'>
          <Toast.Header title='A new version of Wellplaece is available.' notDismissable={true} />
          <Typography.Small text='Click on the button below to update, it only takes a second.' />
          <Button
            text='Update Wellplaece'
            onClick={(): void => {
              if (!refreshing) {
                sw.postMessage({ type: 'SKIP_WAITING' });
                refreshing = true;
              }
            }}
          />
        </Toast.Panel>,
      );
    },
    [doToastOpen],
  );

  useEffect(() => {
    if (!hasServiceWorkerRef.current || !isProductionModeRef.current) {
      return;
    }

    let isRefreshing = false;

    const onSwControllerChange = (): void => {
      console.debug('WorkersProvider.onSwControllerChange');

      if (!isRefreshing) {
        window.location.reload();
        isRefreshing = true;
      }
    };

    navigator.serviceWorker.addEventListener('controllerchange', onSwControllerChange);
    return () => navigator.serviceWorker.removeEventListener('controllerchange', onSwControllerChange);
  }, []);

  useAsyncEffect(async () => {
    if (!hasServiceWorkerRef.current || isProductionModeRef.current) {
      return;
    }

    for (const reg of await navigator.serviceWorker.getRegistrations()) {
      try {
        await reg.unregister();
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: EErrorSummaryDisplayMode.SILENT,
          }),
        );
      }
    }
  }, []);

  useAsyncEffect(async () => {
    if (!hasServiceWorkerRef.current || !isProductionModeRef.current) {
      return;
    }

    const unloadFunctions: (() => void)[] = [];

    try {
      const reg = await navigator.serviceWorker.register('/service-worker.js');

      if (isDef(reg.active) && isDef(reg.waiting)) {
        doNotifyUpdate(reg.waiting);
      }

      const checkForUpdates = (): void => {
        console.log('WorkersProvider.checkForUpdates');
        void reg.update().then(noop).catch(noop);
      };

      const interval = setInterval(checkForUpdates, 10 * 60 * 1000);
      unloadFunctions.push(() => clearInterval(interval));

      const onSwStateChange = (): void => {
        console.log('WorkersProvider.onSwStateChange');

        if (reg.waiting?.state === 'installed') {
          doNotifyUpdate(reg.waiting);
        }
      };

      const onSwUpdateFound = (): void => {
        console.debug('WorkersProvider.onSwUpdateFound');
        const installing = reg.installing;

        if (!isDef(installing) || !isDef(reg.active)) {
          return;
        }

        installing.addEventListener('statechange', onSwStateChange);
        unloadFunctions.push(() => installing.removeEventListener('statechange', onSwStateChange));
      };

      reg.addEventListener('updatefound', onSwUpdateFound);
      unloadFunctions.push(() => reg.removeEventListener('updatefound', onSwUpdateFound));
    } catch (thrown: unknown) {
      doErrorNotify(
        getErrorSummary(thrown, {
          forceDisplayMode: EErrorSummaryDisplayMode.ANNOUNCEMENT,
        }),
      );
    }

    return (): void => {
      unloadFunctions.forEach((f) => f());
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}
