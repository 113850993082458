import { Typography } from '@src/components/appearance/basics/Typography';
import { CurrencyControl } from '@src/components/appearance/controls/Currency';
import { DatePickerControl } from '@src/components/appearance/controls/DatePickerControl';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { SelectControl } from '@src/components/appearance/controls/Select';
import { Card } from '@src/components/appearance/fragments/Card';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

const STitleDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '4px',
});

export type TBackfillOrderInvoiceFormValues = {
  baseSubTotal: number;
  baseShipping: number;
  baseTax: number;
  baseTotal: number;
  wellplaeceSubTotal: number;
  wellplaeceFees: number;
  wellplaeceTax: number;
  wellplaeceTotal: number;
  agentWellplaeceShipping: number;
  agentWellplaeceCcFee: number;
  agentWellplaeceCommission: number;
  agentWellplaeceCommissionPercent: '0' | '1.5' | '1' | '3.5' | '4.5' | '4' | '6' | '8';
  invoiceIssuedAt: string;
};

export type TBackfillOrderInvoiceFormBase = {
  initialValues: TBackfillOrderInvoiceFormValues;
  onSubmit: (values: TBackfillOrderInvoiceFormValues) => void;
};

export type TBackfillOrderInvoiceForm = TProps<false, TBackfillOrderInvoiceFormBase, 'div'>;
export const BACKFILL_ORDER_INVOICE_FORM_CLASS_NAME = 'wp-backfill-order-invoice-form';

export const BackfillOrderInvoiceForm = withCssToString(
  BACKFILL_ORDER_INVOICE_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TBackfillOrderInvoiceForm>(
      ({ initialValues, onSubmit, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, BACKFILL_ORDER_INVOICE_FORM_CLASS_NAME),
          [className],
        );

        const handleSubmit = useCallback<TFormSubmit<TBackfillOrderInvoiceFormValues>>(
          async (values) => {
            onSubmit(values);
            return undefined;
          },
          [onSubmit],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TBackfillOrderInvoiceFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              submitButtonText='Review'>
              <STitleDiv>
                <Typography.Cta text='Base' />
                <Card.Separator />
              </STitleDiv>
              <CurrencyControl id='baseSubTotal' label='Sub Total (Base)' required={true} />
              <CurrencyControl id='baseShipping' label='Shipping (Base)' required={false} />
              <CurrencyControl id='baseTax' label='Tax (Base)' required={false} />
              <CurrencyControl id='baseTotal' label='Total (Base)' required={true} />
              <STitleDiv>
                <Typography.Cta text='Wellplaece' />
                <Card.Separator />
              </STitleDiv>
              <CurrencyControl id='wellplaeceSubTotal' label='Sub Total (Wellplaece)' required={true} />
              <CurrencyControl id='wellplaeceFees' label='Shipping and Handling (Wellplaece)' required={false} />
              <CurrencyControl id='wellplaeceTax' label='Tax (Wellplaece)' required={false} />
              <CurrencyControl id='wellplaeceTotal' label='Total (Wellplaece)' required={true} />
              <STitleDiv>
                <Typography.Cta text='Agent-Only' />
                <Card.Separator />
              </STitleDiv>
              <CurrencyControl
                id='agentWellplaeceShipping'
                label='Agent-Only - Shipping Fees (Wellplaece)'
                required={false}
              />
              <CurrencyControl id='agentWellplaeceCcFee' label='Agent-Only - CC Fee (Wellplaece)' required={false} />
              <CurrencyControl
                id='agentWellplaeceCommission'
                label='Agent-Only - Commission (Wellplaece)'
                required={false}
              />
              <SelectControl id='agentWellplaeceCommissionPercent' label='Agent-Only - Commission Percent (Wellplaece)'>
                <option value='0'>0.0%</option>
                <option value='1'>1.0%</option>
                <option value='1.5'>1.5%</option>
                <option value='3.5'>3.5%</option>
                <option value='4'>4%</option>
                <option value='4.5'>4.5%</option>
                <option value='6'>6.0%</option>
                <option value='8'>8.0%</option>
              </SelectControl>
              <STitleDiv>
                <Typography.Cta text='Details' />
                <Card.Separator />
              </STitleDiv>
              <DatePickerControl id='invoiceIssuedAt' label='Invoice Date' />
            </Form>
          </div>
        );
      },
    ),
  ),
);
