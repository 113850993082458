import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import type { TAgentCustomersOrganizationBaseWithStatisticsFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrganizationWithStatisticsCardBase = {
  organization: TAgentCustomersOrganizationBaseWithStatisticsFragment;
  to: string;
};

export type TOrganizationWithStatisticsCard = TProps<false, TOrganizationWithStatisticsCardBase, 'div'>;
export const ORGANIZATION_WITH_STATISTICS_CARD_CLASS_NAME = 'wp-organization-with-statistics-card';

export const OrganizationWithStatisticsCard = withCssToString(
  ORGANIZATION_WITH_STATISTICS_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrganizationWithStatisticsCard>(
      ({ organization, to, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORGANIZATION_WITH_STATISTICS_CARD_CLASS_NAME),
          [className],
        );

        const lastOrderPlacedOn = useMemo(
          () =>
            organization.lastOrderByLocation
              .reduce<(string | undefined)[]>((out, location) => [...out, location.orders[0]?.created_at], [])
              .filter((createdAt): createdAt is string => isDef(createdAt))
              .sort((a, b) => b.localeCompare(a))[0],
          [organization.lastOrderByLocation],
        );

        const items = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Active Users',
              text: `${organization.organization_acls_aggregate.aggregate?.count ?? 0}`,
            },
            {
              caption: 'Locations',
              text: `${organization.lastOrderByLocation.length}`,
            },
            {
              caption: 'Onboarded On',
              text: { asDate: 'on', at: organization.created_at },
            },
            {
              caption: 'Last Order Placed',
              text: isDef(lastOrderPlacedOn) ? { asDate: 'ago', at: lastOrderPlacedOn } : 'N/A',
            },
          ],
          // @sort
          [
            lastOrderPlacedOn,
            organization.created_at,
            organization.lastOrderByLocation.length,
            organization.organization_acls_aggregate.aggregate?.count,
          ],
        );

        return (
          <Card.Container {...rest} className={joinedClassName} flush={true} interactive={true} ref={ref}>
            <Card.LinkArea to={to}>
              <Card.Title title={organization.name} />
              <Items items={items} />
            </Card.LinkArea>
          </Card.Container>
        );
      },
    ),
  ),
);

export const OrganizationWithStatisticsCardLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Card.Container flush={true} ref={ref}>
        <Card.TitleLoader />
        <ItemsLoader count={4} />
      </Card.Container>
    ),
  ),
);
