import { Banner } from '@src/components/appearance/fragments/Banner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderBaseGroupCard } from '@src/components/mixins/cards/OrderBaseGroupCard';
import type { TAgentOrderBaseGroupCompleteFragment } from '@src/gen/graphql/bindings';
import type { AgentOrderBaseGroupManager } from '@src/gen/shared/data/agentOrders';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderBaseGroupsStructureGroupBase = {
  beginOrderBaseGroupEdit: TAgentOrderActionsTypes['BeginOrderBaseGroupEdit'] | null;
  beginOrderEntryBaseProductView: TAgentOrderActionsTypes['BeginOrderEntryBaseProductView'];
  beginOrderEntryEdit: TAgentOrderActionsTypes['BeginOrderEntryEdit'];
  orderBaseGroups: TAgentOrderBaseGroupCompleteFragment[];
  orderBaseGroupManagers: AgentOrderBaseGroupManager[];
  isEditable: boolean;
};

export type TOrderBaseGroupsStructureGroup = TProps<false, TOrderBaseGroupsStructureGroupBase, 'div'>;
export const ORDER_BASE_GROUPS_STRUCTURE_GROUP = 'wp-order-base-groups-structure-group';

export const OrderBaseGroupsStructureGroup = withCssToString(
  ORDER_BASE_GROUPS_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderBaseGroupsStructureGroup>(
      (
        {
          beginOrderBaseGroupEdit,
          beginOrderEntryBaseProductView,
          beginOrderEntryEdit,
          orderBaseGroups,
          orderBaseGroupManagers,
          isEditable,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_BASE_GROUPS_STRUCTURE_GROUP),
          [className],
        );

        const activeOrderBaseGroupManagers = useMemo(
          () => orderBaseGroupManagers.filter((obgm) => !obgm.isRemoved()),
          [orderBaseGroupManagers],
        );

        return (
          <Structure.Group {...rest} className={joinedClassName} ref={ref}>
            {activeOrderBaseGroupManagers.length === 0 && (
              <Banner icon='info' message='All products in base orders have been removed.' />
            )}
            {activeOrderBaseGroupManagers.map((obgm) => (
              <OrderBaseGroupCard
                isEditable={isEditable}
                key={obgm.orderBaseGroup.id}
                beginOrderBaseGroupEdit={beginOrderBaseGroupEdit}
                beginOrderEntryBaseProductView={beginOrderEntryBaseProductView}
                beginOrderEntryEdit={beginOrderEntryEdit}
                orderBaseGroupManager={obgm}
                notesUpdater={orderBaseGroups.find((obg) => obg.id === obgm.orderBaseGroup.id)?.notes_updater ?? null}
              />
            ))}
          </Structure.Group>
        );
      },
    ),
  ),
);
