import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EOrderBackOrderDecision {
  WAIT = 'wait',
  REMOVE = 'remove',
}

const ORDER_BACK_ORDER_DECISION_LOOKUP = mapValues(invert(EOrderBackOrderDecision), () => true);

export function getOrderBackOrderDecision(rawOrderBackOrderDecision: string): EOrderBackOrderDecision {
  assert(
    ORDER_BACK_ORDER_DECISION_LOOKUP[rawOrderBackOrderDecision],
    `Unknown order back-order decision: "${rawOrderBackOrderDecision}".`,
  );
  return rawOrderBackOrderDecision as EOrderBackOrderDecision; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetOrderBackOrderDecision(rawOrderBackOrderDecision: string): EOrderBackOrderDecision | undefined {
  try {
    return getOrderBackOrderDecision(rawOrderBackOrderDecision);
  } catch {
    return undefined;
  }
}

export const ORDER_BACK_ORDER_DECISION_LABELS: { [key in EOrderBackOrderDecision]: string } = {
  [EOrderBackOrderDecision.WAIT]: 'Wait for Product',
  [EOrderBackOrderDecision.REMOVE]: 'Remove from Order',
};

export function maybeGetOrderBackOrderDecisionLabel(rawOrderBackOrderDecision: string): string {
  const orderBackOrderDecision = maybeGetOrderBackOrderDecision(rawOrderBackOrderDecision);
  return orderBackOrderDecision !== undefined
    ? ensureDef(ORDER_BACK_ORDER_DECISION_LABELS[orderBackOrderDecision])
    : rawOrderBackOrderDecision;
}
