import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { TextBoxControl } from '@src/components/appearance/controls/TextBox';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TOrderTrackingNumberAddFormValues = {
  trackingNumber: string;
};

export type TOrderTrackingNumberAddFormBase = {
  doOrderTrackingNumberAddAsync: TAgentOrderActionsTypes['DoOrderTrackingNumberAddAsync'];
};

export type TOrderTrackingNumberAddForm = TProps<false, TOrderTrackingNumberAddFormBase, 'div'>;
export const ORDER_TRACKING_NUMBER_ADD_FORM_CLASS_NAME = 'wp-order-tracking-number-add-form';

export const OrderTrackingNumberAddForm = withCssToString(
  ORDER_TRACKING_NUMBER_ADD_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderTrackingNumberAddForm>(
      ({ doOrderTrackingNumberAddAsync, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_TRACKING_NUMBER_ADD_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TOrderTrackingNumberAddFormValues>(
          () => ({
            trackingNumber: '',
          }),
          [],
        );

        const handleSubmit = useCallback<TFormSubmit<TOrderTrackingNumberAddFormValues>>(
          async (values) => {
            await doOrderTrackingNumberAddAsync({ trackingNumber: values.trackingNumber.trim() });
            return undefined;
          },
          [doOrderTrackingNumberAddAsync],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TOrderTrackingNumberAddFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              submitButtonText='Add Tracking Number'>
              <TextBoxControl id='trackingNumber' label='Tracking Number' required={true} />
            </Form>
          </div>
        );
      },
    ),
  ),
);
