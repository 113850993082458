import { Root as RxSeparatorRoot } from '@radix-ui/react-separator';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TTertiaryNav = {
  ItemBase: {
    active: boolean;
    title: string;
  };
  Item: TProps<false, TTertiaryNav['ItemBase'], 'button'>;
};

export const TERTIARY_NAV_CLASS_NAMES = {
  Item: 'wp-tertiary-nav-item',
  AlternateItem: 'wp-tertiary-nav-alternate-item',
};

const Panel = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$tertiaryNavGap',
});

const SItemButton = styled('button', {
  all: 'unset',

  alignItems: 'center',
  borderRadius: '$tertiaryNavItem',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  height: '$tertiaryNavItemHeight',
  paddingX: '$tertiaryNavItemPaddingX',

  '&:focus': {
    boxShadow: '$secondaryFocus',
  },

  variants: {
    active: {
      false: {
        '&:hover': {
          backgroundColor: '$tertiaryNavItemHoverBackground',
        },
      },
      true: {
        backgroundColor: '$tertiaryNavItemActiveBackground',
      },
    },
  },
});

const SItemTypographyCaption = styled(Typography.Caption, {
  color: '$tertiaryNavText',
});

const Item = withCssToString(
  TERTIARY_NAV_CLASS_NAMES.Item,
  memo(
    forwardRef<HTMLButtonElement, TTertiaryNav['Item']>(({ active, title, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TERTIARY_NAV_CLASS_NAMES.Item), [className]);

      return (
        <SItemButton {...rest} active={active} className={joinedClassName} ref={ref} title={title}>
          <SItemTypographyCaption expanding={true} rigid={true} text={title} />
        </SItemButton>
      );
    }),
  ),
);

const SAlternateItemButton = styled('button', {
  all: 'unset',

  alignItems: 'center',
  borderRadius: '$tertiaryNavItem',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  color: '$gray4',
  text: '$caption',

  '&:focus': {
    textDecoration: 'underline',
  },

  variants: {
    active: {
      false: {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      true: {
        color: '$black',
        textDecoration: 'underline',
      },
    },
  },
});

const AlternateItem = withCssToString(
  TERTIARY_NAV_CLASS_NAMES.AlternateItem,
  memo(
    forwardRef<HTMLButtonElement, TTertiaryNav['Item']>(({ active, title, className, ...rest }, ref) => {
      const joinedClassName = useMemo(() => joinClassNames(className, TERTIARY_NAV_CLASS_NAMES.Item), [className]);

      return (
        <SAlternateItemButton {...rest} active={active} className={joinedClassName} ref={ref} title={title}>
          {title}
        </SAlternateItemButton>
      );
    }),
  ),
);

const ItemLoader = memo(
  forwardRef<SVGSVGElement, { width: number }>(({ width }, ref) => (
    <Illustrations.Loader height={32} ref={ref} uniqueKey='wp-drawer-title-loader' width={width}>
      <rect x='0' y='9' rx='0' ry='0' width={width} height='14' />
    </Illustrations.Loader>
  )),
);

const Separator = memo(
  styled(RxSeparatorRoot, {
    backgroundColor: '$separatorBackground',
    flexShrink: 0,
    height: '$separatorThickness',
    marginBottom: '$tertiaryNavSeparatorMarginY',
    marginTop: '$tertiaryNavSeparatorMarginY',
  }),
);

export const TertiaryNav = {
  Item,
  ItemLoader,
  Panel,
  Separator,
  AlternateItem,
};
