import { Typography } from '@src/components/appearance/basics/Typography';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { createContext, forwardRef, memo, useContext, useMemo } from 'react';

export type TControlContext = {
  disabled: boolean;
  invalid: boolean;
};

export type TControlContextResolvedControl = {
  finalDisabled: boolean;
  finalInvalid: boolean;
};

const ControlContext = createContext<TControlContext | undefined>(undefined);
export const useControl = (): TControlContext | undefined => useContext(ControlContext);

export function useResolvedControl({ disabled, invalid }: Partial<TControlContext>): TControlContextResolvedControl {
  const context = useControl();

  return {
    finalDisabled: disabled ?? context?.disabled ?? false,
    finalInvalid: invalid ?? context?.invalid ?? false,
  };
}

const SDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$controlGap',
});

const SFooterDiv = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$controlFooterGap',
});

export type TControlBase = {
  disabled?: boolean | undefined;
  error?: string | null | undefined;
  id: string;
  label?: string | undefined;
  noError?: boolean | undefined;
  required?: boolean | undefined;
  secondaryLabel?: string | undefined;
};

export type TControl = TProps<true, TControlBase, 'div'>;
export const CONTROL_CLASS_NAME = 'wp-control';

export const Control = withCssToString(
  CONTROL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TControl>(
      (
        { disabled, error, id, label, noError, required, secondaryLabel, children, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, CONTROL_CLASS_NAME), [className]);

        const value = useMemo<TControlContext>(
          () => ({
            disabled: disabled ?? false,
            invalid: isDef(error),
          }),
          [error, disabled],
        );

        return (
          <SDiv {...rest} className={joinedClassName} ref={ref}>
            <ControlContext.Provider value={value}>
              {label !== undefined && (
                <Typography.Label expanding={true} htmlFor={id} required={required} rigid={true} text={label} />
              )}
              {children}
              {(noError !== true || secondaryLabel !== undefined) && (
                <SFooterDiv>
                  {noError !== true && <Typography.ErrorCaption error={error} />}
                  {secondaryLabel !== undefined && <Typography.Annotation text={secondaryLabel} />}
                </SFooterDiv>
              )}
            </ControlContext.Provider>
          </SDiv>
        );
      },
    ),
  ),
);
