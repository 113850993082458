import { Banner } from '@src/components/appearance/fragments/Banner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { ORDER_ORIGINAL_CART_ITEMS_BANNER_CLASS_NAME } from '@src/components/mixins/banners/OrderOriginalCartItemsBanner';
import type {
  TAgentOrderCompleteFragment,
  TAgentOrderShipmentBaseFragment,
  TAgentOrderTrackingNumberBaseFragment,
  TCustomerOrderCompleteFragment,
  TCustomerOrderShipmentBaseFragment,
  TCustomerOrderTrackingNumberBaseFragment,
} from '@src/gen/graphql/bindings';
import { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TIcon, TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

export type TOrderStatusBannersBase = {
  order: Pick<
    TAgentOrderCompleteFragment | TCustomerOrderCompleteFragment,
    | 'has_back_ordered_products'
    | 'has_pending_returns'
    | 'is_awaiting_prebill_approval'
    | 'is_blocked_on_customer'
    | 'is_emergency'
    | 'is_paid'
    | 'status'
  > & {
    order_shipments: Pick<TAgentOrderShipmentBaseFragment | TCustomerOrderShipmentBaseFragment, 'id'>[];
  } & {
    order_tracking_numbers: Pick<
      TAgentOrderTrackingNumberBaseFragment | TCustomerOrderTrackingNumberBaseFragment,
      'order_id' | 'tracking_number'
    >[];
  };
  organizationName: string;
};

export type TOrderStatusBanners = TProps<false, TOrderStatusBannersBase, 'div'>;
export const ORDER_STATUS_BANNERS_CLASS_NAME = 'wp-order-status-banners';

export const OrderStatusBanners = withCssToString(
  ORDER_STATUS_BANNERS_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderStatusBanners>(
      ({ order, organizationName, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ORIGINAL_CART_ITEMS_BANNER_CLASS_NAME),
          [className],
        );

        const [icon, accent, message] = useMemo<[TIcon, 'default' | 'success' | 'warning', string]>(
          () => {
            switch (order.status) {
              case 'received':
                return [
                  'inbound',
                  'default',
                  'Wellplaece has received this order and will begin processing it as soon as possible.',
                ];
              case 'preparing':
                return ['processing', 'default', 'Wellplaece is preparing this order.'];
              case 'processing':
                return [
                  'processing',
                  'default',
                  order.order_tracking_numbers.length > 0 || order.order_shipments.length > 0
                    ? 'Wellplaece is processing this order, some items have shipped.'
                    : 'Wellplaece is processing this order.',
                ];
              case 'shipping':
                return ['outbound', 'default', 'All items in this order have shipped.'];
              case 'completed':
                return [
                  'apply',
                  'success',
                  order.is_paid
                    ? 'Wellplaece has completed this order and payment has been processed.'
                    : 'Wellplaece has completed this order.',
                ];
              case 'canceled':
                return ['warning', 'warning', 'This order is canceled.'];
              default:
                return ['question', 'default', 'Please check back later.'];
            }
          },
          // @sort
          [order.is_paid, order.order_shipments.length, order.order_tracking_numbers.length, order.status],
        );

        if (order.status === EOrderStatus.CANCELED) {
          return (
            <Structure.Group {...rest} className={joinedClassName} ref={ref}>
              <Banner icon={icon} accent={accent} message={message} />
            </Structure.Group>
          );
        }

        return (
          <Structure.Group {...rest} className={joinedClassName} ref={ref}>
            <Banner icon={icon} accent={accent} message={message} />
            {(order.is_blocked_on_customer || order.is_awaiting_prebill_approval) && (
              <Banner
                icon='warning'
                accent='warning'
                className={joinedClassName}
                message={`Wellplaece is waiting on ${organizationName} to respond or take action.`}
              />
            )}
            {order.has_back_ordered_products && (
              <Banner
                icon='warning'
                accent='warning'
                className={joinedClassName}
                message='Some products from the original cart are on back order.'
              />
            )}
            {order.has_pending_returns && (
              <Banner
                icon='warning'
                accent='warning'
                className={joinedClassName}
                message='Some products are currently being returned.'
              />
            )}
            {order.is_emergency && order.status !== EOrderStatus.COMPLETED && (
              <Banner icon='warning' accent='error' className={joinedClassName} message='This is an emergency order.' />
            )}
          </Structure.Group>
        );
      },
    ),
  ),
);
