import { ActionButton } from '@src/components/appearance/controls/Button';
import { Chrome } from '@src/components/appearance/structure/Chrome';
import { OrderReturnInstructionsDialogPanel } from '@src/components/mixins/dialogs/OrderReturnInstructionsDialogPanel';
import { OrderSnapshotRejectDialogPanel } from '@src/components/mixins/dialogs/OrderSnapshotRejectDialogPanel';
import { OrderBackOrderProductDrawerPanel } from '@src/components/mixins/drawers/OrderBackOrderProductDrawerPanel';
import { OrderProductDrawerPanel } from '@src/components/mixins/drawers/OrderProductDrawerPanel';
import {
  OrderProductTrackingHistoryDrawerPanel,
  OrderProductTrackingHistoryDrawerPanelLoader,
} from '@src/components/mixins/drawers/OrderProductTrackingHistoryDrawerPanel';
import { OrderReturnAddDrawerPanel } from '@src/components/mixins/drawers/OrderReturnAddDrawerPanel';
import { OrderReturnProductDrawerPanel } from '@src/components/mixins/drawers/OrderReturnProductDrawerPanel';
import { OrderShipmentProductDrawerPanel } from '@src/components/mixins/drawers/OrderShipmentProductDrawerPanel';
import { OrderSnapshotEntryBaseProductDrawerPanel } from '@src/components/mixins/drawers/OrderSnapshotEntryBaseProductDrawerPanel';
import { OrderSnapshotEntryPlanProductDrawerPanel } from '@src/components/mixins/drawers/OrderSnapshotEntryPlanProductDrawerPanel';
import { OrderSnapshotEntryPlanQuantityEditDrawerPanel } from '@src/components/mixins/drawers/OrderSnapshotEntryPlanQuantityEditDrawerPanel';
import { OrderPlacedStructureGroup } from '@src/components/mixins/groups/OrderPlacedStructureGroup';
import { OrderSnapshotStructureGroup } from '@src/components/mixins/groups/OrderSnapshotStructureGroup';
import type {
  CustomerGenerateOrderReturnUrlsMutation,
  CustomerOrderBackOrderReplyMutationVariables,
  CustomerOrderMessageSendMutationVariables,
  CustomerOrderReturnAddMutationVariables,
  CustomerOrderReturnExecuteMutationVariables,
  CustomerOrderSnapshotApproveMutationVariables,
  CustomerOrderSnapshotEntryPlanQuantityEditMutationVariables,
  CustomerOrderSnapshotRejectMutationVariables,
  TCustomerOrderBackOrderBaseFragment,
  TCustomerOrderReturnBaseFragment,
  TCustomerOrderShipmentProductBaseFragment,
} from '@src/gen/graphql/bindings';
import {
  useCustomerGenerateOrderAttachmentUrlMutation,
  useCustomerGenerateOrderReturnUrlsMutation,
  useCustomerGenerateOrderSnapshotExportUrlMutation,
  useCustomerOrderBackOrderReplyMutation,
  useCustomerOrderMessageSendMutation,
  useCustomerOrderReturnAddMutation,
  useCustomerOrderReturnExecuteMutation,
  useCustomerOrderSnapshotApproveMutation,
  useCustomerOrderSnapshotEntryPlanQuantityEditMutation,
  useCustomerOrderSnapshotRejectMutation,
} from '@src/gen/graphql/bindings';
import type { AgentOrderSnapshotEntryManager } from '@src/gen/shared/data/agentOrders';
import type { CustomerOrderSnapshotEntryManager } from '@src/gen/shared/data/customerOrders';
import { EExportFormat } from '@src/gen/shared/enums/exportFormat';
import { maybeGetOrderSnapshotTypeLabel } from '@src/gen/shared/enums/orderSnapshotType';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, downloadUrl } from '@src/logic/internal/utils/utils';
import { useAuthCustomer } from '@src/modules/auth/AuthProvider';
import { useCustomerUploads } from '@src/modules/data/customer/global/uploads/CustomerUploadsProvider';
import {
  CustomerGetTrackerResultProvider,
  useCustomerGetTrackerResult,
} from '@src/modules/data/customer/order/CustomerGetTrackerResultProvider';
import { useCustomerOrder } from '@src/modules/data/customer/order/CustomerOrderProvider';
import { useChrome } from '@src/modules/design/ChromeProvider';
import { useDialog } from '@src/modules/design/DialogProvider';
import { useDrawer, useTopDrawer } from '@src/modules/design/DrawerProvider';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import { useCustomerOrderRouteParams, useCustomerRouteOrganization } from '@src/modules/routing/customer';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TCustomerOrderActionsTypes = {
  BeginOrderBackOrderProductView: (args: { orderBackOrder: TCustomerOrderBackOrderBaseFragment }) => void;
  BeginOrderOriginalCartView: () => void;
  BeginOrderProductView: (args: { productId: string }) => void;
  BeginOrderReturnAdd: () => void;
  BeginOrderReturnProductView: (args: { orderReturn: TCustomerOrderReturnBaseFragment }) => void;
  BeginOrderReturnSeeInstructionsAsyncNotify: (args: { orderReturnId: string }) => Promise<void>;
  BeginOrderShipmentProductView: (args: { orderShipmentProduct: TCustomerOrderShipmentProductBaseFragment }) => void;
  BeginOrderSnapshotEntryView: (args: {
    type: 'base' | 'plan';
    orderSnapshotEntryManager: AgentOrderSnapshotEntryManager | CustomerOrderSnapshotEntryManager;
  }) => void;
  BeginOrderSnapshotEntryPlanQuantityEdit: (args: {
    orderSnapshotEntryManager: CustomerOrderSnapshotEntryManager;
  }) => void;
  BeginOrderSnapshotReject: (args: { orderSnapshotId: string }) => void;
  BeginOrderSnapshotView: (args: { orderSnapshotId: string }) => void;
  BeginOrderTrackingHistoryView: (args: { ship24TrackerId: string }) => void;
  DoOrderBackOrderReplyAsyncNotify: (args: CustomerOrderBackOrderReplyMutationVariables) => Promise<void>;
  DoOrderMessageSendAsync: (args: Omit<CustomerOrderMessageSendMutationVariables, 'orderId'>) => Promise<void>;
  DoOrderReturnAddAsync: (args: CustomerOrderReturnAddMutationVariables) => Promise<void>;
  DoOrderReturnExecuteAsyncNotify: (args: CustomerOrderReturnExecuteMutationVariables) => Promise<void>;
  DoOrderSnapshotApproveAsyncNotify: (args: CustomerOrderSnapshotApproveMutationVariables) => Promise<void>;
  DoOrderSnapshotRejectAsync: (args: CustomerOrderSnapshotRejectMutationVariables) => Promise<void>;
  DoOrderSnapshotEntryPlanQuantityEditAsync: (
    args: CustomerOrderSnapshotEntryPlanQuantityEditMutationVariables,
  ) => Promise<void>;
  DownloadOrderAttachmentAsyncNotify: (args: { uploadId: string }) => Promise<void>;
  DownloadOrderSnapshotAsyncNotify: (args: { orderSnapshotId: string; exportFormat: EExportFormat }) => Promise<void>;
};

export type TCustomerOrderActionsContext = {
  beginOrderBackOrderProductView: TCustomerOrderActionsTypes['BeginOrderBackOrderProductView'];
  beginOrderOriginalCartView: TCustomerOrderActionsTypes['BeginOrderOriginalCartView'];
  beginOrderProductView: TCustomerOrderActionsTypes['BeginOrderProductView'];
  beginOrderReturnAdd: TCustomerOrderActionsTypes['BeginOrderReturnAdd'] | null;
  beginOrderReturnProductView: TCustomerOrderActionsTypes['BeginOrderReturnProductView'];
  beginOrderReturnSeeInstructionsAsyncNotify: TCustomerOrderActionsTypes['BeginOrderReturnSeeInstructionsAsyncNotify'];
  beginOrderShipmentProductView: TCustomerOrderActionsTypes['BeginOrderShipmentProductView'];
  beginOrderSnapshotEntryView: TCustomerOrderActionsTypes['BeginOrderSnapshotEntryView'];
  beginOrderSnapshotEntryPlanQuantityEdit: TCustomerOrderActionsTypes['BeginOrderSnapshotEntryPlanQuantityEdit'] | null;
  beginOrderSnapshotReject: TCustomerOrderActionsTypes['BeginOrderSnapshotReject'] | null;
  beginOrderSnapshotView: TCustomerOrderActionsTypes['BeginOrderSnapshotView'];
  beginOrderTrackingHistoryView: TCustomerOrderActionsTypes['BeginOrderTrackingHistoryView'];
  doOrderBackOrderReplyAsyncNotify: TCustomerOrderActionsTypes['DoOrderBackOrderReplyAsyncNotify'] | null;
  doOrderMessageSendAsync: TCustomerOrderActionsTypes['DoOrderMessageSendAsync'] | undefined;
  doOrderReturnExecuteAsyncNotify: TCustomerOrderActionsTypes['DoOrderReturnExecuteAsyncNotify'] | null;
  doOrderSnapshotApproveAsyncNotify: TCustomerOrderActionsTypes['DoOrderSnapshotApproveAsyncNotify'] | null;
  doOrderSnapshotEntryPlanQuantityEditAsync:
    | TCustomerOrderActionsTypes['DoOrderSnapshotEntryPlanQuantityEditAsync']
    | null;
  downloadOrderAttachmentAsyncNotify: TCustomerOrderActionsTypes['DownloadOrderAttachmentAsyncNotify'];
  downloadOrderSnapshotAsyncNotify: TCustomerOrderActionsTypes['DownloadOrderSnapshotAsyncNotify'];
};

export const { Context: CustomerOrderActionsContext, useContext: useCustomerOrderActions } =
  createRequiredContext<TCustomerOrderActionsContext>();

export function CustomerOrderActionsProvider({ children }: PropsWithChildren): JSX.Element {
  const customer = useAuthCustomer();
  const organization = useCustomerRouteOrganization();
  const { order, orderManager } = useCustomerOrder();

  const beginOrderBackOrderProductView = useBeginOrderBackOrderProductView();
  const beginOrderOriginalCartView = useBeginOrderOriginalCartView();
  const beginOrderProductView = useBeginOrderProductView();
  const beginOrderReturnAdd = useBeginOrderReturnAdd();
  const beginOrderReturnProductView = useBeginOrderReturnProductView();
  const beginOrderReturnSeeInstructionsAsyncNotify = useBeginOrderReturnSeeInstructionsAsyncNotify();
  const beginOrderShipmentProductView = useBeginOrderShipmentProductView();
  const beginOrderSnapshotEntryView = useBeginOrderSnapshotEntryView();
  const beginOrderSnapshotEntryPlanQuantityEdit = useBeginOrderSnapshotEntryPlanQuantityEdit();
  const beginOrderSnapshotReject = useBeginOrderSnapshotReject();
  const beginOrderSnapshotView = useBeginOrderSnapshotView();
  const beginOrderTrackingHistoryView = useBeginOrderTrackingHistoryView();
  const doOrderBackOrderReplyAsyncNotify = useDoOrderBackOrderReplyAsyncNotify();
  const doOrderMessageSendAsync = useDoOrderMessageSendAsync();
  const doOrderReturnExecuteAsyncNotify = useDoOrderReturnExecuteAsyncNotify();
  const doOrderSnapshotApproveAsyncNotify = useDoOrderSnapshotApproveAsyncNotify();
  const doOrderSnapshotEntryPlanQuantityEditAsync = useDoOrderSnapshotEntryPlanQuantityEditAsync();
  const downloadOrderAttachmentAsyncNotify = useDownloadOrderAttachmentAsyncNotify();
  const downloadOrderSnapshotAsyncNotify = useDownloadOrderSnapshotAsyncNotify();

  const canSendOrderMessages = useMemo(
    () =>
      order.created_by === customer.id ||
      organization.locations.find((loc) => loc.id === order.location_id)?.acl.can_send_order_messages === true,
    // @sort
    [customer.id, order.created_by, order.location_id, organization.locations],
  );

  const canPlaceOrders = useMemo(
    () => organization.locations.find((loc) => loc.id === order.location_id)?.acl.can_place_orders === true,
    // @sort
    [order.location_id, organization.locations],
  );

  const canApproveOrders = useMemo(
    () => organization.locations.find((loc) => loc.id === order.location_id)?.acl.can_approve_orders === true,
    // @sort
    [order.location_id, organization.locations],
  );

  const canAddReturns = useMemo(
    () => canPlaceOrders && isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest()),
    // @sort
    [canPlaceOrders, orderManager],
  );

  const value = useMemo<TCustomerOrderActionsContext>(
    () => ({
      beginOrderBackOrderProductView,
      beginOrderOriginalCartView,
      beginOrderProductView,
      beginOrderReturnAdd: canAddReturns ? beginOrderReturnAdd : null,
      beginOrderReturnProductView,
      beginOrderReturnSeeInstructionsAsyncNotify,
      beginOrderShipmentProductView,
      beginOrderSnapshotEntryView,
      beginOrderSnapshotEntryPlanQuantityEdit: canApproveOrders ? beginOrderSnapshotEntryPlanQuantityEdit : null,
      beginOrderSnapshotReject: canApproveOrders ? beginOrderSnapshotReject : null,
      beginOrderSnapshotView,
      beginOrderTrackingHistoryView,
      doOrderBackOrderReplyAsyncNotify: canPlaceOrders ? doOrderBackOrderReplyAsyncNotify : null,
      doOrderMessageSendAsync: canSendOrderMessages ? doOrderMessageSendAsync : undefined,
      doOrderReturnExecuteAsyncNotify: canPlaceOrders ? doOrderReturnExecuteAsyncNotify : null,
      doOrderSnapshotApproveAsyncNotify: canApproveOrders ? doOrderSnapshotApproveAsyncNotify : null,
      doOrderSnapshotEntryPlanQuantityEditAsync: canApproveOrders ? doOrderSnapshotEntryPlanQuantityEditAsync : null,
      downloadOrderAttachmentAsyncNotify,
      downloadOrderSnapshotAsyncNotify,
    }),
    // @sort
    [
      beginOrderBackOrderProductView,
      beginOrderOriginalCartView,
      beginOrderProductView,
      beginOrderReturnAdd,
      beginOrderReturnProductView,
      beginOrderReturnSeeInstructionsAsyncNotify,
      beginOrderShipmentProductView,
      beginOrderSnapshotEntryPlanQuantityEdit,
      beginOrderSnapshotEntryView,
      beginOrderSnapshotReject,
      beginOrderSnapshotView,
      beginOrderTrackingHistoryView,
      canAddReturns,
      canApproveOrders,
      canPlaceOrders,
      canSendOrderMessages,
      doOrderBackOrderReplyAsyncNotify,
      doOrderMessageSendAsync,
      doOrderReturnExecuteAsyncNotify,
      doOrderSnapshotApproveAsyncNotify,
      doOrderSnapshotEntryPlanQuantityEditAsync,
      downloadOrderAttachmentAsyncNotify,
      downloadOrderSnapshotAsyncNotify,
    ],
  );

  return <CustomerOrderActionsContext.Provider value={value}>{children}</CustomerOrderActionsContext.Provider>;
}

function useBeginOrderBackOrderProductView(): TCustomerOrderActionsTypes['BeginOrderBackOrderProductView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TCustomerOrderActionsTypes['BeginOrderBackOrderProductView']>(
    ({ orderBackOrder }) => {
      doTopDrawerOpen(<OrderBackOrderProductDrawerPanel orderBackOrder={orderBackOrder} />);
    },
    [doTopDrawerOpen],
  );
}

function useBeginOrderOriginalCartView(): TCustomerOrderActionsTypes['BeginOrderOriginalCartView'] {
  const { doChromeOpen } = useChrome();
  const { order } = useCustomerOrder();
  const { doTopDrawerOpen } = useTopDrawer();

  const beginOrderProductView = useCallback<TCustomerOrderActionsTypes['BeginOrderProductView']>(
    (args) => {
      const orderProduct = ensureDef(order.order_products.find((op) => op.product_id === args.productId));
      doTopDrawerOpen(<OrderProductDrawerPanel orderProduct={orderProduct} />);
    },
    // @sort
    [doTopDrawerOpen, order.order_products],
  );

  return useCallback<TCustomerOrderActionsTypes['BeginOrderOriginalCartView']>(
    async (): Promise<void> => {
      doChromeOpen(
        <Chrome.Panel>
          <Chrome.Header title='Original Cart' />
          <Chrome.ScrollContent>
            <OrderPlacedStructureGroup beginOrderProductView={beginOrderProductView} order={order} />
          </Chrome.ScrollContent>
        </Chrome.Panel>,
      );
    },
    // @sort
    [beginOrderProductView, doChromeOpen, order],
  );
}

function useBeginOrderProductView(): TCustomerOrderActionsTypes['BeginOrderProductView'] {
  const { order } = useCustomerOrder();
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TCustomerOrderActionsTypes['BeginOrderProductView']>(
    (args) => {
      const orderProduct = ensureDef(order.order_products.find((op) => op.product_id === args.productId));
      doTopDrawerOpen(<OrderProductDrawerPanel orderProduct={orderProduct} />);
    },
    // @sort
    [doTopDrawerOpen, order.order_products],
  );
}

function useBeginOrderReturnAdd(): TCustomerOrderActionsTypes['BeginOrderReturnAdd'] {
  const [orderReturnAddMutation] = useCustomerOrderReturnAddMutation();
  const { doDrawerOpen, doDrawerClose } = useDrawer();
  const { doUploadAsync } = useCustomerUploads();
  const { serializationKey, orderManager } = useCustomerOrder();

  const doOrderReturnAddAsync = useCallback<TCustomerOrderActionsTypes['DoOrderReturnAddAsync']>(
    async (args) => {
      const { errors } = await orderReturnAddMutation({
        variables: {
          ...args,
          reason: args.reason.replaceAll(/\n\n\n+/g, '\n\n').trim(),
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDrawerClose();
    },
    // @sort
    [doDrawerClose, orderReturnAddMutation, serializationKey],
  );

  return useCallback<TCustomerOrderActionsTypes['BeginOrderReturnAdd']>(
    async (): Promise<void> => {
      doDrawerOpen(
        <OrderReturnAddDrawerPanel
          doUploadAsync={doUploadAsync}
          doOrderReturnAddAsync={doOrderReturnAddAsync}
          orderSnapshotEntryManagers={
            ensureDef(orderManager.maybeGetOrderSnapshotInvoiceLatest()).orderSnapshotEntryManagers
          }
        />,
      );
    },
    // @sort
    [doDrawerOpen, doOrderReturnAddAsync, doUploadAsync, orderManager],
  );
}

function useBeginOrderReturnProductView(): TCustomerOrderActionsTypes['BeginOrderReturnProductView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TCustomerOrderActionsTypes['BeginOrderReturnProductView']>(
    ({ orderReturn }) => {
      doTopDrawerOpen(<OrderReturnProductDrawerPanel orderReturn={orderReturn} />);
    },
    [doTopDrawerOpen],
  );
}

function useBeginOrderReturnSeeInstructionsAsyncNotify(): TCustomerOrderActionsTypes['BeginOrderReturnSeeInstructionsAsyncNotify'] {
  const [generateOrderReturnUrlsMutation] = useCustomerGenerateOrderReturnUrlsMutation();
  const { doDialogOpen, doDialogClose } = useDialog();
  const { doErrorClear, doErrorNotify } = useErrors();
  const { order, serializationKey } = useCustomerOrder();

  const doGenerateOrderReturnUrlsAsyncNotify = useCallback<
    (args: {
      orderReturnId: string;
    }) => Promise<CustomerGenerateOrderReturnUrlsMutation['generateOrderReturnUrls'] | null>
  >(
    async (args) => {
      try {
        doErrorClear();

        const { errors, data } = await generateOrderReturnUrlsMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        return ensureDef(data).generateOrderReturnUrls;
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While preparing return instructions.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
        return null;
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderReturnUrlsMutation, serializationKey],
  );

  return useCallback<TCustomerOrderActionsTypes['BeginOrderReturnSeeInstructionsAsyncNotify']>(
    async ({ orderReturnId }): Promise<void> => {
      const maybeUrls = await doGenerateOrderReturnUrlsAsyncNotify({
        orderReturnId,
      });

      if (!isDef(maybeUrls)) {
        return;
      }

      const orderReturn = ensureDef(order.order_returns.find((or) => or.id === orderReturnId));
      const repliedShippingLabelUrl = maybeUrls.repliedShippingLabelUrl;

      doDialogOpen(
        <OrderReturnInstructionsDialogPanel
          downloadShippingLabel={
            isDef(repliedShippingLabelUrl) ? (): void => downloadUrl(repliedShippingLabelUrl) : null
          }
          instructions={ensureDef(orderReturn.replied_instructions)}
          onClose={doDialogClose}
        />,
      );
    },
    // @sort
    [doDialogClose, doDialogOpen, doGenerateOrderReturnUrlsAsyncNotify, order.order_returns],
  );
}

function useBeginOrderShipmentProductView(): TCustomerOrderActionsTypes['BeginOrderShipmentProductView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TCustomerOrderActionsTypes['BeginOrderShipmentProductView']>(
    ({ orderShipmentProduct }) => {
      doTopDrawerOpen(<OrderShipmentProductDrawerPanel orderShipmentProduct={orderShipmentProduct} />);
    },
    [doTopDrawerOpen],
  );
}

function useBeginOrderSnapshotEntryView(): TCustomerOrderActionsTypes['BeginOrderSnapshotEntryView'] {
  const { doTopDrawerOpen } = useTopDrawer();

  return useCallback<TCustomerOrderActionsTypes['BeginOrderSnapshotEntryView']>(
    ({ type, orderSnapshotEntryManager }) => {
      doTopDrawerOpen(
        type === 'base' ? (
          <OrderSnapshotEntryBaseProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ) : (
          <OrderSnapshotEntryPlanProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ),
      );
    },
    [doTopDrawerOpen],
  );
}

function useBeginOrderSnapshotEntryPlanQuantityEdit(): TCustomerOrderActionsTypes['BeginOrderSnapshotEntryPlanQuantityEdit'] {
  const { doTopDrawerOpen, doTopDrawerClose } = useTopDrawer();
  const [orderSnapshotEntryPlanQuantityEditMutation] = useCustomerOrderSnapshotEntryPlanQuantityEditMutation();
  const { serializationKey } = useCustomerOrder();

  const doOrderSnapshotEntryPlanQuantityEditAsync = useCallback<
    TCustomerOrderActionsTypes['DoOrderSnapshotEntryPlanQuantityEditAsync']
  >(
    async (args) => {
      const { errors } = await orderSnapshotEntryPlanQuantityEditMutation({
        variables: {
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doTopDrawerClose();
    },
    // @sort
    [doTopDrawerClose, orderSnapshotEntryPlanQuantityEditMutation, serializationKey],
  );

  return useCallback<TCustomerOrderActionsTypes['BeginOrderSnapshotEntryPlanQuantityEdit']>(
    ({ orderSnapshotEntryManager }) => {
      doTopDrawerOpen(
        <OrderSnapshotEntryPlanQuantityEditDrawerPanel
          doOrderSnapshotEntryPlanQuantityEditAsync={doOrderSnapshotEntryPlanQuantityEditAsync}
          orderSnapshotEntryManager={orderSnapshotEntryManager}
        />,
      );
    },
    [doOrderSnapshotEntryPlanQuantityEditAsync, doTopDrawerOpen],
  );
}

function useBeginOrderSnapshotReject(): TCustomerOrderActionsTypes['BeginOrderSnapshotReject'] {
  const [orderSnapshotRejectMutation] = useCustomerOrderSnapshotRejectMutation();
  const { doDialogOpen, doDialogClose } = useDialog();
  const { serializationKey } = useCustomerOrder();

  const doOrderSnapshotRejectAsync = useCallback<TCustomerOrderActionsTypes['DoOrderSnapshotRejectAsync']>(
    async (args) => {
      const { errors } = await orderSnapshotRejectMutation({
        variables: {
          orderSnapshotId: args.orderSnapshotId,
          rejectedReason: args.rejectedReason.replaceAll(/\n\n\n+/g, '\n\n').trim(),
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }

      doDialogClose();
    },
    // @sort
    [doDialogClose, orderSnapshotRejectMutation, serializationKey],
  );

  return useCallback<TCustomerOrderActionsTypes['BeginOrderSnapshotReject']>(
    async ({ orderSnapshotId }): Promise<void> => {
      doDialogOpen(
        <OrderSnapshotRejectDialogPanel
          doOrderSnapshotRejectAsync={doOrderSnapshotRejectAsync}
          orderSnapshotId={orderSnapshotId}
        />,
      );
    },
    // @sort
    [doDialogOpen, doOrderSnapshotRejectAsync],
  );
}

function useBeginOrderSnapshotView(): TCustomerOrderActionsTypes['BeginOrderSnapshotView'] {
  const [generateOrderSnapshotExportUrlMutation] = useCustomerGenerateOrderSnapshotExportUrlMutation();
  const { doChromeOpen } = useChrome();
  const { doErrorNotify, doErrorClear } = useErrors();
  const { doTopDrawerOpen } = useTopDrawer();
  const { orderManager } = useCustomerOrder();

  const beginOrderSnapshotEntryView = useCallback<TCustomerOrderActionsTypes['BeginOrderSnapshotEntryView']>(
    ({ type, orderSnapshotEntryManager }) => {
      doTopDrawerOpen(
        type === 'base' ? (
          <OrderSnapshotEntryBaseProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ) : (
          <OrderSnapshotEntryPlanProductDrawerPanel orderSnapshotEntryManager={orderSnapshotEntryManager} />
        ),
      );
    },
    [doTopDrawerOpen],
  );

  const downloadOrderSnapshotAsyncNotify = useCallback<TCustomerOrderActionsTypes['DownloadOrderSnapshotAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderSnapshotExportUrlMutation({
          variables: {
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderSnapshotExportUrl.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderSnapshotExportUrlMutation],
  );

  return useCallback<TCustomerOrderActionsTypes['BeginOrderSnapshotView']>(
    async ({ orderSnapshotId }): Promise<void> => {
      const orderSnapshotManager = orderManager.mustGetOrderSnapshot(orderSnapshotId);

      doChromeOpen(
        <Chrome.Panel>
          <Chrome.Header title={maybeGetOrderSnapshotTypeLabel(orderSnapshotManager.orderSnapshot.type)} />
          <Chrome.ScrollContent>
            <OrderSnapshotStructureGroup
              beginOrderSnapshotEntryPlanQuantityEdit={null}
              beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
              isSummary={false}
              orderBudgetAssistantManager={null}
              orderSnapshotManager={orderSnapshotManager}
            />
          </Chrome.ScrollContent>
          {!orderSnapshotManager.isEditedByCustomer() && (
            <Chrome.FooterToolBar>
              <ActionButton
                action={{
                  isAsync: true,
                  onClick: async (): Promise<void> =>
                    await downloadOrderSnapshotAsyncNotify({ orderSnapshotId, exportFormat: EExportFormat.XLSX }),
                  text: 'Download XLSX',
                }}
              />
              <ActionButton
                action={{
                  isAsync: true,
                  onClick: async (): Promise<void> =>
                    await downloadOrderSnapshotAsyncNotify({ orderSnapshotId, exportFormat: EExportFormat.PDF }),
                  text: 'Download PDF',
                }}
              />
            </Chrome.FooterToolBar>
          )}
        </Chrome.Panel>,
      );
    },
    // @sort
    [beginOrderSnapshotEntryView, doChromeOpen, downloadOrderSnapshotAsyncNotify, orderManager],
  );
}

function useBeginOrderTrackingHistoryView(): TCustomerOrderActionsTypes['BeginOrderTrackingHistoryView'] {
  const { doDrawerOpen } = useDrawer();

  return useCallback<TCustomerOrderActionsTypes['BeginOrderTrackingHistoryView']>(
    (args) => {
      doDrawerOpen(
        <CustomerGetTrackerResultProvider
          LoaderComponent={OrderProductTrackingHistoryDrawerPanelLoader}
          ship24TrackerId={args.ship24TrackerId}>
          <BeginOrderTrackingHistoryViewConsumer />
        </CustomerGetTrackerResultProvider>,
      );
    },
    // @sort
    [doDrawerOpen],
  );
}

function BeginOrderTrackingHistoryViewConsumer(): JSX.Element {
  const { trackerResult, error } = useCustomerGetTrackerResult();
  return <OrderProductTrackingHistoryDrawerPanel trackerResult={trackerResult} error={error} />;
}

function useDoOrderBackOrderReplyAsyncNotify(): TCustomerOrderActionsTypes['DoOrderBackOrderReplyAsyncNotify'] {
  const [orderBackOrderReplyMutation] = useCustomerOrderBackOrderReplyMutation();
  const { serializationKey } = useCustomerOrder();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TCustomerOrderActionsTypes['DoOrderBackOrderReplyAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderBackOrderReplyMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While applying decision to back-order.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderBackOrderReplyMutation, serializationKey],
  );
}

function useDoOrderMessageSendAsync(): TCustomerOrderActionsTypes['DoOrderMessageSendAsync'] {
  const [orderMessageSendMutation] = useCustomerOrderMessageSendMutation();
  const routeParams = useCustomerOrderRouteParams();
  const { serializationKey } = useCustomerOrder();

  return useCallback<TCustomerOrderActionsTypes['DoOrderMessageSendAsync']>(
    async (args) => {
      const { errors } = await orderMessageSendMutation({
        variables: {
          orderId: routeParams.orderId,
          message: args.message.replaceAll(/\n\n\n+/g, '\n\n').trim(),
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }
    },
    // @sort
    [orderMessageSendMutation, serializationKey, routeParams.orderId],
  );
}

function useDoOrderReturnExecuteAsyncNotify(): TCustomerOrderActionsTypes['DoOrderReturnExecuteAsyncNotify'] {
  const [orderReturnExecuteMutation] = useCustomerOrderReturnExecuteMutation();
  const { serializationKey } = useCustomerOrder();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TCustomerOrderActionsTypes['DoOrderReturnExecuteAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderReturnExecuteMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While reporting return shipped.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderReturnExecuteMutation, serializationKey],
  );
}

function useDoOrderSnapshotApproveAsyncNotify(): TCustomerOrderActionsTypes['DoOrderSnapshotApproveAsyncNotify'] {
  const [orderSnapshotApproveMutation] = useCustomerOrderSnapshotApproveMutation();
  const { serializationKey } = useCustomerOrder();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TCustomerOrderActionsTypes['DoOrderSnapshotApproveAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { errors } = await orderSnapshotApproveMutation({
          variables: {
            ...args,
          },
          context: {
            serializationKey,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While approving prebill.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, orderSnapshotApproveMutation, serializationKey],
  );
}

function useDoOrderSnapshotEntryPlanQuantityEditAsync(): TCustomerOrderActionsTypes['DoOrderSnapshotEntryPlanQuantityEditAsync'] {
  const [orderSnapshotEntryPlanQuantityEditMutation] = useCustomerOrderSnapshotEntryPlanQuantityEditMutation();
  const { serializationKey } = useCustomerOrder();

  return useCallback<TCustomerOrderActionsTypes['DoOrderSnapshotEntryPlanQuantityEditAsync']>(
    async (args) => {
      const { errors } = await orderSnapshotEntryPlanQuantityEditMutation({
        variables: {
          ...args,
        },
        context: {
          serializationKey,
        },
      });

      if (isDef(errors)) {
        throw errors;
      }
    },
    // @sort
    [orderSnapshotEntryPlanQuantityEditMutation, serializationKey],
  );
}

function useDownloadOrderAttachmentAsyncNotify(): TCustomerOrderActionsTypes['DownloadOrderAttachmentAsyncNotify'] {
  const [generateOrderAttachmentUrlMutation] = useCustomerGenerateOrderAttachmentUrlMutation();
  const routeParams = useCustomerOrderRouteParams();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TCustomerOrderActionsTypes['DownloadOrderAttachmentAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderAttachmentUrlMutation({
          variables: {
            orderId: routeParams.orderId,
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderAttachmentUrl?.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderAttachmentUrlMutation, routeParams.orderId],
  );
}

function useDownloadOrderSnapshotAsyncNotify(): TCustomerOrderActionsTypes['DownloadOrderSnapshotAsyncNotify'] {
  const [generateOrderSnapshotExportUrlMutation] = useCustomerGenerateOrderSnapshotExportUrlMutation();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TCustomerOrderActionsTypes['DownloadOrderSnapshotAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderSnapshotExportUrlMutation({
          variables: {
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderSnapshotExportUrl.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderSnapshotExportUrlMutation],
  );
}
