import { OrderSnapshotReviewChromePanel } from '@src/components/mixins/chromes/OrderSnapshotReviewChromePanel';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import { useCustomerOrderActions } from '@src/modules/data/customer/order/CustomerOrderActionsProvider';
import { useCustomerOrderBudgetAssistant } from '@src/modules/data/customer/order/CustomerOrderBudgetAssistantProvider';
import { useCustomerOrder } from '@src/modules/data/customer/order/CustomerOrderProvider';
import { InlineChrome, useInlineChrome } from '@src/modules/design/ChromeProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import assert from 'assert';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TCustomerOrderSnapshotReviewChromeTypes = {
  BeginOrderSnapshotReviewArgs: {
    orderSnapshotId: string;
  };
  BeginOrderSnapshotReview: (args: TCustomerOrderSnapshotReviewChromeTypes['BeginOrderSnapshotReviewArgs']) => void;
};

export type TCustomerOrderSnapshotReviewChromeContext = {
  beginOrderSnapshotReview: TCustomerOrderSnapshotReviewChromeTypes['BeginOrderSnapshotReview'];
};

export const { Context: CustomerOrderSnapshotReviewChromeContext, useContext: useCustomerOrderSnapshotReviewChrome } =
  createRequiredContext<TCustomerOrderSnapshotReviewChromeContext>();

export function CustomerOrderSnapshotReviewChromeProvider({ children }: PropsWithChildren<TEmptyObject>): JSX.Element {
  const inlineChrome = useInlineChrome<TCustomerOrderSnapshotReviewChromeTypes['BeginOrderSnapshotReviewArgs']>();

  const beginOrderSnapshotReview = useCallback<TCustomerOrderSnapshotReviewChromeTypes['BeginOrderSnapshotReview']>(
    (args) => {
      inlineChrome.doChromeOpen({
        metadata: args,
        drawerChildren: <Panel orderSnapshotId={args.orderSnapshotId} />,
      });
    },
    [inlineChrome],
  );

  const value = useMemo<TCustomerOrderSnapshotReviewChromeContext>(
    () => ({
      beginOrderSnapshotReview,
    }),
    [beginOrderSnapshotReview],
  );

  return (
    <CustomerOrderSnapshotReviewChromeContext.Provider value={value}>
      <InlineChrome {...inlineChrome} />
      {children}
    </CustomerOrderSnapshotReviewChromeContext.Provider>
  );
}

type TPanel = {
  orderSnapshotId: string;
};

function Panel({ orderSnapshotId }: TPanel): JSX.Element {
  const { orderManager } = useCustomerOrder();
  const {
    beginOrderSnapshotEntryPlanQuantityEdit,
    beginOrderSnapshotEntryView,
    beginOrderSnapshotReject,
    doOrderSnapshotApproveAsyncNotify,
  } = useCustomerOrderActions();
  const { orderBudgetAssistantManager } = useCustomerOrderBudgetAssistant();

  const orderSnapshotManager = orderManager.mustGetOrderSnapshot(orderSnapshotId);

  assert(
    orderSnapshotManager.orderSnapshot.type === 'prebill' &&
      !isDef(orderSnapshotManager.orderSnapshot.approved_at) &&
      !isDef(orderSnapshotManager.orderSnapshot.rejected_at),
  );

  return (
    <OrderSnapshotReviewChromePanel
      beginOrderSnapshotEntryPlanQuantityEdit={beginOrderSnapshotEntryPlanQuantityEdit}
      beginOrderSnapshotEntryView={beginOrderSnapshotEntryView}
      beginOrderSnapshotReject={beginOrderSnapshotReject}
      doOrderSnapshotApproveAsyncNotify={doOrderSnapshotApproveAsyncNotify}
      orderBudgetAssistantManager={orderBudgetAssistantManager}
      orderSnapshotManager={orderSnapshotManager}
    />
  );
}
