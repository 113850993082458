import type { TButtonAction } from '@src/components/appearance/controls/Button';
import { Control } from '@src/components/appearance/controls/Control';
import { ControlButton } from '@src/components/appearance/controls/ControlButton';
import type { TRawDatePicker } from '@src/components/appearance/controls/RawDatePicker';
import { RawDatePicker } from '@src/components/appearance/controls/RawDatePicker';
import { Card } from '@src/components/appearance/fragments/Card';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import {
  getDateRangeLastNDays,
  getDateRangeMonthToDate,
  getDateRangeQuarterToDate,
  getDateRangeYearToDate,
} from '@src/gen/shared/data/dateRanges';
import { mustParseDatePickerDate } from '@src/gen/shared/utils/converters';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { newDatePickerValidator } from '@src/logic/internal/data/validation';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TDateRangePickerDrawerTypes } from '@src/modules/data/shared/overlays/DateRangePickerDrawerProvider';
import type { TProps } from '@src/modules/design/theme';
import type { Dispatch, SetStateAction } from 'react';
import { Fragment, forwardRef, memo, useCallback, useMemo, useState } from 'react';

export type TDateRangePickerDrawerPanelBase = {
  callback: TDateRangePickerDrawerTypes['BeginLocationPickCallback'];
};

type TDateRangePickerDrawerPanelState = {
  selectedMethod: 'afterDate' | 'beforeDate' | 'betweenDates' | 'last30d' | 'last90d' | 'mtd' | 'qtd' | 'ytd';
  afterDate: { startDate: string } | null;
  beforeDate: { endDate: string } | null;
  betweenDates: { startDate: string; endDate: string } | null;
};

export type TDateRangePickerDrawerPanel = TProps<false, TDateRangePickerDrawerPanelBase, 'div'>;
export const DATE_RANGE_PICKER_DRAWER_PANEL_CLASS_NAME = 'wp-date-range-picker-drawer-panel';

export const DateRangePickerDrawerPanel = withCssToString(
  DATE_RANGE_PICKER_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TDateRangePickerDrawerPanel>(({ callback, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(
        () => joinClassNames(className, DATE_RANGE_PICKER_DRAWER_PANEL_CLASS_NAME),
        [className],
      );

      const [state, setState] = useState<TDateRangePickerDrawerPanelState>({
        selectedMethod: 'mtd',
        afterDate: null,
        beforeDate: null,
        betweenDates: null,
      });

      return (
        <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
          <Drawer.Header title='Select Date Range' />
          {!isDef(state.beforeDate) && !isDef(state.afterDate) && !isDef(state.betweenDates) && (
            <MethodPhase state={state} setState={setState} callback={callback} />
          )}
          {isDef(state.beforeDate) && <BeforeDatePhase state={state} setState={setState} callback={callback} />}
          {isDef(state.afterDate) && <AfterDatePhase state={state} setState={setState} callback={callback} />}
          {isDef(state.betweenDates) && <BetweenDatesPhase state={state} setState={setState} callback={callback} />}
        </Drawer.Panel>
      );
    }),
  ),
);

function MethodPhase({
  state,
  setState,
  callback,
}: {
  state: TDateRangePickerDrawerPanelState;
  setState: Dispatch<SetStateAction<TDateRangePickerDrawerPanelState>>;
  callback: TDateRangePickerDrawerTypes['BeginLocationPickCallback'];
}): JSX.Element {
  const action = useMemo<TButtonAction>(
    () => ({
      isAsync: false,
      text: ['mtd', 'qtd', 'ytd', 'last30d', 'last90d'].includes(state.selectedMethod) ? 'Apply' : 'Continue',
      onClick: (): void => {
        switch (state.selectedMethod) {
          case 'mtd':
            return callback(getDateRangeMonthToDate());
          case 'qtd':
            return callback(getDateRangeQuarterToDate());
          case 'ytd':
            return callback(getDateRangeYearToDate());
          case 'last30d':
            return callback(getDateRangeLastNDays(30));
          case 'last90d':
            return callback(getDateRangeLastNDays(90));
          case 'afterDate':
            setState((prevState) => ({
              ...prevState,
              afterDate: {
                startDate: '',
              },
            }));
            return;
          case 'beforeDate':
            setState((prevState) => ({
              ...prevState,
              beforeDate: {
                endDate: '',
              },
            }));
            return;
          case 'betweenDates':
            setState((prevState) => ({
              ...prevState,
              betweenDates: {
                startDate: '',
                endDate: '',
              },
            }));
            return;
        }
      },
    }),
    [callback, setState, state.selectedMethod],
  );

  return (
    <Fragment>
      <Drawer.ScrollContent>
        <Drawer.Group title='Presets'>
          <ControlButton
            icon={state.selectedMethod === 'mtd' ? 'apply' : undefined}
            onClick={(): void => setState((prevState) => ({ ...prevState, selectedMethod: 'mtd' }))}
            text='Month To Date'
          />
          <ControlButton
            icon={state.selectedMethod === 'qtd' ? 'apply' : undefined}
            onClick={(): void => setState((prevState) => ({ ...prevState, selectedMethod: 'qtd' }))}
            text='Quarter To Date'
          />
          <ControlButton
            icon={state.selectedMethod === 'ytd' ? 'apply' : undefined}
            onClick={(): void => setState((prevState) => ({ ...prevState, selectedMethod: 'ytd' }))}
            text='Year To Date'
          />
          <ControlButton
            icon={state.selectedMethod === 'last30d' ? 'apply' : undefined}
            onClick={(): void => setState((prevState) => ({ ...prevState, selectedMethod: 'last30d' }))}
            text='Last 30 Days'
          />
          <ControlButton
            icon={state.selectedMethod === 'last90d' ? 'apply' : undefined}
            onClick={(): void => setState((prevState) => ({ ...prevState, selectedMethod: 'last90d' }))}
            text='Last 90 Days'
          />
        </Drawer.Group>
        <Drawer.Group title='Custom'>
          <ControlButton
            icon={state.selectedMethod === 'afterDate' ? 'apply' : undefined}
            onClick={(): void => setState((prevState) => ({ ...prevState, selectedMethod: 'afterDate' }))}
            text='After Date'
          />
          <ControlButton
            icon={state.selectedMethod === 'beforeDate' ? 'apply' : undefined}
            onClick={(): void => setState((prevState) => ({ ...prevState, selectedMethod: 'beforeDate' }))}
            text='Before Date'
          />
          <ControlButton
            icon={state.selectedMethod === 'betweenDates' ? 'apply' : undefined}
            onClick={(): void => setState((prevState) => ({ ...prevState, selectedMethod: 'betweenDates' }))}
            text='Between Dates'
          />
        </Drawer.Group>
      </Drawer.ScrollContent>
      <Drawer.Footer buttonAction={action} />
    </Fragment>
  );
}

function BeforeDatePhase({
  state,
  setState,
  callback,
}: {
  state: TDateRangePickerDrawerPanelState;
  setState: Dispatch<SetStateAction<TDateRangePickerDrawerPanelState>>;
  callback: TDateRangePickerDrawerTypes['BeginLocationPickCallback'];
}): JSX.Element {
  const endDateError = useMemo(
    () => newDatePickerValidator(true)(ensureDef(state.beforeDate).endDate),
    [state.beforeDate],
  );

  const handleEndDateChange = useCallback<TRawDatePicker['onChange']>(
    (newValue) => {
      setState((prevState) => ({
        ...prevState,
        beforeDate: {
          endDate: newValue,
        },
      }));
    },
    [setState],
  );

  const onApply = useMemo<TButtonAction>(
    () => ({
      isAsync: false,
      text: 'Apply',
      onClick: (): void => {
        const date = mustParseDatePickerDate(ensureDef(state.beforeDate).endDate);
        callback({
          startTime: null,
          endTime: new Date(date.year, date.month - 1, date.day, 23, 59, 59, 999).toISOString(),
        });
      },
      variant: isDef(endDateError) ? 'disabled' : 'default',
    }),
    [callback, endDateError, state.beforeDate],
  );

  const onBack = useMemo<TButtonAction>(
    () => ({
      isAsync: false,
      text: 'Back',
      onClick: (): void => {
        setState((prevState) => ({
          ...prevState,
          beforeDate: null,
        }));
      },
      variant: 'secondary',
    }),
    [setState],
  );

  return (
    <Fragment>
      <Drawer.ScrollContent>
        <Card.Container variant='form'>
          <Control error={endDateError} id='date-range-picker-end-date' label='End Date (Inclusive)' required={true}>
            <RawDatePicker
              id='date-range-picker-end-date'
              onChange={handleEndDateChange}
              value={ensureDef(state.beforeDate).endDate}
            />
          </Control>
        </Card.Container>
      </Drawer.ScrollContent>
      <Drawer.Footer buttonAction={onApply} secondaryButtonAction={onBack} />
    </Fragment>
  );
}

function AfterDatePhase({
  state,
  setState,
  callback,
}: {
  state: TDateRangePickerDrawerPanelState;
  setState: Dispatch<SetStateAction<TDateRangePickerDrawerPanelState>>;
  callback: TDateRangePickerDrawerTypes['BeginLocationPickCallback'];
}): JSX.Element {
  const startDateError = useMemo(
    () => newDatePickerValidator(true)(ensureDef(state.afterDate).startDate),
    [state.afterDate],
  );

  const handleStartDateChange = useCallback<TRawDatePicker['onChange']>(
    (newValue) => {
      setState((prevState) => ({
        ...prevState,
        afterDate: {
          startDate: newValue,
        },
      }));
    },
    [setState],
  );

  const onApply = useMemo<TButtonAction>(
    () => ({
      isAsync: false,
      text: 'Apply',
      onClick: (): void => {
        const date = mustParseDatePickerDate(ensureDef(state.afterDate).startDate);

        callback({
          startTime: new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0).toISOString(),
          endTime: null,
        });
      },
      variant: isDef(startDateError) ? 'disabled' : 'default',
    }),
    [callback, startDateError, state.afterDate],
  );

  const onBack = useMemo<TButtonAction>(
    () => ({
      isAsync: false,
      text: 'Back',
      onClick: (): void => {
        setState((prevState) => ({
          ...prevState,
          afterDate: null,
        }));
      },
      variant: 'secondary',
    }),
    [setState],
  );

  return (
    <Fragment>
      <Drawer.ScrollContent>
        <Card.Container variant='form'>
          <Control
            error={startDateError}
            id='date-range-picker-start-date'
            label='Start Date (Inclusive)'
            required={true}>
            <RawDatePicker
              id='date-range-picker-start-date'
              onChange={handleStartDateChange}
              value={ensureDef(state.afterDate).startDate}
            />
          </Control>
        </Card.Container>
      </Drawer.ScrollContent>
      <Drawer.Footer buttonAction={onApply} secondaryButtonAction={onBack} />
    </Fragment>
  );
}

function BetweenDatesPhase({
  state,
  setState,
  callback,
}: {
  state: TDateRangePickerDrawerPanelState;
  setState: Dispatch<SetStateAction<TDateRangePickerDrawerPanelState>>;
  callback: TDateRangePickerDrawerTypes['BeginLocationPickCallback'];
}): JSX.Element {
  const startDateError = useMemo(
    () => newDatePickerValidator(true)(ensureDef(state.betweenDates).startDate),
    [state.betweenDates],
  );

  const endDateError = useMemo(
    () => newDatePickerValidator(true)(ensureDef(state.betweenDates).endDate),
    [state.betweenDates],
  );

  const handleStartDateChange = useCallback<TRawDatePicker['onChange']>(
    (newValue) => {
      setState((prevState) => ({
        ...prevState,
        betweenDates: {
          startDate: newValue,
          endDate: ensureDef(prevState.betweenDates).endDate,
        },
      }));
    },
    [setState],
  );

  const handleEndDateChange = useCallback<TRawDatePicker['onChange']>(
    (newValue) => {
      setState((prevState) => ({
        ...prevState,
        betweenDates: {
          startDate: ensureDef(prevState.betweenDates).startDate,
          endDate: newValue,
        },
      }));
    },
    [setState],
  );

  const onApply = useMemo<TButtonAction>(
    () => ({
      isAsync: false,
      text: 'Apply',
      onClick: (): void => {
        const startDate = mustParseDatePickerDate(ensureDef(state.betweenDates).startDate);
        const endDate = mustParseDatePickerDate(ensureDef(state.betweenDates).endDate);

        callback({
          startTime: new Date(startDate.year, startDate.month - 1, startDate.day, 0, 0, 0, 0).toISOString(),
          endTime: new Date(endDate.year, endDate.month - 1, endDate.day, 23, 59, 59, 999).toISOString(),
        });
      },
      variant: isDef(startDateError) || isDef(endDateError) ? 'disabled' : 'default',
    }),
    [callback, endDateError, startDateError, state.betweenDates],
  );

  const onBack = useMemo<TButtonAction>(
    () => ({
      isAsync: false,
      text: 'Back',
      onClick: (): void => {
        setState((prevState) => ({
          ...prevState,
          betweenDates: null,
        }));
      },
      variant: 'secondary',
    }),
    [setState],
  );

  return (
    <Fragment>
      <Drawer.ScrollContent>
        <Card.Container variant='form'>
          <Control
            error={startDateError}
            id='date-range-picker-start-date'
            label='Start Date (Inclusive)'
            required={true}>
            <RawDatePicker
              id='date-range-picker-start-date'
              onChange={handleStartDateChange}
              value={ensureDef(state.betweenDates).startDate}
            />
          </Control>
          <Control error={endDateError} id='date-range-picker-end-date' label='End Date (Inclusive)' required={true}>
            <RawDatePicker
              id='date-range-picker-end-date'
              onChange={handleEndDateChange}
              value={ensureDef(state.betweenDates).endDate}
            />
          </Control>
        </Card.Container>
      </Drawer.ScrollContent>
      <Drawer.Footer buttonAction={onApply} secondaryButtonAction={onBack} />
    </Fragment>
  );
}
