import { Drawer } from '@src/components/appearance/structure/Drawer';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { PropsWithChildren, ReactElement } from 'react';
import { useCallback, useMemo, useState } from 'react';

export type TDrawerTypes = {
  DoDrawerOpen: (newDrawerChildren: ReactElement) => void;
  DoDrawerClose: () => void;
};

export type TDrawerContext = {
  doDrawerOpen: TDrawerTypes['DoDrawerOpen'];
  doDrawerClose: TDrawerTypes['DoDrawerClose'];
};

export const { Context: DrawerContext, useContext: useDrawer } = createRequiredContext<TDrawerContext>();

export function DrawerProvider({ children }: PropsWithChildren): JSX.Element {
  const inlineDrawer = useInlineDrawer<undefined>();

  const value = useMemo<TDrawerContext>(
    () => ({
      doDrawerOpen: (newDrawerChildren) =>
        inlineDrawer.doDrawerOpen({
          metadata: undefined,
          drawerChildren: newDrawerChildren,
        }),
      doDrawerClose: inlineDrawer.doDrawerClose,
    }),
    [inlineDrawer],
  );

  return (
    <DrawerContext.Provider value={value}>
      <InlineDrawer {...inlineDrawer} />
      {children}
    </DrawerContext.Provider>
  );
}

export type TTopDrawerContext = {
  doTopDrawerOpen: TDrawerTypes['DoDrawerOpen'];
  doTopDrawerClose: TDrawerTypes['DoDrawerClose'];
};

export const { Context: TopDrawerContext, useContext: useTopDrawer } = createRequiredContext<TTopDrawerContext>();

export function TopDrawerProvider({ children }: PropsWithChildren): JSX.Element {
  const inlineDrawer = useInlineDrawer<undefined>();

  const value = useMemo<TTopDrawerContext>(
    () => ({
      doTopDrawerOpen: (newDrawerChildren) =>
        inlineDrawer.doDrawerOpen({
          metadata: undefined,
          drawerChildren: newDrawerChildren,
        }),
      doTopDrawerClose: inlineDrawer.doDrawerClose,
    }),
    [inlineDrawer],
  );

  return (
    <TopDrawerContext.Provider value={value}>
      <InlineDrawer {...inlineDrawer} level='top' />
      {children}
    </TopDrawerContext.Provider>
  );
}

export type TInlineDrawerState<T> = {
  metadata: T;
  drawerChildren: ReactElement;
};

export type TInlineDrawer<T> = {
  doDrawerOpen: (newState: TInlineDrawerState<T>) => void;
  doDrawerClose: () => void;
  drawerState: TInlineDrawerState<T> | undefined;
  level?: 'default' | 'top' | undefined;
};

export function useInlineDrawer<T>(): TInlineDrawer<T> {
  const [drawerState, setDrawerState] = useState<TInlineDrawerState<T> | undefined>();

  return useMemo<TInlineDrawer<T>>(
    () => ({
      doDrawerOpen: (newState: TInlineDrawerState<T>) => setDrawerState(newState),
      doDrawerClose: () => setDrawerState(undefined),
      drawerState,
    }),
    [drawerState],
  );
}

export function InlineDrawer<T>({ doDrawerClose, drawerState, level }: TInlineDrawer<T>): JSX.Element {
  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        doDrawerClose();
      }
    },
    [doDrawerClose],
  );

  return (
    <Drawer.Root onOpenChange={handleOpenChange} open={isDef(drawerState)} level={level}>
      {drawerState?.drawerChildren}
    </Drawer.Root>
  );
}
