import { isApolloError } from '@apollo/client';
import { EErrorClientCode } from '@src/gen/shared/enums/errorClientCode';
import { isDef } from '@src/gen/shared/utils/types';
import get from 'lodash/get';
import { isRouteErrorResponse } from 'react-router-dom';

export enum EErrorSummaryDisplayMode {
  ANNOUNCEMENT = 'announcement',
  SILENT = 'silent',
  TOAST = 'toast',
}

export type TErrorSummaryDisplay = {
  title: string;
  message: string;
  context: string | null;
  mode: EErrorSummaryDisplayMode;
};

export type TErrorSummary = {
  display: TErrorSummaryDisplay;
  code: EErrorClientCode | null;
  thrown: unknown;
  serializedThrown: unknown;
};

const ERROR_SUMMARY_DISPLAY_GENERIC: TErrorSummaryDisplay = {
  title: 'Ooops!',
  message: 'Something went wrong.',
  context: null,
  mode: EErrorSummaryDisplayMode.ANNOUNCEMENT,
};

const ERROR_SUMMARY_DISPLAY_NOT_FOUND: TErrorSummaryDisplay = {
  ...ERROR_SUMMARY_DISPLAY_GENERIC,
  title: 'Not Found',
  message: 'The page you are looking for could not be found.',
};

const ERROR_SUMMARY_DISPLAY_OFFLINE: TErrorSummaryDisplay = {
  ...ERROR_SUMMARY_DISPLAY_GENERIC,
  title: 'Network Error',
  message: 'Please check your connection and try again.',
  mode: EErrorSummaryDisplayMode.ANNOUNCEMENT,
};

export type TGetErrorSummaryOptions = {
  displayContext?: string | null | undefined;
  forceDisplayMode?: EErrorSummaryDisplayMode | undefined;
};

export function getErrorSummary(thrown: unknown, options: TGetErrorSummaryOptions): TErrorSummary {
  const errorSummary = getErrorSummaryInternal(thrown, thrown);

  if (isDef(options.displayContext)) {
    errorSummary.display.context = options.displayContext;
  }

  if (isDef(options.forceDisplayMode)) {
    errorSummary.display.mode = options.forceDisplayMode;
  }

  return errorSummary;
}

function getErrorSummaryInternal(current: unknown, thrown: unknown): Omit<TErrorSummary, 'userContext'> {
  if (Array.isArray(current) && current.length > 0) {
    return getErrorSummaryInternal(current[0], thrown);
  }

  if (current !== null && typeof current === 'object' && 'extensions' in current) {
    const maybeCode = get(current.extensions, 'code');
    const parsedCode = parseCode(typeof maybeCode === 'string' && maybeCode !== '' ? maybeCode : null);

    return {
      ...parsedCode,
      thrown: thrown,
      serializedThrown: passThroughJson(thrown),
    };
  }

  if (current instanceof Error && isApolloError(current)) {
    if (current.message.includes('invalid input syntax for type uuid')) {
      return {
        display: {
          ...ERROR_SUMMARY_DISPLAY_NOT_FOUND,
        },
        code: EErrorClientCode.NOT_FOUND,
        thrown: thrown,
        serializedThrown: passThroughJson(thrown),
      };
    }

    if (current.graphQLErrors.length > 0) {
      return getErrorSummaryInternal(current.graphQLErrors, thrown);
    }
  }

  if (isRouteErrorResponse(current) || (current instanceof Error && current.message.includes('Not Found'))) {
    return {
      display: {
        ...ERROR_SUMMARY_DISPLAY_NOT_FOUND,
      },
      code: EErrorClientCode.NOT_FOUND,
      thrown: thrown,
      serializedThrown: passThroughJson(thrown),
    };
  }

  if (current instanceof Error && isApolloError(current) && current.networkError !== null) {
    return {
      display: {
        ...ERROR_SUMMARY_DISPLAY_OFFLINE,
      },
      code: EErrorClientCode.NETWORK_ERROR,
      thrown: thrown,
      serializedThrown: passThroughJson(thrown),
    };
  }

  return {
    display: {
      ...ERROR_SUMMARY_DISPLAY_GENERIC,
    },
    code: null,
    thrown: thrown,
    serializedThrown: passThroughJson(thrown),
  };
}

function parseCode(code: string | null): Pick<TErrorSummary, 'code' | 'display'> {
  switch (code) {
    case EErrorClientCode.DISABLED_USER:
    case EErrorClientCode.UNAUTHORIZED_USER:
    case EErrorClientCode.UNKNOWN_EMAIL:
      return {
        display: {
          ...ERROR_SUMMARY_DISPLAY_GENERIC,
          title: 'Not Authorized',
          message:
            'This account is not authorized to access Wellplaece. If you believe this an error please contact support.',
        },
        code,
      };
    case EErrorClientCode.INVALID_CONTENT_TYPE:
      return {
        display: {
          ...ERROR_SUMMARY_DISPLAY_GENERIC,
          message: 'Unacceptable file type.',
          mode: EErrorSummaryDisplayMode.TOAST,
        },
        code,
      };
    case EErrorClientCode.INVALID_TOKEN:
      return {
        display: {
          ...ERROR_SUMMARY_DISPLAY_GENERIC,
          title: 'Not quite right...',
          message: 'The code you provided is invalid, expired, or has already been used.',
          mode: EErrorSummaryDisplayMode.TOAST,
        },
        code,
      };
    case EErrorClientCode.NOT_FOUND:
      return {
        display: {
          ...ERROR_SUMMARY_DISPLAY_NOT_FOUND,
        },
        code,
      };
    case EErrorClientCode.RATE_LIMIT:
      return {
        display: {
          ...ERROR_SUMMARY_DISPLAY_GENERIC,
          title: 'Not too fast!',
          message:
            'We recently sent a security code to this email. Please check in spam/promotions or try again in a minute.',
        },
        code,
      };
    default:
      return {
        display: {
          ...ERROR_SUMMARY_DISPLAY_GENERIC,
        },
        code: null,
      };
  }
}

function passThroughJson(value: unknown): unknown {
  // TODO(ibrt): Move to shared lib.

  if (!isDef(value)) {
    return null;
  }

  const seen = new WeakSet();

  return JSON.parse(
    JSON.stringify(value, (k: string, v: unknown): unknown => {
      if (typeof v === 'bigint') {
        return `${v}n`;
      }

      if (typeof v === 'object' && v !== null) {
        if (seen.has(v)) {
          return '[ CIRCULAR ]';
        }
        seen.add(v);
      }

      return v;
    }),
  );
}
