import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import { Select } from '@src/components/appearance/controls/Select';
import type { TTabSelectBase } from '@src/components/appearance/controls/TabSelect';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderPaymentCard } from '@src/components/mixins/cards/OrderPaymentCard';
import type { TAgentOrderPaymentCompleteFragment } from '@src/gen/graphql/bindings';
import { EOrderPaymentType } from '@src/gen/shared/enums/orderPaymentType';
import { isDef } from '@src/gen/shared/utils/types';
import { openUrl } from '@src/logic/internal/utils/utils';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import type { TAgentOrderWorksheetPaymentsRouteParamsConfig } from '@src/modules/routing/agent';
import {
  encodeAgentOrderWorksheetPaymentsPath,
  useAgentOrderWorksheetPaymentsRouteParams,
} from '@src/modules/routing/agent';
import { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentOrderWorksheetPaymentsPart(): JSX.Element {
  const navigate = useNavigate();
  const routeParams = useAgentOrderWorksheetPaymentsRouteParams();
  const { beginOrderPaymentAdd, doOrderPaymentRemoveAsyncNotify } = useAgentOrderActions();
  const { order, orderManager } = useAgentOrder();

  const handleFilterTypeSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetPaymentsPath(routeParams.orderId, {
          ...routeParams.config,
          type: e.target.value as TAgentOrderWorksheetPaymentsRouteParamsConfig['type'], // eslint-disable-line @typescript-eslint/consistent-type-assertions
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const showOrderPayments = useMemo<TAgentOrderPaymentCompleteFragment[]>(
    () =>
      order.order_payments.filter((op) => {
        switch (routeParams.config.type) {
          case 'any':
            return true;
          default:
            return op.type === routeParams.config.type;
        }
      }),
    // @sort
    [order.order_payments, routeParams.config.type],
  );

  if (!isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())) {
    return (
      <Structure.ScrollContent skipSeparator={true}>
        <Banner icon='info' message='Payments will be enabled once an invoice is issued.' />
      </Structure.ScrollContent>
    );
  }

  return (
    <Fragment>
      <Structure.Content>
        <ToolBar
          RightChildren={
            <Fragment>
              <ActionIconButton action={{ isAsync: false, icon: 'add', onClick: beginOrderPaymentAdd }} />
              <ActionIconButton
                action={{
                  isAsync: false,
                  icon: 'credit-card',
                  onClick: (): void => {
                    if (isDef(order.location.stripe_customer_id)) {
                      openUrl(`https://dashboard.stripe.com/customers/${order.location.stripe_customer_id}`);
                    }
                  },
                  variant: isDef(order.location.stripe_customer_id) ? 'default' : 'disabled',
                }}
              />
            </Fragment>
          }
          variant='structure'>
          <Select onChange={handleFilterTypeSelectChange} value={routeParams.config.type}>
            <option value='any'>Type: Any</option>
            <option value={EOrderPaymentType.CHARGE}>Type: Charge</option>
            <option value={EOrderPaymentType.REFUND}>Type: Refund</option>
          </Select>
        </ToolBar>
      </Structure.Content>
      <Structure.ScrollContent skipSeparator={true}>
        <Structure.Group>
          {showOrderPayments.length === 0 && (
            <Banner
              icon='apply'
              message={
                routeParams.config.type === 'any'
                  ? 'Click on the + button to add a payment.'
                  : 'No payments matching these filters.'
              }
            />
          )}
          {showOrderPayments.map((op) => (
            <OrderPaymentCard
              key={op.id}
              onDeleteAsyncNotify={async (): Promise<void> =>
                await doOrderPaymentRemoveAsyncNotify({ orderPaymentId: op.id })
              }
              orderPayment={op}
            />
          ))}
        </Structure.Group>
      </Structure.ScrollContent>
    </Fragment>
  );
}
