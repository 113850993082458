import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { ControlButton, ControlButtonLoader } from '@src/components/appearance/controls/ControlButton';
import type { TDrawer } from '@src/components/appearance/structure/Drawer';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import type { TAgentUserBaseFragment } from '@src/gen/graphql/bindings';
import { getFullName } from '@src/gen/shared/data/snippets';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import noop from 'lodash/noop';
import { forwardRef, memo, useMemo, useState } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

type TUserPickerDrawerPanelBase = {
  buttonActionText?: string | undefined;
  drawerHeaderTitle?: string | undefined;
  loaderRef: UseInfiniteScrollHookRefCallback | null;
  onBack?: (() => void) | undefined;
  onSelectUser: (user: TAgentUserBaseFragment) => Promise<void>;
  users: TAgentUserBaseFragment[];
};

export type TUserPickerDrawerPanel = TProps<false, TUserPickerDrawerPanelBase, 'div'>;
export const USER_PICKER_DRAWER_PANEL_CLASS_NAME = 'wp-user-picker-drawer-panel';

export const UserPickerDrawerPanel = withCssToString(
  USER_PICKER_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TUserPickerDrawerPanel>(
      (
        { buttonActionText, drawerHeaderTitle, loaderRef, onBack, onSelectUser, users, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, USER_PICKER_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [selectedUser, setSelectedUser] = useState<TAgentUserBaseFragment | null>(null);

        const buttonAction = useMemo<TDrawer['FooterBase']['buttonAction']>(
          () =>
            isDef(selectedUser)
              ? {
                  isAsync: true,
                  onClick: async (): Promise<void> => await onSelectUser(selectedUser),
                  text: buttonActionText ?? 'Select User',
                }
              : {
                  isAsync: false,
                  onClick: noop,
                  text: buttonActionText ?? 'Select User',
                  variant: 'disabled',
                },
          [buttonActionText, onSelectUser, selectedUser],
        );

        const secondaryButtonAction = useMemo<TDrawer['FooterBase']['secondaryButtonAction']>(
          () =>
            isDef(onBack)
              ? {
                  isAsync: false,
                  onClick: onBack,
                  text: 'Go Back',
                  variant: 'secondary',
                }
              : undefined,
          [onBack],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title={drawerHeaderTitle ?? 'Select Organization'} />
            <Drawer.ScrollContent>
              <Drawer.Group>
                {users.map((user) => (
                  <ControlButton
                    key={user.id}
                    icon={user.id === selectedUser?.id ? 'apply' : undefined}
                    onClick={(): void => setSelectedUser(user)}
                    text={getFullName(user)}
                  />
                ))}
              </Drawer.Group>
              {isDef(loaderRef) && <Illustrations.Spinner ref={loaderRef} />}
            </Drawer.ScrollContent>
            <Drawer.Footer buttonAction={buttonAction} secondaryButtonAction={secondaryButtonAction} />
          </Drawer.Panel>
        );
      },
    ),
  ),
);

export const UserPickerDrawerPanelLoader = memo(
  forwardRef<HTMLDivElement, Pick<TUserPickerDrawerPanelBase, 'buttonActionText' | 'drawerHeaderTitle'>>(
    ({ buttonActionText, drawerHeaderTitle }, ref): JSX.Element => (
      <Drawer.Panel ref={ref}>
        <Drawer.Header title={drawerHeaderTitle ?? 'Select User'} />
        <Drawer.ScrollContentLoader>
          <Drawer.Group>
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
            <ControlButtonLoader />
          </Drawer.Group>
        </Drawer.ScrollContentLoader>
        <Drawer.FooterLoader buttonText={buttonActionText ?? 'Select User'} />
      </Drawer.Panel>
    ),
  ),
);
