import { NetworkStatus } from '@apollo/client';
import type {
  CustomerNotificationsQueryVariables,
  TCustomerNotificationCompleteFragment,
} from '@src/gen/graphql/bindings';
import { CustomerNotificationsDocument, useCustomerNotificationsQuery } from '@src/gen/graphql/bindings';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import { useAuthCustomer } from '@src/modules/auth/AuthProvider';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, FUTURE_DATE, mergeGetMore } from '@src/modules/graphql/utils';
import type { DocumentNode } from 'graphql/language';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TCustomerNotificationsContext = {
  notifications: TCustomerNotificationCompleteFragment[];
  queryRef: { query: DocumentNode; variables: CustomerNotificationsQueryVariables };
  serializationKey: string;
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const { Context: CustomerNotificationsContext, useContext: useCustomerNotifications } =
  createRequiredContext<TCustomerNotificationsContext>();

export type TCustomerNotificationsProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function CustomerNotificationsProvider({
  LoaderComponent,
  children,
}: TCustomerNotificationsProvider): JSX.Element {
  const customer = useAuthCustomer();

  const variables = useMemo<CustomerNotificationsQueryVariables>(
    () => ({
      userId: customer.id,
      updatedAtCursor: FUTURE_DATE.toISOString(),
      limit: DEFAULT_LIMIT,
    }),
    // @sort
    [customer.id],
  );

  const { data, error, fetchMore, networkStatus } = useCustomerNotificationsQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          updatedAtCursor:
            (data?.notifications.length ?? 0) > 0
              ? ensureDef(data?.notifications[data.notifications.length - 1]).updated_at
              : FUTURE_DATE.toISOString(),
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          notifications: mergeGetMore(prev.notifications, fetchMoreResult.notifications),
        }),
      });

      return newData.notifications.length;
    },
    // @sort
    [fetchMore, data?.notifications],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.notifications.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TCustomerNotificationsContext | undefined>(
    () =>
      isDef(data)
        ? {
            notifications: data.notifications.filter((n) => n.cleared_at === null),
            queryRef: {
              query: CustomerNotificationsDocument,
              variables,
            },
            serializationKey: 'notifications',
            loaderRef: loaderRef,
          }
        : undefined,
    // @sort
    [data, loaderRef, variables],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <CustomerNotificationsContext.Provider value={value}>{children}</CustomerNotificationsContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
