import { Typography } from '@src/components/appearance/basics/Typography';
import { Button, getButtonVariant } from '@src/components/appearance/controls/Button';
import type { TTextBoxBase } from '@src/components/appearance/controls/TextBox';
import { TextBox } from '@src/components/appearance/controls/TextBox';
import { Cover } from '@src/components/appearance/structure/Cover';
import { EErrorClientCode } from '@src/gen/shared/enums/errorClientCode';
import { useAuth } from '@src/modules/auth/AuthProvider';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import { styled } from '@stitches/react';
import type { KeyboardEvent } from 'react';
import { useCallback, useState } from 'react';

export function SignInPart(): JSX.Element {
  const [emailTokenId, setEmailTokenId] = useState<string | undefined>(undefined);

  return (
    <Cover background='image'>
      {emailTokenId === undefined && <EmailPrompt setEmailTokenId={setEmailTokenId} />}
      {emailTokenId !== undefined && <CodePrompt emailTokenId={emailTokenId} setEmailTokenId={setEmailTokenId} />}
    </Cover>
  );
}

const SEmailPromptDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$cardGap',
});

function EmailPrompt({ setEmailTokenId }: { setEmailTokenId: (emailTokenId: string) => void }): JSX.Element {
  const { doIssueEmailToken } = useAuth();
  const { doErrorNotify, doErrorClear } = useErrors();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const isValid = /^[^ \t]+@[^ \t]+\.[^ \t]+$/.test(value.trim());

  const handleClickAsync = useCallback(
    async () => {
      try {
        doErrorClear();
        setLoading(true);
        setEmailTokenId(await doIssueEmailToken(value.toLowerCase().trim()));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      } finally {
        setLoading(false);
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, doIssueEmailToken, setEmailTokenId, value],
  );

  const handleChange = useCallback<TTextBoxBase['onChange']>((e) => setValue(e.target.value), []);
  const handleClick = useCallback(() => void handleClickAsync(), [handleClickAsync]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter' && isValid) {
        handleClick();
      }
    },
    // @sort
    [handleClick, isValid],
  );

  return (
    <SEmailPromptDiv>
      <Typography.Caption text='Enter your email address to sign in' />
      <TextBox
        disabled={loading}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder='example@email.com'
        value={value}
        css={{ alignSelf: 'stretch' }}
      />
      <Button
        variant={getButtonVariant({ base: 'default', disabled: !isValid, loading })}
        onClick={handleClick}
        text='Continue'
      />
    </SEmailPromptDiv>
  );
}

function CodePrompt({
  emailTokenId,
  setEmailTokenId,
}: {
  emailTokenId: string;
  setEmailTokenId: (emailTokenId: string | undefined) => void;
}): JSX.Element {
  const { doVerifyEmailToken } = useAuth();
  const { doErrorNotify, doErrorClear } = useErrors();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const isValid = /^[a-z0-9]{8}$/.test(value);

  const handleClickAsync = useCallback(
    async () => {
      try {
        doErrorClear();
        setLoading(true);
        await doVerifyEmailToken(`${emailTokenId}-${value}`);
      } catch (thrown: unknown) {
        const errorSummary = getErrorSummary(thrown, {});
        doErrorNotify(errorSummary);

        if (errorSummary.code === EErrorClientCode.UNKNOWN_EMAIL) {
          setEmailTokenId(undefined);
        }
      } finally {
        setLoading(false);
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, doVerifyEmailToken, emailTokenId, setEmailTokenId, value],
  );

  const handleChange = useCallback<TTextBoxBase['onChange']>((e) => setValue(e.target.value), []);
  const handleClick = useCallback(() => void handleClickAsync(), [handleClickAsync]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter' && isValid) {
        handleClick();
      }
    },
    // @sort
    [handleClick, isValid],
  );

  return (
    <SEmailPromptDiv>
      <Typography.Caption text='Enter the security code we emailed you' />
      <TextBox
        disabled={loading}
        maxLength={8}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder='ab123kd2'
        value={value}
        css={{ alignSelf: 'stretch' }}
      />
      <Button
        variant={getButtonVariant({ base: 'default', disabled: !isValid, loading })}
        onClick={handleClick}
        text='Continue'
      />
    </SEmailPromptDiv>
  );
}
