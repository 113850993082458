import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EDonationTarget {
  TEST = 'test',
  AAPD_FOUNDATION = 'aapd_foundation',
  SMILES_FOR_LIFE_FOUNDATION = 'smiles_for_life_foundation',
}

const DONATION_TARGET_LOOKUP = mapValues(invert(EDonationTarget), () => true);

export function getDonationTarget(rawDonationTarget: string): EDonationTarget {
  assert(DONATION_TARGET_LOOKUP[rawDonationTarget], `Unknown donation target: "${rawDonationTarget}".`);
  return rawDonationTarget as EDonationTarget; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetDonationTarget(rawDonationTarget: string): EDonationTarget | undefined {
  try {
    return getDonationTarget(rawDonationTarget);
  } catch {
    return undefined;
  }
}

export const DONATION_TARGET_LABELS: { [key in EDonationTarget]: string } = {
  [EDonationTarget.TEST]: 'Test',
  [EDonationTarget.AAPD_FOUNDATION]: 'AAPD Foundation',
  [EDonationTarget.SMILES_FOR_LIFE_FOUNDATION]: 'Smiles for Life Foundation',
};

export function maybeGetDonationTargetLabel(rawDonationTarget: string): string {
  const donationTarget = maybeGetDonationTarget(rawDonationTarget);
  return donationTarget !== undefined ? ensureDef(DONATION_TARGET_LABELS[donationTarget]) : rawDonationTarget;
}
