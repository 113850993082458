import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderAttachmentCard } from '@src/components/mixins/cards/OrderAttachmentCard';
import type {
  TAgentOrderAttachmentBaseFragment,
  TCustomerOrderAttachmentBaseFragment,
} from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TOrderAttachmentsStructureGroupBase = {
  beginOrderAttachmentAdd?: TAgentOrderActionsTypes['BeginOrderAttachmentAdd'] | undefined;
  doOrderAttachmentRemoveAsyncNotify?: TAgentOrderActionsTypes['DoOrderAttachmentRemoveAsyncNotify'] | undefined;
  downloadOrderAttachmentAsyncNotify: TAgentOrderActionsTypes['DownloadOrderAttachmentAsyncNotify'];
  orderAttachments: TAgentOrderAttachmentBaseFragment[] | TCustomerOrderAttachmentBaseFragment[];
};

export type TOrderAttachmentsStructureGroup = TProps<false, TOrderAttachmentsStructureGroupBase, 'div'>;
export const ORDER_ATTACHMENTS_STRUCTURE_GROUP = 'wp-order-attachments-structure-group';

export const OrderAttachmentsStructureGroup = withCssToString(
  ORDER_ATTACHMENTS_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TOrderAttachmentsStructureGroup>(
      (
        {
          beginOrderAttachmentAdd,
          doOrderAttachmentRemoveAsyncNotify,
          downloadOrderAttachmentAsyncNotify,
          orderAttachments,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ATTACHMENTS_STRUCTURE_GROUP),
          [className],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () => getAvailableIconButtonActions([{ isAsync: false, icon: 'add', onClick: beginOrderAttachmentAdd }]),
          // @sort
          [beginOrderAttachmentAdd],
        );

        return (
          <Structure.Group
            {...rest}
            className={joinedClassName}
            iconButtonActions={iconButtonActions}
            ref={ref}
            title='Attachments'>
            {orderAttachments.map((otn) => (
              <CardComponent
                key={otn.upload_id}
                doOrderAttachmentRemoveAsyncNotify={doOrderAttachmentRemoveAsyncNotify}
                downloadOrderAttachmentAsyncNotify={downloadOrderAttachmentAsyncNotify}
                orderAttachment={otn}
              />
            ))}
            {orderAttachments.length === 0 && (
              <Banner icon='info' message='Invoices and other types of attachments will appear here when available.' />
            )}
          </Structure.Group>
        );
      },
    ),
  ),
);

function CardComponent({
  doOrderAttachmentRemoveAsyncNotify,
  downloadOrderAttachmentAsyncNotify,
  orderAttachment,
}: {
  doOrderAttachmentRemoveAsyncNotify?: TAgentOrderActionsTypes['DoOrderAttachmentRemoveAsyncNotify'] | undefined;
  downloadOrderAttachmentAsyncNotify: TAgentOrderActionsTypes['DownloadOrderAttachmentAsyncNotify'];
  orderAttachment: TAgentOrderAttachmentBaseFragment | TCustomerOrderAttachmentBaseFragment;
}): JSX.Element {
  const handleDownloadAsync = useCallback(
    async () => await downloadOrderAttachmentAsyncNotify({ uploadId: orderAttachment.upload_id }),
    // @sort
    [downloadOrderAttachmentAsyncNotify, orderAttachment.upload_id],
  );

  const handleRemoveAsync = useMemo(
    () =>
      isDef(doOrderAttachmentRemoveAsyncNotify)
        ? async (): Promise<void> => await doOrderAttachmentRemoveAsyncNotify({ uploadId: orderAttachment.upload_id })
        : undefined,
    // @sort
    [doOrderAttachmentRemoveAsyncNotify, orderAttachment.upload_id],
  );

  return (
    <OrderAttachmentCard
      orderAttachment={orderAttachment}
      onDownloadAsync={handleDownloadAsync}
      onRemoveAsync={handleRemoveAsync}
    />
  );
}
