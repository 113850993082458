export enum EErrorClientCode {
  DISABLED_USER = 'DISABLED_USER',
  INVALID_CONTENT_TYPE = 'INVALID_CONTENT_TYPE',
  INVALID_TOKEN = 'INVALID_TOKEN',
  NETWORK_ERROR = 'NETWORK_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  RATE_LIMIT = 'RATE_LIMIT',
  UNAUTHORIZED_USER = 'UNAUTHORIZED_USER',
  UNKNOWN_EMAIL = 'UNKNOWN_EMAIL',
}
