import type { TAgentProductHintBaseFragment } from '@src/gen/graphql/bindings';
import { useAgentProductHintsQuery } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TAgentProductHintsContext = {
  forLocation: TAgentProductHintBaseFragment[];
  forOrganization: TAgentProductHintBaseFragment[];
  forOthers: TAgentProductHintBaseFragment[];
};

export const { Context: AgentProductHintsContext, useContext: useAgentProductHints } =
  createRequiredContext<TAgentProductHintsContext>();

export type TAgentNextOrderProductProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  organizationId: string;
  locationId: string;
  productId: string;
}>;

export function AgentProductHintsProvider({
  LoaderComponent,
  organizationId,
  locationId,
  productId,
  children,
}: TAgentNextOrderProductProvider): JSX.Element {
  const { data, error } = useAgentProductHintsQuery({
    variables: {
      organizationId,
      locationId,
      productId,
    },
  });

  const value = useMemo<TAgentProductHintsContext | undefined>(
    () =>
      isDef(data)
        ? {
            forLocation: data.forLocation,
            forOrganization: data.forOrganization,
            forOthers: data.forOthers,
          }
        : undefined,
    [data],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) ? (
    <AgentProductHintsContext.Provider value={value}>{children}</AgentProductHintsContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
