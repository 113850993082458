import { NetworkStatus } from '@apollo/client';
import type { AgentOrdersFilterQueryVariables, TAgentOrderBaseFragment } from '@src/gen/graphql/bindings';
import { useAgentOrdersFilterQuery } from '@src/gen/graphql/bindings';
import type { EOrderStatus } from '@src/gen/shared/enums/orderStatus';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, useIntelligentInfiniteScroll } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { DEFAULT_LIMIT, mergeGetMore } from '@src/modules/graphql/utils';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export type TAgentOrdersFilterContext = {
  orders: TAgentOrderBaseFragment[];
  loaderRef: UseInfiniteScrollHookRefCallback | null;
};

export const { Context: AgentOrdersFilterContext, useContext: useAgentOrdersFilter } =
  createRequiredContext<TAgentOrdersFilterContext>();

export type TAgentOrdersFilterProviderFilter = {
  organizationId: string | null;
  status: EOrderStatus | null;
  isAwaitingPrebillApproval: boolean | null;
  isBlockedOnCustomer: boolean | null;
  isEmergency: boolean | null;
  isPaid: boolean | null;
};

export type TAgentOrdersFilterProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  filter: TAgentOrdersFilterProviderFilter;
}>;

export function AgentOrdersFilterProvider({
  LoaderComponent,
  filter,
  children,
}: TAgentOrdersFilterProvider): JSX.Element {
  const variables = useMemo<AgentOrdersFilterQueryVariables>(
    () => ({
      ...filter,
      offset: 0,
      limit: DEFAULT_LIMIT,
    }),
    // @sort
    [filter],
  );

  const { data, error, fetchMore, networkStatus } = useAgentOrdersFilterQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const getMoreAsync = useCallback(
    async (): Promise<number> => {
      const { data: newData } = await fetchMore({
        variables: {
          offset: ensureDef(data?.agent_orders_filter.length),
        },
        updateQuery: (prev, { fetchMoreResult }) => ({
          ...prev,
          agent_orders_filter: mergeGetMore(prev.agent_orders_filter, fetchMoreResult.agent_orders_filter),
        }),
      });

      return newData.agent_orders_filter.length;
    },
    // @sort
    [fetchMore, data?.agent_orders_filter.length],
  );

  const loaderRef = useIntelligentInfiniteScroll({
    itemsCount: data?.agent_orders_filter.length,
    networkStatus,
    getMoreAsync,
  });

  const value = useMemo<TAgentOrdersFilterContext | undefined>(
    () =>
      isDef(data)
        ? {
            orders: data.agent_orders_filter,
            loaderRef: loaderRef,
          }
        : undefined,
    // @sort
    [data, loaderRef],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentOrdersFilterContext.Provider value={value}>{children}</AgentOrdersFilterContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
