import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { maybeGetCategoryNameParts } from '@src/gen/shared/data/category';
import { ensureDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled, theme } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
});

const SItemDiv = styled('div', {
  alignItems: 'stretch',
  backgroundImage: Illustrations.getTreeIndentDataUrl(theme.colors.gray3.value),
  backgroundPosition: 'top 4px left 4px',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  gap: '$categoryTreeGap',
  paddingLeft: '$categoryTreeIndent',
});

export type TCategoryTreeBase = {
  category: string;
};

export type TCategoryTree = TProps<false, TCategoryTreeBase, 'div'>;
const CATEGORY_TREE_CLASS_NAME = 'wp-category-tree';

export const CategoryTree = withCssToString(
  CATEGORY_TREE_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TCategoryTree>(({ category, className, ...rest }, ref): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, CATEGORY_TREE_CLASS_NAME), [className]);
      const nameParts = useMemo(() => maybeGetCategoryNameParts(category), [category]);

      return (
        <SDiv {...rest} className={joinedClassName} ref={ref}>
          <Category nameParts={nameParts} />
        </SDiv>
      );
    }),
  ),
);

export function Category({ nameParts }: { nameParts: string[] }): JSX.Element {
  return nameParts.length > 0 ? (
    <SItemDiv>
      <Typography.Small text={ensureDef(nameParts[0])} />
      <Category nameParts={nameParts.slice(1)} />
    </SItemDiv>
  ) : (
    <Fragment />
  );
}
