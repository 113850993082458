import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Typography } from '@src/components/appearance/basics/Typography';
import { Button } from '@src/components/appearance/controls/Button';
import { Announcement } from '@src/components/appearance/fragments/Announcement';
import { getOrderReference } from '@src/gen/shared/data/snippets';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TCustomerNextOrderActionsTypes } from '@src/modules/data/customer/nextOrder/CustomerNextOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

const SReferenceDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export type TNextOrderPlacingOrderAnnouncement = TProps<
  false,
  TCustomerNextOrderActionsTypes['PlacingOrderBase'],
  'div'
>;
export const NEXT_ORDER_PLACING_ORDER_ANNOUNCEMENT_CLASS_NAME = 'wp-next-order-placing-order-announcement';

export const NextOrderPlacingOrderAnnouncement = withCssToString(
  NEXT_ORDER_PLACING_ORDER_ANNOUNCEMENT_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TNextOrderPlacingOrderAnnouncement>(
      ({ placedOrder, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, NEXT_ORDER_PLACING_ORDER_ANNOUNCEMENT_CLASS_NAME),
          [className],
        );

        const orderRef = useMemo(
          () =>
            placedOrder?.order.location.order_code !== undefined
              ? getOrderReference(placedOrder.order.location.order_code, placedOrder.order.reference_number)
              : '',
          // @sort
          [placedOrder?.order.location.order_code, placedOrder?.order.reference_number],
        );

        return (
          <Announcement {...rest} className={joinedClassName} ref={ref}>
            {isDef(placedOrder) ? (
              <Fragment>
                <Typography.SubTitle text='Order Placed' />
                <Typography.Small text='Wellplaece has received your order and will begin processing it as soon as possible.' />
                <SReferenceDiv>
                  <Typography.Strong text={orderRef} />
                  <Typography.Small text={`for ${placedOrder.order.location.name}`} />
                </SReferenceDiv>
                <Button onClick={placedOrder.doOrderShow} text='Show Order' />
              </Fragment>
            ) : (
              <Fragment>
                <Typography.SubTitle text='Placing Order...' />
                <Illustrations.Spinner />
              </Fragment>
            )}
          </Announcement>
        );
      },
    ),
  ),
);
