import type { TSecondaryNavTab } from '@src/components/appearance/structure/SecondaryNav';
import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { Structure } from '@src/components/appearance/structure/Structure';
import { AgentOrderSummaryPartLoader } from '@src/components/parts/agent/order/AgentOrderSummaryPart';
import { AgentOrderUpdatesAndSupportPartLoader } from '@src/components/parts/agent/order/AgentOrderUpdatesAndSupportPart';
import { getOrderReference } from '@src/gen/shared/data/snippets';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import {
  EAgentRoutes,
  encodeAgentOrderSummaryPath,
  encodeAgentOrderUpdatesAndSupportPath,
  encodeAgentOrderWorksheetDashboardPath,
  useAgentOrderRouteParams,
} from '@src/modules/routing/agent';
import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentOrderNavPart(): JSX.Element {
  const { order } = useAgentOrder();
  const tabs = useTabs(order.version === 2);

  return (
    <SecondaryNav
      title='Order'
      highlight={getOrderReference(order.location.order_code, order.reference_number)}
      label={`for ${order.location.name}`}
      tag={order.status}
      errorTag={order.is_emergency ? 'Emergency' : undefined}
      tabs={tabs}
    />
  );
}

export function AgentOrderNavPartLoader(): JSX.Element {
  const tabs = useTabs(false);
  const routeParams = useAgentOrderRouteParams();

  return (
    <Fragment>
      <Structure.Content>
        <SecondaryNav tabs={tabs} tagLoader={true} title='Order' />
      </Structure.Content>
      {routeParams.route === EAgentRoutes.AGENT_ORDER_SUMMARY && <AgentOrderSummaryPartLoader />}
      {routeParams.route === EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT && <AgentOrderUpdatesAndSupportPartLoader />}
    </Fragment>
  );
}

function useTabs(withWorksheet: boolean): TSecondaryNavTab[] {
  const routeParams = useAgentOrderRouteParams();
  const navigate = useNavigate();

  return useMemo<TSecondaryNavTab[]>(
    () => [
      {
        active: routeParams.route === EAgentRoutes.AGENT_ORDER_SUMMARY,
        title: 'Summary',
        onClick: () => navigate(encodeAgentOrderSummaryPath(routeParams.orderId)),
      },
      {
        active: routeParams.route === EAgentRoutes.AGENT_ORDER_UPDATES_AND_SUPPORT,
        title: 'Updates and Support',
        onClick: () => navigate(encodeAgentOrderUpdatesAndSupportPath(routeParams.orderId)),
      },
      ...(withWorksheet
        ? [
            {
              active:
                routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_DASHBOARD ||
                routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_PRODUCTS ||
                routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_BASE_ORDERS ||
                routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_PLAN_ORDERS ||
                routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_PAYMENTS ||
                routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_SHIPMENTS ||
                routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_BACK_ORDERS ||
                routeParams.route === EAgentRoutes.AGENT_ORDER_WORKSHEET_RETURNS,
              title: 'Worksheet',
              onClick: () => navigate(encodeAgentOrderWorksheetDashboardPath(routeParams.orderId)),
            },
          ]
        : []),
    ],
    [routeParams.route, routeParams.orderId, withWorksheet, navigate],
  );
}
