import type { ApolloError } from '@apollo/client';
import type { TBannerBase } from '@src/components/appearance/fragments/Banner';
import { Banner, BannerLoader } from '@src/components/appearance/fragments/Banner';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items, ItemsLoader } from '@src/components/appearance/fragments/Items';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { getDate } from '@src/gen/shared/data/snippets';
import type { TTrackerResult, TTrackerResultEvent } from '@src/gen/shared/data/tracking';
import { maybeGetShip24StatusCodeLabel } from '@src/gen/shared/enums/ship24StatusCode';
import {
  EShip24StatusMilestone,
  maybeGetShip24StatusMilestoneLabel,
} from '@src/gen/shared/enums/ship24StatusMilestone';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TEmptyObject, TProps } from '@src/modules/design/theme';
import { differenceInCalendarDays } from 'date-fns';
import { Fragment, forwardRef, memo, useMemo } from 'react';

export type TOrderProductTrackingHistoryDrawerPanelBase = {
  trackerResult?: TTrackerResult | undefined;
  error?: ApolloError | undefined;
};

const ERROR_MESSAGE_CAPTION = 'Tracking history temporarily unavailable.';

export type TOrderProductTrackingHistoryDrawerPanel = TProps<false, TOrderProductTrackingHistoryDrawerPanelBase, 'div'>;
export const ORDER_TRACKING_HISTORY_DRAWER_PANEL_CLASS_NAME = 'wp-order-tracking-history-drawer-panel';

export const OrderProductTrackingHistoryDrawerPanel = withCssToString(
  ORDER_TRACKING_HISTORY_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderProductTrackingHistoryDrawerPanel>(
      ({ error, trackerResult, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_TRACKING_HISTORY_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Tracking History' />
            {isDef(error) && <DrawerScrollContentWithError />}
            {isDef(trackerResult) && <DrawerScrollContentWithResult trackerResult={trackerResult} />}
          </Drawer.Panel>
        );
      },
    ),
  ),
);

export const OrderProductTrackingHistoryDrawerPanelLoader = memo(
  forwardRef<HTMLDivElement, TEmptyObject>(
    ({}, ref): JSX.Element => (
      <Drawer.Panel ref={ref}>
        <Drawer.Header title='Tracking History' />
        <Drawer.ScrollContentLoader>
          <Drawer.Group>
            <ItemsLoader count={2} />
            <BannerLoader />
          </Drawer.Group>
          <Drawer.Separator />
          <Drawer.Group>
            <ItemsLoader count={2} />
            <BannerLoader />
          </Drawer.Group>
          <Drawer.Separator />
          <Drawer.Group>
            <ItemsLoader count={2} />
            <BannerLoader />
          </Drawer.Group>
          <Drawer.Separator />
          <Drawer.Group>
            <ItemsLoader count={2} />
            <BannerLoader />
          </Drawer.Group>
        </Drawer.ScrollContentLoader>
      </Drawer.Panel>
    ),
  ),
);

function DrawerScrollContentWithError(): JSX.Element {
  return (
    <Drawer.ScrollContent>
      <Drawer.Group>
        <Banner icon='warning' accent='error' message={ERROR_MESSAGE_CAPTION} />
      </Drawer.Group>
    </Drawer.ScrollContent>
  );
}

function DrawerScrollContentWithResult({ trackerResult }: { trackerResult: TTrackerResult }): JSX.Element {
  const items = useMemo<TItemsBase['items'] | undefined>(() => {
    const curItems: TItemBaseItem[] = [];

    if (trackerResult.snapshot.statusMilestone === EShip24StatusMilestone.DELIVERED) {
      return undefined;
    }

    if (isDef(trackerResult.snapshot.statusMilestone)) {
      curItems.push({
        caption: 'Status',
        text: maybeGetShip24StatusMilestoneLabel(trackerResult.snapshot.statusMilestone),
      });
    }

    if (isDef(trackerResult.snapshot.deliveryService)) {
      curItems.push({
        caption: 'Courier',
        text: trackerResult.snapshot.deliveryService,
      });
    }

    if (isDef(trackerResult.snapshot.deliveryEstimatedDeliveryDate)) {
      curItems.push({
        caption: 'Estimated Delivery On',
        text: { asDate: 'on', at: trackerResult.snapshot.deliveryEstimatedDeliveryDate },
      });
    }

    if (curItems.length === 1 || curItems.length === 3) {
      curItems.push({ caption: '', text: '' });
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return curItems as unknown as TItemsBase['items'];
  }, [
    trackerResult.snapshot.deliveryEstimatedDeliveryDate,
    trackerResult.snapshot.deliveryService,
    trackerResult.snapshot.statusMilestone,
  ]);

  return (
    <Drawer.ScrollContent>
      {isDef(items) && <ItemsBanner items={items} />}
      {trackerResult.events.map((event, i) => (
        <Fragment key={event.eventId}>
          {i > 0 && <Drawer.Separator />}
          <EventDrawerGroup event={event} />
        </Fragment>
      ))}
    </Drawer.ScrollContent>
  );
}

function EventDrawerGroup({ event }: { event: TTrackerResultEvent }): JSX.Element {
  const { icon, accent } = useMemo<Pick<TBannerBase, 'accent' | 'icon'>>(
    () =>
      event.statusMilestone === EShip24StatusMilestone.DELIVERED
        ? { icon: 'apply', accent: 'success' }
        : event.statusMilestone === EShip24StatusMilestone.EXCEPTION ||
          event.statusMilestone === EShip24StatusMilestone.FAILED_ATTEMPT
        ? { icon: 'warning', accent: 'warning' }
        : { icon: 'info', accent: 'default' },
    [event.statusMilestone],
  );

  const items = useMemo<TItemsBase['items']>(
    () => [
      {
        caption: 'Location',
        text: event.displayLocation?.replaceAll(',', ', ') ?? 'N/A',
      },
      {
        caption: 'On',
        text:
          differenceInCalendarDays(new Date(), getDate(event.at)) > 7
            ? { asDate: 'on', at: event.at }
            : { asDate: 'ago', at: event.at },
      },
    ],
    [event.at, event.displayLocation],
  );

  return (
    <Drawer.Group>
      <Items items={items} />
      {(isDef(event.displayStatus) || isDef(event.statusCode) || isDef(event.statusMilestone)) && (
        <Banner
          icon={icon}
          accent={accent}
          message={
            event.displayStatus ??
            (isDef(event.statusCode)
              ? maybeGetShip24StatusCodeLabel(event.statusCode)
              : maybeGetShip24StatusMilestoneLabel(ensureDef(event.statusMilestone)))
          }
        />
      )}
    </Drawer.Group>
  );
}
