import { NetworkStatus } from '@apollo/client';
import type { TAgentOrderReturnCompleteWithOrderSummaryFragment } from '@src/gen/graphql/bindings';
import { useAgentOrderReturnsWaitingQuery } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TAgentOrderReturnsWaitingContext = {
  waitingForInstructions: TAgentOrderReturnCompleteWithOrderSummaryFragment[];
  waitingForShipment: TAgentOrderReturnCompleteWithOrderSummaryFragment[];
  waitingForAcknowledgment: TAgentOrderReturnCompleteWithOrderSummaryFragment[];
};

export const { Context: AgentOrderReturnsWaitingContext, useContext: useAgentOrderReturnsWaiting } =
  createRequiredContext<TAgentOrderReturnsWaitingContext>();

export type TAgentOrderReturnsWaitingProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function AgentOrderReturnsWaitingProvider({
  LoaderComponent,
  children,
}: TAgentOrderReturnsWaitingProvider): JSX.Element {
  const { data, error, networkStatus } = useAgentOrderReturnsWaitingQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  const value = useMemo<TAgentOrderReturnsWaitingContext | undefined>(
    () =>
      isDef(data)
        ? {
            waitingForInstructions: data.waitingForInstructions,
            waitingForShipment: data.waitingForShipment,
            waitingForAcknowledgment: data.waitingForAcknowledgment,
          }
        : undefined,
    // @sort
    [data],
  );

  if (isDef(error)) {
    throw error;
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentOrderReturnsWaitingContext.Provider value={value}>{children}</AgentOrderReturnsWaitingContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
