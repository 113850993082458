import { Typography } from '@src/components/appearance/basics/Typography';
import type { TCurrencyBase } from '@src/components/appearance/controls/Currency';
import { Currency } from '@src/components/appearance/controls/Currency';
import type { TTextAreaBase } from '@src/components/appearance/controls/TextArea';
import { TextArea } from '@src/components/appearance/controls/TextArea';
import { Card } from '@src/components/appearance/fragments/Card';
import { Drawer } from '@src/components/appearance/structure/Drawer';
import { OrderBaseGroupCard } from '@src/components/mixins/cards/OrderBaseGroupCard';
import type { TAgentOrderBaseGroupBaseFragment } from '@src/gen/graphql/bindings';
import { AgentOrderBaseGroupManager } from '@src/gen/shared/data/agentOrders';
import { SOURCE_TAX_ESTIMATES, getSource } from '@src/gen/shared/enums/source';
import { formatDollarsCurrency, formatPercent } from '@src/gen/shared/utils/converters';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';

export type TOrderBaseGroupEditDrawerPanelBase = {
  doOrderBaseGroupUpdateAsync: TAgentOrderActionsTypes['DoOrderBaseGroupUpdateAsync'];
  orderBaseGroupManager: AgentOrderBaseGroupManager;
};

export type TOrderBaseGroupEditDrawerPanelState = {
  orderBaseGroupManager: AgentOrderBaseGroupManager;
};

type TMutateOrderBaseGroupEditDrawerPanelState = (
  callback: (orderBaseGroup: TAgentOrderBaseGroupBaseFragment) => TAgentOrderBaseGroupBaseFragment,
) => void;

export type TOrderBaseGroupEditDrawerPanel = TProps<false, TOrderBaseGroupEditDrawerPanelBase, 'div'>;
export const ORDER_BASE_GROUP_EDIT_DRAWER_PANEL_CLASS_NAME = 'wp-order-base-group-edit-drawer-panel';

export const OrderBaseGroupEditDrawerPanel = withCssToString(
  ORDER_BASE_GROUP_EDIT_DRAWER_PANEL_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderBaseGroupEditDrawerPanel>(
      ({ doOrderBaseGroupUpdateAsync, orderBaseGroupManager, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_BASE_GROUP_EDIT_DRAWER_PANEL_CLASS_NAME),
          [className],
        );

        const [state, setState] = useState<TOrderBaseGroupEditDrawerPanelState>({
          orderBaseGroupManager: new AgentOrderBaseGroupManager(
            orderBaseGroupManager.orderBaseGroup,
            orderBaseGroupManager.orderEntryManagers,
          ),
        });

        const mutateState = useCallback<TMutateOrderBaseGroupEditDrawerPanelState>((callback) => {
          setState((prevState) => ({
            orderBaseGroupManager: new AgentOrderBaseGroupManager(
              callback(prevState.orderBaseGroupManager.orderBaseGroup),
              prevState.orderBaseGroupManager.orderEntryManagers,
            ),
          }));
        }, []);

        const handleSave = useCallback(
          async (): Promise<void> => {
            await doOrderBaseGroupUpdateAsync({
              orderBaseGroupId: state.orderBaseGroupManager.orderBaseGroup.id,
              shipping: state.orderBaseGroupManager.orderBaseGroup.shipping,
              tax: state.orderBaseGroupManager.orderBaseGroup.tax,
              notes: state.orderBaseGroupManager.orderBaseGroup.notes,
            });
          },
          // @sort
          [
            doOrderBaseGroupUpdateAsync,
            state.orderBaseGroupManager.orderBaseGroup.id,
            state.orderBaseGroupManager.orderBaseGroup.notes,
            state.orderBaseGroupManager.orderBaseGroup.shipping,
            state.orderBaseGroupManager.orderBaseGroup.tax,
          ],
        );

        return (
          <Drawer.Panel {...rest} className={joinedClassName} ref={ref}>
            <Drawer.Header title='Edit Base Order' />
            <Drawer.ScrollContent>
              <Drawer.Group>
                <OrderBaseGroupCard
                  beginOrderBaseGroupEdit={null}
                  beginOrderEntryBaseProductView={null}
                  beginOrderEntryEdit={null}
                  isEditable={false}
                  notesUpdater={null}
                  orderBaseGroupManager={state.orderBaseGroupManager}
                />
                <EditComponent state={state} mutateState={mutateState} />
              </Drawer.Group>
            </Drawer.ScrollContent>
            <Drawer.Footer
              buttonAction={{
                isAsync: true,
                onClick: handleSave,
                text: 'Save',
              }}
            />
          </Drawer.Panel>
        );
      },
    ),
  ),
);

const SEditComponentDiv = styled('div', {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '$controlGap',
});

function EditComponent({
  state,
  mutateState,
}: {
  state: TOrderBaseGroupEditDrawerPanelState;
  mutateState: TMutateOrderBaseGroupEditDrawerPanelState;
}): JSX.Element {
  const handleShippingChange = useCallback<TCurrencyBase['onChange']>(
    (newValue) => {
      mutateState((orderBaseGroup) => ({
        ...orderBaseGroup,
        shipping: newValue,
      }));
    },
    [mutateState],
  );

  const handleTaxChange = useCallback<TCurrencyBase['onChange']>(
    (newValue) => {
      mutateState((orderBaseGroup) => ({
        ...orderBaseGroup,
        tax: newValue,
      }));
    },
    [mutateState],
  );

  const handleNotesChange = useCallback<TTextAreaBase['onChange']>(
    (e) => {
      mutateState((orderBaseGroup) => ({
        ...orderBaseGroup,
        notes: e.target.value,
      }));
    },
    [mutateState],
  );

  const sourceTaxEstimate = useMemo(
    () => SOURCE_TAX_ESTIMATES[getSource(state.orderBaseGroupManager.orderBaseGroup.source)],
    [state.orderBaseGroupManager.orderBaseGroup.source],
  );

  return (
    <Card.Container flush={true} interactive={false} variant='form'>
      <SEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-base-group-shipping-edit'
          required={false}
          rigid={true}
          text='Shipping'
        />
        <Currency
          id='order-base-group-shipping-edit'
          value={state.orderBaseGroupManager.orderBaseGroup.shipping ?? 0}
          onChange={handleShippingChange}
        />
      </SEditComponentDiv>
      <SEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-base-group-tax-edit'
          required={false}
          rigid={true}
          text='Tax'
        />
        <Currency
          id='order-base-group-tax-edit'
          value={state.orderBaseGroupManager.orderBaseGroup.tax ?? 0}
          onChange={handleTaxChange}
        />
        {isDef(sourceTaxEstimate) && isDef(state.orderBaseGroupManager.maybeGetBaseSubTotal()) && (
          <Typography.Annotation
            css={{ paddingTop: '2px' }}
            text={`Estimated Tax ${formatPercent(sourceTaxEstimate)} of ${formatDollarsCurrency(
              ensureDef(state.orderBaseGroupManager.maybeGetBaseSubTotal()),
            )} → ${formatDollarsCurrency(
              Math.round((ensureDef(state.orderBaseGroupManager.maybeGetBaseSubTotal()) * sourceTaxEstimate) / 10000),
            )} `}
          />
        )}
      </SEditComponentDiv>
      <SEditComponentDiv>
        <Typography.Label
          expanding={true}
          htmlFor='order-base-group-notes-edit'
          required={false}
          rigid={true}
          text='Notes'
        />
        <TextArea
          id='order-base-group-notes-edit'
          value={state.orderBaseGroupManager.orderBaseGroup.notes ?? ''}
          onChange={handleNotesChange}
          maxLength={2048}
        />
        <Typography.Annotation
          text={`(${state.orderBaseGroupManager.orderBaseGroup.notes?.length ?? 0} / 2048)`}
          css={{ alignSelf: 'flex-end', paddingTop: '4px' }}
        />
      </SEditComponentDiv>
    </Card.Container>
  );
}
