import { getDateAgo } from '@src/gen/shared/data/snippets';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';

const SSpan = styled('span', {
  variants: {
    expanding: {
      false: {
        // intentionally empty
      },
      true: {
        flexGrow: 1,
      },
    },
    rigid: {
      false: {
        // intentionally empty
      },
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    typography: {
      caption: {
        color: '$typographyCaptionText',
        text: '$caption',
      },
      small: {
        color: '$typographySmallText',
        text: '$small',
      },
    },
  },
});

export type TAgoBase = {
  at: Date | string;
  expanding?: boolean | undefined;
  prefix?: string;
  rigid?: boolean | undefined;
  suffix?: string;
  typography: 'caption' | 'small';
};

export type TAgo = TProps<false, TAgoBase, 'span'>;
export const AGO_CLASS_NAME = 'wp-ago';

export const Ago = withCssToString(
  AGO_CLASS_NAME,
  memo(
    forwardRef<HTMLSpanElement, TAgo>(
      ({ at, expanding, prefix, rigid, suffix, typography, className, ...rest }, ref) => {
        const joinedClassName = useMemo(() => joinClassNames(className, AGO_CLASS_NAME), [className]);
        const getText = useCallback(() => `${prefix ?? ''}${getDateAgo(at)}${suffix ?? ''}`, [at, prefix, suffix]);
        const [text, setText] = useState(getText());

        useEffect(() => {
          setText(getText());
          const interval = setInterval(() => setText(getText()), 30000);
          return (): void => clearInterval(interval);
        }, [getText]);

        return (
          <SSpan
            {...rest}
            className={joinedClassName}
            expanding={expanding === true}
            ref={ref}
            rigid={rigid === true}
            typography={typography}>
            {text}
          </SSpan>
        );
      },
    ),
  ),
);
