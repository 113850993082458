import { CheckBoxControl } from '@src/components/appearance/controls/CheckBox';
import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { SelectControl } from '@src/components/appearance/controls/Select';
import type { TAgentOrderBaseFragment } from '@src/gen/graphql/bindings';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

const SCheckboxesDiv = styled('div', {
  display: 'grid',
  gap: '$formControlGap',
  gridTemplateColumns: 'repeat(auto-fit, minMax(250px, 1fr))',
});

export type TOrderStatusEditFormValues = {
  status: string;
  isBlockedOnCustomer: boolean;
  hasBackOrderedProducts: boolean;
  hasPendingReturns: boolean;
  isPaid: boolean;
};

export type TOrderStatusEditFormBase = {
  doOrderUpdateAsync: TAgentOrderActionsTypes['DoOrderUpdateAsync'];
  order: Pick<
    TAgentOrderBaseFragment,
    'has_back_ordered_products' | 'has_pending_returns' | 'is_blocked_on_customer' | 'is_paid' | 'status'
  >;
};

export type TOrderStatusEditForm = TProps<false, TOrderStatusEditFormBase, 'div'>;
export const ORDER_STATUS_EDIT_FORM_CLASS_NAME = 'wp-order-status-edit-form';

export const OrderStatusEditForm = withCssToString(
  ORDER_STATUS_EDIT_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderStatusEditForm>(
      ({ doOrderUpdateAsync, order, className, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_STATUS_EDIT_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TOrderStatusEditFormValues>(
          () => ({
            status: order.status,
            isBlockedOnCustomer: order.is_blocked_on_customer,
            hasBackOrderedProducts: order.has_back_ordered_products,
            hasPendingReturns: order.has_pending_returns,
            isPaid: order.is_paid,
          }),
          [order],
        );

        const handleSubmit = useCallback<TFormSubmit<TOrderStatusEditFormValues>>(
          async (values) => {
            // TODO (ibrt): Only apply diff.
            await doOrderUpdateAsync(values);
            return undefined;
          },
          [doOrderUpdateAsync],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TOrderStatusEditFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              requireChanges={true}
              submitButtonText='Save'>
              <SelectControl id='status' label='Status'>
                <option value='received'>Received</option>
                <option value='preparing'>Preparing</option>
                <option value='processing'>Processing</option>
                <option value='shipping'>Shipping</option>
                <option value='completed'>Completed</option>
              </SelectControl>
              <SCheckboxesDiv>
                <CheckBoxControl id='isBlockedOnCustomer' label='Waiting On Customer' />
                <CheckBoxControl id='hasBackOrderedProducts' label='Back-Ordered Products' />
                <CheckBoxControl id='hasPendingReturns' label='Pending Returns' />
                <CheckBoxControl id='isPaid' label='Paid' />
              </SCheckboxesDiv>
            </Form>
          </div>
        );
      },
    ),
  ),
);
