import type { CustomerGenerateCustomerOrdersDashboardExportUrlMutationVariables } from '@src/gen/graphql/bindings';
import {
  useCustomerGenerateCustomerOrdersDashboardExportUrlMutation,
  useCustomerGenerateOrderSnapshotExportUrlMutation,
} from '@src/gen/graphql/bindings';
import type { EExportFormat } from '@src/gen/shared/enums/exportFormat';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext, downloadUrl } from '@src/logic/internal/utils/utils';
import { useErrors } from '@src/modules/errors/ErrorsProvider';
import { EErrorSummaryDisplayMode, getErrorSummary } from '@src/modules/errors/errorSummary';
import { encodeCustomerOrderSummaryPath, useCustomerRouteOrganization } from '@src/modules/routing/customer';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

export type TCustomerAnalyticsOrdersActionsTypes = {
  DoGenerateOrderTo: (args: { locationId: string; orderId: string }) => string | null;
  DownloadOrderSnapshotAsyncNotify: (args: { orderSnapshotId: string; exportFormat: EExportFormat }) => Promise<void>;
  DownloadOrdersDashboardExportAsyncNotify: (
    args: CustomerGenerateCustomerOrdersDashboardExportUrlMutationVariables,
  ) => Promise<void>;
};

export type TCustomerAnalyticsOrdersActionsContext = {
  doGenerateOrderTo: TCustomerAnalyticsOrdersActionsTypes['DoGenerateOrderTo'];
  downloadOrderSnapshotAsyncNotify: TCustomerAnalyticsOrdersActionsTypes['DownloadOrderSnapshotAsyncNotify'];
  downloadOrdersDashboardExportAsyncNotify: TCustomerAnalyticsOrdersActionsTypes['DownloadOrdersDashboardExportAsyncNotify'];
};

export const { Context: CustomerAnalyticsOrdersActionsContext, useContext: useCustomerAnalyticsOrdersActions } =
  createRequiredContext<TCustomerAnalyticsOrdersActionsContext>();

export function CustomerAnalyticsOrdersActionsProvider({ children }: PropsWithChildren): JSX.Element {
  const doGenerateOrderTo = useDoGenerateOrderTo();
  const downloadOrderSnapshotAsyncNotify = useDownloadOrderSnapshotAsyncNotify();
  const downloadOrdersDashboardExportAsyncNotify = useDownloadOrdersDashboardExportAsyncNotify();

  const value = useMemo<TCustomerAnalyticsOrdersActionsContext>(
    () => ({
      doGenerateOrderTo,
      downloadOrderSnapshotAsyncNotify,
      downloadOrdersDashboardExportAsyncNotify,
    }),
    // @sort
    [doGenerateOrderTo, downloadOrderSnapshotAsyncNotify, downloadOrdersDashboardExportAsyncNotify],
  );

  return (
    <CustomerAnalyticsOrdersActionsContext.Provider value={value}>
      {children}
    </CustomerAnalyticsOrdersActionsContext.Provider>
  );
}

function useDoGenerateOrderTo(): TCustomerAnalyticsOrdersActionsTypes['DoGenerateOrderTo'] {
  const organization = useCustomerRouteOrganization();

  const canViewOrdersByLocationId = organization.locations
    .filter((loc) => loc.acl.can_view_orders)
    .reduce<{ [key: string]: true }>((out, loc) => ({ ...out, [loc.id]: true }), {});

  return useCallback<TCustomerAnalyticsOrdersActionsTypes['DoGenerateOrderTo']>(
    ({ locationId, orderId }) => {
      return isDef(canViewOrdersByLocationId[locationId])
        ? encodeCustomerOrderSummaryPath(organization.id, orderId)
        : null;
    },
    [canViewOrdersByLocationId, organization.id],
  );
}

function useDownloadOrderSnapshotAsyncNotify(): TCustomerAnalyticsOrdersActionsTypes['DownloadOrderSnapshotAsyncNotify'] {
  const [generateOrderSnapshotExportUrlMutation] = useCustomerGenerateOrderSnapshotExportUrlMutation();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TCustomerAnalyticsOrdersActionsTypes['DownloadOrderSnapshotAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateOrderSnapshotExportUrlMutation({
          variables: {
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateOrderSnapshotExportUrl.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateOrderSnapshotExportUrlMutation],
  );
}

function useDownloadOrdersDashboardExportAsyncNotify(): TCustomerAnalyticsOrdersActionsTypes['DownloadOrdersDashboardExportAsyncNotify'] {
  const [generateCustomerOrdersDashboardExportUrlMutation] =
    useCustomerGenerateCustomerOrdersDashboardExportUrlMutation();
  const { doErrorNotify, doErrorClear } = useErrors();

  return useCallback<TCustomerAnalyticsOrdersActionsTypes['DownloadOrdersDashboardExportAsyncNotify']>(
    async (args) => {
      try {
        doErrorClear();

        const { data, errors } = await generateCustomerOrdersDashboardExportUrlMutation({
          variables: {
            ...args,
          },
        });

        if (isDef(errors)) {
          throw errors;
        }

        downloadUrl(ensureDef(data?.generateCustomerOrdersDashboardExportUrl.url));
      } catch (thrown: unknown) {
        doErrorNotify(
          getErrorSummary(thrown, {
            displayContext: 'While generating a secure download link.',
            forceDisplayMode: EErrorSummaryDisplayMode.TOAST,
          }),
        );
      }
    },
    // @sort
    [doErrorClear, doErrorNotify, generateCustomerOrdersDashboardExportUrlMutation],
  );
}
