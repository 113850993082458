import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import { Structure } from '@src/components/appearance/structure/Structure';
import { ProductHintBaseProductCard } from '@src/components/mixins/cards/ProductHintBaseProductCard';
import { ProductHintPlanProductCard } from '@src/components/mixins/cards/ProductHintPlanProductCard';
import type { AgentProductHintManager } from '@src/gen/shared/data/agentOrders';
import { getDateAgo } from '@src/gen/shared/data/snippets';
import { maybeGetOrderEntryPlanStatusLabel } from '@src/gen/shared/enums/orderEntryPlanStatus';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useMemo } from 'react';

const SOuterDiv = styled('div', {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  gap: '$cardGap',
});

const SInnerDiv = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$gray1',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$structureGroupGapDesktop',
  padding: '$cardPadding',
});

const SActionsDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$cardGap',
});

export type TProductHintStructureGroupBase = {
  beginProductHintBaseProductView: TAgentOrderActionsTypes['BeginProductHintBaseProductView'];
  beginProductHintPlanProductView: TAgentOrderActionsTypes['BeginProductHintPlanProductView'];
  productHintManager: AgentProductHintManager;
  onSelect: (mode: 'base' | 'both' | 'plan') => void;
};

export type TProductHintStructureGroup = TProps<false, TProductHintStructureGroupBase, 'div'>;
export const ORDER_ENTRY_STRUCTURE_GROUP = 'wp-order-entry-structure-group';

export const ProductHintStructureGroup = withCssToString(
  ORDER_ENTRY_STRUCTURE_GROUP,
  memo(
    forwardRef<HTMLDivElement, TProductHintStructureGroup>(
      (
        {
          beginProductHintBaseProductView,
          beginProductHintPlanProductView,
          productHintManager,
          onSelect,
          className,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_ENTRY_STRUCTURE_GROUP), [className]);

        return (
          <SOuterDiv>
            <SInnerDiv>
              <Structure.Group
                {...rest}
                className={joinedClassName}
                ref={ref}
                title='Base'
                tag={getDateAgo(productHintManager.productHint.created_at)}
                variant='secondary'>
                <ProductHintBaseProductCard
                  productHintManager={productHintManager}
                  onClick={(): void => beginProductHintBaseProductView({ productHintManager })}
                />
              </Structure.Group>
              <Structure.Group
                title='Plan'
                tag={maybeGetOrderEntryPlanStatusLabel(productHintManager.productHint.plan_status)}
                variant='secondary'>
                {productHintManager.isPurchasedByWellplaece() && (
                  <ProductHintPlanProductCard
                    productHintManager={productHintManager}
                    onClick={(): void =>
                      beginProductHintPlanProductView({
                        productHintManager,
                      })
                    }
                  />
                )}
              </Structure.Group>
            </SInnerDiv>
            <SActionsDiv>
              <ActionIconButton
                action={{
                  isAsync: false,
                  icon: 'view',
                  annotation: 'Base',
                  onClick: (): void => onSelect('base'),
                }}
              />
              {isDef(productHintManager.productHint.plan_product_id) && (
                <Fragment>
                  <ActionIconButton
                    action={{
                      isAsync: false,
                      icon: 'view',
                      annotation: 'Plan',
                      onClick: (): void => onSelect('plan'),
                    }}
                  />
                  <ActionIconButton
                    action={{
                      isAsync: false,
                      icon: 'view',
                      annotation: 'Both',
                      onClick: (): void => onSelect('both'),
                    }}
                  />
                </Fragment>
              )}
            </SActionsDiv>
          </SOuterDiv>
        );
      },
    ),
  ),
);
