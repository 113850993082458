import { ActionIconButton } from '@src/components/appearance/controls/IconButton';
import { Select } from '@src/components/appearance/controls/Select';
import type { TTabSelectBase } from '@src/components/appearance/controls/TabSelect';
import { Banner } from '@src/components/appearance/fragments/Banner';
import { ToolBar } from '@src/components/appearance/fragments/ToolBar';
import { Structure } from '@src/components/appearance/structure/Structure';
import { OrderShipmentCard } from '@src/components/mixins/cards/OrderShipmentCard';
import type { TAgentOrderShipmentCompleteFragment } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { useAgentOrderActions } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import { useAgentOrder } from '@src/modules/data/agent/order/AgentOrderProvider';
import type { TAgentOrderWorksheetShipmentsRouteParamsConfig } from '@src/modules/routing/agent';
import {
  encodeAgentOrderWorksheetShipmentsPath,
  useAgentOrderWorksheetShipmentsRouteParams,
} from '@src/modules/routing/agent';
import { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function AgentOrderWorksheetShipmentsPart(): JSX.Element {
  const navigate = useNavigate();
  const routeParams = useAgentOrderWorksheetShipmentsRouteParams();
  const { order, orderManager } = useAgentOrder();

  const {
    beginOrderShipmentAdd,
    beginOrderShipmentProductView,
    beginOrderTrackingHistoryView,
    doOrderShipmentSetDeliveredAsyncNotify,
    doOrderShipmentRemoveAsyncNotify,
  } = useAgentOrderActions();

  const shipping = useMemo(() => order.order_shipments.filter((os) => !os.is_delivered), [order.order_shipments]);
  const delivered = useMemo(() => order.order_shipments.filter((os) => os.is_delivered), [order.order_shipments]);

  const handleFilterStatusSelectChange = useCallback<TTabSelectBase['onChange']>(
    (e) => {
      navigate(
        encodeAgentOrderWorksheetShipmentsPath(routeParams.orderId, {
          ...routeParams.config,
          status: e.target.value as TAgentOrderWorksheetShipmentsRouteParamsConfig['status'], // eslint-disable-line @typescript-eslint/consistent-type-assertions
        }),
      );
    },
    [navigate, routeParams.config, routeParams.orderId],
  );

  const showOrderShipments = useMemo<TAgentOrderShipmentCompleteFragment[]>(
    () =>
      order.order_shipments.filter((op) => {
        switch (routeParams.config.status) {
          case 'any':
            return true;
          case 'tracked':
            return !op.is_delivered;
          case 'delivered':
            return op.is_delivered;
        }
      }),
    // @sort
    [order.order_shipments, routeParams.config.status],
  );

  if (!isDef(orderManager.maybeGetOrderSnapshotInvoiceLatest())) {
    return (
      <Structure.ScrollContent skipSeparator={true}>
        <Banner icon='info' message='Shipments will be enabled once an invoice is issued.' />
      </Structure.ScrollContent>
    );
  }

  return (
    <Fragment>
      <Structure.Content>
        <ToolBar
          RightChildren={
            <Fragment>
              <ActionIconButton action={{ isAsync: false, icon: 'add', onClick: beginOrderShipmentAdd }} />
            </Fragment>
          }
          variant='structure'>
          <Select onChange={handleFilterStatusSelectChange} value={routeParams.config.status}>
            <option value='any'>Status: Any</option>
            <option value='tracked'>Status: Tracked</option>
            <option value='delivered'>Status: Delivered</option>
          </Select>
        </ToolBar>
      </Structure.Content>
      <Structure.ScrollContent skipSeparator={true}>
        <Structure.Stack>
          {routeParams.config.status === 'any' && (
            <Fragment>
              {order.order_shipments.length === 0 && (
                <Banner icon='info' message='Click on the + button to add a shipment.' />
              )}
              {shipping.length > 0 && (
                <Structure.Group title='Status: Tracked' variant='secondary'>
                  {shipping.map((os) => (
                    <OrderShipmentCard
                      key={os.id}
                      beginOrderShipmentProductView={beginOrderShipmentProductView}
                      beginOrderTrackingHistoryView={beginOrderTrackingHistoryView}
                      orderShipment={os}
                      onDeleteAsyncNotify={async (): Promise<void> =>
                        await doOrderShipmentRemoveAsyncNotify({ orderShipmentId: os.id })
                      }
                      onSetDeliveredAsyncNotify={async (): Promise<void> => {
                        await doOrderShipmentSetDeliveredAsyncNotify({ orderShipmentId: os.id });
                      }}
                    />
                  ))}
                </Structure.Group>
              )}
              {delivered.length > 0 && (
                <Structure.Group title='Status: Delivered' variant='secondary'>
                  {delivered.map((os) => (
                    <OrderShipmentCard
                      key={os.id}
                      beginOrderShipmentProductView={beginOrderShipmentProductView}
                      beginOrderTrackingHistoryView={beginOrderTrackingHistoryView}
                      orderShipment={os}
                      onDeleteAsyncNotify={async (): Promise<void> =>
                        await doOrderShipmentRemoveAsyncNotify({ orderShipmentId: os.id })
                      }
                      onSetDeliveredAsyncNotify={async (): Promise<void> => {
                        await doOrderShipmentSetDeliveredAsyncNotify({ orderShipmentId: os.id });
                      }}
                    />
                  ))}
                </Structure.Group>
              )}
            </Fragment>
          )}
          {routeParams.config.status !== 'any' && (
            <Fragment>
              {showOrderShipments.length === 0 && (
                <Banner icon='apply' message='No shipments matching these filters.' />
              )}
              {showOrderShipments.map((os) => (
                <OrderShipmentCard
                  key={os.id}
                  beginOrderShipmentProductView={beginOrderShipmentProductView}
                  beginOrderTrackingHistoryView={beginOrderTrackingHistoryView}
                  orderShipment={os}
                  onDeleteAsyncNotify={async (): Promise<void> =>
                    await doOrderShipmentRemoveAsyncNotify({ orderShipmentId: os.id })
                  }
                  onSetDeliveredAsyncNotify={async (): Promise<void> => {
                    await doOrderShipmentSetDeliveredAsyncNotify({ orderShipmentId: os.id });
                  }}
                />
              ))}
            </Fragment>
          )}
        </Structure.Stack>
      </Structure.ScrollContent>
    </Fragment>
  );
}
