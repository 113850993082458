import type { ApolloQueryResult } from '@apollo/client';
import { NetworkStatus } from '@apollo/client';
import type {
  AgentCustomersUserQuery,
  AgentCustomersUserQueryVariables,
  TAgentCustomersUserCompleteFragment,
} from '@src/gen/graphql/bindings';
import { useAgentCustomersUserQuery } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import { useAgentCustomersUserRouteParams } from '@src/modules/routing/agent';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TAgentCustomersUserContext = {
  user: TAgentCustomersUserCompleteFragment;
  doRefetchUser: () => Promise<ApolloQueryResult<AgentCustomersUserQuery>>;
};

export const { Context: AgentCustomersUserContext, useContext: useAgentCustomersUser } =
  createRequiredContext<TAgentCustomersUserContext>();

export type TAgentCustomersUserProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
}>;

export function AgentCustomersUserProvider({ LoaderComponent, children }: TAgentCustomersUserProvider): JSX.Element {
  const { userId } = useAgentCustomersUserRouteParams();

  const variables = useMemo<AgentCustomersUserQueryVariables>(
    () => ({
      userId,
    }),
    // @sort
    [userId],
  );

  const { data, error, networkStatus, refetch } = useAgentCustomersUserQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const value = useMemo<TAgentCustomersUserContext | undefined>(
    () =>
      isDef(data) && isDef(data.users_by_pk)
        ? {
            user: data.users_by_pk,
            doRefetchUser: refetch,
          }
        : undefined,
    // @sort
    [data, refetch],
  );

  if (isDef(error)) {
    throw error;
  }

  if (isDef(data) && !isDef(data.users_by_pk)) {
    throw new Error('Not Found');
  }

  return isDef(value) && networkStatus !== NetworkStatus.loading ? (
    <AgentCustomersUserContext.Provider value={value}>{children}</AgentCustomersUserContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
