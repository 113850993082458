import type { TIconButtonAction } from '@src/components/appearance/controls/IconButton';
import { ActionIconButton, getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemBaseItem } from '@src/components/appearance/fragments/Item';
import { Item } from '@src/components/appearance/fragments/Item';
import { ItemButton } from '@src/components/appearance/fragments/ItemButton';
import type { TItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { ItemsBanner } from '@src/components/appearance/fragments/ItemsBanner';
import { NotesCard } from '@src/components/mixins/cards/NotesCard';
import type { TAgentUserBaseFragment } from '@src/gen/graphql/bindings';
import type { AgentOrderEntryManager, AgentOrderPlanGroupManager } from '@src/gen/shared/data/agentOrders';
import { getCombinedProductName } from '@src/gen/shared/data/snippets';
import { maybeGetOrderEntryPlanStatusLabel } from '@src/gen/shared/enums/orderEntryPlanStatus';
import { getSource, getSourceName } from '@src/gen/shared/enums/source';
import { maybeFormatDollarsCurrency } from '@src/gen/shared/utils/converters';
import { ensureDef, isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { maybeOpenUrls, withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentOrderActionsTypes } from '@src/modules/data/agent/order/AgentOrderActionsProvider';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SItemButtonDiv = styled('div', {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  gap: '$cardGap',
});

export type TOrderPlanGroupCardBase = {
  beginOrderEntryEdit: TAgentOrderActionsTypes['BeginOrderEntryEdit'] | null;
  beginOrderEntryPlanProductView: TAgentOrderActionsTypes['BeginOrderEntryPlanProductView'] | null;
  beginOrderPlanGroupEdit: TAgentOrderActionsTypes['BeginOrderPlanGroupEdit'] | null;
  orderPlanGroupManager: AgentOrderPlanGroupManager;
  notesUpdater: TAgentUserBaseFragment | null;
  isEditable: boolean;
};

export type TOrderPlanGroupCard = TProps<false, TOrderPlanGroupCardBase, 'div'>;
export const ORDER_PLAN_GROUP_CARD_CLASS_NAME = 'wp-order-plan-group-card';

export const OrderPlanGroupCard = withCssToString(
  ORDER_PLAN_GROUP_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderPlanGroupCard>(
      (
        {
          beginOrderEntryEdit,
          beginOrderEntryPlanProductView,
          beginOrderPlanGroupEdit,
          orderPlanGroupManager,
          notesUpdater,
          className,
          isEditable,
          ...rest
        },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(() => joinClassNames(className, ORDER_PLAN_GROUP_CARD_CLASS_NAME), [className]);
        const productPageUrls = useMemo<string[]>(
          () =>
            orderPlanGroupManager.orderEntryManagers
              .filter((oem) => !oem.isRemoved() && isDef(oem.orderEntry.plan_product_page_url))
              .map((oem) => ensureDef(oem.orderEntry.plan_product_page_url)),
          [orderPlanGroupManager.orderEntryManagers],
        );

        const iconButtonActions = useMemo<TIconButtonAction[]>(
          () =>
            getAvailableIconButtonActions([
              {
                isAsync: false,
                variant: orderPlanGroupManager.isRemoved() || !isEditable ? 'disabled' : undefined,
                icon: 'edit',
                onClick: isDef(beginOrderPlanGroupEdit)
                  ? (): void => beginOrderPlanGroupEdit({ orderPlanGroupId: orderPlanGroupManager.orderPlanGroup.id })
                  : undefined,
              },
              {
                annotation: productPageUrls.length > 1 ? `${productPageUrls.length}` : undefined,
                icon: 'link',
                isAsync: false,
                onClick: (): void => maybeOpenUrls(productPageUrls),
              },
            ]),
          // @sort
          [beginOrderPlanGroupEdit, isEditable, orderPlanGroupManager, productPageUrls],
        );

        const items = useMemo<TItemsBanner['items']>(
          () => [
            {
              caption: 'Sub Total',
              text: orderPlanGroupManager.isRemoved()
                ? 'Not Needed'
                : maybeFormatDollarsCurrency(orderPlanGroupManager.maybeGetPlanSubTotal()) ?? 'TBD',
            },
            {
              caption: 'Shipping',
              text: orderPlanGroupManager.isRemoved()
                ? 'Not Needed'
                : maybeFormatDollarsCurrency(orderPlanGroupManager.orderPlanGroup.shipping) ?? 'TBD',
            },
            {
              caption: 'Tax',
              text: orderPlanGroupManager.isRemoved()
                ? 'Not Needed'
                : maybeFormatDollarsCurrency(orderPlanGroupManager.orderPlanGroup.tax) ?? 'TBD',
            },
            {
              caption: 'Total',
              text: orderPlanGroupManager.isRemoved()
                ? 'Not Needed'
                : maybeFormatDollarsCurrency(orderPlanGroupManager.maybeGetPlanTotal()) ?? 'TBD',
            },
          ],
          [orderPlanGroupManager],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flush={true}
            iconButtonActions={iconButtonActions}
            interactive={false}
            ref={ref}>
            <Card.Title
              title={getSourceName(getSource(orderPlanGroupManager.orderPlanGroup.source))}
              subTitle={
                isDef(orderPlanGroupManager.orderPlanGroup.supplier_order_reference)
                  ? `Ref. ${orderPlanGroupManager.orderPlanGroup.supplier_order_reference}`
                  : undefined
              }
            />
            {orderPlanGroupManager.orderEntryManagers.map((oem) => (
              <SItemButtonDiv key={oem.orderEntry.id}>
                {isDef(beginOrderEntryPlanProductView) ? (
                  <ItemButton
                    css={{ flexGrow: 1 }}
                    icon={isDef(oem.maybeGetPlanSubTotal()) ? undefined : 'warning'}
                    item={getPlanGroupOrderEntryItem(oem)}
                    onClick={(): void => beginOrderEntryPlanProductView({ orderEntryId: oem.orderEntry.id })}
                    variant='control'
                  />
                ) : (
                  <Item item={getPlanGroupOrderEntryItem(oem)} css={{ flexGrow: 1 }} />
                )}
                {isDef(beginOrderEntryEdit) && (
                  <ActionIconButton
                    action={{
                      isAsync: false,
                      icon: 'edit',
                      variant: !isEditable ? 'disabled' : 'default',
                      onClick: (): void => beginOrderEntryEdit({ orderEntryId: oem.orderEntry.id }),
                    }}
                  />
                )}
              </SItemButtonDiv>
            ))}
            <ItemsBanner items={items} />
            {isDef(notesUpdater) && isDef(orderPlanGroupManager.orderPlanGroup.notes) && (
              <NotesCard
                content={orderPlanGroupManager.orderPlanGroup.notes}
                updatedAt={ensureDef(orderPlanGroupManager.orderPlanGroup.notes_updated_at)}
                updater={notesUpdater}
              />
            )}
          </Card.Container>
        );
      },
    ),
  ),
);

function getPlanGroupOrderEntryItem(orderEntryManager: AgentOrderEntryManager): TItemBaseItem {
  const orderEntry = orderEntryManager.orderEntry;
  const planProduct = orderEntryManager.mustGetPlanProduct();

  const prefix = `${planProduct.product_sku} — Qty. ${orderEntry.plan_quantity ?? 'TBD'}`;
  const unitPrice = maybeFormatDollarsCurrency(orderEntry.plan_unit_price) ?? 'TBD';
  const subTotal = maybeFormatDollarsCurrency(orderEntryManager.maybeGetPlanSubTotal()) ?? 'TBD';
  const suffix = maybeGetOrderEntryPlanStatusLabel(orderEntry.plan_status);

  return {
    caption: `${prefix} x ${unitPrice} = ${subTotal} — ${suffix}`,
    text: getCombinedProductName(planProduct.name, planProduct.secondary_name),
  };
}
