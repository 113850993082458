import { IconButton } from '@src/components/appearance/controls/IconButton';
import { SecondaryNav } from '@src/components/appearance/structure/SecondaryNav';
import { getFullName } from '@src/gen/shared/data/snippets';
import { useAuth, useAuthAgent } from '@src/modules/auth/AuthProvider';

export function AgentUserNavPart(): JSX.Element {
  const agent = useAuthAgent();
  const { doSignOut } = useAuth();

  return (
    <SecondaryNav
      IconButtonElement={<IconButton icon='exit' onClick={doSignOut} />}
      label={agent.email}
      title={getFullName(agent)}
    />
  );
}
