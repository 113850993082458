import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EOrderEntryPlanStatus {
  NONE = 'none',
  SAME_PRODUCT_SAME_SUPPLIER = 'same_product_same_supplier',
  SAME_PRODUCT_DIFFERENT_SUPPLIER = 'same_product_different_supplier',
  SUBSTITUTION_REASON_BACK_ORDERED = 'substitution_reason_back_ordered',
  SUBSTITUTION_REASON_BETTER_PRICE = 'substitution_reason_better_price',
  SUBSTITUTION_REASON_DISCONTINUED = 'substitution_reason_discontinued',
  SUBSTITUTION_REASON_OUT_OF_STOCK = 'substitution_reason_out_of_stock',
  REMOVED_REASON_BACK_ORDERED = 'removed_reason_back_ordered',
  REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE = 'removed_reason_customer_has_best_price',
  REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED = 'removed_reason_customer_has_best_price_substitution_not_allowed',
  REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION = 'removed_reason_customer_has_best_price_no_exact_substitution',
  REMOVED_REASON_CUSTOMER_REQUESTED = 'removed_reason_customer_requested',
  REMOVED_REASON_DISCONTINUED = 'removed_reason_discontinued',
  REMOVED_REASON_OUT_OF_STOCK = 'removed_reason_out_of_stock',
  REMOVED_REASON_RETURNED = 'removed_reason_returned',
}

const ORDER_ENTRY_PLAN_STATUS_LOOKUP = mapValues(invert(EOrderEntryPlanStatus), () => true);

export function getOrderEntryPlanStatus(rawOrderEntryPlanStatus: string): EOrderEntryPlanStatus {
  assert(
    ORDER_ENTRY_PLAN_STATUS_LOOKUP[rawOrderEntryPlanStatus],
    `Unknown order entry plan status: "${rawOrderEntryPlanStatus}".`,
  );
  return rawOrderEntryPlanStatus as EOrderEntryPlanStatus; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetOrderEntryPlanStatus(rawOrderEntryPlanStatus: string): EOrderEntryPlanStatus | undefined {
  try {
    return getOrderEntryPlanStatus(rawOrderEntryPlanStatus);
  } catch {
    return undefined;
  }
}

export const ORDER_ENTRY_PLAN_STATUS_LABELS: { [key in EOrderEntryPlanStatus]: string } = {
  [EOrderEntryPlanStatus.NONE]: 'None',
  [EOrderEntryPlanStatus.SAME_PRODUCT_SAME_SUPPLIER]: 'Same Product, Same Supplier',
  [EOrderEntryPlanStatus.SAME_PRODUCT_DIFFERENT_SUPPLIER]: 'Same Product, Different Supplier',
  [EOrderEntryPlanStatus.SUBSTITUTION_REASON_BACK_ORDERED]: 'Substitution / Reason: Back-Ordered',
  [EOrderEntryPlanStatus.SUBSTITUTION_REASON_BETTER_PRICE]: 'Substitution / Reason: Better Price',
  [EOrderEntryPlanStatus.SUBSTITUTION_REASON_DISCONTINUED]: 'Substitution / Reason: Discontinued',
  [EOrderEntryPlanStatus.SUBSTITUTION_REASON_OUT_OF_STOCK]: 'Substitution / Reason: Out of Stock',
  [EOrderEntryPlanStatus.REMOVED_REASON_BACK_ORDERED]: 'Removed / Reason: Back-Ordered',
  [EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE]: 'Removed / Reason: Customer Has Best Price',
  [EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_SUBSTITUTION_NOT_ALLOWED]:
    'Removed / Reason: Customer Has Best Price (Substitution Not Allowed)',
  [EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_HAS_BEST_PRICE_NO_EXACT_SUBSTITUTION]:
    'Removed / Reason: Customer Has Best Price (No Exact Substitution)',
  [EOrderEntryPlanStatus.REMOVED_REASON_CUSTOMER_REQUESTED]: 'Removed / Reason: Customer Requested',
  [EOrderEntryPlanStatus.REMOVED_REASON_DISCONTINUED]: 'Removed / Reason: Discontinued',
  [EOrderEntryPlanStatus.REMOVED_REASON_OUT_OF_STOCK]: 'Removed / Reason: Out of Stock',
  [EOrderEntryPlanStatus.REMOVED_REASON_RETURNED]: 'Removed / Reason: Returned',
};

export function maybeGetOrderEntryPlanStatusLabel(rawOrderEntryPlanStatus: string): string {
  const orderEntryPlanStatus = maybeGetOrderEntryPlanStatus(rawOrderEntryPlanStatus);
  return orderEntryPlanStatus !== undefined
    ? ensureDef(ORDER_ENTRY_PLAN_STATUS_LABELS[orderEntryPlanStatus])
    : rawOrderEntryPlanStatus;
}
