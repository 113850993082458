import { Ago } from '@src/components/appearance/basics/Ago';
import { Avatar, AvatarLoader } from '@src/components/appearance/basics/Avatar';
import { Illustrations } from '@src/components/appearance/basics/Illustrations';
import { Tag } from '@src/components/appearance/basics/Tag';
import { Typography } from '@src/components/appearance/basics/Typography';
import { Notification } from '@src/components/appearance/fragments/Notification';
import type { TNotification } from '@src/gen/shared/data/notification';
import { getDateOn } from '@src/gen/shared/data/snippets';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { styled } from '@src/modules/design/theme';
import { forwardRef, memo, useMemo } from 'react';

const SDiv = styled('div', {
  alignItems: 'flex-start',
  display: 'flex',

  variants: {
    compact: {
      false: {
        gap: '$chatBubbleSpace',
      },
      true: {
        gap: '$chatBubbleCompactSpace',
      },
    },
    wellplaeceAgent: {
      false: {
        flexDirection: 'row',
      },
      true: {
        flexDirection: 'row-reverse',
      },
    },
  },
});

const SAvatar = styled(Avatar, {
  variants: {
    compact: {
      false: {
        marginTop: '9px',
      },
      true: {
        marginTop: '5px',
      },
    },
  },
});

const SBubbleDiv = styled('div', {
  alignItems: 'stretch',
  borderRadius: '$chatBubble',
  borderStyle: '$regular',
  borderWidth: '$regular',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',

  [`& ${Tag.toString()}`]: {
    alignSelf: 'flex-start',
  },

  variants: {
    compact: {
      false: {
        gap: '$chatBubbleSpace',
        padding: '$chatBubbleSpace',
      },
      true: {
        gap: '$chatBubbleCompactSpace',
        padding: '$chatBubbleCompactSpace',
      },
    },
    wellplaeceAgent: {
      false: {
        backgroundColor: '$chatBubbleCustomerBackground',
        borderColor: '$chatBubbleCustomerBorder',
      },
      true: {
        backgroundColor: '$chatBubbleAgentBackground',
        borderWidth: '0px',

        [`& ${Tag.toString()}`]: {
          backgroundColor: '$chatBubbleCustomerBackground',
        },
      },
    },
  },
});

const SBubbleInnerDiv = styled('div', {
  alignItems: 'baseline',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '$chatBubbleInnerGap',
});

export type TChatBubbleBase = {
  at: Date | string;
  compact?: boolean | undefined;
  firstName: string;
  lastName: string;
  content: TNotification | string;
  wellplaeceAgent?: boolean | undefined;
};

export type TChatBubble = TProps<true, TChatBubbleBase, 'div'>;
export const CHAT_BUBBLE_CLASS_NAME = 'wp-chat-bubble';

export const ChatBubble = withCssToString(
  CHAT_BUBBLE_CLASS_NAME,
  forwardRef<HTMLDivElement, TChatBubble>(
    (
      { at, compact, firstName, lastName, content, wellplaeceAgent, children, className, ...rest },
      ref,
    ): JSX.Element => {
      const joinedClassName = useMemo(() => joinClassNames(className, CHAT_BUBBLE_CLASS_NAME), [className]);
      const fullName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);
      const sentOn = useMemo(() => `Sent on ${getDateOn(at)} `, [at]);

      return (
        <SDiv
          {...rest}
          className={joinedClassName}
          compact={compact === true}
          ref={ref}
          wellplaeceAgent={wellplaeceAgent === true}>
          <SAvatar compact={compact === true} firstName={firstName} lastName={lastName} />
          <SBubbleDiv compact={compact === true} wellplaeceAgent={wellplaeceAgent === true}>
            <SBubbleInnerDiv>
              <Typography.Cta text={fullName} />
              {wellplaeceAgent === true && <Typography.Caption text='from Wellplaece' />}
              {compact === true && <Ago at={at} prefix='(' suffix=')' typography='caption' />}
            </SBubbleInnerDiv>
            {typeof content === 'string' ? (
              <Typography.Small text={content} css={{ whiteSpace: 'pre-wrap' }} />
            ) : (
              <Notification notification={content} />
            )}
            {children}
            {compact !== true && (
              <SBubbleInnerDiv>
                <Typography.Caption text={sentOn} />
                <Ago at={at} prefix='(' suffix=')' typography='caption' />
              </SBubbleInnerDiv>
            )}
          </SBubbleDiv>
        </SDiv>
      );
    },
  ),
);

export const ChatBubbleLoader = memo(
  forwardRef<HTMLDivElement, { wellplaeceAgent?: boolean | undefined }>(
    ({ wellplaeceAgent }, ref): JSX.Element => (
      <SDiv compact={false} ref={ref} wellplaeceAgent={wellplaeceAgent === true}>
        <AvatarLoader />
        <SBubbleDiv compact={false} wellplaeceAgent={wellplaeceAgent === true}>
          <SBubbleInnerDiv>
            <Illustrations.Loader
              darker={wellplaeceAgent === true}
              height={24}
              uniqueKey='wp-chat-bubble-loader-name'
              width={192}>
              <rect x='0' y='5' rx='0' ry='0' width='192' height='14' />
            </Illustrations.Loader>
          </SBubbleInnerDiv>
          <Illustrations.Loader
            darker={wellplaeceAgent === true}
            height={10}
            uniqueKey='wp-chat-bubble-loader-text-1'
            width={256}>
            <rect x='0' y='0' rx='0' ry='0' width='256' height='10' />
          </Illustrations.Loader>
          <Illustrations.Loader
            darker={wellplaeceAgent === true}
            height={10}
            uniqueKey='wp-chat-bubble-loader-text-2'
            width={128}>
            <rect x='0' y='0' rx='0' ry='0' width='128' height='10' />
          </Illustrations.Loader>
        </SBubbleDiv>
      </SDiv>
    ),
  ),
);
