import type { TFormSubmit } from '@src/components/appearance/controls/Form';
import { Form } from '@src/components/appearance/controls/Form';
import { UploadControl } from '@src/components/appearance/controls/Upload';
import { ensureDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { withCssToString } from '@src/logic/internal/utils/utils';
import type { TAgentCustomersOrganizationActionsTypes } from '@src/modules/data/agent/customers/organizations/AgentCustomersOrganizationActionsProvider';
import type { TAgentUploadsTypes } from '@src/modules/data/agent/global/uploads/AgentUploadsProvider';
import type { TProps } from '@src/modules/design/theme';
import { forwardRef, memo, useCallback, useMemo } from 'react';

export type TFormularyProductsImportFormValues = {
  uploadId?: string | undefined;
};

export type TFormularyProductsImportFormBase = {
  doCustomersOrganizationImportFormularyProductsAsync: TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationImportFormularyProductsAsync'];
  doUploadAsync: TAgentUploadsTypes['DoUploadAsync'];
  onImport: (result: TAgentCustomersOrganizationActionsTypes['DoCustomersOrganizationImportResult']) => void;
};

export type TFormularyProductsImportForm = TProps<false, TFormularyProductsImportFormBase, 'div'>;
export const ORDER_ATTACHMENT_ADD_FORM_CLASS_NAME = 'wp-order-attachment-add-form';

export const FormularyProductsImportForm = withCssToString(
  ORDER_ATTACHMENT_ADD_FORM_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TFormularyProductsImportForm>(
      (
        { doCustomersOrganizationImportFormularyProductsAsync, doUploadAsync, onImport, className, ...rest },
        ref,
      ): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_ATTACHMENT_ADD_FORM_CLASS_NAME),
          [className],
        );

        const initialValues = useMemo<TFormularyProductsImportFormValues>(
          () => ({
            uploadId: undefined,
          }),
          [],
        );

        const handleSubmit = useCallback<TFormSubmit<TFormularyProductsImportFormValues>>(
          async (values) => {
            const result = await doCustomersOrganizationImportFormularyProductsAsync({
              uploadId: ensureDef(values.uploadId),
            });
            onImport(result);
            return undefined;
          },
          [doCustomersOrganizationImportFormularyProductsAsync, onImport],
        );

        return (
          <div {...rest} className={joinedClassName} ref={ref}>
            <Form<TFormularyProductsImportFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              submitButtonText='Import'>
              <UploadControl
                acceptOverride='text/plain'
                doUploadAsync={doUploadAsync}
                id='uploadId'
                label='Formulary Products'
                required={true}
              />
            </Form>
          </div>
        );
      },
    ),
  ),
);
