import { getAvailableIconButtonActions } from '@src/components/appearance/controls/IconButton';
import { Card } from '@src/components/appearance/fragments/Card';
import type { TItemsBase } from '@src/components/appearance/fragments/Items';
import { Items } from '@src/components/appearance/fragments/Items';
import type {
  TAgentOrderTrackingNumberBaseFragment,
  TCustomerOrderTrackingNumberBaseFragment,
} from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { joinClassNames } from '@src/logic/internal/data/utils';
import { openUrl, withCssToString } from '@src/logic/internal/utils/utils';
import type { TProps } from '@src/modules/design/theme';
import { Fragment, forwardRef, memo, useCallback, useMemo } from 'react';

export type TOrderTrackingNumberCardBase = {
  onRemoveAsync?: (() => Promise<void>) | undefined;
  onViewHistory?: (() => void) | undefined;
  orderTrackingNumber: TAgentOrderTrackingNumberBaseFragment | TCustomerOrderTrackingNumberBaseFragment;
};

export type TOrderTrackingNumberCard = TProps<false, TOrderTrackingNumberCardBase, 'div'>;
export const ORDER_TRACKING_NUMBER_CARD_CLASS_NAME = 'wp-order-tracking-number-card';

export const OrderTrackingNumberCard = withCssToString(
  ORDER_TRACKING_NUMBER_CARD_CLASS_NAME,
  memo(
    forwardRef<HTMLDivElement, TOrderTrackingNumberCard>(
      ({ onRemoveAsync, orderTrackingNumber, className, onViewHistory, ...rest }, ref): JSX.Element => {
        const joinedClassName = useMemo(
          () => joinClassNames(className, ORDER_TRACKING_NUMBER_CARD_CLASS_NAME),
          [className],
        );

        const items = useMemo<TItemsBase['items']>(
          () => [
            {
              caption: 'Added By',
              text: `${orderTrackingNumber.creator.first_name} ${orderTrackingNumber.creator.last_name}`,
            },
            {
              caption: 'Added',
              text: { asDate: 'ago', at: orderTrackingNumber.created_at },
            },
          ],
          // @sort
          [
            orderTrackingNumber.created_at,
            orderTrackingNumber.creator.first_name,
            orderTrackingNumber.creator.last_name,
          ],
        );

        const openWindowTrackingNumberLink = useCallback(
          () => openUrl(`https://t.17track.net/en#nums=${encodeURIComponent(orderTrackingNumber.tracking_number)}`),
          [orderTrackingNumber.tracking_number],
        );

        const iconButtonActions = useMemo(
          () =>
            getAvailableIconButtonActions([
              {
                isAsync: false,
                icon: 'link',
                onClick: !isDef(onViewHistory) ? openWindowTrackingNumberLink : undefined,
              },
              { isAsync: true, icon: 'trash', onClick: onRemoveAsync },
            ]),
          // @sort
          [onRemoveAsync, onViewHistory, openWindowTrackingNumberLink],
        );

        return (
          <Card.Container
            {...rest}
            className={joinedClassName}
            flush={true}
            interactive={isDef(onViewHistory)}
            iconButtonActions={iconButtonActions}
            ref={ref}>
            {isDef(onViewHistory) ? (
              <Card.ButtonArea onClick={onViewHistory}>
                <Card.Title title={orderTrackingNumber.tracking_number} />
                <Items items={items} />
              </Card.ButtonArea>
            ) : (
              <Fragment>
                <Card.Title title={orderTrackingNumber.tracking_number} />
                <Items items={items} />
              </Fragment>
            )}
          </Card.Container>
        );
      },
    ),
  ),
);
