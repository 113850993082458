import type { TCustomerPublicCatalogProductCompleteWithVariantsFragment } from '@src/gen/graphql/bindings';
import { useCustomerWpxLegacyProductGetQuery } from '@src/gen/graphql/bindings';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TCustomerPublicCatalogProductContext = {
  publicCatalogProduct: TCustomerPublicCatalogProductCompleteWithVariantsFragment;
};

export const { Context: CustomerPublicCatalogProductContext, useContext: useCustomerPublicCatalogProduct } =
  createRequiredContext<TCustomerPublicCatalogProductContext>();

export type TCustomerNextOrderProductProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  publicCatalogProductId: string;
}>;

export function CustomerPublicCatalogProductProvider({
  LoaderComponent,
  publicCatalogProductId,
  children,
}: TCustomerNextOrderProductProvider): JSX.Element {
  const { data, error } = useCustomerWpxLegacyProductGetQuery({
    variables: {
      productId: publicCatalogProductId,
    },
  });

  const value = useMemo<TCustomerPublicCatalogProductContext | undefined>(
    () =>
      isDef(data) && isDef(data.wpxGetLegacyProduct)
        ? {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            publicCatalogProduct: data.wpxGetLegacyProduct as TCustomerPublicCatalogProductCompleteWithVariantsFragment,
          }
        : undefined,
    [data],
  );

  if (isDef(error)) {
    throw error;
  }

  if (isDef(data) && !isDef(data.wpxGetLegacyProduct)) {
    throw new Error('Not Found');
  }

  return isDef(value) ? (
    <CustomerPublicCatalogProductContext.Provider value={value}>
      {children}
    </CustomerPublicCatalogProductContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
