import type { ApolloError } from '@apollo/client';
import { useCustomerGetTrackerResultQuery } from '@src/gen/graphql/bindings';
import type { TTrackerResult } from '@src/gen/shared/data/tracking';
import { castTrackingUnsafe } from '@src/gen/shared/data/tracking';
import { isDef } from '@src/gen/shared/utils/types';
import { createRequiredContext } from '@src/logic/internal/utils/utils';
import type { TEmptyObject } from '@src/modules/design/theme';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

export type TCustomerGetTrackerResultContext = {
  trackerResult?: TTrackerResult | undefined;
  error?: ApolloError | undefined;
};

export const { Context: CustomerGetTrackerResultContext, useContext: useCustomerGetTrackerResult } =
  createRequiredContext<TCustomerGetTrackerResultContext>();

export type TCustomerGetTrackerResultProvider = PropsWithChildren<{
  LoaderComponent: FunctionComponent<TEmptyObject>;
  ship24TrackerId: string;
}>;

export function CustomerGetTrackerResultProvider({
  LoaderComponent,
  ship24TrackerId,
  children,
}: TCustomerGetTrackerResultProvider): JSX.Element {
  const { data, error } = useCustomerGetTrackerResultQuery({
    variables: {
      ship24TrackerId,
    },
  });

  const value = useMemo<TCustomerGetTrackerResultContext | undefined>(
    () =>
      isDef(data) && isDef(data.getTrackerResult)
        ? {
            trackerResult: castTrackingUnsafe(data.getTrackerResult.trackerResult),
          }
        : isDef(error)
        ? {
            error,
          }
        : undefined,
    [error, data],
  );

  return isDef(value) ? (
    <CustomerGetTrackerResultContext.Provider value={value}>{children}</CustomerGetTrackerResultContext.Provider>
  ) : (
    <LoaderComponent />
  );
}
