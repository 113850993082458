import { ensureDef } from '@src/gen/shared/utils/types';
import assert from 'assert';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export enum EReferralFeeTarget {
  TEST = 'test',
  NEW_HOPE_DENTAL = 'new_hope_dental',
  SAN_MATEO_COUNTY_DENTAL_SOCIETY = 'san_mateo_county_dental_society',
  SANTA_CLARA_COUNTY_DENTAL_SOCIETY = 'santa_clara_county_dental_society',
  ZIRC = 'zirc',
}

const REFERRAL_FEE_TARGET_LOOKUP = mapValues(invert(EReferralFeeTarget), () => true);

export function getReferralFeeTarget(rawReferralFeeTarget: string): EReferralFeeTarget {
  assert(REFERRAL_FEE_TARGET_LOOKUP[rawReferralFeeTarget], `Unknown revenue share target: "${rawReferralFeeTarget}".`);
  return rawReferralFeeTarget as EReferralFeeTarget; // eslint-disable-line @typescript-eslint/consistent-type-assertions
}

export function maybeGetReferralFeeTarget(rawReferralFeeTarget: string): EReferralFeeTarget | undefined {
  try {
    return getReferralFeeTarget(rawReferralFeeTarget);
  } catch {
    return undefined;
  }
}

export const REFERRAL_FEE_TARGET_LABELS: { [key in EReferralFeeTarget]: string } = {
  [EReferralFeeTarget.TEST]: 'Test',
  [EReferralFeeTarget.NEW_HOPE_DENTAL]: 'New Hope Dental',
  [EReferralFeeTarget.SAN_MATEO_COUNTY_DENTAL_SOCIETY]: 'San Mateo County Dental Society',
  [EReferralFeeTarget.SANTA_CLARA_COUNTY_DENTAL_SOCIETY]: 'Santa Clara County Dental Society',
  [EReferralFeeTarget.ZIRC]: 'ZIRC',
};

export function maybeGetReferralFeeTargetLabel(rawReferralFeeTarget: string): string {
  const referralFeeTarget = maybeGetReferralFeeTarget(rawReferralFeeTarget);
  return referralFeeTarget !== undefined
    ? ensureDef(REFERRAL_FEE_TARGET_LABELS[referralFeeTarget])
    : rawReferralFeeTarget;
}

const REFERRAL_FEE_TARGET_AMOUNTS_BY_ORDER_INDEX: { [key in EReferralFeeTarget]: number[] } = {
  [EReferralFeeTarget.TEST]: [],
  [EReferralFeeTarget.NEW_HOPE_DENTAL]: [20000, 0, 20000],
  [EReferralFeeTarget.SAN_MATEO_COUNTY_DENTAL_SOCIETY]: [0, 0, 20000],
  [EReferralFeeTarget.SANTA_CLARA_COUNTY_DENTAL_SOCIETY]: [0, 0, 20000],
  [EReferralFeeTarget.ZIRC]: [0, 0, 50000],
};

export function getReferralFeeAmountByOrderIndex(rawReferralFeeTarget: string, orderIndex: number): number {
  return REFERRAL_FEE_TARGET_AMOUNTS_BY_ORDER_INDEX[getReferralFeeTarget(rawReferralFeeTarget)][orderIndex] ?? 0;
}
